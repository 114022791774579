import {
  Badge,
  Checkbox,
  Container,
  ExpandableSection,
  Grid,
  Header,
  Input,
  Select,
  SpaceBetween
} from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditEventActions, useEditEvent } from '../../../store/edit-event.context';
import { Event } from '../../../types/Event';
import { i18nKeys } from '../../../utils/i18n.utils';
import { KeyValue } from '../KeyValue';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import { EventAudienceType } from '@amzn/aws-jam-constants';
import { useUser } from '../../../store/user.context';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { toTitleCase } from '../../../utils/string.utils';
import { EventTags } from '../EventTags';
import { Campaign } from '../../../types/Campaign';
import { EditCampaignActions, useEditCampaign } from '../../../store/edit-campaign.context';
import '../../../styles/tags.scss';

interface TargetSummaryDetailsProps {
  target: Event | Campaign | undefined;
}

const TargetSummaryDetails: React.FC<TargetSummaryDetailsProps> = ({ target }) => {
  const { t } = useTranslation();
  const { editMode, newEventMode, editedEvent, handleUpdateEditEvent } = useEditEvent();
  const { campaignEditMode, newCampaignMode, editedCampaign, handleUpdateEditCampaign } = useEditCampaign();
  const { user } = useUser();
  const [selectedOption, setSelectedOption] = useState<OptionDefinition | null>(null);
  const [selectionOptions, setSelectionOptions] = useState<OptionDefinition[]>([]);

  const isEvent = target instanceof Event;
  const canEdit = isEvent ? editMode || newEventMode : campaignEditMode || newCampaignMode;

  // TODO: Fix design token import errors and replace javascript implementation below
  const SecondaryText = {
    color: awsui.colorTextFormSecondary,
  };

  // TODO: Align type with enum once aws-jam-constants is refactored to use actual enums
  const EventAudienceTypeDescriptionDictionary = {
    [EventAudienceType.CUSTOMER_INTERNAL]: `${toTitleCase(EventAudienceType.CUSTOMER_INTERNAL)} - ${t(
      i18nKeys.events.eventDetails.labels.eventAudienceTypeDescriptions.customerInternal
    )}`,
    [EventAudienceType.GENERAL_PUBLIC]: `${toTitleCase(EventAudienceType.GENERAL_PUBLIC)} - ${t(
      i18nKeys.events.eventDetails.labels.eventAudienceTypeDescriptions.generalPublic
    )}`,
    [EventAudienceType.AWS_PRIVATE_ENGAGEMENT]: `${toTitleCase(EventAudienceType.AWS_PRIVATE_ENGAGEMENT)} - ${t(
      i18nKeys.events.eventDetails.labels.eventAudienceTypeDescriptions.awsPrivateEngagement
    )}`,
    [EventAudienceType.AWS_BOOTCAMP]: `${toTitleCase(EventAudienceType.AWS_BOOTCAMP)} - ${t(
      i18nKeys.events.eventDetails.labels.eventAudienceTypeDescriptions.awsBootcamp
    )}`,
    [EventAudienceType.AWS_INTERNAL]: `${toTitleCase(EventAudienceType.AWS_INTERNAL)} - ${t(
      i18nKeys.events.eventDetails.labels.eventAudienceTypeDescriptions.awsInternal
    )}`,
    [EventAudienceType.TESTERS]: `${toTitleCase(EventAudienceType.TESTERS)} - ${t(
      i18nKeys.events.eventDetails.labels.eventAudienceTypeDescriptions.testers
    )}`,
    [EventAudienceType.INDIVIDUAL]: `${toTitleCase(EventAudienceType.INDIVIDUAL)} - ${t(
      i18nKeys.events.eventDetails.labels.eventAudienceTypeDescriptions.individual
    )}`,
  };

  const generateSelectionOptions = () => {
    // TODO: Revisit after aws-jam-constants is refactored to use actual enums
    const valueList: string[] = [];
    // non-amazonian only allowed customer internal and testers
    if (user && !user.isAmazonian) {
      valueList.push(EventAudienceType.CUSTOMER_INTERNAL, EventAudienceType.TESTERS);
    }
    if (target && target instanceof Event && target.isCampaignEvent) {
      valueList.push(EventAudienceType.INDIVIDUAL);
    }

    // default allow everything except individual
    const otherKeys = Object.keys(EventAudienceType).filter((key) => {
      return ![EventAudienceType.INDIVIDUAL].includes(key);
    });

    otherKeys.forEach((key) => {
      if (!valueList.includes(key)) valueList.push(key);
    });

    const newSelection: OptionDefinition[] = [];
    valueList.forEach((value) => {
      const exists = selectionOptions.some((option) => option.value === value);
      if (!exists) {
        newSelection.push({ label: EventAudienceTypeDescriptionDictionary[value], value });
      }
    });

    setSelectionOptions(newSelection);
    if (isEvent) {
      setSelectedOption({
        label: EventAudienceTypeDescriptionDictionary[target?.audienceType as keyof typeof EventAudienceType],
        value: target?.audienceType as string,
      });
    }
  };

  const updateAudienceType = (audienceType: OptionDefinition) => {
    if (audienceType) {
      setSelectedOption(audienceType);
      handleUpdateEditEvent(EditEventActions.AUDIENCE, audienceType.value);
    }
  };

  const updateDisableCodeWhisperer = (value: boolean) => {
    handleUpdateEditEvent(EditEventActions.CODE_WHISPERER_DISABLED, value)
  }

  const handleTitleUpdate = (value: string) => {
    if ((value && typeof value !== 'string') || !value.trim()) {
      if (isEvent) {
        handleUpdateEditEvent(EditEventActions.TITLE, '');
      } else {
        handleUpdateEditCampaign(EditCampaignActions.TITLE, '');
      }
      return;
    }
    if (isEvent) {
      handleUpdateEditEvent(EditEventActions.TITLE, value);
    } else {
      handleUpdateEditCampaign(EditCampaignActions.TITLE, value);
    }
  };

  const handleChangeSlug = (value: string) => {
    if ((value && typeof value !== 'string') || !value.trim()) {
      if (isEvent) {
        handleUpdateEditEvent(EditEventActions.SLUG, '');
      } else {
        handleUpdateEditCampaign(EditCampaignActions.SLUG, '');
      }
      return;
    }
    if (isEvent) {
      handleUpdateEditEvent(EditEventActions.SLUG, value);
    } else {
      handleUpdateEditCampaign(EditCampaignActions.SLUG, value);
    }
  };

  const showCampaignSlug = (): boolean => {
    if (target instanceof Campaign) {
      if (target.cancelled) {
        return false;
      }
      if (target.denied) {
        return false;
      }

      return target.approved;
    }
    return false;
  };

  useEffect(() => {
    generateSelectionOptions();
  }, []);

  const editedSlug = isEvent ? target.name : target?.slug;

  const renderContent = () => {
    return !canEdit ? (
      <React.Fragment>
        <div className="section-first-row">
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
            <div style={SecondaryText}>
              {isEvent
                ? t(i18nKeys.events.fields.eventTitle.title)
                : t(i18nKeys.campaigns.labels.campaignDetails.campaignTitle)}
            </div>
            <div>{target?.title}</div>
          </Grid>
        </div>
        {((isEvent && target?.showEventId) || (target instanceof Campaign && showCampaignSlug())) && (
          <div className="grey-section-divider-top">
            <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
              <div style={SecondaryText}>{t(i18nKeys.events.fields.slug.title)}</div>
              <div>{isEvent ? target?.id : target.slug}</div>
            </Grid>
          </div>
        )}
        {isEvent && (
          <div className="grey-section-divider-top">
            <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
              <div style={SecondaryText}>{t(i18nKeys.events.fields.audience.title)}</div>
              <div>{EventAudienceTypeDescriptionDictionary[target?.audienceType as string]}</div>
            </Grid>
          </div>
        )}
        <div className="grey-section-divider-top">
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
            <div style={SecondaryText}>{t(i18nKeys.events.fields.tags.title)}</div>
            <div className="tag-group">
              {target?.tags?.map((tag, key) => {
                return (
                    <Badge color="grey" key={key}>
                      {tag}
                    </Badge>
                );
              })}
            </div>
          </Grid>
        </div>
        {isEvent && (
          <div className="grey-section-divider-top">
            <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
              <div style={SecondaryText}>{t(i18nKeys.events.fields.disableCodeWhisperer.title)}</div>
              <div>{t(target?.codeWhispererDisabled ? i18nKeys.general.yes : i18nKeys.general.no)}</div>
            </Grid>
          </div>
        )}
      </React.Fragment>
    ) : (
      canEdit && user && (
        <Grid gridDefinition={[{ colspan: 8 }]}>
          <SpaceBetween direction="vertical" size="m">
            <KeyValue
              label={
                <b>
                  {isEvent
                    ? t(i18nKeys.events.fields.eventTitle.title)
                    : t(i18nKeys.campaigns.labels.campaignDetails.campaignTitle)}
                </b>
              }
              required>
              <Input
                value={target?.title || ''}
                placeholder={
                  isEvent
                    ? t(i18nKeys.events.eventDetails.labels.exampleJamTitle)
                    : t(i18nKeys.campaigns.labels.campaignDetails.exampleCampaignTitle)
                }
                readOnly={!target?.canEditAttribute('title', user)}
                disabled={!target?.canEditAttribute('title', user)}
                onChange={({ detail }) => handleTitleUpdate(detail.value)}
              />
            </KeyValue>
            {(isEvent && target?.showEventId && !newEventMode) ||
              (target instanceof Campaign && showCampaignSlug() && !newCampaignMode && (
                <KeyValue label={<b>{t(i18nKeys.events.fields.slug.title)}</b>} required>
                  <div style={SecondaryText}>
                    {t(i18nKeys.general.example)}: https://jam.awsevents.com/
                    <span style={{ color: awsui.colorTextStatusSuccess }}>my-jam-event</span>
                  </div>
                  <Input
                    value={editedSlug || ''}
                    onChange={({ detail }) => handleChangeSlug(detail.value)}
                    placeholder="aws-slug-example"
                    readOnly={isEvent ? !newEventMode : !newCampaignMode}
                    disabled={isEvent ? !newEventMode : !newCampaignMode}
                  />
                </KeyValue>
              ))}
            {isEvent && (
              <KeyValue label={<b>{t(i18nKeys.events.fields.audience.title)}</b>} required>
                <div style={SecondaryText}>{t(i18nKeys.events.eventDetails.messages.whoIsThisEventIntendedFor)}</div>
                <Select
                  disabled={!target?.canEditAttribute('audienceType', user)}
                  options={selectionOptions}
                  selectedOption={selectedOption}
                  onChange={({ detail }) => updateAudienceType(detail.selectedOption)}
                />
              </KeyValue>
            )}
            {((editedEvent || newEventMode) || (editedCampaign || newCampaignMode)) && (
              <KeyValue label={<b>{t(i18nKeys.events.eventDetails.headers.tags)}</b>}>
                <EventTags />
              </KeyValue>
            )}
            {isEvent && (
              <KeyValue label={<b>{t(i18nKeys.events.fields.disableCodeWhisperer.sectionTitle)}</b>}>
                <label>
                  <SpaceBetween direction="horizontal" size="xxxs">
                    <Checkbox
                      checked={target.codeWhispererDisabled ?? false}
                      onChange={({ detail }) => updateDisableCodeWhisperer(detail.checked)}
                    />
                    <div style={{ marginLeft: '1rem' }}>
                      <div>
                        {t(i18nKeys.events.fields.disableCodeWhisperer.title)}
                      </div>
                      <div style={SecondaryText}>
                        {t(i18nKeys.events.fields.disableCodeWhisperer.description)}
                      </div>
                    </div>
                  </SpaceBetween>
                </label>
              </KeyValue>
            )}
          </SpaceBetween>
        </Grid>
      )
    );
  };

  return (
    <React.Fragment>
      {(isEvent ? !newEventMode : !newCampaignMode) && (
        <ExpandableSection
          variant="container"
          header={
            <Header variant="h2">
              {isEvent
                ? t(i18nKeys.events.eventDetails.headers.eventDetails)
                : t(i18nKeys.campaigns.headers.campaignDetails.campaignDetails)}
            </Header>
          }>
          {renderContent()}
        </ExpandableSection>
      )}
      {(isEvent ? newEventMode : newCampaignMode) && (
        <Container
          header={
            <Header variant="h2">
              {isEvent
                ? t(i18nKeys.events.eventDetails.headers.eventDetails)
                : t(i18nKeys.campaigns.headers.campaignDetails.campaignDetails)}
            </Header>
          }>
          {renderContent()}
        </Container>
      )}
    </React.Fragment>
  );
};
export default TargetSummaryDetails;
