import { ApiClient } from './ApiClient';
import { i18nKeys } from '../utils/i18n.utils';
import { asList } from '../utils/list.utils';
import { fromPlainObject } from '../utils/mapper.utils';
import { NullableString } from '../types/common';
import { CreateUsagePlanRequest, UsagePlan, UsagePlanComment, UsagePlanDetails } from '../types/usage-plan/UsagePlan';
import { TFunction } from 'i18next';

export class UsagePlanAPI {
  // eslint-disable-next-line no-empty-function
  constructor(private apiClient: ApiClient, private t: TFunction) {}

  /**
   * Gets the latest versions of all the usage plans for this user. If the user is super-admin, they can see all plans.
   * Otherwise, they can only view plans that have their email in the maintainer or allowlist.   *
   *
   * @param silent Optional param to enable silent failure
   */
  public async getUsagePlans(silent = false): Promise<UsagePlan[]> {
    let plans: UsagePlan[] = [];
    let nextToken: NullableString = null;

    do {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const res: { plans: object[]; nextToken: NullableString } = await this.apiClient.get({
        path: '/admin/usagePlans',
        failMessage: this.t(i18nKeys.errors.requestFailed.usagePlan.getUsagePlans),
        params: nextToken ? { nextToken } : undefined,
        silent,
      });
      const receivedPlans: UsagePlan[] = res.plans
        ? (res.plans.map((plan: object) => fromPlainObject(plan, UsagePlan)) as UsagePlan[])
        : [];
      plans = [...plans, ...receivedPlans];
      nextToken = res.nextToken;
    } while (nextToken != null);
    return plans;
  }

  /**
   * Gets details of a usage plan. Viewable only by super admins, plan maintainers, and allowlisted users.
   * Returns a scrubbed version of the plan, depending on the user:
   * - general users can view description and constraints of the plan
   * - maintainers can view the above, plus the allowlist/maintainer list, plus usage plan notes
   * - superadmins can view the above, plus plan change history, requests for the plan, and usage metrics
   *
   * @param planId The ID of the usage plan
   * @param silent Optional param to enable silent failure
   */
  public async getUsagePlanDetails(planId: string, silent = false): Promise<UsagePlanDetails> {
    return (await this.apiClient.get({
      path: `/admin/usagePlans/${planId}`,
      failMessage: this.t(i18nKeys.errors.requestFailed.usagePlan.getUsagePlanDetails),
      responseMapper: (object) => fromPlainObject(object, UsagePlanDetails),
      silent,
    })) as Promise<UsagePlanDetails>;
  }

  /**
   * Creates a new usage plan
   *
   * @param usagePlan The new usage plan to create
   * @param silent Optional param to enable silent failure
   */
  public async createPlan(planRequest: CreateUsagePlanRequest, silent = false): Promise<UsagePlan> {
    return (await this.apiClient.post({
      path: '/admin/usagePlans/create',
      failMessage: this.t(i18nKeys.errors.requestFailed.usagePlan.create),
      successMessage: this.t(i18nKeys.success.requestSucceeded.usagePlan.create),
      body: planRequest,
      responseMapper: (object) => fromPlainObject(object, UsagePlan),
      silent,
    })) as Promise<UsagePlan>;
  }

  /**
   * Updates an existing usage plan
   *
   * @param usagePlan The updated usage plan
   * @param silent Optional param to enable silent failure
   */
  public async updatePlan(usagePlan: UsagePlan, silent = false): Promise<UsagePlan> {
    return (await this.apiClient.post({
      path: '/admin/usagePlans/update',
      failMessage: this.t(i18nKeys.errors.requestFailed.usagePlan.update),
      successMessage: this.t(i18nKeys.success.requestSucceeded.usagePlan.update),
      body: usagePlan,
      responseMapper: (object) => fromPlainObject(object, UsagePlan),
      silent,
    })) as Promise<UsagePlan>;
  }

  /**
   * Updates the allowlist of the plan. This is only allowed by super admins and users who are maintainers of this plan
   *
   * @param emails The new emails to be on the allowlist
   * @param planId The plan to update
   * @param silent Optional param to enable silent failure
   */
  public async updateAllowlist(emails: string[], planId: string, silent = false): Promise<UsagePlan> {
    return (await this.apiClient.post({
      path: `/admin/usagePlans/${planId}/update/allowlist`,
      failMessage: this.t(i18nKeys.errors.requestFailed.usagePlan.update),
      successMessage: this.t(i18nKeys.success.requestSucceeded.usagePlan.update),
      body: emails,
      responseMapper: (object) => fromPlainObject(object, UsagePlan),
      silent,
    })) as Promise<UsagePlan>;
  }

  /**
   * Gets all comments on a specific plan
   *
   * @param planId The ID of the usage plan
   * @param silent Optional param to enable silent failure
   */
  public async getUsagePlanComments(planId: string, silent = false): Promise<UsagePlanComment[]> {
    return (await this.apiClient.get({
      path: `/admin/usagePlans/${planId}/comments`,
      failMessage: this.t(i18nKeys.errors.requestFailed.usagePlan.getComments),
      responseMapper: asList((object) => fromPlainObject(object, UsagePlanComment)),
      silent,
    })) as Promise<UsagePlanComment[]>;
  }

  /**
   * Adds a comment
   *
   * @param planId The ID of the usage plan
   * @param commentValue The content of the comment
   * @param silent Optional param to enable silent failure
   */
  public async addComment(planId: string, commentValue: string, silent = false): Promise<UsagePlanComment[]> {
    return (await this.apiClient.post({
      path: `/admin/usagePlans/${planId}/comments/create`,
      body: { value: commentValue },
      successMessage: this.t(i18nKeys.success.requestSucceeded.comments.addComment),
      failMessage: this.t(i18nKeys.errors.requestFailed.comments.addComment),
      silent,
    })) as Promise<UsagePlanComment[]>;
  }

  /**
   * Updates a comment
   *
   * @param planId The ID of the usage plan
   * @param commentValue The content of the comment
   * @param commentId The ID of the comment
   * @param silent Optional param to enable silent failure
   */
  public async updateComment(
    planId: string,
    commentId: string,
    commentValue: string,
    silent = false
  ): Promise<UsagePlanComment> {
    return (await this.apiClient.post({
      path: `/admin/usagePlans/${planId}/comments/update`,
      body: { id: commentId, value: commentValue },
      successMessage: this.t(i18nKeys.success.requestSucceeded.comments.updateComment),
      failMessage: this.t(i18nKeys.errors.requestFailed.comments.updateComment),
      responseMapper: (object) => fromPlainObject(object, UsagePlanComment),
      silent,
    })) as Promise<UsagePlanComment>;
  }

  /**
   * Deletes a comment
   *
   * @param planId The ID of the usage plan
   * @param commentId The ID of the comment
   * @param silent Optional param to enable silent failure
   */
  public async deleteComment(planId: string, commentId: string, silent = false): Promise<UsagePlanComment> {
    return (await this.apiClient.post({
      path: `/admin/usagePlans/${planId}/comments/delete`,
      body: { id: commentId },
      successMessage: this.t(i18nKeys.success.requestSucceeded.comments.deleteComment),
      failMessage: this.t(i18nKeys.errors.requestFailed.comments.deleteComment),
      responseMapper: (object) => fromPlainObject(object, UsagePlanComment),
      silent,
    })) as Promise<UsagePlanComment>;
  }

  public async getUsagePlanDashboardUrl(planId: string, silent = false): Promise<string> {
    return (await this.apiClient.get({
      path: `/admin/usagePlans/${planId}/dashboard`,
      failMessage: this.t(i18nKeys.errors.requestFailed.usagePlan.dashboard),
      silent,
    })) as Promise<string>;
  }

  public async reevaluateRequests(planId: string, silent = false): Promise<void> {
    await this.apiClient.post({
      path: `/admin/usagePlans/${planId}/reevaluate`,
      successMessage: this.t(i18nKeys.success.requestSucceeded.usagePlan.reevaluateRequest),
      failMessage: this.t(i18nKeys.errors.requestFailed.usagePlan.reevaluatePlural),
      silent,
    });
  }
}
