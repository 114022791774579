import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './components/root/App';
import reportWebVitals from './reportWebVitals';
import '@amzn/awsui-global-styles/polaris.css';
import './i18n';
import { LoadingBar } from './components/common/LoadingBar';
import { UserProvider } from './store/user.context';
import { FlashbarProvider } from './store/flashbar.context';
import { ApiClientProvider } from './store/api.context';
import Amplify from 'aws-amplify';
import amplifyConfig from './config/amplifyConfig';
import { AuthClientProvider } from './store/auth.context';
import { EventsProvider } from './store/events.context';
import { CookieConsentProvider } from './store/cookie-consent.context';
import { EditEventProvider } from './store/edit-event.context';
import { UsagePlanProvider } from './store/usage-plan/usage-plan.context';
import { ChallengesProvider } from './store/challenge.context';
import { SplitPanelProvider } from './store/split-panel.context';
import { ToolPanelProvider } from './store/tool-panel.context';
import { BASE_PATH_PREFIX } from './utils/env.utils';
import { CreateChallengeProvider } from './store/create-challenge.context';
import { ChallengeTutorial } from './components/challenges/ChallengeTutorial';
import { ChallengeBoardProvider } from './store/challenge-board.context';
import { ChallengeSetProvider } from './store/challenge-set.context';
import { LSEProvider } from './store/lse.context';
import { CampaignsProvider } from './store/campaigns.context';
import { EditCampaignProvider } from './store/edit-campaign.context';

Amplify.configure(amplifyConfig);

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
global.fetch = require('node-fetch');

const Root = () => (
  <React.StrictMode>
    <BrowserRouter basename={BASE_PATH_PREFIX}>
      <div className="awsui">
        <Suspense fallback={<LoadingBar />}>
          <FlashbarProvider>
            <AuthClientProvider>
              <ApiClientProvider>
                <UserProvider>
                  {/*
                    TODO: figure out how to wrap a provider around a specific route in routes.ts or MainContent.tsx
                          because this EventsProvider is only needed by EventList and EventDetails
                  */}
                  <CookieConsentProvider>
                    <LSEProvider>
                      <ChallengesProvider>
                        <ChallengeSetProvider>
                          <ChallengeBoardProvider>
                            <SplitPanelProvider>
                              <ToolPanelProvider>
                                <EventsProvider>
                                  <CampaignsProvider>
                                    <EditEventProvider>
                                      <EditCampaignProvider>
                                        <UsagePlanProvider>
                                          <CreateChallengeProvider>
                                            <ChallengeTutorial>
                                              <App />
                                            </ChallengeTutorial>
                                          </CreateChallengeProvider>
                                        </UsagePlanProvider>
                                      </EditCampaignProvider>
                                    </EditEventProvider>
                                  </CampaignsProvider>
                                </EventsProvider>
                              </ToolPanelProvider>
                            </SplitPanelProvider>
                          </ChallengeBoardProvider>
                        </ChallengeSetProvider>
                      </ChallengesProvider>
                    </LSEProvider>
                  </CookieConsentProvider>
                </UserProvider>
              </ApiClientProvider>
            </AuthClientProvider>
          </FlashbarProvider>
        </Suspense>
      </div>
    </BrowserRouter>
  </React.StrictMode>
);

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
