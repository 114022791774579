import React from 'react';
import { Badge, Button, CollectionPreferencesProps, SpaceBetween } from '@amzn/awsui-components-react';
import { CognitoUser, DEFAULT_STATUS } from '../../../../types/User';
import { getTableFilterMatcher, TableFilterMatcher } from '../../../../utils/table.utils';
import { useTranslation } from 'react-i18next';
import { UserStatuses } from '../../../../types/cognito';
import { capitalize, snakeCase } from 'lodash';
import { AuthRole } from '@amzn/aws-jam-constants';
import { CopyToClipboard } from '../../../common/CopyToClipboard';
import { i18nKeys } from '../../../../utils/i18n.utils';

const formatStatus = (status: string): string => {
  return snakeCase(status).split('_').map(capitalize).join(' ');
};

export const getGroupTitle = (group: string): string => {
  if (isCustomGroup(group)) {
    return formatStatus(group);
  }

  return group.split('-').map(capitalize).join(' ');
};

const isCustomGroup = (group: string): boolean => {
  return !(Object.values(AuthRole) as string[]).includes(group);
};

export const filteringFunction = (item: CognitoUser, filteringText: string): boolean => {
  const matcher: TableFilterMatcher = getTableFilterMatcher(filteringText);
  return (
    matcher.filterEmpty ||
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    matcher.isMatch(item.userAttributes.email) ||
    matcher.isMatch(item.username) ||
    (item.groups?.findIndex((group) => getGroupTitle(group).includes(filteringText)) || -1) > -1 ||
    matcher.isMatch(formatStatus(item.userStatus))
  );
};

export const COLUMN_DEFINITIONS = (
  preferences: CollectionPreferencesProps.Preferences,
  handleRemoveGroupClick: (group: string, user: CognitoUser) => void
) => {
  const { t } = useTranslation();

  return [
    {
      id: 'username-email',
      sortingField: 'email',
      header: t(i18nKeys.settings.userManagement.table.headers.usernameEmail),
      cell: (item: CognitoUser) => {
        return (
          <React.Fragment>
            {item.username !== item.userAttributes.email && <div>{item.username}</div>}
            <div>
              {item.userAttributes.email}
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
              <CopyToClipboard value={item.userAttributes.email} icon />
            </div>
          </React.Fragment>
        );
      },
      minWidth: 260,
      allowLineWrap: true,
    },
    {
      id: 'groups',
      sortingField: 'groups',
      header: t(i18nKeys.settings.userManagement.table.headers.groups),
      minWidth: 260,
      cell: (item: CognitoUser) => (
        <SpaceBetween direction="vertical" size="s">
          {item.groups?.map((group, i) => (
            <div key={`group-${i}`}>
              <Badge>
                {getGroupTitle(group)}
                <Button variant="icon" iconName="close" onClick={() => handleRemoveGroupClick(group, item)} />
              </Badge>
            </div>
          ))}
        </SpaceBetween>
      ),
    },
    {
      id: 'email-verified',
      sortingField: 'emailVerified',
      header: t(i18nKeys.settings.userManagement.table.headers.emailVerified),
      cell: (item: CognitoUser) =>
        item.userAttributes.emailVerified ? t(i18nKeys.general.yes) : t(i18nKeys.general.no),
      minWidth: 100,
      allowLineWrap: false,
    },
    {
      id: 'status',
      sortingField: 'status',
      header: t(i18nKeys.settings.userManagement.table.headers.status),
      cell: (item: CognitoUser) => {
        const knownStatuses = [
          UserStatuses.CONFIRMED,
          DEFAULT_STATUS,
          UserStatuses.UNCONFIRMED,
          UserStatuses.FORCE_CHANGE_PASSWORD,
        ];
        return (
          <React.Fragment>
            {item.userStatus === UserStatuses.CONFIRMED && (
              <span>{t(i18nKeys.settings.userManagement.table.statuses.confirmed)}</span>
            )}
            {item.userStatus === DEFAULT_STATUS && (
              <span>{t(i18nKeys.settings.userManagement.table.statuses.externalProvider)}</span>
            )}
            {item.userStatus === UserStatuses.UNCONFIRMED && (
              <span>{t(i18nKeys.settings.userManagement.table.statuses.unconfirmed)}</span>
            )}
            {item.userStatus === UserStatuses.FORCE_CHANGE_PASSWORD && (
              <span>{t(i18nKeys.settings.userManagement.table.statuses.forcePasswordChange)}</span>
            )}
            {!knownStatuses.includes(item.userStatus) && <span>{formatStatus(item.userStatus)}</span>}
          </React.Fragment>
        );
      },
      minWidth: 156,
      allowLineWrap: false,
    },
    {
      id: 'enabled',
      sortingField: 'isEnabled',
      header: t(i18nKeys.settings.userManagement.table.headers.enabled),
      cell: (item: CognitoUser) => (item.isEnabled ? t(i18nKeys.general.yes) : t(i18nKeys.general.no)),
      minWidth: 100,
      allowLineWrap: false,
    },
  ];
};
