import { EventType, EventTypeDescriptions } from '@amzn/aws-jam-constants';
import { ExpandableSection, FormField, Grid, Header, Select } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditEventActions, useEditEvent } from '../../../../store/edit-event.context';
import { useUser } from '../../../../store/user.context';
import { Event } from '../../../../types/Event';
import { i18nKeys } from '../../../../utils/i18n.utils';

interface EventDetailsSubTypeProps {
  event: Event | undefined;
}

const EventDetailsSubType: React.FC<EventDetailsSubTypeProps> = ({ event }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { editMode, editedEvent, handleUpdateEditEvent } = useEditEvent();
  const [selectedEventType, setSelectedEventType] = useState<OptionDefinition | null>(null);

  const eventTypeDescriptionDictionary: { [key in keyof typeof EventTypeDescriptions]: string } = {
    CAMPAIGN_GROUP: t(i18nKeys.events.eventDetails.labels.campaignGroup),
    CTF: `CTF - ${t(i18nKeys.events.eventDetails.labels.ctf)}`,
    JAM: t(i18nKeys.events.eventDetails.labels.jam),
    ONE_CLICK_TEST_EVENT: t(i18nKeys.events.eventDetails.labels.oneClickTestEvent),
    RECURRING_AUTOMATED_TEST_EVENT: t(i18nKeys.events.eventDetails.labels.recurringAutomatedTestEvent),
  };

  const eventTypeOptions: OptionDefinition[] = [{ label: eventTypeDescriptionDictionary.JAM, value: EventType.JAM }];

  if (event?.isCampaignEvent) {
    eventTypeOptions.push({ label: eventTypeDescriptionDictionary.CAMPAIGN_GROUP, value: EventType.CAMPAIGN_GROUP });
  }

  useEffect(() => {
    if (editedEvent?.type) {
      setSelectedEventType({
        label: eventTypeDescriptionDictionary[editedEvent?.type as keyof typeof EventType],
        value: editedEvent.type,
      });
    }
  }, [editMode, editedEvent]);

  const handleEventTypeSelection = (eventTypeSelection: OptionDefinition) => {
    setSelectedEventType(eventTypeSelection);
    handleUpdateEditEvent(EditEventActions.TYPE, eventTypeSelection.value);
  };

  return (
    <ExpandableSection
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.eventSubType)}</Header>}>
      {!editMode && event && event.type && (
        <div className="section-first-row">
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
            <div className="secondary-text">{t(i18nKeys.events.eventDetails.headers.eventSubType)}</div>
            <div>{eventTypeDescriptionDictionary[event?.type as keyof typeof EventTypeDescriptions]}</div>
          </Grid>
        </div>
      )}
      {editMode && user && (
        <Grid gridDefinition={[{ colspan: 8 }]}>
          <FormField
            label={t(i18nKeys.events.eventDetails.labels.eventSubType.label)}
            description={t(i18nKeys.events.eventDetails.labels.eventSubType.message)}>
            <Select
              options={eventTypeOptions}
              selectedOption={selectedEventType}
              onChange={({ detail }) => handleEventTypeSelection(detail.selectedOption)}
              disabled={!event?.canEditAttribute('type', user)}
            />
          </FormField>
        </Grid>
      )}
    </ExpandableSection>
  );
};
export default EventDetailsSubType;
