import { ChallengeFeedbackSummary } from "../types/Challenge";

export const getAverageRating = (challengeFeedbackSummary: ChallengeFeedbackSummary | undefined): number => {
    if (challengeFeedbackSummary) {
        const totalScore =
        challengeFeedbackSummary.rating5 * 5 + 
        challengeFeedbackSummary.rating4 * 4 + 
        challengeFeedbackSummary.rating3 * 3 + 
        challengeFeedbackSummary.rating2 * 2 + 
        challengeFeedbackSummary.rating1;
  
        if (totalScore < 1) {
          return 0;
        }
  
        return (Number((totalScore / challengeFeedbackSummary.total).toFixed(2)));
      }

    return 0;
}

export const getAverageDifficulty = (challengeFeedbackSummary: ChallengeFeedbackSummary | undefined): number => {
    if (challengeFeedbackSummary) {
      const totalScore =
        challengeFeedbackSummary.difficulty5 * 5 +
        challengeFeedbackSummary.difficulty4 * 4 +
        challengeFeedbackSummary.difficulty3 * 3 +
        challengeFeedbackSummary.difficulty2 * 2 +
        challengeFeedbackSummary.difficulty1;

      if (totalScore < 1) {
        return 0;
      }

      return (Number((totalScore / challengeFeedbackSummary.total).toFixed(2)));
    }

    return 0;
  };

export const getLearningSomethingNewPercentage = (challengeFeedbackSummary: ChallengeFeedbackSummary | undefined): number => {
    if (challengeFeedbackSummary) {
      const totalLearnings = challengeFeedbackSummary.learnedSomethingNew + challengeFeedbackSummary.didNotLearnSomethingNew;
      const percentage = (challengeFeedbackSummary.learnedSomethingNew / totalLearnings) * 100;

      return (Number(percentage.toFixed(0)));
    }

    return 0;
  };