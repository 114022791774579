import {
  ExpandableSection,
  FormField,
  Grid,
  Header,
  Input,
  Multiselect,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { Event } from '../../../types/Event';
import { useUser } from '../../../store/user.context';
import { EditEventActions, useEditEvent } from '../../../store/edit-event.context';
import { InputWithIcon } from '../InputWithIcon';
import { SupportedRegionsDefinitionsWithID } from '../../challenges/challengesCommon/ChallengeOptionDefinitions';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../../utils/i18n.utils';
import { Campaign } from '../../../types/Campaign';
import { EditCampaignActions, useEditCampaign } from '../../../store/edit-campaign.context';

interface LabAvailabiltyProps {
  target?: Event | Campaign;
}

const LabAvailabilty: React.FC<LabAvailabiltyProps> = ({ target }) => {
  const { user } = useUser();
  const { t } = useTranslation();
  const { editMode, editedEvent, handleUpdateEditEvent } = useEditEvent();
  const { campaignEditMode, editedCampaign, handleUpdateEditCampaign } = useEditCampaign();
  const [selectedIncludeRegionOptions, setSelectedIncludeRegionOptions] = useState<readonly OptionDefinition[]>([]);
  const [selectedExcludeRegionOptions, setSelectedExcludeRegionOptions] = useState<readonly OptionDefinition[]>([]);
  const isEvent = target instanceof Event;
  const canEdit = editMode || campaignEditMode;
  const targetSettings = isEvent ? target : target?.campaignSettings;
  const editedTarget = isEvent ? editedEvent : editedCampaign?.campaignSettings;

  const generateValuesFromOptionDefinitions = (options: readonly OptionDefinition[]) => {
    const newRegionList: string[] = [];
    options.forEach((region: OptionDefinition) => {
      if (region.value) {
        newRegionList.push(region.value);
      }
    });
  };

  const handleSelectExludeRegionOptions = (options: readonly OptionDefinition[]) => {
    setSelectedExcludeRegionOptions(options);
    if (isEvent) {
      handleUpdateEditEvent(EditEventActions.REGION_DENYLIST, generateValuesFromOptionDefinitions(options));
    } else {
      handleUpdateEditCampaign(EditCampaignActions.REGION_DENYLIST, generateValuesFromOptionDefinitions(options));
    }
  };

  const handleSelectIncludeRegionOptions = (options: readonly OptionDefinition[]) => {
    setSelectedIncludeRegionOptions(options);
    if (isEvent) {
      handleUpdateEditEvent(EditEventActions.REGION_ALLOWLIST, generateValuesFromOptionDefinitions(options));
    } else {
      handleUpdateEditCampaign(EditCampaignActions.REGION_ALLOWLIST, generateValuesFromOptionDefinitions(options));
    }
  };

  const getExcludeRegionsPlaceholder = (): string => {
    const placeholderText = 'regions';
    let filters = '';
    if (selectedExcludeRegionOptions) {
      const selectedLabels = selectedExcludeRegionOptions.map((filter) => filter.value);
      filters = selectedLabels.join(', ');
    }
    return filters || placeholderText;
  };

  const getIncludeRegionsPlaceholder = (): string => {
    const placeholderText = 'regions';
    let filters = '';
    if (selectedIncludeRegionOptions) {
      const selectedLabels = selectedIncludeRegionOptions.map((filter) => filter.value);
      filters = selectedLabels.join(', ');
    }
    return filters || placeholderText;
  };

  const generateRegionSelectedOptions = (allow: boolean) => {
    const list = allow ? 'regionAllowlist' : 'regionDenylist';
    if (editedTarget) {
      const regions: OptionDefinition[] = [];
      editedTarget[list].forEach((region) => {
        const foundRegion = SupportedRegionsDefinitionsWithID.find(
          (supportedRegion) => supportedRegion.value === region
        );
        if (foundRegion) {
          regions.push(foundRegion);
        }
      });
      if (allow) {
        setSelectedIncludeRegionOptions([...regions]);
      } else {
        setSelectedExcludeRegionOptions([...regions]);
      }
    }
  };

  useEffect(() => {
    generateRegionSelectedOptions(true);
    generateRegionSelectedOptions(false);
  }, [canEdit]);

  return (
    <React.Fragment>
      {user?.isEventAdmin && (
        <ExpandableSection
          variant="container"
          header={
            <Header variant="h2">{t(i18nKeys.events.eventDetails.labels.labAvailability.labAvailability.label)}</Header>
          }>
          {canEdit && (
            <React.Fragment>
              {isEvent && !editedEvent?.isCampaignEvent && (
                <SpaceBetween direction="vertical" size="s">
                  <FormField label={t(i18nKeys.events.eventDetails.labels.labAvailability.minimumDeployedLabs)}>
                    <InputWithIcon
                      value={editedEvent?.labAutoScaleMinPercent.toString() || '0'}
                      icon={<span>%</span>}
                      disabled={!target?.canEditAttribute('labAutoScaleMinPercent', user) || false}
                      onChange={(value) => {
                        handleUpdateEditEvent(EditEventActions.LAB_AUTO_SCALE_MIN_PERCENTAGE, Number(value));
                      }}
                    />
                  </FormField>
                  <FormField label={t(i18nKeys.events.eventDetails.labels.labAvailability.labAvailability.label)}>
                    <InputWithIcon
                      value={editedEvent?.warmupOffsetHours.toString() || '0'}
                      disabled={!target?.canEditAttribute('warmupOffsetHours', user)}
                      icon={<span>{t(i18nKeys.general.hours)}</span>}
                      onChange={(value) => {
                        handleUpdateEditEvent(EditEventActions.WARMUP_OFFSET_HOURS, Number(value));
                      }}
                    />
                    {t(i18nKeys.events.eventDetails.labels.labAvailability.labAvailability.formPartOne)}
                    <InputWithIcon
                      value={editedEvent?.labExtensionHours.toString() || '0'}
                      icon={<span>{t(i18nKeys.general.hours)}</span>}
                      disabled={!target?.canEditAttribute('labExtensionHours', user)}
                      onChange={(value) => {
                        handleUpdateEditEvent(EditEventActions.LAB_EXTENSION_HOURS, Number(value));
                      }}
                    />
                    {t(i18nKeys.events.eventDetails.labels.labAvailability.labAvailability.formPartTwo)}
                  </FormField>
                  <hr />
                </SpaceBetween>
              )}
              {!isEvent && canEdit && (
                <FormField label={t(i18nKeys.campaigns.labels.campaignDetails.labTimeout)}>
                  <span>
                    {t(i18nKeys.campaigns.labels.campaignDetails.timeoutHoursPrefix)}
                    <Input
                      type="number"
                      className="numeric-input"
                      value={editedCampaign?.campaignSettings.labTimeoutHours?.toString() || '0'}
                      onChange={({ detail }) =>
                        handleUpdateEditCampaign(EditCampaignActions.LAB_TIMEOUT, Number(detail.value))
                      }
                    />
                    {t(i18nKeys.campaigns.labels.campaignDetails.timeoutHoursSuffix)}
                  </span>
                </FormField>
              )}
              <SpaceBetween direction="vertical" size="s">
                <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }]}>
                  <FormField label={t(i18nKeys.events.eventDetails.labels.labAvailability.includeRegions)}>
                    <Multiselect
                      placeholder={getIncludeRegionsPlaceholder()}
                      selectedOptions={selectedIncludeRegionOptions}
                      disabled={!target?.canEditAttribute('regionAllowlist', user)}
                      options={SupportedRegionsDefinitionsWithID}
                      onChange={({ detail }) => handleSelectIncludeRegionOptions(detail.selectedOptions)}
                      selectedAriaLabel={t(i18nKeys.general.selected)}
                    />
                  </FormField>
                  <FormField label={t(i18nKeys.events.eventDetails.labels.labAvailability.excludeRegions)}>
                    <Multiselect
                      placeholder={getExcludeRegionsPlaceholder()}
                      selectedOptions={selectedExcludeRegionOptions}
                      disabled={!target?.canEditAttribute('regionDenylist', user)}
                      options={SupportedRegionsDefinitionsWithID}
                      onChange={({ detail }) => handleSelectExludeRegionOptions(detail.selectedOptions)}
                      selectedAriaLabel={t(i18nKeys.general.selected)}
                    />
                  </FormField>
                </Grid>
              </SpaceBetween>
            </React.Fragment>
          )}
          {!canEdit && (
            <React.Fragment>
              {isEvent && !target?.isCampaignEvent && (
                <React.Fragment>
                  <div className="section-first-row">
                    <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
                      <div className="secondary-text">
                        {t(i18nKeys.events.eventDetails.labels.labAvailability.minimumDeployedLabs)}
                      </div>
                      <div>{`${target?.labAutoScaleMinPercent}%`}</div>
                    </Grid>
                  </div>
                  <div className="grey-section-divider-top" style={{ paddingBottom: '18px' }}>
                    <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
                      <div className="secondary-text">
                        {t(i18nKeys.events.eventDetails.labels.labAvailability.labAvailability.label)}
                      </div>
                      <div>
                        {t(i18nKeys.events.eventDetails.labels.labAvailability.labAvailability.message, {
                          warmUpOffsetHours: target?.warmupOffsetHours,
                          labExtensionHours: target?.labExtensionHours,
                        })}
                      </div>
                    </Grid>
                  </div>
                </React.Fragment>
              )}
              {!isEvent && !canEdit && (
                <div className="section-first-row">
                  <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
                    <div className="secondary-text">{t(i18nKeys.campaigns.labels.campaignDetails.labTimeout)}</div>
                    <div>
                      {t(i18nKeys.campaigns.labels.campaignDetails.timeoutHours, {
                        hours: target?.campaignSettings.labTimeoutHours,
                      })}
                    </div>
                  </Grid>
                </div>
              )}
              <div
                className={
                  (isEvent && !target?.isCampaignEvent) || !isEvent ? 'grey-section-divider-top' : 'section-first-row'
                }
                style={{ paddingBottom: '18px' }}>
                <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
                  <div className="secondary-text">
                    {t(i18nKeys.events.eventDetails.labels.labAvailability.includeRegions)}
                  </div>
                  <div>
                    {targetSettings?.regionAllowlist && targetSettings?.regionAllowlist.length > 0
                      ? targetSettings?.regionAllowlist.join(', ')
                      : t(i18nKeys.events.eventDetails.messages.thisListIsEmpty)}
                  </div>
                </Grid>
              </div>
              <div className="grey-section-divider-top" style={{ paddingBottom: '18px' }}>
                <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
                  <div className="secondary-text">
                    {t(i18nKeys.events.eventDetails.labels.labAvailability.excludeRegions)}
                  </div>
                  <div>
                    {targetSettings?.regionDenylist && targetSettings?.regionDenylist.length > 0
                      ? targetSettings?.regionDenylist.join(', ')
                      : t(i18nKeys.events.eventDetails.messages.thisListIsEmpty)}
                  </div>
                </Grid>
              </div>
            </React.Fragment>
          )}
        </ExpandableSection>
      )}
    </React.Fragment>
  );
};
export default LabAvailabilty;
