import { Badge, Box, SpaceBetween, Link, ColumnLayout, Icon } from '@amzn/awsui-components-react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useChallenges } from '../../../store/challenge.context';
import { ChallengeBoard } from '../../../types/ChallengeBoard';
import { i18nKeys } from '../../../utils/i18n.utils';
import DifficultyIndicator from '../../challenges/challengesCommon/difficultyIndiciator/DifficultyIndicator';

interface GameBoardGridProps {
  gameBoard: ChallengeBoard;
}

const GameBoardGrid: React.FC<GameBoardGridProps> = ({ gameBoard }) => {
  const { getChallengeListItemFromChallengeId } = useChallenges();
  const { t } = useTranslation();
  const gridBoardRef = useRef<HTMLDivElement>(null);

  return (
    <React.Fragment>
      <SpaceBetween direction="vertical" size="s">
        <Box float="right">
          <SpaceBetween direction="horizontal" size="s">
            <div className="inline">
              <strong>{t(i18nKeys.challenges.gameBoard.labels.totalPoints)}</strong>{' '}
              <Badge color="grey">{gameBoard.totalPoints}</Badge>
            </div>
          </SpaceBetween>
        </Box>
        <div ref={gridBoardRef}>
          <Box className="game-board-box" padding="s">
            <ColumnLayout columns={gameBoard.numCategories}>
              {gameBoard.columns.map((column, i) => {
                /** Map out column headers */
                return (
                  <SpaceBetween direction="vertical" size="s" key={`game-board-column-${i}`}>
                    <Box
                      padding="m"
                      key={`game-board-column-${i}-title`}
                      className="game-board-card"
                      textAlign="center">
                      <strong>{column.title}</strong>
                    </Box>
                    {column.challengeIds.map((challenge, index) => {
                      // Map out challenge boxes
                      const challengeItem = getChallengeListItemFromChallengeId(challenge);
                      return (
                        <Box
                          key={`game-board-column-${i}-item-${index}`}
                          padding="m"
                          textAlign="center"
                          className="game-board-challenge-card">
                          <SpaceBetween direction="vertical" size="s" className="game-board-card-content">
                            <h1>{gameBoard.scores[index]}</h1>
                            {(challengeItem?.props.title && (
                              <div>
                                {challengeItem?.props.title} <Link external href={`/challenges/${challengeItem?.id}`} />
                              </div>
                            )) ||
                              challengeItem?.challengeId || (
                                <Badge color="red">
                                  <SpaceBetween direction="horizontal" size="xs">
                                    <Icon name="status-warning" />
                                    <strong>{t(i18nKeys.general.empty)}</strong>
                                  </SpaceBetween>
                                </Badge>
                              )}
                            {challengeItem && <DifficultyIndicator challenge={challengeItem} />}
                            {challengeItem && (
                              <Badge color="blue">
                                <strong>{challengeItem.props.category}</strong>
                              </Badge>
                            )}
                          </SpaceBetween>
                        </Box>
                      );
                    })}
                  </SpaceBetween>
                );
              })}
            </ColumnLayout>
          </Box>
        </div>
      </SpaceBetween>
    </React.Fragment>
  );
};
export default GameBoardGrid;
