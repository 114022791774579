/* eslint-disable @typescript-eslint/no-unsafe-argument */
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Home } from './components/root/Home';
import EventList from './components/events/EventList';
import EventDetails from './components/events/EventDetails';
import ChallengeList from './components/challenges/ChallengeList';
import ChallengeDetails from './components/challenges/ChallengeDetails';
import { i18nKeys, withI18NPrefix } from './utils/i18n.utils';
import { Account } from './components/root/Account/Account';
import CreateChallenge from './components/challenges/CreateChallenge';
import NewEvent from './components/events/NewEvent';
import Documentation from './components/documentation/Documentation';
import { OAuthRedirect } from './components/root/OAuthRedirect';
import { RoutePath } from './RoutePath';
import { AuthRole } from '@amzn/aws-jam-constants';
import UsagePlanList from './components/usage-plan/UsagePlanList';
import CreateUsagePlan from './components/usage-plan/create-usage-plan/CreateUsagePlan';
import UsagePlanDetails from './components/usage-plan/usage-plan-details/UsagePlanDetails';
import ChallengesInReview from './components/challenges/ChallengesInReview';
import PrizeRedemption from './components/prize-redemption/PrizeRedemption';
import ChallengeSetListItems from './components/challenges/challengeSets/ChallengeSetList';
import ChallengeSetDetails from './components/challenges/challengeSets/ChallengeSetDetails';
import CampaignList from './components/campaigns/CampaignList';
import NewCampaign from './components/campaigns/NewCampaign';
import CampaignDetails from './components/campaigns/CampaignDetails';
import Settings from './components/Settings/Settings';
import { isArray } from 'lodash';
import GroupDetails from './components/campaigns/CampaignSections/Groups/GroupDetails';
import CreateChallengeSet from './components/challenges/challengeSets/CreateChallengeSet';

export interface RouteItem {
  path: string;
  name: string;
  requiredGroups?: AuthRole[];
  Component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  exact?: boolean;
  entityType?: 'event' | 'challenge' | 'challengeSet' | 'usagePlan' | 'campaign' | 'group';
}

export class ResolvableRoute {
  public static readonly TOKEN = '{token}';

  public constructor(private readonly path: string) {
    if (!path.includes(ResolvableRoute.TOKEN)) {
      throw new Error(`Path '${path}' is non-resolvable`);
    }
  }

  replaceTokens = (tokens: string[]) => {
    let newPath = this.path;
    tokens.forEach((token) => {
      newPath = newPath.replace(ResolvableRoute.TOKEN, token);
    });
    return newPath;
  };

  public resolve(tokenParams: string | string[]): string {
    if (isArray(tokenParams)) {
      return this.replaceTokens(tokenParams);
    } else {
      return this.path.replaceAll(ResolvableRoute.TOKEN, tokenParams);
    }
  }

  public wildcard(): string {
    return this.resolve('*');
  }
}

export const CHALLENGE_DETAILS_ROUTES = {
  Summary: new ResolvableRoute(`/challenges/${ResolvableRoute.TOKEN}`),
  LearningOutcome: new ResolvableRoute(`/challenges/${ResolvableRoute.TOKEN}/learning-outcome`),
  Settings: new ResolvableRoute(`/challenges/${ResolvableRoute.TOKEN}/settings`),
  Tasks: new ResolvableRoute(`/challenges/${ResolvableRoute.TOKEN}/tasks`),
  Assets: new ResolvableRoute(`/challenges/${ResolvableRoute.TOKEN}/assets`),
  IamPolicy: new ResolvableRoute(`/challenges/${ResolvableRoute.TOKEN}/iam`),
  CfnTemplate: new ResolvableRoute(`/challenges/${ResolvableRoute.TOKEN}/cfn`),
  NextSteps: new ResolvableRoute(`/challenges/${ResolvableRoute.TOKEN}/next-steps`),
  Wiki: new ResolvableRoute(`/challenges/${ResolvableRoute.TOKEN}/wiki`),
  Collaborators: new ResolvableRoute(`/challenges/${ResolvableRoute.TOKEN}/collaborators`),
  Issues: new ResolvableRoute(`/challenges/${ResolvableRoute.TOKEN}/issues`),
  Comments: new ResolvableRoute(`/challenges/${ResolvableRoute.TOKEN}/comments`),
  Testing: new ResolvableRoute(`/challenges/${ResolvableRoute.TOKEN}/testing`),
  Feedback: new ResolvableRoute(`/challenges/${ResolvableRoute.TOKEN}/feedback`),
  Stats: new ResolvableRoute(`/challenges/${ResolvableRoute.TOKEN}/stats`),
  Revisions: new ResolvableRoute(`/challenges/${ResolvableRoute.TOKEN}/revisions`),
  Reviews: new ResolvableRoute(`/challenges/${ResolvableRoute.TOKEN}/review/`),
  Translation: new ResolvableRoute(`/challenges/${ResolvableRoute.TOKEN}/translation`),
  FacilitatorNotes: new ResolvableRoute(`/challenges/${ResolvableRoute.TOKEN}/facilitator-notes`),
  Help: new ResolvableRoute(`/challenges/${ResolvableRoute.TOKEN}/help`),
} as const;

export const EVENT_DETAILS_ROUTES = {
  Summary: new ResolvableRoute(`/events/${ResolvableRoute.TOKEN}`),
  Challenges: new ResolvableRoute(`/events/${ResolvableRoute.TOKEN}/challenges`),
  Settings: new ResolvableRoute(`/events/${ResolvableRoute.TOKEN}/settings`),
  Comments: new ResolvableRoute(`/events/${ResolvableRoute.TOKEN}/comments`),
  Participants: new ResolvableRoute(`/events/${ResolvableRoute.TOKEN}/participants`),
  Labs: new ResolvableRoute(`/events/${ResolvableRoute.TOKEN}/labs`),
  Feedback: new ResolvableRoute(`/events/${ResolvableRoute.TOKEN}/feedback`),
  Revisions: new ResolvableRoute(`/events/${ResolvableRoute.TOKEN}/revisions`),
  Report: new ResolvableRoute(`/events/${ResolvableRoute.TOKEN}/report`),
} as const;

export const CHALLENGE_SETS_ROUTES = {
  Summary: new ResolvableRoute(`/challenges/sets/${ResolvableRoute.TOKEN}`),
};

export const CAMPAIGN_DETAILS_ROUTES = {
  Summary: new ResolvableRoute(`/campaigns/${ResolvableRoute.TOKEN}`),
  Challenges: new ResolvableRoute(`/campaigns/${ResolvableRoute.TOKEN}/challenges`),
  Settings: new ResolvableRoute(`/campaigns/${ResolvableRoute.TOKEN}/settings`),
  AdvancedSettings: new ResolvableRoute(`/campaigns/${ResolvableRoute.TOKEN}/advanced-settings`),
  Groups: new ResolvableRoute(`/campaigns/${ResolvableRoute.TOKEN}/groups`),
  NewGroup: new ResolvableRoute(`/campaigns/${ResolvableRoute.TOKEN}/groups/new`),
  GroupDetails: new ResolvableRoute(`/campaigns/${ResolvableRoute.TOKEN}/groups/${ResolvableRoute.TOKEN}`),
  Comments: new ResolvableRoute(`/campaigns/${ResolvableRoute.TOKEN}/comments`),
};

export interface ParameterizedRouteItem extends RouteItem {
  resolvePath: (...params: string[]) => string;
}

export const homeRoute: RouteItem = {
  path: RoutePath.ROOT,
  name: withI18NPrefix(i18nKeys.home.title),
  exact: true,
  Component: Home,
};

export const oauth2Route: RouteItem = {
  path: RoutePath.OAUTH2,
  name: 'Oauth',
  exact: true,
  Component: OAuthRedirect,
};

export const eventsRoute: RouteItem = {
  path: RoutePath.EVENTS,
  name: withI18NPrefix(i18nKeys.events.title),
  exact: true,
  Component: EventList,
};

export const newEventRoute: RouteItem = {
  path: RoutePath.NEW_EVENT,
  name: withI18NPrefix(i18nKeys.newEvent.title),
  exact: true,
  entityType: 'event',
  Component: NewEvent,
};

export const eventRoute: ParameterizedRouteItem = {
  path: RoutePath.EVENT_DETAILS,
  name: withI18NPrefix(i18nKeys.events.event),
  exact: false,
  Component: EventDetails,
  entityType: 'event',
  resolvePath: (...params) => `/events/${params[0] || ''}*`,
};

export const newCampaignRoute: RouteItem = {
  path: RoutePath.NEW_CAMPAIGN,
  name: withI18NPrefix(i18nKeys.campaigns.newCampaign.title),
  exact: true,
  Component: NewCampaign,
};

export const campaignsRoute: RouteItem = {
  path: RoutePath.CAMPAIGNS,
  name: withI18NPrefix(i18nKeys.campaigns.title),
  exact: true,
  Component: CampaignList,
};

export const campaignRoute: ParameterizedRouteItem = {
  path: RoutePath.CAMPAIGN_DETAILS,
  name: withI18NPrefix(i18nKeys.campaigns.campaignDetails.title),
  exact: false,
  entityType: 'campaign',
  resolvePath: (...params) => `/campaigns/${params[0] || ''}*`,
  Component: CampaignDetails,
};

export const groupRoute: ParameterizedRouteItem = {
  path: RoutePath.CAMPAIGN_GROUP_DETAILS,
  name: withI18NPrefix(i18nKeys.campaigns.headers.groups.group),
  exact: false,
  entityType: 'group',
  resolvePath: (...params) => `/campaigns/${params[0] || ''}/groups/${params[1] || ''}`,
  Component: GroupDetails,
};

export const challengesRoute: RouteItem = {
  path: RoutePath.CHALLENGES,
  name: withI18NPrefix(i18nKeys.challenges.title),
  exact: true,
  Component: ChallengeList,
};

export const challengeSetsRoute: RouteItem = {
  path: RoutePath.CHALLENGE_SETS,
  name: withI18NPrefix(i18nKeys.challenges.challengeSets),
  exact: true,
  Component: ChallengeSetListItems,
};

export const challengeSetRoute: ParameterizedRouteItem = {
  path: RoutePath.CHALLENGE_SET_DETAILS,
  name: withI18NPrefix(i18nKeys.challenges.challengeSets),
  exact: false,
  Component: ChallengeSetDetails,
  entityType: 'challengeSet',
  resolvePath: (...params) => `/challenges/sets/${params[0] ?? ''}*`,
};

export const createChallengeSetRoute: RouteItem = {
  path: RoutePath.NEW_CHALLENGE_SET,
  name: withI18NPrefix(i18nKeys.challenges.challengeSet.header.createChallengeSet),
  exact: true,
  Component: CreateChallengeSet,
};

// TODO: add required groups
export const reviewChallengesRoute: RouteItem = {
  path: RoutePath.REVIEW_CHALLENGES,
  name: withI18NPrefix(i18nKeys.challenges.reviewChallenges.title),
  exact: true,
  Component: ChallengesInReview,
};

export const challengeRoute: ParameterizedRouteItem = {
  path: RoutePath.CHALLENGE_DETAILS,
  name: withI18NPrefix(i18nKeys.challenges.challenge),
  exact: false,
  Component: ChallengeDetails,
  entityType: 'challenge',
  resolvePath: (...params) => `/challenges/${params[0] ?? ''}*`,
};

export const createChallengeRoute: RouteItem = {
  path: RoutePath.NEW_CHALLENGE,
  name: withI18NPrefix(i18nKeys.challenges.createChallenge),
  exact: true,
  Component: CreateChallenge,
};

export const accountRoute: RouteItem = {
  path: RoutePath.ACCOUNT,
  name: withI18NPrefix(i18nKeys.account.title),
  exact: true,
  Component: Account,
};

export const usagePlansRoute: RouteItem = {
  path: RoutePath.USAGE_PLANS,
  name: withI18NPrefix(i18nKeys.usagePlan.title),
  exact: true,
  Component: UsagePlanList,
};

export const createUsagePlanRoute: RouteItem = {
  path: RoutePath.NEW_USAGE_PLAN,
  name: withI18NPrefix(i18nKeys.usagePlan.createUsagePlan),
  exact: true,
  Component: CreateUsagePlan,
};

export const usagePlanDetailsRoute: ParameterizedRouteItem = {
  path: RoutePath.USAGE_PLAN_DETAILS,
  name: withI18NPrefix(i18nKeys.usagePlan.sections.details),
  exact: true,
  Component: UsagePlanDetails,
  entityType: 'usagePlan',
  resolvePath: (...params) => `/usage-plans/${params[0] || ''}`,
};

export const prizeRedemptionRoute: RouteItem = {
  path: RoutePath.PRIZES,
  name: withI18NPrefix(i18nKeys.prizeRedemption.title),
  exact: true,
  Component: PrizeRedemption,
};

export const documentationRoute: RouteItem = {
  path: RoutePath.DOCUMENTATION,
  name: withI18NPrefix(i18nKeys.documentation.title),
  exact: true,
  Component: Documentation,
};

export const settingsRoute: RouteItem = {
  path: RoutePath.SETTINGS,
  name: withI18NPrefix(i18nKeys.settings.title),
  exact: true,
  Component: Settings,
};

export const routes: RouteItem[] = [
  homeRoute,
  oauth2Route,
  eventsRoute,
  newEventRoute,
  eventRoute,
  newCampaignRoute,
  campaignsRoute,
  campaignRoute,
  challengesRoute,
  challengeSetsRoute,
  createChallengeSetRoute,
  challengeSetRoute,
  createChallengeRoute,
  reviewChallengesRoute,
  challengeRoute,
  accountRoute,
  usagePlansRoute,
  createUsagePlanRoute,
  usagePlanDetailsRoute,
  prizeRedemptionRoute,
  documentationRoute,
  settingsRoute,
];

export const rootRoute = routes.find((r) => r.path === '/');
export const resolve404Path = (from: string) => `/404?from=${encodeURIComponent(from)}`;

export default routes;
