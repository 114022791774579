import * as React from 'react';
import { useState } from 'react';
import {
  Alert,
  Badge,
  Box,
  Button,
  Form,
  FormField,
  Grid,
  Header,
  Input,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { useUser } from '../../../store/user.context';
import './Account.scss';
import { useApi } from '../../../store/api.context';
import { useComponentDidMountEffect } from '../../../hooks/useComponentDidMountEffect';
import { phoneNumberIsValid } from '../../../utils/validation.utils';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../../utils/i18n.utils';
import { IdentityProvider, IdpToProviderLabel } from '../../../types/IdentityProvider';
import { CognitoUserModel, CustomUserStatus, UserStatuses } from '../../../types/cognito';
import { preProdLogger } from '../../../utils/log.utils';

export const Account: React.FC = () => {
  const { user } = useUser();
  const { accountApi } = useApi();
  const { t } = useTranslation();
  const [accountInfo, setAccountInfo] = useState<CognitoUserModel>();
  // TODO: Update verificationCode, phoneNumber, originalPhoneNumber to use Nullable<String> once added
  const [verificationCode, setVerificationCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [originalPhoneNumber, setOriginalPhoneNumber] = useState('');

  // default states will be false unless set otherwise
  const [phoneNumberVerified, setPhoneNumberVerified] = useState<boolean>(false);
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [awaitingPhoneVerificationCode, setAwaitingPhoneVerificationCode] = useState(false);

  useComponentDidMountEffect(async () => {
    await getAccountInfo();
  });

  const getAccountInfo = async () => {
    setAccountInfo(await accountApi.getUserAccountInfo());
    setPhoneNumber(accountInfo?.userAttributes.phone_number as string);
    setPhoneNumberVerified(accountInfo?.userAttributes.phone_number_verified as boolean);
    setOriginalPhoneNumber(phoneNumber);
    const isMfaEnabled = accountInfo?.isMFAEnabled ? accountInfo.isMFAEnabled : false;
    setMfaEnabled(isMfaEnabled);
  };

  const enable = () => {
    void accountApi.setMFAEnabled(true);
    void getAccountInfo();
  };

  const disable = () => {
    void accountApi.setMFAEnabled(false);
    void getAccountInfo();
  };

  const updateUser = () => {
    accountApi
      .updateUser(phoneNumber)
      .then(({ status }) => {
        if (status === 'phoneVerificationSent') {
          setAwaitingPhoneVerificationCode(true);
          setVerificationCode('');
        }
      })
      .catch((err) => preProdLogger(err));

    void getAccountInfo();
  };

  const verifyPhoneNumber = () => {
    void accountApi.verifyPhoneNumber(verificationCode);
    setAwaitingPhoneVerificationCode(false);
    void getAccountInfo();
  };

  /**
   * Get the idp label to display taking role spoofing into account
   */
  const getUserIdpLabel = () => {
    if (user?.isAmazonian) {
      return IdpToProviderLabel[IdentityProvider.AmazonFederate];
    }

    if (user?.isTCUser) {
      return IdpToProviderLabel[IdentityProvider.TC];
    }

    return null;
  };

  return (
    <Box>
      <Header variant="h1" className={'account-details-title'}>
        {t(i18nKeys.account.details)}
      </Header>
      <div className={'primary-account-details'} key={'primary-account-details'}>
        {user && (
          <Grid gridDefinition={[{ colspan: { l: 2 } }, { colspan: { l: 10 } }]}>
            <strong key={'user-id'}>{t(i18nKeys.account.userId)}:</strong>
            <SpaceBetween direction="horizontal" size="xxs" key={'user-provider'}>
              <span>{user.name}</span>

              <span>
                {getUserIdpLabel() ? (
                  <React.Fragment>
                    <span> {t(i18nKeys.account.via)} </span> <strong> {getUserIdpLabel()} </strong>
                  </React.Fragment>
                ) : (
                  ''
                )}
              </span>
            </SpaceBetween>
          </Grid>
        )}

        {user && (
          <Grid gridDefinition={[{ colspan: 2 }, { colspan: 10 }]}>
            <strong key={'email'}>{t(i18nKeys.account.email)}:</strong>
            <div key={'user-email'}>{user.email}</div>
          </Grid>
        )}
      </div>

      <hr />

      {accountInfo && (
        <Box className={'account-info-section'}>
          <Header variant="h3" className={'mfa-header'}>
            {t(i18nKeys.account.mfa.title)}
          </Header>
          {accountInfo.userStatus !== 'CONFIRMED' && (
            <Box>
              <Alert visible={accountInfo.userStatus !== CustomUserStatus.EXTERNAL_PROVIDER}>
                <strong>{t(i18nKeys.account.mfa.internalProviderWarning)}</strong>
              </Alert>
              <Alert visible={accountInfo.userStatus === CustomUserStatus.EXTERNAL_PROVIDER}>
                <strong>{t(i18nKeys.account.mfa.externalProviderWarning)}</strong>
              </Alert>
            </Box>
          )}
        </Box>
      )}

      {accountInfo?.userStatus === 'CONFIRMED' && (
        <Form>
          <Alert visible={!phoneNumberVerified}>{t(i18nKeys.account.phoneNumberForm.formWarning)}.</Alert>
          <FormField
            label={t(i18nKeys.account.phoneNumberForm.phoneNumberField.label)}
            description={t(i18nKeys.account.phoneNumberForm.phoneNumberField.description)}>
            <Input
              value={phoneNumber}
              onChange={(event) => setPhoneNumber(event.detail.value)}
              placeholder={'+19995551212'}
              name={'phoneNumber'}
              id={'phoneNumber'}
            />
            <label htmlFor={'phoneNumber'}>
              {phoneNumber && (
                <span>
                  {phoneNumberVerified && originalPhoneNumber === phoneNumber && (
                    <Badge color={'green'}>{t(i18nKeys.account.badge.verified)}</Badge>
                  )}
                  {phoneNumberVerified && originalPhoneNumber === phoneNumber && mfaEnabled && (
                    <Badge color={'green'}>{t(i18nKeys.account.badge.mfaEnabled)}</Badge>
                  )}
                  {!(phoneNumberVerified && originalPhoneNumber === phoneNumber) && (
                    <Badge color={'red'}>{t(i18nKeys.account.badge.mfaDisabled)}</Badge>
                  )}
                </span>
              )}
            </label>
          </FormField>
          <Alert visible={phoneNumber !== null && !phoneNumberIsValid(phoneNumber)} type={'warning'}>
            {t(i18nKeys.account.phoneNumberForm.phoneNumberField.warning)}
          </Alert>
          {(!phoneNumberVerified || originalPhoneNumber !== phoneNumber) && (
            <Button onClick={updateUser} variant={'primary'}>
              {originalPhoneNumber && originalPhoneNumber === phoneNumber
                ? t(i18nKeys.account.phoneNumberForm.buttons.send)
                : t(i18nKeys.account.phoneNumberForm.buttons.update)}
            </Button>
          )}

          {originalPhoneNumber !== phoneNumber && (
            <Button onClick={() => setPhoneNumber(originalPhoneNumber)} variant={'link'}>
              {t(i18nKeys.general.cancel)}
            </Button>
          )}

          {!awaitingPhoneVerificationCode &&
            !phoneNumberVerified &&
            originalPhoneNumber &&
            originalPhoneNumber === phoneNumber && (
              <Button onClick={() => setAwaitingPhoneVerificationCode(true)}>
                {t(i18nKeys.account.phoneNumberForm.buttons.verificationCode)}
              </Button>
            )}
        </Form>
      )}

      {awaitingPhoneVerificationCode && (
        <Form>
          <FormField
            label={t(i18nKeys.account.phoneNumberForm.verificationCodeField.label)}
            description={t(i18nKeys.account.phoneNumberForm.verificationCodeField.description)}>
            <Input
              value={verificationCode}
              onChange={(event) => setVerificationCode(event.detail.value)}
              placeholder={t(i18nKeys.account.phoneNumberForm.verificationCodeField.label)}
              name={'verificationCode'}
              id={'verificationCode'}
            />
          </FormField>
          <Button onClick={verifyPhoneNumber} variant={'primary'}>
            {t(i18nKeys.general.verify)}
          </Button>

          <Button
            onClick={() => {
              setAwaitingPhoneVerificationCode(false);
            }}
            variant={'link'}>
            {t(i18nKeys.general.cancel)}
          </Button>
        </Form>
      )}

      {accountInfo?.userStatus === UserStatuses.CONFIRMED &&
        phoneNumberVerified &&
        originalPhoneNumber === phoneNumber &&
        !mfaEnabled && (
          <Button variant={'primary'} onClick={enable}>
            {t(i18nKeys.account.mfa.enable)}
          </Button>
        )}

      {accountInfo?.userStatus === UserStatuses.CONFIRMED &&
        phoneNumberVerified &&
        originalPhoneNumber === phoneNumber &&
        mfaEnabled && (
          <Button variant={'normal'} onClick={disable}>
            {t(i18nKeys.account.mfa.disable)}
          </Button>
        )}
    </Box>
  );
};
