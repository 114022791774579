import React, { useCallback, memo } from 'react'
import { Button, SpaceBetween} from '@amzn/awsui-components-react'

import './ThemeToggle.scss'

export type ThemeMode = 'light'  | 'dark'

export interface IThemeToggleProps {
  /** current selected theme light or dark */
  mode: ThemeMode
  /** on theme change handler */
  onToggleTheme: (mode: ThemeMode) => void
}

export const ThemeToggle = memo<IThemeToggleProps>(({mode, onToggleTheme}: IThemeToggleProps) => {

  const handleThemeToggle = useCallback((selectedMode: ThemeMode) => {
    onToggleTheme(selectedMode)
  }, [onToggleTheme])

  return (
    <div className='theme-toggle'>
      <SpaceBetween direction="horizontal" size="xs">
        <Button data-active={ mode === 'light'} variant='link' onClick={handleThemeToggle.bind(this, 'light')}>Light Mode</Button>
        <Button data-active={ mode === 'dark'} variant='link' onClick={handleThemeToggle.bind(this, 'dark')}>Dark Mode</Button>
      </SpaceBetween>
    </div>
  )
})

ThemeToggle.displayName = 'ThemeToggle'