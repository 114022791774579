/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty-function */
import _ from 'lodash';
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import {
  Challenge,
  ChallengeConfiguration,
  ChallengeDescriptor,
  ChallengeGlobalStatistics,
  ChallengeListItem,
  ChallengeReview,
  ChallengeReviewableSection,
  ChallengeReviewStatus,
  ChallengeUtils,
  ChallengeWarningResponse,
  ChallengeWrapper,
  cloneChallenge,
  cloneGlobalStats,
  IamPolicyValidationResponse,
  TemplateScannerResponse,
} from '../types/Challenge';
import { ChallengeSet } from '../types/ChallengeSet';
import { Nullable, NullableString } from '../types/common';
import { DEFAULT_LAB_PROVIDER } from '../types/LabProvider';
import { callIgnoringExceptionsAsync } from '../utils/call-ignoring-exceptions';
import { fromPlainObject } from '../utils/mapper.utils';
import { useApi } from './api.context';
import { safeFilterNulls } from '../utils/list.utils';
import { S3Object } from '../types/s3-object';
import { ChallengeBoard, ChallengeBoardPosition } from '../types/ChallengeBoard';

export type GetChallenge = (id: string) => Promise<void>;
export type SetChallenge = (challenge: Challenge) => void;
export type GetFullChallenge = (id: string) => void;
export type GetChallengeResources = (challengeId: string) => void;
export type GetChallenges = (includeArchived: boolean, forceLoadChallenges: boolean, silent: boolean) => Promise<void>;
export type GetChallengeConfiguration = () => void;
export type UpdateChallengeDescriptor = (cd: ChallengeDescriptor) => void;
export type UpdateChallengeDescriptors = (cd: { [id: string]: ChallengeDescriptor }) => void;
export type UpdateGameBoardPositions = (newGameBoardPositions: {
  [key: string]: Nullable<ChallengeBoardPosition>;
}) => void;
export type AddChallengeComment = (commentValue: string) => Promise<void>;
export type UpdateChallengeComment = (commentId: string, commentValue: string) => Promise<void>;
export type DeleteChallengeComment = (commentId: string) => Promise<void>;
export type GetChallengeListItemFromChallengeId = (challengeId: string) => Nullable<ChallengeListItem>;
export type CopyChallengeDescriptorsFromTarget = (
  challengeDescriptors: ChallengeDescriptor[],
  gameBoard?: ChallengeBoard
) => void;
export type GetChallengeDescriptorsToCopy = (
  currentChallengeDescriptors: ChallengeDescriptor[],
  selectedChallenges?: ChallengeListItem[]
) => ChallengeDescriptor[];
export type GetChallengeDescriptor = (
  challengeItem: ChallengeListItem,
  gameBoard?: ChallengeBoard
) => Nullable<ChallengeDescriptor>;
export type DeployTestEvent = (
  challengeId: string,
  labProvider: string,
  pinnedRegion: NullableString,
  silent?: boolean
) => void;
export type RedeployTestEvent = (challengeId: string, silent?: boolean) => void;
export type UpdateOneClickEventTesters = (challengeId: string, testers: string[], silent?: boolean) => void;
export type TerminateTestEvent = (challengeId: string, silent?: boolean) => void;
export type ExtendTestEvent = (challengeId: string, hours: number, silent?: boolean) => void;
export type UpdatePublicStatus = (challengeId: string, isPublic: boolean, silent?: boolean) => void;
export type UpdateDemoStatus = (challengeId: string, isDemo: boolean, silent?: boolean) => void;
export type ArchiveChallenge = (challengeId: string, silent?: boolean) => void;
export type UnarchiveChallenge = (challengeId: string, silent?: boolean) => void;
export type DeleteChallenge = (challengeId: string, silent?: boolean) => void;
export type ReadyForReview = (comment: string) => void;
export type BeginReview = () => void;
export type CancelReview = () => void;
export type RejectReview = () => void;
export type ApproveChallenge = (approvalWarnings: ChallengeWarningResponse, comment: string) => void;
export type ValidateCfnTemplate = (cfnTemplate: string) => Promise<string>;
export type ValidateIamPolicy = (iamPolicy: string) => Promise<IamPolicyValidationResponse>;
export type GetChallengeSets = () => void;
export type GetChallengeDescriptors = (challengeIds: string[]) => ChallengeDescriptor[];
export type GetChallengeDescriptorsFromSet = (challengeSetId: string) => ChallengeDescriptor[];
export type GetReview = (silent: boolean) => void;
export type UpdateReview = (
  comment: string,
  section: ChallengeReviewableSection,
  status: ChallengeReviewStatus
) => Promise<void>;
export type SubmitReview = () => Promise<void>;

export interface ChallengesContentValue {
  challenges: Challenge[] | undefined;
  challenge: Challenge | undefined;
  challengeResources: S3Object[];
  fullChallenge: ChallengeWrapper | undefined;
  challengeConfig: ChallengeConfiguration | undefined;
  challengeWrappers: ChallengeWrapper[] | undefined;
  challengeTitles: { [challengeId: string]: string };
  challengeListItems: ChallengeListItem[] | undefined;
  challengeWrapperMap: { [id: string]: ChallengeWrapper };
  challengeDescriptors: { [id: string]: ChallengeDescriptor } | undefined;
  gameBoardPositions: { [key: string]: Nullable<ChallengeBoardPosition> } | undefined;
  challengeSets: ChallengeSet[] | undefined;
  wrapperMapInitalized: boolean;
  challengesInReview: ChallengeListItem[] | undefined;
  testEventName: NullableString;
  currentReview: ChallengeReview | undefined;
  getChallenge: GetChallenge;
  setChallenge: SetChallenge;
  getFullChallenge: GetFullChallenge;
  getChallengeResources: GetChallengeResources;
  getChallenges: GetChallenges;
  getChallengeConfiguration: GetChallengeConfiguration;
  updateChallengeDescriptor: UpdateChallengeDescriptor;
  updateChallengeDescriptors: UpdateChallengeDescriptors;
  updateGameBoardPositions: UpdateGameBoardPositions;
  addChallengeComment: AddChallengeComment;
  updateChallengeComment: UpdateChallengeComment;
  deleteChallengeComment: DeleteChallengeComment;
  getChallengeListItemFromChallengeId: GetChallengeListItemFromChallengeId;
  copyChallengeDescriptorsFromTarget: CopyChallengeDescriptorsFromTarget;
  getChallengeDescriptorsToCopy: GetChallengeDescriptorsToCopy;
  getChallengeDescriptor: GetChallengeDescriptor;
  deployTestEvent: DeployTestEvent;
  redeployTestEvent: RedeployTestEvent;
  updateOneClickEventTesters: UpdateOneClickEventTesters;
  terminateTestEvent: TerminateTestEvent;
  extendTestEvent: ExtendTestEvent;
  updatePublicStatus: UpdatePublicStatus;
  updateDemoStatus: UpdateDemoStatus;
  archiveChallenge: ArchiveChallenge;
  unarchiveChallenge: UnarchiveChallenge;
  deleteChallenge: DeleteChallenge;
  readyForReview: ReadyForReview;
  beginReview: BeginReview;
  cancelReview: CancelReview;
  rejectReview: RejectReview;
  approveChallenge: ApproveChallenge;
  validateCfnTemplate: ValidateCfnTemplate;
  validateIamPolicy: ValidateIamPolicy;
  getChallengeSets: GetChallengeSets;
  getChallengeDescriptors: GetChallengeDescriptors;
  getChallengeDescriptorsFromSet: GetChallengeDescriptorsFromSet;
  getReview: GetReview;
  updateReview: UpdateReview;
  submitReview: SubmitReview;
  setCurrentReview: Dispatch<SetStateAction<ChallengeReview | undefined>>;
  reviewLoading: boolean;
  setReviewLoading: Dispatch<SetStateAction<boolean>>;
  reviewMode: boolean;
  setReviewMode: Dispatch<SetStateAction<boolean>>;
}

const ChallengesContent = React.createContext<ChallengesContentValue>({
  challenges: undefined,
  challenge: undefined,
  challengeResources: [],
  fullChallenge: undefined,
  challengeConfig: undefined,
  challengeWrappers: undefined,
  challengeTitles: {},
  challengeListItems: undefined,
  challengeDescriptors: undefined,
  gameBoardPositions: undefined,
  challengeWrapperMap: {},
  wrapperMapInitalized: false,
  challengesInReview: [],
  testEventName: null,
  challengeSets: undefined,
  currentReview: undefined,
  getChallenge: (id: string) => new Promise(() => {}),
  setChallenge: (challenge: Challenge) => {},
  getFullChallenge: (id: string) => {},
  getChallengeResources: (challengeId: string) => {},
  getChallenges: () => new Promise(() => {}),
  getChallengeConfiguration: () => {},
  updateChallengeDescriptor: (challengeDescriptor: ChallengeDescriptor): void => {},
  updateChallengeDescriptors: (challengeDescriptors: { [id: string]: ChallengeDescriptor }): void => {},
  updateGameBoardPositions: (newGameBoardPositions: { [key: string]: Nullable<ChallengeBoardPosition> }): void => {},
  copyChallengeDescriptorsFromTarget: (
    _challengeDescriptors: ChallengeDescriptor[],
    gameBoard?: ChallengeBoard
  ): void => {},
  addChallengeComment: (commentValue: string) => new Promise(() => {}),
  updateChallengeComment: (_commentId: string, commentValue: string) => new Promise(() => {}),
  deleteChallengeComment: (_commentId: string) => new Promise(() => {}),
  getChallengeListItemFromChallengeId: (challengeId: string): Nullable<ChallengeListItem> => {
    return null;
  },
  getChallengeDescriptorsToCopy: (
    currentChallengeDescriptors: ChallengeDescriptor[],
    selectedChallenges?: ChallengeListItem[]
  ) => [],
  getChallengeDescriptor: (challengeItem: ChallengeListItem, gameBoard?: ChallengeBoard) => null,
  deployTestEvent: (
    challengeId: string,
    labProvider: string,
    pinnedRegion: NullableString,
    silent?: boolean
  ): void => {},
  redeployTestEvent: (challengeId: string, silent?: boolean): void => {},
  updateOneClickEventTesters: (challengeId: string, testers: string[], silent?: boolean): void => {},
  terminateTestEvent: (challengeId: string, silent?: boolean): void => {},
  extendTestEvent: (challengeId: string, hours: number, silent?: boolean): void => {},
  updatePublicStatus: (challengeId: string, isPublic: boolean, silent?: boolean): void => {},
  updateDemoStatus: (challengeId: string, isDemo: boolean, silent?: boolean): void => {},
  archiveChallenge: (challengeId: string, silent?: boolean): void => {},
  unarchiveChallenge: (challengeId: string, silent?: boolean): void => {},
  deleteChallenge: (challengeId: string, silent?: boolean): void => {},
  readyForReview: (comment: string): void => {},
  beginReview: (): void => {},
  cancelReview: (): void => {},
  rejectReview: (): void => {},
  approveChallenge: (approvalWarnings: ChallengeWarningResponse, comment: string): void => {},
  validateCfnTemplate: (cfnTemplate: string): any => {},
  validateIamPolicy: (iamPolicy: string): any => {},
  getChallengeSets: () => null,
  getChallengeDescriptors: (): ChallengeDescriptor[] => [],
  getChallengeDescriptorsFromSet: (): ChallengeDescriptor[] => [],
  getReview: () => {},
  updateReview: () => new Promise<void>(() => {}),
  submitReview: () => Promise.resolve(),
  setCurrentReview: () => {},
  reviewLoading: false,
  setReviewLoading: () => {},
  reviewMode: false,
  setReviewMode: () => {},
});

const ChallengesProvider: React.FC = ({ children }) => {
  const [challenges, setChallenges] = useState<Challenge[] | undefined>(undefined);
  const [challenge, setChallenge] = useState<Challenge | undefined>(undefined);
  const [challengeResources, setChallengeResources] = useState<S3Object[]>([]);
  const [fullChallenge, setFullChallenge] = useState<ChallengeWrapper | undefined>(undefined);
  const [challengeConfig, setChallengeConfig] = useState<ChallengeConfiguration | undefined>(undefined);
  const [challengeWrappers, setChallengeWrappers] = useState<ChallengeWrapper[] | undefined>(undefined);
  const [challengeListItems, setChallengeListItems] = useState<ChallengeListItem[] | undefined>(undefined);
  const [challengesInReview, setChallengesInReview] = useState<ChallengeListItem[] | undefined>(undefined);
  const [challengeWrapperMap, setChallengeWrapperMap] = useState<{ [id: string]: ChallengeWrapper }>({});
  const [listItemsCache, setListItemsCache] = useState<{ [id: string]: ChallengeListItem }>({});
  const [challengeDescriptors, setChallengeDescriptors] = useState<{ [challengeId: string]: ChallengeDescriptor }>({});
  const [gameBoardPositions, setGameBoardPositions] = useState<{ [key: string]: Nullable<ChallengeBoardPosition> }>({});
  const [challengeTitles, setChallengeTitles] = useState<{ [id: string]: string }>({});
  const [wrapperMapInitalized, setWrapperMapInitialized] = useState(false);
  const [challengeSets, setChallengeSets] = useState<ChallengeSet[] | undefined>(undefined);
  const [testEventName, setTestEventName] = useState<NullableString>(null);
  const [currentReview, setCurrentReview] = useState<ChallengeReview | undefined>(undefined);
  const { challengesApi } = useApi();
  const [reviewLoading, setReviewLoading] = useState<boolean>(false);
  const [reviewMode, setReviewMode] = useState<boolean>(false);

  useEffect(() => {
    setupChallengeWrapperMap();
  }, [challengeWrappers]);

  useEffect(() => {
    if (ChallengeUtils.isReviewable(currentReview)) {
      setReviewMode(true);
    } else {
      setReviewMode(false);
    }
  }, [currentReview]);

  const getChallenge = async (id: string) => {
    if (!challengeWrappers) {
      await getChallenges();
    }

    await getFullChallenge(id).then(() => setChallenge(challengeWrapperMap[id].latest as Challenge));
  };

  const getFullChallenge = async (id: string) => {
    const challengeRes: ChallengeWrapper | undefined = await challengesApi.getFullChallenge(id);
    setFullChallenge(challengeRes);

    if (challengeRes) {
      setTestEventName(challengeRes.testEventName);
      const cw = fromPlainObject(challengeRes, ChallengeWrapper) as ChallengeWrapper;
      cw.latest = cloneChallenge(cw.latest as Challenge);
      cw.latestApproved = cloneChallenge(cw.latestApproved as Challenge);
      cw.globalStatistics = cw.latest.challengeId
        ? cloneGlobalStats(cw.globalStatistics)
        : new ChallengeGlobalStatistics();
      challengeRes.copyGlobalFlagsTo(cw);
      cw.copyChallengeLevelFlagsToVersions();

      challengeWrapperMap[id] = cw;
      setChallengeWrapperMap(challengeWrapperMap);
    }
  };

  const getChallengeResources = async (challengeId: string) => {
    await challengesApi.getChallengeResources(challengeId).then((resources) => setChallengeResources(resources));
  };

  const getChallenges = async (includeArchived = false, forceLoadChallenges = false, silent = false) => {
    if (!challengeWrappers || forceLoadChallenges) {
      await challengesApi.loadChallenges(silent, includeArchived).then((challengeWrappersResponse) => {
        setChallengeWrappers(challengeWrappersResponse);
        const newChallengeListItems: ChallengeListItem[] = [];
        const newChallengesInReview: ChallengeListItem[] = [];
        challengeWrappersResponse.forEach((challengeWrapper) => {
          const newChallengeListItem = getChallengeListItemFromChallengeWrapper(challengeWrapper);
          if (newChallengeListItem) {
            newChallengeListItems.push(newChallengeListItem);
          }
          const newChallengeInReview = getChallengeInReviewFromChallengeWrapper(challengeWrapper);
          if (newChallengeInReview) {
            newChallengesInReview.push(newChallengeInReview);
          }
        });
        setChallengeListItems(newChallengeListItems);
        setChallengesInReview(newChallengesInReview);
      });

      const challengeList: Challenge[] = [];

      challengeWrappers?.forEach((cw) => {
        if (cw.latest) {
          challengeList.push(cw.latest);
        }
      });

      setChallenges(challengeList);
    }
  };

  const setupChallengeWrapperMap = () => {
    if (challengeWrappers) {
      challengeWrappers.forEach((cw) => {
        if (cw.challengeId) {
          challengeWrapperMap[cw.challengeId] = cw;
          const latestChallengeVersion = cw.latestApproved || cw.latest;
          challengeTitles[cw.challengeId] = latestChallengeVersion?.props.title || '';
          setChallengeTitles(challengeTitles);
        }
      });
      setChallengeWrapperMap(_.cloneDeep(challengeWrapperMap));
      setWrapperMapInitialized(true);
    }
  };

  const getChallengeConfiguration = (): void => {
    void callIgnoringExceptionsAsync(async () => {
      return await challengesApi.getChallengeConfig();
    }).then((challengeConfigResponse: ChallengeConfiguration | undefined) => {
      setChallengeConfig(challengeConfigResponse);
    });
  };

  const addChallengeComment = async (commentValue: string) => {
    if (challenge) {
      return await challengesApi.addComment(challenge, commentValue).then(async () => {
        if (challenge.challengeId) {
          await getChallenge(challenge.challengeId);
        }
      });
    }
  };

  const updateChallengeComment = async (commentId: string, commentValue: string) => {
    if (challenge) {
      return await challengesApi.updateComment(challenge, commentId, commentValue).then(async () => {
        if (challenge.challengeId) {
          await getChallenge(challenge.challengeId);
        }
      });
    }
  };

  const deleteChallengeComment = async (commentId: string) => {
    if (challenge) {
      return await challengesApi.deleteComment(challenge, commentId).then(async () => {
        if (challenge.challengeId) {
          await getChallenge(challenge.challengeId);
        }
      });
    }
  };

  const getChallengeListItemFromChallengeWrapper = (cw: ChallengeWrapper): Nullable<ChallengeListItem> => {
    if (!cw || !cw.latest) {
      return null;
    }
    return new ChallengeListItem(cw.latest, cw.globalStatistics);
  };

  const getChallengeInReviewFromChallengeWrapper = (cw: ChallengeWrapper): Nullable<ChallengeListItem> => {
    if (
      !cw ||
      !cw.latest ||
      !ChallengeUtils.isReviewable(cw.latest) ||
      !cw.latest.barRaiserReviewStatus
    ) {
      return null;
    }
    return new ChallengeListItem(cw.latest, cw.globalStatistics);
  };

  const getChallengeListItemFromChallengeId = (challengeId: string): Nullable<ChallengeListItem> => {
    if (!challengeWrapperMap) {
      return null;
    }

    const key = challengeId;

    if (listItemsCache[key]) {
      return listItemsCache[key];
    }

    const challengeListItem = getChallengeListItemFromChallengeWrapper(challengeWrapperMap[challengeId]);
    if (challengeListItem) {
      listItemsCache[key] = challengeListItem;
      setListItemsCache(listItemsCache);
    }

    return listItemsCache[key];
  };

  const getChallengeByDescriptor = (cd: ChallengeDescriptor): Nullable<Challenge> => {
    if (cd && cd.challengeId) {
      const challengeWrapper: ChallengeWrapper = challengeWrapperMap[cd.challengeId];

      if (!challengeWrapper || !challengeWrapper.latestApproved) {
        return null;
      }
      return challengeWrapper.latestApproved;
    }
    return null;
  };

  const updateChallengeDescriptor = (cd: ChallengeDescriptor): void => {
    if (cd && cd.challengeId) {
      const newChallengeDescriptors: { [challengeId: string]: ChallengeDescriptor } = _.cloneDeep(challengeDescriptors);
      challengeDescriptors[cd.challengeId] = cd;
      setChallengeDescriptors(newChallengeDescriptors);
    }
  };

  const updateChallengeDescriptors = (newChallengeDescriptors: { [id: string]: ChallengeDescriptor }): void => {
    if (newChallengeDescriptors) {
      setChallengeDescriptors(newChallengeDescriptors);
    }
  };

  const updateGameBoardPositions = (newGameBoardPositions: { [key: string]: Nullable<ChallengeBoardPosition> }) => {
    setGameBoardPositions(newGameBoardPositions);
  };

  /**
   * Gets the challenge descriptors from the event and copies to local map.
   */
  const copyChallengeDescriptorsFromTarget = (
    targetChallengeDescriptors: ChallengeDescriptor[],
    gameBoard?: ChallengeBoard
  ) => {
    if (!challengeWrappers) {
      return;
    }
    setChallengeDescriptors({});

    if (targetChallengeDescriptors) {
      targetChallengeDescriptors.forEach((cd: ChallengeDescriptor) => {
        // clone the challenge descriptor to break the reference to the event
        const originalCd: ChallengeDescriptor = cd;
        cd = fromPlainObject(cd, ChallengeDescriptor) as ChallengeDescriptor;

        const challengeFromDescriptor: Nullable<Challenge> = getChallengeByDescriptor(cd);
        if (!challengeFromDescriptor) {
          return;
        }
        if (cd && cd.challengeId) {
          const gameBoardPosition: Nullable<ChallengeBoardPosition> = gameBoardPositions[cd.challengeId] || null;
          if (gameBoardPosition && gameBoard) {
            // always override the score for a game board challenge
            if (gameBoardPosition.row) {
              cd.overrides.score = gameBoard.scores[gameBoardPosition.row];
            }

            // always use latest approved version for a game board challenge
            cd.version = ChallengeDescriptor.LATEST_APPROVED_VERSION;

            // set the challenge as not a warmup (but only if not overridden yet)
            if (cd.overrides.challengeAlwaysOn == null) {
              cd.overrides.challengeAlwaysOn = false;
            }
          }
        }

        // update the challenge descriptor on the event to be aware of the defaultLabProvider configured for the challenge
        originalCd.defaultLabProvider = challengeFromDescriptor.props.defaultLabProvider || DEFAULT_LAB_PROVIDER;
        cd.defaultLabProvider = originalCd.defaultLabProvider;

        if (cd.overrides.difficulty == null) {
          cd.overrides.difficulty = challengeFromDescriptor.props.difficulty;
        }
        if (cd.overrides.challengeAlwaysOn == null) {
          cd.overrides.challengeAlwaysOn = challengeFromDescriptor.props.challengeAlwaysOn;
        }
        if (challengeFromDescriptor.challengeId) {
          const challengeDescriptorsCopy = challengeDescriptors;
          challengeDescriptorsCopy[challengeFromDescriptor.challengeId] = cd;
          setChallengeDescriptors(challengeDescriptorsCopy);
        }
      });
    }
  };

  const getChallengeDescriptorsToCopy = (
    currentChallengeDescriptors: ChallengeDescriptor[],
    selectedChallenges?: ChallengeListItem[]
  ): ChallengeDescriptor[] => {
    const privateChallenges = (currentChallengeDescriptors || [])
      // if the challenge is null, then the user doesnt have access to it, it is private.
      .filter((cd) => getChallengeByDescriptor(cd) == null);
    const newChallengeDescriptors = (selectedChallenges || []).map((challengeItem: ChallengeListItem) => {
      // clone when pulling from memory, so not to change the UI.
      const cd: ChallengeDescriptor = fromPlainObject(
        getChallengeDescriptor(challengeItem),
        ChallengeDescriptor
      ) as ChallengeDescriptor;

      // clear out overrides if they match the defaults
      if (cd.overrides.difficulty === challengeItem.props.difficulty) {
        cd.overrides.difficulty = null;
      }
      if (cd.overrides.challengeAlwaysOn === challengeItem.props.challengeAlwaysOn) {
        cd.overrides.challengeAlwaysOn = null;
      }
      if (cd.labProvider === cd.defaultLabProvider) {
        cd.labProvider = null;
      }

      return cd;
    });

    return [...newChallengeDescriptors, ...privateChallenges];
  };

  const getChallengeDescriptor = (
    challengeItem: ChallengeListItem,
    gameBoard?: ChallengeBoard
  ): Nullable<ChallengeDescriptor> => {
    if (challengeItem && challengeItem.challengeId) {
      if (!challengeDescriptors[challengeItem.challengeId]) {
        challengeDescriptors[challengeItem.challengeId] = ChallengeDescriptor.fromChallenge(challengeItem);
      }

      const cd: ChallengeDescriptor = challengeDescriptors[challengeItem.challengeId];
      const gameBoardPosition: Nullable<ChallengeBoardPosition> = gameBoardPositions[challengeItem.challengeId] || null;

      if (gameBoardPosition && gameBoard) {
        // always override the score for a game board challenge
        if (gameBoardPosition.row) {
          cd.overrides.score = gameBoard.scores[gameBoardPosition.row];
        }

        // always use latest approved version for a game board challenge
        cd.version = ChallengeDescriptor.LATEST_APPROVED_VERSION;

        // set the challenge as not a warmup (but only if not overridden yet)
        if (cd.overrides.challengeAlwaysOn == null) {
          cd.overrides.challengeAlwaysOn = false;
        }
      }

      if (cd.overrides.difficulty == null) {
        cd.overrides.difficulty = challengeItem.props.difficulty;
      }
      if (cd.overrides.challengeAlwaysOn == null) {
        cd.overrides.challengeAlwaysOn = challengeItem.props.challengeAlwaysOn;
      }

      cd.defaultLabProvider = challengeItem.props.defaultLabProvider || DEFAULT_LAB_PROVIDER;

      // remove display time for warmup challenges
      if (cd.overrides.challengeAlwaysOn) {
        cd.displayTime = null;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        cd.setDateTimeModels();
      }

      return cd;
    }
    return null;
  };

  const getChallengeDescriptors = (challengeIds: string[]) => {
    const cds: ChallengeDescriptor[] = [];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    for (const challengeId of safeFilterNulls(challengeIds) as string[]) {
      let cd = challengeDescriptors[challengeId];
      if (!cd) {
        const challengeItem = getChallengeListItemFromChallengeId(challengeId);
        if (!challengeItem) continue; // this means it's an invalid ID; skip this id
        cd = challengeDescriptors[challengeId] = ChallengeDescriptor.fromChallenge(challengeItem);
      }
      cds.push(cd);
    }
    return cds;
  };

  const deployTestEvent = async (
    challengeId: string,
    labProvider: string,
    pinnedRegion: NullableString = null,
    silent = false
  ) => {
    await challengesApi
      .deployTestEvent(challengeId, labProvider, pinnedRegion, silent)
      .then(async (cw: ChallengeWrapper) => {
        setFullChallenge(cw);
        if (cw.latest) {
          await getChallenge(cw.challengeId || '');
          setTestEventName(cw.testEventName);
        }
      });
  };

  const updatePublicStatus = async (challengeId: string, isPublic: boolean, silent = false) => {
    await challengesApi.updatePublicStatus(challengeId, isPublic, silent).then(async (cw: ChallengeWrapper) => {
      if (cw.latest) {
        await getChallenge(cw.challengeId || '');
        setTestEventName(cw.testEventName);
      }
    });
  };

  const redeployTestEvent = async (challengeId: string, silent = false) => {
    await challengesApi.redeployTestEvent(challengeId, silent).then(async (cw: ChallengeWrapper) => {
      if (cw.latest) {
        await getChallenge(cw.challengeId || '');
        setTestEventName(cw.testEventName);
      }
    });
  };

  const updateOneClickEventTesters = async (challengeId: string, testers: string[], silent = false) => {
    await challengesApi.updateOneClickEventTesters(challengeId, testers, silent).then(async (cw: ChallengeWrapper) => {
      if (cw.latest) {
        await getChallenge(cw.challengeId || '');
        setTestEventName(cw.testEventName);
      }
    });
  };

  const terminateTestEvent = async (challengeId: string, silent = false) => {
    await challengesApi.terminateTestEvent(challengeId, silent).then(async (cw: ChallengeWrapper) => {
      setTestEventName(null);
      if (cw.latest) {
        await getChallenge(cw.challengeId || '');
        setTestEventName(cw.testEventName);
      }
    });
  };

  const extendTestEvent = async (challengeId: string, hours: number, silent = false) => {
    await challengesApi.extendTestEvent(challengeId, hours, silent).then(async (cw: ChallengeWrapper) => {
      if (cw.latest) {
        await getChallenge(cw.challengeId || '');
        setTestEventName(cw.testEventName);
      }
    });
  };

  const updateDemoStatus = async (challengeId: string, isDemo: boolean, silent = false) => {
    await challengesApi.updateDemoStatus(challengeId, isDemo, silent).then(async (cw: ChallengeWrapper) => {
      if (cw.latest) {
        await getChallenge(cw.challengeId || '');
      }
    });
  };

  const archiveChallenge = async (challengeId: string, silent = false) => {
    await challengesApi.archiveChallenge(challengeId, silent).then(async (cw: ChallengeWrapper) => {
      if (cw.latest) {
        await getChallenge(cw.challengeId || '');
      }
    });
  };

  const unarchiveChallenge = async (challengeId: string, silent = false) => {
    await challengesApi.unarchiveChallenge(challengeId, silent).then(async (cw: ChallengeWrapper) => {
      if (cw.latest) {
        await getChallenge(cw.challengeId || '');
      }
    });
  };

  const deleteChallenge = async (challengeId: string, silent = false) => {
    await challengesApi.deleteChallenge(challengeId, silent).then(async (cw: ChallengeWrapper) => {
      if (cw.latest) {
        await getChallenge(cw.challengeId || '');
      }
    });
  };

  const readyForReview = async (comment: string) => {
    if (challenge) {
      await challengesApi.readyForReview(challenge, comment).then(async (cw: ChallengeWrapper) => {
        if (cw.latest) {
          await getChallenge(cw.challengeId || '');
        }
      });
    }
  };

  const beginReview = async () => {
    setReviewLoading(true);
    if (challenge) {
      await challengesApi
        .beginReview(challenge)
        .then(async (cw: ChallengeWrapper) => {
          await getChallenge(cw.challengeId || '');
        })
        .then(() => getReview())
        .finally(() => setReviewLoading(false));
    }
  };

  const cancelReview = async () => {
    setReviewLoading(true);
    if (challenge) {
      await challengesApi
        .cancelReview(challenge)
        .then(async (cw: ChallengeWrapper) => {
          await getChallenge(cw.challengeId || '');
        })
        .then(() => setCurrentReview(undefined))
        .finally(() => setReviewLoading(false));
    }
  };

  const rejectReview = async () => {
    if (challenge) {
      await challengesApi.rejectReview(challenge, false).then(async (cw: ChallengeWrapper) => {
        await getChallenge(cw.challengeId || '');
      });
    }
  };

  const approveChallenge = async (approvalWarnings: ChallengeWarningResponse, comment: string) => {
    if (challenge) {
      await challengesApi
        .approveReview(challenge, approvalWarnings.warningToken, comment)
        .then(async (cw: ChallengeWrapper) => {
          await getChallenge(cw.challengeId || '');
        });
    }
  };

  const getReview = async (silent = false) => {
    if (challenge) {
      await challengesApi
        .getReview(challenge, silent)
        .then((review: ChallengeReview) => {
          setCurrentReview(review);
        })
        .catch(() => {
          setCurrentReview(undefined);
        });
    }
  };

  const updateReview = async (comment: string, section: ChallengeReviewableSection, status: ChallengeReviewStatus) => {
    if (challenge) {
      await challengesApi.updateReview(challenge, comment, section, status, false).then((review: ChallengeReview) => {
        setCurrentReview(review);
      });
    }
  };

  const submitReview = async () => {
    if (challenge) {
      await challengesApi
        .submitReview(challenge, false)
        .then(() => {
          setCurrentReview(undefined);
        })
        .then(() => getChallenge(challenge?.challengeId || ''));
    }
  };

  const validateCfnTemplate = async (cfnSnippet: string) => {
    const res: TemplateScannerResponse = await challengesApi.validateCfnTemplate(cfnSnippet, true);
    return res.result;
  };

  const validateIamPolicy = async (iamPolicy: string) => {
    return await challengesApi.validateIamPolicy(iamPolicy, false);
  };

  const getChallengeSets = () => {
    void callIgnoringExceptionsAsync(async () => {
      return await challengesApi.findAllChallengeSets();
    }).then((challengeSetRes: ChallengeSet[] | undefined) => {
      setChallengeSets(challengeSetRes);
    });
  };

  const getChallengeDescriptorsFromSet = (challengeSetId: string) => {
    const challengeSet = challengeSets?.find((cs: ChallengeSet) => cs.id === challengeSetId);
    return getChallengeDescriptors(challengeSet?.challengeIds || []);
  };

  const data: ChallengesContentValue = {
    challenges,
    challenge,
    challengeResources,
    fullChallenge,
    challengeConfig,
    challengeWrappers,
    challengeTitles,
    challengeListItems,
    challengeWrapperMap,
    challengeDescriptors,
    challengeSets,
    wrapperMapInitalized,
    challengesInReview,
    testEventName,
    currentReview,
    getChallengeListItemFromChallengeId,
    getChallenge,
    setChallenge,
    getFullChallenge,
    getChallengeResources,
    getChallenges,
    getChallengeConfiguration,
    updateChallengeDescriptor,
    updateChallengeDescriptors,
    gameBoardPositions,
    updateGameBoardPositions,
    copyChallengeDescriptorsFromTarget,
    addChallengeComment,
    updateChallengeComment,
    deleteChallengeComment,
    getChallengeDescriptorsToCopy,
    getChallengeDescriptor,
    deployTestEvent,
    redeployTestEvent,
    updateOneClickEventTesters,
    terminateTestEvent,
    extendTestEvent,
    updatePublicStatus,
    updateDemoStatus,
    archiveChallenge,
    unarchiveChallenge,
    deleteChallenge,
    readyForReview,
    beginReview,
    cancelReview,
    rejectReview,
    approveChallenge,
    validateCfnTemplate,
    validateIamPolicy,
    getChallengeSets,
    getChallengeDescriptors,
    getChallengeDescriptorsFromSet,
    getReview,
    updateReview,
    submitReview,
    setCurrentReview,
    reviewLoading,
    setReviewLoading,
    reviewMode,
    setReviewMode,
  };

  return <ChallengesContent.Provider value={data}>{children}</ChallengesContent.Provider>;
};

const useChallenges = () => {
  const context = useContext(ChallengesContent);
  if (context === undefined) {
    throw new Error('useChallenges can only be used inside ChallengesProvider');
  }
  return context;
};

export { ChallengesProvider, useChallenges };
