/* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, Link, Route, Switch } from 'react-router-dom';
import { PageHeader } from '../common/PageHeader';
import { Button, ButtonDropdown, Container, Icon, Input, SpaceBetween, Textarea, ContentLayout } from '@amzn/awsui-components-react';
import { Columns } from '../common/Columns';
import { Column } from '../common/Column';
import { KeyValue } from '../common/KeyValue';
import { LoadingBar } from '../common/LoadingBar';
import { eventRoute, EVENT_DETAILS_ROUTES, resolve404Path, eventsRoute } from '../../routes';
import { Event, EventStatus, getEventListStatus } from '../../types/Event';
import { i18nKeys } from '../../utils/i18n.utils';
import { useEvents } from '../../store/events.context';
import { useComponentDidMountEffect } from '../../hooks/useComponentDidMountEffect';
import { StatusBadge } from '../common/StatusBadge';
import { DateTime, DateTimeKeys, TimezoneFormat } from '../common/DateTime';
import EventDetailsSummary from './eventDetailsSections/EventDetailsSummary';
import { ConfirmModal } from '../common/ConfirmModal';
import { useEffect, useState } from 'react';
import { EditEventActions, useEditEvent } from '../../store/edit-event.context';
import Comments from '../common/Comments/Comments';
import { ChangeRequestPendingStatus, Comment, RequestUnapprovedStatus } from '../../types/common';
import EventDetailsAdvancedSettings from './eventDetailsSections/EventDetailsAdvancedSettings';
import { useChallenges } from '../../store/challenge.context';
import ChangeRequestSummary from '../common/ChangeRequestSummary';
import { useUser } from '../../store/user.context';
import { usePlans } from '../../store/usage-plan/usage-plan.context';
import ChallengeTabs from '../common/Challenges/ChallengeTabs';
import { ChallengeDescriptor, ChallengeWrapper } from '../../types/Challenge';
import { useToolPanel } from '../../store/tool-panel.context';
import Revisions from '../common/Revisions/Revisions';
import { Participants } from '../common/Participants/Participants';
import Report from './eventDetailsSections/Report/Report';
import Feedback from './eventDetailsSections/Feedback/Feedback';
import { CloneEvent, generateTemplateUrl } from '../../utils/url.utils';
import { getEmptyEventPermissions } from '../../utils/event-campaign-common.utils';
import _ from 'lodash';
import * as common from '../../types/common';
import { preProdLogger } from '../../utils/log.utils';
import { Badge } from '@amzn/awsui-components-react';
import '../../styles/tags.scss';
import { getGameUIUrl } from '../../utils/jam-urls';

// eslint-disable-next-line no-shadow
export enum SAVE_ACTIONS {
  UPDATE_CHANGE_REQUEST = 'update-change-request',
  UPDATE_EVENT_REQUEST = 'update-event-request',
  SUBMIT_CHANGE_REQUEST = 'submit-change-request',
}

// eslint-disable-next-line no-shadow
enum ActionButtonNames {
  CANCEL_CHANGE_REQUEST = 'cancel-change-request',
  CANCEL_EVENT = 'cancel-event',
  APPROVE_CHANGE_REQUEST = 'approve-change-request',
  DENY_CHANGE_REQUEST = 'deny-change-request',
  CANCEL_EVENT_REQUEST = 'cancel-event-request',
  APPROVE_EVENT_REQUEST = 'approve-event-request',
  DENY_EVENT_REQUEST = 'deny-event-request',
  CLONE_EVENT = 'clone-event',
  UNLOCK_CHALLENGES = 'unlock-challenges',
  LOCK_CHALLENGES = 'lock-challenges',
  DELETE_EVENT = 'delete-event',
  ACTIONS = 'actions',
  RESET_EVENT = 'reset-event',
  RESET_EVENT_TEAMS = 'reset-event-teams',
}

const EventDetails: React.FC = () => {
  const { t } = useTranslation();
  const { eventName }: { eventName: string } = useParams();
  const history = useHistory();
  const {
    event,
    getEventByName,
    unlockChallengesForEvent,
    lockChallengesForEvent,
    cancelEvent,
    deleteEvent,
    addEventComment,
    updateEventComment,
    deleteEventComment,
    loadConfig,
    eventConfig,
    approveJamEventRequest,
    denyJamEventRequest,
    cancelJamEventRequest,
    approveJamEventChangeRequest,
    denyJamEventChangeRequest,
    cancelJamEventChangeRequest,
    resetEvent,
    resetEventTeams,
    cleanUpEvent,
  } = useEvents();
  const { getChallengeConfiguration } = useChallenges();
  const [confirmCancelVisible, setConfirmCancelVisible] = useState(false);
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const [confirmApproveEventRequestVisible, setConfirmApproveEventRequestVisible] = useState(false);
  const [confirmDenyEventRequestVisible, setConfirmDenyEventRequestVisible] = useState(false);
  const [confirmCancelEventRequestVisible, setConfirmCancelEventRequestVisible] = useState(false);
  const [confirmCancelChangeRequestVisible, setConfirmCancelChangeRequestVisible] = useState(false);
  const [confirmApproveChangeRequestVisible, setConfirmApproveChangeRequestVisible] = useState(false);
  const [confirmDenyChangeRequestVisible, setConfirmDenyChangeRequestVisible] = useState(false);
  const [confirmResetEventVisible, setConfirmResetEventVisible] = useState(false);
  const [confirmResetTeamsVisible, setConfirmResetTeamsVisible] = useState(false);
  const [userEmptyPermissionsVisible, setUserEmptyPermissionsVisible] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [typeOfSave, setTypeOfSave] = useState<common.Nullable<SAVE_ACTIONS>>(null);
  const {
    editMode,
    toggleEditMode,
    initializeEditEvent,
    editedEvent,
    bulkHandleUpdateEditEvent,
    hasChanges,
    saveUpdatedEvent,
  } = useEditEvent();
  const { wrapperMapInitalized, challengeWrapperMap } = useChallenges();
  // const { usagePlans, loadPlans } = usePlans();
  const { user } = useUser();
  const { toggleChallengeInfo } = useToolPanel();
  const [actionDropDownItems, setActionDropDownItems] = useState<{ text: string; id: string; disabled: boolean }[]>([]);
  const [approveEventRequestID, setApproveEventRequestId] = useState('');
  const [eventRequestComment, setEventRequestComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // events will be undefined if not yet initialized from an api response, forces reload of event if new event in context
  const loading = !event || event?.name !== eventName;

  let eventComments: Comment[] = [];
  // Comments are in FIFO format, so reversing them puts the newest on top
  if (event?.comments) {
    eventComments = [...event?.comments].reverse();
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  useComponentDidMountEffect(async () => {
    // if (!usagePlans || !usagePlans.length) {
    //   loadPlans();
    // }
    if (loading) {
      getEventByName(eventName);
      loadConfig();
      getChallengeConfiguration();
    }
  });

  useEffect(() => {
    if (event?.challengeDescriptors) {
      handleGenerateWarnings(event, event?.challengeDescriptors);
    }
  }, [wrapperMapInitalized]);

  /**
   * Cleans up event context on component dismount
   */
  useEffect(() => {
    return () => {
      cleanUpEvent();
    };
  }, []);

  /**
   * Generate event permission based actions once event has loaded
   */
  useEffect(() => {
    if (event) {
      generateDropdownItems();
    }
  }, [event]);

  const handleChallengeAction = (payload: ChallengeDescriptor[]) => {
    if (editedEvent && editMode) {
      const actions = [];
      actions.push({
        action: EditEventActions.PER_CHALLENGE_BACKUP_CHALLENGES,
        payload: editedEvent.backupChallengeConfig?.perChallengeBackups,
      });
      actions.push({ action: EditEventActions.CHALLENGE_DESCRIPTORS, payload });
      bulkHandleUpdateEditEvent(actions);
      handleGenerateWarnings(editedEvent, payload);
    }
  };

  const handleGenerateWarnings = (eventToScan: Event, challengeDescriptors: ChallengeDescriptor[]) => {
    const selectedChallengeWrapperMap: { [key: string]: ChallengeWrapper } = {};
    challengeDescriptors.forEach((challengeDescriptor: ChallengeDescriptor) => {
      if (challengeDescriptor.challengeId) {
        selectedChallengeWrapperMap[challengeDescriptor.challengeId] =
          challengeWrapperMap[challengeDescriptor.challengeId];
      }
    });
    eventToScan?.generateWarnings(selectedChallengeWrapperMap);
  };

  if (loading) {
    return <LoadingBar />;
  }

  if (history && event == null) {
    history.push(resolve404Path(eventRoute.resolvePath(eventName)));
    return null;
  }

  const renderChildView = () => {
    return (
      <Switch>
        <Route path={EVENT_DETAILS_ROUTES.Comments.wildcard()}>
          <Comments
            comments={eventComments}
            addNewComment={addEventComment}
            deleteComment={deleteEventComment}
            updateComment={updateEventComment}
          />
        </Route>
        <Route path={EVENT_DETAILS_ROUTES.Participants.wildcard()}>
          <Participants target={event} />
        </Route>
        <Route path={EVENT_DETAILS_ROUTES.Feedback.wildcard()}>
          <Feedback event={editedEvent || event} />
        </Route>
        <Route path={EVENT_DETAILS_ROUTES.Settings.wildcard()}>
          <EventDetailsAdvancedSettings event={editedEvent || event} />
        </Route>
        <Route path={EVENT_DETAILS_ROUTES.Challenges.wildcard()}>
          <ChallengeTabs
            target={editedEvent || event}
            challengeDescriptors={editedEvent?.challengeDescriptors || event?.challengeDescriptors}
            action={EditEventActions.CHALLENGE_DESCRIPTORS}
            handleChallengeAction={handleChallengeAction}
            toggleChallengeInfo={toggleChallengeInfo}
          />
        </Route>
        <Route path={EVENT_DETAILS_ROUTES.Revisions.wildcard()}>
          <Revisions changes={event.changeHistory} />
        </Route>
        <Route path={EVENT_DETAILS_ROUTES.Report.wildcard()}>
          <Report event={event} />
        </Route>
        {/* TODO: Remove once all pages have been implemented */}
        <Route path={'/events/*/*'}>
          <p>Not implemented</p>
        </Route>
        {/* Summary must be last */}
        <Route path={EVENT_DETAILS_ROUTES.Summary.wildcard()}>
          <EventDetailsSummary event={editedEvent || event} />
        </Route>
      </Switch>
    );
  };

  const handleCancelEvent = () => {
    void cancelEvent(eventName, cancelReason);
    setConfirmCancelVisible(false);
  };

  const handleDeleteEvent = async () => {
    setConfirmDeleteVisible(false);
    await deleteEvent(eventName);

    if (history) {
      history.push(eventsRoute.path);
    }
  };

  const handleToggleEdit = () => {
    if (event) {
      initializeEditEvent(event);
    }
    toggleEditMode();
  };

  const generateCloneEvent = () => {
    const eventDetailsToClone: CloneEvent = {
      title: event.title || null,
      notes: event.notes || null,
      agreementId: event.agreementId || null,
      tags: event.tags.join(',') || null,
      owners: event.owners.join(',') || null,
      facilitators: event.getFacilitators().join(',') || null,
      skillbuilderSubs: event.validSkillBuilderSubscriptions || null,
      challenges:
        event.challengeDescriptors
          .map((challengeDescriptor: ChallengeDescriptor) => challengeDescriptor.challengeId)
          .join(',') || null,
    };
    return generateTemplateUrl(eventDetailsToClone, false);
  };

  const cloneEvent = () => {
    // when cloning an event, have the page update the query params and load the cloned event
    const cloneEventurl = generateCloneEvent() || '/events/new';
    history.replace(cloneEventurl);
  };

  const alertEmptyEventPermissions = () => {
    if (editedEvent) {
      const userNoPermissions = getEmptyEventPermissions(editedEvent);
      if (userNoPermissions.length > 0) {
        setUserEmptyPermissionsVisible(true);
        return;
      }
    }
    return true;
  };

  const saveEvent = (saveType: SAVE_ACTIONS, byPassAlert = false) => {
    if (editMode && user && eventConfig && hasChanges(user, eventConfig, event)) {
      setTypeOfSave(saveType);
      if (!byPassAlert) {
        const proceed = alertEmptyEventPermissions();
        if (!proceed) {
          return;
        }
      }
      setTypeOfSave(null);
      setUserEmptyPermissionsVisible(false);
      setIsLoading(true);
      saveUpdatedEvent(saveType)
        .then(() => {
          setIsLoading(false);
        })
        .catch((err) => {
          preProdLogger('Error saving event', err.message);
        });
    }
  };

  const getPendingEventChanges = () => {
    return user && eventConfig ? event.getPendingChanges(user, eventConfig) : [];
  };

  const shouldShowButton = (buttonName: string) => {
    if (!event) {
      return false;
    }
    if (event.isCampaignEvent) {
      return false;
    }
    switch (buttonName) {
      case ActionButtonNames.CANCEL_CHANGE_REQUEST:
        // Can cancel a change request as long as the request is still in progress.
        return !user?.isEventAdmin && event.approved && event.pendingChangeRequest;

      case ActionButtonNames.CANCEL_EVENT:
        return (
          (!!user &&
            event.isEventOwner(user) &&
            !user.isEventAdmin &&
            event.approved &&
            !event.pendingChangeRequest &&
            !event.ended &&
            !event.isClone &&
            !event.oneClickTestEvent) ||
          (user?.isEventAdmin && !event.isClone && event.approved)
        );

      case ActionButtonNames.APPROVE_CHANGE_REQUEST:
        // Can approve a change request as long as it is not yet resolved.
        return user?.isEventAdmin && event.approved && event.changeRequest != null && !event.changeRequest.resolved;

      case ActionButtonNames.DENY_CHANGE_REQUEST:
        // Can deny a change request as long as it is not yet resolved.
        return user?.isEventAdmin && event.approved && event.changeRequest != null && !event.changeRequest.resolved;

      case ActionButtonNames.CANCEL_EVENT_REQUEST:
        // Can cancel an event request as long as it is not yet resolved.
        return !user?.isEventAdmin && !event.requestResolved;

      case ActionButtonNames.APPROVE_EVENT_REQUEST:
        // Can approve an event request as long as it is not yet resolved.
        return user?.isEventAdmin && !event.requestResolved;

      case ActionButtonNames.DENY_EVENT_REQUEST:
        // Can deny an event request as long as it is not yet resolved.
        return user?.isEventAdmin && !event.requestResolved;

      case ActionButtonNames.UNLOCK_CHALLENGES:
        return (
          user?.isEventAdmin && !event.isCampaignEvent && event.approved && event.status === EventStatus.NOT_STARTED
        );

      case ActionButtonNames.LOCK_CHALLENGES:
        return user?.isEventAdmin && !event.isCampaignEvent && event.approved && event.status === EventStatus.ONGOING;

      case ActionButtonNames.CLONE_EVENT:
        return (
          !!user &&
          event.isEventOwner(user) &&
          event.approved &&
          !event.pendingChangeRequest &&
          !event.oneClickTestEvent &&
          !event.isClone
        );

      case ActionButtonNames.RESET_EVENT:
        return eventConfig?.allowEventReset && user?.isEventAdmin;

      case ActionButtonNames.RESET_EVENT_TEAMS:
        return eventConfig?.allowEventTeamsReset && user?.isEventAdmin;

      case ActionButtonNames.DELETE_EVENT:
        return user && user.isEventAdmin && !event?.isClone;

      default:
        return false;
    }
  };

  const handleActionClick = (clickEvent: any) => {
    switch (clickEvent.detail.id) {
      case ActionButtonNames.UNLOCK_CHALLENGES:
        void unlockChallengesForEvent(eventName);
        break;
      case ActionButtonNames.LOCK_CHALLENGES:
        void lockChallengesForEvent(eventName);
        break;
      case ActionButtonNames.CLONE_EVENT:
        cloneEvent();
        break;
      case ActionButtonNames.CANCEL_EVENT:
        setConfirmCancelVisible(true);
        break;
      case ActionButtonNames.DELETE_EVENT:
        setConfirmDeleteVisible(true);
        break;
      case ActionButtonNames.RESET_EVENT:
        setConfirmResetEventVisible(true);
        break;
      case ActionButtonNames.RESET_EVENT_TEAMS:
        setConfirmResetTeamsVisible(true);
        break;
      default:
        break;
    }
    return;
  };

  const generateDropdownItems = () => {
    const newDropdownItems: { text: string; id: string; disabled: boolean }[] = [];
    if (shouldShowButton(ActionButtonNames.LOCK_CHALLENGES)) {
      newDropdownItems.push({
        text: t(i18nKeys.events.eventDetails.buttons.lockChallenges),
        id: ActionButtonNames.LOCK_CHALLENGES,
        disabled: event.readonly,
      });
    }
    if (shouldShowButton(ActionButtonNames.UNLOCK_CHALLENGES)) {
      newDropdownItems.push({
        text: t(i18nKeys.events.eventDetails.buttons.unlockChallenges),
        id: ActionButtonNames.UNLOCK_CHALLENGES,
        disabled: event.readonly,
      });
    }
    if (shouldShowButton(ActionButtonNames.CLONE_EVENT)) {
      newDropdownItems.push({
        text: t(i18nKeys.events.eventDetails.buttons.cloneEvent),
        id: ActionButtonNames.CLONE_EVENT,
        disabled: event.readonly,
      });
    }

    if (shouldShowButton(ActionButtonNames.CANCEL_EVENT)) {
      newDropdownItems.push({
        text: t(i18nKeys.events.eventDetails.buttons.cancelEvent),
        id: ActionButtonNames.CANCEL_EVENT,
        disabled: event.readonly,
      });
    }
    if (shouldShowButton(ActionButtonNames.DELETE_EVENT)) {
      newDropdownItems.push({
        text: t(i18nKeys.events.eventDetails.buttons.deleteEvent),
        id: ActionButtonNames.DELETE_EVENT,
        disabled: event.readonly,
      });
    }
    if (shouldShowButton(ActionButtonNames.RESET_EVENT)) {
      newDropdownItems.push({
        text: t(i18nKeys.events.eventDetails.buttons.resetEvent),
        id: ActionButtonNames.RESET_EVENT,
        disabled: event.readonly,
      });
    }
    if (shouldShowButton(ActionButtonNames.RESET_EVENT_TEAMS)) {
      newDropdownItems.push({
        text: t(i18nKeys.events.eventDetails.buttons.resetEventTeams),
        id: ActionButtonNames.RESET_EVENT_TEAMS,
        disabled: event.readonly,
      });
    }
    setActionDropDownItems(newDropdownItems);
  };

  /**
   * Format to slug and approve event
   */
  const handleApproveEventRequestClick = () => {
    setApproveEventRequestId(event.title?.replaceAll(' ', '-').toLowerCase() || '');
    setConfirmApproveEventRequestVisible(true);
  };

  const approveEventRequest = () => {
    if (approveEventRequestID) {
      void approveJamEventRequest(event.name, approveEventRequestID, eventRequestComment);
      cleanUpActionModals();
    }
  };

  const denyEventRequest = () => {
    void denyJamEventRequest(event?.name, eventRequestComment);
    cleanUpActionModals();
  };

  const cancelEventRequest = () => {
    void cancelJamEventRequest(event?.name, eventRequestComment);
    cleanUpActionModals();
  };

  const approveChangeRequest = () => {
    void approveJamEventChangeRequest(event?.name, eventRequestComment);
    cleanUpActionModals();
  };

  const denyChangeRequest = () => {
    void denyJamEventChangeRequest(event?.name, eventRequestComment);
    cleanUpActionModals();
  };

  const cancelChangeRequest = () => {
    void cancelJamEventChangeRequest(event?.name, eventRequestComment);
    cleanUpActionModals();
  };

  const handleResetEvent = async () => {
    await resetEvent(event?.name);
    cleanUpActionModals();
  };

  const handleResetEventTeams = async () => {
    await resetEventTeams(event?.name);
    cleanUpActionModals();
  };

  const cleanUpActionModals = () => {
    setApproveEventRequestId('');
    setEventRequestComment('');
    setConfirmDenyEventRequestVisible(false);
    setConfirmApproveEventRequestVisible(false);
    setConfirmCancelEventRequestVisible(false);
    setConfirmCancelChangeRequestVisible(false);
    setConfirmDenyChangeRequestVisible(false);
    setConfirmApproveChangeRequestVisible(false);
    setConfirmResetEventVisible(false);
    setConfirmResetTeamsVisible(false);
  };

  const getSaveButton = () => {
    if (user && eventConfig) {
      switch (getEventListStatus(event)) {
        case ChangeRequestPendingStatus.CHANGE_PENDING:
          return (
            <Button
              className="mr-5"
              variant="primary"
              loading={isLoading}
              disabled={!hasChanges(user, eventConfig, event)}
              onClick={() => saveEvent(SAVE_ACTIONS.UPDATE_CHANGE_REQUEST)}>
              {t(i18nKeys.events.eventDetails.buttons.updateChangeRequest)}
            </Button>
          );
        case RequestUnapprovedStatus.REQUEST_PENDING:
          return (
            <Button
              className="mr-5"
              variant="primary"
              loading={isLoading}
              disabled={!hasChanges(user, eventConfig, event)}
              onClick={() => saveEvent(SAVE_ACTIONS.UPDATE_EVENT_REQUEST)}>
              {t(i18nKeys.events.eventDetails.buttons.updateEventRequest)}
            </Button>
          );
        default:
          return (
            <Button
              className="mr-5"
              variant="primary"
              loading={isLoading}
              disabled={!hasChanges(user, eventConfig, event)}
              onClick={() => saveEvent(SAVE_ACTIONS.SUBMIT_CHANGE_REQUEST)}>
              {t(i18nKeys.events.eventDetails.buttons.createChangeRequest)}
            </Button>
          );
      }
    }
  };

  return (
    <ContentLayout 
    header={      
    <PageHeader
      title={event && event.title}
      buttons={[
        <span key="event-details-button">
          {event?.isCampaignEvent && !editMode && (
            <Button className="mr-5" href={`/campaigns/${event?.campaignId}`}>
              {t(i18nKeys.events.eventDetails.buttons.goToParentCampaign)}
            </Button>
          )}
          {event?.testCloneEventName && !event?.isClone && !editMode && (
            <Button className="mr-5" href={`/events/${event?.testCloneEventName}`}>
              {t(i18nKeys.events.eventDetails.buttons.goToTestEvent)}
            </Button>
          )}
          {!editMode && user && (user.isEventAdmin || event?.isOwner(user) || event?.isEventOwner(user)) && (
            <Button className="mr-5" onClick={() => handleToggleEdit()}>
              {t(i18nKeys.general.edit)}
            </Button>
          )}
          {!editMode && shouldShowButton(ActionButtonNames.CANCEL_CHANGE_REQUEST) && (
            <Button
              className="mr-5"
              onClick={() => setConfirmCancelChangeRequestVisible(true)}
              disabled={event.readonly}>
              {t(i18nKeys.events.eventDetails.buttons.cancelChangeRequest)}
            </Button>
          )}
          {!editMode && shouldShowButton(ActionButtonNames.DENY_CHANGE_REQUEST) && (
            <Button
              className="mr-5"
              onClick={() => setConfirmDenyChangeRequestVisible(true)}
              disabled={event.readonly}>
              {t(i18nKeys.events.eventDetails.buttons.denyChangeRequest)}
            </Button>
          )}
          {!editMode && shouldShowButton(ActionButtonNames.APPROVE_CHANGE_REQUEST) && (
            <Button
              className="mr-5"
              onClick={() => setConfirmApproveChangeRequestVisible(true)}
              disabled={event.readonly}>
              {t(i18nKeys.events.eventDetails.buttons.approveChangeRequest)}
            </Button>
          )}
          {!editMode && shouldShowButton(ActionButtonNames.CANCEL_EVENT_REQUEST) && (
            <Button
              className="mr-5"
              onClick={() => setConfirmCancelEventRequestVisible(true)}
              disabled={event.readonly}>
              {t(i18nKeys.events.eventDetails.buttons.cancelEventRequest)}
            </Button>
          )}
          {!editMode && shouldShowButton(ActionButtonNames.DENY_EVENT_REQUEST) && (
            <Button
              className="mr-5"
              onClick={() => setConfirmDenyEventRequestVisible(true)}
              disabled={event.readonly}>
              {t(i18nKeys.events.eventDetails.buttons.denyEventRequest)}
            </Button>
          )}
          {!editMode && shouldShowButton(ActionButtonNames.APPROVE_EVENT_REQUEST) && (
            <Button className="mr-5" onClick={() => handleApproveEventRequestClick()} disabled={event.readonly}>
              {t(i18nKeys.events.eventDetails.buttons.approveEventRequest)}
            </Button>
          )}
          {editMode && user && (
            <React.Fragment>
              <Button
                className="mr-5"
                variant="link"
                onClick={() => toggleEditMode()}
                loading={isLoading}
                disabled={event.readonly}>
                {t(i18nKeys.general.cancel)}
              </Button>
              {eventConfig && getSaveButton()}
            </React.Fragment>
          )}
          {!editMode && (
            <React.Fragment>
              {actionDropDownItems?.length > 0 && (
                <ButtonDropdown onItemClick={handleActionClick} items={actionDropDownItems}>
                  {t(i18nKeys.events.eventDetails.buttons.actionsPlaceholder)}
                </ButtonDropdown>
              )}
            </React.Fragment>
          )}
        </span>,
      ]}
    />}
    >
      <SpaceBetween size="l">
        <Container>
          <ConfirmModal
            visible={confirmCancelVisible}
            message={
              <React.Fragment>
                {t(i18nKeys.events.eventDetails.messages.cancelEventMessage)}
                <Input
                  className="mt-10"
                  value={cancelReason}
                  onChange={({ detail }) => setCancelReason(detail.value)}
                />
              </React.Fragment>
            }
            cancelBtnLabel={t(i18nKeys.events.eventDetails.buttons.noGoBack)}
            confirmBtnLabel={t(i18nKeys.events.eventDetails.buttons.yesCancelEvent)}
            onConfirm={handleCancelEvent}
            onCancel={() => setConfirmCancelVisible(false)}
          />
          <ConfirmModal
            visible={confirmDeleteVisible}
            message={t(i18nKeys.events.eventDetails.messages.deleteEventMessage)}
            confirmBtnLabel={t(i18nKeys.events.eventDetails.buttons.yesDelete)}
            onConfirm={handleDeleteEvent}
            onCancel={() => setConfirmDeleteVisible(false)}
          />
          <ConfirmModal
            visible={confirmDenyEventRequestVisible}
            title={t(i18nKeys.events.eventDetails.labels.eventActions.denyEventRequest)}
            onCancel={() => cleanUpActionModals()}
            onConfirm={() => denyEventRequest()}
            confirmBtnLabel={t(i18nKeys.events.eventDetails.buttons.denyEventRequest)}
            message={
              <React.Fragment>
                <div>{t(i18nKeys.events.eventDetails.messages.eventActions.denyEventRequest)}</div>
                <Input value={eventRequestComment} onChange={({ detail }) => setEventRequestComment(detail.value)} />
              </React.Fragment>
            }
          />
          <ConfirmModal
            visible={confirmApproveEventRequestVisible}
            title={t(i18nKeys.events.eventDetails.labels.eventActions.approveEventRequest)}
            onCancel={() => cleanUpActionModals()}
            onConfirm={() => approveEventRequest()}
            confirmBtnLabel={t(i18nKeys.events.eventDetails.buttons.approveEventRequest)}
            disabled={_.isEmpty(approveEventRequestID)}
            message={
              <React.Fragment>
                <KeyValue
                  required
                  label={t(i18nKeys.events.eventDetails.labels.eventID)}
                  description={`${t(i18nKeys.events.eventDetails.messages.eventActions.approveEventRequest)}
                  ${getGameUIUrl()}/valid-event`}>
                  <Input
                    value={approveEventRequestID}
                    onChange={({ detail }) => setApproveEventRequestId(detail.value.replaceAll(' ', '-').toLowerCase())}
                  />
                </KeyValue>
                <KeyValue
                  label={t(i18nKeys.events.eventDetails.labels.optionalComment)}
                  description={t(i18nKeys.events.eventDetails.messages.eventActions.approveEventRequestComment)}>
                  <Textarea
                    value={eventRequestComment}
                    onChange={({ detail }) => setEventRequestComment(detail.value)}
                  />
                </KeyValue>
              </React.Fragment>
            }
          />
          <ConfirmModal
            visible={confirmCancelEventRequestVisible}
            title={t(i18nKeys.events.eventDetails.labels.eventActions.cancelEventRequest)}
            onCancel={() => cleanUpActionModals()}
            onConfirm={() => cancelEventRequest()}
            confirmBtnLabel={t(i18nKeys.events.eventDetails.buttons.cancelEventRequest)}
            message={
              <React.Fragment>
                <div>{t(i18nKeys.events.eventDetails.messages.eventActions.cancelEventRequest)}</div>
                <Input value={eventRequestComment} onChange={({ detail }) => setEventRequestComment(detail.value)} />
              </React.Fragment>
            }
          />
          <ConfirmModal
            visible={confirmApproveChangeRequestVisible}
            title={t(i18nKeys.events.eventDetails.labels.eventActions.approveChangeRequest)}
            onCancel={() => cleanUpActionModals()}
            onConfirm={() => approveChangeRequest()}
            confirmBtnLabel={t(i18nKeys.events.eventDetails.buttons.approveChangeRequest)}
            message={
              <React.Fragment>
                <div>{t(i18nKeys.events.eventDetails.messages.eventActions.approveChangeRequest)}</div>
                <Input value={eventRequestComment} onChange={({ detail }) => setEventRequestComment(detail.value)} />
              </React.Fragment>
            }
          />
          <ConfirmModal
            visible={confirmDenyChangeRequestVisible}
            title={t(i18nKeys.events.eventDetails.labels.eventActions.denyChangeRequest)}
            onCancel={() => cleanUpActionModals()}
            onConfirm={() => denyChangeRequest()}
            confirmBtnLabel={t(i18nKeys.events.eventDetails.buttons.denyChangeRequest)}
            disabled={_.isEmpty(eventRequestComment)}
            message={
              <React.Fragment>
                <div>{t(i18nKeys.events.eventDetails.messages.eventActions.denyChangeRequest)}</div>
                <Input value={eventRequestComment} onChange={({ detail }) => setEventRequestComment(detail.value)} />
              </React.Fragment>
            }
          />
          <ConfirmModal
            visible={confirmCancelChangeRequestVisible}
            title={t(i18nKeys.events.eventDetails.labels.eventActions.cancelChangeRequest)}
            onCancel={() => cleanUpActionModals()}
            onConfirm={() => cancelChangeRequest()}
            confirmBtnLabel={t(i18nKeys.events.eventDetails.buttons.cancelChangeRequest)}
            message={
              <React.Fragment>
                <div>{t(i18nKeys.events.eventDetails.messages.eventActions.cancelChangeRequest)}</div>
                <Input value={eventRequestComment} onChange={({ detail }) => setEventRequestComment(detail.value)} />
              </React.Fragment>
            }
          />
          <ConfirmModal
            visible={confirmResetEventVisible}
            title={t(i18nKeys.events.eventDetails.labels.eventActions.resetEvent)}
            onCancel={() => cleanUpActionModals()}
            onConfirm={() => handleResetEvent()}
            confirmBtnLabel={t(i18nKeys.events.eventDetails.buttons.yesResetEvent)}
            message={t(i18nKeys.events.eventDetails.messages.eventActions.resetEvent)}
          />
          <ConfirmModal
            visible={confirmResetTeamsVisible}
            title={t(i18nKeys.events.eventDetails.labels.eventActions.resetEventTeams)}
            onCancel={() => cleanUpActionModals()}
            onConfirm={() => handleResetEventTeams()}
            confirmBtnLabel={t(i18nKeys.events.eventDetails.buttons.yesResetTeams)}
            message={t(i18nKeys.events.eventDetails.messages.eventActions.resetEventTeams)}
          />
          {editedEvent && typeOfSave && (
            <ConfirmModal
              visible={userEmptyPermissionsVisible}
              title={t(i18nKeys.events.eventDetails.labels.eventActions.emptyEventPermissionsDetected)}
              message={t(i18nKeys.events.eventDetails.messages.eventActions.emptyEventPermissions, {
                emails: getEmptyEventPermissions(editedEvent).join(', '),
              })}
              onCancel={() => setUserEmptyPermissionsVisible(false)}
              onConfirm={() => saveEvent(typeOfSave, true)}
            />
          )}
          <Columns columns={4}>
            <Column size="s">
              <KeyValue className="secondary-text" label={t(i18nKeys.events.eventDetails.labels.eventStatus)}>
                {event && <StatusBadge status={getEventListStatus(event)} />}
              </KeyValue>
            </Column>
            <Column size="s">
              <KeyValue className="secondary-text" label={t(i18nKeys.events.eventDetails.labels.ends)}>
                <DateTime startOrEnd={DateTimeKeys.END} event={event} timezoneFormat={TimezoneFormat.LOCAL} />
              </KeyValue>
            </Column>
            <Column size="s">
              <KeyValue className="secondary-text" label={t(i18nKeys.events.eventDetails.labels.tags)}>
                <React.Fragment>
                  <div className="tag-group">
                    {event?.tags?.map((tag, key) => {
                      return (
                        <Badge color="grey" key={key}>
                          {tag}
                        </Badge>
                      );
                    })}
                  </div>
                </React.Fragment>
              </KeyValue>
            </Column>
            {event?.isCampaignEvent && (
              <Column size="s">
                <div style={{ marginTop: 5, width: '90%' }}>
                  <Icon name="status-info" className="aws-orange-info" />{' '}
                  {t(i18nKeys.events.eventDetails.messages.toMakeChangesToEvent)}
                  <Link to={`/campaigns/${event?.campaignId}`} className="link">
                    {t(i18nKeys.events.eventDetails.messages.parentCampaign)}
                  </Link>
                </div>
              </Column>
            )}
          </Columns>
        </Container>
        <ChangeRequestSummary source={event} getPendingChanges={getPendingEventChanges} />
        {renderChildView()}
      </SpaceBetween>
    </ContentLayout>
  );
};

export default EventDetails;
