/* eslint-disable @typescript-eslint/no-unsafe-return */
import * as React from 'react';
import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { getEnvVar } from '../../../utils/env-var.utils';
import CfnReadOnly from './CfnReadOnly';
import { i18nKeys } from '../../../utils/i18n.utils';

interface ChallengeResourceSnippetModalProps {
  visible: boolean;
  onCancel: () => any;
  onConfirm: () => any;
  s3Key: string;
}
const ChallengeResourceSnippetModal: React.FC<ChallengeResourceSnippetModalProps> = ({
  visible,
  onCancel,
  onConfirm,
  s3Key,
}) => {
  const { t } = useTranslation();

  const bucketCfn = `!Sub ${getEnvVar('REACT_APP_CHALLENGE_RESOURCES_BUCKET_NAME')}-\${AWS::Region}`;

  const lambdaCodeCfn = ['Code:', '  S3Bucket:', `    ${bucketCfn}`, '  S3Key:', `    ${s3Key}`].join('\n');

  return (
    <Modal
      header={t(i18nKeys.challenges.challengeDetails.headings.usingChallengeResources)}
      onDismiss={() => onCancel()}
      footer={<Button onClick={() => onConfirm()}>{t(i18nKeys.general.close)}</Button>}
      visible={visible}>
      <SpaceBetween size="s">
        <Box>
          <h4>{t(i18nKeys.challenges.challengeDetails.titles.regionalResourceBucket)}</h4>
          <p>{t(i18nKeys.challenges.challengeDetails.messages.resourceAutomaticallyReplicated)}</p>
          <p>
            {t(i18nKeys.challenges.challengeDetails.messages.preventLabFailuresPartOne)}{' '}
            <strong>{t(i18nKeys.challenges.challengeDetails.messages.regionalBucket)}</strong>{' '}
            {t(i18nKeys.challenges.challengeDetails.messages.preventLabFailuresPartTwo)}
          </p>
          <CfnReadOnly cfn={bucketCfn} />
        </Box>
        <Box>
          <h4>{t(i18nKeys.challenges.challengeDetails.titles.resourceKey)}</h4>
          <p>{t(i18nKeys.challenges.challengeDetails.messages.resourceKey)}</p>
          <CfnReadOnly cfn={s3Key} />
        </Box>
        <Box>
          <h4>{t(i18nKeys.challenges.challengeDetails.titles.lambdaFunctionExample)}</h4>
          <p>{t(i18nKeys.challenges.challengeDetails.messages.lambdaFunctionExmaple)}</p>
          <CfnReadOnly cfn={lambdaCodeCfn} />
        </Box>
      </SpaceBetween>
    </Modal>
  );
};

export default ChallengeResourceSnippetModal;
