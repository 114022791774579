import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { useUser } from '../../store/user.context';
import { useFlashbars } from '../../store/flashbar.context';
import { i18nKeys, withI18NPrefix } from '../../utils/i18n.utils';
import { errorFlashbar } from '../../utils/notification.utils';
import { AuthRole } from '@amzn/aws-jam-constants';

interface RestrictedRouteProps {
  exact: boolean | undefined;
  path: string;
  key: number;
  requiredGroups: AuthRole[] | undefined;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

/**
 * This is used to determine if a user is authenticated and if they are allowed to visit the page they navigated to.
 * If they are: they proceed to the page, but if not, they are redirected to the root with a flashbar error message.
 *
 * @param Component the route component to direct the user to the correct path
 * @param requiredPermissions all necessary permissions for a user to proceed
 * @param rest any other parameters necessary for Route to function
 * @constructor
 */
const RestrictedRoute: React.FC<RestrictedRouteProps> = ({ component: Component, requiredGroups, ...rest }) => {
  const { user } = useUser();
  const { addFlashbar } = useFlashbars();

  // check if user has access to all requiredGroups
  let hasAccess = true;
  if (user && requiredGroups) {
    for (const group of requiredGroups) {
      hasAccess = hasAccess ? user.memberOfGroup(group) : false;
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user && hasAccess) {
          return (
            <div className="mb-20">
              <Component {...props} />
            </div>
          );
        } else {
          addFlashbar(
            errorFlashbar(
              withI18NPrefix(i18nKeys.errors.requestFailed.accessDenied),
              withI18NPrefix(i18nKeys.errors.requestFailed.insufficientPermissions)
            )
          );
          return <Redirect to={{ pathname: '/' }} />;
        }
      }}
    />
  );
};

export default RestrictedRoute;
