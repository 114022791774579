/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  Box,
  Button,
  Container,
  ExpandableSection,
  Flashbar,
  Header,
  Icon,
  Link,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChallenges } from '../../../../store/challenge.context';
import { ChallengePropAction, useCreateChallenge } from '../../../../store/create-challenge.context';
import { Challenge, ChallengeReviewableSection } from '../../../../types/Challenge';
import {
  hasAwsJamResourceWithoutRegion,
  hasValidCfnTemplateResource,
  parseCfnToJson,
} from '../../../../utils/cfn.utils';
import { getEnvVar } from '../../../../utils/env-var.utils';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { preProdLogger } from '../../../../utils/log.utils';
import ChallengeResourceSnippetModal from '../../../common/Challenges/ChallengeResourceSnippetModal';
import ChallengeSshSnippetModal from '../../../common/Challenges/ChallengeSshSnippetModal';
import JamCodeEditor from '../../challengesCommon/JamCodeEditor';
import { ChallengeReviewPopover } from '../../challengesCommon/ChallengeReviewPopover';

interface ChallengeCFNTemplateDetailProps {
  challenge: Challenge;
}

const ChallengeCFNTemplateDetail: React.FC<ChallengeCFNTemplateDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { challengeResources, challengeConfig, getChallengeResources, getChallengeConfiguration, validateCfnTemplate } =
    useChallenges();
  const { editMode, editedChallenge, handleUpdateChallengeProp } = useCreateChallenge();
  const [templateScanResults, setTemplateScanResults] = useState('');
  const [visibleSshExampleSnippet, setVisibleSshExampleSnippet] = useState(false);
  const [visibleCfnResourceSnippet, setVisibleCfnResourceSnippet] = useState(false);
  const hiddenFileInput = React.createRef<HTMLInputElement>();

  const challengeResourceAccountName = getEnvVar('REACT_APP_CHALLENGE_RESOURCES_BUCKET_NAME');

  useEffect(() => {
    getChallengeConfiguration();
    getChallengeResources(challenge.challengeId || '');
  }, []);

  const [items, setItems] = useState([
    {
      dismissible: true,
      dismissLabel: t(i18nKeys.challenges.subSections.cfnTemplate.dismissLabel),
      onDismiss: () => setItems([]),
      content: <>{t(i18nKeys.challenges.subSections.cfnTemplate.infoFlashbar)}</>,
    },
  ]);

  const handleFileUpload = (uploadedFile: File) => {
    // Handle file upload
    uploadedFile
      .text()
      .then((file) => {
        handleUpdateChallengeProp(ChallengePropAction.CFN_TEMPLATE, file);
      })
      .catch((err) => preProdLogger(err));
  };

  const handleUpdateCfnTemplate = (template: string) => {
    if (editMode) {
      handleUpdateChallengeProp(ChallengePropAction.CFN_TEMPLATE, template);
    }
  };

  const challengeResourceBucket = getEnvVar('REACT_APP_CHALLENGE_RESOURCES_BUCKET_NAME') || '';
  const cfnTemplateFileName = `aws-jam-${challenge.challengeId}-cfn-template`;

  const onValidateCFNTemplate = () => {
    if (editedChallenge) {
      validateCfnTemplate(editedChallenge.props.cfnTemplate || '')
        .then((results) => setTemplateScanResults(results))
        .catch((err) => preProdLogger(err));
    }
  };

  const hasInvalidCfnTemplateResource = () => {
    if (editedChallenge) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const cfnJson = parseCfnToJson(editedChallenge.props.cfnTemplate || '');
      return !hasValidCfnTemplateResource(cfnJson);
    }
  };

  const hasMissingResourceRegion = () => {
    if (editedChallenge) {
      return hasAwsJamResourceWithoutRegion(editedChallenge.props.cfnTemplate || '');
    }
  };

  /**
   * Get the fully qualified S3 key of the object with the challenge id folder.
   */
  const getChallengeResourceFullS3Key = (resourceName: string): string => {
    return `${challenge.id}/${resourceName}`;
  };

  return (
    <Container
      header={
        <Header variant="h2" actions={<ChallengeReviewPopover section={ChallengeReviewableSection.CFN_TEMPLATE} />}>
          {t(i18nKeys.challenges.challengeDetails.headings.cloudformationTemplate)}
        </Header>
      }>
      <SpaceBetween size="s">
        {editMode && <Flashbar items={items} />}
        {editMode && (
          <SpaceBetween size="s" direction="horizontal">
            <Button>{t(i18nKeys.challenges.challengeDetails.buttons.showCfnSnippets)}</Button>
            <Button onClick={() => setVisibleSshExampleSnippet(true)}>
              {t(i18nKeys.challenges.challengeDetails.buttons.provideSshAccess)}
            </Button>
            <Button onClick={() => setVisibleCfnResourceSnippet(true)}>
              {t(i18nKeys.challenges.challengeDetails.buttons.usingChallengeResources)}
            </Button>
          </SpaceBetween>
        )}
        {editMode && (
          <Box className="gray-box" padding={'l'} textAlign="center">
            <SpaceBetween size="s">
              {challenge.hasCfnTemplate && (
                <div>
                  <Icon name="file" />
                  <Link href="#" variant="primary">
                    {cfnTemplateFileName}
                  </Link>
                </div>
              )}
              <p>{t(i18nKeys.challenges.subSections.cfnTemplate.dropYamlFile)}</p>
              <input
                ref={hiddenFileInput}
                id="chooseFileInput"
                type="file"
                hidden
                multiple={false}
                accept="application/yaml"
                // @ts-ignore
                onChange={(event) => handleFileUpload(event.target.files[0])}
              />
              <Button
                variant="link"
                iconName="upload"
                formAction="none"
                onClick={() => {
                  // @ts-ignore
                  hiddenFileInput.current.value = null; // allow for re-choosing the same file name
                  // @ts-ignore
                  hiddenFileInput.current.click();
                }}>
                {t(i18nKeys.challenges.subSections.cfnTemplate.browseFiles)}
              </Button>
              {editedChallenge?.hasCfnTemplate && (
                <Button
                  iconName="status-negative"
                  formAction="none"
                  onClick={() => {
                    handleUpdateChallengeProp(ChallengePropAction.CFN_TEMPLATE, null);
                  }}>
                  {t(i18nKeys.general.remove)}
                </Button>
              )}
            </SpaceBetween>
          </Box>
        )}
        {editMode && (
          <Button iconName="status-positive" onClick={onValidateCFNTemplate}>
            {t(i18nKeys.challenges.challengeDetails.buttons.validateTemplate)}
          </Button>
        )}
        {challenge.hasCfnTemplate && !editMode && (
          <TextContent>
            <h4>{t(i18nKeys.challenges.challengeDetails.titles.file)}</h4>
          </TextContent>
        )}
        {challenge.hasCfnTemplate && !editMode && (
          <div>
            <Icon name="file" />
            <Link href="#" variant="primary">
              {cfnTemplateFileName}
            </Link>
          </div>
        )}
        {editMode && (
          <JamCodeEditor
            language="yaml"
            value={editedChallenge?.props.cfnTemplate || ''}
            setValue={(e) => {
              handleUpdateCfnTemplate(e);
            }}
          />
        )}
        {!editMode && (
          <div style={{ pointerEvents: 'none', opacity: '0.4' }}>
            <JamCodeEditor
              language="yaml"
              value={challenge.props.cfnTemplate || ''}
              setValue={(e) => {
                handleUpdateCfnTemplate(e);
              }}
            />
          </div>
        )}
        {templateScanResults.length > 0 && (
          <ExpandableSection
            header={<Header>{t(i18nKeys.challenges.challengeDetails.headings.templateSecurityFindings)}</Header>}>
            <JamCodeEditor
              language="yaml"
              value={templateScanResults}
              setValue={() => {
                preProdLogger('none');
              }}
            />
          </ExpandableSection>
        )}

        {!editMode && challenge.hasCfnTemplate && (
          <div>
            {(hasMissingResourceRegion() || hasInvalidCfnTemplateResource()) && (
              <div style={{ fontSize: '1rem' }}>
                <div className="aws-panel aws-panel-info mb-3">
                  <span className="fa fa-info-circle" />
                  {hasMissingResourceRegion() && (
                    <Box>
                      {t(i18nKeys.challenges.challengeDetails.text.missingResourcePartOne)}{' '}
                      <strong>{challengeResourceAccountName}</strong>{' '}
                      {t(i18nKeys.challenges.challengeDetails.text.missingResourcePartTwo)}
                    </Box>
                  )}
                  {hasInvalidCfnTemplateResource() && (
                    <span style={{ display: hasMissingResourceRegion() ? 'block' : 'inline' }}>
                      {hasMissingResourceRegion() && <br />}
                      {t(i18nKeys.challenges.challengeDetails.text.nestedCfnTemplate)}
                      <strong>{`${challengeResourceBucket} ${t(
                        i18nKeys.challenges.challengeDetails.text.bucket
                      )}`}</strong>
                      .
                    </span>
                  )}

                  <div>
                    <br />
                    {t(i18nKeys.challenges.challengeDetails.text.mayDelayApproval)}
                    {challengeResources.length > 0 && (
                      <Button variant="link" onClick={() => setVisibleCfnResourceSnippet(true)}>
                        {t(i18nKeys.challenges.challengeDetails.titles.moreInfo)}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </SpaceBetween>
      {challengeConfig && (
        <ChallengeSshSnippetModal
          visible={visibleSshExampleSnippet}
          onCancel={() => setVisibleSshExampleSnippet(false)}
          onConfirm={() => setVisibleSshExampleSnippet(false)}
          challengeConfig={challengeConfig}
        />
      )}
      {challengeResources?.length > 0 && (
        <ChallengeResourceSnippetModal
          visible={visibleCfnResourceSnippet}
          onCancel={() => setVisibleCfnResourceSnippet(false)}
          onConfirm={() => setVisibleCfnResourceSnippet(false)}
          s3Key={getChallengeResourceFullS3Key(challengeResources[0].key || '')}
        />
      )}
    </Container>
  );
};

export default ChallengeCFNTemplateDetail;
