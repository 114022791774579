import React, { Dispatch, SetStateAction } from 'react';
import { CollectionPreferencesProps, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { i18nKeys } from '../../../utils/i18n.utils';
import { getTableFilterMatcher, TableFilterMatcher } from '../../../utils/table.utils';
import { useUser } from '../../../store/user.context';
import { Event } from '../../../types/Event';
import { useTranslation } from 'react-i18next';
import { Team, TeamGoal } from '../../../types/Team';
import { EVENT_DETAILS_ROUTES } from '../../../routes';

export const filteringFunction = (item: Team, filteringText: string): boolean => {
  const matcher: TableFilterMatcher = getTableFilterMatcher(filteringText);
  return (
    matcher.filterEmpty ||
    matcher.isMatch(item.name) ||
    item.members.findIndex((member) => member.nickname === filteringText) > -1
  );
};

export const COLUMN_DEFINITIONS = (
  preferences: CollectionPreferencesProps.Preferences,
  target: Event,
  toggleTeamDetails: (teamName: string) => void,
  selectTeamName: (teamName: string) => void,
  setTeamDetailsVisible: Dispatch<SetStateAction<boolean>>,
  setRenameTeamAliasVisible: Dispatch<SetStateAction<boolean>>,
  setDisableTeamVisible: Dispatch<SetStateAction<boolean>>,
  setDeleteTeamVisible: Dispatch<SetStateAction<boolean>>
) => {
  const { user } = useUser();
  const { t } = useTranslation();

  const getTeamPasswordRequired = (team: Team): string => {
    const isFacilitator = team.members && team.members.some((member) => member.facilitator);
    let passwordRequired: string;

    if (team.passwordRequired) {
      passwordRequired = t(i18nKeys.general.yes);
    } else {
      passwordRequired = t(i18nKeys.general.no);
    }

    if (isFacilitator) {
      passwordRequired = '--';
    }
    return passwordRequired;
  };

  const getTeamGoal = (team: Team): string => {
    const isFacilitator = team.members && team.members.some((member) => member.facilitator);
    let goal: string;

    switch (team.goal) {
      case TeamGoal.PLAY_TO_WIN:
        goal = t(i18nKeys.participants.labels.goals.playingToWin);
        break;
      case TeamGoal.PLAY_TO_LEARN:
        goal = t(i18nKeys.participants.labels.goals.playingToLearn);
        break;
      default:
        goal = '--';
    }

    if (isFacilitator) {
      goal = '--';
    }
    return goal;
  };

  return [
    {
      id: 'name',
      sortingField: 'name',
      header: t(i18nKeys.participants.headers.teamName),
      cell: (item: Team) => <Link onFollow={() => toggleTeamDetails(item.name as string)}>{item.teamLabel}</Link>,
      minWidth: 260,
      allowLineWrap: true,
    },
    {
      id: 'event-name',
      sortingField: 'eventName',
      header: t(i18nKeys.participants.headers.eventName),
      cell: (item: Team) => <Link href={EVENT_DETAILS_ROUTES.Summary.resolve(target.name)}>{target.title}</Link>,
      minWidth: 180,
      allowLineWrap: false,
    },
    {
      id: 'members',
      sortingField: 'members',
      header: t(i18nKeys.participants.headers.teamMembers),
      cell: (item: Team) => {
        const teamMembers = item.members.map((member) => {
          return member.nickname;
        });
        return <div>{teamMembers.join(', ')}</div>;
      },
      minWidth: 156,
      allowLineWrap: false,
    },
    {
      id: 'password-protected',
      sortingField: 'passwordRequired',
      header: t(i18nKeys.participants.headers.passwordProtected),
      cell: (item: Team) => <div>{getTeamPasswordRequired(item)}</div>,
      minWidth: 215,
      allowLineWrap: false,
    },
    {
      id: 'goal',
      sortingField: 'goal',
      header: t(i18nKeys.participants.headers.teamGoal),
      cell: (item: Team) => <div>{getTeamGoal(item)}</div>,
      minWidth: 215,
      allowLineWrap: false,
    },
    {
      id: 'actions',
      header: t(i18nKeys.participants.headers.actions),
      cell: (item: Team) => (
        <div>
          {user && target.canEditAttribute('users', user) && (
            <SpaceBetween direction="horizontal" size="s">
              <Link
                className="right-border pr-12"
                onFollow={() => {
                  selectTeamName(item.name!);
                  setTeamDetailsVisible(false);
                  setRenameTeamAliasVisible(true);
                }}>
                {t(i18nKeys.participants.labels.changeTeamAlias)}
              </Link>
              <Link
                className="right-border pr-12"
                onFollow={() => {
                  selectTeamName(item.name!);
                  setDisableTeamVisible(true);
                  setTeamDetailsVisible(false);
                }}>
                {t(i18nKeys.participants.labels.disableTeam)}
              </Link>
              <Link
                onFollow={() => {
                  selectTeamName(item.name!);
                  setDisableTeamVisible(false);
                  setDeleteTeamVisible(true);
                }}>
                {t(i18nKeys.participants.labels.deleteTeam)}
              </Link>
            </SpaceBetween>
          )}
        </div>
      ),
      minWidth: 380,
      allowLineWrap: false,
    },
  ];
};
