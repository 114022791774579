export enum ChallengeHotspot {
  challengeId = 'challenge-id',
  challengeTitle = 'challenge-title',
  challengeDescription = 'challenge-description',
  learningOutcomeSummary = 'learning-outcome-summary',
  learningOutcomeIntroduction = 'learning-outcome-introduction',
  learningOutcomeTopicsCovered = 'learning-outcome-topics-covered',
  learningOutcomeTechnicalKnowledgePrerequisites = 'learning-outcome-technical-knowledge-prerequisites',
  basicSettingsType = 'settings-basic-settings-type',
  basicSettingsCategory = 'settings-basic-settings-category',
  basicSettingsDifficulty = 'settings-basic-settings-difficulty',
  awsSettingsServices = 'settings-aws-settings-services',
  awsSettingsRegions = 'settings-aws-settings-supported-regions',
}
