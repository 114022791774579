import _locales from '../constants/locales.json';
import { SelectProps } from '@amzn/awsui-components-react/uxdg';

/**
 * The locale to use as a fallback in the event that we don't support the locale that is auto-detected.
 */
export const DEFAULT_LOCALE = 'en';

/**
 * Dictionary of the supported locales and their corresponding labels.
 */
export const locales: { [languageCode: string]: string } = _locales;

/**
 * Language codes for all supported locales.
 */
export const LANGUAGE_CODES = Object.keys(locales);

export const LANGUAGE_SELECT_OPTIONS = LANGUAGE_CODES.map((code) => {
  return {
    value: code,
    label: locales[code],
  };
});

/**
 * Find the first supported locale that the provided language code starts with.
 * Default to the DEFAULT_LOCALE if none found.
 *
 * @param languageCode
 */
export const getLanguageCodeSafe = (languageCode: string) => {
  return LANGUAGE_CODES.find((lng) => languageCode.startsWith(lng)) || DEFAULT_LOCALE;
};

/**
 * Get the label for a locale language code.
 *
 * @param languageCode
 */
export const getLocaleLabel = (languageCode: string) => {
  return locales[getLanguageCodeSafe(languageCode)];
};
