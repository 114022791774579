import { LanguageCodeOption, LanguageCodes } from '@amzn/aws-jam-constants';
import { Box, FormField, Header, Input, Select, SpaceBetween } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../store/api.context';
import { LocalizationOverrides } from '../../../types/common';
import { i18nKeys } from '../../../utils/i18n.utils';
import { preProdLogger } from '../../../utils/log.utils';
import { LoadingBar } from '../../common/LoadingBar';
import LocalizationItemContainer from './LocalizationItem';

export interface LocalizationItem {
  key: string;
  english: string;
  translated: string;
  override: string;
  _override: string;
}

const JamUILocalization: React.FC = () => {
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageCodeOption | OptionDefinition | null>(null);
  const appName = 'jam-ui';
  const withoutEnglish: LanguageCodeOption[] = LanguageCodes.list.filter((i) => i.value !== LanguageCodes.map.ENGLISH);
  const [items, setItems] = useState<LocalizationItem[]>([]);
  const [filteredItems, setFilteredItems] = useState<LocalizationItem[]>([]);
  const { settingsApi } = useApi();
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentTimeout, setCurrentTimeout] = useState<ReturnType<typeof setTimeout> | undefined>();
  const { t } = useTranslation();

  const loadLocalization = async () => {
    setLoading(true);
    if (selectedLanguage?.value) {
      const response: LocalizationOverrides = await settingsApi.getLocalizationMessages(
        appName,
        selectedLanguage.value
      );

      const translatedMessages: { [key: string]: string } = Object.assign({}, response.defaults);
      const overrideMessages: { [key: string]: string } = Object.assign({}, response.overrides);

      const englishResponse: LocalizationOverrides = await settingsApi.getLocalizationMessages(
        appName,
        LanguageCodes.map.ENGLISH
      );

      const englishMessages: { [key: string]: string } = Object.assign({}, englishResponse.defaults);

      const newItems = Object.entries(translatedMessages).map(([key, translated]) => {
        return {
          key,
          english: englishMessages[key] || '',
          translated,
          override: overrideMessages[key] || '',
          _override: overrideMessages[key] || '',
        };
      });
      setItems(newItems);
    }
  };

  const onChangeFilter = () => {
    const term = (searchText || '').toLowerCase().trim();
    let newFilteredItems: LocalizationItem[] = [];
    if (!term) {
      setFilteredItems(newFilteredItems);
      setLoading(false);
      return;
    }
    newFilteredItems = items
      .filter((item: LocalizationItem) => {
        return (
          (item.english || '').includes(term) ||
          (item.translated || '').includes(term) ||
          (item.override || '').includes(term) ||
          (item.key || '').includes(term)
        );
      })
      .sort((a, b) => {
        return a.key > b.key ? 1 : -1;
      });
    setFilteredItems(newFilteredItems);
    setLoading(false);
  };

  /** Create delay in filter logic to allow seemless typing in search bar */
  useEffect(() => {
    setLoading(true);
    if (currentTimeout) {
      clearTimeout(currentTimeout);
    }
    setCurrentTimeout(
      setTimeout(() => {
        onChangeFilter();
      }, 500)
    );
  }, [searchText]);

  useEffect(() => {
    loadLocalization()
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        preProdLogger('Error loading localizations', err.message);
        setLoading(false);
      });
  }, [selectedLanguage]);

  return (
    <Box>
      <SpaceBetween direction="vertical" size="s">
        <Header variant="h2">{t(i18nKeys.settings.jamUILocalization.title)}</Header>
        <FormField label={t(i18nKeys.settings.jamUILocalization.labels.languageSelection)}>
          <Select
            className="dropdown-select"
            options={withoutEnglish}
            placeholder={t(i18nKeys.settings.jamUILocalization.placeholder.selectLanguage)}
            selectedOption={selectedLanguage}
            disabled={loading}
            onChange={({ detail }) => setSelectedLanguage(detail.selectedOption)}
          />
        </FormField>
        {selectedLanguage && (
          <FormField
            label={
              filteredItems.length > 0
                ? t(i18nKeys.settings.jamUILocalization.labels.itemsFoundSearch, { itemCount: filteredItems.length })
                : t(i18nKeys.settings.jamUILocalization.labels.noItemsSearch)
            }>
            <Input
              type="search"
              placeholder={t(i18nKeys.settings.jamUILocalization.placeholder.searchText)}
              value={searchText}
              className="long-select-input"
              onChange={({ detail }) => setSearchText(detail.value)}
            />
          </FormField>
        )}

        {loading && <LoadingBar />}
        {!loading &&
          filteredItems.length > 0 &&
          searchText.length > 0 &&
          filteredItems.map((localization, i) => {
            return (
              <LocalizationItemContainer
                item={localization}
                selectedLanguage={selectedLanguage?.value || ''}
                key={`localization-item-${i}`}
              />
            );
          })}
      </SpaceBetween>
    </Box>
  );
};
export default JamUILocalization;
