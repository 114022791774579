/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';
import { ChallengeInfo } from '../components/root/ToolsContent';
import { ChallengeListItem } from '../types/Challenge';
import { NullableString } from '../types/common';
import { useChallenges } from './challenge.context';

export interface ToolPanelContextValue {
  toolPanelContent: ReactNode | null;
  toolPanelHeader: NullableString;
  showToolPanel: boolean;
  toolPanelOpen: boolean;
  toolPanelWidth: number;
  setToolPanelWidth: Dispatch<SetStateAction<number>>;
  setHideAfterClose: Dispatch<SetStateAction<boolean>>;
  toggleToolPanel: (toggle: boolean) => void;
  toggleShowToolPanel: (toggle: boolean) => void;
  renderToolPanelContent: (header: string, content: ReactNode) => void;
  toggleChallengeInfo: (challengeItem: ChallengeListItem) => void;
  showSidenav: boolean;
  toggleSideNav: (toggle: boolean) => void;
}

const ToolPanelContext = React.createContext<ToolPanelContextValue>({
  toolPanelContent: null,
  toolPanelHeader: null,
  showToolPanel: false,
  toolPanelOpen: false,
  toolPanelWidth: 300,
  setToolPanelWidth: () => {},
  setHideAfterClose: () => {},
  toggleToolPanel: (toggle: boolean) => {},
  toggleShowToolPanel: (toggle: boolean) => {},
  renderToolPanelContent: (header: string, content: ReactNode) => {},
  toggleChallengeInfo: (challengeItem: ChallengeListItem) => {},
  showSidenav: true,
  toggleSideNav: () => {},
});

const ToolPanelProvider: React.FC = ({ children }) => {
  const [showToolPanel, setShowToolPanel] = useState(false);
  const [toolPanelOpen, setToolPanelOpen] = useState(false);
  const [toolPanelContent, setToolPanelContent] = useState<ReactNode>(null);
  const [toolPanelHeader, setToolPanelHeader] = useState('');
  const [toolPanelWidth, setToolPanelWidth] = useState(300);
  const [hideAfterClose, setHideAfterClose] = useState(false);
  const [showSidenav, setShowSidenav] = useState<boolean>(true);
  const { getFullChallenge } = useChallenges();

  const toggleToolPanel = (toggle: boolean) => {
    /** Allows configuration of tool panel to hide if closed in specific components */
    if (toolPanelOpen && hideAfterClose) {
      toggleShowToolPanel(false);
      setHideAfterClose(false);
    }
    setToolPanelOpen(toggle);
  };

  const toggleSideNav = (toggle: boolean) => {
    setShowSidenav(toggle);
  };

  const toggleShowToolPanel = (toggle: boolean) => {
    setShowToolPanel(toggle);
    // Destory previous content if tool panel is being hidden, as well as disable custom settings
    if (!showToolPanel) {
      setToolPanelOpen(false);
      setToolPanelContent(undefined);
      setHideAfterClose(false);
    }
  };

  const renderToolPanelContent = (header: string, content: ReactNode) => {
    setToolPanelHeader(header);
    setToolPanelContent(content);
  };

  const toggleChallengeInfo = (challengeItem: ChallengeListItem) => {
    if (challengeItem?.challengeId) {
      if (!showToolPanel) {
        toggleShowToolPanel(true);
        toggleToolPanel(true);
        setToolPanelWidth(400);
        setHideAfterClose(true);
      }
      getFullChallenge(challengeItem.challengeId);
      renderToolPanelContent(challengeItem.props.title || '', <ChallengeInfo />);
    }
  };

  const data: ToolPanelContextValue = {
    toolPanelContent,
    toolPanelHeader,
    toggleToolPanel,
    toggleShowToolPanel,
    showToolPanel,
    toolPanelOpen,
    renderToolPanelContent,
    toolPanelWidth,
    setToolPanelWidth,
    setHideAfterClose,
    toggleChallengeInfo,
    showSidenav,
    toggleSideNav,
  };

  return <ToolPanelContext.Provider value={data}>{children}</ToolPanelContext.Provider>;
};

const useToolPanel = () => {
  const context = useContext(ToolPanelContext);
  if (context === undefined) {
    throw new Error('useToolPanels can only be used inside ToolPanelProvider');
  }
  return context;
};

export { ToolPanelProvider, useToolPanel };
