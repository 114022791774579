import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container } from '@amzn/awsui-components-react';
import { i18nKeys } from '../../utils/i18n.utils';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const NoMatch: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const query = useQuery();
  const from = query && query.get('from') ? decodeURIComponent(query.get('from') as string) : location.pathname;
  return (
    <Container>
      <h3>
        <code>{from}</code>
        <div style={{ marginTop: 10, textTransform: 'uppercase' }}>
          <strong>404: {t(i18nKeys.general.notFound)}</strong>
        </div>
      </h3>
    </Container>
  );
};
