import React, { useState, useContext } from 'react';
import { config } from '../config/app-config';
import { EventsAPI } from '../api/EventsAPI';
import { ApiClient } from '../api/ApiClient';
import { AddErrorFlashbar, AddSuccessFlashbar, useFlashbars } from './flashbar.context';
import { AccountAPI } from '../api/AccountAPI';
import { useAuth } from './auth.context';
import { useHistory } from 'react-router';
import { useUser } from './user.context';
import { UsagePlanAPI } from '../api/UsagePlanAPI';
import { ChallengesAPI } from '../api/ChallengesAPI';
import { UserAPI } from '../api/UserAPI';
import { TeamAPI } from '../api/TeamAPI';
import { TFunction, useTranslation } from 'react-i18next';
import { ReportsAPI } from '../api/ReportsAPI';
import { ChallengeBoardAPI } from '../api/ChallengeBoardAPI';
import { ChallengeSetAPI } from '../api/ChallengeSetAPI';
import { CampaignAPI } from '../api/CampaignAPI';
import { LseAPI } from '../api/LseAPI';
import { SettingsAPI } from '../api/SettingsAPI';

export interface ApiContextValue {
  apiClient: ApiClient | null;
  eventsApi: EventsAPI;
  challengesApi: ChallengesAPI;
  challengeSetApi: ChallengeSetAPI;
  challengeBoardApi: ChallengeBoardAPI;
  accountApi: AccountAPI;
  usagePlanApi: UsagePlanAPI;
  userApi: UserAPI;
  teamApi: TeamAPI;
  reportsApi: ReportsAPI;
  lseApi: LseAPI;
  campaignApi: CampaignAPI;
  settingsApi: SettingsAPI;
}

const ApiClientContext = React.createContext<ApiContextValue>({
  apiClient: null,
  // initialize with null cast as an ApiClient so that we don't have to declare eventsAPI as EventsAPI|null
  eventsApi: new EventsAPI(null as unknown as ApiClient, null as unknown as TFunction),
  challengesApi: new ChallengesAPI(null as unknown as ApiClient, null as unknown as TFunction),
  challengeSetApi: new ChallengeSetAPI(null as unknown as ApiClient, null as unknown as TFunction),
  challengeBoardApi: new ChallengeBoardAPI(null as unknown as ApiClient, null as unknown as TFunction),
  accountApi: new AccountAPI(null as unknown as ApiClient, null as unknown as TFunction),
  usagePlanApi: new UsagePlanAPI(null as unknown as ApiClient, null as unknown as TFunction),
  userApi: new UserAPI(null as unknown as ApiClient, null as unknown as TFunction),
  teamApi: new TeamAPI(
    null as unknown as ApiClient,
    null as unknown as AddSuccessFlashbar,
    null as unknown as AddErrorFlashbar,
    null as unknown as TFunction
  ),
  reportsApi: new ReportsAPI(null as unknown as ApiClient, null as unknown as TFunction),
  lseApi: new LseAPI(null as unknown as ApiClient),
  campaignApi: new CampaignAPI(null as unknown as ApiClient, null as unknown as TFunction),
  settingsApi: new SettingsAPI(null as unknown as ApiClient, null as unknown as TFunction),
});

const ApiClientProvider: React.FC = ({ children }) => {
  const { addFlashbar, addSuccessFlashbar, addErrorFlashbar } = useFlashbars();
  const { authClient } = useAuth();
  const { user } = useUser();
  const { t } = useTranslation();

  // make a single api client and never expose any method for updating this state/client
  const [apiClient] = useState<ApiClient>(
    new ApiClient(config.api.origin, authClient, user, addFlashbar, addSuccessFlashbar, useHistory())
  );

  const data: ApiContextValue = {
    apiClient,
    eventsApi: new EventsAPI(apiClient, t),
    challengesApi: new ChallengesAPI(apiClient, t),
    challengeSetApi: new ChallengeSetAPI(apiClient, t),
    challengeBoardApi: new ChallengeBoardAPI(apiClient, t),
    accountApi: new AccountAPI(apiClient, t),
    usagePlanApi: new UsagePlanAPI(apiClient, t),
    userApi: new UserAPI(apiClient, t),
    teamApi: new TeamAPI(apiClient, addSuccessFlashbar, addErrorFlashbar, t),
    reportsApi: new ReportsAPI(apiClient, t),
    lseApi: new LseAPI(apiClient),
    campaignApi: new CampaignAPI(apiClient, t),
    settingsApi: new SettingsAPI(apiClient, t),
  };

  return <ApiClientContext.Provider value={data}>{children}</ApiClientContext.Provider>;
};

const useApi = () => {
  const context = useContext(ApiClientContext);
  if (context === undefined) {
    throw new Error('useApiClient can only be used inside ApiClientProvider');
  }
  return context;
};

export { ApiClientProvider, useApi };
