import { Button, Container, Grid, Header, SpaceBetween, Textarea } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import DetailedComment from './DetailedComment';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../../utils/i18n.utils';
import { Comment } from '../../../types/common';

interface CommentsProps {
  comments: Comment[];
  addNewComment: (value: string) => Promise<void>;
  updateComment: (id: string, value: string) => Promise<void>;
  deleteComment: (id: string) => Promise<void>;
}

const Comments: React.FC<CommentsProps> = ({ comments, addNewComment, updateComment, deleteComment }) => {
  const { t } = useTranslation();
  const [newComment, setNewComment] = useState('');

  const handleAddNewComment = () => {
    if (newComment.length > 0) {
      void addNewComment(newComment);
      setNewComment('');
    }
  };

  return (
    <div>
      <Header variant="h2">{t(i18nKeys.comments.header)}</Header>
      <SpaceBetween size="s">
        <Container>
          <span>{t(i18nKeys.comments.labels.enterComment)}</span>
          <Grid gridDefinition={[{ colspan: 8 }, { colspan: 8 }]}>
            <Textarea
              placeholder={t(i18nKeys.comments.placeHolder)}
              value={newComment}
              onChange={({ detail }) => setNewComment(detail.value)}
            />
            <div style={{ float: 'right' }}>
              <Button variant="primary" disabled={newComment.length === 0} onClick={handleAddNewComment}>
                {t(i18nKeys.general.submit)}
              </Button>
            </div>
          </Grid>
        </Container>
        <Container header={<Header variant="h2">{t(i18nKeys.comments.header)}</Header>}>
          {comments.length > 0 && (
            <SpaceBetween size="l">
              {comments.map((value: Comment) => {
                return (
                  <DetailedComment
                    commentDetails={value}
                    key={`comment-${value.id}`}
                    updateComment={updateComment}
                    deleteComment={deleteComment}
                  />
                );
              })}
            </SpaceBetween>
          )}
          {comments.length === 0 && <span>{t(i18nKeys.comments.messages.noComments)}</span>}
        </Container>
      </SpaceBetween>
    </div>
  );
};
export default Comments;
