import { Button } from '@amzn/awsui-components-react';
import { i18nKeys } from '../../../../utils/i18n.utils';
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  ChallengeReviewStatus,
  ChallengeStatus,
  ChallengeUtils,
  ChallengeWarningResponse,
} from '../../../../types/Challenge';
import { ConfirmModal } from '../../../common/ConfirmModal';
import { useChallenges } from '../../../../store/challenge.context';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useUser } from '../../../../store/user.context';
import { useApi } from '../../../../store/api.context';
import { COMMENT_MAX_LENGTH, COMMENT_MIN_LENGTH, useCreateChallenge } from '../../../../store/create-challenge.context';
import { preProdLogger } from '../../../../utils/log.utils';
import { CHALLENGE_DETAILS_ROUTES } from '../../../../routes';

const ReviewButtonPanel = () => {
  const {
    currentReview,
    submitReview,
    readyForReview,
    beginReview,
    cancelReview,
    reviewMode,
    approveChallenge,
    updatePublicStatus,
    challenge,
    getReview,
    setCurrentReview,
    reviewLoading,
    setReviewMode,
  } = useChallenges();

  const { t } = useTranslation();
  const { challengeId }: { challengeId: string } = useParams();
  const { user } = useUser();
  const { challengesApi } = useApi();
  const { editMode } = useCreateChallenge();
  const history = useHistory();
  // eslint-disable-next-line no-shadow
  enum ReviewButtonType {
    REQUEST_REVIEW = 'REQUEST_REVIEW',
    COMPLETE_REVIEW = 'COMPLETE_REVIEW',
    CANCEL_REVIEW = 'CANCEL_REVIEW',
    START_REVIEW = 'START_REVIEW',
    APPROVE_REVIEW = 'APPROVE_REVIEW',
    REVIEW_MODE = 'REVIEW_MODE',
  }

  useEffect(() => {
    if (challenge?.status === ChallengeStatus.IN_REVIEW && ChallengeUtils.hasReviewer(challenge, user)) {
      // check for existing reviews with current user as reviewer (only when challenge is in review)
      // other wise user cannot submit their reviews since the status will be out of date
      getReview(true);
    }

    return () => {
      setCurrentReview(undefined);
      setReviewMode(false);
    };
  }, []);

  const [confirmReadyForReviewVisible, setConfirmReadyForReviewVisible] = useState(false);
  const [confirmApprovalVisible, setConfirmApprovalVisible] = useState(false);
  const [confirmPublicAfterApproval, setConfirmPublicAfterApproval] = useState(false);
  const [readyForReviewComment, setReadyReadyForReviewComment] = useState('');

  const [approvalWarning, setApprovalWarning] = useState<ChallengeWarningResponse | undefined>();
  const [approvalMessage, setApprovalMessage] = useState('');
  const [approvalComment, setApprovalComment] = useState('');
  const [commentErrorText, setCommentErrorText] = useState('');
  const commentConstraintText = t(i18nKeys.challenges.challengeDetails.popover.commentConstraint, {
    minLength: COMMENT_MIN_LENGTH,
    maxLength: COMMENT_MAX_LENGTH,
  });

  const handleApproval = () => {
    if (
      !approvalComment ||
      approvalComment.length < COMMENT_MIN_LENGTH ||
      approvalComment.length > COMMENT_MAX_LENGTH
    ) {
      setCommentErrorText(t(i18nKeys.challenges.challengeDetails.popover.commentInvalidError));
      return;
    }
    if (approvalWarning) {
      approveChallenge(approvalWarning, approvalComment);
    }
    setConfirmApprovalVisible(false);

    if (user?.isChallengeAdmin) {
      setConfirmPublicAfterApproval(true);
    }
  };

  const handlePublicAfterApproval = () => {
    updatePublicStatus(challengeId, true);
    setConfirmPublicAfterApproval(false);
  };

  const handleSubmitForReview = () => {
    if (
      !readyForReviewComment ||
      readyForReviewComment.length < COMMENT_MIN_LENGTH ||
      readyForReviewComment.length > COMMENT_MAX_LENGTH
    ) {
      setCommentErrorText(t(i18nKeys.challenges.challengeDetails.popover.commentInvalidError));
      return;
    }
    readyForReview(readyForReviewComment);
    setConfirmReadyForReviewVisible(false);
  };

  const getApprovalMessage = async () => {
    if (challenge) {
      // get a list of warnings associated with approving this challenge
      // i.e. "approving this challenge will redeploy X active lab accounts"
      const aw: ChallengeWarningResponse = await challengesApi.getApprovalWarnings(challenge, false);

      setApprovalWarning(aw);

      if (approvalWarning && approvalWarning.warnings !== undefined && approvalWarning.warnings.length > 0) {
        let modalMessage = '';

        for (const warning of approvalWarning.warnings) {
          modalMessage += `${warning}\n`;
        }

        modalMessage += `\n${t(i18nKeys.challenges.challengeDetails.messages.approval)}`;

        setApprovalMessage(modalMessage);
      } else {
        setApprovalMessage(t(i18nKeys.general.areYouSure));
      }
    }
  };

  const shouldShowButton = (buttonType: ReviewButtonType): boolean => {
    if (editMode) {
      return false;
    }

    if (user && challenge) {
      switch (buttonType) {
        case ReviewButtonType.REQUEST_REVIEW:
          return challenge?.status === ChallengeStatus.DRAFT && ChallengeUtils.isCollaborator(challenge, user);
        case ReviewButtonType.START_REVIEW: {
          return (
            currentReview == null &&
            (ChallengeUtils.needsReviewer(challenge, user) || ChallengeUtils.needsReview(challenge, user))
          );
        }
        case ReviewButtonType.COMPLETE_REVIEW: {
          return (
            currentReview != null &&
            ChallengeUtils.isBeingReviewed(challenge, user) &&
            ChallengeUtils.isComplete(currentReview)
          );
        }
        case ReviewButtonType.CANCEL_REVIEW:
          return currentReview != null && ChallengeUtils.isBeingReviewed(challenge, user);
        case ReviewButtonType.APPROVE_REVIEW:
          return (
            challenge?.status === ChallengeStatus.IN_REVIEW && // challenge must be in review
            (user?.isChallengeAdmin || ChallengeUtils.isBarRaiser(challenge, user)) && // user is either challenge admin OR bar raiser
            challenge?.barRaiserReviewStatus === ChallengeReviewStatus.APPROVED
          );
        case ReviewButtonType.REVIEW_MODE:
          return currentReview != null && !reviewMode && ChallengeUtils.isBeingReviewed(challenge, user);
        default:
          return false;
      }
    }

    return false;
  };

  return (
    <>
      {shouldShowButton(ReviewButtonType.REQUEST_REVIEW) && (
        <Button className="mr-5" onClick={() => setConfirmReadyForReviewVisible(true)}>
          {t(i18nKeys.challenges.challengeDetails.buttons.submitForReview)}
        </Button>
      )}

      {shouldShowButton(ReviewButtonType.START_REVIEW) && (
        <Button className="mr-5" onClick={beginReview} loading={reviewLoading}>
          {t(i18nKeys.challenges.challengeDetails.buttons.beginReview)}
        </Button>
      )}

      {shouldShowButton(ReviewButtonType.CANCEL_REVIEW) && (
        <Button className="mr-5" onClick={cancelReview} loading={reviewLoading}>
          {t(i18nKeys.challenges.challengeDetails.buttons.unassignReviewer)}
        </Button>
      )}

      {shouldShowButton(ReviewButtonType.REVIEW_MODE) && (
        <Button className="mr-5" onClick={() => setReviewMode(true)}>
          {t(i18nKeys.challenges.challengeDetails.buttons.startReviewMode)}
        </Button>
      )}

      {shouldShowButton(ReviewButtonType.COMPLETE_REVIEW) && (
        <Button
          className="mr-5"
          variant="primary"
          onClick={() => {
            submitReview()
              .then(() => {
                const token = challenge?.challengeId;
                if (token != null && token !== '') {
                  history.push(CHALLENGE_DETAILS_ROUTES.Revisions.resolve(token));
                }
              })
              .catch((err) => {
                preProdLogger('Error submitting review', err);
              });
          }}
          loading={reviewLoading}>
          {t(i18nKeys.challenges.challengeDetails.buttons.completeChallengeReview)}
        </Button>
      )}

      {shouldShowButton(ReviewButtonType.APPROVE_REVIEW) && (
        <Button
          variant={'primary'}
          className="mr-5"
          onClick={() => {
            void getApprovalMessage();
            setConfirmApprovalVisible(true);
          }}>
          {t(i18nKeys.challenges.challengeDetails.buttons.approve)}
        </Button>
      )}
      <ConfirmModal
        message={t(i18nKeys.challenges.challengeDetails.messages.readyForReview)}
        visible={confirmReadyForReviewVisible}
        confirmBtnLabel={t(i18nKeys.general.submit)}
        textInput={readyForReviewComment}
        onTextInput={setReadyReadyForReviewComment}
        onConfirm={handleSubmitForReview}
        onCancel={() => setConfirmReadyForReviewVisible(false)}
        constraintText={commentConstraintText}
        errorText={commentErrorText}
      />
      <ConfirmModal
        title={t(i18nKeys.challenges.challengeDetails.titles.approval)}
        message={approvalMessage}
        visible={confirmApprovalVisible}
        textInput={approvalComment}
        onTextInput={setApprovalComment}
        confirmBtnLabel={t(i18nKeys.challenges.challengeDetails.yesLabels.approval)}
        cancelBtnLabel={t(i18nKeys.challenges.challengeDetails.cancelLabels.approval)}
        onConfirm={handleApproval}
        onCancel={() => setConfirmApprovalVisible(false)}
        constraintText={commentConstraintText}
        errorText={commentErrorText}
      />
      <ConfirmModal
        title={t(i18nKeys.challenges.challengeDetails.titles.markAsPublic)}
        message={t(i18nKeys.challenges.challengeDetails.messages.markAsPublic)}
        visible={confirmPublicAfterApproval}
        confirmBtnLabel={t(i18nKeys.challenges.challengeDetails.yesLabels.markChallengePublic)}
        cancelBtnLabel={t(i18nKeys.challenges.challengeDetails.cancelLabels.markChallengePublic)}
        onConfirm={handlePublicAfterApproval}
        onCancel={() => setConfirmPublicAfterApproval(false)}
      />
    </>
  );
};

export default ReviewButtonPanel;
