import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Challenge, ChallengeReviewableSection, ChallengeTask } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import ChallengeTaskDetail from './Task';
import { Button, Container, Header } from '@amzn/awsui-components-react';
import { ChallengeReviewPopover } from '../../challengesCommon/ChallengeReviewPopover';
import { useCreateChallenge } from '../../../../store/create-challenge.context';

interface ChallengeTasksDetailProps {
  challenge: Challenge;
}

const ChallengeTasksDetail: React.FC<ChallengeTasksDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { editMode, editedChallenge, addChallengeTask, removeChallengeTask } = useCreateChallenge();

  const getTaskLabel = (taskId: string): string => {
    const task: ChallengeTask | undefined = challenge.props.tasks.find(
      (challengeTask: ChallengeTask) => challengeTask.id === taskId
    );
    return task ? task.label : t(i18nKeys.challenges.challengeDetails.text.unknownTask);
  };

  return (
    <Container
      header={
        <Header variant="h2" actions={<ChallengeReviewPopover section={ChallengeReviewableSection.TASKS} />}>
          {t(i18nKeys.challenges.challengeDetails.headings.tasks)}
        </Header>
      }>
      <div>
        {challenge.props.tasks ? (
          challenge.props.tasks.map((task, i) => {
            return (
              <ChallengeTaskDetail
                key={i}
                task={task}
                getTaskLabel={getTaskLabel}
                removeTask={() => void removeChallengeTask}
              />
            );
          })
        ) : (
          <div>{t(i18nKeys.challenges.challengeDetails.text.noTasks)}</div>
        )}
      </div>
      {editMode && (
        <Button
          onClick={() => void addChallengeTask(editedChallenge?.challengeId || '', editedChallenge?.version || 0)}
          iconName="add-plus">
          {t(i18nKeys.challenges.subSections.tasks.addTask)}
        </Button>
      )}
    </Container>
  );
};

export default ChallengeTasksDetail;
