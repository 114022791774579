import { SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { Event } from '../../../types/Event';
import OwnerPermissions from '../eventDetailsSections/Summary/OwnerPermissions';
import SupportAndCollaboration from '../../common/CampaignEventComponents/SupportAndCollaboration';

interface OwnersAndPermissionsProps {
  target: Event;
}

const OwnersAndPermissions: React.FC<OwnersAndPermissionsProps> = ({ target }) => {
  return (
    <SpaceBetween direction="vertical" size="s">
      <OwnerPermissions target={target} targetType="event" />
      <SupportAndCollaboration target={target} />
    </SpaceBetween>
  );
};

export default OwnersAndPermissions;
