import { Badge, Button, Grid, Icon, SpaceBetween, Input } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditCampaignActions, useEditCampaign } from '../../store/edit-campaign.context';
import { EditEventActions, useEditEvent } from '../../store/edit-event.context';
import { i18nKeys } from '../../utils/i18n.utils';

export const EventTags: React.FC = ({}) => {
  const [selectedTag, setSelectedTag] = useState<string>('');
  const { editedEvent, handleUpdateEditEvent, newEditedEvent } = useEditEvent();
  const { editedCampaign, handleUpdateEditCampaign, newEditedCampaign } = useEditCampaign();
  const { t } = useTranslation();

  const removeTag = (tag: string) => {
    if (getEvent()) {
      handleUpdateEditEvent(EditEventActions.REMOVE_TAG, tag);
    } else if (getCampaign()) {
      handleUpdateEditCampaign(EditCampaignActions.REMOVE_TAG, tag);
    }
  };

  const addTag = () => {
    if (!selectedTag || typeof selectedTag !== 'string' || !selectedTag.trim()) {
      return;
    }

    if (getEvent()) {
      handleUpdateEditEvent(EditEventActions.ADD_TAG, selectedTag);
    } else if (getCampaign()) {
      handleUpdateEditCampaign(EditCampaignActions.ADD_TAG, selectedTag);
    }
    setSelectedTag('');
  };

  const getEvent = () => {
    return editedEvent || newEditedEvent;
  }

  const getCampaign = () => {
    return editedCampaign || newEditedCampaign;
  }

  return getEvent() || getCampaign() ? (
    <React.Fragment>
      <SpaceBetween direction="vertical" size="s">
        <Grid gridDefinition={[{ colspan: 6 }]}>
          <SpaceBetween direction="horizontal" size="s">
            {(getEvent() || getCampaign())?.tags.map((tag, i) => {
              return (
                <div key={`${tag}-${i}-button`} onClick={() => removeTag(tag)}>
                  <Badge key={`${tag}-${i}`} color="grey">
                    <span className="mr-5">{tag}</span>
                    <Icon name="status-negative" size="normal" variant="normal" />
                  </Badge>
                </div>
              );
            })}
          </SpaceBetween>
        </Grid>
        <Grid gridDefinition={[{ colspan: 4 }]}>
          <SpaceBetween direction="vertical" size="s">
            <Input
              onChange={({ detail }) => setSelectedTag(detail.value)}
              value={selectedTag}
              ariaLabel={t(i18nKeys.events.tags.aria)}
              placeholder={t(i18nKeys.events.tags.placeholder)}
            />
            {selectedTag && <Button onClick={addTag}>{t(i18nKeys.events.eventDetails.buttons.addNewTag)}</Button>}
          </SpaceBetween>
        </Grid>
      </SpaceBetween>
    </React.Fragment>
  ) : null;
};
