/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty-function */
import { SpaceBetween } from '@amzn/awsui-components-react';
import * as React from 'react';
import { ChallengeConfiguration } from '../../../types/Challenge';
import JamCodeEditor from '../../challenges/challengesCommon/JamCodeEditor';
import { CopyToClipboard } from '../CopyToClipboard';

interface ChallengeSshExampleProps {
  readonly challengeConfig: ChallengeConfiguration;
}

const ChallengeSshExample: React.FC<ChallengeSshExampleProps> = ({ challengeConfig }) => {
  const exampleTemplate = `AWSTemplateFormatVersion: 2010-09-09
Description: CFN Template using SSH KeyPair
Parameters:
  # A Parameter named {{ paramName }} is required in order to receive the name of the KeyPair when deploying the challenge.
  # The Parameter must be named {{ paramName }} to conform to AWS Jam convention. Any other name will not work.
  {{ paramName }}:
    Type: String
    Description: Name for the keypair used to SSH into EC2 instances
    # A default of "{{ defaultKeyName }}" is required here to conform to AWS Jam convention. Any other default will not work.
    Default: {{ defaultKeyName }}

Resources:
  LabInstance:
    Type: 'AWS::EC2::Instance'
    Properties:
      # Reference the {{ paramName }} parameter to allow the team to SSH into this EC2 instance.
      KeyName: !Ref {{ paramName }}
      InstanceType: t2.micro

Outputs:
  # An Output named {{ outputName }} is required in order to provide the KeyPair to the team with the correct name.
  # The Output must be named {{ outputName }} to conform to AWS Jam convention. Any other name will not work.
  {{ outputName }}:
    Description: The SSH KeyPair Name
    Value: !Ref {{ paramName }}
`;

  const getSampleYaml = (): string => {
    return exampleTemplate
      .replace(/{{\s*paramName\s*}}/g, challengeConfig.keyPairCfnParameterName)
      .replace(/{{\s*defaultKeyName\s*}}/g, challengeConfig.defaultLabKeyPairName)
      .replace(/{{\s*outputName\s*}}/g, challengeConfig.keyPairCfnOutputName);
  };

  return (
    <SpaceBetween size="s">
      <CopyToClipboard value={getSampleYaml()} />
      <div style={{ pointerEvents: 'none', opacity: '0.4' }}>
        <JamCodeEditor language={'yaml'} value={getSampleYaml()} setValue={() => {}} />
      </div>
    </SpaceBetween>
  );
};

export default ChallengeSshExample;
