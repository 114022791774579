import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { SideNavigation } from '@amzn/awsui-components-react';
import { useActiveRouteDetails } from '../../hooks/useActiveRouteDetails';
import { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation/interfaces';
import { i18nKeys } from '../../utils/i18n.utils';
import {
  CHALLENGE_DETAILS_ROUTES,
  challengeRoute,
  EVENT_DETAILS_ROUTES,
  CAMPAIGN_DETAILS_ROUTES,
  eventRoute,
  reviewChallengesRoute,
  campaignRoute,
  CHALLENGE_SETS_ROUTES,
  challengeSetRoute,
} from '../../routes';
import { useSplitPanel } from '../../store/split-panel.context';
import { useToolPanel } from '../../store/tool-panel.context';
import { useEditEvent } from '../../store/edit-event.context';
import { switchToLegacyConsole } from '@amzn/aws-jam-hybrid-console';
import { config } from '../../config/app-config';
import { NewBadge } from '../common/NewBadge';
import { useChallenges } from '../../store/challenge.context';
import { useEvents } from '../../store/events.context';
import { RoutePath } from '../../RoutePath';
import { useUser } from '../../store/user.context';
import { Challenge, ChallengeUtils, ChallengeWrapper } from '../../types/Challenge';
import { Nullable } from '../../types/common';
import { useChallengeSet } from '../../store/challenge-set.context';
import { useCampaigns } from '../../store/campaigns.context';
import { useEditCampaign } from '../../store/edit-campaign.context';

interface RouteSet {
  path: string;
  keyName: string;
  set: (val: string) => void;
}

/**
 * Types that allow attaching custom "onClick" behavior to side navigation items.
 */
type FollowDetailWithClick = SideNavigationProps.FollowDetail & { onClick?: () => void };
type SideNavItemWithClick = SideNavigationProps.Item & { onClick?: () => void };

export const SideNav: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const history = useHistory();
  const location = useLocation();
  const { activeRoute } = useActiveRouteDetails();
  const [activeNav, setActiveNav] = useState('');
  const [eventName, setEventName] = useState('');
  const [campaignId, setCampaignId] = useState('');
  const [challengeId, setChallengeId] = useState('');
  const [challengeSetId, setChallengeSetId] = useState('');
  const { toggleShowSplitPanel } = useSplitPanel();
  const { toggleShowToolPanel } = useToolPanel();
  const { newEventMode } = useEditEvent();
  const { newCampaignMode } = useEditCampaign();
  const { event } = useEvents();
  const { campaign } = useCampaigns();
  const { challengeWrapperMap } = useChallenges();
  const { challengeSet } = useChallengeSet();

  // Add new route paths here to have them be matched
  const routePaths: RouteSet[] = [
    {
      path: eventRoute.path,
      keyName: 'eventName',
      set: (val: string) => {
        setEventName(val);
      },
    },
    {
      path: challengeRoute.path,
      keyName: 'challengeId',
      set: (val: string) => setChallengeId(val),
    },
    {
      path: challengeSetRoute.path,
      keyName: 'challengeSetId',
      set: (val: string) => setChallengeSetId(val),
    },
    {
      path: campaignRoute.path,
      keyName: 'campaignId',
      set: (val: string) => {
        setCampaignId(val);
      },
    },
  ];

  const matchRoutePaths = () => {
    routePaths.forEach((route: RouteSet) => {
      // Setting type to any as the params are dynamic based on the param supplied and our strict typing expects params to be an empty object
      const path: string = route.path;
      const match: any = matchPath(location.pathname, {
        path,
        exact: false,
        strict: false,
      });

      route.set(match?.params[route.keyName] as string);
    });
  };

  // If the provided link is empty, do not redirect pages
  const onFollow = (e: CustomEvent<FollowDetailWithClick>) => {
    toggleShowSplitPanel(false);
    toggleShowToolPanel(false);
    e.preventDefault();

    if (e.detail.onClick) {
      e.detail.onClick();
    } else if (e.detail.href) {
      history.push(e.detail.href);
    }
  };

  React.useEffect(() => {
    if (location.hash) {
      if (newEventMode) setActiveNav(location.hash);
      if (newCampaignMode) setActiveNav(location.hash);
    } else {
      setActiveNav(activeRoute);
    }

    matchRoutePaths();
  }, [activeRoute, location.hash, eventName, campaignId, challengeId, challengeSetId, location.pathname]);

  const showChallengeRoute = (route: string) => {
    const challengeWrapper: ChallengeWrapper | undefined = challengeWrapperMap[challengeId];
    const challenge: Nullable<Challenge> = challengeWrapper?.latest;

    if (challenge) {
      switch (route) {
        case CHALLENGE_DETAILS_ROUTES.Assets.resolve(challengeId):
          return ChallengeUtils.isCollaborator(challenge, user);
        case CHALLENGE_DETAILS_ROUTES.IamPolicy.resolve(challengeId):
          return ChallengeUtils.isCollaborator(challenge, user) || challenge.isDemo;
        case CHALLENGE_DETAILS_ROUTES.CfnTemplate.resolve(challengeId):
          return ChallengeUtils.isCollaborator(challenge, user) || challenge.isDemo;
        case CHALLENGE_DETAILS_ROUTES.Wiki.resolve(challengeId):
          return ChallengeUtils.isCollaborator(challenge, user) || challenge.isDemo;
        case CHALLENGE_DETAILS_ROUTES.Comments.resolve(challengeId):
          return ChallengeUtils.isCollaborator(challenge, user);
        case CHALLENGE_DETAILS_ROUTES.Testing.resolve(challengeId):
          return ChallengeUtils.isCollaborator(challenge, user);
        case CHALLENGE_DETAILS_ROUTES.Feedback.resolve(challengeId):
          return ChallengeUtils.isCollaborator(challenge, user);
        case CHALLENGE_DETAILS_ROUTES.Revisions.resolve(challengeId):
          return ChallengeUtils.isCollaborator(challenge, user);
        case CHALLENGE_DETAILS_ROUTES.Translation.resolve(challengeId):
          return ChallengeUtils.isCollaborator(challenge, user) && challenge.majorVersion && challenge.majorVersion > 0;
        case CHALLENGE_DETAILS_ROUTES.FacilitatorNotes.resolve(challengeId):
          return ChallengeUtils.isCollaborator(challenge, user);
        case CHALLENGE_DETAILS_ROUTES.Help.resolve(challengeId):
          return ChallengeUtils.isCollaborator(challenge, user);
        default:
          return true;
      }
    }
    return false;
  };

  const allChallengeDetailRoutes: SideNavigationProps.Item[] = [
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.summary),
      href: CHALLENGE_DETAILS_ROUTES.Summary.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.learningPlan),
      href: CHALLENGE_DETAILS_ROUTES.LearningOutcome.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.settings),
      href: CHALLENGE_DETAILS_ROUTES.Settings.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.tasks),
      href: CHALLENGE_DETAILS_ROUTES.Tasks.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.assetsResources),
      href: CHALLENGE_DETAILS_ROUTES.Assets.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.iamPolicy),
      href: CHALLENGE_DETAILS_ROUTES.IamPolicy.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.cfnTemplate),
      href: CHALLENGE_DETAILS_ROUTES.CfnTemplate.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.nextSteps),
      href: CHALLENGE_DETAILS_ROUTES.NextSteps.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.wiki),
      href: CHALLENGE_DETAILS_ROUTES.Wiki.resolve(challengeId),
    },
    {
      type: 'divider',
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.collaborators),
      href: CHALLENGE_DETAILS_ROUTES.Collaborators.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.issues),
      href: CHALLENGE_DETAILS_ROUTES.Issues.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.comments),
      href: CHALLENGE_DETAILS_ROUTES.Comments.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.testing),
      href: CHALLENGE_DETAILS_ROUTES.Testing.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.feedback),
      href: CHALLENGE_DETAILS_ROUTES.Feedback.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.stats),
      href: CHALLENGE_DETAILS_ROUTES.Stats.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.revisions),
      href: CHALLENGE_DETAILS_ROUTES.Revisions.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.translation),
      href: CHALLENGE_DETAILS_ROUTES.Translation.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.facilitatorNotes),
      href: CHALLENGE_DETAILS_ROUTES.FacilitatorNotes.resolve(challengeId),
    },
    {
      type: 'link',
      text: t(i18nKeys.challenges.subNavigation.help),
      href: CHALLENGE_DETAILS_ROUTES.Help.resolve(challengeId),
    },
  ];

  const allEventDetailRoutes: SideNavigationProps.Item[] = [
    {
      type: 'link',
      text: t(i18nKeys.events.subNavigation.summary),
      href: EVENT_DETAILS_ROUTES.Summary.resolve(eventName),
    },
    {
      type: 'link',
      text: t(i18nKeys.events.subNavigation.challenges),
      href: EVENT_DETAILS_ROUTES.Challenges.resolve(eventName),
    },
    {
      type: 'link',
      text: t(i18nKeys.events.subNavigation.advancedSettings),
      href: EVENT_DETAILS_ROUTES.Settings.resolve(eventName),
    },
    {
      type: 'link',
      text: t(i18nKeys.events.subNavigation.comments),
      href: EVENT_DETAILS_ROUTES.Comments.resolve(eventName),
    },
    {
      type: 'link',
      text: t(i18nKeys.events.subNavigation.participants),
      href: EVENT_DETAILS_ROUTES.Participants.resolve(eventName),
    },
    {
      type: 'link',
      text: t(i18nKeys.events.subNavigation.labs),
      href: EVENT_DETAILS_ROUTES.Labs.resolve(eventName),
    },
    {
      type: 'link',
      text: t(i18nKeys.events.subNavigation.feedback),
      href: EVENT_DETAILS_ROUTES.Feedback.resolve(eventName),
    },
    {
      type: 'link',
      text: t(i18nKeys.events.subNavigation.revisions),
      href: EVENT_DETAILS_ROUTES.Revisions.resolve(eventName),
    },
    {
      type: 'link',
      text: t(i18nKeys.events.subNavigation.report),
      href: EVENT_DETAILS_ROUTES.Report.resolve(eventName),
    },
  ];

  const allCampaignDetailsRoutes: SideNavigationProps.Item[] = [
    {
      type: 'link',
      text: t(i18nKeys.campaigns.subNavigation.summary),
      href: CAMPAIGN_DETAILS_ROUTES.Summary.resolve(campaignId),
    },
    {
      type: 'link',
      text: t(i18nKeys.campaigns.subNavigation.challenges),
      href: CAMPAIGN_DETAILS_ROUTES.Challenges.resolve(campaignId),
    },
    {
      type: 'link',
      text: t(i18nKeys.campaigns.subNavigation.settings),
      href: CAMPAIGN_DETAILS_ROUTES.Settings.resolve(campaignId),
    },
    {
      type: 'link',
      text: t(i18nKeys.campaigns.subNavigation.advancedSettings),
      href: CAMPAIGN_DETAILS_ROUTES.AdvancedSettings.resolve(campaignId),
    },
    { type: 'divider' },
    {
      type: 'link',
      text: t(i18nKeys.campaigns.subNavigation.comments),
      href: CAMPAIGN_DETAILS_ROUTES.Comments.resolve(campaignId),
    },
  ];

  const campaignGroupItem: SideNavigationProps.Item = {
    type: 'link',
    text: t(i18nKeys.campaigns.subNavigation.groups),
    href: CAMPAIGN_DETAILS_ROUTES.Groups.resolve(campaignId),
  };

  if (campaign?.wasEverApproved) {
    allCampaignDetailsRoutes.splice(5, 0, campaignGroupItem);
  }

  // Get event title when on an event detail page
  const eventTitle: string = event?.title || 'Untitled Event';
  const campaignTitle: string = campaign?.title || 'Untitled Campaign';

  // get challenge title when on a challenge detail page
  const challengeTitle: string = challengeWrapperMap[challengeId]?.latest?.props.title || 'Untitled Challenge';

  // get challenge set title when on a challenge set detail page
  const challengeSetTitle: string = challengeSet?.title || 'Untitled Challenge Set';

  // filter through all challenge routes for only link routes allowed to the user
  const challengeDetailRoutes = allChallengeDetailRoutes.filter((item: SideNavigationProps.Item) => {
    if (item.type === 'link') {
      return showChallengeRoute(item.href);
    }
    return true;
  });

  return (
    <SideNavigation
      activeHref={activeNav}
      header={{
        href: RoutePath.ROOT,
        text: 'AWS Jam',
      }}
      items={[
        {
          type: 'link-group',
          text: t(i18nKeys.events.title),
          href: RoutePath.EVENTS,
          items:
            !newEventMode && eventName != null
              ? [
                  {
                    type: 'link-group',
                    text: eventTitle,
                    href: EVENT_DETAILS_ROUTES.Summary.resolve(eventName),
                    items: allEventDetailRoutes,
                  },
                ]
              : [],
        },
        {
          type: 'link-group',
          text: t(i18nKeys.campaigns.title),
          href: RoutePath.CAMPAIGNS,
          items:
            !newCampaignMode && campaignId != null
              ? [
                  {
                    type: 'link-group',
                    text: campaignTitle,
                    href: CAMPAIGN_DETAILS_ROUTES.Summary.resolve(campaignId),
                    items: allCampaignDetailsRoutes,
                  },
                ]
              : [],
        },
        {
          type: 'link-group',
          text: t(i18nKeys.challenges.title),
          href: RoutePath.CHALLENGES,
          items:
            challengeWrapperMap[challengeId] != null
              ? [
                  {
                    type: 'link-group',
                    text: challengeTitle,
                    href: CHALLENGE_DETAILS_ROUTES.Summary.resolve(challengeId),
                    items: challengeDetailRoutes,
                  },
                ]
              : [
                  {
                    type: 'link-group',
                    text: t(i18nKeys.challenges.challengeSets),
                    href: RoutePath.CHALLENGE_SETS,
                    items:
                      challengeSetId != null
                        ? [
                            {
                              type: 'link',
                              text: challengeSetTitle,
                              href: CHALLENGE_SETS_ROUTES.Summary.resolve(challengeSetId),
                            },
                          ]
                        : [],
                  },
                ],
        },
        { type: 'link', text: t(i18nKeys.labs.title), href: RoutePath.LABS },
        { type: 'link', text: t(i18nKeys.reports.title), href: RoutePath.REPORTS },
        ...(config.isChallengeReviewEnabled && (user?.isBarRaiser || user?.isChallengeAdmin || user?.isSuperAdmin)
            ? ([
              {
                type: 'expandable-link-group',
                text: t(i18nKeys.readyForReview.title),
                defaultExpanded: true,
                href: '#',
                items: [
                  {
                    type: 'link',
                    text: t(i18nKeys.challenges.title),
                    info: <NewBadge />,
                    href: reviewChallengesRoute.path,
                  },
                ],
              },
          ] as SideNavItemWithClick[])
        : []),
        ...(config.isUsagePlanEnabled
            ? ([
              { type: 'link', text: t(i18nKeys.usagePlan.title), info: <NewBadge />, href: RoutePath.USAGE_PLANS },
          ] as SideNavItemWithClick[])
        : []),
        { type: 'divider' },
        { type: 'link', text: t(i18nKeys.prizes.title), href: RoutePath.PRIZES },
        { type: 'link', text: t(i18nKeys.documentation.title), href: RoutePath.DOCUMENTATION },
        ...(config.useHybridExperience
          ? ([
              { type: 'divider' },
              {
                type: 'link',
                external: true,
                text: t(i18nKeys.hybrid.original),
                href: '',
                onClick: () => switchToLegacyConsole(),
              },
            ] as SideNavItemWithClick[])
          : []),
        { type: 'link', text: t(i18nKeys.settings.title), href: RoutePath.SETTINGS },
      ]}
      onFollow={onFollow}
    />
  );
};
