/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import {
  Box,
  Button,
  ExpandableSection,
  Header,
  LineChart,
  MixedLineBarChartProps,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useComponentDidMountEffect } from '../../../../hooks/useComponentDidMountEffect';
import { useApi } from '../../../../store/api.context';
import { Challenge, ChallengeFeedbackTrend } from '../../../../types/Challenge';
import { NullableNumber } from '../../../../types/common';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { safeString } from '../../../../utils/string.utils';

const DEFAULT_PAST_MONTHS = 3;

declare type LineSeries<T> = MixedLineBarChartProps.LineDataSeries<T> | MixedLineBarChartProps.ThresholdSeries;
interface ChallengeLatestFeedbackTrendDetailProps {
  challenge: Challenge;
}

const ChallengeLatestFeedbackTrendDetail: React.FC<ChallengeLatestFeedbackTrendDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { challengesApi } = useApi();

  const [series, setSeries] = useState<LineSeries<Date>[]>([]);
  const [global, setGlobal] = useState(false);

  // Feedback Trends
  const [feedbackTrends, setFeedbackTrends] = useState<ChallengeFeedbackTrend[]>([]);
  const [allFeedbackTrends, setAllFeedbackTrends] = useState<ChallengeFeedbackTrend[]>([]);

  // Daily & Global Data
  const [dailyAllData, setDailyAllData] = useState<{ x: Date; y: NullableNumber }[]>([]);
  const [globalDailyAllData, setGlobalDailyAllData] = useState<{ x: Date; y: NullableNumber }[]>();

  const [timeRangeStart, setTimeRangeStart] = useState<number>(0);
  const [timeRangeEnd, setTimeRangeEnd] = useState<number>(100000000);

  const [oneDayMovingAverage, setOneDayMovingAverage] = useState<MixedLineBarChartProps.Datum<Date>[]>([]);
  const [threeDayMovingAverage, setThreeDayMovingAverage] = useState<MixedLineBarChartProps.Datum<Date>[]>([]);
  const [fiveDayMovingAverage, setFiveDayMovingAverage] = useState<MixedLineBarChartProps.Datum<Date>[]>([]);
  const [weeklyMovingAverage, setWeeklyMovingAverage] = useState<MixedLineBarChartProps.Datum<Date>[]>([]);

  useComponentDidMountEffect(async () => {
    // load in feedback trends
    setFeedbackTrends(await challengesApi.getChallengeFeedbackTrend(safeString(challenge.id)));
    setAllFeedbackTrends(await challengesApi.getChallengeFeedbackTrendAll(safeString(challenge.id)));
  });

  useEffect(() => {
    if (feedbackTrends.length > 0) {
      // Setup Challenge Trend Feedback Dataset
      getFeedbackTrendDataset();

      // Setup Challenge Trend Moving Average Datasets
      setOneDayMovingAverage(calculateMovingAverage(1));
      setThreeDayMovingAverage(calculateMovingAverage(3));
      setFiveDayMovingAverage(calculateMovingAverage(5));
      setWeeklyMovingAverage(calculateMovingAverage(7));

      // Setup series for line chart
      setSeries([
        {
          title: t(i18nKeys.challenges.challengeDetails.chart.feedback.oneDayFeedbackAverage),
          type: 'line',
          data: oneDayMovingAverage,
        },
        {
          title: t(i18nKeys.challenges.challengeDetails.chart.feedback.threeDayRollingAverage),
          type: 'line',
          data: threeDayMovingAverage,
        },
        {
          title: t(i18nKeys.challenges.challengeDetails.chart.feedback.fiveDayRollingAverage),
          type: 'line',
          data: fiveDayMovingAverage,
        },
        {
          title: t(i18nKeys.challenges.challengeDetails.chart.feedback.weeklyRollingAverage),
          type: 'line',
          data: weeklyMovingAverage,
        },
      ]);
    }
  }, [allFeedbackTrends]);

  // implement rolling average options
  const calculateMovingAverage = (n: number): MixedLineBarChartProps.Datum<Date>[] => {
    const newDataSet: MixedLineBarChartProps.Datum<Date>[] = [];

    const allData = global ? globalDailyAllData : dailyAllData;

    if (allData && feedbackTrends && feedbackTrends.length > 0) {
      let days = 0;
      let sum = 0;

      allData.forEach((data) => {
        const rating: NullableNumber = data.y;
        if (rating) {
          sum += rating;
        }
        days++;
        if (days === n) {
          const average: number = +(sum / n).toFixed(2);
          newDataSet.push({
            x: data.x,
            y: average,
          });

          days = 0;
          sum = 0;
        }
      });
    }
    return newDataSet;
  };

  // get daily feedback trend
  const getFeedbackTrendDataset = (): void => {
    const fbDataset = global ? allFeedbackTrends : feedbackTrends;

    const origEntries = fbDataset.map((fb: ChallengeFeedbackTrend) => {
      const date: Date = new Date(`${fb.date}`); // fill in the earliest time
      return {
        x: date,
        y: fb.avgRating,
      };
    });

    // give a threshold of 90 days to show all challenge feedback data; prevents largely differing timeline scale.
    const dateLimit = new Date();
    dateLimit.setMonth(dateLimit.getMonth() - DEFAULT_PAST_MONTHS);

    // ensure entries are sorted in accending order based on date time
    const entries = origEntries
      .filter((entry) => entry.x.getTime() >= dateLimit.getTime())
      .sort((a, b) => a.x.getTime() - b.x.getTime());

    const fbData = allFeedbackTrends.filter((entry) => {
      return new Date(`${entry.date}`).getTime() >= dateLimit.getTime();
    });

    if (entries.length > 0) {
      setTimeRangeStart(entries[0].x.getTime());
      setTimeRangeEnd(entries[entries.length - 1].x.getTime());
    }

    if (global) {
      setGlobalDailyAllData(entries);
    } else {
      setDailyAllData(entries);
    }

    setFeedbackTrends(fbData);
  };

  const toggleGlobalTrends = () => {
    // TODO: Implement button to toggle global trends
    setGlobal(!global);
  };

  return (
    <ExpandableSection
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.challenges.challengeDetails.headings.latestFeedbackTrend)}</Header>}>
      <SpaceBetween size="m" direction="vertical">
        <TextContent className="secondary-text">
          {t(i18nKeys.challenges.challengeDetails.text.feedbackTrendDescription)}
        </TextContent>
        <SpaceBetween size="m" direction="vertical">
          <LineChart
            series={series}
            xDomain={[new Date(timeRangeStart), new Date(timeRangeEnd)]}
            yDomain={[0, 5]}
            i18nStrings={{
              filterLabel: t(i18nKeys.challenges.challengeDetails.chart.feedback.filterLabel),
              filterPlaceholder: t(i18nKeys.challenges.challengeDetails.chart.feedback.filterPlaceholder),
              filterSelectedAriaLabel: 'selected',
              legendAriaLabel: 'Legend',
              chartAriaRoleDescription: 'line chart',
              xTickFormatter: (e) =>
                e
                  .toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: !1,
                  })
                  .split(',')
                  .join('\n'),
              yTickFormatter: undefined,
            }}
            ariaLabel="Single data series line chart"
            errorText={t(i18nKeys.challenges.challengeDetails.chart.feedback.errorText)}
            height={300}
            loadingText={t(i18nKeys.challenges.challengeDetails.chart.feedback.loadingText)}
            recoveryText={t(i18nKeys.challenges.challengeDetails.chart.feedback.recoveryText)}
            xScaleType="time"
            xTitle={t(i18nKeys.challenges.challengeDetails.chart.feedback.xAxisTitle)}
            yTitle={t(i18nKeys.challenges.challengeDetails.chart.feedback.yAxisTitle)}
            empty={
              <Box textAlign="center" color="inherit">
                <b>{t(i18nKeys.challenges.challengeDetails.chart.feedback.emptyState)}</b>
                <Box variant="p" color="inherit">
                  {t(i18nKeys.challenges.challengeDetails.chart.feedback.emptyBox)}
                </Box>
              </Box>
            }
            noMatch={
              <Box textAlign="center" color="inherit">
                <b>{t(i18nKeys.challenges.challengeDetails.chart.feedback.noMatchState)}</b>
                <Box variant="p" color="inherit">
                  {t(i18nKeys.challenges.challengeDetails.chart.feedback.noMatchStateBox)}
                </Box>
                <Button>{t(i18nKeys.challenges.challengeDetails.chart.feedback.noMatchButton)}</Button>
              </Box>
            }
          />
        </SpaceBetween>
      </SpaceBetween>
    </ExpandableSection>
  );
};

export default ChallengeLatestFeedbackTrendDetail;
