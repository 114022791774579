/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Box, Button, Form, FormField, Grid, Input, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../store/api.context';
import { useFlashbars } from '../../../store/flashbar.context';
import { i18nKeys } from '../../../utils/i18n.utils';
import { preProdLogger } from '../../../utils/log.utils';
import { isEmailListValid } from '../../../utils/string.utils';
import { ConfirmModal } from '../../common/ConfirmModal';
import UsersTable from './UsersTable/UsersTable';

const UserManagement: React.FC = () => {
  const [emails, setEmails] = useState('');
  const { accountApi } = useApi();
  const { addSuccessFlashbar, addWarningFlashbar, addErrorFlashbar } = useFlashbars();
  const [confirmCreateUsersModalVisible, setConfirmCreateUsersModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const isValidEmailInput = (): boolean => {
    return isEmailListValid(splitEmails(emails));
  };

  const splitEmails = (newEmails: string) => {
    return newEmails.split(/\s*[,; ]\s*/);
  };

  const handleCreateUsers = async () => {
    setLoading(true);
    const results = await accountApi.createUsers(splitEmails(emails));
    let successMessage;
    let warningMessage;
    let errorMessage;
    const alreadyExistsUsers = results?.alreadyExistsUsers;
    const otherErrorsUsers = results?.otherErrorsUsers;
    const successfulUsers = results?.successfulUsers;
    if (successfulUsers && successfulUsers.length > 0) {
      const successfulUsersString = successfulUsers.join(', ');
      successMessage = t(i18nKeys.settings.userManagement.createUsersFeedback.success, {
        emails: successfulUsersString,
      });
    }
    if (alreadyExistsUsers && alreadyExistsUsers.length > 0) {
      const alreadyExistsUsersString = alreadyExistsUsers.join(', ');
      warningMessage = t(i18nKeys.settings.userManagement.createUsersFeedback.warning, {
        emails: alreadyExistsUsersString,
      });
    }
    if (otherErrorsUsers && otherErrorsUsers.length > 0) {
      const otherErrorsString = otherErrorsUsers.join(', ');
      preProdLogger(otherErrorsUsers);
      errorMessage = t(i18nKeys.settings.userManagement.createUsersFeedback.error, { emails: otherErrorsString });
    }
    setEmails('');
    setConfirmCreateUsersModalVisible(false);
    setLoading(false);

    if (successMessage) {
      addSuccessFlashbar(successMessage);
    }

    if (warningMessage) {
      addWarningFlashbar(warningMessage);
    }

    if (errorMessage) {
      addErrorFlashbar(errorMessage);
    }
  };

  return (
    <Box>
      <ConfirmModal
        visible={confirmCreateUsersModalVisible}
        onCancel={() => setConfirmCreateUsersModalVisible(false)}
        onConfirm={() => handleCreateUsers()}
        disabled={loading}
      />
      <SpaceBetween direction="vertical" size="s">
        <Grid gridDefinition={[{ colspan: 6 }]}>
          <Form>
            <SpaceBetween direction="vertical" size="s">
              <FormField
                className="mt-8"
                label={t(i18nKeys.settings.userManagement.createUsers.label)}
                description={t(i18nKeys.settings.userManagement.createUsers.description)}>
                <Input
                  className="long-select-input"
                  placeholder="user1@example.com, user2@example.com"
                  value={emails}
                  onChange={({ detail }) => setEmails(detail.value)}
                />
                {emails.length > 0 && !isValidEmailInput() && (
                  <div className="warning">{t(i18nKeys.settings.errors.invalidEmailFormat)}</div>
                )}
              </FormField>
              <Button
                variant="primary"
                disabled={!isValidEmailInput()}
                onClick={() => setConfirmCreateUsersModalVisible(true)}>
                {t(i18nKeys.settings.userManagement.createUsers.button)}
              </Button>
            </SpaceBetween>
          </Form>
        </Grid>
        <UsersTable emailsLoading={loading} />
      </SpaceBetween>
    </Box>
  );
};
export default UserManagement;
