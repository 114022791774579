import { ExpandableSection, Header, Select } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useComponentDidMountEffect } from '../../../../hooks/useComponentDidMountEffect';
import { useApi } from '../../../../store/api.context';
import {
  Challenge,
  ChallengeFeedback,
  ChallengeFeedbackSortOptions,
  ChallengeFeedbackSortType,
  ChallengeFeedbackSummary,
} from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import {
  getAverageDifficulty,
  getAverageRating,
  getLearningSomethingNewPercentage,
} from '../../../../utils/rating.utils';
import { safeString } from '../../../../utils/string.utils';
import CircleRating from '../../../common/CircleRating';
import { Column } from '../../../common/Column';
import { Columns } from '../../../common/Columns';
import { HorizontalRule } from '../../../common/HorizontalRule';
import { PageHeader } from '../../../common/PageHeader';
import StarRating from '../../../common/StarRating';
import RatingSummary from '../../challengesCommon/ratingSummary/RatingSummary';
import { FeedbackItem } from './feedbackItems/FeedbackItem';

const ROWS_PER_PAGE = 40;

interface ChallengeReviewsDetailProps {
  challenge: Challenge;
}

const ChallengeReviewsDetail: React.FC<ChallengeReviewsDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { challengesApi } = useApi();

  const [loading, setLoading] = useState(false);

  const [feedbackList, setFeedbackList] = useState<ChallengeFeedback[]>([]);
  const [feedbackWithComments, setFeedbackWithComments] = useState<ChallengeFeedback[]>();
  const [feedbackSortOptions, setFeedbackSortOption] = useState<ChallengeFeedbackSortOptions>({
    sortType: ChallengeFeedbackSortType.DATE,
    ascending: false,
    label: t(i18nKeys.challenge.optionDefinitions.sortType.sortByNewest),
  });

  const [summary, setSummary] = useState<ChallengeFeedbackSummary>();
  const [learnedSomethingNewPercentage, setLearnedSomethingNewPercentage] = useState(0);
  const [sortOption, setSortOption] = useState<OptionDefinition>({ label: 'Sort by newest', value: '1' });

  useComponentDidMountEffect(async () => {
    setSummary(await challengesApi.getChallengeFeedbackSummary(safeString(challenge.id), false));
    void loadNextPage(false);
  });

  useEffect(() => {
    setLearnedSomethingNewPercentage(getLearningSomethingNewPercentage(summary));
  }, [summary]);

  const loadNextPage = async (reload = false) => {
    // don't allow concurrent page requests
    if (!reload && loading) {
      return;
    }

    setLoading(true);

    const lastFeedback: ChallengeFeedback | null =
      feedbackList && feedbackList.length > 0 ? feedbackList[feedbackList.length - 1] : null;

    if (lastFeedback) {
      const rows = await challengesApi.getChallengeFeedback(
        safeString(challenge.id),
        ROWS_PER_PAGE,
        feedbackSortOptions,
        lastFeedback
      );

      const hasRows = rows.length > 0;

      if (hasRows) {
        // update the feedback list
        addRows(rows);

        const partialPage = rows.length > 0 && rows.length < ROWS_PER_PAGE;
        const hasComments = withComments(rows).length > 0;

        // if this is a partial page, or this page has no comments
        // then load one more page
        if (partialPage || !hasComments) {
          // if this is already a reload, then dont load another page
          if (!reload) {
            return;
          }
        }
      }
    }
  };

  const addRows = (feedbacks: ChallengeFeedback[]) => {
    setFeedbackList([...feedbackList, ...feedbacks]);
    updateFeedbackWithComments();
  };

  const withComments = (feedbacks: ChallengeFeedback[]): ChallengeFeedback[] => {
    return (feedbacks || []).filter((f) => f.notes != null && f.notes.length > 0);
  };

  const updateFeedbackWithComments = () => {
    setFeedbackWithComments(withComments(feedbackList));
  };

  const updateSortOption = (selectedOption: OptionDefinition) => {
    const sortOptionIndex = Number(selectedOption.value);
    setFeedbackSortOption(getFeedbackSortOptions()[sortOptionIndex]);
    setSortOption(selectedOption);
  };

  const getFeedbackSortOptions = (): ChallengeFeedbackSortOptions[] => {
    return [
      {
        sortType: ChallengeFeedbackSortType.DATE,
        ascending: false,
        label: t(i18nKeys.challenge.optionDefinitions.sortType.sortByNewest),
      },
      {
        sortType: ChallengeFeedbackSortType.DATE,
        ascending: true,
        label: t(i18nKeys.challenge.optionDefinitions.sortType.sortByOldest),
      },
      {
        sortType: ChallengeFeedbackSortType.RATING,
        ascending: true,
        label: t(i18nKeys.challenge.optionDefinitions.sortType.sortByLowestRating),
      },
      {
        sortType: ChallengeFeedbackSortType.RATING,
        ascending: false,
        label: t(i18nKeys.challenge.optionDefinitions.sortType.sortByHighestRating),
      },
    ];
  };

  return (
    <ExpandableSection
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.challenges.challengeDetails.headings.reviews)}</Header>}>
      <Columns columns={3} variant="default">
        <Column size="s">
          <Header variant="h3">
            <strong>{t(i18nKeys.challenges.challengeDetails.titles.rating)}</strong>
          </Header>
          {summary && <StarRating value={getAverageRating(summary)} valueCount={summary.total} />}
          <RatingSummary
            one={summary ? summary.rating1 : 0}
            two={summary ? summary.rating2 : 0}
            three={summary ? summary.rating3 : 0}
            four={summary ? summary.rating4 : 0}
            five={summary ? summary.rating5 : 0}
            total={summary ? summary.total : 0}
            color={'darkorange'}
            ratingUnit={'stars'}
          />
        </Column>
        <Column size="s">
          <Header variant="h3">
            <strong>{t(i18nKeys.challenges.challengeDetails.titles.difficulty)}</strong>
          </Header>
          <CircleRating value={getAverageDifficulty(summary)} />
          <RatingSummary
            one={summary ? summary.difficulty1 : 0}
            two={summary ? summary.difficulty2 : 0}
            three={summary ? summary.difficulty3 : 0}
            four={summary ? summary.difficulty4 : 0}
            five={summary ? summary.difficulty5 : 0}
            total={summary ? summary.total : 0}
            color={'#0275d8'}
            ratingUnit={'stars'}
          />
        </Column>
        <Column size="s">
          <Header variant="h3">
            <strong>{t(i18nKeys.challenges.challengeDetails.titles.learningPlan)}</strong>
          </Header>
          {summary && summary.didNotLearnSomethingNew + summary.learnedSomethingNew > 0 ? (
            <div>
              {learnedSomethingNewPercentage}%
              <small style={{ marginLeft: '0.5rem' }}>
                {t(i18nKeys.challenges.challengeDetails.text.learnedSomethingNew)}
              </small>
              <br />
              <small>
                (<strong>{t(i18nKeys.general.yes)}:</strong> {summary.learnedSomethingNew},{' '}
                <strong>{t(i18nKeys.general.no)}:</strong>
                {summary.didNotLearnSomethingNew}, <strong>{t(i18nKeys.general.nA)}:</strong>
                {summary.total - summary.learnedSomethingNew - summary.didNotLearnSomethingNew})
              </small>
            </div>
          ) : (
            <div>{t(i18nKeys.general.nA)}</div>
          )}
        </Column>
      </Columns>
      <HorizontalRule />
      <PageHeader
        title={t(i18nKeys.challenges.challengeDetails.titles.allReviews)}
        buttons={[
          <Select
            key="1"
            selectedOption={sortOption}
            onChange={({ detail }) => updateSortOption(detail.selectedOption)}
            options={[
              { label: t(i18nKeys.challenge.optionDefinitions.sortType.sortByNewest), value: '0' },
              { label: t(i18nKeys.challenge.optionDefinitions.sortType.sortByOldest), value: '1' },
              { label: t(i18nKeys.challenge.optionDefinitions.sortType.sortByLowestRating), value: '2' },
              { label: t(i18nKeys.challenge.optionDefinitions.sortType.sortByHighestRating), value: '3' },
            ]}
            selectedAriaLabel={t(i18nKeys.general.selected)}
          />,
        ]}
      />
      {feedbackWithComments && feedbackWithComments.length > 0 ? (
        feedbackWithComments.map((feedback) => {
          <FeedbackItem challengeFeedback={feedback} />;
        })
      ) : (
        <div>{t(i18nKeys.challenges.challengeDetails.text.challengeFeedbackEmptyState)}</div>
      )}
    </ExpandableSection>
  );
};

export default ChallengeReviewsDetail;
