import {
  Box,
  Button,
  Form,
  FormField,
  Grid,
  Header,
  Input,
  SpaceBetween,
  Textarea,
} from '@amzn/awsui-components-react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLSE } from '../../../store/lse.context';
import { LSEMessage } from '../../../types/Lse';
import { i18nKeys } from '../../../utils/i18n.utils';
import { fromPlainObject } from '../../../utils/mapper.utils';

const Settings: React.FC = () => {
  const emptyLseMessage = fromPlainObject({ text: '', url: undefined }, LSEMessage) as LSEMessage;
  const [bannerMessage, setBannerMessage] = useState('');
  const [learnMoreUrl, setLearnMoreUrl] = useState<string | undefined>();
  const { currentLSE, createLSE, updateLSE, deactivateLSE } = useLSE();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  // eslint-disable-next-line no-shadow
  enum LSE_ACTIONS {
    UPDATE = 'update',
    CREATE = 'create',
    DISABLE = 'disable',
  }

  useEffect(() => {
    setBannerMessage(currentLSE?.text || '');
    setLearnMoreUrl(currentLSE?.url || undefined);
    setLoading(false);
  }, [currentLSE]);

  const hasNoChanges = (): boolean => {
    const editedLSE = fromPlainObject({ text: bannerMessage, url: learnMoreUrl }, LSEMessage) as LSEMessage;
    // Polaris input resets this to empty string when LSEMessage.url can be undefined, this forces equality in that case
    if (editedLSE.url === '') {
      editedLSE.url = undefined;
    }
    return _.isEqual(editedLSE, currentLSE || emptyLseMessage);
  };

  const handleLSEAction = (action: LSE_ACTIONS) => {
    setLoading(true);
    const editedLSE = fromPlainObject({ text: bannerMessage, url: learnMoreUrl }, LSEMessage) as LSEMessage;
    switch (action) {
      case LSE_ACTIONS.CREATE:
        createLSE(editedLSE);
        return;
      case LSE_ACTIONS.UPDATE:
        updateLSE(editedLSE);
        return;
      case LSE_ACTIONS.DISABLE:
        deactivateLSE();
        setBannerMessage('');
        setLearnMoreUrl('');
        return;
    }
  };

  return (
    <Box>
      <Grid gridDefinition={[{ colspan: 6 }]}>
        <Form
          header={
            <Header variant="h2" description={t(i18nKeys.settings.lse.description)}>
              LSE
            </Header>
          }
          actions={
            <SpaceBetween direction="horizontal" size="s">
              {!currentLSE?.text && (
                <Button
                  className="button-right"
                  variant="primary"
                  disabled={hasNoChanges()}
                  loading={loading}
                  onClick={() => handleLSEAction(LSE_ACTIONS.CREATE)}>
                  {t(i18nKeys.settings.lse.buttons.create)}
                </Button>
              )}
              {currentLSE?.text && (
                <Button
                  className="button-right"
                  variant="normal"
                  onClick={() => handleLSEAction(LSE_ACTIONS.DISABLE)}
                  loading={loading}>
                  {t(i18nKeys.settings.lse.buttons.disable)}
                </Button>
              )}
              {currentLSE?.text && (
                <Button
                  className="button-right"
                  variant="primary"
                  disabled={hasNoChanges()}
                  onClick={() => handleLSEAction(LSE_ACTIONS.UPDATE)}
                  loading={loading}>
                  {t(i18nKeys.settings.lse.buttons.update)}
                </Button>
              )}
            </SpaceBetween>
          }>
          <SpaceBetween direction="vertical" size="s">
            <FormField label={t(i18nKeys.settings.lse.labels.bannerMessage)}>
              <Textarea
                placeholder={t(i18nKeys.settings.lse.placeholder.bannerMessage)}
                onChange={({ detail }) => setBannerMessage(detail.value)}
                value={bannerMessage}
              />
            </FormField>
            <FormField label={t(i18nKeys.settings.lse.labels.learnMoreURL)}>
              <Input
                placeholder="https://health.aws.amazon.com"
                value={learnMoreUrl || ''}
                onChange={({ detail }) => setLearnMoreUrl(detail.value)}
              />
            </FormField>
          </SpaceBetween>
        </Form>
      </Grid>
    </Box>
  );
};

export default Settings;
