import { useCollection } from '@amzn/awsui-collection-hooks';
import { Pagination, Table, TextFilter } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useComponentDidMountEffect } from '../../../../../hooks/useComponentDidMountEffect';
import { useApi } from '../../../../../store/api.context';
import { Challenge } from '../../../../../types/Challenge';
import { TinyEvent } from '../../../../../types/Event';
import { i18nKeys } from '../../../../../utils/i18n.utils';
import { safeString } from '../../../../../utils/string.utils';
import { paginationLabels } from '../../../../../utils/table.utils';
import { TableEmptyState } from '../../../../common/TableEmptyState';
import { TableHeader } from '../../../../common/TableHeader';
import { COLUMN_DEFINITIONS, filteringFunction } from './recent-events-list-config';

interface ChallengeRecentEventsDetailProps {
  challenge: Challenge;
}

const ChallengeRecentEventsDetail: React.FC<ChallengeRecentEventsDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { challengesApi } = useApi();

  const [recentEvents, setRecentEvents] = useState<TinyEvent[]>();

  useComponentDidMountEffect(async () => {
    setRecentEvents(await challengesApi.getRecentEventsChallengeUsedIn(safeString(challenge.challengeId)));
  });

  const [preferences] = useState({
    pageSize: 10,
    visibleColumns: ['title', 'startTime', 'endTime'],
  });

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    recentEvents || [],
    {
      filtering: {
        filteringFunction,
        empty: (
          <TableEmptyState
            title={t(i18nKeys.challenges.challengeDetails.table.emptyIssuesState)}
            subtitle={t(i18nKeys.challenges.challengeDetails.table.emptyIssuesSubtitle)}
          />
        ),
        noMatch: (
          <TableEmptyState
            title={t(i18nKeys.tables.noMatch.title)}
            subtitle={t(i18nKeys.tables.noMatch.subtitle)}
            onClearFilter={() => actions.setFiltering('')}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
    }
  );

  return (
    <Table
      {...collectionProps}
      variant="container"
      resizableColumns
      header={
        <TableHeader
          totalItems={recentEvents ? recentEvents.length : 0}
          title={t(i18nKeys.challenges.challengeDetails.headings.recentUpcomingEvents)}
          toggleTools={() => alert('challenge list tools not implemented yet')}
        />
      }
      columnDefinitions={COLUMN_DEFINITIONS}
      items={items}
      loadingText={t(i18nKeys.challenges.challengeDetails.table.loadingIssues)}
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels(t)} />}
      filter={
        <TextFilter
          {...filterProps}
          countText={t(i18nKeys.tables.matchesCount, { count: filteredItemsCount })}
          filteringAriaLabel={t(i18nKeys.challenges.filteringLabel)}
        />
      }
    />
  );
};

export default ChallengeRecentEventsDetail;
