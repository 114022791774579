import { useCollection } from '@amzn/awsui-collection-hooks';
import { CollectionPreferencesProps, Header, Pagination, Table } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from '../../../types/common';
import { ApprovableDiff } from '../../../types/ResourceDeployment';
import { i18nKeys } from '../../../utils/i18n.utils';
import { paginationLabels } from '../../../utils/table.utils';
import { TableHeader } from '../TableHeader';
import RevisionDetails from './RevisionDetails';
import { COLUMN_DEFINITIONS } from './revisions-list-config';

export interface ChangeWithRevisionNumber extends ApprovableDiff {
  revisionNumber: number;
}

interface RevisionsProps {
  changes: any[];
}

export const Revisions: React.FC<RevisionsProps> = ({ changes }) => {
  const { t } = useTranslation();
  const [changeHistory, setChangeHistory] = useState<ChangeWithRevisionNumber[]>();
  const [selectedRevision, setSelectedRevision] = useState<Nullable<ChangeWithRevisionNumber>>(null);

  useEffect(() => {
    let newChangesList = changes;
    newChangesList = newChangesList.filter((change) => !!change?.changeAppliedDate);
    newChangesList = newChangesList.sort((a, b) => {
      if (b.changeAppliedDate && a.changeAppliedDate) {
        return b.changeAppliedDate - a.changeAppliedDate;
      } else {
        return -1;
      }
    });
    newChangesList.forEach((change, index) => {
      change.revisionNumber = newChangesList.length - index;
      return change as ChangeWithRevisionNumber;
    });
    setChangeHistory(newChangesList);

    changes = changes.filter((change) => !!change.changeAppliedDate);
  }, [changes]);

  const [preferences] = useState<CollectionPreferencesProps.Preferences>({
    pageSize: 10,
  });

  const { items, collectionProps, paginationProps } = useCollection(changeHistory || [], {
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
  });

  // TODO: Once available, implement comparison logic
  // TODO: Create UI friendly dictionary to replace property labels
  return (
    <div className="mt-10" style={{ paddingBottom: '16px' }}>
      {!selectedRevision ? (
        <React.Fragment>
          <Header variant="h2">{t(i18nKeys.revisions.title)}</Header>
          <Table
            {...collectionProps}
            resizableColumns
            header={<TableHeader totalItems={changes.length} title={t(i18nKeys.revisions.publishedRevisions.title)} />}
            columnDefinitions={COLUMN_DEFINITIONS(setSelectedRevision)}
            items={items}
            pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels(t)} />}
          />
        </React.Fragment>
      ) : (
        <RevisionDetails revision={selectedRevision} goBack={() => setSelectedRevision(null)} />
      )}
    </div>
  );
};

export default Revisions;
