/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useState } from 'react';
import { ChallengeBoard } from '../types/ChallengeBoard';
import { useApi } from './api.context';

export type LoadChallengeBoards = () => Promise<void>;

export interface ChallengeBoardContextValue {
  challengeBoards: ChallengeBoard[] | undefined;
  loadChallengeBoards: LoadChallengeBoards;
}

const ChallengeBoardContext = React.createContext<ChallengeBoardContextValue>({
  challengeBoards: undefined,
  loadChallengeBoards: () => new Promise(() => {}),
});

const ChallengeBoardProvider: React.FC = ({ children }) => {
  const [challengeBoards, setChallengeBoards] = useState<ChallengeBoard[] | undefined>();
  const { challengeBoardApi } = useApi();

  const loadChallengeBoards = async () => {
    await challengeBoardApi.findAll().then((res: ChallengeBoard[]) => {
      if (res) {
        setChallengeBoards(res);
      }
    });
  };

  const data: ChallengeBoardContextValue = {
    challengeBoards,
    loadChallengeBoards,
  };

  return <ChallengeBoardContext.Provider value={data}>{children}</ChallengeBoardContext.Provider>;
};

const useChallengeBoard = () => {
  const context = useContext(ChallengeBoardContext);
  if (context === undefined) {
    throw new Error('useChallengeBoards can only be used inside ChallengeBoardProvider');
  }
  return context;
};

export { ChallengeBoardProvider, useChallengeBoard };
