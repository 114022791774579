import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.scss';
import { i18nKeys } from '../../../utils/i18n.utils';
import { useCookieConsent } from '../../../store/cookie-consent.context';
import { isPreProd } from '../../../utils/env.utils';
import { useState } from 'react';
import { RoleSpoofingModal } from '../../spoofed-groups/RoleSpoofingModal';
import { Badge, Icon, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { getSpoofedGroups } from '../../../utils/spoofed-groups';
import { useUser } from '../../../store/user.context';
import { getGameUIUrl, privacy, siteTerms, wiki } from '../../../utils/jam-urls';
import { config } from '../../../config/app-config';

export const Footer: React.FC = () => {
  const { t } = useTranslation();
  const { customizeCookies } = useCookieConsent();
  const { isLoggedIn } = useUser();
  const [showRoleSpoofingModal, setShowRoleSpoofingModal] = useState<boolean>(false);
  const { user } = useUser();

  return (
    <footer id="footer" style={{ position: 'fixed', width: '100%', zIndex: '1000' }}>
      <div style={{ textAlign: 'center', paddingRight: '2rem' }}>
        <div className="inline">
          <SpaceBetween direction="horizontal" size="s">
            {isPreProd() && <Badge className="inline">{config.stageName.toUpperCase()}</Badge>}
            <Link className="mr-8" href={privacy} target="_blank" rel="noopener noreferrer">
              {t(i18nKeys.footer.privacy)}
            </Link>
            <Link href={siteTerms} target="_blank" rel="noopener noreferrer">
              {t(i18nKeys.footer.siteTerms)}
            </Link>
            <Link href={getGameUIUrl()} target="_blank" rel="noopener noreferrer">
              {t(i18nKeys.footer.homepage)}
            </Link>
            {user?.isAmazonian && (
              <Link href={wiki} target="_blank" rel="noopener noreferrer">
                {t(i18nKeys.footer.wiki)}
              </Link>
            )}
            <div id={'copyright'} className="mt-4">
              {t(i18nKeys.footer.copyright, { name: `©${new Date().getFullYear()} Amazon.com` })}
            </div>
          </SpaceBetween>
        </div>
        {isPreProd() && isLoggedIn && (
          <span className="role-spoofing" onClick={() => setShowRoleSpoofingModal(true)}>
            {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              <Icon name="settings" variant={getSpoofedGroups().length > 0 ? 'warning' : 'normal'} size="small" />
            }
          </span>
        )}
        {showRoleSpoofingModal && <RoleSpoofingModal handleCloseModal={() => setShowRoleSpoofingModal(false)} />}

        <span className="cookie-preferences">
          <button className="button-link" onClick={customizeCookies}>
            {t(i18nKeys.footer.cookie)}
          </button>
        </span>
      </div>
      <div id="shortbread-container" />
    </footer>
  );
};
