import {
  Alert,
  Cards,
  Checkbox,
  Container,
  ExpandableSection,
  Grid,
  Header,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditEventActions, useEditEvent } from '../../../store/edit-event.context';
import { Event } from '../../../types/Event';
import { i18nKeys } from '../../../utils/i18n.utils';
import _ from 'lodash';
import { Campaign } from '../../../types/Campaign';
import { EditCampaignActions, useEditCampaign } from '../../../store/edit-campaign.context';

interface SupportAndCollaborationProps {
  target: Event | Campaign | undefined;
}

const SupportAndCollaboration: React.FC<SupportAndCollaborationProps> = ({ target }) => {
  const { t } = useTranslation();
  const { editMode, newEventMode, handleUpdateEditEvent } = useEditEvent();
  const { campaignEditMode, handleUpdateEditCampaign } = useEditCampaign();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const canEdit = editMode || campaignEditMode || newEventMode;
  const isEvent = target instanceof Event;

  const collaborationSettings = isEvent ? target.collaborationOptions : target?.campaignSettings?.collaborationOptions;

  const collaborationOptions = {
    teamChatEnabled: {
      name: t(i18nKeys.events.eventDetails.labels.collaborationOptions.teamChat.name),
      description: t(i18nKeys.events.eventDetails.labels.collaborationOptions.teamChat.description),
      value: 'teamChatEnabled',
      footer: undefined,
    },
    messagingEnabled: {
      name: t(i18nKeys.events.eventDetails.labels.collaborationOptions.facilitatorMessaging.name),
      description: t(i18nKeys.events.eventDetails.labels.collaborationOptions.facilitatorMessaging.description),
      value: 'messagingEnabled',
      footer: undefined,
    },
    supportChatEnabled: {
      name: t(i18nKeys.events.eventDetails.labels.collaborationOptions.supportChat.name),
      description: t(i18nKeys.events.eventDetails.labels.collaborationOptions.supportChat.description),
      footer: t(i18nKeys.events.eventDetails.labels.collaborationOptions.supportChat.footer),
      value: 'supportChatEnabled',
    },
  };

  useEffect(() => {
    if (target) {
      const eventCollaborationOptions = isEvent
        ? target?.collaborationOptions
        : target.campaignSettings?.collaborationOptions;
      const selectedCollaborationItems: string[] = [];
      if (eventCollaborationOptions?.messagingEnabled) {
        selectedCollaborationItems.push(collaborationOptions.messagingEnabled.value);
      }
      if (eventCollaborationOptions?.supportChatEnabled) {
        selectedCollaborationItems.push(collaborationOptions.supportChatEnabled.value);
      }
      if (eventCollaborationOptions?.teamChatEnabled) {
        selectedCollaborationItems.push(collaborationOptions.teamChatEnabled.value);
      }
      setSelectedItems(selectedCollaborationItems);
    }
  }, [editMode, newEventMode]);

  const handleSelectedItems = (itemSelected: string) => {
    const clonedSelectedItems = _.cloneDeep(selectedItems);
    if (clonedSelectedItems.includes(itemSelected)) {
      clonedSelectedItems.splice(
        clonedSelectedItems.findIndex((item) => item === itemSelected),
        1
      );
    } else {
      clonedSelectedItems.push(itemSelected);
    }
    setSelectedItems(clonedSelectedItems);
    if (isEvent) {
      handleUpdateEditEvent(EditEventActions.SUPPORT_AND_COLLABORATION, clonedSelectedItems);
    } else {
      handleUpdateEditCampaign(EditCampaignActions.SUPPORT_AND_COLLABORATION, clonedSelectedItems);
    }
  };

  const renderContent = () => {
    return !canEdit ? (
      <React.Fragment>
        <div className="section-first-row">
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
            <div className="secondary-text">{t(i18nKeys.events.eventDetails.labels.supportChat)}</div>
            <div>{collaborationSettings?.supportChatEnabled ? t(i18nKeys.general.yes) : t(i18nKeys.general.no)}</div>
          </Grid>
        </div>
        {isEvent && (
          <div className="grey-section-divider-top">
            <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
              <div className="secondary-text">{t(i18nKeys.events.eventDetails.labels.teamChat)}</div>
              <div>{collaborationSettings?.teamChatEnabled ? t(i18nKeys.general.yes) : t(i18nKeys.general.no)}</div>
            </Grid>
          </div>
        )}
        <div className="grey-section-divider-top">
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
            <div className="secondary-text">{t(i18nKeys.events.eventDetails.labels.facilitatorMessaging)}</div>
            <div>{collaborationSettings?.messagingEnabled ? t(i18nKeys.general.yes) : t(i18nKeys.general.no)}</div>
          </Grid>
        </div>
      </React.Fragment>
    ) : (
      canEdit && (
        <SpaceBetween direction="vertical" size="s">
          <Alert header={t(i18nKeys.newEvent.messages.title)}>{t(i18nKeys.newEvent.messages.virtualEvent)}</Alert>
          <Checkbox
            checked={selectedItems.includes(collaborationOptions.supportChatEnabled.value)}
            onChange={() => handleSelectedItems(collaborationOptions.supportChatEnabled.value)}
            description={
              <div>
                <div>{collaborationOptions.supportChatEnabled.description}</div>
                <strong>{collaborationOptions.supportChatEnabled.footer}</strong>
              </div>
            }>
            {collaborationOptions.supportChatEnabled.name}
          </Checkbox>
          {isEvent && (
            <Checkbox
              checked={selectedItems.includes(collaborationOptions.teamChatEnabled.value)}
              onChange={() => handleSelectedItems(collaborationOptions.teamChatEnabled.value)}
              description={collaborationOptions.teamChatEnabled.description}>
              {collaborationOptions.teamChatEnabled.name}
            </Checkbox>
          )}
          <Checkbox
            checked={selectedItems.includes(collaborationOptions.messagingEnabled.value)}
            onChange={() => handleSelectedItems(collaborationOptions.messagingEnabled.value)}
            description={collaborationOptions.messagingEnabled.description}>
            {collaborationOptions.messagingEnabled.name}
          </Checkbox>
        </SpaceBetween>
      )
    );
  };

  return !newEventMode ? (
    <ExpandableSection
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.supportAndCollaboration)}</Header>}>
      {renderContent()}
    </ExpandableSection>
  ) : (
    <Container header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.supportAndCollaboration)}</Header>}>
      {renderContent()}
    </Container>
  );
};
export default SupportAndCollaboration;
