import { Badge } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Challenge, ChallengeForImport, ChallengeListItem } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import './DifficultyIndicator.scss';

interface DifficultyIndicatorProps {
  challenge: Challenge | ChallengeForImport | ChallengeListItem | undefined;
  inline?: boolean;
  inlineWarmup?: boolean;
}

const DifficultyIndicator: React.FC<DifficultyIndicatorProps> = ({
  challenge,
  inline = false,
  inlineWarmup = false,
}) => {
  const { t } = useTranslation();
  const renderIndicator = () => {
    const challengeDifficulty: number =
      (challenge && challenge instanceof Challenge ? challenge.props.difficulty : challenge?.difficulty) || 0;

    switch (challengeDifficulty) {
      case 0:
        return (
          <div className="default-text" style={{ display: 'inline-block' }}>
            <div className="block" style={{ backgroundColor: 'green' }}>
              {' '}
            </div>
            {t(i18nKeys.general.fundamental)}
          </div>
        );
      case 1:
        return (
          <div className="default-text" style={{ display: 'inline-block' }}>
            <div className="block" style={{ backgroundColor: 'yellow' }}>
              {' '}
            </div>
            {t(i18nKeys.general.intermediate)}
          </div>
        );
      case 2:
        return (
          <div className="default-text" style={{ display: 'inline-block' }}>
            <div className="block" style={{ backgroundColor: 'orange' }}>
              {' '}
            </div>
            {t(i18nKeys.general.advanced)}
          </div>
        );
      case 3:
        return (
          <div className="default-text" style={{ display: 'inline-block' }}>
            <div className="block" style={{ backgroundColor: 'red' }}>
              {' '}
            </div>
            {t(i18nKeys.general.expert)}
          </div>
        );
    }
  };

  return (
    <div style={{ display: inline ? 'inline' : undefined }}>
      {challenge instanceof ChallengeForImport && challenge.missing ? (
        <div
          className="badge-aws badge-aws-warning inline"
          title={t(i18nKeys.challenges.challengeDetails.field.warmup.title)}>
          <Badge className="ml-5" color="red">
            {t(i18nKeys.general.notAvailable)}
          </Badge>
        </div>
      ) : (
        <React.Fragment>
          {renderIndicator()}
          {(challenge instanceof Challenge ? challenge?.challengeAlwaysOn : challenge?.warmup) ? (
            <div
              className={`badge-aws badge-aws-warning warmup ${inlineWarmup ? 'inline ml-5' : undefined}`}
              title={t(i18nKeys.challenges.challengeDetails.field.warmup.title)}>
              <img src="assets/warmup.png" style={{ width: '7px' }} />
              {t(i18nKeys.challenges.challengeDetails.field.warmup.name)}
            </div>
          ) : null}
        </React.Fragment>
      )}
    </div>
  );
};

export default DifficultyIndicator;
