import {
  ColumnLayout,
  Container,
  ExpandableSection,
  Grid,
  Header,
  Input,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditEventActions, useEditEvent } from '../../../../store/edit-event.context';
import { useUser } from '../../../../store/user.context';
import { Event } from '../../../../types/Event';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { KeyValue } from '../../../common/KeyValue';

interface AgreementInfoProps {
  target: Event | undefined;
}

const AgreementInfo: React.FC<AgreementInfoProps> = ({ target }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { editMode, handleUpdateEditEvent, newEventMode } = useEditEvent();

  const canEdit = newEventMode || editMode;

  const renderContent = () => {
    return !canEdit ? (
      <ColumnLayout columns={2} variant="text-grid">
        <div>
          <div>{t(i18nKeys.events.eventDetails.headers.agreementId)}</div>
          <div>
            <strong>{target?.agreementId || t(i18nKeys.events.eventDetails.labels.none)}</strong>
          </div>
        </div>
      </ColumnLayout>
    ) : (
      canEdit && user && (
        <Grid gridDefinition={[{ colspan: 8 }]}>
          <SpaceBetween direction="vertical" size="s">
            <KeyValue label={<b>{t(i18nKeys.events.eventDetails.labels.agreementId)}</b>}>
              <Input
                type="text"
                value={target?.agreementId || ''}
                onChange={({ detail }) => handleUpdateEditEvent(EditEventActions.AGREEMENT_ID, detail.value)}
                disabled={!target?.canEditAttribute('agreementId', user)}
              />
            </KeyValue>
          </SpaceBetween>
        </Grid>
      )
    );
  };

  return !newEventMode ? (
    <ExpandableSection
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.agreementInfo)}</Header>}>
      {renderContent()}
    </ExpandableSection>
  ) : (
    <Container header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.agreementInfo)}</Header>}>
      {renderContent()}
    </Container>
  );
};
export default AgreementInfo;
