import { useCollection } from '@amzn/awsui-collection-hooks';
import { Table } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChallengeSectionFeedback } from '../../../../../types/Challenge';
import { i18nKeys } from '../../../../../utils/i18n.utils';
import { TableHeader } from '../../../../common/TableHeader';
import { useChallenges } from '../../../../../store/challenge.context';
import { COLUMN_DEFINITIONS } from './review-comments-list-config';
import { safeString } from '../../../../../utils/string.utils';
import { TableEmptyState } from '../../../../common/TableEmptyState';
import { useHistory } from 'react-router-dom';
import { CHALLENGE_DETAILS_ROUTES } from '../../../../../routes';

const ChallengeReviewDetail: React.FC = () => {
  const { t } = useTranslation();
  const { challenge, currentReview } = useChallenges();
  const [feedback, setFeedback] = useState<ChallengeSectionFeedback[]>(
    currentReview?.challengeReviewFeedback?.feedback || []
  );
  const challengeId = safeString(challenge?.challengeId);
  const history = useHistory();

  useEffect(() => {
    // redirect to revisions tab if there's nothing to review
    if (feedback.length === 0) {
      history.push(CHALLENGE_DETAILS_ROUTES.Revisions.resolve(challengeId));
    }
  }, []);

  const { items, collectionProps } = useCollection(feedback || [], {
    sorting: {},
    filtering: {
      empty: (
        <TableEmptyState
          title={t(i18nKeys.challenges.challengeDetails.table.emptyReviewsState)}
          subtitle={t(i18nKeys.challenges.challengeDetails.table.emptyReviewsSubtitle)}
        />
      ),
    },
  });

  return (
    <React.Fragment>
      <Table
        {...collectionProps}
        resizableColumns
        header={
          <TableHeader
            totalItems={feedback ? feedback.length : 0}
            title={t(i18nKeys.challenges.challengeDetails.table.titles.challengeReviewFeedback)}
          />
        }
        columnDefinitions={COLUMN_DEFINITIONS(challengeId)}
        items={items}
        wrapLines
      />
    </React.Fragment>
  );
};

export default ChallengeReviewDetail;
