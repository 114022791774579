import React from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import { toTitleCase } from '../../../../../../utils/string.utils';
import { ExpandableSection, Header } from '@amzn/awsui-components-react';
import { DiffChange } from '../../../../../../types/Diff';

interface ChallengeDiffTableProps {
  tableHeader: string;
  challengeDiffs: DiffChange[];
}

const ChallengeDiffTable: React.FC<ChallengeDiffTableProps> = ({ tableHeader, challengeDiffs }) => {
  return (
    <ExpandableSection variant="container" header={<Header>{tableHeader}</Header>}>
      {challengeDiffs.map((diff, i) => (
        <ExpandableSection key={i} header={<Header>{toTitleCase(diff.property)}</Header>}>
          <ReactDiffViewer oldValue={String(diff.previousValue)} newValue={String(diff.updatedValue)} splitView />
        </ExpandableSection>
      ))}
    </ExpandableSection>
  );
};

export default ChallengeDiffTable;
