/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useState } from 'react';
import { User } from '../types/User';
import { useComponentDidMountEffect } from '../hooks/useComponentDidMountEffect';
import { useAuth } from './auth.context';

export interface UserContextValue {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>> | null; // Exported for use by role spoofing component
  isLoggedIn: boolean;
}

// eslint-disable-next-line no-shadow
export enum UserPreferenceKeys {
  PAGE_SIZE = 'userPreferencesPageSize',
  DISPLAY_TIME = 'userPreferencesSecondaryDisplayTime',
}

const UserContext = React.createContext<UserContextValue>({
  user: null,
  setUser: null,
  isLoggedIn: false,
});

const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const { authClient } = useAuth();

  // load the user one time
  useComponentDidMountEffect(async () => {
    // the user may or may not exist
    await authClient.getUser().then((cognitoUser) => {
      setUser(cognitoUser);
      setIsLoggedIn(cognitoUser != null);
    });
  });

  const data: UserContextValue = { user, setUser, isLoggedIn };

  return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
};

const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser can only be used inside UserProvider');
  }
  return context;
};

export { UserProvider, useUser };
