import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Rating } from 'react-simple-star-rating';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface CircleRatingProps {
  value: number;
}

const CircleRating: React.FC<CircleRatingProps> = ({ value }) => {
  return (
    <div style={{ display: 'inline-block' }}>
      <Rating
        readonly
        allowFraction
        size={15}
        iconsCount={5}
        initialValue={value}
        fillIcon={
          <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon={faCircle as IconProp} color="#00A1C9" size="xs" />
          </span>
        }
        emptyIcon={
          <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon={faCircle as IconProp} color="#00A1C9" size="xs" />
            <FontAwesomeIcon icon={faCircle as IconProp} size="xs" inverse transform="shrink-7" />
          </span>
        }
      />
    </div>
  );
};
export default CircleRating;
