import {
  Button,
  Container,
  ExpandableSection,
  Flashbar,
  Grid,
  Header,
  Input,
  Link,
  Select,
  SpaceBetween,
  TextContent,
  TokenGroup,
} from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../../store/api.context';
import { useChallenges } from '../../../../store/challenge.context';
import { useUser } from '../../../../store/user.context';
import { ChallengeUtils, Challenge, ChallengeReviewableSection } from '../../../../types/Challenge';
import { Event } from '../../../../types/Event';
import { EventLabSummary } from '../../../../types/EventLabSummary';
import { Lab } from '../../../../types/LabModels';
import { LabProvider } from '../../../../types/LabProvider';
import { ResourceDeploymentItem } from '../../../../types/ResourceDeployment';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { preProdLogger } from '../../../../utils/log.utils';
import { ConfirmModal } from '../../../common/ConfirmModal';
import LabAccountTable from '../../../common/Labs/LabAccountTable';
import LabShutoffStatusBadge from '../../../common/LabShutoffStatusBadge';
import SecretKey from '../../../common/SecretKey';
import LabStatusCountdown from '../../../events/eventsCommon/labStatusCountdown/LabStatusCountdown';
import { LabProviderDefinitions } from '../../challengesCommon/ChallengeOptionDefinitions';
import { ChallengeReviewPopover } from '../../challengesCommon/ChallengeReviewPopover';

interface ChallengeTestingDetailProps {
  challenge: Challenge;
}

const ChallengeTestingDetail: React.FC<ChallengeTestingDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const {
    testEventName,
    deployTestEvent,
    redeployTestEvent,
    updateOneClickEventTesters,
    terminateTestEvent,
    extendTestEvent,
  } = useChallenges();
  const { user } = useUser();
  const { eventsApi } = useApi();

  const [items, setItems] = useState([
    {
      dismissible: true,
      dismissLabel: t(i18nKeys.challenges.subSections.cfnTemplate.dismissLabel),
      onDismiss: () => setItems([]),
      content: <>{t(i18nKeys.challenges.challengeDetails.flashbar.oneClickTestEvent)}</>,
    },
  ]);

  const [activeTestItems, setActiveTestItems] = useState([
    {
      onDismiss: () => setActiveTestItems([]),
      content: <>{t(i18nKeys.challenges.challengeDetails.flashbar.noLabAccounts)}</>,
      id: 'no-active-lab-accounts',
    },
  ]);

  const regions: OptionDefinition[] = challenge.props.allowedRegions
    ? challenge.props.allowedRegions.map((region) => ({
        label: region,
        value: region.toLocaleLowerCase(),
      }))
    : [];

  const labProviders: OptionDefinition[] = LabProviderDefinitions.map((labProvider: OptionDefinition) => {
    return { label: t(labProvider.label as string), value: labProvider.value };
  });

  const challengeId = challenge.challengeId;

  // defaults to AWS Labs when no lab provider is assigned, should never happen
  const defaultLabProvider: OptionDefinition =
    challenge.props.defaultLabProvider === LabProvider.EVENT_ENGINE ? labProviders[1] : labProviders[0];

  const [selectedRegion, setSelectedRegion] = useState(regions[0]);
  const [selectedLabProvider, setSelectedLabProvider] = useState(defaultLabProvider);
  const [testEventLabSummary, setTestEventLabSummary] = useState<EventLabSummary | null>();
  const [testEvent, setTestEvent] = useState<Event | null>();
  const [testEventLabs, setTestEventLabs] = useState<Lab[]>();
  const [testEventChallengesById, setTestEventChallengesById] = useState<{ [challengeId: string]: Challenge }>();
  const [testerItems, setTesterItems] = useState<{ label: string; dismissLabel: string }[]>([]);
  const [testers, setTesters] = useState<string[]>([]);
  const [tester, setTester] = useState('');

  // modal inputs & states
  const [hours, setHours] = useState('');

  const [confirmTerminateVisibile, setConfirmTerminateVisible] = useState(false);
  const [confirmHoursVisible, setConfirmHoursVisible] = useState(false);

  useEffect(() => {
    if (testEventName) {
      loadTestEventLabSummary();

      // load the event
      eventsApi
        .getEvent(testEventName)
        .then((eventData) => {
          setTestEvent(eventData.event);
          // get the list of facilitators who will be testers
          setTesters(eventData.event.getFacilitators());
        })
        .catch((err) => preProdLogger(err));
    } else {
      setTestEventLabSummary(null);
      setTestEventLabs([]);
      setTestEvent(null);
      setTesters([]);
    }
  }, [testEventName]);

  useEffect(() => {
    if (testers) {
      const newTesterItems: { label: string; dismissLabel: string }[] = [];
      testers.forEach((testerEmail: string) => {
        newTesterItems.push({
          label: testerEmail,
          dismissLabel: `${t(i18nKeys.challenges.challengeDetails.labels.removeTag)}: ${testerEmail}`,
        });
      });
      setTesterItems(newTesterItems);
    }
  }, [testers]);

  const loadTestEventLabSummary = () => {
    if (testEventName && ChallengeUtils.isCollaborator(challenge, user)) {
      // load the event lab summary
      eventsApi
        .getEventLabSummary(testEventName)
        .then(async (eventLabSummary) => {
          if (eventLabSummary) {
            // fetch the labs for the event and add them to the event lab summary
            eventLabSummary.labs = await eventsApi.getEventLabs(
              testEventName,
              Object.keys(eventLabSummary.labStatusSnapshots)
            );

            const eventLabs = eventLabSummary.getLabsAsList();

            for (const lab of eventLabs) {
              if (!Lab.isInFinalStatus(lab.status)) {
                const resourceDeploymentItems: ResourceDeploymentItem[] =
                  await eventsApi.getLabResourceDeploymentHistory(lab);
                lab.applyResourceTaskHistory(resourceDeploymentItems);
              }
            }
            setTestEventLabs(eventLabs);

            setTestEventLabSummary(eventLabSummary);
          } else {
            setTestEventLabSummary(null);
            setTestEventLabs([]);
          }
          loadTestEventChallenges();
        })
        .catch((err) => preProdLogger(err));
    }
  };

  const loadTestEventChallenges = () => {
    let eventChallengesById: { [challengeId: string]: Challenge } = {};
    if (testEventLabSummary) {
      eventChallengesById = (testEventLabSummary.challenges || []).reduce((acc, c) => {
        return Object.assign(acc, { [c.id || '']: c });
      }, {});
    }
    if (challenge) {
      eventChallengesById[challenge.id || ''] = challenge;
    }
    setTestEventChallengesById(eventChallengesById);
  };

  const onDeployTestEvent = () => {
    if (challengeId && defaultLabProvider.value) {
      deployTestEvent(
        challengeId,
        selectedLabProvider.value || defaultLabProvider.value,
        selectedRegion.value || null,
        false
      );
    }
  };

  const onRedeployTestEvent = () => {
    if (challengeId) {
      redeployTestEvent(challengeId);
    }
  };

  /**
   * Update the list of facilitators and event owners (testers).
   */
  const onUpdateOneClickEventTesters = () => {
    if (challengeId && testers) {
      updateOneClickEventTesters(challengeId, testers);
    }
  };

  const onTerminateTestEvent = () => {
    // set other properties that should be emptied when `testEventName` is nullified for consistency
    setTestEvent(null);
    setTestEventLabSummary(null);
    setTestEventLabs([]);

    if (challengeId) {
      terminateTestEvent(challengeId);
    }
  };

  const onExtendTestEvent = () => {
    if (challengeId && hours) {
      extendTestEvent(challengeId, Number(hours));
    }
  };

  const addTester = () => {
    const currentTesters = testers;
    const currentTesterItems = testerItems;

    currentTesters.push(tester);
    currentTesterItems.push({
      label: tester,
      dismissLabel: `${t(i18nKeys.challenges.challengeDetails.labels.removeTag)}: ${tester}`,
    });

    setTesters(currentTesters);
    setTesterItems(currentTesterItems);
    setTester('');
  };

  const getTestEventChallengeVersion = () => {
    if (!testEventLabSummary) {
      return null;
    }
    if (!testEventLabSummary.challengeDescriptors) {
      return null;
    }
    if (testEventLabSummary.challengeDescriptors.length < 1) {
      return null;
    }
    return testEventLabSummary.challengeDescriptors[0].version;
  };

  return (
    <React.Fragment>
      {!testEventName ? (
        <Container
          header={
            <Header variant="h2" actions={<ChallengeReviewPopover section={ChallengeReviewableSection.TESTING} />}>
              {t(i18nKeys.challenges.challengeDetails.headings.testing)}
            </Header>
          }>
          <SpaceBetween direction="vertical" size="m">
            <Flashbar items={items} />
            <TextContent>
              <p>{t(i18nKeys.challenges.challengeDetails.text.oneClickTestEventDescription)}</p>
              <h3>1. {t(i18nKeys.challenges.challengeDetails.titles.chooseLabDeploymentRegion)}</h3>
              <p>{t(i18nKeys.challenges.challengeDetails.text.labDeploymentRegionDescription)}</p>
              <ul>
                <li>{t(i18nKeys.challenges.challengeDetails.text.labDeploymentRegionStepOne)}</li>
                <li>{t(i18nKeys.challenges.challengeDetails.text.labDeploymentRegionStepTwo)}</li>
              </ul>
            </TextContent>
            <Grid gridDefinition={[{ colspan: 5 }, { colspan: 7 }]}>
              <Select
                selectedOption={selectedRegion}
                onChange={({ detail }) => setSelectedRegion(detail.selectedOption)}
                options={regions}
              />
            </Grid>
            <TextContent className="grey-section-divider-top">
              <h3>{t(i18nKeys.challenges.challengeDetails.titles.chooseLabProvider)}</h3>
              <p>{t(i18nKeys.challenges.challengeDetails.text.labProviderDescription)}</p>
            </TextContent>
            <Grid gridDefinition={[{ colspan: 4 }, { colspan: 8 }]}>
              <Select
                selectedOption={selectedLabProvider}
                onChange={({ detail }) => setSelectedLabProvider(detail.selectedOption)}
                options={labProviders}
              />
            </Grid>
            <div className="grey-section-divider-top">
              <div style={{ float: 'right', paddingTop: '1rem' }}>
                <Button variant="primary" onClick={onDeployTestEvent}>
                  {t(i18nKeys.challenges.challengeDetails.buttons.createOneClickTestEvent)}
                </Button>
              </div>
            </div>
          </SpaceBetween>
        </Container>
      ) : (
        <SpaceBetween size="s" direction="vertical">
          <Container
            header={
              <Header variant="h2" actions={<ChallengeReviewPopover section={ChallengeReviewableSection.TESTING} />}>
                {t(i18nKeys.challenges.challengeDetails.headings.testing)}
              </Header>
            }>
            {t(i18nKeys.challenges.challengeDetails.text.oneClickTestEventDescription)}
          </Container>
          <ExpandableSection
            variant="container"
            header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.access)}</Header>}>
            <div className="container-table-header">
              <Grid gridDefinition={[{ colspan: 2 }, { colspan: 5 }, { colspan: 2 }, { colspan: 3 }]}>
                <div className="table-divider">
                  <strong>{t(i18nKeys.events.eventDetails.headers.eventType.header)}</strong>
                </div>
                <div>
                  <strong>{t(i18nKeys.events.eventDetails.headers.link)}</strong>
                </div>
                <div className="table-divider">
                  <strong>{t(i18nKeys.events.eventDetails.headers.secretKey)}</strong>
                </div>
                <div>
                  <strong>{t(i18nKeys.events.eventDetails.headers.status)}</strong>
                </div>
              </Grid>
            </div>
            <div style={{ padding: '10px 0px 10px 0px', marginBottom: '-20px' }}>
              <Grid gridDefinition={[{ colspan: 2 }, { colspan: 5 }, { colspan: 2 }, { colspan: 3 }]}>
                <React.Fragment>
                  <div>{testEventLabSummary && t(i18nKeys.events.eventDetails.labels.testEvent)}</div>
                  <div>
                    {testEventLabSummary?.urlWithSecretKey && (
                      <Link external href={testEventLabSummary.urlWithSecretKey || ''}>
                        {testEventLabSummary?.urlWithMaskedSecretKey}
                      </Link>
                    )}
                  </div>
                  <div>
                    <SecretKey secretKey={testEventLabSummary?.eventCode} />
                  </div>
                  <div>
                    <LabStatusCountdown
                      isTest
                      includeSeconds={false}
                      inline
                      status={testEventLabSummary?.eventStatus}
                      labStartTime={testEventLabSummary?.labStartTime || ''}
                      labEndTime={testEventLabSummary?.labEndTime || ''}
                    />
                    <span style={{ marginRight: '20px' }} />
                    <LabShutoffStatusBadge
                      challengeDescriptors={testEventLabSummary?.challengeDescriptors}
                      shutoffStatus={testEventLabSummary?.shutoffStatus}
                      testEventShutoffStatus={testEventLabSummary?.testEventShutoffStatus}
                    />
                  </div>
                </React.Fragment>
              </Grid>
            </div>
          </ExpandableSection>
          {testEvent && (
            <ExpandableSection
              variant="container"
              header={<Header variant="h2">{t(i18nKeys.challenges.challengeDetails.headings.testers)}</Header>}>
              <SpaceBetween size="s" direction="vertical">
                <p>{t(i18nKeys.challenges.challengeDetails.text.testersDescription)}</p>
                <SpaceBetween size={'m'} direction={'horizontal'}>
                  <Input
                    onChange={({ detail }) => setTester(detail.value)}
                    value={tester}
                    placeholder={'user@example.com'}
                  />
                  <Button iconName="add-plus" onClick={addTester}>
                    {t(i18nKeys.challenges.challengeDetails.buttons.addTester)}
                  </Button>
                </SpaceBetween>
                <TokenGroup
                  onDismiss={({ detail: { itemIndex } }) => {
                    setTesters([...testers.slice(0, itemIndex), ...testers.slice(itemIndex + 1)]);
                    setTesterItems([...testerItems.slice(0, itemIndex), ...testerItems.slice(itemIndex + 1)]);
                  }}
                  items={testerItems}
                />
                <div style={{ float: 'right' }}>
                  <Button variant="primary" onClick={onUpdateOneClickEventTesters}>
                    {t(i18nKeys.challenges.challengeDetails.buttons.saveTesters)}
                  </Button>
                </div>
              </SpaceBetween>
            </ExpandableSection>
          )}
          <ExpandableSection
            variant="container"
            header={<Header variant="h2">{t(i18nKeys.challenges.challengeDetails.headings.controls)}</Header>}>
            {getTestEventChallengeVersion() !== challenge.version && (
              <Grid gridDefinition={[{ colspan: 4 }, { colspan: 8 }]}>
                {user?.isChallengeBuilder && (
                  <Button iconName="upload" onClick={onRedeployTestEvent}>
                    {t(i18nKeys.challenges.challengeDetails.buttons.pushLatestVersion)}
                  </Button>
                )}
                <div>
                  {t(i18nKeys.challenges.challengeDetails.text.updateTestChallengeVersion)}
                  <br />
                  {challenge.version && (
                    <strong>
                      {t(i18nKeys.challenges.challengeDetails.text.revisionBehind)}:{' '}
                      {challenge.version - (getTestEventChallengeVersion() || 0)}
                    </strong>
                  )}
                </div>
              </Grid>
            )}
            <SpaceBetween size="s" direction="vertical">
              <p>{t(i18nKeys.challenges.challengeDetails.text.extendTestEventWarning)}</p>
              <div style={{ float: 'right' }}>
                <SpaceBetween size="m" direction="horizontal">
                  <Button iconName={'status-pending'} onClick={onExtendTestEvent}>
                    {t(i18nKeys.challenges.challengeDetails.buttons.extendTestEvent)}
                  </Button>
                  <Button iconName={'status-negative'} onClick={onTerminateTestEvent}>
                    {t(i18nKeys.challenges.challengeDetails.buttons.terminateTestEvent)}
                  </Button>
                </SpaceBetween>
              </div>
            </SpaceBetween>
          </ExpandableSection>
          <ExpandableSection
            variant="container"
            header={<Header variant="h2"> {t(i18nKeys.challenges.challengeDetails.headings.labAccounts)}</Header>}>
            {challenge.awsAccountBased ? (
              <SpaceBetween size={'l'}>
                <Button iconName="refresh" onClick={() => void loadTestEventLabSummary()}>
                  {t(i18nKeys.general.refresh)}
                </Button>
                <LabAccountTable
                  labs={testEventLabs || []}
                  filterable={false}
                  showLabProviderStatus
                  showChallenge={false}
                  paginate={false}
                />
              </SpaceBetween>
            ) : (
              <Flashbar items={activeTestItems} />
            )}
          </ExpandableSection>
        </SpaceBetween>
      )}
      <ConfirmModal
        message={t(i18nKeys.general.areYouSure)}
        visible={confirmTerminateVisibile}
        confirmBtnLabel={t(i18nKeys.challenges.challengeDetails.yesLabels.continue)}
        onConfirm={onTerminateTestEvent}
        onCancel={() => setConfirmTerminateVisible(false)}
      />
      <ConfirmModal
        title={t(i18nKeys.challenges.challengeDetails.titles.extendTestEvent)}
        message={t(i18nKeys.challenges.challengeDetails.messages.extendTestEvent)}
        visible={confirmHoursVisible}
        confirmBtnLabel={t(i18nKeys.general.submit)}
        textInput={hours}
        onTextInput={setHours}
        onConfirm={onExtendTestEvent}
        onCancel={() => setConfirmHoursVisible(false)}
      />
    </React.Fragment>
  );
};

export default ChallengeTestingDetail;
