import { useHistory, useLocation } from 'react-router-dom';
import { getCurrentBreadCrumbs } from '../utils/route.utils';

export const useActiveRouteDetails = () => {
  const location = useLocation();
  const history = useHistory();
  const breadcrumbs = getCurrentBreadCrumbs(history.location.pathname);
  return {
    activeRoute: location.pathname,
    breadcrumbs,
    isHome: breadcrumbs.length < 2,
  };
};
