import React, { useEffect, useState } from 'react';
import { DatePicker, FormField, Select, SpaceBetween } from '@amzn/awsui-components-react';
import { KeyValue } from './KeyValue';
import { useEvents } from '../../store/events.context';
import { Nullable } from '../../types/common';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import moment from 'moment';
import { YYYY_MM_DD } from '../../utils/event-time.utils';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../utils/i18n.utils';
import { LoadingBar } from './LoadingBar';
import { getLanguageCodeSafe } from '../../utils/locale.utils';

interface TinyEventSelectionProps {
  handleSelectedEvent: (option: OptionDefinition) => void;
  inline?: boolean;
  clearOnSelection?: boolean;
}

export const TinyEventSelection: React.FC<TinyEventSelectionProps> = ({
  handleSelectedEvent,
  inline,
  clearOnSelection,
}) => {
  const { i18n, t } = useTranslation();
  const { tinyEvents, loadTinyEvents } = useEvents();
  const [startDate, setStartDate] = useState(moment().subtract(30, 'days').format(YYYY_MM_DD));
  const [endDate, setEndDate] = useState('');
  const [selectedEventOption, setSelectedEventOption] = useState<Nullable<OptionDefinition>>(null);
  const languageCode: string = getLanguageCodeSafe(i18n.language);

  useEffect(() => {
    loadTinyEvents(startDate, endDate);
  }, [startDate, endDate]);

  return (
    <FormField label={<strong>{t(i18nKeys.challenges.importChallenges.fields.searchEvents.title)}</strong>}>
      <SpaceBetween direction="horizontal" size="xs">
        <KeyValue
          className="ml-5"
          label={t(i18nKeys.challenges.importChallenges.fields.searchEvents.rangeStart)}
          inline>
          <DatePicker
            openCalendarAriaLabel={(selectedDate) =>
              t(i18nKeys.general.chooseDate) + (selectedDate ? t(i18nKeys.general.selectedDate, { selectedDate }) : '')
            }
            previousMonthAriaLabel={t(i18nKeys.events.fields.filters.labels.previousMonth)}
            nextMonthAriaLabel={t(i18nKeys.events.fields.filters.labels.nextMonth)}
            todayAriaLabel={t(i18nKeys.events.fields.filters.labels.today)}
            value={startDate}
            locale={languageCode}
            onChange={({ detail }) => setStartDate(detail.value)}
          />
        </KeyValue>
        <KeyValue label={t(i18nKeys.challenges.importChallenges.fields.searchEvents.rangeEnd)} inline>
          <DatePicker
            openCalendarAriaLabel={(selectedDate) =>
              t(i18nKeys.general.chooseDate) + (selectedDate ? t(i18nKeys.general.selectedDate, { selectedDate }) : '')
            }
            previousMonthAriaLabel={t(i18nKeys.events.fields.filters.labels.previousMonth)}
            nextMonthAriaLabel={t(i18nKeys.events.fields.filters.labels.nextMonth)}
            todayAriaLabel={t(i18nKeys.events.fields.filters.labels.today)}
            value={endDate}
            locale={languageCode}
            onChange={({ detail }) => setEndDate(detail.value)}
          />
        </KeyValue>
        {tinyEvents ? (
          <KeyValue
            className={inline ? 'inline-event-select mt-22' : undefined}
            inline
            label={inline ? undefined : t(i18nKeys.challenges.importChallenges.fields.searchEvents.selectEvent)}>
            <Select
              className={`import-challenge-select ${inline ? 'inline-event-select mt-22' : undefined}`}
              onChange={({ detail }) => {
                handleSelectedEvent(detail.selectedOption);
                if (!clearOnSelection) {
                  setSelectedEventOption(detail.selectedOption);
                }
              }}
              placeholder={t(i18nKeys.challenges.importChallenges.fields.searchEvents.chooseEvent)}
              selectedOption={selectedEventOption}
              options={[
                ...(tinyEvents
                  ? tinyEvents?.map((tinyEvent) => {
                      return { label: tinyEvent.title || '', value: tinyEvent.name || '' };
                    })
                  : []),
              ]}
            />
          </KeyValue>
        ) : (
          <LoadingBar />
        )}
      </SpaceBetween>
    </FormField>
  );
};
