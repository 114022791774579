import { Button, FormField, Icon, Input, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditEventActions } from '../../store/edit-event.context';
import { i18nKeys } from '../../utils/i18n.utils';
import { isValidDomainSuffix, splitDelimitedEmails } from '../../utils/string.utils';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import { EditCampaignActions } from '../../store/edit-campaign.context';

interface DomainListProps {
  label: string;
  description?: string;
  addDomain: (domains: string[], newDomain: string, eventAction: EditEventActions | EditCampaignActions) => void;
  removeDomain: (
    domains: string[],
    removeDomainIndex: number,
    eventAction: EditEventActions | EditCampaignActions
  ) => void;
  domains: string[];
  eventAction: EditEventActions | EditCampaignActions;
  disabled?: boolean;
}

export const DomainList: React.FC<DomainListProps> = ({
  label,
  description,
  addDomain,
  removeDomain,
  domains,
  eventAction,
  disabled = false,
}) => {
  const [newDomain, setNewDomain] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  const validateAndSplitDomains = (newDomains: string): string[] => {
    const domainList: string[] = splitDelimitedEmails(newDomains);
    const invalidDomains = (domainList || []).filter((e) => !isValidDomainSuffix(e));
    if (invalidDomains.length > 0) {
      setErrorMessage(t(i18nKeys.errors.eventPermissions.invalidDomain) + invalidDomains.join(', '));
    } else {
      setErrorMessage('');
      return domainList;
    }
    return [];
  };

  const handleAddDomain = (selectedDomain: string) => {
    const domainList = validateAndSplitDomains(selectedDomain);
    if (domainList.length > 0) {
      domainList.forEach((domain) => {
        const domainAlreadyExists = domains.find((item) => item === domain);
        if (!domainAlreadyExists) {
          addDomain(domains, domain, eventAction);
        } else {
          setErrorMessage(t(i18nKeys.errors.eventPermissions.existingDomain));
        }
      });
    }
  };

  return (
    <React.Fragment>
      <SpaceBetween direction="vertical" size="s">
        <FormField label={label} description={description}>
          <SpaceBetween direction="horizontal" size="s">
            <Input
              className="inline email-input"
              placeholder="@example.com"
              disabled={disabled}
              value={newDomain}
              onChange={({ detail }) => setNewDomain(detail.value)}
              onKeyDown={({ detail }) => {
                // 13 is the keyCode for "Enter"
                if (detail.keyCode === 13) {
                  handleAddDomain(newDomain);
                  setNewDomain('');
                }
              }}
            />
            <Button
              disabled={disabled}
              onClick={() => {
                handleAddDomain(newDomain);
                setNewDomain('');
              }}>
              {t(i18nKeys.general.add)}
            </Button>
            <div style={{ color: awsui.colorTextStatusError }}>{errorMessage}</div>
          </SpaceBetween>
        </FormField>
        {!domains ||
          (domains && domains.length < 1 && (
            <div style={{ display: 'block' }}>
              <strong>{t(i18nKeys.events.eventDetails.messages.thisListIsEmpty)}</strong>
            </div>
          ))}
        {domains &&
          domains.length > 0 &&
          domains.map((domain, index) => {
            return (
              <div key={`${domain}${index}`}>
                <SpaceBetween direction="horizontal" size="s" key={`${domain}${index}-space`}>
                  <div
                    key={`${domain}${index}-close`}
                    className="inline"
                    title={t(i18nKeys.general.remove)}
                    onClick={() => removeDomain(domains, index, eventAction)}
                    style={{ cursor: 'pointer' }}>
                    <Icon name="close" />
                  </div>
                  <span key={`${domain}${index}-name`}>{domain}</span>
                </SpaceBetween>
              </div>
            );
          })}
      </SpaceBetween>
    </React.Fragment>
  );
};
