import { TableHeader } from '../../../common/TableHeader';
import {
  Button,
  Checkbox,
  CollectionPreferencesProps,
  Input,
  Pagination,
  Select,
  SpaceBetween,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableEmptyState } from '../../../common/TableEmptyState';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { paginationLabels } from '../../../../utils/table.utils';
import { COLUMN_DEFINITIONS, filteringFunction, getGroupTitle } from './users-config';
import { useApi } from '../../../../store/api.context';
import { preProdLogger } from '../../../../utils/log.utils';
import { CognitoUser, DEFAULT_STATUS } from '../../../../types/User';
import { ConfirmModal } from '../../../common/ConfirmModal';
import { AuthRole } from '@amzn/aws-jam-constants';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';

interface UsersTableProps {
  emailsLoading: boolean;
}

export const UsersTable: React.FC<UsersTableProps> = ({ emailsLoading }) => {
  const { t } = useTranslation();
  const [preferences] = useState<CollectionPreferencesProps.Preferences>({
    pageSize: 50,
  });
  const [users, setUsers] = useState<CognitoUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<CognitoUser[]>([]);
  const { accountApi } = useApi();
  const [loading, setLoading] = useState(true);
  const [showGroups, setShowGroups] = useState(false);
  const [includeFederated, setIncludeFederated] = useState(false);
  const [selectedUser, setSelectedUser] = useState<CognitoUser[]>([]);
  const [confirmRemoveGroupModalVisible, setConfirmRemoveGroupModalVisible] = useState(false);
  const [confirmAddUserToAuthGroupModalVisible, setConfirmAddUserToAuthGroupModalVisible] = useState(false);
  const [confirmAddUserToCustomAuthGroupModalVisible, setConfirmAddUserToCustomAuthGroupModalVisible] = useState(false);
  const [confirmDeleteUserModalVisible, setConfirmDeleteUserModalVisible] = useState(false);
  const [confirmResendModalVisible, setConfirmResendModalVisible] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedAuthGroupOption, setSelectedAuthGroupOption] = useState<OptionDefinition | null>(null);
  const [customAuthGroup, setCustomAuthGroup] = useState('');
  const [authGroupOptions, setAuthGroupOptions] = useState<{ label: string; value: string }[]>([]);

  const ColumnIDs = {
    USERNAME_EMAIL: 'username-email',
    GROUPS: 'groups',
    EMAIL_VERIFIED: 'email-verified',
    STATUS: 'status',
    ENABLED: 'enabled',
    ACTIONS: 'actions',
  };

  const visibleColumns = [
    ColumnIDs.USERNAME_EMAIL,
    ColumnIDs.EMAIL_VERIFIED,
    ColumnIDs.STATUS,
    ColumnIDs.ENABLED,
    ColumnIDs.ACTIONS,
  ];

  /** Conditionally add groups column */
  if (showGroups) {
    visibleColumns.splice(1, 0, ColumnIDs.GROUPS);
  }

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    filteredUsers || [],
    {
      filtering: {
        filteringFunction,
        empty: <TableEmptyState title={t(i18nKeys.settings.userManagement.table.noUsersFound)} />,
        noMatch: (
          <TableEmptyState
            title={t(i18nKeys.tables.noMatch.title)}
            subtitle={t(i18nKeys.tables.noMatch.subtitle)}
            onClearFilter={() => actions.setFiltering('')}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
    }
  );

  const generateAuthGroupOptions = () => {
    // auth groups that may be added from the dropdown; some admin groups are excluded
    const authGroups = Object.values(AuthRole).filter(
      (group) => group !== AuthRole.JAM_ADMIN && group !== AuthRole.EVENT_SUPPORT
    );
    const newAuthGroupOptions = authGroups.map((group) => ({ label: getGroupTitle(group), value: group }));
    setAuthGroupOptions([...newAuthGroupOptions]);
  };

  const loadUsers = (includeGroups = false) => {
    setLoading(true);
    accountApi
      .listUsers(includeGroups)
      .then((res: CognitoUser[]) => {
        setUsers(res);
        updateFilter(res);
      })
      .catch((err) => {
        setLoading(false);
        preProdLogger('Error loading users', err.message);
      });
  };

  const updateFilter = (newUsers?: CognitoUser[]) => {
    setLoading(true);
    let newFilteredUsers = [...(newUsers || users)];
    if (!includeFederated) {
      newFilteredUsers = newFilteredUsers.filter((row) => {
        return row.userStatus !== DEFAULT_STATUS;
      });
    }
    setFilteredUsers(newFilteredUsers);
    setSelectedUser([]);
    setLoading(false);
  };

  const handleRemoveGroupClick = (group: string, user: CognitoUser) => {
    setSelectedGroup(group);
    setSelectedUser([user]);
    setConfirmRemoveGroupModalVisible(true);
  };

  const handleDeleteUser = () => {
    accountApi
      .deleteUser(selectedUser[0]?.username)
      .then(() => {
        handleResponse();
      })
      .catch((err) => {
        preProdLogger('Error deleteing user', err.message);
        cleanupModals();
      });
  };

  const handleResend = () => {
    accountApi
      .resendInvite(selectedUser[0]?.username)
      .then(() => {
        handleResponse();
      })
      .catch((err) => {
        preProdLogger('Error resending invite', err.message);
        cleanupModals();
      });
  };

  const removeUserFromGroup = async () => {
    setLoading(true);
    await accountApi
      .removeUserFromGroup(selectedUser[0]?.username, selectedGroup)
      .then(() => {
        handleResponse();
      })
      .catch((err) => {
        preProdLogger('Error removing user from auth group', err.message);
        cleanupModals();
      });
  };

  const addUserToGroup = () => {
    if (selectedAuthGroupOption?.value || customAuthGroup) {
      setLoading(true);
      accountApi
        .addUserToGroup(selectedUser[0]?.username, selectedAuthGroupOption?.value || customAuthGroup)
        .then(() => {
          handleResponse();
        })
        .catch((err) => {
          preProdLogger('Erroring adding user to auth group', err.message);
          cleanupModals();
        });
    }
  };

  const handleResponse = () => {
    loadUsers(showGroups);
    cleanupModals();
  };

  const cleanupModals = () => {
    setSelectedGroup('');
    setSelectedUser([]);
    setSelectedAuthGroupOption(null);
    setCustomAuthGroup('');
    setConfirmRemoveGroupModalVisible(false);
    setConfirmAddUserToAuthGroupModalVisible(false);
    setConfirmAddUserToCustomAuthGroupModalVisible(false);
    setConfirmDeleteUserModalVisible(false);
    setConfirmResendModalVisible(false);
  };

  useEffect(() => {
    loadUsers();
    generateAuthGroupOptions();
  }, []);

  useEffect(() => {
    updateFilter();
  }, [includeFederated]);

  useEffect(() => {
    if (!loading) {
      loadUsers(showGroups);
    }
  }, [showGroups]);

  /** When created emails finish loading, load users*/
  useEffect(() => {
    if (!emailsLoading && !loading) {
      loadUsers(showGroups);
    }
  }, [emailsLoading]);

  return (
    <React.Fragment>
      <ConfirmModal
        title={t(i18nKeys.settings.userManagement.modals.removeUserFromAuthGroup.title)}
        message={t(i18nKeys.settings.userManagement.modals.removeUserFromAuthGroup.message, {
          username: selectedUser[0]?.username,
          group: getGroupTitle(selectedGroup),
        })}
        confirmBtnLabel={t(i18nKeys.settings.userManagement.buttons.yesContinue)}
        onConfirm={() => removeUserFromGroup()}
        visible={confirmRemoveGroupModalVisible}
        onCancel={() => cleanupModals()}
      />
      <ConfirmModal
        title={t(i18nKeys.settings.userManagement.modals.addUserToAuthGroup.title)}
        message={
          <React.Fragment>
            <div>
              {t(i18nKeys.settings.userManagement.modals.addUserToAuthGroup.message, {
                username: selectedUser[0]?.username,
              })}
            </div>
            <Select
              options={authGroupOptions}
              selectedOption={selectedAuthGroupOption}
              onChange={({ detail }) => setSelectedAuthGroupOption(detail.selectedOption)}
            />
          </React.Fragment>
        }
        disabled={!selectedAuthGroupOption}
        onCancel={() => cleanupModals()}
        onConfirm={() => addUserToGroup()}
        visible={confirmAddUserToAuthGroupModalVisible}
      />
      <ConfirmModal
        title={t(i18nKeys.settings.userManagement.modals.addUserToCustomAuthGroup.title)}
        visible={confirmAddUserToCustomAuthGroupModalVisible}
        onCancel={() => cleanupModals()}
        onConfirm={() => addUserToGroup()}
        disabled={!customAuthGroup}
        message={
          <React.Fragment>
            <div>
              {t(i18nKeys.settings.userManagement.modals.addUserToCustomAuthGroup.message, {
                username: selectedUser[0]?.username,
              })}
            </div>
            <Input type="text" value={customAuthGroup} onChange={({ detail }) => setCustomAuthGroup(detail.value)} />
          </React.Fragment>
        }
      />
      <ConfirmModal
        visible={confirmDeleteUserModalVisible}
        onCancel={() => cleanupModals()}
        onConfirm={() => handleDeleteUser()}
      />
      <ConfirmModal
        visible={confirmResendModalVisible}
        onCancel={() => cleanupModals()}
        onConfirm={() => handleResend()}
      />
      <Table
        className="mb-12"
        {...collectionProps}
        header={
          <TableHeader
            title="Users"
            totalItems={filteredUsers.length}
            actionButtons={
              <SpaceBetween direction="horizontal" size="s">
                {selectedUser[0]?.userStatus === 'FORCE_CHANGE_PASSWORD' && (
                  <Button
                    variant="normal"
                    disabled={selectedUser.length < 1}
                    onClick={() => setConfirmResendModalVisible(true)}>
                    {t(i18nKeys.settings.userManagement.buttons.resendInvite)}
                  </Button>
                )}
                <Button
                  variant="primary"
                  disabled={selectedUser.length < 1}
                  onClick={() => setConfirmAddUserToAuthGroupModalVisible(true)}>
                  {t(i18nKeys.settings.userManagement.buttons.addUserToAuthGroup)}
                </Button>
                <Button
                  variant="primary"
                  disabled={selectedUser.length < 1}
                  onClick={() => setConfirmAddUserToCustomAuthGroupModalVisible(true)}>
                  {t(i18nKeys.settings.userManagement.buttons.addUserToCustomAuthGroup)}
                </Button>
                <Button
                  variant="normal"
                  disabled={selectedUser.length < 1}
                  onClick={() => setConfirmDeleteUserModalVisible(true)}>
                  {t(i18nKeys.settings.userManagement.buttons.deleteUser)}
                </Button>
              </SpaceBetween>
            }
          />
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels(t)} />}
        items={items}
        loading={loading}
        visibleColumns={visibleColumns}
        selectionType="single"
        selectedItems={selectedUser}
        onSelectionChange={({ detail }) => setSelectedUser(detail.selectedItems)}
        filter={
          <SpaceBetween direction="horizontal" size="s">
            <TextFilter
              className="text-filter"
              filteringPlaceholder={t(i18nKeys.settings.userManagement.table.searchUsers)}
              {...filterProps}
              countText={t(i18nKeys.tables.matchesCount, { count: filteredItemsCount })}
              filteringAriaLabel={t(i18nKeys.events.filteringLabel)}
            />
            <Checkbox
              className="mt-4"
              checked={includeFederated}
              onChange={() => setIncludeFederated(!includeFederated)}>
              {t(i18nKeys.settings.userManagement.buttons.includeFederatedUsers)}
            </Checkbox>
            <Checkbox className="mt-4" checked={showGroups} onChange={() => setShowGroups(!showGroups)}>
              {t(i18nKeys.settings.userManagement.buttons.showGroups)}
            </Checkbox>
          </SpaceBetween>
        }
        columnDefinitions={COLUMN_DEFINITIONS(preferences, handleRemoveGroupClick)}
      />
    </React.Fragment>
  );
};
export default UsersTable;
