// constants
import { INavSubMenuItemWithAuthRoles, NAV_MENU } from "@/src/constants/navMenu"

// types
import type { INavSubMenuItem, INavMenuItem } from "@/src/components/ui/organisms/Header/Header.types"
import type { User } from "@/src/types/User"


const getNavSubMenuForUser = (user: User, subMenuItems?: INavSubMenuItemWithAuthRoles[]):INavSubMenuItem[] => {
  if (!subMenuItems) return []
  return subMenuItems
    .filter((item) => item.roles.some((role) =>user.memberOfGroup(role))) // remove submenuitems for which user doesn't have access
    .map((item) => ({ ...item, roles: undefined })) // remove roles since its not needed
}

export const getNavMenuForUser = (user: User):INavMenuItem[] => {
  return NAV_MENU
    .map((item) => ({...item, subMenuItems: getNavSubMenuForUser(user, item.subMenuItems)}))
    .filter((item) => item.subMenuItems.length > 0) // remove empty submenuitems
}