import { Header } from '@amzn/awsui-components-react';
import React, { ReactNode } from 'react';
import { NullableString } from '../../types/common';

interface PageHeaderProps {
  title: NullableString;
  buttons?: ReactNode[];
}

export const PageHeader: React.FC<PageHeaderProps> = ({ title, buttons }) => {
  return (
    <div className="awsui-util-mb-m awsui-util-mt-xs">
      <div className="awsui-util-action-stripe-large">
        <div className="awsui-util-action-stripe-title">
          <Header
            variant="h1"
            actions={
              <div
                className="awsui-util-action-stripe-group awsui-util-pv-n"
                style={{ display: 'inline-block', float: 'right' }}>
                {buttons?.map((button, key) => (
                  <span key={key} style={{ marginLeft: 8 }}>
                    {button}
                  </span>
                ))}
              </div>
            }>
            {title}
          </Header>
        </div>
      </div>
    </div>
  );
};
