/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useState } from 'react';
import { Campaign, CampaignGroup } from '../types/Campaign';
import { DateRangeFilter } from '../types/common';
import { preProdLogger } from '../utils/log.utils';
import { useApi } from './api.context';

export interface CampaignsContextValue {
  campaigns: Campaign[];
  campaign: Campaign | undefined;
  campaignGroup: CampaignGroup | undefined;
  loadCampaigns: (filter: DateRangeFilter) => Promise<void>;
  getCampaignById: (id: string) => Promise<void>;
  addCampaignComment: (comment: string) => Promise<void>;
  updateCampaignComment: (commentId: string, commentValue: string) => Promise<void>;
  deleteCampaignComment: (commentId: string) => Promise<void>;
  approveChangeRequest: (comment: string) => Promise<void>;
  denyChangeRequest: (comment: string) => Promise<void>;
  markChangeRequestPending: () => Promise<void>;
  markRequestPending: () => Promise<void>;
  approveCampaignRequest: (slug: string, comment: string) => Promise<void>;
  denyCampaignRequest: (comment: string) => Promise<void>;
  deleteCampaign: () => Promise<void>;
  createCampaignGroup: (campaignGroup: CampaignGroup) => Promise<void>;
  cancelChangeRequest: (comment: string) => Promise<void>;
  cancelCampaignRequest: (comment: string) => Promise<void>;
  getCampaignGroupById: (campaignGroupId: string) => Promise<void>;
}

const CampaignsContext = React.createContext<CampaignsContextValue>({
  campaigns: [],
  campaign: undefined,
  campaignGroup: undefined,
  loadCampaigns: () => new Promise(() => {}),
  getCampaignById: () => new Promise(() => {}),
  addCampaignComment: (commentValue: string) => new Promise(() => {}),
  updateCampaignComment: (commentId: string, commentValue: string) => new Promise(() => {}),
  deleteCampaignComment: (commentId: string) => new Promise(() => {}),
  approveChangeRequest: (comment: string) => new Promise(() => {}),
  denyChangeRequest: (comment: string) => new Promise(() => {}),
  markChangeRequestPending: () => new Promise(() => {}),
  markRequestPending: () => new Promise(() => {}),
  approveCampaignRequest: (slug: string, comment: string) => new Promise(() => {}),
  denyCampaignRequest: (comment: string) => new Promise(() => {}),
  deleteCampaign: () => new Promise(() => {}),
  createCampaignGroup: () => new Promise(() => {}),
  cancelChangeRequest: () => new Promise(() => {}),
  cancelCampaignRequest: () => new Promise(() => {}),
  getCampaignGroupById: () => new Promise(() => {}),
});

const CampaignsProvider: React.FC = ({ children }) => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [campaign, setCampaign] = useState<Campaign | undefined>();
  const [campaignGroup, setCampaignGroup] = useState<CampaignGroup | undefined>();
  const { campaignApi } = useApi();

  const loadCampaigns = async (filter: DateRangeFilter) => {
    await campaignApi
      .getJamCampaignsByDateRange(filter)
      .then((res) => {
        setCampaigns(res);
      })
      .catch((err) => {
        preProdLogger('Error getting campaigns', err.message);
      });
  };

  const getCampaignById = async (id: string) => {
    await campaignApi
      .getCampaign(id)
      .then((res) => {
        setCampaign(res);
      })
      .catch((err) => {
        preProdLogger('Error getting campaigns', err.message);
      });
  };

  const addCampaignComment = async (commentValue: string) => {
    if (campaign?.id) {
      await campaignApi
        .addComment(campaign.id, commentValue)
        .then(async () => {
          if (campaign?.id) {
            await getCampaignById(campaign.id);
          }
        })
        .catch((err) => {
          preProdLogger('Error adding comment', err.message);
        });
    }
  };

  const updateCampaignComment = async (commentId: string, commentValue: string) => {
    if (campaign?.id) {
      await campaignApi
        .updateComment(campaign.id, commentId, commentValue)
        .then(async () => {
          if (campaign?.id) {
            await getCampaignById(campaign.id);
          }
        })
        .catch((err) => {
          preProdLogger('Error updating comment', err.message);
        });
    }
  };

  const deleteCampaignComment = async (commentId: string) => {
    if (campaign?.id) {
      await campaignApi
        .deleteComment(campaign.id, commentId)
        .then(async () => {
          if (campaign?.id) {
            await getCampaignById(campaign.id);
          }
        })
        .catch((err) => {
          preProdLogger('Error deleting comment', err.message);
        });
    }
  };

  const approveChangeRequest = async (comment: string) => {
    if (campaign?.id) {
      await campaignApi
        .approveJamCampaignChangeRequest(campaign.id, comment)
        .then(async () => {
          if (campaign?.id) {
            await getCampaignById(campaign.id);
          }
        })
        .catch((err) => {
          preProdLogger('Error approving campaign request', err.message);
        });
    }
  };

  const denyChangeRequest = async (comment: string) => {
    if (campaign?.id) {
      await campaignApi
        .denyJamCampaignChangeRequest(campaign.id, comment)
        .then(async () => {
          if (campaign?.id) {
            await getCampaignById(campaign.id);
          }
        })
        .catch((err) => {
          preProdLogger('Error denying change request', err.message);
        });
    }
  };

  const cancelChangeRequest = async (comment: string) => {
    if (campaign?.id) {
      await campaignApi
        .cancelJamCampaignChangeRequest(campaign?.id, comment)
        .then(async () => {
          if (campaign?.id) {
            await getCampaignById(campaign.id);
          }
        })
        .catch((err) => {
          preProdLogger('Error cancelling change request', err.message);
        });
    }
  };

  const cancelCampaignRequest = async (comment: string) => {
    if (campaign?.id) {
      await campaignApi
        .cancelJamCampaignRequest(campaign?.id, comment)
        .then(async () => {
          if (campaign?.id) {
            await getCampaignById(campaign.id);
          }
        })
        .catch((err) => {
          preProdLogger('Error cancelling campaign request', err.message);
        });
    }
  };

  const markChangeRequestPending = async () => {
    if (campaign?.id) {
      await campaignApi
        .setJamCampaignChangeRequestPending(campaign.id)
        .then(async () => {
          if (campaign?.id) {
            await getCampaignById(campaign.id);
          }
        })
        .catch((err) => {
          preProdLogger('Error setting change request as pending', err.message);
        });
    }
  };

  const markRequestPending = async () => {
    if (campaign?.id) {
      await campaignApi
        .setJamCampaignRequestPending(campaign.id)
        .then(async () => {
          if (campaign?.id) {
            await getCampaignById(campaign.id);
          }
        })
        .catch((err) => {
          preProdLogger('Error setting request as pending', err.message);
        });
    }
  };

  const approveCampaignRequest = async (slug: string, comment: string) => {
    if (campaign?.id) {
      await campaignApi
        .approveJamCampaignRequest(campaign.id, slug, comment)
        .then(async () => {
          if (campaign?.id) {
            await getCampaignById(campaign.id);
          }
        })
        .catch((err) => {
          preProdLogger('Error setting request as pending', err.message);
        });
    }
  };

  const denyCampaignRequest = async (comment: string) => {
    if (campaign?.id) {
      await campaignApi
        .denyJamCampaignRequest(campaign.id, comment)
        .then(async () => {
          if (campaign?.id) {
            await getCampaignById(campaign.id);
          }
        })
        .catch((err) => {
          preProdLogger('Error setting request as pending', err.message);
        });
    }
  };

  const deleteCampaign = async () => {
    if (campaign?.id) {
      await campaignApi
        .deleteCampaign(campaign)
        .then(() => {
          preProdLogger('Deleted campaign');
        })
        .catch((err) => {
          preProdLogger('Error deleting campaign', err.message);
        });
    }
  };

  const createCampaignGroup = async (newCampaignGroup: CampaignGroup) => {
    await campaignApi.createCampaignGroup(newCampaignGroup);
  };

  const getCampaignGroupById = async (campaignGroupId: string) => {
    if (campaign?.id) {
      await campaignApi.getCampaignGroup(campaign?.id, campaignGroupId).then((res) => {
        setCampaignGroup(res);
      });
    }
  };

  const data: CampaignsContextValue = {
    campaigns,
    campaign,
    campaignGroup,
    loadCampaigns,
    getCampaignById,
    addCampaignComment,
    updateCampaignComment,
    deleteCampaignComment,
    approveChangeRequest,
    denyChangeRequest,
    markChangeRequestPending,
    markRequestPending,
    approveCampaignRequest,
    denyCampaignRequest,
    cancelChangeRequest,
    cancelCampaignRequest,
    deleteCampaign,
    createCampaignGroup,
    getCampaignGroupById,
  };

  return <CampaignsContext.Provider value={data}>{children}</CampaignsContext.Provider>;
};

const useCampaigns = () => {
  const context = useContext(CampaignsContext);
  if (context === undefined) {
    throw new Error('useCampaignss can only be used inside CampaignsProvider');
  }
  return context;
};

export { CampaignsProvider, useCampaigns };
