/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-function */
import _ from 'lodash';
import * as React from 'react';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import {
  CreateUsagePlanRequest,
  MAX_ALLOWLIST,
  MAX_BLOCKED_CHALLENGES,
  MAX_DAY_FREQUENCY,
  MAX_DURATION,
  MAX_FREE_FORM,
  MAX_LEAD_TIME,
  MAX_MAINTAINERS,
  MAX_MONTH_FREQUENCY,
  MAX_NAME_LENGTH,
  MAX_NUM_CHALLENGES,
  MAX_PARTICIPANTS,
  MAX_TEAM_SIZE,
  MAX_WEEK_FREQUENCY,
  MAX_YEAR_FREQUENCY,
  MIN_DURATION,
  MIN_FREE_FORM,
  MIN_FREQUENCY,
  MIN_LEAD_TIME,
  MIN_NAME_LENGTH,
  MIN_NUM_CHALLENGES,
  MIN_PARTICIPANTS,
  MIN_TEAM_SIZE,
  MinMax,
  RequestFrequency,
  UsagePlan,
  UsagePlanComment,
  UsagePlanDetails,
} from '../../types/usage-plan/UsagePlan';
import { useApi } from '../api.context';
import { callIgnoringExceptionsAsync } from '../../utils/call-ignoring-exceptions';
import {
  DateString,
  FrequencyUnit,
  Nullable,
  NullableDateString,
  NullableNumber,
  NullableString,
  RequestType,
} from '../../types/common';
import { LabProvider } from '../../types/LabProvider';
import { useTranslation } from 'react-i18next';
import { isValidInteger, isValidLength, isValidMinMax } from '../../utils/validation.utils';
import { i18nKeys } from '../../utils/i18n.utils';
import { isEmpty as isStringEmpty } from '../../utils/string.utils';
import { isValidDateString } from '../../utils/time.utils';
import moment from 'moment/moment';

// eslint-disable-next-line no-shadow
export enum EditUsagePlanActions {
  ARCHIVED,
  NAME,
  ALLOWLIST,
  MAINTAINERS,
  EXPIRATION,
  NOTES,
  DESCRIPTION,
  REQUEST_TYPE,
  ALLOWED_LAB_PROVIDERS,
  MIN_DAYS_BEFORE_EVENT,
  BLOCKED_CHALLENGES,
  TEAM_SIZE,
  EVENT_DURATION,
  NUM_OF_CHALLENGES,
  NUM_OF_PARTICIPANTS,
  REQUEST_FREQUENCY,
}

export interface IUsagePlanContext {
  usagePlans: UsagePlan[];
  usagePlanDetails: UsagePlanDetails | undefined;
  editedUsagePlan: UsagePlan | undefined;
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  comments: UsagePlanComment[];
  toggleEditMode: () => void;
  loadPlans: () => void;
  getPlanDetails: (usagePlanId: string) => void;
  initializeCreateEditPlan: (usagePlan?: UsagePlan) => void;
  handleUpdateUsagePlan: (action: EditUsagePlanActions, payload: any) => string;
  createUsagePlan: (payload: any) => Promise<string>;
  updateUsagePlan: (payload: any) => void;
  updateAllowlist: (emails: string[], usagePlanId: string) => void;
  getComments: (usagePlanId: string) => void;
  addComment: (value: string) => Promise<void>;
  updateComment: (commentId: string, value: string) => Promise<void>;
  deleteComment: (commentId: string) => Promise<void>;
  getUsagePlanDashboardUrl: (usagePlanId: string) => Promise<string>;
  reevaluateRequests: (usagePlanId: string) => void;
  isValidUsagePlan: (plan?: UsagePlan) => boolean;
}

const UsagePlanContext = React.createContext<IUsagePlanContext>({
  usagePlans: [],
  usagePlanDetails: undefined,
  editedUsagePlan: undefined,
  editMode: false,
  setEditMode: () => {},
  comments: [],
  toggleEditMode: () => {},
  loadPlans: () => {},
  getPlanDetails: () => {},
  initializeCreateEditPlan: () => {},
  handleUpdateUsagePlan: () => {
    return '';
  },
  createUsagePlan: () => new Promise(() => {}),
  updateUsagePlan: () => {},
  updateAllowlist: () => {},
  getComments: () => {},
  addComment: () => new Promise(() => {}),
  deleteComment: () => new Promise(() => {}),
  updateComment: () => new Promise(() => {}),
  getUsagePlanDashboardUrl: (): any => {},
  reevaluateRequests: () => {},
  isValidUsagePlan: () => {
    return false;
  },
});

const UsagePlanProvider: React.FC = ({ children }) => {
  const [usagePlans, setUsagePlans] = useState<UsagePlan[]>([]);
  const [usagePlanDetails, setUsagePlanDetails] = useState<UsagePlanDetails>();
  const [editedUsagePlan, setEditedUsagePlan] = useState<UsagePlan>();
  const [editMode, setEditMode] = useState(false);
  const [comments, setComments] = useState<UsagePlanComment[]>([]);
  const { usagePlanApi } = useApi();
  const [planId, setUsagePlanId] = useState(usagePlanDetails?.versions[0].id);
  const [dashboardUrl, setDashboardUrl] = useState<string>('');
  const { t } = useTranslation();

  const getComments = (usagePlanId: string) => {
    void callIgnoringExceptionsAsync(async () => {
      return await usagePlanApi.getUsagePlanComments(usagePlanId);
    }).then((c) => {
      if (c) {
        // sort by date
        c.sort((a, b) => {
          return Date.parse(a.createdAt || '') - Date.parse(b.createdAt || '');
        });
        setComments(c);
      }
    });
  };

  const addComment = async (value: string) => {
    if (planId) {
      return await usagePlanApi.addComment(planId, value).then(() => {
        getComments(planId);
      });
    }
  };

  const updateComment = async (commentId: string, value: string) => {
    if (planId) {
      return await usagePlanApi.updateComment(planId, commentId, value).then(() => {
        getComments(planId);
      });
    }
  };

  const deleteComment = async (commentId: string) => {
    if (planId) {
      return await usagePlanApi.deleteComment(planId, commentId).then(() => {
        getComments(planId);
      });
    }
  };

  const getUsagePlanDashboardUrl = async (usagePlanId: string) => {
    return await usagePlanApi.getUsagePlanDashboardUrl(usagePlanId);
  };

  const loadPlans = () => {
    void callIgnoringExceptionsAsync(async () => {
      return await usagePlanApi.getUsagePlans(false);
    }).then((plans: UsagePlan[] | undefined) => {
      if (plans) setUsagePlans(plans);
    });
  };

  const getPlanDetails = (usagePlanId: string) => {
    void callIgnoringExceptionsAsync(async () => {
      return await usagePlanApi.getUsagePlanDetails(usagePlanId);
    }).then((details: UsagePlanDetails | undefined) => {
      if (details) setUsagePlanDetails(details);
      if (details?.versions) setUsagePlanId(details?.findLatestVersion()?.id);
    });
  };

  const initializeCreateEditPlan = (plan?: UsagePlan) => {
    setEditedUsagePlan(plan ?? UsagePlan.setDefaults(new UsagePlan()));
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const reevaluateRequests = (usagePlanId: string) => {
    void callIgnoringExceptionsAsync(async () => {
      await usagePlanApi.reevaluateRequests(usagePlanId);
    });
  };

  const handleUpdateUsagePlan = (action: EditUsagePlanActions, payload: any) => {
    const planClone = _.cloneDeep(editedUsagePlan);
    let errMsg = '';
    if (planClone) {
      switch (action) {
        case EditUsagePlanActions.ARCHIVED:
          planClone.archived = payload as boolean;
          break;
        case EditUsagePlanActions.NAME:
          const name = payload as string;
          errMsg = isValidName(name);
          if (isStringEmpty(errMsg)) {
            planClone.name = name;
          }
          break;
        case EditUsagePlanActions.ALLOWLIST:
          const allowlist = payload as string[];
          errMsg = isValidAllowlist(allowlist);
          if (isStringEmpty(errMsg)) {
            planClone.setAllowlist(allowlist);
          }
          break;
        case EditUsagePlanActions.MAINTAINERS:
          const maintainers = payload as string[];
          errMsg = isValidMaintainers(maintainers);
          if (isStringEmpty(errMsg)) {
            planClone.setMaintainers(maintainers);
          }
          break;
        case EditUsagePlanActions.EXPIRATION:
          const expiration = payload as DateString;
          errMsg = isValidExpiration(expiration);
          if (isStringEmpty(errMsg)) {
            planClone.setExpiration(expiration);
          }
          break;
        case EditUsagePlanActions.NOTES:
          const notes = payload as string;
          errMsg = isValidNotes(notes);
          if (isStringEmpty(errMsg)) {
            planClone.notes = notes;
          }
          break;
        case EditUsagePlanActions.DESCRIPTION:
          const description = payload as string;
          errMsg = isValidDescription(description);
          if (isStringEmpty(errMsg)) {
            planClone.description = description;
          }
          break;
        case EditUsagePlanActions.REQUEST_TYPE:
          const reqType = payload as RequestType;
          errMsg = isValidRequestType(reqType);
          if (isStringEmpty(errMsg)) {
            planClone.requestType = reqType;
          }
          break;
        case EditUsagePlanActions.ALLOWED_LAB_PROVIDERS:
          const providers = payload as LabProvider[];
          errMsg = isValidLabProvider(providers);
          if (isStringEmpty(errMsg)) {
            planClone.allowedLabProviders = providers;
          }
          break;
        case EditUsagePlanActions.MIN_DAYS_BEFORE_EVENT:
          const daysBeforeEvent = payload as number;
          errMsg = isValidDaysBeforeEvent(daysBeforeEvent);
          if (isStringEmpty(errMsg)) {
            planClone.minDaysBeforeEvent = daysBeforeEvent;
          }
          break;
        case EditUsagePlanActions.BLOCKED_CHALLENGES:
          const blockedChallenges = payload as string[];
          errMsg = isValidBlockedChallenges(blockedChallenges);
          if (isStringEmpty(errMsg)) {
            planClone.blockedChallenges = blockedChallenges;
          }
          break;
        case EditUsagePlanActions.TEAM_SIZE:
          const teamSize = payload as MinMax;
          errMsg = isValidTeamSize(teamSize);
          if (isStringEmpty(errMsg)) {
            planClone.maxTeamSize = teamSize;
          }
          break;
        case EditUsagePlanActions.EVENT_DURATION:
          const duration = payload as number;
          errMsg = isValidDuration(duration);
          if (isStringEmpty(errMsg)) {
            planClone.eventDuration = duration;
          }
          break;
        case EditUsagePlanActions.NUM_OF_CHALLENGES:
          const numOfChallenges = payload as number;
          errMsg = isValidNumOfChallenges(numOfChallenges);
          if (isStringEmpty(errMsg)) {
            planClone.numOfChallenges = numOfChallenges;
          }
          break;
        case EditUsagePlanActions.NUM_OF_PARTICIPANTS:
          const numOfParticipants = payload as MinMax;
          errMsg = isValidParticipants(numOfParticipants);
          if (isStringEmpty(errMsg)) {
            planClone.numOfParticipants = numOfParticipants;
          }
          break;
        case EditUsagePlanActions.REQUEST_FREQUENCY:
          const frequency = payload as RequestFrequency;
          errMsg = isValidRequestFrequency(frequency);
          if (isStringEmpty(errMsg)) {
            planClone.requestFrequency = frequency;
          }
          break;
        default:
          break;
      }
      setEditedUsagePlan(planClone);
    }
    return errMsg;
  };

  const createUsagePlan = (payload: any): Promise<string> => {
    const plan = Object.assign(new UsagePlan(), payload) as UsagePlan;
    return usagePlanApi
      .createPlan(CreateUsagePlanRequest.forCreate(plan))
      .then((createdPlan: UsagePlan | undefined) => {
        // if creation is successful, bring user to plan detail page
        if (createdPlan)
          setUsagePlanDetails(Object.assign(new UsagePlanDetails(), { versions: [createdPlan], requests: [] }));
        return createdPlan?.id ?? '';
      });
  };

  const updateUsagePlan = (payload: any) => {
    const plan = Object.assign(new UsagePlan(), payload) as UsagePlan;
    void callIgnoringExceptionsAsync(async () => {
      return await usagePlanApi.updatePlan(plan);
    }).then((updatedPlan: UsagePlan | undefined) => {
      // if update was successful, set the new plan
      if (updatedPlan)
        setUsagePlanDetails(Object.assign(new UsagePlanDetails(), { versions: [updatedPlan], requests: [] }));
    });
  };

  const updateAllowlist = (emails: string[], usagePlanId: string) => {
    void callIgnoringExceptionsAsync(async () => {
      return await usagePlanApi.updateAllowlist(emails, usagePlanId);
    }).then((updatedPlan: UsagePlan | undefined) => {
      // if update was successful, set the new plan
      if (updatedPlan)
        setUsagePlanDetails(Object.assign(new UsagePlanDetails(), { versions: [updatedPlan], requests: [] }));
    });
  };

  const isValidUsagePlan = (plan?: UsagePlan) => {
    const isValidBasePlan =
      plan &&
      isStringEmpty(isValidName(plan.name)) &&
      isStringEmpty(isValidRequestFrequency(plan.requestFrequency)) &&
      isStringEmpty(isValidAllowlist(plan.getAllowlist())) &&
      isStringEmpty(isValidMaintainers(plan.getMaintainers())) &&
      isStringEmpty(isValidExpiration(plan.expiration)) &&
      isStringEmpty(isValidNotes(plan.notes)) &&
      isStringEmpty(isValidDescription(plan.description)) &&
      isStringEmpty(isValidRequestType(plan.requestType)) &&
      isStringEmpty(isValidLabProvider(plan.allowedLabProviders)) &&
      isStringEmpty(isValidBlockedChallenges(plan.blockedChallenges)) &&
      isStringEmpty(isValidNumOfChallenges(plan.numOfChallenges)) &&
      isStringEmpty(isValidParticipants(plan.numOfParticipants));
    if (isValidBasePlan && plan.requestType === RequestType.EVENT) {
      // check for values that are only relevant for events
      return (
        isStringEmpty(isValidTeamSize(plan.maxTeamSize)) &&
        isStringEmpty(isValidDuration(plan.eventDuration)) &&
        isStringEmpty(isValidDaysBeforeEvent(plan.minDaysBeforeEvent))
      );
    }
    return isValidBasePlan as boolean;
  };

  const isValidName = (name: NullableString) => {
    if (isValidLength(name ?? '', MIN_NAME_LENGTH, MAX_NAME_LENGTH, true)) {
      return '';
    }
    return t(i18nKeys.usagePlan.fields.name.invalid, { min: MIN_NAME_LENGTH, max: MAX_NAME_LENGTH });
  };

  const isValidRequestFrequency = (frequency: Nullable<RequestFrequency>) => {
    if (!frequency) {
      return t(i18nKeys.usagePlan.fields.requestLimit.invalidEmpty);
    }
    const min = MIN_FREQUENCY;
    let max;
    switch (frequency.unit) {
      case FrequencyUnit.DAY:
        max = MAX_DAY_FREQUENCY;
        break;
      case FrequencyUnit.WEEK:
        max = MAX_WEEK_FREQUENCY;
        break;
      case FrequencyUnit.MONTH:
        max = MAX_MONTH_FREQUENCY;
        break;
      case FrequencyUnit.YEAR:
        max = MAX_YEAR_FREQUENCY;
        break;
    }
    if (isValidInteger(frequency.frequency, min, max, true)) {
      return '';
    }
    return t(i18nKeys.usagePlan.fields.requestLimit.invalid, { min, max, unit: frequency.unit.toLowerCase() });
  };

  const isValidRequestType = (reqType: Nullable<RequestType>) => {
    if (reqType) {
      return '';
    }
    return t(i18nKeys.usagePlan.fields.requestType.invalid);
  };

  const isValidDuration = (duration: NullableNumber) => {
    if (isValidInteger(duration, MIN_DURATION, MAX_DURATION, true)) {
      return '';
    }
    return t(i18nKeys.usagePlan.fields.duration.invalid, { min: MIN_DURATION, max: MAX_DURATION });
  };

  const isValidDaysBeforeEvent = (daysBeforeEvent: NullableNumber) => {
    if (isValidInteger(daysBeforeEvent, MIN_LEAD_TIME, MAX_LEAD_TIME, true)) {
      return '';
    }
    return t(i18nKeys.usagePlan.fields.minDaysBeforeEvent.invalid, { min: MIN_LEAD_TIME, max: MAX_LEAD_TIME });
  };

  const isValidTeamSize = (teamSize: Nullable<MinMax>) => {
    if (teamSize && isValidMinMax(teamSize.min, teamSize.max, MIN_TEAM_SIZE, MAX_TEAM_SIZE)) {
      return '';
    }
    return t(i18nKeys.usagePlan.fields.teamSize.invalid, { min: MIN_TEAM_SIZE, max: MAX_TEAM_SIZE });
  };

  const isValidNumOfChallenges = (numOfChallenges: NullableNumber) => {
    if (isValidInteger(numOfChallenges, MIN_NUM_CHALLENGES, MAX_NUM_CHALLENGES, true)) {
      return '';
    }
    return t(i18nKeys.usagePlan.fields.challenge.invalid, { min: MIN_NUM_CHALLENGES, max: MAX_NUM_CHALLENGES });
  };

  const isValidParticipants = (participants?: MinMax) => {
    if (participants && isValidMinMax(participants.min, participants.max, MIN_PARTICIPANTS, MAX_PARTICIPANTS)) {
      return '';
    }
    return t(i18nKeys.usagePlan.fields.participant.invalid, { min: MIN_PARTICIPANTS, max: MAX_PARTICIPANTS });
  };

  const isValidNotes = (notes: NullableString) => {
    if (isValidLength(notes, MIN_FREE_FORM, MAX_FREE_FORM, false)) {
      return '';
    }
    return t(i18nKeys.usagePlan.fields.notes.invalid, { min: MIN_FREE_FORM, max: MAX_FREE_FORM });
  };

  const isValidDescription = (description: NullableString) => {
    if (isValidLength(description, MIN_FREE_FORM, MAX_FREE_FORM, false)) {
      return '';
    }
    return t(i18nKeys.usagePlan.fields.description.invalid, { min: MIN_FREE_FORM, max: MAX_FREE_FORM });
  };

  const isValidBlockedChallenges = (challenges?: string[]) => {
    if (challenges && challenges.length > MAX_BLOCKED_CHALLENGES) {
      return t(i18nKeys.usagePlan.fields.challengeDeny.invalid, { max: MAX_BLOCKED_CHALLENGES });
    }
    return '';
  };

  const isValidMaintainers = (maintainers?: string[]) => {
    if (maintainers && maintainers.length > MAX_MAINTAINERS) {
      return t(i18nKeys.usagePlan.fields.maintainers.invalid, { max: MAX_MAINTAINERS });
    }
    return '';
  };

  const isValidAllowlist = (allowlist?: string[]) => {
    if (allowlist && allowlist.length > MAX_ALLOWLIST) {
      return t(i18nKeys.usagePlan.fields.allowedUsers.invalid, { max: MAX_ALLOWLIST });
    }
    return '';
  };

  const isValidExpiration = (expiration: NullableDateString) => {
    if (!expiration || (isValidDateString(expiration) && moment(expiration).isAfter(moment()))) {
      return '';
    }
    return t(i18nKeys.usagePlan.fields.expiration.invalid);
  };

  const isValidLabProvider = (labProvider?: LabProvider[]) => {
    if (labProvider && labProvider.length === 1 && labProvider.includes(LabProvider.AWS_LABS)) {
      return '';
    }
    return t(i18nKeys.usagePlan.fields.labProvider.invalid);
  };

  const data: IUsagePlanContext = {
    usagePlans,
    usagePlanDetails,
    editedUsagePlan,
    editMode,
    setEditMode,
    comments,
    toggleEditMode,
    loadPlans,
    getPlanDetails,
    initializeCreateEditPlan,
    handleUpdateUsagePlan,
    createUsagePlan,
    updateUsagePlan,
    updateAllowlist,
    getComments,
    addComment,
    updateComment,
    deleteComment,
    getUsagePlanDashboardUrl,
    reevaluateRequests,
    isValidUsagePlan,
  };

  return <UsagePlanContext.Provider value={data}>{children}</UsagePlanContext.Provider>;
};

const usePlans = () => {
  const context = useContext(UsagePlanContext);
  if (context === undefined) {
    throw new Error('usePlans can only be used inside UsagePlanProvider');
  }
  return context;
};

export { UsagePlanProvider, usePlans };
