import { ExpandableSection, FormField, Grid, Header, Textarea } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditEventActions, useEditEvent } from '../../../../store/edit-event.context';
import { Event } from '../../../../types/Event';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { decodeForMarkdown } from '../../../../utils/string.utils';
import { Markdown } from '../../../common/Markdown';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import { useUser } from '../../../../store/user.context';

interface EventCapacityRedirectSettingsProps {
  event?: Event;
}

const EventCapacityRedirectSettings: React.FC<EventCapacityRedirectSettingsProps> = ({ event }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { editMode, editedEvent, handleUpdateEditEvent } = useEditEvent();

  return (
    <ExpandableSection
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.eventCapacityRedirectSettings)}</Header>}>
      <Header variant="h2" description={t(i18nKeys.events.eventDetails.headers.eventCapacityMessage.description)}>
        {t(i18nKeys.events.eventDetails.headers.eventCapacityMessage.header)}
      </Header>
      {!editMode && (
        <Grid gridDefinition={[{ colspan: 8 }]}>
          <Markdown content={event?.eventFullMessage} />
        </Grid>
      )}
      {editMode && user && (
        <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
          <FormField label="">
            <div style={{ marginTop: '16px' }}>
              <Textarea
                value={editedEvent?.eventFullMessage || ''}
                disabled={!editedEvent?.canEditAttribute('eventFullMessage', user)}
                onChange={({ detail }) =>
                  handleUpdateEditEvent(EditEventActions.EVENT_FULL_MESSAGE, decodeForMarkdown(detail.value))
                }
              />
              <span style={{ color: awsui.colorTextFormSecondary }}>
                {t(i18nKeys.events.eventDetails.headers.eventCapacityMessage.footer)}
              </span>
            </div>
          </FormField>
          <Markdown content={editedEvent?.eventFullMessage} />
        </Grid>
      )}
    </ExpandableSection>
  );
};
export default EventCapacityRedirectSettings;
