export enum RoutePath {
  ROOT = '/',
  OAUTH2 = '/oauth2',
  SIGNOUT = '/signout',
  EVENTS = '/events',
  NEW_EVENT = '/events/new',
  EVENT_DETAILS = '/events/:eventName',
  CHALLENGES = '/challenges',
  LABS = '/labs',
  NEW_CAMPAIGN = '/campaigns/new',
  CAMPAIGNS = '/campaigns',
  CHALLENGE_SETS = '/challenges/sets',
  CHALLENGE_SET_DETAILS = '/challenges/sets/:challengeSetId',
  NEW_CHALLENGE_SET = '/challenges/sets/new',
  CAMPAIGN_DETAILS = '/campaigns/:campaignId',
  CAMPAIGN_GROUP_DETAILS = '/campaigns/:campaignId/groups/:eventName',
  CHALLENGE_DETAILS = '/challenges/:challengeId',
  NEW_CHALLENGE = '/challenges/new',
  CHALLENGE_SNIPPETS = '/challenges/snippets',
  GAME_BOARDS = '/challenges/boards',
  REVIEW_CHALLENGES = '/review-challenges',
  ACCOUNT = '/account',
  USAGE_PLANS = '/usage-plans',
  NEW_USAGE_PLAN = '/usage-plans/create',
  USAGE_PLAN_DETAILS = '/usage-plans/:planId',
  REPORTS = '/reports',
  PRIZES = '/prizes',
  SETTINGS = '/settings',
  DOCUMENTATION = '/documentation',
}
