import { INavSubMenuItem  } from '@/src/components/ui/organisms/Header/Header.types';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { RoutePath } from '@/src/RoutePath';

import { AuthRole} from '@amzn/aws-jam-constants';

export interface INavSubMenuItemWithAuthRoles extends INavSubMenuItem {
  roles: AuthRole[]
}

export interface INavMenuItemWithRoles {
  id: string;
  text: string;
  href?: string;
  subMenuItems?: INavSubMenuItemWithAuthRoles[];
}

const ALL_ROLES_ACCESS = [
  AuthRole.GUEST_USER,
  AuthRole.SUBSCRIBED_USER,
  AuthRole.JAM_ADMIN,
  AuthRole.EVENT_SUPPORT,
  AuthRole.CHALLENGE_SUPPORT,
  AuthRole.CHALLENGE_BUILDER,
  AuthRole.CHALLENGE_REVIEWER,
  AuthRole.EVENT_PRIZES_ADMIN,
]

export enum NAV_MENU_ITEM_ID {
  PLAY_MENU_ID = 'nav-play-menuitem',
  HOST_MENU_ID = 'nav-host-menuitem',
  CREATE_MENU_ID = 'nav-create-menuitem',
  SUPPORT_MENU_ID = 'nav-support-menuitem',
  ADMIN_MENU_ID = 'nav-admin-menuitem'
}

const PLAY_MENU: INavMenuItemWithRoles = {
  id: NAV_MENU_ITEM_ID.PLAY_MENU_ID,
  text: i18nKeys.header.nav.play.text,
  subMenuItems: [
    {
      id: 'nav-play-join-jam-submenuitem',
      text:  i18nKeys.header.nav.play.joinAJam.text,
      description: i18nKeys.header.nav.play.joinAJam.description,
      href: '/join', // TODO: Move to RoutePath once added here
      roles: ALL_ROLES_ACCESS
    },
    {
      id: 'nav-play-my-jam-submenuitem',
      text: i18nKeys.header.nav.play.myJamEvents.text,
      description: i18nKeys.header.nav.play.myJamEvents.description,
      href: '/home/my-jams',
      roles: ALL_ROLES_ACCESS
    },
    {
      id: 'nav-play-upcoming-jam-submenuitem',
      text: i18nKeys.header.nav.play.publicEvents.text,
      description: i18nKeys.header.nav.play.publicEvents.description,
      href: '/#events', // TODO: Move to RoutePath once added here
      roles: ALL_ROLES_ACCESS
    },
    {
      id: 'nav-play-join-jam-submenuitem',
      text: i18nKeys.header.nav.play.support.text,
      description: i18nKeys.header.nav.play.support.description,
      href: '/#faq', // TODO: Move to RoutePath once added here
      roles: ALL_ROLES_ACCESS
    },
  ],
};

const HOST_MENU: INavMenuItemWithRoles = {
  text: i18nKeys.header.nav.host.text,
  id: NAV_MENU_ITEM_ID.HOST_MENU_ID,
  subMenuItems: [
    {
      id: 'nav-host-discover-submenuitem',
      text: i18nKeys.header.nav.host.discoverEvents.text,
      description: i18nKeys.header.nav.host.discoverEvents.description,
      href: '/discover', // TODO: Move to RoutePath once added here
      roles: ALL_ROLES_ACCESS
    },
    {
      id: 'nav-host-create-submenuitem',
      text: i18nKeys.header.nav.host.createEvent.text,
      description: i18nKeys.header.nav.host.createEvent.description,
      href: RoutePath.NEW_EVENT,
      roles: ALL_ROLES_ACCESS
    },
    {
      id: 'nav-host-event-library-submenuitem',
      text: i18nKeys.header.nav.host.eventLibrary.text,
      description: i18nKeys.header.nav.host.eventLibrary.description,
      href: RoutePath.EVENTS,
      roles: ALL_ROLES_ACCESS
    },
    {
      id: 'nav-host-support-submenuitem',
      text: i18nKeys.header.nav.host.support.text,
      description: i18nKeys.header.nav.host.support.description,
      href: RoutePath.DOCUMENTATION,
      roles: ALL_ROLES_ACCESS
    },
  ],
};

const CREATE_MENU: INavMenuItemWithRoles = {
  text: i18nKeys.header.nav.create.text,
  id: NAV_MENU_ITEM_ID.CREATE_MENU_ID,
  subMenuItems: [
    {
      id: 'nav-create-challenge-submenuitem',
      text: i18nKeys.header.nav.create.createChallenge.text,
      description: i18nKeys.header.nav.create.createChallenge.description,
      href: RoutePath.NEW_CHALLENGE,
      roles: [ AuthRole.JAM_ADMIN, AuthRole.CHALLENGE_SUPPORT, AuthRole.CHALLENGE_REVIEWER, AuthRole.CHALLENGE_BUILDER ]
    },
    {
      id: 'nav-create-challenge-set-submenuitem',
      text: i18nKeys.header.nav.create.createChallengeSet.text,
      description: i18nKeys.header.nav.create.createChallengeSet.description,
      href:  RoutePath.NEW_CHALLENGE_SET,
      roles: [ AuthRole.JAM_ADMIN, AuthRole.CHALLENGE_SUPPORT, AuthRole.CHALLENGE_REVIEWER, AuthRole.CHALLENGE_BUILDER ]
    },
    {
      id: 'nav-create-support-submenuitem',
      text: i18nKeys.header.nav.create.support.text,
      description: i18nKeys.header.nav.create.support.description,
      href: RoutePath.DOCUMENTATION,
      roles: [ AuthRole.JAM_ADMIN, AuthRole.CHALLENGE_SUPPORT, AuthRole.CHALLENGE_REVIEWER, AuthRole.CHALLENGE_BUILDER ]
    },
  ],
};

const SUPPORT_MENU:INavMenuItemWithRoles = {
  text: i18nKeys.header.nav.support.text,
  id: NAV_MENU_ITEM_ID.SUPPORT_MENU_ID,
  subMenuItems: [
    {
      id: 'nav-support-learners-submenuitem',
      text: i18nKeys.header.nav.support.forLearners.text,
      description: i18nKeys.header.nav.support.forLearners.description,
      href: '/#faq',
      roles: ALL_ROLES_ACCESS
    },
    {
      id: 'nav-support-event-owners-submenuitem',
      text: i18nKeys.header.nav.support.forEventOwners.text,
      description: i18nKeys.header.nav.support.forEventOwners.description,
      href: RoutePath.DOCUMENTATION,
      roles: [AuthRole.GUEST_USER, AuthRole.JAM_ADMIN, AuthRole.EVENT_SUPPORT, AuthRole.EVENT_PRIZES_ADMIN]
    },
    {
      id: 'nav-support-creators-submenuitem',
      text: i18nKeys.header.nav.support.forCreators.text,
      description: i18nKeys.header.nav.support.forCreators.description,
      href: RoutePath.DOCUMENTATION,
      roles: [AuthRole.JAM_ADMIN, AuthRole.CHALLENGE_SUPPORT, AuthRole.CHALLENGE_REVIEWER, AuthRole.CHALLENGE_BUILDER]
    },
  ],
}
const ADMIN_MENU: INavMenuItemWithRoles = {
  text: i18nKeys.header.nav.admin.text,
  id: NAV_MENU_ITEM_ID.ADMIN_MENU_ID,
  subMenuItems: [
    {
      id: 'nav-admin-lab-dashboard-submenuitem',
      text: i18nKeys.header.nav.admin.labDashboard.text,
      description: i18nKeys.header.nav.admin.labDashboard.description,
      href: RoutePath.LABS,
      roles: [AuthRole.JAM_ADMIN]
    },
    {
      id: 'nav-admin-settings-submenuitem',
      text: i18nKeys.header.nav.admin.settings.text,
      description: i18nKeys.header.nav.admin.settings.description,
      href: RoutePath.SETTINGS,
      roles: [AuthRole.JAM_ADMIN]
    },
    {
      id: 'nav-admin-challenge-libary-submenuitem',
      text: i18nKeys.header.nav.admin.challengeLibary.text,
      description: i18nKeys.header.nav.admin.challengeLibary.description,
      href: RoutePath.CHALLENGES,
      roles: [AuthRole.JAM_ADMIN, AuthRole.CHALLENGE_SUPPORT, AuthRole.CHALLENGE_BUILDER, AuthRole.CHALLENGE_REVIEWER]
    },
    {
      id: 'nav-admin-reports-submenuitem',
      text: i18nKeys.header.nav.admin.reports.text,
      description: i18nKeys.header.nav.admin.reports.description,
      href: RoutePath.REPORTS,
      roles: [AuthRole.JAM_ADMIN]
    },
    {
      id: 'nav-admin-prize-redemption-submenuitem',
      text: i18nKeys.header.nav.admin.prizeRedemption.text,
      description: i18nKeys.header.nav.admin.prizeRedemption.description,
      href: RoutePath.PRIZES,
      roles: [AuthRole.JAM_ADMIN, AuthRole.EVENT_PRIZES_ADMIN]
    },
  ],
}

export const NAV_MENU:INavMenuItemWithRoles[] = [PLAY_MENU, HOST_MENU, CREATE_MENU, SUPPORT_MENU, ADMIN_MENU];
