import React, { useEffect, useState } from 'react';
import { Badge, Icon } from '@amzn/awsui-components-react';
import { Event } from '../../types/Event';
import { useUser } from '../../store/user.context';
import { NullableString } from '../../types/common';
import { Region } from '@amzn/aws-jam-constants';
import { intersection, union } from 'lodash';
import RegionBadge from './RegionBadge';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../utils/i18n.utils';
import { ChallengeDescriptor } from '../../types/Challenge';
import { LabShutoffStatus } from '../../types/LabShutoff';

interface LabShutoffStatusBadgeProps {
  challengeDescriptors?: ChallengeDescriptor[];
  shutoffStatus?: LabShutoffStatus;
  testEventShutoffStatus?: LabShutoffStatus;
  test?: boolean;
  showRegionShutoffs?: boolean;
}
const LabShutoffStatusBadge: React.FC<LabShutoffStatusBadgeProps> = ({
  challengeDescriptors,
  shutoffStatus,
  testEventShutoffStatus,
  test = false,
  showRegionShutoffs = true,
}) => {
  const { user } = useUser();
  const { t } = useTranslation();
  const [updatingState, setUpdatingState] = useState(false);
  const [selfShutoffChallenges, setSelfShutoffChallenges] = useState<NullableString[]>();
  const [applicableRegions, setApplicableRegions] = useState<string[]>([]);
  const [applicableSelfShutoffRegions, setApplicableSelfShutoffRegions] = useState<NullableString[]>();
  const [applicableInheritedShutoffRegions, setApplicableInheritedShutoffRegions] = useState<NullableString[]>();
  const [supportedLabRegions, setSupportedLabRegions] = useState<Region[]>([]);
  const [hasAllApplicablesRegionsShutoff, setHasAllApplicableRegionsShutoff] = useState(false);

  const faPauseSvg = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
      <path
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        d="M144 479H48c-26.5 0-48-21.5-48-48V79c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48zm304-48V79c0-26.5-21.5-48-48-48h-96c-26.5 0-48 21.5-48 48v352c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48z"
      />
    </svg>
  );

  const labStatusShutoffTextDictionary = {
    notShutoff: t(i18nKeys.events.eventDetails.labels.labShutoff.notShutoff),
    selfShutoff: t(i18nKeys.events.eventDetails.labels.labShutoff.selfShutoff),
    inheritedShutoff: t(i18nKeys.events.eventDetails.labels.labShutoff.inheritedShutoff),
    allRegionsShutoff: t(i18nKeys.events.eventDetails.labels.labShutoff.allRegions),
    labProviderShutoff: t(i18nKeys.events.eventDetails.labels.labShutoff.labProvider),
  };

  const labShutoffStatus = test ? testEventShutoffStatus : shutoffStatus;
  const badgePrefix = test ? t(i18nKeys.general.test) : '';

  // *LEGACY LOGIC*
  // Any time the self shutoff/inherited shutoff regions change, the applicable regions list must be updated
  useEffect(() => {
    // set the list of supported lab regions (before updating applicable regions)
    if (!supportedLabRegions || supportedLabRegions.length === 0) {
      // Todo: Implement getChallengeConfig supportedRegions mapping once challenge service is implemented
    }
    updateState();
  }, [supportedLabRegions]);

  const updateState = () => {
    if (updatingState) {
      return;
    }
    setUpdatingState(true);
    // Todo: add linkTo assignment when lab dashboard is implemented
    if (!test) {
      setSelfShutoffChallenges(
        challengeDescriptors?.filter((cd) => cd.shutoffStatus?.selfShutoff).map((cd) => cd?.challengeId)
      );
    } else if (test) {
      setSelfShutoffChallenges(
        challengeDescriptors?.filter((cd) => cd?.testEventShutoffStatus?.selfShutoff).map((cd) => cd?.challengeId)
      );
    }

    updateApplicableRegions();

    setUpdatingState(false);
  };

  const updateApplicableRegions = () => {
    if (!applicableRegions) {
      setApplicableRegions(supportedLabRegions?.map((region) => region?.id));
    }

    // self shutoffs
    setApplicableSelfShutoffRegions(intersection(applicableRegions || [], labShutoffStatus?.selfShutoffRegions || []));

    // inherited shutoffs
    setApplicableInheritedShutoffRegions(
      intersection(applicableRegions || [], labShutoffStatus?.inheritedShutoffRegions || [])
    );

    /**
     * Every region that's shutoff for this component according to its shutoff status,
     * including both self and inherited shutoffs.
     */
    const allShutoffRegions = union(applicableInheritedShutoffRegions, applicableSelfShutoffRegions);

    // hasAllApplicableRegionsShutoff = "every region in applicableRegions is included in allShutoffRegions"
    // (if this component shows region shutoffs only and if this component has at least one shutoff region)
    setHasAllApplicableRegionsShutoff(
      showRegionShutoffs &&
        allShutoffRegions.length > 0 &&
        applicableRegions.every((regionId) => allShutoffRegions.includes(regionId))
    );
  };

  // TODO: Implement Linkable portions after Labs dashboard implementation
  return (
    (user?.isSuperAdmin && !updatingState && (
      <React.Fragment>
        {!labShutoffStatus?.hasCompleteShutoff && (
          <span title={labStatusShutoffTextDictionary.notShutoff}>
            <Badge color="green">
              <Icon name="caret-right-filled" />
              {t(i18nKeys.events.eventDetails.labels.labShutoff.messages.on, { prefix: badgePrefix + ' ' })}
            </Badge>
          </span>
        )}
        {labShutoffStatus?.selfShutoff && (
          <span title={labStatusShutoffTextDictionary.selfShutoff}>
            <Badge color="red">
              <Icon svg={faPauseSvg} />{' '}
              {t(i18nKeys.events.eventDetails.labels.labShutoff.messages.shutoff, { prefix: badgePrefix + ' ' })}
            </Badge>
          </span>
        )}
        {labShutoffStatus?.inheritedShutoff && (
          <span title={labStatusShutoffTextDictionary.inheritedShutoff}>
            <Badge color="red">
              <Icon svg={faPauseSvg} />{' '}
              {t(i18nKeys.events.eventDetails.labels.labShutoff.messages.inherited, { prefix: badgePrefix + ' ' })}
            </Badge>
          </span>
        )}
        {selfShutoffChallenges && selfShutoffChallenges?.length > 0 && (
          <span title={selfShutoffChallenges?.join(', ') || ''}>
            <Badge color="red">
              <Icon svg={faPauseSvg} />
              {selfShutoffChallenges.length === 1
                ? t(i18nKeys.events.eventDetails.labels.labShutoff.messages.shutoffChallenge)
                : t(i18nKeys.events.eventDetails.labels.labShutoff.messages.shutoffChallenges, {
                    count: selfShutoffChallenges.length,
                  })}
            </Badge>
          </span>
        )}
        {showRegionShutoffs && !hasAllApplicablesRegionsShutoff && (
          // Only show the individual region shutoff if not all applicable regions are shutoff; otherwise, show a generic "All Regions" badge
          <React.Fragment>
            <RegionBadge regions={applicableSelfShutoffRegions} pauseSvg={faPauseSvg} selfShutoff />
            <RegionBadge regions={applicableInheritedShutoffRegions} pauseSvg={faPauseSvg} />
          </React.Fragment>
        )}
        {hasAllApplicablesRegionsShutoff && !labShutoffStatus?.hasCompleteShutoff && (
          // Don't show an "All regions" shutoff badge if there's a complete shutoff, as this is redundant.
          <span title={labStatusShutoffTextDictionary.allRegionsShutoff}>
            <Badge color="red">
              <Icon svg={faPauseSvg} /> {t(i18nKeys.events.eventDetails.labels.labShutoff.messages.allRegions)}
            </Badge>
          </span>
        )}
      </React.Fragment>
    )) ||
    null
  );
};

export default LabShutoffStatusBadge;
