import { ExpandableSection, FormField, Grid, Header, Select } from '@amzn/awsui-components-react';
import { BaseChangeDetail } from '@amzn/awsui-components-react/polaris/input/interfaces';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditEventActions, useEditEvent } from '../../../../store/edit-event.context';
import { useUser } from '../../../../store/user.context';
import { Event } from '../../../../types/Event';
import { i18nKeys } from '../../../../utils/i18n.utils';

interface JamUISettingsProps {
  event?: Event;
}

// eslint-disable-next-line no-shadow
enum ChallengeViews {
  MAP = 'map',
  LIST = 'list',
}

// eslint-disable-next-line no-shadow
enum UIThemes {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

const JamUISettings: React.FC<JamUISettingsProps> = ({ event }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { editMode, editedEvent, handleUpdateEditEvent } = useEditEvent();
  const [selectedChallengeViewOption, setSelectedChallengeViewOption] = useState<OptionDefinition | null>(null);
  const [selectedUIThemeOption, setSelectedUIThemeOption] = useState<OptionDefinition | null>(null);

  const mapChallengeViewOption = {
    label: t(i18nKeys.events.eventDetails.labels.jamUserInterface.worldMap),
    value: ChallengeViews.MAP,
  };
  const listChallengeViewOption = {
    label: t(i18nKeys.events.eventDetails.labels.jamUserInterface.listView),
    value: ChallengeViews.LIST,
  };

  const darkUIThemeOption: OptionDefinition = {
    label: t(i18nKeys.events.eventDetails.labels.jamUserInterface.darkTheme),
    value: UIThemes.DARK,
  };

  const lightUIThemeOption: OptionDefinition = {
    label: t(i18nKeys.events.eventDetails.labels.jamUserInterface.lightTheme),
    value: UIThemes.LIGHT,
  };

  const ChallengeViewDictionary = {
    [ChallengeViews.MAP]: mapChallengeViewOption.label,
    [ChallengeViews.LIST]: listChallengeViewOption.label,
  };

  const UIThemeDictionary = {
    [UIThemes.DARK]: darkUIThemeOption.label,
    [UIThemes.LIGHT]: lightUIThemeOption.label,
  };

  const challengeViewOptions = [mapChallengeViewOption, listChallengeViewOption];

  const uiThemeOptions = [darkUIThemeOption, lightUIThemeOption];

  useEffect(() => {
    const selectedChallengeView = challengeViewOptions.find((e) => e.value === editedEvent?.challengeViewType);
    if (selectedChallengeView) {
      setSelectedChallengeViewOption(selectedChallengeView);
    } else {
      setSelectedChallengeViewOption(challengeViewOptions[0]);
    }
    const selectedUITheme = uiThemeOptions.find((e) => e.value === editedEvent?.uiTheme);
    if (selectedUITheme) {
      setSelectedUIThemeOption(selectedUITheme);
    } else {
      setSelectedUIThemeOption(uiThemeOptions[0]);
    }
  }, [editMode]);

  const handleChallengeViewSelection = (option: OptionDefinition) => {
    setSelectedChallengeViewOption(option);
    handleUpdateEditEvent(EditEventActions.CHALLENGE_VIEW, option.value);
  };

  const handleUIThemeSelection = (option: OptionDefinition) => {
    setSelectedUIThemeOption(option);
    handleUpdateEditEvent(EditEventActions.UI_THEME, option.value);
  };

  return (
    <ExpandableSection
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.jamUserInterface)}</Header>}>
      {editMode && user && (
        <React.Fragment>
          <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }]}>
            <FormField
              label={t(i18nKeys.events.eventDetails.labels.jamUserInterface.challengeView)}
              description={t(i18nKeys.events.eventDetails.labels.jamUserInterface.challengeViewDescription)}>
              <Select
                selectedOption={selectedChallengeViewOption}
                options={challengeViewOptions}
                onChange={({ detail }) => handleChallengeViewSelection(detail.selectedOption)}
                disabled={!event?.canEditAttribute('challengeViewType', user)}
              />
            </FormField>
            <FormField
              label={t(i18nKeys.events.eventDetails.labels.jamUserInterface.theme)}
              description={t(i18nKeys.events.eventDetails.labels.jamUserInterface.themeDescription)}>
              <Select
                selectedOption={selectedUIThemeOption}
                options={uiThemeOptions}
                onChange={({ detail }) => handleUIThemeSelection(detail.selectedOption)}
                disabled={!event?.canEditAttribute('uiTheme', user)}
              />
            </FormField>
          </Grid>
        </React.Fragment>
      )}
      {!editMode && (
        <React.Fragment>
          <div className="section-first-row">
            <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
              <div className="secondary-text">
                {t(i18nKeys.events.eventDetails.labels.jamUserInterface.challengeView)}
              </div>
              <div>{ChallengeViewDictionary[event?.challengeViewType as ChallengeViews]}</div>
            </Grid>
          </div>
          <div className="grey-section-divider-top" style={{ paddingBottom: '18px' }}>
            <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
              <div className="secondary-text">{t(i18nKeys.events.eventDetails.labels.jamUserInterface.theme)}</div>
              <div>{UIThemeDictionary[event?.uiTheme as UIThemes]}</div>
            </Grid>
          </div>
        </React.Fragment>
      )}
    </ExpandableSection>
  );
};
export default JamUISettings;
