import { ExpandableSection, Header, TextContent } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TimeAgo from 'timeago-react';
import { useChallenges } from '../../../../store/challenge.context';
import {
  Challenge,
  ChallengeDeploymentStatistics,
  ChallengeGlobalStatistics,
  ChallengeListItem,
} from '../../../../types/Challenge';
import { Nullable } from '../../../../types/common';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { roundFloat } from '../../../../utils/number.utils';
import { safeString } from '../../../../utils/string.utils';
import { getDuration } from '../../../../utils/time.utils';
import CircleRating from '../../../common/CircleRating';
import { Column } from '../../../common/Column';
import { Columns } from '../../../common/Columns';
import { KeyValue } from '../../../common/KeyValue';
import StarRating from '../../../common/StarRating';

interface ChallengeStatisticSummaryDetailProps {
  challenge: Challenge;
  challengeDeploymentStatistics?: ChallengeDeploymentStatistics;
}

const ChallengeStatisticSummarysDetail: React.FC<ChallengeStatisticSummaryDetailProps> = ({
  challenge,
  challengeDeploymentStatistics,
}) => {
  const { t } = useTranslation();
  const { getChallengeListItemFromChallengeId } = useChallenges();

  const [globalStats, setGlobalStats] = useState<ChallengeGlobalStatistics>();

  useEffect(() => {
    const challengeListItem: Nullable<ChallengeListItem> = getChallengeListItemFromChallengeId(
      safeString(challenge.challengeId)
    );

    if (challengeListItem) {
      setGlobalStats(challengeListItem.globalStatistics);
    }
  });

  return (
    <ExpandableSection
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.challenges.challengeDetails.headings.statisticsSummary)}</Header>}>
      <TextContent>
        <Columns columns={4} variant="default">
          <Column size="s">
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.rating)}>
              {globalStats && <StarRating value={globalStats.rating} valueCount={globalStats.ratingCount} />}
            </KeyValue>
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.difficultyRating)}>
              {globalStats && <CircleRating value={globalStats.difficultyRating} />}
            </KeyValue>
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.stackDeployTime)}>
              <React.Fragment>
                <div>
                  <strong style={{ marginRight: '0.5rem' }}>Avg:</strong>
                  {challenge.avgStackDeployTime && challenge.avgStackDeployTime > 0
                    ? challenge.avgStackDeployTime
                    : '--'}
                </div>
                <div>
                  <strong style={{ marginRight: '0.5rem' }}>Most Recent:</strong>
                  {challenge.avgDeployTimesLastUpdated ? challenge.avgDeployTimesLastUpdated : '--'}
                </div>
              </React.Fragment>
            </KeyValue>
          </Column>
          <Column size="s">
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.labVendTime)}>
              <div>
                <strong style={{ marginRight: '0.5rem' }}>Avg:</strong>
                {challenge.avgDeployResolveTime && challenge.avgDeployResolveTime > 0
                  ? challenge.avgDeployResolveTime
                  : '--'}
              </div>
              <div>
                <strong style={{ marginRight: '0.5rem' }}>Most Recent:</strong>
                {challenge.lastSuccessfulDeployment.deploymentResolutionDuration
                  ? challenge.lastSuccessfulDeployment.deploymentResolutionDuration
                  : '--'}
              </div>
            </KeyValue>
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.numbersOfJamUsed)}>
              {globalStats?.jamsUsed}
            </KeyValue>
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.firstUsed)}>
              {globalStats && globalStats.firstUsed ? <TimeAgo datetime={globalStats.firstUsed} /> : '--'}
            </KeyValue>
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.lastUsed)}>
              {globalStats && globalStats.lastUsed ? <TimeAgo datetime={globalStats.lastUsed} /> : '--'}
            </KeyValue>
          </Column>
          <Column size="s">
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.completedTasks)}>
              {globalStats?.totalCompletedTasks}
            </KeyValue>
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.incorrectAnswers)}>
              {globalStats?.totalIncorrect}
            </KeyValue>
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.solveRate)}>
              {globalStats ? `${roundFloat(globalStats.passRate * 100 || 0, 0)}%` : '--'}
            </KeyValue>
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.averageSolveTime)}>
              <div>
                <strong style={{ marginRight: '0.5rem' }}>Avg:</strong>
                {globalStats && globalStats.solveTimes.trimmedAvgSeconds
                  ? getDuration(globalStats.solveTimes.trimmedAvgSeconds)
                  : '--'}
              </div>
              <div>
                <strong style={{ marginRight: '0.5rem' }}>Min:</strong>
                {globalStats && globalStats.solveTimes.lowestSolveTime
                  ? getDuration(globalStats.solveTimes.lowestSolveTime.numSeconds)
                  : '--'}
              </div>
              <div>
                <strong style={{ marginRight: '0.5rem' }}>Max:</strong>
                {globalStats && globalStats.solveTimes.highestSolveTime
                  ? getDuration(globalStats.solveTimes.highestSolveTime.numSeconds)
                  : '--'}
              </div>
            </KeyValue>
          </Column>
          <Column size="s">
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.cluesRequested)}>
              {globalStats?.totalRequestedClues}
            </KeyValue>
            <KeyValue
              className="primary-text"
              label={t(i18nKeys.challenges.challengeDetails.titles.cluePerCompletedTask)}>
              {globalStats && globalStats.totalCompletedTasks > 0 ? (
                roundFloat((globalStats.totalRequestedClues || 0) / globalStats.totalCompletedTasks, 2).toFixed(2)
              ) : (
                <div>--</div>
              )}
            </KeyValue>
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.avgCostPerHour)}>
              {globalStats && `$${roundFloat(globalStats.averageCostPerHour || 0, 4).toFixed(4)}`}
            </KeyValue>
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.stability)}>
              {challengeDeploymentStatistics ? `${challengeDeploymentStatistics.stability}%` : '--'}
            </KeyValue>
          </Column>
        </Columns>
      </TextContent>
    </ExpandableSection>
  );
};

export default ChallengeStatisticSummarysDetail;
