import { CollectionPreferencesProps, Link } from '@amzn/awsui-components-react';
import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from '../../../../../types/common';
import { TeamMetricsRow } from '../../../../../types/DetailedEventStatisticsReport';
import { i18nKeys } from '../../../../../utils/i18n.utils';
import { getTableFilterMatcher, TableFilterMatcher } from '../../../../../utils/table.utils';
import { getDuration } from '../../../../../utils/time.utils';

export const filteringFunction = (item: TeamMetricsRow, filteringText: string): boolean => {
  const matcher: TableFilterMatcher = getTableFilterMatcher(filteringText);
  return (
    matcher.filterEmpty ||
    matcher.isMatch(item.teamName) ||
    matcher.isMatch(item.numberChallengesSolved.toString()) ||
    matcher.isMatch(item.timeFromFirstStartedChallengeToLastCompletionTime?.toString() as string) ||
    matcher.isMatch(item.startedChallenges.toString()) ||
    matcher.isMatch(item.notStartedChallenges.toString()) ||
    matcher.isMatch(item.finalScore.toString()) ||
    matcher.isMatch(item.finalRank.toString())
  );
};

export const COLUMN_DEFINITIONS = (
  preferences: CollectionPreferencesProps.Preferences,
  selectTeam: Dispatch<SetStateAction<Nullable<TeamMetricsRow>>>
) => {
  const { t } = useTranslation();
  return [
    {
      id: 'team-name',
      sortingField: 'teamName',
      header: t(i18nKeys.report.headers.teamTable.teamName),
      cell: (item: TeamMetricsRow) => <Link onFollow={() => selectTeam(item)}>{item?.teamName}</Link>,
      minWidth: 260,
      allowLineWrap: true,
    },
    {
      id: 'number-challenges-solved',
      sortingField: 'numberChallengesSolved',
      header: t(i18nKeys.report.headers.teamTable.challengesSolved),
      cell: (item: TeamMetricsRow) => <div>{item.numberChallengesSolved}</div>,
      allowLineWrap: true,
    },
    {
      id: 'total-time',
      sortingField: 'timeFromFirstStartedChallengeToLastCompletionTime',
      header: t(i18nKeys.report.headers.teamTable.totalTime),
      cell: (item: TeamMetricsRow) => <div>{getDuration(item.timeFromFirstStartedChallengeToLastCompletionTime)}</div>,
      allowLineWrap: true,
    },
    {
      id: 'started',
      sortingField: 'startedChallenges',
      header: t(i18nKeys.report.headers.teamTable.started),
      cell: (item: TeamMetricsRow) => <div>{item.startedChallenges.length}</div>,
      allowLineWrap: true,
    },
    {
      id: 'unstarted',
      sortingField: 'notStartedChallenges',
      header: t(i18nKeys.report.headers.teamTable.unstarted),
      cell: (item: TeamMetricsRow) => <div>{item.notStartedChallenges.length}</div>,
      allowLineWrap: true,
    },
    {
      id: 'score',
      sortingField: 'finalScore',
      header: t(i18nKeys.report.headers.teamTable.score),
      cell: (item: TeamMetricsRow) => <div>{item.finalScore}</div>,
      allowLineWrap: true,
    },

    {
      id: 'rank',
      sortingField: 'finalRank',
      header: t(i18nKeys.report.headers.teamTable.rank),
      cell: (item: TeamMetricsRow) => <div>{item.finalRank}</div>,
      allowLineWrap: true,
    },
  ];
};
