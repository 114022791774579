import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { RequestUnapprovedStatus } from '../../../../types/common';
import { EventStatus } from '../../../../types/Event';
import { getDaysHoursMinutes } from '../../../../utils/event-time.utils';
import { i18nKeys } from '../../../../utils/i18n.utils';
import './LabStatusCountdown.scss';

interface LabStatusCountdownProps {
  includeSeconds: boolean;
  isTest: boolean;
  status: any;
  labStartTime: string;
  labEndTime: string;
  inline?: boolean;
}

/**
 * Checks if dateString supplied is before current time
 *
 * @param dateString Date to check
 * @returns boolean depicting whether date supplied is before current time
 */
const isBefore = (dateString: string): boolean => {
  return moment().isBefore(moment(dateString));
};

/**
 * Calculates and returns countdown string till date supplied
 *
 * @param dateString Datestring to calculate countdown till
 * @param includeSeconds boolean to configure option to show seconds
 * @returns Countdown string
 */
const getCountdown = (dateString: string, includeSeconds: boolean): string => {
  const millis = moment(dateString).valueOf();
  const millisUntil = Math.max(0, millis - Date.now());
  return getDaysHoursMinutes(millisUntil, includeSeconds);
};

const isCancelled = (status: any) => {
  return status === EventStatus.CANCELLED || status === RequestUnapprovedStatus.REQUEST_CANCELLED;
};

/**
 * Displays current status of labs and countdown till they're active when applicable
 *
 * @param param0 Event parameters to calculate time until labs active and what message to display
 * @returns Countdown till labs active with trailing message and icon
 */
const LabStatusCountdown: React.FC<LabStatusCountdownProps> = ({
  includeSeconds,
  isTest,
  status,
  labStartTime,
  labEndTime,
  inline,
}: LabStatusCountdownProps) => {
  const { t } = useTranslation();
  if (!isCancelled(status)) {
    if (isBefore(labStartTime)) {
      const countdown = getCountdown(labStartTime, includeSeconds);
      return (
        <div>
          <strong>{countdown}</strong>
          {isTest ? t(i18nKeys.labs.until.test) : t(i18nKeys.labs.until.live)}
        </div>
      );
    } else if (isBefore(labEndTime)) {
      return (
        <div style={{ display: inline ? 'inline-block' : 'block' }}>
          <div className="dot" style={{ backgroundColor: isTest ? 'blue' : 'green' }}>
            {' '}
          </div>
          {isTest ? t(i18nKeys.labs.active.test) : t(i18nKeys.labs.active.live)}
        </div>
      );
    }
    return <div>{isTest ? t(i18nKeys.labs.terminated.test) : t(i18nKeys.labs.terminated.live)}</div>;
  } else {
    return <span />;
  }
};

export default LabStatusCountdown;
