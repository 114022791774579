import { Link, TutorialPanelProps } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { i18nKeys } from '../../utils/i18n.utils';
import { ChallengeHotspot } from '../challenges/challengesCommon/ChallengeHotspots';
import { TFunctionProvider } from '../common/TFunctionProvider';
import { ChallengeTutorialInfoTopic } from '../../store/create-challenge.context';

// TODO: Add translations for every title/description field. https://sim.amazon.com/issues/JAM-7437
const challengeTutorialData: (
  helpPanelHandler: (activeTopic: ChallengeTutorialInfoTopic) => () => void
) => TutorialPanelProps.Tutorial[] = (helpPanelHandler) => [
  {
    id: ChallengeTutorialType.CREATE_CHALLENGE,
    // Any elements that are strings rather than ReactNodes should be stored as the i18n key,
    // and translated at render time. This is because of a quirk of React, where we cannot call the translation function
    // outside of a component. For ReactNodes, we can use the TFunctionProvider component.
    title: i18nKeys.tutorial.createChallenge.title,
    description: (
      <>
      <TFunctionProvider>
        {(t) => t(i18nKeys.tutorial.createChallenge.description)}
      </TFunctionProvider>
      </>
    ),
    tasks: [
      {
        title: i18nKeys.tutorial.createChallenge.tasks.task1.title,
        steps: [
          {
            title: i18nKeys.tutorial.createChallenge.tasks.task1.steps.step1.title,
            content: (
              <>
                <TFunctionProvider>
                  {(t) => t(i18nKeys.tutorial.createChallenge.tasks.task1.steps.step1.content)}
                </TFunctionProvider>{' '}
                <Link variant="info" onFollow={helpPanelHandler(ChallengeHotspot.challengeId)}>
                  <TFunctionProvider>
                    {(t) => t(i18nKeys.tutorial.createChallenge.tasks.task1.steps.step1.linkLabel)}
                  </TFunctionProvider>
                </Link>
              </>
            ),
            hotspotId: ChallengeHotspot.challengeId,
          },
          {
            title: i18nKeys.tutorial.createChallenge.tasks.task1.steps.step2.title,
            content: (
              <>
                <TFunctionProvider>
                  {(t) => t(i18nKeys.tutorial.createChallenge.tasks.task1.steps.step2.content)}
                </TFunctionProvider>{' '}
                <Link variant="info" onFollow={helpPanelHandler(ChallengeHotspot.challengeTitle)}>
                  <TFunctionProvider>
                    {(t) => t(i18nKeys.tutorial.createChallenge.tasks.task1.steps.step2.linkLabel)}
                  </TFunctionProvider>
                </Link>
              </>
            ),
            hotspotId: ChallengeHotspot.challengeTitle,
          },
          {
            title: i18nKeys.tutorial.createChallenge.tasks.task1.steps.step3.title,
            content: (
              <>
                <TFunctionProvider>
                  {(t) => t(i18nKeys.tutorial.createChallenge.tasks.task1.steps.step3.content)}
                </TFunctionProvider>{' '}
                <Link variant="info" onFollow={helpPanelHandler(ChallengeHotspot.challengeDescription)}>
                  <TFunctionProvider>
                    {(t) => t(i18nKeys.tutorial.createChallenge.tasks.task1.steps.step3.linkLabel)}
                  </TFunctionProvider>
                </Link>
              </>
            ),
            hotspotId: ChallengeHotspot.challengeDescription,
          },
        ],
      },
      {
        title: i18nKeys.tutorial.createChallenge.tasks.task2.title,
        steps: [
          {
            title: i18nKeys.tutorial.createChallenge.tasks.task2.steps.step1.title,
            content: (
              <>
                <TFunctionProvider>
                  {(t) => t(i18nKeys.tutorial.createChallenge.tasks.task2.steps.step1.content)}
                </TFunctionProvider>{' '}
                <Link variant="info" onFollow={helpPanelHandler(ChallengeHotspot.learningOutcomeSummary)}>
                  <TFunctionProvider>
                    {(t) => t(i18nKeys.tutorial.createChallenge.tasks.task2.steps.step1.linkLabel)}
                  </TFunctionProvider>
                </Link>
              </>
            ),
            hotspotId: ChallengeHotspot.learningOutcomeSummary,
          },
          {
            title: i18nKeys.tutorial.createChallenge.tasks.task2.steps.step2.title,
            content: (
              <>
                <TFunctionProvider>
                  {(t) => t(i18nKeys.tutorial.createChallenge.tasks.task2.steps.step2.content)}
                </TFunctionProvider>{' '}
                <Link variant="info" onFollow={helpPanelHandler(ChallengeHotspot.learningOutcomeIntroduction)}>
                  <TFunctionProvider>
                    {(t) => t(i18nKeys.tutorial.createChallenge.tasks.task2.steps.step2.linkLabel)}
                  </TFunctionProvider>
                </Link>
              </>
            ),
            hotspotId: ChallengeHotspot.learningOutcomeIntroduction,
          },
          {
            title: i18nKeys.tutorial.createChallenge.tasks.task2.steps.step3.title,
            content: (
              <>
                <TFunctionProvider>
                  {(t) => t(i18nKeys.tutorial.createChallenge.tasks.task2.steps.step3.content)}
                </TFunctionProvider>{' '}
                <Link variant="info" onFollow={helpPanelHandler(ChallengeHotspot.learningOutcomeTopicsCovered)}>
                  <TFunctionProvider>
                    {(t) => t(i18nKeys.tutorial.createChallenge.tasks.task2.steps.step3.linkLabel)}
                  </TFunctionProvider>
                </Link>
              </>
            ),
            hotspotId: ChallengeHotspot.learningOutcomeTopicsCovered,
          },
          {
            title: i18nKeys.tutorial.createChallenge.tasks.task2.steps.step4.title,
            content: (
              <>
                <TFunctionProvider>
                  {(t) => t(i18nKeys.tutorial.createChallenge.tasks.task2.steps.step4.content)}
                </TFunctionProvider>{' '}
                <Link
                  variant="info"
                  onFollow={helpPanelHandler(ChallengeHotspot.learningOutcomeTechnicalKnowledgePrerequisites)}>
                  <TFunctionProvider>
                    {(t) => t(i18nKeys.tutorial.createChallenge.tasks.task2.steps.step4.linkLabel)}
                  </TFunctionProvider>
                </Link>
              </>
            ),
            hotspotId: ChallengeHotspot.learningOutcomeTechnicalKnowledgePrerequisites,
          },
        ],
      },
      {
        title: i18nKeys.tutorial.createChallenge.tasks.task3.title,
        steps: [
          {
            title: i18nKeys.tutorial.createChallenge.tasks.task3.steps.step1.title,
            content: (
              <>
                <TFunctionProvider>
                  {(t) => t(i18nKeys.tutorial.createChallenge.tasks.task3.steps.step1.content)}
                </TFunctionProvider>{' '}
                <Link variant="info" onFollow={helpPanelHandler(ChallengeHotspot.basicSettingsType)}>
                  <TFunctionProvider>
                    {(t) => t(i18nKeys.tutorial.createChallenge.tasks.task3.steps.step1.linkLabel)}
                  </TFunctionProvider>
                </Link>
              </>
            ),
            hotspotId: ChallengeHotspot.basicSettingsType,
          },
          {
            title: i18nKeys.tutorial.createChallenge.tasks.task3.steps.step2.title,
            content: (
              <>
                <TFunctionProvider>
                  {(t) => t(i18nKeys.tutorial.createChallenge.tasks.task3.steps.step2.content)}
                </TFunctionProvider>{' '}
                <Link variant="info" onFollow={helpPanelHandler(ChallengeHotspot.basicSettingsCategory)}>
                  <TFunctionProvider>
                    {(t) => t(i18nKeys.tutorial.createChallenge.tasks.task3.steps.step2.linkLabel)}
                  </TFunctionProvider>
                </Link>
              </>
            ),
            hotspotId: ChallengeHotspot.basicSettingsCategory,
          },
          {
            title: i18nKeys.tutorial.createChallenge.tasks.task3.steps.step3.title,
            content: (
              <>
                <TFunctionProvider>
                  {(t) => t(i18nKeys.tutorial.createChallenge.tasks.task3.steps.step3.content)}
                </TFunctionProvider>{' '}
                <Link variant="info" onFollow={helpPanelHandler(ChallengeHotspot.basicSettingsDifficulty)}>
                  <TFunctionProvider>
                    {(t) => t(i18nKeys.tutorial.createChallenge.tasks.task3.steps.step3.linkLabel)}
                  </TFunctionProvider>
                </Link>
              </>
            ),
            hotspotId: ChallengeHotspot.basicSettingsDifficulty,
          },
          {
            title: i18nKeys.tutorial.createChallenge.tasks.task3.steps.step4.title,
            content: (
              <>
                <TFunctionProvider>
                  {(t) => t(i18nKeys.tutorial.createChallenge.tasks.task3.steps.step4.content)}
                </TFunctionProvider>{' '}
                <Link variant="info" onFollow={helpPanelHandler(ChallengeHotspot.awsSettingsServices)}>
                  <TFunctionProvider>
                    {(t) => t(i18nKeys.tutorial.createChallenge.tasks.task3.steps.step4.linkLabel)}
                  </TFunctionProvider>
                </Link>
              </>
            ),
            hotspotId: ChallengeHotspot.awsSettingsServices,
          },
          {
            title: i18nKeys.tutorial.createChallenge.tasks.task3.steps.step5.title,
            content: (
              <>
                <TFunctionProvider>
                  {(t) => t(i18nKeys.tutorial.createChallenge.tasks.task3.steps.step5.content)}
                </TFunctionProvider>{' '}
                <Link variant="info" onFollow={helpPanelHandler(ChallengeHotspot.awsSettingsRegions)}>
                  <TFunctionProvider>
                    {(t) => t(i18nKeys.tutorial.createChallenge.tasks.task3.steps.step5.linkLabel)}
                  </TFunctionProvider>
                </Link>
              </>
            ),
            hotspotId: ChallengeHotspot.awsSettingsRegions,
          },
        ],
      },
    ],
    completedScreenDescription: (
      <>
        <TFunctionProvider>{(t) => t(i18nKeys.tutorial.createChallenge.completedScreenDescription)}</TFunctionProvider>
      </>
    ),
    completed: false,
  },
];

export const useAvailableTutorials = (helpPanelHandler: (topic: ChallengeTutorialInfoTopic) => () => void) => {
  const [tutorials, setTutorials] = useState(() =>
    challengeTutorialData(helpPanelHandler).map((tutorial) => ({
      ...tutorial,
      completed: false,
    }))
  );

  const setTutorialCompleted = useCallback((tutorial: TutorialPanelProps.Tutorial, completed: boolean) => {
    setTutorials((prevTutorials) => {
      return prevTutorials.map((t: TutorialPanelProps.Tutorial) => {
        if ((t as any).id === (tutorial as any).id) {
          return { ...t, completed };
        }
        return t;
      });
    });
  }, []);

  return { tutorials, setTutorialCompleted };
};

// eslint-disable-next-line no-shadow
export enum ChallengeTutorialType {
  CREATE_CHALLENGE,
}
