import * as React from 'react';
import BrowseChallenges from '../common/Challenges/BrowseChallenges';
import { useToolPanel } from '../../store/tool-panel.context';
import { Button, SpaceBetween } from '@amzn/awsui-components-react';
import { i18nKeys } from '../../utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink } from 'react-router-dom';
import { RoutePath } from '../../RoutePath';

const ChallengeList: React.FC = () => {
  const { t } = useTranslation();
  const { toggleChallengeInfo } = useToolPanel();

  return (
    <BrowseChallenges
      currentChallengeDescriptors={[]}
      toggleChallengeInfo={toggleChallengeInfo}
      editMode={false}
      actionButtons={
        <>
          <SpaceBetween direction="horizontal" size="xs">
            <ReactRouterLink to={RoutePath.CHALLENGE_SETS}>
              <Button variant="normal">{t(i18nKeys.challenges.challengeSets)}</Button>
            </ReactRouterLink>
            <ReactRouterLink to={RoutePath.CHALLENGE_SNIPPETS}>
              <Button variant="normal">{t(i18nKeys.challenges.challengeSnippets)}</Button>
            </ReactRouterLink>
            <ReactRouterLink to={RoutePath.GAME_BOARDS}>
              <Button variant="normal">{t(i18nKeys.challenges.gameBoards)}</Button>
            </ReactRouterLink>
            <ReactRouterLink to={RoutePath.NEW_CHALLENGE}>
              <Button variant="primary">{t(i18nKeys.challenges.createChallenge)}</Button>
            </ReactRouterLink>
          </SpaceBetween>
        </>
      }
    />
  );
};

export default ChallengeList;
