import * as awsJamConstants from '@amzn/aws-jam-constants';
import { AwsRegions, AwsServices } from '@amzn/aws-jam-constants';
import { i18nKeys } from '../../../utils/i18n.utils';
import { LabProvider } from '../../../types/LabProvider';
import { OptionDefinition } from '@amzn/awsui-components-react/uxdg/internal/components/option/interfaces';
import { ChallengeDifficulty } from '../../../types/Challenge';

export enum SettingType {
  SECURITY = 'Security',
  ANALYTICS = 'Analytics',
  ALL_IN = 'All In',
  CTF = 'ctf',
}

export const SettingTypeDefinitions: OptionDefinition[] = [
  { label: i18nKeys.challenge.optionDefinitions.settingType.security, value: SettingType.SECURITY },
  { label: i18nKeys.challenge.optionDefinitions.settingType.analytics, value: SettingType.ANALYTICS },
  { label: i18nKeys.challenge.optionDefinitions.settingType.allIn, value: SettingType.ALL_IN },
  { label: i18nKeys.challenge.optionDefinitions.settingType.ctf, value: SettingType.CTF },
];

export const DifficultyDefinitions: OptionDefinition[] = [
  { label: ChallengeDifficulty.FUNDAMENTAL.i18nKeyLong, value: ChallengeDifficulty.FUNDAMENTAL.key.toString() },
  { label: ChallengeDifficulty.INTERMEDIATE.i18nKeyLong, value: ChallengeDifficulty.INTERMEDIATE.key.toString() },
  { label: ChallengeDifficulty.ADVANCED.i18nKeyLong, value: ChallengeDifficulty.ADVANCED.key.toString() },
  { label: ChallengeDifficulty.EXPERT.i18nKeyLong, value: ChallengeDifficulty.EXPERT.key.toString() },
];

export const ModeDefinitions: OptionDefinition[] = [
  {
    label: i18nKeys.challenge.optionDefinitions.modeType.traditional,
    value: awsJamConstants.ChallengeModes.TRADITIONAL,
  },
  {
    label: i18nKeys.challenge.optionDefinitions.modeType.castleDefense,
    value: awsJamConstants.ChallengeModes.CASTLE_DEFENSE,
  },
];

export const LabProviderDefinitions: OptionDefinition[] = [
  {
    label: i18nKeys.challenge.optionDefinitions.labProviderType.awsLabs,
    value: LabProvider.AWS_LABS,
  },
];

export const AWSServicesDefinitions: OptionDefinition[] = [];

export const SupportedRegionsDefinitions: OptionDefinition[] = [];

export const SupportedRegionsDefinitionsWithID: OptionDefinition[] = [];

const setAWSServiceDefinitions = () => {
  AwsServices.list.forEach((value) => AWSServicesDefinitions.push({ label: value, value }));
};

const setSupportedRegionsDefinitions = () => {
  AwsRegions.ALL_AWS_REGIONS.forEach((region) =>
    SupportedRegionsDefinitions.push({ label: region.name, value: region.id })
  );
};

const setSupportedRegionsDefinitionsWithID = () => {
  AwsRegions.ALL_AWS_REGIONS.forEach((region) =>
    SupportedRegionsDefinitionsWithID.push({ label: `${region.name} - ${region.id}`, value: region.id })
  );
};

const initDefinitions = () => {
  setAWSServiceDefinitions();
  setSupportedRegionsDefinitions();
  setSupportedRegionsDefinitionsWithID();
};

initDefinitions();
