import { Container, FormField, Header, Hotspot, Input, SpaceBetween, Textarea } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChallengeOverviewFields,
  ChallengePropAction,
  CHALLENGE_ID_MAX_LENGTH,
  CHALLENGE_ID_MIN_LENGTH,
  DESCRIPTION_MAX_LENGTH,
  DESCRIPTION_MIN_LENGTH,
  TITLE_MAX_LENGTH,
  TITLE_MIN_LENGTH,
  useCreateChallenge,
} from '../../../store/create-challenge.context';
import { i18nKeys } from '../../../utils/i18n.utils';
import { ChallengeHotspot } from '../challengesCommon/ChallengeHotspots';

export interface ChallengeOverviewProps {
  validationHandler: (validateSection: () => Promise<boolean>) => void;
}

const ChallengeOverviewSection: React.FC<ChallengeOverviewProps> = ({ validationHandler }) => {
  const { t } = useTranslation();
  const { editedChallenge, handleUpdateChallengeId, handleUpdateChallengeProp, challengeOverviewValidator } =
    useCreateChallenge();
  const [challengeId, setChallengeId] = useState<string>(editedChallenge?.challengeId || '');
  const [title, setTitle] = useState<string>(editedChallenge?.props.title || '');
  const [description, setDescription] = useState<string>(editedChallenge?.props.description || '');

  // error texts and validation logic
  const [challengeIdErrorText, setChallengeIdErrorText] = useState('');
  const [titleErrorText, setTitleErrorText] = useState('');
  const [descriptionErrorText, setDescriptionErrorText] = useState('');

  const validator = challengeOverviewValidator(
    new Map<ChallengeOverviewFields, (error: string) => void>([
      [ChallengeOverviewFields.CHALLENGE_ID, (error: string) => setChallengeIdErrorText(error)],
      [ChallengeOverviewFields.TITLE, (error: string) => setTitleErrorText(error)],
      [ChallengeOverviewFields.DESCRIPTION, (error: string) => setDescriptionErrorText(error)],
    ])
  );

  validationHandler(() => {
    return Promise.resolve(validator.isValidSection(true));
  });

  const handleChallengeId = (changedId: string) => {
    handleUpdateChallengeId(changedId);
    setChallengeId(changedId);
  };

  const handleChallengeTitle = (changedTitle: string) => {
    handleUpdateChallengeProp(ChallengePropAction.TITLE, changedTitle);
    setTitle(changedTitle);
  };

  const handleChallengeDescription = (changedDescription: string) => {
    handleUpdateChallengeProp(ChallengePropAction.DESCRIPTION, changedDescription);
    setDescription(changedDescription);
  };

  return (
    <Container header={<Header variant={'h2'}> {t(i18nKeys.challenges.challengeDetails.titles.summary)} </Header>}>
      <SpaceBetween direction="vertical" size="l">
        <FormField
          label={`${t(i18nKeys.challenges.fields.challengeId.title)}*`}
          errorText={challengeIdErrorText}
          constraintText={t(i18nKeys.challenges.subSections.overview.fields.challengeId.constraint, {
            CHALLENGE_ID_MIN_LENGTH,
            CHALLENGE_ID_MAX_LENGTH,
          })}>
          <Hotspot direction="right" hotspotId={ChallengeHotspot.challengeId}>
            <Input
              type="text"
              value={challengeId || ''}
              onChange={({ detail }) => handleChallengeId(detail.value)}
              placeholder={t(i18nKeys.challenges.fields.challengeId.placeholder)}
              onBlur={() => validator.isValidField(ChallengeOverviewFields.CHALLENGE_ID)}
            />
          </Hotspot>
        </FormField>
        <FormField
          label={t(i18nKeys.challenges.fields.title.title) + '*'}
          errorText={titleErrorText}
          constraintText={t(i18nKeys.challenges.subSections.overview.fields.title.constraint, {
            TITLE_MIN_LENGTH,
            TITLE_MAX_LENGTH,
          })}>
          <Hotspot direction="right" hotspotId={ChallengeHotspot.challengeTitle}>
            <Input
              type="text"
              value={title || ''}
              onChange={({ detail }) => handleChallengeTitle(detail.value)}
              placeholder={t(i18nKeys.challenges.fields.title.placeholder)}
              onBlur={() => validator.isValidField(ChallengeOverviewFields.TITLE)}
            />
          </Hotspot>
        </FormField>
        <FormField
          label={t(i18nKeys.challenges.fields.description.title) + '*'}
          errorText={descriptionErrorText}
          constraintText={t(i18nKeys.challenges.subSections.overview.fields.description.constraint, {
            DESCRIPTION_MIN_LENGTH,
            DESCRIPTION_MAX_LENGTH,
          })}>
          <Hotspot direction="right" hotspotId={ChallengeHotspot.challengeDescription}>
            <Textarea
              onChange={({ detail }) => handleChallengeDescription(detail.value || '')}
              value={description || ''}
              placeholder={t(i18nKeys.challenges.fields.description.placeholder)}
              onBlur={() => validator.isValidField(ChallengeOverviewFields.DESCRIPTION)}
            />
          </Hotspot>
        </FormField>
      </SpaceBetween>
    </Container>
  );
};

export default ChallengeOverviewSection;
