import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ButtonDropdown, Modal, SelectProps, Toggle } from '@amzn/awsui-components-react';

// constants
import { useUser } from '@/src/store/user.context';
import { useAuth } from '@/src/store/auth.context';

// utils
import { preProdLogger } from '@/src/utils/log.utils';
import { getLanguageCodeSafe, getLocaleLabel, LANGUAGE_SELECT_OPTIONS } from '@/src/utils/locale.utils';
import { i18nKeys } from '@/src/utils/i18n.utils';

import { DARK_MODE_CLASS, DARK_MODE_KEY } from '@/src/constants/common';

import './Header.scss';


export const Header: React.FC = () => {
  const { i18n, t } = useTranslation();
  const { user } = useUser();
  const { authClient } = useAuth();
  const history = useHistory();
  const languageCode: string = getLanguageCodeSafe(i18n.language);
  const languageLabel = getLocaleLabel(languageCode);
  const [languageSelectionModalVisible, setLanguageSelectionModalVisible] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languageCode);
  const [darkMode, setDarkMode] = useState(false);

  const handleAccountDropdown = (e: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const id: string = e.detail.id as string;
    switch (id) {
      case 'account':
        history.push('/' + id);
        break;
      case 'signOut':
        authClient
          .signOut()
          .then(() => preProdLogger('sign out'))
          .catch((err) => preProdLogger(err));
        break;
    }
  };

  const updateSelectedLanguage = () => {
    setLanguageSelectionModalVisible(false);
    void i18n.changeLanguage(selectedLanguage);
  };

  const buildDropdown = (): { id: string; text: string }[] => {
    const items: { id: string; text: string }[] = [
      {
        id: 'account',
        text: t(i18nKeys.account.title).toString(),
      },
      {
        id: 'signOut',
        text: t(i18nKeys.account.signOut.button).toString(),
      },
    ];
    return items;
  };

  const handleToggleDarkMode = () => {
    if (!darkMode) {
      document.body.classList.toggle(DARK_MODE_CLASS);
      localStorage.setItem(DARK_MODE_KEY, 'yes');
    } else {
      document.body.classList.remove(DARK_MODE_CLASS);
      localStorage.removeItem(DARK_MODE_KEY);
    }
    setDarkMode(!darkMode);
  };

  const checkLocalStorageForDarkMode = () => {
    const prefersDarkMode = localStorage.getItem(DARK_MODE_KEY);
    if (prefersDarkMode) {
      handleToggleDarkMode();
    }
  };

  useEffect(() => {
    checkLocalStorageForDarkMode();
  }, []);

  return (
    <header
      id="header"
      className="website-navigation-top"
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 801,
        padding: '0rem 2rem'
      }}>
      <div className={'awsui-util-action-stripe-large'}>
        <div className="awsui-util-action-stripe-title">
          <span style={{ paddingTop: 10, paddingBottom: 3, marginRight: 10 }}>
            <span className={'aws-logo'} />
          </span>
          <h1 className={'title'}>{t('home.title')}</h1>
          <div className={'header-right-content'}>
            <Toggle checked={darkMode} onChange={() => handleToggleDarkMode()}>
              <strong className="dark-background-label">{t(i18nKeys.general.darkMode)}</strong>
            </Toggle>
            {user && (
              <>
                <ButtonDropdown className={'user-dropdown'} items={buildDropdown()} onItemClick={handleAccountDropdown}>
                  {user && <strong>{user.name}</strong>}
                </ButtonDropdown>
              </>
            )}
            <button className={'header-button'} onClick={() => setLanguageSelectionModalVisible(true)}>
              {languageLabel}
            </button>
          </div>
        </div>
      </div>

      <Modal
        visible={languageSelectionModalVisible}
        header={t(i18nKeys.general.selectLanguage)}
        onDismiss={() => setLanguageSelectionModalVisible(false)}
        footer={
          <span className="awsui-util-f-r">
            <Button variant="link" onClick={() => setLanguageSelectionModalVisible(false)}>
              {t(i18nKeys.general.cancel)}
            </Button>
            <Button variant="primary" onClick={() => updateSelectedLanguage()}>
              {t(i18nKeys.general.confirm)}
            </Button>
          </span>
        }>
        <div className={'header-modal-content'}>
          {LANGUAGE_SELECT_OPTIONS.map((option: SelectProps.Option, key: number) => (
            <Button
              key={key}
              className={'language-btn'}
              variant={option.value === selectedLanguage ? 'primary' : 'normal'}
              onClick={() => setSelectedLanguage(option.value || 'en')}
              ariaLabel={option.label}>
              {option.label}
            </Button>
          ))}
        </div>
      </Modal>
    </header>
  );
};
