import { Button, Container, FormField, Header, Input, SpaceBetween, Textarea } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChallengeLearningOutcomeFields,
  ChallengePropAction,
  INTRODUCTION_MAX_LENGTH,
  INTRODUCTION_MIN_LENGTH,
  PREREQUISITES_MAX_LENGTH,
  PREREQUISITES_MIN_LENGTH,
  SUMMARY_MAX_LENGTH,
  SUMMARY_MIN_LENGTH,
  TOPICS_COVERED_MAX_LENGTH,
  TOPICS_COVERED_MIN_LENGTH,
  useCreateChallenge,
} from '../../../../store/create-challenge.context';
import { Challenge, ChallengeLearningOutcome, ChallengeReviewableSection } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { HorizontalRule } from '../../../common/HorizontalRule';
import { KeyValue } from '../../../common/KeyValue';
import { ChallengeReviewPopover } from '../../challengesCommon/ChallengeReviewPopover';
import { parseBraceDelimitedString, stringArrayToBraceDelimitedString, safeString } from "../../../../utils/string.utils";

interface LearningOutcomeDetailProps {
  challenge: Challenge;
}

const LearningOutcomeDetail: React.FC<LearningOutcomeDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { editMode, editedChallenge, handleUpdateChallengeProp, challengeLearningOutcomeValidator } =
    useCreateChallenge();

  const [learningOutcome, setLearningOutcome] = useState<ChallengeLearningOutcome>(
    editedChallenge?.props.learningOutcome || new ChallengeLearningOutcome()
  );

  // error texts and validation logic
  const [summaryErrorText, setSummaryErrorText] = useState<string>('');
  const [introductionErrorText, setIntroductionErrorText] = useState<string>('');
  const [topicsCoveredErrorText, setTopicsCoveredErrorText] = useState<string>('');
  const [preRequisitesErrorText, setPreRequisitesErrorText] = useState<string>('');
  const [difficultyErrorText, setDifficultyErrorText] = useState<string>('');
  const field = i18nKeys.challenges.subSections.learningPlan.fields;
  const [learningObjectives, setLearningObjectives] = useState<string[]>(
    parseBraceDelimitedString(challenge.props.learningOutcome.topicsCovered) ||
      [safeString(challenge.props.learningOutcome.topicsCovered)]
  );

  useEffect(() => {
    // reset error fields when editMode is changed
    if (!editMode) {
      setSummaryErrorText('');
      setIntroductionErrorText('');
      setTopicsCoveredErrorText('');
      setPreRequisitesErrorText('');
      setDifficultyErrorText('');
    }
  }, [editMode]);

  const validator = challengeLearningOutcomeValidator(
    new Map<ChallengeLearningOutcomeFields, (error: string) => void>([
      [ChallengeLearningOutcomeFields.SUMMARY, (error: string) => setSummaryErrorText(error)],
      [ChallengeLearningOutcomeFields.INTRODUCTION, (error: string) => setIntroductionErrorText(error)],
      [ChallengeLearningOutcomeFields.TOPICS_COVERED, (error: string) => setTopicsCoveredErrorText(error)],
      [ChallengeLearningOutcomeFields.PRE_REQUISITES, (error: string) => setPreRequisitesErrorText(error)],
    ])
  );

  useEffect(() => {
    if (editedChallenge) {
      setLearningOutcome({
        summary: editedChallenge.props.learningOutcome.summary,
        introduction: editedChallenge.props.learningOutcome.introduction,
        topicsCovered: editedChallenge.props.learningOutcome.topicsCovered,
        technicalKnowledgePrerequisites: editedChallenge.props.learningOutcome.technicalKnowledgePrerequisites,
      });
    }
  }, [editMode]);

  const handleLearningOutcome = (modifiedLearningOutcome: ChallengeLearningOutcome) => {
    setLearningOutcome(modifiedLearningOutcome);
    handleUpdateChallengeProp(ChallengePropAction.LEARNING_OUTCOME, modifiedLearningOutcome);
  };

  const handleModifyLearningObjective = (modifiedLearningObjective: string, index: number) => {
    learningObjectives[index] = modifiedLearningObjective;
    updateLearningObjectives(learningObjectives);
  };

  const handleAddLearningObjective = () => {
    learningObjectives.push('');
    updateLearningObjectives(learningObjectives);
  };

  const handleRemoveLearningObjective = (index: number) => {
    learningObjectives.splice(index, 1);
    updateLearningObjectives(learningObjectives);
    validator.isValidField(ChallengeLearningOutcomeFields.TOPICS_COVERED)
  };

  const updateLearningObjectives = (objectives: string[]) => {
    setLearningObjectives(objectives);
    handleInputChange({
      attribute: ChallengeLearningOutcomeFields.TOPICS_COVERED,
      value: stringArrayToBraceDelimitedString(objectives)
    });
  };

  interface LearningOutcomeHelper {
    attribute: ChallengeLearningOutcomeFields;
    value: string;
  }

  const handleInputChange = (event: LearningOutcomeHelper) => {
    const { attribute, value } = event;
    switch (attribute) {
      case ChallengeLearningOutcomeFields.SUMMARY:
        learningOutcome.summary = value;
        break;
      case ChallengeLearningOutcomeFields.INTRODUCTION:
        learningOutcome.introduction = value;
        break;
      case ChallengeLearningOutcomeFields.TOPICS_COVERED:
        learningOutcome.topicsCovered = value;
        break;
      case ChallengeLearningOutcomeFields.PRE_REQUISITES:
        learningOutcome.technicalKnowledgePrerequisites = value;
        break;
      default:
        throw new Error('Unrecognized field on ChallengeLearningOutcome');
    }
    handleLearningOutcome(learningOutcome);
  };

  return (
    <Container
      header={
        <Header variant="h2" actions={<ChallengeReviewPopover section={ChallengeReviewableSection.LEARNING_OUTCOME} />}>
          {t(i18nKeys.challenges.challengeDetails.headings.learningPlan)}
        </Header>
      }>
      <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.summary)}>
        {!editMode ? (
          challenge.props?.learningOutcome?.summary
        ) : (
          <FormField
            errorText={summaryErrorText}
            constraintText={t(field.summary.constraint, { SUMMARY_MIN_LENGTH, SUMMARY_MAX_LENGTH })}>
            <Textarea
              onChange={({ detail }) =>
                handleInputChange({ attribute: ChallengeLearningOutcomeFields.SUMMARY, value: detail.value })
              }
              name="learningOutcomeSummary"
              value={editedChallenge?.props.learningOutcome.summary || ''}
              placeholder={t(i18nKeys.challenges.subSections.learningPlan.fields.summary.placeholder)}
              onBlur={() => validator.isValidField(ChallengeLearningOutcomeFields.SUMMARY)}
            />
          </FormField>
        )}
      </KeyValue>
      <HorizontalRule />
      <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.introduction)}>
        {!editMode ? (
          challenge.props?.learningOutcome?.introduction
        ) : (
          <FormField
            errorText={introductionErrorText}
            constraintText={t(field.introduction.constraint, {
              INTRODUCTION_MIN_LENGTH,
              INTRODUCTION_MAX_LENGTH,
            })}>
            <Textarea
              onChange={({ detail }) =>
                handleInputChange({ attribute: ChallengeLearningOutcomeFields.INTRODUCTION, value: detail.value })
              }
              name="learningOutcomeIntroduction"
              value={editedChallenge?.props.learningOutcome.introduction || ''}
              onBlur={() => validator.isValidField(ChallengeLearningOutcomeFields.INTRODUCTION)}
              placeholder={t(i18nKeys.challenges.subSections.learningPlan.fields.introduction.placeholder)}
            />
          </FormField>
        )}
      </KeyValue>
      <HorizontalRule />
      <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.learningObjectives)}>
        {!editMode ? (
          <ol>
          {learningObjectives.map((learningObjective, i) => <li key={i}>{learningObjective || ''}</li>)}
          </ol>
        ) : (
          <FormField
            errorText={topicsCoveredErrorText}
            constraintText={t(field.learningObjectives.constraint, {
              TOPICS_COVERED_MIN_LENGTH,
              TOPICS_COVERED_MAX_LENGTH,
            })}>
            <SpaceBetween direction="vertical" size="m">
              {learningObjectives.map((learningObjective, i) => {
                return <span key={i}>
                  <Input
                    type="text"
                    value={learningObjective || ''}
                    onChange={({ detail }) => handleModifyLearningObjective(detail.value, i)}
                    placeholder={t(field.learningObjectives.placeholder)}
                    onBlur={() => validator.isValidField(ChallengeLearningOutcomeFields.TOPICS_COVERED)}
                  />
                  {learningObjectives.length > 1 && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '0.5em'}}>
                      <Button variant={'normal'} onClick={() => handleRemoveLearningObjective(i)}>
                        {t(i18nKeys.general.remove)}
                      </Button>
                  </div>}
                </span>;
              })}
              <Button variant={'normal'} onClick={handleAddLearningObjective}>
                {t(field.learningObjectives.add)}
              </Button>
            </SpaceBetween>
          </FormField>
        )}
      </KeyValue>
      <HorizontalRule />
      <KeyValue
        className="primary-text"
        label={t(i18nKeys.challenges.challengeDetails.titles.technicalKnoledgePrerequisites)}>
        {!editMode ? (
          challenge.props?.learningOutcome?.technicalKnowledgePrerequisites
        ) : (
          <FormField
            errorText={preRequisitesErrorText}
            constraintText={t(field.technicalKnowledgePrerequisites.constraint, {
              PREREQUISITES_MIN_LENGTH,
              PREREQUISITES_MAX_LENGTH,
            })}>
            <Textarea
              onChange={({ detail }) =>
                handleInputChange({ attribute: ChallengeLearningOutcomeFields.PRE_REQUISITES, value: detail.value })
              }
              onBlur={() => validator.isValidField(ChallengeLearningOutcomeFields.PRE_REQUISITES)}
              value={editedChallenge?.props.learningOutcome.technicalKnowledgePrerequisites || ''}
              placeholder={t(
                i18nKeys.challenges.subSections.learningPlan.fields.technicalKnowledgePrerequisites.placeholder
              )}
            />
          </FormField>
        )}
      </KeyValue>
    </Container>
  );
};

export default LearningOutcomeDetail;
