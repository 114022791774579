/* eslint-disable @typescript-eslint/no-unsafe-argument */
export const downloadAs = (content: any, filename: string, mimetype: string) => {
  const dataStr = 'data:' + mimetype + ';charset=utf-8,' + encodeURIComponent(content);

  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', filename);

  document.body.appendChild(downloadAnchorNode);

  downloadAnchorNode.click();
  downloadAnchorNode.remove();
};

export const downloadObjectAsJson = (exportObj: any, exportName: string) => {
  downloadAsJson(JSON.stringify(exportObj, null, 4), exportName);
};

export const downloadObjectAsCsv = (exportObj: any, exportName: string) => {
  downloadAs(objToCsvStr(exportObj), exportName + '.csv', 'text/csv');
};

export const downloadAsJson = (fileContent: any, exportName: string) => {
  downloadAs(fileContent, exportName + '.json', 'text/json');
};

export const objToCsvStr = (obj: any[]) => {
  const header = Object.keys(obj[0]).join(',');
  const values = obj.map((o) => Object.values(o).join(',')).join('\n');
  return `${header}\n${values}`;
};

export const downloadAsPEM = (text: any, exportName: string) => {
  downloadAs(text, `${exportName}.pem`, "application/x-pem-file");
};
