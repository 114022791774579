/* eslint-disable @typescript-eslint/no-unsafe-return */
import * as React from 'react';
import { Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../../utils/i18n.utils';
import ChallengeSshExample from './ChallengeSshExample';
import { ChallengeConfiguration } from '../../../types/Challenge';

interface ChallengeSshSnippetModalProps {
  visible: boolean;
  onCancel: () => any;
  onConfirm: () => any;
  challengeConfig: ChallengeConfiguration;
}
const ChallengeSshSnippetModal: React.FC<ChallengeSshSnippetModalProps> = ({
  visible,
  onCancel,
  onConfirm,
  challengeConfig,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      header={t(i18nKeys.challenges.challengeDetails.headings.usingChallengeResources)}
      onDismiss={() => onCancel()}
      footer={<Button onClick={() => onConfirm()}>{t(i18nKeys.general.close)}</Button>}
      visible={visible}>
      <SpaceBetween size="s">
        <div>
          <h4>{t(i18nKeys.general.stepCounter, { count: 1 })}</h4>
          <p>{t(i18nKeys.challenges.challengeDetails.messages.challengeSshSnippetStepOne)}</p>
          <ChallengeSshExample challengeConfig={challengeConfig} />
        </div>
        <div>
          <h4>{t(i18nKeys.general.stepCounter, { count: 2 })}</h4>
          <p>{t(i18nKeys.challenges.challengeDetails.messages.challengeSshSnippetStepTwo)}</p>
        </div>
      </SpaceBetween>
    </Modal>
  );
};

export default ChallengeSshSnippetModal;
