import {
  Badge,
  Button,
  ExpandableSection,
  Header,
  Input,
  SpaceBetween,
  TokenGroup,
} from '@amzn/awsui-components-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChallengePropAction, useCreateChallenge } from '../../../../store/create-challenge.context';
import { Challenge } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { Column } from '../../../common/Column';
import { Columns } from '../../../common/Columns';
import { KeyValue } from '../../../common/KeyValue';
import ChallengeStatusIndicator from '../../challengesCommon/ChallengeStatusIndictator';

interface ChallengeSummaryDetailProp {
  challenge: Challenge;
}

const ChallengeSummaryDetail: React.FC<ChallengeSummaryDetailProp> = ({ challenge }) => {
  const { t } = useTranslation();
  const { editMode, editedChallenge, handleUpdateChallengeProp } = useCreateChallenge();

  const tagItems: { label: string; dismissLabel: string }[] = [];

  useEffect(() => {
    const challengeTags = editMode ? editedChallenge?.props.tags : challenge.props.tags;
    if (challengeTags) {
      challengeTags.forEach((tag: string) => {
        tagItems.push({
          label: tag,
          dismissLabel: `${t(i18nKeys.challenges.challengeDetails.labels.removeTag)}: ${tag}`,
        });
      });
    }
  }, [editMode]);

  const [tags, setTags] = useState<{ label: string; dismissLabel: string }[]>(tagItems);
  const [value, setValue] = React.useState('');
  const [expanded, setExpanded] = useState(true);

  const addTag = () => {
    const newTags = tags;
    newTags.push({
      label: value,
      dismissLabel: `${t(i18nKeys.challenges.challengeDetails.labels.removeTag)}: ${value}`,
    });
    const updatedTags = newTags.map((tag) => tag.label);
    handleUpdateChallengeProp(ChallengePropAction.TAGS, updatedTags);
    setTags(newTags);
    setValue('');
  };

  const removeTag = (itemIndex: number) => {
    const newTags = [...tags.slice(0, itemIndex), ...tags.slice(itemIndex + 1)];
    setTags(newTags);
    const updatedTags = newTags.map((tag) => tag.label);
    handleUpdateChallengeProp(ChallengePropAction.TAGS, updatedTags);
  };

  return (
    <ExpandableSection
      variant="container"
      expanded={expanded}
      onChange={() => setExpanded((prevState) => !prevState)}
      header={<Header variant="h2">{t(i18nKeys.challenges.challengeDetails.headings.summary)}</Header>}>
      <Columns columns={4} variant="default">
        <Column size="s">
          <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.id)}>
            {challenge.challengeId}
          </KeyValue>
          <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.version)}>
            <ChallengeStatusIndicator challenge={challenge} subtitle={''} showVersion />
          </KeyValue>
        </Column>
        <Column size="s">
          <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.tags)}>
            {!editMode ? (
              <React.Fragment>
                {challenge.props.tags
                  ? challenge.props.tags.map((tag, key) => {
                      return <Badge key={key}>{tag}</Badge>;
                    })
                  : null}
              </React.Fragment>
            ) : (
              <SpaceBetween size={'s'}>
                <TokenGroup onDismiss={({ detail: { itemIndex } }) => removeTag(itemIndex)} items={tags} />
                <Input onChange={({ detail }) => setValue(detail.value)} value={value} />
                <Button onClick={addTag}>{t(i18nKeys.challenges.challengeDetails.buttons.addTag)}</Button>
              </SpaceBetween>
            )}
          </KeyValue>
        </Column>
        <Column size="s">
          <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.lastModified)}>
            {challenge.props.lastUpdatedBy}
          </KeyValue>
          <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.lastApprovedBy)}>
            {challenge.props.lastApprovedBy}
          </KeyValue>
        </Column>
        <Column size="s">
          <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.lastMajorEdit)}>
            {challenge.props.lastUpdatedBy}
          </KeyValue>
          <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.lastMinorEdit)}>
            {challenge.props.lastEditedBy}
          </KeyValue>
        </Column>
      </Columns>
    </ExpandableSection>
  );
};

export default ChallengeSummaryDetail;
