import { ExpandableSection, Grid, Header, Link } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Event, getEventListStatus } from '../../../../types/Event';
import { i18nKeys } from '../../../../utils/i18n.utils';
import LabShutoffStatusBadge from '../../../common/LabShutoffStatusBadge';
import SecretKey from '../../../common/SecretKey';
import LabStatusCountdown from '../../eventsCommon/labStatusCountdown/LabStatusCountdown';

interface AccessProps {
  event: Event | undefined;
}

const Access: React.FC<AccessProps> = ({ event }) => {
  const { t } = useTranslation();
  // SecretKey accepts a String/undefined param but secretKeys urls are nullable strings
  // Typically checking before rendering SecretKey suffices, however due to ternary nature of this component linting
  // did not allow us to do so. Moving the ternary to a const and checking for truthy solves for that
  const secretKeyUrl = event?.test ? event?.testUrlWithSecretKey : event?.url;
  return (
    <React.Fragment>
      {event?.approved && (
        <ExpandableSection
          variant="container"
          defaultExpanded
          header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.access)}</Header>}>
          <div className="container-table-header">
            <Grid gridDefinition={[{ colspan: 2 }, { colspan: 5 }, { colspan: 2 }, { colspan: 3 }]}>
              <div className="table-divider">
                <strong>{t(i18nKeys.events.eventDetails.headers.eventType.header)}</strong>
              </div>
              <div>
                <strong>{t(i18nKeys.events.eventDetails.headers.link)}</strong>
              </div>
              <div className="table-divider">
                <strong>{t(i18nKeys.events.eventDetails.headers.secretKey)}</strong>
              </div>
              <div>
                <strong>{t(i18nKeys.events.eventDetails.headers.status)}</strong>
              </div>
            </Grid>
          </div>
          <div style={{ padding: '10px 0px 10px 0px', marginBottom: '-20px' }}>
            <Grid gridDefinition={[{ colspan: 2 }, { colspan: 5 }, { colspan: 2 }, { colspan: 3 }]}>
              <React.Fragment>
                <div>
                  {event?.test
                    ? t(i18nKeys.events.eventDetails.labels.testEvent)
                    : t(i18nKeys.events.eventDetails.labels.liveEvent)}
                </div>
                <div>
                  {(event?.testUrlWithSecretKey || event?.url) && secretKeyUrl && (
                    <Link external href={secretKeyUrl}>
                      {event?.test ? event?.testUrlWithMaskedSecretKey : event?.urlWithMaskedSecretKey}
                    </Link>
                  )}
                </div>
                <div>
                  <SecretKey secretKey={event?.eventCode} />
                </div>
                <div>
                  <LabStatusCountdown
                    isTest={event?.test}
                    includeSeconds={false}
                    inline
                    status={getEventListStatus(event)}
                    labStartTime={event.labStartTime}
                    labEndTime={event.labEndTime}
                  />
                  <span style={{ marginRight: '20px' }} />
                  <LabShutoffStatusBadge
                    challengeDescriptors={event.challengeDescriptors}
                    shutoffStatus={event.shutoffStatus}
                    testEventShutoffStatus={event.testEventShutoffStatus}
                  />
                </div>
              </React.Fragment>
            </Grid>
          </div>
          {event && !event?.test && event?.testCloneSuffix && (
            <div className="grey-section-divider-top">
              <Grid gridDefinition={[{ colspan: 2 }, { colspan: 5 }, { colspan: 2 }, { colspan: 3 }]}>
                <React.Fragment>
                  <div>{t(i18nKeys.events.eventDetails.labels.testEvent)}</div>
                  <div>
                    {event?.testUrlWithSecretKey && (
                      <Link external href={event?.testUrlWithSecretKey}>
                        {event?.testUrlWithMaskedSecretKey}
                      </Link>
                    )}
                  </div>
                  <div>
                    <SecretKey secretKey={event?.testCloneCode} />
                  </div>
                  <div>
                    <LabStatusCountdown
                      isTest
                      includeSeconds={false}
                      status={getEventListStatus(event)}
                      labStartTime={event.labStartTime}
                      labEndTime={event.labEndTime}
                      inline
                    />
                    <span style={{ marginRight: '20px' }} />
                    <LabShutoffStatusBadge
                      challengeDescriptors={event.challengeDescriptors}
                      shutoffStatus={event.shutoffStatus}
                      testEventShutoffStatus={event.testEventShutoffStatus}
                      test
                    />
                  </div>
                </React.Fragment>
              </Grid>
            </div>
          )}
        </ExpandableSection>
      )}
    </React.Fragment>
  );
};

export default Access;
