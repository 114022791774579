import { isLocal } from './env.utils';

export enum LogLevel {
  NONE = -1,
  ERROR = 0,
  WARN = 1,
  INFO = 2,
  DEBUG = 3,
}

export class LoggingService {
  private static logLevel: LogLevel = !isLocal() ? LogLevel.NONE : LogLevel.DEBUG;

  private static setLogLevel(logLevel: LogLevel) {
    LoggingService.logLevel = logLevel;
  }

  public static error(message: string, ...args: any[]): void {
    LoggingService.log(LogLevel.ERROR, message, args);
  }

  public static warn(message: string, ...args: any[]): void {
    LoggingService.log(LogLevel.WARN, message, args);
  }

  public static info(message: string, ...args: any[]): void {
    LoggingService.log(LogLevel.INFO, message, args);
  }

  public static debug(message: string, ...args: any[]): void {
    LoggingService.log(LogLevel.DEBUG, message, args);
  }

  private static log(level: LogLevel, message: string, ...args: any[]): void {
    if (level <= LoggingService.logLevel) {
      const timestamp = new Date().toISOString().replace('T', ' ').slice(0, 23);
      const formattedMessage = `[${timestamp}] [${LogLevel[level]}] ${message}`;
      console.log(formattedMessage); // eslint-disable-line
    }
  }
}
