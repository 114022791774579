import { SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { Event } from '../../../types/Event';
import AgreementInfo from '../eventDetailsSections/Summary/AgreementInfo';
import EventTimes from '../eventDetailsSections/Summary/EventTimes';
import EventDetailsNotes from '../eventDetailsSections/Summary/EventDetailsNotes';
import TargetSummaryDetails from '../../common/CampaignEventComponents/TargetSummaryDetails';
// import TargetDetailsUsagePlan from '../../common/CampaignEventComponents/TargetDetailsUsagePlan';

interface NewEventDetailsProps {
  target: Event;
}

const NewEventDetails: React.FC<NewEventDetailsProps> = ({ target }) => {
  return (
    <SpaceBetween direction="vertical" size="s">
      <TargetSummaryDetails target={target} />
      <AgreementInfo target={target} />
      {/* <TargetDetailsUsagePlan target={target} /> */}
      <EventTimes target={target} />
      <EventDetailsNotes target={target} />
    </SpaceBetween>
  );
};

export default NewEventDetails;
