/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useContext } from 'react';
import { FlashbarItem } from '../types/common';
import { useLocalContext } from '../hooks/useLocalContext';
import {
  bannerFlashbar,
  errorFlashbar,
  infoFlashbar,
  successFlashbar,
  warningFlashbar,
} from '../utils/notification.utils';
import { i18nKeys } from '../utils/i18n.utils';
import { useTranslation } from 'react-i18next';

export type AddFlashbar = (flashbar: FlashbarItem) => void;
export type RemoveFlashbar = (id: string) => void;
export type AddInfoFlashbar = (message: string, header?: string) => void;
export type AddSuccessFlashbar = (message: string, header?: string) => void;
export type AddWarningFlashbar = (message: string, header?: string) => void;
export type AddErrorFlashbar = (message: string, header?: string) => void;
export type AddBannerFlashbar = (message: string, header?: string) => void;

export interface FlashbarContextValue {
  flashbars: FlashbarItem[];
  addFlashbar: AddFlashbar;
  removeFlashbar: RemoveFlashbar;
  addInfoFlashbar: AddInfoFlashbar;
  addSuccessFlashbar: AddSuccessFlashbar;
  addWarningFlashbar: AddWarningFlashbar;
  addErrorFlashbar: AddErrorFlashbar;
  addBannerFlashbar: AddBannerFlashbar;
}

const FlashbarContext = React.createContext<FlashbarContextValue>({
  flashbars: [],
  addFlashbar: (_flashbar: FlashbarItem) => {},
  removeFlashbar: (_id: string) => {},
  addInfoFlashbar: (_message: string, _header?: string) => {},
  addSuccessFlashbar: (_message: string, _header?: string) => {},
  addWarningFlashbar: (_message: string, _header?: string) => {},
  addErrorFlashbar: (_message: string, _header?: string) => {},
  addBannerFlashbar: (_message: string, _header?: string) => {},
});

const FlashbarProvider: React.FC = ({ children }) => {
  const [flashbars, setFlashbars] = useState<FlashbarItem[]>([]);

  // using a local context so that the handler methods defined below always have access to the latest state
  const localContext = useLocalContext({ flashbars });
  const { t } = useTranslation();

  const removeFlashbar = (id: string): void => {
    const filtered = localContext.flashbars.filter((f) => f.id !== id);
    setFlashbars(filtered);
  };

  const addFlashbar = (flashbar: FlashbarItem): void => {
    if (flashbar.dismissible) {
      const originalOnDismiss = flashbar.onDismiss;

      // add a callback to remove the flashbar item from the state
      flashbar.onDismiss = (event) => {
        removeFlashbar(flashbar.id);
        if (typeof originalOnDismiss === 'function') {
          originalOnDismiss(event);
        }
      };
    }

    const nextFlashbars = [...localContext.flashbars, flashbar];

    setFlashbars(nextFlashbars);
  };

  const addInfoFlashbar = (message: string, header?: string) => {
    addFlashbar(infoFlashbar(header ? header : t(i18nKeys.general.info), message));
  };

  const addSuccessFlashbar = (message: string, header?: string) => {
    addFlashbar(successFlashbar(header ? header : t(i18nKeys.general.success), message));
  };

  const addWarningFlashbar = (message: string, header?: string) => {
    addFlashbar(warningFlashbar(header ? header : t(i18nKeys.general.warning), message));
  };

  const addErrorFlashbar = (message: string, header?: string) => {
    addFlashbar(errorFlashbar(header ? header : t(i18nKeys.general.error), message));
  };

  const addBannerFlashbar = (message: string, header?: string) => {
    addFlashbar(bannerFlashbar(header ? header : t(i18nKeys.general.warning), message));
  };

  const data: FlashbarContextValue = {
    flashbars,
    addFlashbar,
    removeFlashbar,
    addInfoFlashbar,
    addSuccessFlashbar,
    addWarningFlashbar,
    addErrorFlashbar,
    addBannerFlashbar,
  };

  return <FlashbarContext.Provider value={data}>{children}</FlashbarContext.Provider>;
};

const useFlashbars = () => {
  const context = useContext(FlashbarContext);
  if (context === undefined) {
    throw new Error('useFlashbars can only be used inside FlashbarProvider');
  }
  return context;
};

export { FlashbarProvider, useFlashbars };
