import * as React from 'react';
import { Link } from 'react-router-dom';
import { CHALLENGE_DETAILS_ROUTES } from '../../../../routes';
import { ChallengeIssue } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { getTableFilterMatcher, TableFilterMatcher } from '../../../../utils/table.utils';
import { TFunctionProvider } from '../../../common/TFunctionProvider';

export const filteringFunction = (item: ChallengeIssue, filteringText: string): boolean => {
  const matcher: TableFilterMatcher = getTableFilterMatcher(filteringText);
  return (
    matcher.filterEmpty ||
    matcher.isMatch(item?.id) ||
    matcher.isMatch(item?.title) ||
    matcher.isMatch(item?.openedBy) ||
    matcher.isMatch(item?.severity)
  );
};

export const COLUMN_DEFINITIONS = [
  {
    id: 'title',
    header: <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.title)}</TFunctionProvider>,
    cell: (item: ChallengeIssue) => {
      return (
        <Link to={`${CHALLENGE_DETAILS_ROUTES.Issues.resolve(item.challengeId ?? '')}/${item.id}`}>{item.title}</Link>
      );
    },
    sortingField: 'title',
    minWidth: 100,
    allowLineWrap: true,
  },
  {
    id: 'createdDate',
    header: (
      <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.dateCreated)}</TFunctionProvider>
    ),
    cell: (item: ChallengeIssue) => (item ? item.createdDate : '-'),
    sortingField: 'createdDate',
    minWidth: 100,
    allowLineWrap: true,
  },
  {
    id: 'openedBy',
    header: (
      <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.openedBy)}</TFunctionProvider>
    ),
    cell: (item: ChallengeIssue) => (item ? item.openedBy : '-'),
    minWidth: 100,
    allowLineWrap: true,
  },
  {
    id: 'status',
    header: <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.status)}</TFunctionProvider>,
    cell: (item: ChallengeIssue) => (item ? item.status : '-'),
    sortingField: 'status',
    minWidth: 100,
    allowLineWrap: true,
  },
  {
    id: 'severity',
    header: (
      <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.severity)}</TFunctionProvider>
    ),
    cell: (item: ChallengeIssue) => (item ? item.severity : '-'),
    sortingField: 'severity',
    minWidth: 100,
    allowLineWrap: true,
  },
  {
    id: 'comments',
    header: (
      <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.comments)}</TFunctionProvider>
    ),
    cell: (item: ChallengeIssue) => (item ? item.comments.length : '-'),
    minWidth: 100,
    allowLineWrap: true,
  },
];
