import { ExpandableSection, Header, Icon, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Campaign } from '../../../types/Campaign';
import { i18nKeys } from '../../../utils/i18n.utils';
import Audit from '../../events/eventDetailsSections/Summary/Audit';
import TargetSummaryDetails from '../../common/CampaignEventComponents/TargetSummaryDetails';
import OwnerPermissions from '../../events/eventDetailsSections/Summary/OwnerPermissions';
import ParticipantLimit from './ParticipantLimit';
import ReportRecipients from './ReportRecipients';
// import TargetDetailsUsagePlan from '../../common/CampaignEventComponents/TargetDetailsUsagePlan';
import { useEditCampaign } from '../../../store/edit-campaign.context';

interface CampaignSummaryProps {
  campaign: Campaign | undefined;
}

const CampaignSummary: React.FC<CampaignSummaryProps> = ({ campaign }) => {
  const { t } = useTranslation();
  const { editedCampaign, campaignEditMode } = useEditCampaign();

  return (
    <React.Fragment>
      <Header variant="h2">{t(i18nKeys.campaigns.subNavigation.summary)}</Header>
      <SpaceBetween direction="vertical" size="s">
        <ExpandableSection
          variant="container"
          header={<Header variant="h2">{t(i18nKeys.campaigns.headers.campaignDetails.preparationGuide)}</Header>}
          defaultExpanded>
          <div className="inline">
            <Icon name="status-warning" className="warning inline mr-5" />
            {t(i18nKeys.campaigns.messages.campaignDetails.preparationGuide)}
          </div>
        </ExpandableSection>
        <TargetSummaryDetails target={editedCampaign || campaign} />
        {/* <TargetDetailsUsagePlan target={editedCampaign || campaign} /> */}
        <ParticipantLimit campaign={editedCampaign || campaign} />
        <OwnerPermissions target={editedCampaign || campaign} targetType="campaign" />
        <ReportRecipients campaign={editedCampaign || campaign} />
        {!campaignEditMode && <Audit target={campaign} />}
      </SpaceBetween>
    </React.Fragment>
  );
};
export default CampaignSummary;
