import {
  BarChart,
  Box,
  ColumnLayout,
  Container,
  ExpandableSection,
  Header,
  Link,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useToolPanel } from '../../../../store/tool-panel.context';
import { ChallengeListItem } from '../../../../types/Challenge';
import { ChallengeMetricsRow } from '../../../../types/DetailedEventStatisticsReport';
import { getDisplayDuration } from '../../../../utils/time.utils';
import { HorizontalRule } from '../../../common/HorizontalRule';
import { KeyValue } from '../../../common/KeyValue';
import { Nullable } from '../../../../types/common';
import { useChallenges } from '../../../../store/challenge.context';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { LearningOutcome } from '../../../common/LearningOutcome';
import { roundFloat } from '../../../../utils/number.utils';

interface ChallengeMetricsProps {
  challengeMetricsRow: ChallengeMetricsRow | undefined;
  title: string;
  teamCount: number;
}

const ChallengeMetrics: React.FC<ChallengeMetricsProps> = ({ challengeMetricsRow, title, teamCount }) => {
  const { t } = useTranslation();
  const [comments, setComments] = useState<string[]>([]);
  const { toggleChallengeInfo } = useToolPanel();
  const { getChallengeListItemFromChallengeId } = useChallenges();
  const [challengeListItem, setChallengeListItem] = useState<Nullable<ChallengeListItem>>();
  const getFeedbackComments = (): string[] => {
    if (challengeMetricsRow && challengeMetricsRow.feedbackComments) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
      return (
        challengeMetricsRow.feedbackComments.filter((feedback: string) => {
          return feedback != null && feedback.length > 1;
        }) || []
      );
    } else {
      return [];
    }
  };

  useEffect(() => {
    setComments(getFeedbackComments());
    if (challengeMetricsRow?.challengeId) {
      setChallengeListItem(getChallengeListItemFromChallengeId(challengeMetricsRow?.challengeId));
    }
  }, [challengeMetricsRow]);

  return (
    <ExpandableSection
      header={
        <Header>
          {title}
          {challengeListItem && (
            <Link className="ml-5" variant="info" onFollow={() => toggleChallengeInfo(challengeListItem)}>
              {t(i18nKeys.general.info)}
            </Link>
          )}
        </Header>
      }
      variant="container">
      <ColumnLayout columns={4} variant="text-grid">
        <Box>
          <Container header={<Header variant="h3">{t(i18nKeys.report.headers.teamData)}</Header>}>
            <BarChart
              hideFilter
              hideLegend
              height={220}
              yTitle={t(i18nKeys.report.graph.labels.numOfTeams)}
              xTitle={t(i18nKeys.report.graph.labels.teamState)}
              xScaleType="categorical"
              yDomain={[0, 40]}
              series={[
                {
                  title: t(i18nKeys.report.graph.labels.started),
                  type: 'bar',
                  data: [
                    {
                      x: t(i18nKeys.report.graph.labels.started).toString(),
                      y: challengeMetricsRow?.numTeamsStarted || 0,
                    },
                  ],
                },
                {
                  title: t(i18nKeys.report.graph.labels.attempted),
                  type: 'bar',
                  data: [
                    {
                      x: t(i18nKeys.report.graph.labels.attempted).toString(),
                      y: challengeMetricsRow?.numTeamsAttempted || 0,
                    },
                  ],
                },
                {
                  title: t(i18nKeys.report.graph.labels.solved),
                  type: 'bar',
                  data: [
                    {
                      x: t(i18nKeys.report.graph.labels.solved).toString(),
                      y: challengeMetricsRow?.numTeamsSolved || 0,
                    },
                  ],
                },
              ]}
            />
          </Container>
        </Box>
        <Box>
          <KeyValue label={t(i18nKeys.report.labels.timeToSolve)} secondaryLabelColor>
            {challengeMetricsRow?.timeToCompletedChallenge &&
            Object.keys(challengeMetricsRow.timeToCompletedChallenge).length > 0 ? (
              <React.Fragment>
                {challengeMetricsRow?.timeToCompletedChallenge.mean && (
                  <div>
                    <strong>{t(i18nKeys.report.labels.minMaxMean.mean)}</strong>
                    {getDisplayDuration(challengeMetricsRow?.timeToCompletedChallenge.mean)}
                  </div>
                )}
                {challengeMetricsRow?.timeToCompletedChallenge.min && (
                  <div>
                    <strong>{t(i18nKeys.report.labels.minMaxMean.min)}</strong>
                    {getDisplayDuration(challengeMetricsRow?.timeToCompletedChallenge.min)}
                  </div>
                )}
                {challengeMetricsRow?.timeToCompletedChallenge.max && (
                  <div>
                    <strong>{t(i18nKeys.report.labels.minMaxMean.max)}</strong>
                    {getDisplayDuration(challengeMetricsRow?.timeToCompletedChallenge.max)}
                  </div>
                )}
              </React.Fragment>
            ) : (
              <span>{t(i18nKeys.general.nA)}</span>
            )}
          </KeyValue>
          <KeyValue label={t(i18nKeys.report.labels.timeSpentOnFirstAttempt)} secondaryLabelColor>
            {challengeMetricsRow?.timeToFirstAttempt &&
            Object.keys(challengeMetricsRow.timeToFirstAttempt).length > 0 ? (
              <React.Fragment>
                {challengeMetricsRow?.timeToFirstAttempt.mean && (
                  <div>
                    <strong>{t(i18nKeys.report.labels.minMaxMean.mean)}</strong>
                    {getDisplayDuration(challengeMetricsRow?.timeToFirstAttempt.mean)}
                  </div>
                )}
                {challengeMetricsRow?.timeToFirstAttempt.min && (
                  <div>
                    <strong>{t(i18nKeys.report.labels.minMaxMean.min)}</strong>{' '}
                    {getDisplayDuration(challengeMetricsRow?.timeToFirstAttempt.min)}
                  </div>
                )}
                {challengeMetricsRow?.timeToFirstAttempt.max && (
                  <div>
                    <strong>{t(i18nKeys.report.labels.minMaxMean.max)}</strong>
                    {getDisplayDuration(challengeMetricsRow?.timeToFirstAttempt.max)}
                  </div>
                )}
              </React.Fragment>
            ) : (
              <span>{t(i18nKeys.general.nA)}</span>
            )}
          </KeyValue>
          <KeyValue label={t(i18nKeys.report.labels.attemptsMade)} secondaryLabelColor>
            <div>
              {t(i18nKeys.report.labels.numOfCount, {
                num: challengeMetricsRow?.numTeamsAttempted || '0',
                totalCount: teamCount,
              })}
            </div>
          </KeyValue>
          <KeyValue label={t(i18nKeys.report.labels.restarts)} secondaryLabelColor>
            <div>{challengeMetricsRow?.numberOfRestartsUsed}</div>
          </KeyValue>
          {/** TODO: Add the Avg Reasearch time once values are made available on report */}
        </Box>
        <Box>
          {/** TODO: Implement Time spent on tasks once values are available on report */}
          <KeyValue label={t(i18nKeys.report.labels.cluesUsed)} secondaryLabelColor>
            <div>{challengeMetricsRow?.numCluesRequested || 0}</div>
          </KeyValue>
          <KeyValue label={t(i18nKeys.report.labels.supportChats)} secondaryLabelColor>
            <div>{challengeMetricsRow?.supportChatRequests}</div>
          </KeyValue>
           <KeyValue label={t(i18nKeys.report.labels.solvedWithAmazonCodeWhisperer)} secondaryLabelColor>
             <div>{challengeMetricsRow?.numParticipantsUsedCodeWhisperer ?? 0}</div>
           </KeyValue>
           <KeyValue label={t(i18nKeys.report.labels.solvedWithAwsConsole)} secondaryLabelColor>
             <div>{challengeMetricsRow?.numParticipantsUsedAwsConsole ?? 0}</div>
           </KeyValue>
           <KeyValue label={t(i18nKeys.report.labels.solvedWithAwsCli)} secondaryLabelColor>
             <div>{challengeMetricsRow?.numParticipantsUsedAwsCli ?? 0}</div>
           </KeyValue>
        </Box>
        <Box>
          <SpaceBetween direction="vertical" size="s">
            <LearningOutcome
              totalLearned={challengeMetricsRow?.learnedSomethingNew || 0}
              totalCount={
                (challengeMetricsRow?.learnedSomethingNew || 0) + (challengeMetricsRow?.didNotLearnSomethingNew || 0)
              }
            />
            <KeyValue label={t(i18nKeys.report.labels.avgRank)} secondaryLabelColor>
              <div>
                {!Number.isNaN(challengeMetricsRow?.avgChallengeRank)
                  ? roundFloat(challengeMetricsRow?.avgChallengeRank as number, 2)
                  : t(i18nKeys.general.nA)}
              </div>
            </KeyValue>
            <KeyValue label={t(i18nKeys.report.labels.avgDifficulty)} secondaryLabelColor>
              <div>
                {!Number.isNaN(challengeMetricsRow?.avgChallengeDifficulty)
                  ? roundFloat(challengeMetricsRow?.avgChallengeDifficulty as number, 2)
                  : t(i18nKeys.general.nA)}
              </div>
            </KeyValue>
            <KeyValue label={t(i18nKeys.report.labels.awsAccounts)} secondaryLabelColor>
              <div>{challengeMetricsRow?.totalNumberOfAWSAccountsUsed || 0}</div>
            </KeyValue>
            <KeyValue label={t(i18nKeys.report.labels.awsServices)} secondaryLabelColor>
              <div>{challengeMetricsRow?.awsServicesUsed?.join(', ')}</div>
            </KeyValue>
          </SpaceBetween>
        </Box>
      </ColumnLayout>
      {comments.length > 0 && (
        <React.Fragment>
          <HorizontalRule evenMargins />
          <ExpandableSection header={t(i18nKeys.report.headers.comments, { commentCount: comments.length })}>
            {comments.map((comment: string, i) => {
              return (
                <React.Fragment key={`comment-${i}`}>
                  <div>&quot;{comment}&quot;</div>
                  {i + 1 !== comments.length && (
                    <div>
                      <strong>—</strong>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </ExpandableSection>
        </React.Fragment>
      )}
    </ExpandableSection>
  );
};
export default ChallengeMetrics;
