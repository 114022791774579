import { Wizard } from '@amzn/awsui-components-react';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Event } from '../../types/Event';
import { EVENT_DETAILS_ROUTES } from '../../routes';
import { useApi } from '../../store/api.context';
import { useEditEvent } from '../../store/edit-event.context';
import { useSplitPanel } from '../../store/split-panel.context';
import { useToolPanel } from '../../store/tool-panel.context';
import { i18nKeys } from '../../utils/i18n.utils';
import { ConfirmModal } from '../common/ConfirmModal';
import TeamSettings from './eventDetailsSections/Summary/TeamSettings';
import AttendanceAndTesting from './newEventSteps/AttendanceAndTesting';
import NewEventChallenges from '../common/CampaignEventComponents/NewEventChallenges';
import NewEventDetails from './newEventSteps/NewEventDetails';
import OwnersAndPermissions from './newEventSteps/OwnersAndPermissions';
import { isEmpty } from '../../utils/list.utils';
import _ from 'lodash';
import { useFlashbars } from '../../store/flashbar.context';
import ReviewAndCreate from './newEventSteps/ReviewAndCreate';
import { preProdLogger } from '../../utils/log.utils';
import { JamEventRequest } from '../../types/Event';
import { fromPlainObject } from '../../utils/mapper.utils';
import { useChallenges } from "../../store/challenge.context";
import MonkeySpinner from "../common/MonkeySpinner";

const NewEvent: React.FC = () => {
  const { t } = useTranslation();
  const { eventsApi } = useApi();
  const { addErrorFlashbar } = useFlashbars();
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const { newEventMode, toggleNewEvent, newEditedEvent, destroyEdits, populateWithUrlParams } = useEditEvent();
  const { toggleShowSplitPanel, toggleSplitPanel, showSplitPanel } = useSplitPanel();
  const { toggleShowToolPanel, toggleToolPanel, showToolPanel } = useToolPanel();
  const [confirmCancelVisible, setConfirmCancelVisible] = useState(false);
  const [notifyUsersAdded, setNotifyUsersAdded] = useState(false);
  const [populatedFields, setPopulatedFields] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const { getChallenges } = useChallenges();
  const [preloadingChallenges, setPreloadingChallenges] = useState(false);

  useEffect(() => {
    if (!newEventMode) {
      toggleNewEvent();
    }
  }, []);

  useEffect(() => {
    if (newEventMode && params) {
      setPreloadingChallenges(true);
      getChallenges(false, false, true)
        .then(() => {
          const fieldsPopulated = populateWithUrlParams(params);
          if (!isEmpty(fieldsPopulated)) {
            setPopulatedFields(fieldsPopulated);
            setNotifyUsersAdded(true);
          }
          setPreloadingChallenges(false);
        })
        .catch((err) => {
          setPreloadingChallenges(false);
          preProdLogger('Error fetching challenges', err);
        });
    }
  }, [newEventMode]);

  const handleCancel = () => {
    destroyEdits();
    history.push('/events');
  };

  const promptModal = () => {
    setConfirmCancelVisible(true);
  };

  const handleCreateEvent = () => {
    if (newEditedEvent) {
      setLoading(true);
      const jamEventRequest = fromPlainObject(newEditedEvent, JamEventRequest) as JamEventRequest;
      eventsApi
        .createJamEventRequest(jamEventRequest)
        .then((res: Event) => {
          // Update event tags if needed
          if (res && !_.isEqual(res.tags, newEditedEvent.tags)) {
            res.tags = newEditedEvent.tags;
            eventsApi
              .updateTags(res)
              .catch((err) => {
                preProdLogger(err);
            });
          }
          history.replace(EVENT_DETAILS_ROUTES.Summary.resolve(res?.name));
          destroyEdits();
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          preProdLogger(err);
        });
    }
  };

  const handleActiveStepChange = (stepIndex: number) => {
    const previousStep = stepsDictionary[(stepIndex - 1) as unknown as NewEventSteps];
    // Check for validation errors
    if (previousStep?.isValid && !previousStep.isValid() && previousStep.getErrors) {
      const errorList = previousStep.getErrors();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      addErrorFlashbar(t(i18nKeys.newEvent.errors.message, { errors: errorList.join(', ') }));
    } else {
      // If not on challenge step, hide challenge selection aid
      // Make sure to update this if we ever add additional steps or uncover a better way to track the activeStepIndex per step
      if (stepIndex !== NewEventSteps.CHALLENGES && (showSplitPanel || showToolPanel)) {
        if (showSplitPanel) {
          toggleShowSplitPanel(false);
          toggleSplitPanel(false);
        }
        if (showToolPanel) {
          toggleShowToolPanel(false);
          toggleToolPanel(false);
        }
      }
      setActiveStepIndex(stepIndex);
    }
  };

  // eslint-disable-next-line no-shadow
  enum NewEventSteps {
    EVENT_DETAILS = 0,
    ATTENDANCE_AND_TESTING = 1,
    TEAM_SETTINGS = 2,
    OWNERS_AND_PERMISSIONS = 3,
    CHALLENGES = 4,
    REVIEW_AND_CREATE = 5,
  }

  /**
   * Add steps here to keep active step number inline with wizard
   */
  const stepsDictionary: {
    [key in NewEventSteps]: { title: string; content: ReactNode; isValid?: () => boolean; getErrors?: () => string[] };
  } = {
    [NewEventSteps.EVENT_DETAILS]: {
      title: t(i18nKeys.newEvent.steps.eventDetails),
      content: newEditedEvent && <NewEventDetails target={newEditedEvent} />,
      isValid: () => {
        return (
          !_.isEmpty(newEditedEvent?.title) &&
          !_.isEmpty(newEditedEvent?.audienceType) &&
          !_.isEmpty(newEditedEvent?.startDate) &&
          !_.isEmpty(newEditedEvent?.endDate)
        );
      },
      getErrors: () => {
        const errors: string[] = [];
        if (_.isEmpty(newEditedEvent?.title)) {
          errors.push(t(i18nKeys.newEvent.errors.title));
        }
        if (_.isEmpty(newEditedEvent?.audienceType)) {
          errors.push(t(i18nKeys.newEvent.errors.audience));
        }
        if (_.isEmpty(newEditedEvent?.startDate)) {
          errors.push(t(i18nKeys.newEvent.errors.startDate));
        }
        if (_.isEmpty(newEditedEvent?.endDate)) {
          errors.push(t(i18nKeys.newEvent.errors.duration));
        }
        return errors;
      },
    },
    [NewEventSteps.ATTENDANCE_AND_TESTING]: {
      title: t(i18nKeys.newEvent.steps.attendanceAndTesting),
      content: newEditedEvent && <AttendanceAndTesting target={newEditedEvent} />,
      isValid: () => {
        return (
          !_.isEmpty(newEditedEvent?.minExpectedParticipants) && !_.isEmpty(newEditedEvent?.maxExpectedParticipants)
        );
      },
    },
    [NewEventSteps.TEAM_SETTINGS]: {
      title: t(i18nKeys.newEvent.steps.teamAssignmentSettings),
      content: newEditedEvent && <TeamSettings target={newEditedEvent} />,
      isValid: () => {
        return (
          newEditedEvent?.formTeamsMinsBeforeEventStart != null && newEditedEvent?.formTeamsMinsBeforeEventStart >= 0
        );
      },
      getErrors: () => {
        const errors: string[] = [];
        if (!newEditedEvent?.formTeamsMinsBeforeEventStart || newEditedEvent?.formTeamsMinsBeforeEventStart < 0) {
          errors.push(t(i18nKeys.newEvent.errors.teamFormingStartTime));
        }
        return errors;
      },
    },
    [NewEventSteps.OWNERS_AND_PERMISSIONS]: {
      title: t(i18nKeys.newEvent.steps.ownersAndPermissions),
      content: newEditedEvent && <OwnersAndPermissions target={newEditedEvent} />,
    },
    [NewEventSteps.CHALLENGES]: {
      title: t(i18nKeys.newEvent.steps.challenges),
      content: newEditedEvent && <NewEventChallenges target={newEditedEvent} />,
      isValid: () => {
        return !_.isEmpty(newEditedEvent?.challengeDescriptors);
      },
      getErrors: () => {
        const errors: string[] = [];
        if (_.isEmpty(newEditedEvent?.challengeDescriptors)) {
          errors.push(t(i18nKeys.events.eventDetails.messages.errors.selectChallenges));
        }
        return errors;
      },
    },
    [NewEventSteps.REVIEW_AND_CREATE]: {
      title: t(i18nKeys.newEvent.steps.reviewAndCreate),
      content: newEditedEvent && <ReviewAndCreate target={newEditedEvent} navigate={handleActiveStepChange} />,
    },
  };

  return (
    <React.Fragment>
      <ConfirmModal
        visible={confirmCancelVisible}
        message={<React.Fragment>{t(i18nKeys.newEvent.modal.message)}</React.Fragment>}
        cancelBtnLabel={t(i18nKeys.newEvent.modal.cancel)}
        confirmBtnLabel={t(i18nKeys.newEvent.modal.confirm)}
        onConfirm={handleCancel}
        onCancel={() => setConfirmCancelVisible(false)}
      />
      <ConfirmModal
        title={t(i18nKeys.events.templateUrls.modalTitle)}
        visible={notifyUsersAdded}
        message={
          <React.Fragment>
            {t(i18nKeys.events.templateUrls.message)}
            <div>
              <ul>
                {populatedFields.map((f) => (
                  <li key={f}>{f}</li>
                ))}
              </ul>
            </div>
          </React.Fragment>
        }
        cancelBtnLabel={t(i18nKeys.general.cancel)}
        confirmBtnLabel={t(i18nKeys.general.confirm)}
        onCancel={handleCancel}
        onConfirm={() => setNotifyUsersAdded(false)}
      />
      {!preloadingChallenges && (
        <Wizard
          className="mb-12"
          onCancel={() => promptModal()}
          i18nStrings={{
            stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
            collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
            cancelButton: t(i18nKeys.general.cancel),
            previousButton: t(i18nKeys.general.previous),
            nextButton: t(i18nKeys.general.next),
            submitButton: t(i18nKeys.events.eventDetails.buttons.createEventRequest),
            optional: t(i18nKeys.general.optional),
          }}
          activeStepIndex={activeStepIndex}
          steps={Object.keys(stepsDictionary).map((stepKey: string) => {
            return {
              title: stepsDictionary[stepKey as unknown as NewEventSteps].title,
              content: stepsDictionary[stepKey as unknown as NewEventSteps].content,
            };
          })}
          onNavigate={({ detail }) => {
            handleActiveStepChange(detail.requestedStepIndex);
          }}
          isLoadingNextStep={loading}
          onSubmit={handleCreateEvent}
        />
      )}
      {preloadingChallenges && (
        <MonkeySpinner/>
      )}
    </React.Fragment>
  );
};
export default NewEvent;
