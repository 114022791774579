/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  Box,
  Button,
  Container,
  ExpandableSection,
  Header,
  Pagination,
  SpaceBetween,
  Table,
  TextContent,
} from '@amzn/awsui-components-react';
import { Credentials } from 'aws-sdk';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TimeAgo from 'timeago-react';
import { useApi } from '../../../../store/api.context';
import { useChallenges } from '../../../../store/challenge.context';
import { useCreateChallenge } from '../../../../store/create-challenge.context';
import {
  Challenge,
  ChallengeReviewableSection,
  ChallengeUtils,
  TemplateScannerResponse,
} from '../../../../types/Challenge';
import { Nullable } from '../../../../types/common';
import { AccountCredentials } from '../../../../types/LabModels';
import { getEnvVar } from '../../../../utils/env-var.utils';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { preProdLogger } from '../../../../utils/log.utils';
import { uploadFile } from '../../../../utils/s3-utils';
import { safeString } from '../../../../utils/string.utils';
import AwsAccountCLIModal from '../../../common/Challenges/AwsAccountCLIModal';
import { CopyToClipboard } from '../../../common/CopyToClipboard';
import { ChallengeReviewPopover } from '../../challengesCommon/ChallengeReviewPopover';
import JamCodeEditor from '../../challengesCommon/JamCodeEditor';
import { useUser } from '../../../../store/user.context';

interface ChallengeResourcesDetailProps {
  challenge: Challenge;
}

const ChallengeResourcesDetail: React.FC<ChallengeResourcesDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { challengesApi } = useApi();
  const { challengeResources, getChallengeResources } = useChallenges();
  const { editMode } = useCreateChallenge();
  const { user } = useUser();

  const resourceBucket = getEnvVar('REACT_APP_CHALLENGE_RESOURCES_BUCKET_NAME');
  const resourcesBucketUrl = `https://${resourceBucket}.s3.amazonaws.com`;

  const [credentials, setCredentials] = useState<AccountCredentials | undefined>();
  const [templateScanResults, setTemplateScanResults] = useState('');
  const hiddenFileInput = React.createRef<HTMLInputElement>();

  const [visibleSTSCredentialsModal, setVisibleSTSCredentialsModal] = useState(false);

  useEffect(() => {
    if (ChallengeUtils.isCollaborator(challenge, user)) {
      const getSTSCreds = async () =>
        setCredentials(await challengesApi.getCredentialsToUploadChallengeResources(challenge.challengeId || ''));

      getSTSCreds().catch((err) => preProdLogger(err));
      getChallengeResources(challenge.challengeId || '');
    }
  }, []);

  const getResourcesBucketChallengePath = (challengeId: string): string => {
    return `${resourcesBucketUrl}/${challengeId}`;
  };

  const getS3Uri = (challengeId: string): string => {
    return `s3://${resourceBucket}/${challengeId}`;
  };

  const getS3SyncCommand = () => {
    return `aws s3 sync . ${getS3Uri(challenge.challengeId || '')}`;
  };

  const deleteChallengeResource = async (key: string) => {
    await challengesApi.deleteChallengeResource(challenge.challengeId || '', key);
  };

  const validateS3Template = () => {
    challengesApi
      .validateS3Template(challenge.challengeId || '', true)
      .then((response: TemplateScannerResponse) => setTemplateScanResults(response.result))
      .catch((err) => preProdLogger(err));
  };

  const onReceiveFiles = (files: File[]) => {
    challengesApi
      .getCredentialsToUploadChallengeResources(challenge.challengeId || '')
      .then((accountCredentials) => {
        const creds: Nullable<Credentials> = accountCredentials.toAWSCredentials();
        if (creds) {
          Promise.all(
            files.map((file) => {
              return uploadFile(creds, resourceBucket, file, challenge.challengeId);
            })
          ).catch((err) => preProdLogger(err));
        }
      })
      .catch((err) => preProdLogger(err));
  };

  return (
    <SpaceBetween direction="vertical" size="m">
      {(!editMode && challengeResources === undefined) ||
        (challengeResources.length === 0 && (
          <Container
            header={
              <Header
                variant="h2"
                actions={<ChallengeReviewPopover section={ChallengeReviewableSection.ASSETS_RESOURCES} />}>
                {t(i18nKeys.challenges.challengeDetails.headings.assetsResources)}
              </Header>
            }>
            <SpaceBetween direction="vertical" size="m">
              <Box padding={'l'} textAlign="center">
                <p>{t(i18nKeys.challenges.subSections.assetsResources.noResourceMessage)}</p>
                <Button onClick={() => getChallengeResources(challenge.challengeId || '')}>
                  {t(i18nKeys.challenges.subSections.assetsResources.refresh)}
                </Button>
              </Box>
            </SpaceBetween>
          </Container>
        ))}
      {!editMode && challengeResources.length > 0 && (
        <Table
          columnDefinitions={[
            {
              id: 'file',
              header: t(i18nKeys.challenges.challengeDetails.table.titles.fileName),
              cell: (e) => e.key,
            },
            {
              id: 'size',
              header: t(i18nKeys.challenges.challengeDetails.table.titles.size),
              cell: (e) => e.size,
              sortingField: 'size',
            },
            {
              id: 'lastModified',
              header: t(i18nKeys.challenges.challengeDetails.table.titles.lastModified),
              cell: (e) => (e.lastModified ? <TimeAgo datetime={safeString(e.lastModified.toString())} /> : '--'),
            },
          ]}
          items={challengeResources}
          loadingText={t(i18nKeys.challenges.challengeDetails.table.loadingResources)}
          empty={
            <Box textAlign="center" color="inherit">
              <b>{t(i18nKeys.challenges.subSections.assetsResources.noResourceMessage)}</b>
              <Box padding={{ bottom: 's' }} variant="p" color="inherit">
                {t(i18nKeys.challenges.subSections.assetsResources.noResourceMessage)}
              </Box>
              <Button>{t(i18nKeys.challenges.subSections.assetsResources.refresh)}</Button>
            </Box>
          }
          header={
            <Header actions={<ChallengeReviewPopover section={ChallengeReviewableSection.ASSETS_RESOURCES} />}>
              {t(i18nKeys.challenges.challengeDetails.table.headers.resources)}
            </Header>
          }
          pagination={
            <Pagination
              currentPageIndex={1}
              pagesCount={1}
              ariaLabels={{
                nextPageLabel: t(i18nKeys.tables.pagination.nextPage),
                previousPageLabel: t(i18nKeys.tables.pagination.prevPage),
                pageLabel: (pageNumber) => t(i18nKeys.tables.pagination.label, { count: pageNumber }),
              }}
            />
          }
        />
      )}
      {editMode && (
        <Container
          header={
            <Header
              variant="h2"
              actions={<ChallengeReviewPopover section={ChallengeReviewableSection.ASSETS_RESOURCES} />}>
              {t(i18nKeys.challenges.challengeDetails.headings.assetsResources)}
            </Header>
          }>
          <SpaceBetween size="s">
            <TextContent>
              <i>{t(i18nKeys.challenges.subSections.assetsResources.noResourceMessage)}</i>
            </TextContent>
            <SpaceBetween direction="horizontal" size="xs">
              <Button onClick={() => getChallengeResources(challenge.challengeId || '')}>
                {t(i18nKeys.challenges.subSections.assetsResources.refresh)}
              </Button>
              <Button variant="primary" onClick={validateS3Template}>
                {t(i18nKeys.challenges.subSections.assetsResources.validateTemplates)}
              </Button>
            </SpaceBetween>
            <Box className="gray-box" padding={'l'} textAlign="center">
              <p>{t(i18nKeys.challenges.subSections.assetsResources.dropFiles)}</p>
              <input
                ref={hiddenFileInput}
                id="chooseFileInput"
                type="file"
                hidden
                multiple
                accept="any"
                // @ts-ignore
                onChange={(event) => onReceiveFiles(event.target.files)}
              />
              <Button
                iconName="upload"
                variant="link"
                formAction="none"
                onClick={() => {
                  // @ts-ignore
                  hiddenFileInput.current.value = null; // allow for re-choosing the same file name
                  // @ts-ignore
                  hiddenFileInput.current.click();
                }}>
                {t(i18nKeys.challenges.subSections.assetsResources.browseFiles)}
              </Button>
            </Box>
          </SpaceBetween>
        </Container>
      )}
      <ExpandableSection
        variant="container"
        header={<Header variant="h2">{t(i18nKeys.challenges.challengeDetails.headings.cliInstructions)}</Header>}>
        <TextContent>
          <ol>
            <li>{t(i18nKeys.challenges.subSections.assetsResources.cliInstructions.step1)}</li>
            <li>
              {t(i18nKeys.challenges.subSections.assetsResources.cliInstructions.step2Copy)}
              <Button onClick={() => setVisibleSTSCredentialsModal(true)} variant="link">
                {t(i18nKeys.challenges.subSections.assetsResources.cliInstructions.step2Credentials)}
              </Button>
              {t(i18nKeys.challenges.subSections.assetsResources.cliInstructions.step2Terminal)}
            </li>
            <li>
              {t(i18nKeys.challenges.subSections.assetsResources.cliInstructions.step3)}
              <CopyToClipboard value={getS3SyncCommand()} icon />
              {getS3SyncCommand()}
            </li>
            <li>
              <SpaceBetween direction="horizontal" size="s">
                {t(i18nKeys.challenges.subSections.assetsResources.cliInstructions.step4)}
                <span className="badge-aws badge-aws-light">
                  {getResourcesBucketChallengePath(challenge.challengeId || '')}
                </span>
              </SpaceBetween>
            </li>
          </ol>
        </TextContent>
      </ExpandableSection>
      {editMode && templateScanResults.length > 0 && (
        <ExpandableSection
          variant="container"
          header={<Header>{t(i18nKeys.challenges.challengeDetails.headings.templateSecurityFindings)}</Header>}>
          <JamCodeEditor
            language="yaml"
            value={templateScanResults}
            setValue={() => {
              preProdLogger('none');
            }}
          />
        </ExpandableSection>
      )}
      {credentials && (
        <AwsAccountCLIModal
          visible={visibleSTSCredentialsModal}
          onCancel={() => setVisibleSTSCredentialsModal(false)}
          onConfirm={() => setVisibleSTSCredentialsModal(false)}
          credentials={credentials}
        />
      )}
    </SpaceBetween>
  );
};

export default ChallengeResourcesDetail;
