import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Link, Modal, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import { i18nKeys } from '../../utils/i18n.utils';
import { useApi } from '../../store/api.context';
import { TermsAndConditionsStatus } from '../../types/TermsAndConditionsStatus';
import { useState } from 'react';

export interface TermsAndConditionsModalProps {
  /**
   * The user's terms and conditions status.
   */
  readonly termsStatus: TermsAndConditionsStatus;

  /**
   * Called after the user accepts the terms and conditions and the modal closes.
   */
  readonly afterAcceptTerms: () => void;
}

/**
 * Modal to display a disclaimer linking to the Jam console terms and conditions
 * and allow the user to accept the terms and conditions.
 */
export const TermsAndConditionsModal: React.FC<TermsAndConditionsModalProps> = (props) => {
  const { t } = useTranslation();
  const { accountApi } = useApi();

  /* Whether the user viewed the terms and conditions by clicking the link. */
  const [viewedTerms, setViewedTerms] = useState(false);
  /* Whether there is currently a request being made to accept the terms and conditions. */
  const [acceptingTerms, setAcceptingTerms] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  /**
   * Function called when the user views the terms and conditions.
   */
  const onViewTerms = () => {
    setViewedTerms(true);
  };

  const acceptTerms = () => {
    try {
      setAcceptingTerms(true);
      void accountApi.acceptTermsAndConditions();
      props.afterAcceptTerms();
      setIsVisible(false);
    } catch (err) {
      setAcceptingTerms(false);
      setIsVisible(false);
    }
  };

  return (
    <Modal
      visible={isVisible}
      header={t(i18nKeys.account.termsAndConditions.header)}
      footer={
        <SpaceBetween direction="horizontal" size="xs">
          <Button variant="primary" loading={acceptingTerms} disabled={!viewedTerms} onClick={acceptTerms}>
            {acceptingTerms
              ? t(i18nKeys.account.termsAndConditions.continuing)
              : t(i18nKeys.account.termsAndConditions.continueToConsole)}
          </Button>
          {viewedTerms ? null : (
            <StatusIndicator type="warning">
              <strong>{t(i18nKeys.account.termsAndConditions.mustReviewTerms)}</strong>
            </StatusIndicator>
          )}
        </SpaceBetween>
      }>
      <React.Fragment>
        <p>{t(i18nKeys.account.termsAndConditions.disclaimerPart1)}</p>
        {t(i18nKeys.account.termsAndConditions.disclaimerPart2)}{' '}
        <span onClick={onViewTerms} onAuxClick={onViewTerms}>
          <Link external href={props.termsStatus.termsAndConditionsUrl}>
            {t(i18nKeys.account.termsAndConditions.disclaimerPart3)}.
          </Link>
        </span>
      </React.Fragment>
    </Modal>
  );
};
