import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Popover } from '@amzn/awsui-components-react';
import { copyToClipboard } from '../../utils/copy-to-clipboard';
import { ReactNode } from 'react';
import { i18nKeys } from '../../utils/i18n.utils';

interface CopyToClipboardProps {
  value: string;
  icon?: boolean;
  successMessage?: string;
  buttonLabel?: string;
}

export const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  value,
  icon = false,
  successMessage,
  buttonLabel,
}) => {
  const { t } = useTranslation();

  successMessage = successMessage || t(i18nKeys.clipboard.successMessage);
  buttonLabel = buttonLabel || t(i18nKeys.clipboard.buttonLabel);

  const popoverContent: ReactNode = (
    <span className="awsui-util-status-positive">
      <Icon variant="success" name="status-positive" /> {successMessage}
    </span>
  );

  return (
    <span className={icon ? 'awsui-util-copy-text' : ''}>
      <Popover size="small" position="top" triggerType="custom" dismissButton={false} content={popoverContent}>
        <Button
          iconName="copy"
          variant={icon ? 'icon' : 'normal'}
          ariaLabel={buttonLabel}
          onClick={() => copyToClipboard(value)}>
          {buttonLabel}
        </Button>
      </Popover>
    </span>
  );
};
