import { Box, Button, Container, Header, Icon, Select, SpaceBetween } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChallengeBoard } from '../../../store/challenge-board.context';
import { useChallenges } from '../../../store/challenge.context';
import { EditEventActions, EventEdit, useEditEvent } from '../../../store/edit-event.context';
import { ChallengeDescriptor } from '../../../types/Challenge';
import { ChallengeBoard, ChallengeBoardPosition } from '../../../types/ChallengeBoard';
import { Nullable } from '../../../types/common';
import { Event } from '../../../types/Event';
import { i18nKeys } from '../../../utils/i18n.utils';
import { HorizontalRule } from '../HorizontalRule';
import { KeyValue } from '../KeyValue';
import { LoadingBar } from '../LoadingBar';
import GameBoardGrid from './GameBoardGrid';

interface GameBoardProps {
  target: Event;
}

const GameBoard: React.FC<GameBoardProps> = ({ target }) => {
  const { t } = useTranslation();
  const NO_SELECTED_OPTION: OptionDefinition = {
    label: `-- ${t(i18nKeys.general.none)} --`,
  };
  const { loadChallengeBoards, challengeBoards } = useChallengeBoard();
  const { bulkHandleUpdateEditEvent } = useEditEvent();
  const {
    challengeDescriptors,
    updateGameBoardPositions,
    getChallengeDescriptor,
    getChallengeListItemFromChallengeId,
    updateChallengeDescriptors,
  } = useChallenges();
  const [selectedOption, setSelectedOption] = useState<OptionDefinition>(NO_SELECTED_OPTION);
  const [selectedGameBoard, setSelectedGameBoard] = useState<Nullable<ChallengeBoard>>(null);
  const [challengeBoardOptions, setChallengeBoardOptions] = useState<OptionDefinition[]>([]);

  const generateOptionDefinitions = () => {
    const newOptions: OptionDefinition[] = [NO_SELECTED_OPTION];
    challengeBoards?.forEach((challengeBoard: ChallengeBoard) => {
      newOptions.push({
        label: `${challengeBoard.name} ${getBoardSizeLabel(challengeBoard)}` || '',
        value: challengeBoard.id || '',
      });
    });
    setChallengeBoardOptions(_.clone(newOptions));
  };

  const getBoardSizeLabel = (challengeBoard: ChallengeBoard) => {
    return t(i18nKeys.challenges.gameBoard.labels.rowsColumns, {
      numChallenges: challengeBoard.numChallenges,
      numColumns: challengeBoard.numCategories,
      numRows: challengeBoard.columns[0].challengeIds.length,
    });
  };

  /**
   * On load checks to see if event has game board, populates selection
   */
  useEffect(() => {
    void loadChallengeBoards();
    if (target.challengeBoards) {
      setSelectedGameBoard(target.challengeBoards[0]);
    }
  }, []);

  /**
   * Generates option definitions once challengeboards are loaded
   */
  useEffect(() => {
    generateOptionDefinitions();
  }, [challengeBoards]);

  /**
   * When an option is selected, grabs the gameBoard and sets the state appropriately
   */
  useEffect(() => {
    if (challengeBoards && selectedOption.value) {
      const challengeBoardIndex = challengeBoards?.findIndex((challengeBoard: ChallengeBoard) => {
        return challengeBoard.id === selectedOption?.value;
      });
      setSelectedGameBoard(challengeBoards[challengeBoardIndex]);
    }
  }, [selectedOption]);

  const handleGameBoardSelection = (newSelectedGameBoard: OptionDefinition) => {
    setSelectedOption(newSelectedGameBoard);
  };

  /**
   * Adds challenge board to event, as well as challenge descriptors
   *
   * @returns void
   */
  const addChallengeBoardToEvent = () => {
    if (selectedOption === NO_SELECTED_OPTION) {
      return;
    }

    if (!target) {
      return;
    }

    if (!selectedGameBoard) {
      return;
    }

    // Start storing event edits for bulk edit
    const edits: EventEdit[] = [];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    edits.push({ action: EditEventActions.CHALLENGE_BOARDS, payload: [selectedGameBoard] });
    const newGameBoardPositions: { [id: string]: Nullable<ChallengeBoardPosition> } = {};

    // remove all board challenges from the event
    const eventChallenges: ChallengeDescriptor[] = _.clone(target.challengeDescriptors);
    (selectedGameBoard.challengeIds || []).forEach((challengeId) =>
      eventChallenges.filter((cd: ChallengeDescriptor) => {
        return cd.challengeId === challengeId;
      })
    );

    // remove the challenge descriptors for all board challenges (to reset any overrides)
    const updatedChallengeDescriptors: { [id: string]: ChallengeDescriptor } | undefined =
      _.clone(challengeDescriptors);
    (selectedGameBoard.challengeIds || []).forEach((challengeId) => {
      if (updatedChallengeDescriptors && updatedChallengeDescriptors[challengeId]) {
        delete updatedChallengeDescriptors[challengeId];
      }
    });

    updateChallengeDescriptors(updatedChallengeDescriptors || {});

    // add all board challenges to the event
    (selectedGameBoard.challengeIds || []).forEach((challengeId: string) => {
      const challengeItem = getChallengeListItemFromChallengeId(challengeId);
      if (challengeItem) {
        const challengeDescriptor = getChallengeDescriptor(challengeItem);
        if (challengeDescriptor) {
          eventChallenges.push(challengeDescriptor);
          newGameBoardPositions[challengeId] = selectedGameBoard.getBoardPosition(challengeId);
        }
      }
    });
    edits.push({
      action: EditEventActions.CHALLENGE_DESCRIPTORS,
      payload: [
        ...target.challengeDescriptors,
        ...ChallengeDescriptor.filterNonUniqueChallengeDescriptorsFromOriginal(
          target.challengeDescriptors,
          eventChallenges
        ),
      ],
    });
    bulkHandleUpdateEditEvent(edits);
    updateGameBoardPositions(newGameBoardPositions);
  };

  /**
   * Removes challenge board reference from event, challenge descriptors from challengeboard, and resets state
   *
   * @returns void
   */
  const removeChallengeBoardFromEvent = () => {
    const gameBoard = target.challengeBoards[0];
    if (!gameBoard) {
      return;
    }
    if (!target) {
      return;
    }

    // Remove Challenge Board from event
    const edits: EventEdit[] = [];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    edits.push({ action: EditEventActions.CHALLENGE_BOARDS, payload: null });

    // Reset Game Board Positions
    updateGameBoardPositions({});

    // remove all board challenges from the event
    let updatedEventChallengeDescriptors: ChallengeDescriptor[] = _.clone(target.challengeDescriptors);
    (gameBoard.challengeIds || []).forEach(
      (challengeId) =>
        (updatedEventChallengeDescriptors = updatedEventChallengeDescriptors.filter((cd: ChallengeDescriptor) => {
          return cd.challengeId !== challengeId;
        }))
    );
    edits.push({ action: EditEventActions.CHALLENGE_DESCRIPTORS, payload: updatedEventChallengeDescriptors });
    bulkHandleUpdateEditEvent(edits);

    // remove the challenge descriptors for all board challenges (to reset any overrides)
    const updatedChallengeDescriptors = _.clone(challengeDescriptors) || {};
    (gameBoard.challengeIds || []).forEach((challengeId) => {
      if (updatedChallengeDescriptors && updatedChallengeDescriptors[challengeId]) {
        delete updatedChallengeDescriptors[challengeId];
      }
    });
    updateChallengeDescriptors(updatedChallengeDescriptors);
    // Remove selected game board from state
    setSelectedGameBoard(null);
    setSelectedOption(NO_SELECTED_OPTION);
  };

  return (
    <Container
      header={
        <Header
          actions={[
            <Button variant="primary" key="create-new-game-board" href="/challenges/boards/new">
              <Icon className="mr-5" name="add-plus" />
              {t(i18nKeys.challenges.gameBoard.buttons.createNewGameBoard)}
            </Button>,
          ]}
          variant="h2"
          description={t(i18nKeys.challenges.gameBoard.headers.gameBoard.description)}>
          {t(i18nKeys.challenges.gameBoard.headers.gameBoard.title)}
        </Header>
      }>
      {!selectedGameBoard && (
        <KeyValue label={t(i18nKeys.challenges.gameBoard.labels.chooseAnExistingGameBoard)}>
          {!challengeBoards && <LoadingBar />}
          {challengeBoards && (
            <Select
              selectedOption={selectedOption}
              className="long-select-input"
              options={challengeBoardOptions}
              onChange={({ detail }) => handleGameBoardSelection(detail.selectedOption)}
            />
          )}
        </KeyValue>
      )}
      {selectedGameBoard && (
        <SpaceBetween direction="vertical" size="s">
          <Box>
            <strong>{t(i18nKeys.challenges.gameBoard.labels.selectedGameBoard)}</strong>{' '}
            {`${selectedGameBoard.name}${getBoardSizeLabel(selectedGameBoard)}`}
          </Box>
          <GameBoardGrid gameBoard={selectedGameBoard} />
          <HorizontalRule />
          <Box float="right">
            {!target.challengeBoards || target.challengeBoards.length < 1 ? (
              <Button variant="primary" onClick={() => addChallengeBoardToEvent()}>
                {t(i18nKeys.challenges.gameBoard.buttons.addToEvent)}
              </Button>
            ) : (
              <Button variant="normal" onClick={() => removeChallengeBoardFromEvent()}>
                {t(i18nKeys.challenges.gameBoard.buttons.removeFromEvent)}
              </Button>
            )}
          </Box>
        </SpaceBetween>
      )}
    </Container>
  );
};

export default GameBoard;
