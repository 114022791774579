import { SpaceBetween } from '@amzn/awsui-components-react';
import * as React from 'react';
import { Challenge } from '../../../../types/Challenge';
import ChallengeLatestFeedbackTrendDetail from './LatestFeedbackTrend';
import ChallengeReviewsDetail from './Reviews';

interface ChallengeFeedbackDetailsProps {
  challenge: Challenge;
}

const ChallengeFeedbackDetails: React.FC<ChallengeFeedbackDetailsProps> = ({ challenge }) => {
  return (
    <SpaceBetween direction="vertical" size="m">
      <ChallengeLatestFeedbackTrendDetail challenge={challenge} />
      <ChallengeReviewsDetail challenge={challenge} />
    </SpaceBetween>
  );
};

export default ChallengeFeedbackDetails;
