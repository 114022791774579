import * as React from 'react';
import { getTableFilterMatcher, TableFilterMatcher } from '../../utils/table.utils';
import { Link } from 'react-router-dom';
import { Event, getEventListStatus } from '../../types/Event';
import { i18nKeys } from '../../utils/i18n.utils';
import { TFunctionProvider } from '../common/TFunctionProvider';
import { StatusBadge } from '../common/StatusBadge';
import { DateTime, DateTimeKeys } from '../common/DateTime';
import LabStatusCountdown from './eventsCommon/labStatusCountdown/LabStatusCountdown';
import { Badge, CollectionPreferencesProps } from '@amzn/awsui-components-react';
import { EVENT_DETAILS_ROUTES } from '../../routes';
import { toTitleCase } from '../../utils/string.utils';
import { EventAudienceType } from '@amzn/aws-jam-constants';
import { useTranslation } from 'react-i18next';

export const filteringFunction = (item: Event, filteringText: string): boolean => {
  const matcher: TableFilterMatcher = getTableFilterMatcher(filteringText);
  return (
    matcher.filterEmpty ||
    matcher.isMatch(item.title) ||
    matcher.isMatch(item.audienceType) ||
    matcher.isMatch(item.name) ||
    matcher.isMatch(item.type) ||
    matcher.isMatch(getEventListStatus(item)) ||
    matcher.isMatch(item.startDate) ||
    matcher.isMatch(item.endDate)
  );
};

export const COLUMN_DEFINITIONS = (preferences: CollectionPreferencesProps.Preferences) => {
  const audienceTypeDictionary: { [key: string]: React.ReactElement } = {
    [EventAudienceType.GENERAL_PUBLIC]: (
      <TFunctionProvider>
        {(translate) => translate(i18nKeys.events.eventDetails.labels.eventAudienceType.generalPublic)}
      </TFunctionProvider>
    ),
    [EventAudienceType.AWS_PRIVATE_ENGAGEMENT]: (
      <TFunctionProvider>
        {(translate) => translate(i18nKeys.events.eventDetails.labels.eventAudienceType.awsPrivateEngagement)}
      </TFunctionProvider>
    ),
    [EventAudienceType.AWS_BOOTCAMP]: (
      <TFunctionProvider>
        {(translate) => translate(i18nKeys.events.eventDetails.labels.eventAudienceType.awsBootCamp)}
      </TFunctionProvider>
    ),
    [EventAudienceType.AWS_INTERNAL]: (
      <TFunctionProvider>
        {(translate) => translate(i18nKeys.events.eventDetails.labels.eventAudienceType.awsInternal)}
      </TFunctionProvider>
    ),
    [EventAudienceType.CUSTOMER_INTERNAL]: (
      <TFunctionProvider>
        {(translate) => translate(i18nKeys.events.eventDetails.labels.eventAudienceType.customerInternal)}
      </TFunctionProvider>
    ),
    [EventAudienceType.TESTERS]: (
      <TFunctionProvider>
        {(translate) => translate(i18nKeys.events.eventDetails.labels.eventAudienceType.testers)}
      </TFunctionProvider>
    ),
    [EventAudienceType.INDIVIDUAL]: (
      <TFunctionProvider>
        {(translate) => translate(i18nKeys.events.eventDetails.labels.eventAudienceType.individual)}
      </TFunctionProvider>
    ),
  };
  return [
    {
      id: 'title',
      sortingField: 'title',
      header: <TFunctionProvider>{(translate) => translate(i18nKeys.events.fields.title.title)}</TFunctionProvider>,
      cell: (item: Event) => (
        <div>
          <Link to={EVENT_DETAILS_ROUTES.Summary.resolve(item?.name ?? '')}>{item?.title}</Link>
          <div>{toTitleCase(item?.type)}</div>
        </div>
      ),
      minWidth: 260,
      allowLineWrap: true,
    },
    {
      id: 'audience',
      sortingField: 'audienceType',
      header: <TFunctionProvider>{(translate) => translate(i18nKeys.events.fields.audience.title)}</TFunctionProvider>,
      cell: (item: Event) => item.audienceType && <div>{audienceTypeDictionary[item.audienceType]}</div>,
      minWidth: 180,
      allowLineWrap: false,
    },
    {
      id: 'tags',
      sortingField: 'tags',
      header: <TFunctionProvider>{(translate) => translate(i18nKeys.events.fields.tags.title)}</TFunctionProvider>,
      cell: (item: Event) => (
        <React.Fragment>
          {item?.tags?.map((tag, key) => {
            return (
              <div style={{ fontSize: '12px', marginBottom: '5px' }} key={key}>
                <Badge>{tag}</Badge>
              </div>
            );
          })}
        </React.Fragment>
      ),
      minWidth: 156,
      allowLineWrap: false,
    },
    {
      id: 'startDate',
      sortingField: 'startDate',
      header: <TFunctionProvider>{(translate) => translate(i18nKeys.events.fields.startDate.title)}</TFunctionProvider>,
      cell: (item: Event) => (
        <DateTime
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          key={preferences.custom}
          startOrEnd={DateTimeKeys.START}
          event={item}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          timezoneFormat={preferences.custom}
        />
      ),
      minWidth: 215,
      allowLineWrap: false,
    },
    {
      id: 'endDate',
      sortingField: 'endDate',
      header: <TFunctionProvider>{(translate) => translate(i18nKeys.events.fields.endDate.title)}</TFunctionProvider>,
      cell: (item: Event) => (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        <DateTime startOrEnd={DateTimeKeys.END} event={item} timezoneFormat={preferences.custom} />
      ),
      minWidth: 215,
      allowLineWrap: false,
    },
    {
      id: 'status',
      sortingField: 'status',
      header: <TFunctionProvider>{(translate) => translate(i18nKeys.events.fields.status.title)}</TFunctionProvider>,
      cell: (item: Event) => (
        <div>
          <StatusBadge status={getEventListStatus(item)} />
          <LabStatusCountdown
            isTest={item?.test}
            includeSeconds={false}
            status={getEventListStatus(item)}
            labStartTime={item.labStartTime}
            labEndTime={item.labEndTime}
          />
          {item?.testCloneCode && (
            <LabStatusCountdown
              includeSeconds={false}
              isTest
              status={getEventListStatus(item)}
              labStartTime={item.labStartTime}
              labEndTime={item.labEndTime}
            />
          )}
        </div>
      ),
      minWidth: 242,
      allowLineWrap: false,
    },
  ];
};
