import { Button, Icon, SpaceBetween, Textarea } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { Comment } from '../../../types/common';
import { getTimeInBrowserLocalTime } from '../../../utils/event-time.utils';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import { useUser } from '../../../store/user.context';
import { ConfirmModal } from '../ConfirmModal';
import { i18nKeys } from '../../../utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { Markdown } from '../Markdown';

interface CommentProps {
  commentDetails: Comment;
  key: string;
  updateComment: (id: string, value: string) => Promise<void>;
  deleteComment: (id: string) => Promise<void>;
}

const DetailedComment: React.FC<CommentProps> = ({ commentDetails, updateComment, deleteComment }) => {
  const { user } = useUser();
  const [editing, setEditing] = useState(false);
  const [editedComment, setEditedComment] = useState<string>('');
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const { t } = useTranslation();

  const canEditComment = () => {
    return commentDetails.author === user?.email;
  };

  const cancelEdit = () => {
    setEditing(false);
    setEditedComment('');
  };

  const saveEditedComment = () => {
    if (commentDetails.id && editedComment) {
      void updateComment(commentDetails.id, editedComment);
      cancelEdit();
    }
  };

  const handleDeleteComment = () => {
    if (commentDetails?.id) {
      void deleteComment(commentDetails.id);
      setConfirmDeleteVisible(false);
    }
  };

  const trashSVG = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path
        fill="currentColor"
        d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM394.8 466.1C393.2 492.3 372.3 512 346.9 512H101.1C75.75 512 54.77 492.3 53.19 466.1L31.1 128H416L394.8 466.1z"
      />
    </svg>
  );

  useEffect(() => {
    if (commentDetails.value) {
      setEditedComment(commentDetails.value);
    }
  }, [editing]);

  return (
    <div>
      <ConfirmModal
        visible={confirmDeleteVisible}
        message={t(i18nKeys.general.areYouSure)}
        confirmBtnLabel={t(i18nKeys.events.eventDetails.buttons.yesDeleteComment)}
        onConfirm={handleDeleteComment}
        onCancel={() => setConfirmDeleteVisible(false)}
      />
      <Icon name="user-profile" />
      <strong>{commentDetails.author}</strong>
      {canEditComment() && (
        <div style={{ float: 'right' }}>
          {!editing && (
            <div>
              <SpaceBetween size="s" direction="horizontal">
                <span
                  style={{ color: awsui.colorBackgroundControlChecked }}
                  title={t(i18nKeys.general.edit)}
                  onClick={() => setEditing(!editing)}>
                  <Icon name="edit" />
                </span>
                <span
                  style={{ color: awsui.colorTextStatusError }}
                  title={t(i18nKeys.general.delete)}
                  onClick={() => setConfirmDeleteVisible(true)}>
                  <Icon svg={trashSVG} />
                </span>
              </SpaceBetween>
            </div>
          )}
          {editing && (
            <div>
              <SpaceBetween size="s" direction="horizontal">
                <Button variant="link" onClick={() => cancelEdit()}>
                  {t(i18nKeys.general.cancel)}
                </Button>
                <Button variant="primary" onClick={saveEditedComment}>
                  {t(i18nKeys.general.save)}
                </Button>
              </SpaceBetween>
            </div>
          )}
        </div>
      )}
      {!commentDetails.updatedAt && (
        <div style={{ color: awsui.colorTextFormSecondary }}>
          {getTimeInBrowserLocalTime(commentDetails.createdAt, {
            includeDate: true,
            includeTime: true,
            includeComma: true,
          })}
        </div>
      )}
      {commentDetails.updatedAt && (
        <div style={{ color: awsui.colorTextFormSecondary }}>
          {t(i18nKeys.events.eventDetails.labels.edited)}
          {getTimeInBrowserLocalTime(commentDetails.updatedAt, {
            includeDate: true,
            includeTime: true,
            includeComma: true,
          })}
        </div>
      )}
      {!editing && <Markdown content={commentDetails.value || ''} />}
      {editing && <Textarea value={editedComment} onChange={({ detail }) => setEditedComment(detail.value)} />}
    </div>
  );
};

export default DetailedComment;
