import { useCollection } from '@amzn/awsui-collection-hooks';
import { Pagination, Table, TableProps, TextFilter } from '@amzn/awsui-components-react';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChallenges } from '../../../store/challenge.context';
import { useSplitPanel } from '../../../store/split-panel.context';
import { ChallengeDescriptor, ChallengeListItem, ChallengeStatus } from '../../../types/Challenge';
import { i18nKeys } from '../../../utils/i18n.utils';
import { TableEmptyState } from '../TableEmptyState';
import { TableHeader } from '../TableHeader';
import { COLUMN_DEFINITIONS, filteringFunction } from './browse-challenges-list-config';
import BrowseChallengesSplitPanel from './BrowseChallengesSplitPanel';
import { preProdLogger } from '../../../utils/log.utils';

interface BrowseChallengesProps {
  handleChallengeAction?: (payload: ChallengeDescriptor[]) => void;
  toggleChallengeInfo: (challengeListItem: ChallengeListItem) => void;
  currentChallengeDescriptors: ChallengeDescriptor[];
  editMode?: boolean;
  displayChallenges?: string[];
  showSelectedSplitPanel?: boolean;
  actionButtons?: ReactNode;
  excludedChallenges?: ChallengeDescriptor[];
}
const BrowseChallenges: React.FC<BrowseChallengesProps> = ({
  handleChallengeAction,
  currentChallengeDescriptors,
  toggleChallengeInfo,
  editMode = true,
  displayChallenges = [],
  showSelectedSplitPanel = true,
  actionButtons,
  excludedChallenges = [],
}) => {
  const { challengeListItems, getChallenges, getChallengeDescriptorsToCopy, challengeWrapperMap } = useChallenges();
  const [filteredChallenges, setFilteredChallenges] = useState<ChallengeListItem[] | undefined>([]);
  const [selectedChallenges, setSelectedChallenges] = useState<ChallengeListItem[]>([]);
  const { renderSplitPanelContent, toggleShowSplitPanel } = useSplitPanel();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    getChallenges(false, false, true)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        preProdLogger('Error loading challenges', err);
      });
    if (showSelectedSplitPanel) {
      if (selectedChallenges.length > 0) {
        toggleShowSplitPanel(true);
        renderSplitPanelContent(
          t(i18nKeys.challenges.browseChallenges.selectedChallenges, {
            selectedChallengeCount: selectedChallenges.length,
          }),
          <BrowseChallengesSplitPanel selectedChallenges={selectedChallenges || []} />
        );
      } else {
        toggleShowSplitPanel(false);
      }
    }
  }, [selectedChallenges]);

  useEffect(() => {
    populateSelectedChallengesFromCurrentDescriptors();
  }, [currentChallengeDescriptors]);

  const lastUsedColumnIndex = COLUMN_DEFINITIONS(toggleChallengeInfo, editMode).findIndex(
    (columnDef: TableProps.ColumnDefinition<ChallengeListItem>) => columnDef.id === 'last-used'
  );

  useEffect(() => {
    filterChallenges();
  }, [challengeListItems, editMode]);

  const filterChallenges = () => {
    let newFilteredChallenges: ChallengeListItem[] | undefined = challengeListItems;

    if (displayChallenges.length > 0 && !editMode) {
      newFilteredChallenges = newFilteredChallenges?.filter((challenge) =>
        displayChallenges.includes(challenge.challengeId || '')
      );
    }
    if (editMode) {
      newFilteredChallenges = newFilteredChallenges
        ?.filter((challenge) => challenge.status === ChallengeStatus.APPROVED)
        .filter(
          (challenge) => challenge.challengeId && !(challengeWrapperMap[challenge.challengeId] || challenge).isPrivate
        )
        .filter(
          (challenge) =>
            challenge.challengeId && (challengeWrapperMap[challenge.challengeId] || challenge).isEligibleForEvents()
        );
    }

    if (excludedChallenges.length > 0) {
      newFilteredChallenges = newFilteredChallenges?.filter((challenge) => {
        const foundChallengeIndex = excludedChallenges.findIndex(
          (excludedChallenge: ChallengeDescriptor) => excludedChallenge.challengeId === challenge.challengeId
        );
        return foundChallengeIndex < 0;
      });
    }
    setFilteredChallenges(newFilteredChallenges);

    setLoading(false);
  };

  const [preferences] = useState({
    pageSize: 10,
    visibleColumns: ['title', 'type'],
  });

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    filteredChallenges || [],
    {
      filtering: {
        filteringFunction: filteringFunction(t),
        empty: (
          <TableEmptyState
            title={t(i18nKeys.challenges.list.empty.title)}
            subtitle={t(i18nKeys.challenges.list.empty.subtitle)}
          />
        ),
        noMatch: (
          <TableEmptyState
            title={t(i18nKeys.tables.noMatch.title)}
            subtitle={t(i18nKeys.tables.noMatch.subtitle)}
            onClearFilter={() => actions.setFiltering('')}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {
        defaultState: {
          sortingColumn: COLUMN_DEFINITIONS(toggleChallengeInfo)[lastUsedColumnIndex],
          isDescending: false,
        },
      },
    }
  );

  const handleChallengeSelection = (selectedChallengeItems: ChallengeListItem[]) => {
    setSelectedChallenges(selectedChallengeItems);
    if (handleChallengeAction) {
      handleChallengeAction(getChallengeDescriptorsToCopy(currentChallengeDescriptors, selectedChallengeItems));
    }
    if (showSelectedSplitPanel) {
      renderSplitPanelContent(
        t(i18nKeys.challenges.browseChallenges.selectedChallenges, {
          selectedChallengeCount: selectedChallengeItems.length,
        }),
        <BrowseChallengesSplitPanel selectedChallenges={selectedChallengeItems} />
      );
    }
  };

  const populateSelectedChallengesFromCurrentDescriptors = () => {
    const newSelectedChallenges: ChallengeListItem[] = [];
    currentChallengeDescriptors.map((challenge) => {
      if (challenge.challengeId) {
        const challengeIndex = challengeListItems?.findIndex((item) => item.challengeId === challenge.challengeId);
        if (challengeIndex && challengeListItems) {
          newSelectedChallenges.push(challengeListItems[challengeIndex]);
        }
      }
    });
    setSelectedChallenges(newSelectedChallenges);
  };

  return (
    <React.Fragment>
      {editMode ? (
        <Table
          {...collectionProps}
          variant="full-page"
          stickyHeader
          header={
            <TableHeader
              totalItems={filteredChallenges?.length || 0}
              selectedItems={selectedChallenges}
              showZero
              title={t(i18nKeys.challenges.title)}
            />
          }
          items={items || []}
          columnDefinitions={COLUMN_DEFINITIONS(toggleChallengeInfo)}
          pagination={<Pagination {...paginationProps} />}
          selectionType="multi"
          loading={loading}
          resizableColumns
          loadingText={t(i18nKeys.challenges.loadingChallenges)}
          selectedItems={selectedChallenges}
          onSelectionChange={({ detail }) => handleChallengeSelection(detail.selectedItems)}
          filter={
            <TextFilter
              className="text-filter"
              filteringPlaceholder={t(i18nKeys.challenges.filter.searchChallenges)}
              {...filterProps}
              countText={t(i18nKeys.tables.matchesCount, { count: filteredItemsCount })}
              filteringAriaLabel={t(i18nKeys.challenges.filter.filteringLabel)}
            />
          }
        />
      ) : (
        <Table
          {...collectionProps}
          variant="full-page"
          stickyHeader
          header={
            <TableHeader
              totalItems={filteredChallenges?.length || 0}
              title={t(i18nKeys.challenges.title)}
              actionButtons={actionButtons}
            />
          }
          items={items || []}
          columnDefinitions={COLUMN_DEFINITIONS(toggleChallengeInfo, editMode)}
          pagination={<Pagination {...paginationProps} />}
          loading={loading}
          resizableColumns
          loadingText={t(i18nKeys.challenges.loadingChallenges)}
          filter={
            <TextFilter
              className="text-filter"
              filteringPlaceholder={t(i18nKeys.challenges.filter.searchChallenges)}
              {...filterProps}
              countText={t(i18nKeys.tables.matchesCount, { count: filteredItemsCount })}
              filteringAriaLabel={t(i18nKeys.challenges.filter.filteringLabel)}
            />
          }
        />
      )}
    </React.Fragment>
  );
};

export default BrowseChallenges;
