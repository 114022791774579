import React from 'react';
import { CollectionPreferencesProps, Icon, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { UserRow } from '../../../types/UserRow';
import { i18nKeys } from '../../../utils/i18n.utils';
import { getTableFilterMatcher, TableFilterMatcher } from '../../../utils/table.utils';
import { useEvents } from '../../../store/events.context';
import { useUser } from '../../../store/user.context';
import { Event } from '../../../types/Event';
import { useTranslation } from 'react-i18next';
import { copyToClipboard } from '../../../utils/copy-to-clipboard';

export const filteringFunction = (item: UserRow, filteringText: string): boolean => {
  const matcher: TableFilterMatcher = getTableFilterMatcher(filteringText);
  return (
    matcher.filterEmpty ||
    matcher.isMatch(item.email) ||
    matcher.isMatch(item.nickname) ||
    matcher.isMatch(item.teamName)
  );
};

export const COLUMN_DEFINITIONS = (
  preferences: CollectionPreferencesProps.Preferences,
  target: Event,
  toggleResetPassword: (user: UserRow) => void,
  toggleHandlePromoteUser: (user: UserRow) => void,
  toggleHandleDemoteUser: (user: UserRow) => void,
  toggleDisableUser: (user: UserRow) => void,
  toggleEnableUser: (user: UserRow) => void,
  toggleAssignTeam: (userId: UserRow) => void,
  toggleUserDetails: (user: UserRow) => void,
  toggleTeamDetails: (teamName: string) => void
) => {
  const { emailCache, fetchUserEmail } = useEvents();
  const { user } = useUser();
  const { t } = useTranslation();

  return [
    {
      id: 'nickname',
      sortingField: 'nickname',
      header: t(i18nKeys.participants.headers.user),
      cell: (item: UserRow) => (
        <div>
          <div>
            {item.disabled && <Icon name="lock-private" variant="warning" className="mr-5" />}
            <Link onFollow={() => toggleUserDetails(item)}>{item?.nickname}</Link>
          </div>
        </div>
      ),
      minWidth: 260,
      allowLineWrap: true,
    },
    {
      id: 'facilitator',
      sortingField: 'facilitator',
      header: t(i18nKeys.participants.headers.role),
      cell: (item: UserRow) => (
        <div>
          {item?.facilitator
            ? t(i18nKeys.participants.labels.facilitator)
            : t(i18nKeys.participants.labels.participant)}
        </div>
      ),
      minWidth: 180,
      allowLineWrap: false,
    },
    {
      id: 'team-name',
      sortingField: 'teamDisplayName',
      header: t(i18nKeys.participants.headers.team),
      cell: (item: UserRow) => (
        <div>
          {item.teamName ? (
            <Link onFollow={() => toggleTeamDetails(item.teamName || '')}>{item?.teamDisplayName}</Link>
          ) : (
            t(i18nKeys.general.unassigned)
          )}
          {user && target.canEditAttribute('users', user) && (
            <div className="inline ml-5">
              <Link onFollow={() => toggleAssignTeam(item)}>
                <Icon name="edit" />
              </Link>
            </div>
          )}
        </div>
      ),
      minWidth: 156,
      allowLineWrap: false,
    },
    {
      id: 'email',
      sortingField: 'email',
      header: t(i18nKeys.participants.headers.email),
      cell: (item: UserRow) => (
        <div>
          {item.userId && !emailCache[item.userId]?.email && (
            <Link onFollow={() => void fetchUserEmail(item.userId || '')}>
              {t(i18nKeys.participants.labels.showEmail)}
            </Link>
          )}
          {item.userId && emailCache[item.userId]?.email && (
            <div>
              {emailCache[item?.userId].isVerified && (
                <Icon className="mr-5" variant="success" name="status-positive" />
              )}
              {emailCache[item?.userId].email}
              {emailCache[item?.userId].email && (
                <div className="ml-5 mr-5 inline" onClick={() => copyToClipboard(emailCache[item.userId || ''].email)}>
                  <Icon name="copy" />
                </div>
              )}
              {!emailCache[item?.userId].isVerified && (
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                <div className="ml-5 mr-5 inline" onClick={() => void fetchUserEmail(item.userId || '')}>
                  <Icon name="refresh" />
                </div>
              )}
            </div>
          )}
        </div>
      ),
      minWidth: 215,
      allowLineWrap: false,
    },
    {
      id: 'actions',
      header: t(i18nKeys.participants.headers.actions),
      cell: (item: UserRow) => (
        <div>
          {user && target.canEditAttribute('users', user) && (
            <SpaceBetween direction="horizontal" size="s">
              {!item.disabled && (
                <Link className="right-border pr-12" onFollow={() => toggleResetPassword(item)}>
                  {t(i18nKeys.participants.labels.resetPassword)}
                </Link>
              )}
              {!item.disabled && !item.facilitator && (
                <Link className="right-border pr-12" onFollow={() => toggleHandlePromoteUser(item)}>
                  {t(i18nKeys.participants.labels.promoteToFacilitator)}
                </Link>
              )}
              {!item.disabled && item.facilitator && (
                <Link className="right-border pr-12" onFollow={() => toggleHandleDemoteUser(item)}>
                  {t(i18nKeys.participants.labels.demoteToParticipant)}
                </Link>
              )}
              {!item.disabled && (
                <Link onFollow={() => toggleDisableUser(item)}>{t(i18nKeys.participants.labels.disable)}</Link>
              )}
              {item.disabled && (
                <Link onFollow={() => toggleEnableUser(item)}>{t(i18nKeys.participants.labels.enable)}</Link>
              )}
            </SpaceBetween>
          )}
        </div>
      ),
      minWidth: 215,
      allowLineWrap: false,
    },
  ];
};
