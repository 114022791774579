import { Tabs, TabsProps } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChallenges } from '../../../store/challenge.context';
import { EditEventActions, useEditEvent } from '../../../store/edit-event.context';
import { useSplitPanel } from '../../../store/split-panel.context';
import { useToolPanel } from '../../../store/tool-panel.context';
import { ChallengeDescriptor, ChallengeListItem } from '../../../types/Challenge';
import { Event } from '../../../types/Event';
import { i18nKeys } from '../../../utils/i18n.utils';
import BrowseChallenges from './BrowseChallenges';
import ChallengeSelection from './ChallengeSelection';
import ImportChallenges from './ImportChallenges';
import BackupChallenges from './BackupChallenges';
import _ from 'lodash';
import { NewBadge } from '../NewBadge';
import GameBoard from './GameBoard';
import { Campaign } from '../../../types/Campaign';
import { EditCampaignActions, useEditCampaign } from '../../../store/edit-campaign.context';

interface ChallengeTabsProps {
  challengeDescriptors: ChallengeDescriptor[];
  target: Event | Campaign;
  action: EditEventActions | EditCampaignActions;
  handleChallengeAction: (payload: ChallengeDescriptor[]) => void;
  toggleChallengeInfo: (challengeListItem: ChallengeListItem) => void;
}

// eslint-disable-next-line no-shadow
export enum ChallengeTabIds {
  SELECTED_CHALLENGES = 'selected-challenges-tab',
  BACKUP_CHALLENGES = 'backup-challenges',
  BROWSE_CHALLENGES = 'browse-challenges-tab',
  IMPORT_CHALLENGES = 'import-challenges-tab',
  GAME_BOARD = 'game-board-tab',
}

const ChallengeTabs: React.FC<ChallengeTabsProps> = ({
  challengeDescriptors,
  handleChallengeAction,
  target,
  toggleChallengeInfo,
}) => {
  const { toggleShowSplitPanel } = useSplitPanel();
  const { editMode } = useEditEvent();
  const { campaignEditMode } = useEditCampaign();
  const { t } = useTranslation();
  const { getChallenges } = useChallenges();
  const [activeTabId, setActiveTabId] = useState('selected-challenges-tab');
  const [tabs, setTabs] = useState<TabsProps.Tab[]>([]);

  const { toggleShowToolPanel } = useToolPanel();
  const isEvent = target instanceof Event;

  const handleTabSelection = (tabId: string) => {
    if (tabId === ChallengeTabIds.BROWSE_CHALLENGES) {
      toggleShowSplitPanel(true);
    } else {
      toggleShowSplitPanel(false);
    }
    setActiveTabId(tabId);
  };

  useEffect(() => {
    void getChallenges(false, false, true);
    // run on cleanup
    return () => toggleShowToolPanel(false);
  }, []);

  useEffect(() => {
    if (!editMode && !campaignEditMode) {
      setActiveTabId(ChallengeTabIds.SELECTED_CHALLENGES);
    }
  }, [editMode]);

  const ChallengeTabDictionary: { [key: string]: TabsProps.Tab } = {
    CHALLENGE_SELECTION: {
      label: t(i18nKeys.challenges.tabHeaders.selectedChallenges, {
        selectedChallengeCount: challengeDescriptors.length,
      }),
      id: ChallengeTabIds.SELECTED_CHALLENGES,
      content: (
        <ChallengeSelection
          target={target}
          targetChallengeDescriptors={challengeDescriptors}
          handleRemoveChallenge={handleChallengeAction}
          toggleChallengeInfo={toggleChallengeInfo}
        />
      ),
    },
    BACKUP_CHALLENGES: {
      label: (
        <div>
          {t(i18nKeys.challenges.tabHeaders.backupChallenges)}
          <NewBadge className="ml-5" />
        </div>
      ),
      id: ChallengeTabIds.BACKUP_CHALLENGES,
      content: isEvent ? <BackupChallenges target={target} /> : <></>,
    },
    BROWSE_CHALLENGES: {
      label: t(i18nKeys.challenges.tabHeaders.browseChallenges),
      id: ChallengeTabIds.BROWSE_CHALLENGES,
      content: (
        <BrowseChallenges
          handleChallengeAction={handleChallengeAction}
          currentChallengeDescriptors={challengeDescriptors}
          toggleChallengeInfo={toggleChallengeInfo}
        />
      ),
    },
    IMPORT_CHALLENGES: {
      label: t(i18nKeys.challenges.tabHeaders.importChallenges),
      id: ChallengeTabIds.IMPORT_CHALLENGES,
      content: (
        <ImportChallenges
          currentChallengeDescriptors={challengeDescriptors}
          handleChallengeAction={handleChallengeAction}
          target={target}
          navigateToTab={setActiveTabId}
        />
      ),
    },
    GAME_BOARD: {
      label: t(i18nKeys.challenges.tabHeaders.gameBoard),
      id: ChallengeTabIds.GAME_BOARD,
      content: isEvent ? <GameBoard target={target} /> : <></>,
    },
  };

  const getTabs = () => {
    const newTabs = [];
    // Add tabs that will show regardless of editMode
    newTabs.push(...[ChallengeTabDictionary.CHALLENGE_SELECTION]);
    if (isEvent) {
      newTabs.push(...[ChallengeTabDictionary.BACKUP_CHALLENGES]);
    }
    if (editMode || campaignEditMode) {
      newTabs.push(...[ChallengeTabDictionary.BROWSE_CHALLENGES, ChallengeTabDictionary.IMPORT_CHALLENGES]);
      if (isEvent) {
        newTabs.push(...[ChallengeTabDictionary.GAME_BOARD]);
      }
    }
    setTabs(_.clone(newTabs));
  };

  useEffect(() => {
    getTabs();
  }, [target, editMode, campaignEditMode]);

  return (
    <Tabs activeTabId={activeTabId} onChange={({ detail }) => handleTabSelection(detail.activeTabId)} tabs={tabs} />
  );
};
export default ChallengeTabs;
