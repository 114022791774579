import React from 'react';
import moment from 'moment';
import { Button, Icon, SpaceBetween } from '@amzn/awsui-components-react';
import { TFunctionProvider } from '../../../common/TFunctionProvider';
import { Challenge, ChallengeReviewStatus, ChallengeStatus } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { Nullable } from '../../../../types/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const getReviewStatus: (
  status: Nullable<ChallengeReviewStatus> | Nullable<ChallengeStatus> | undefined
) => JSX.Element = (status) => {
  if (!status) {
    return <div>--</div>;
  }

  switch (status) {
    case ChallengeStatus.APPROVED:
    case ChallengeReviewStatus.APPROVED:
      return (
        <div className="aws-positive-green">
          <SpaceBetween size={'xxs'} direction={'horizontal'}>
            <Icon name="status-positive" />
            <TFunctionProvider>{(tr) => tr(i18nKeys.general.approved)}</TFunctionProvider>
          </SpaceBetween>
        </div>
      );
    case ChallengeStatus.IN_REVIEW:
    case ChallengeReviewStatus.IN_REVIEW:
      return (
        <div className="subtle">
          <SpaceBetween size={'xxs'} direction={'horizontal'}>
            <Icon name="status-pending" variant="subtle" />
            <TFunctionProvider>{(tr) => tr(i18nKeys.general.inReview)}</TFunctionProvider>
          </SpaceBetween>
        </div>
      );
    case ChallengeStatus.READY_FOR_REVIEW:
    case ChallengeReviewStatus.READY_FOR_REVIEW:
      return (
        <div className="info">
          <SpaceBetween size={'xxs'} direction={'horizontal'}>
            <Icon name="status-info" />
            <TFunctionProvider>{(tr) => tr(i18nKeys.general.readyForReview)}</TFunctionProvider>
          </SpaceBetween>
        </div>
      );
    case ChallengeStatus.NEEDS_WORK:
    case ChallengeReviewStatus.NEEDS_WORK:
      return (
        <div className="warning">
          <SpaceBetween size={'xxs'} direction={'horizontal'}>
            <Icon name="status-warning" variant="warning" />
            <TFunctionProvider>{(tr) => tr(i18nKeys.general.needsWork)}</TFunctionProvider>
          </SpaceBetween>
        </div>
      );
    case ChallengeReviewStatus.NOT_REVIEWED:
      return (
        <div className="subtle">
          <SpaceBetween size={'xxs'} direction={'horizontal'}>
            <FontAwesomeIcon icon={'close'} />
            <TFunctionProvider>{(tr) => tr(i18nKeys.general.notReviewed)}</TFunctionProvider>
          </SpaceBetween>
        </div>
      );
    case ChallengeStatus.DRAFT:
      return (
        <div className="subtle">
          <SpaceBetween size={'xxs'} direction={'horizontal'}>
            <Icon name="status-in-progress" variant="subtle" />
            <TFunctionProvider>{(tr) => tr(i18nKeys.general.draft)}</TFunctionProvider>
          </SpaceBetween>
        </div>
      );
    default:
      return (
        <div className="grey">
          <SpaceBetween size={'xxs'} direction={'horizontal'}>
            <Icon name="status-pending" />
            <TFunctionProvider>{(tr) => tr(i18nKeys.general.pending)}</TFunctionProvider>
          </SpaceBetween>
        </div>
      );
  }
};

export const COLUMN_DEFINITIONS = (handleRevisionSelection: (challengeRevision: Challenge) => void) => {
  return [
    {
      id: 'version',
      sortingField: 'version',
      header: (
        <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.version)}</TFunctionProvider>
      ),
      width: 125,
      cell: (item: Challenge) => (
        <Button
          variant="link"
          onClick={() => handleRevisionSelection(item)}>{`${item?.majorVersion}.${item?.minorVersion}`}</Button>
      ),
    },
    {
      id: 'status',
      header: (
        <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.status)}</TFunctionProvider>
      ),
      minWidth: 150,
      cell: (item: Challenge) => getReviewStatus(item.status),
    },
    {
      id: 'timeCreated',
      sortingField: 'timeCreated',
      minWidth: 300,
      header: (
        <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.timeCreated)}</TFunctionProvider>
      ),
      cell: (item: Challenge) => moment(item?.createdDate).format('lll'),
      allowLineWrap: true,
    },
    {
      id: 'changedBy',
      sortingField: 'changedBy',
      minWidth: 300,
      header: (
        <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.changedBy)}</TFunctionProvider>
      ),
      cell: (item: Challenge) => item?.props.lastUpdatedBy || '--',
      allowLineWrap: true,
    },
  ];
};
