import { Event, EventStatus } from '../types/Event';
import { ApprovalStatus, EventFilterType } from '../types/common';

// various filter functions used to filter events based on their status
const isEventEnded = (event: Event) => event.ended || event.cancelled || event.denied;
const isEventLive = (event: Event) => event.status === EventStatus.ONGOING && !isEventEnded(event);
const isEventInReview = (event: Event) => (
  event.approvalStatus === ApprovalStatus.REQUEST_PENDING ||
  event.approvalStatus === ApprovalStatus.REQUEST_SUBMITTED ||
  event.approvalStatus === ApprovalStatus.REQUEST_DENIED
) && !isEventEnded(event);
const isEventCampaign = (event: Event) => event.isCampaignEvent && !isEventEnded(event);
const isEventJam = (event: Event) => !event.isCampaignEvent && !isEventEnded(event);
const isEventFuture = (event: Event) => event.isBeforeStart  && !isEventEnded(event);

const filterFunctionToEventStatusMapping = {
  [EventFilterType.ENDED as string]: isEventEnded,
  [EventFilterType.LIVE as string]: isEventLive,
  [EventFilterType.IN_REVIEW as string]: isEventInReview,
  [EventFilterType.CAMPAIGN as string]: isEventCampaign,
  [EventFilterType.JAM as string]: isEventJam,
  [EventFilterType.FUTURE as string]: isEventFuture,
};

export const filterEvents = (events: Event[] | undefined, eventFilters: (undefined | string)[], showEndedEvents: boolean): Event[] => {
  const isNoFiltersApplied = !eventFilters || eventFilters.length === 0;
  const isEventsEmpty = !events || events.length === 0;
  
  if (isEventsEmpty) {
    return [];
  }

  const mutatedEventFiltes = showEndedEvents ? 
    [...eventFilters, EventFilterType.ENDED] : [...eventFilters];

  
    // if no filters are applied, show all events
  if (isNoFiltersApplied) {
    return showEndedEvents ? events : events.filter((event: Event) => !isEventEnded(event));
  }

  return events.filter((event: Event) => {
    // if event is not part of the user selected filters, filter out
    const isPartOfTheSelectedFilters = mutatedEventFiltes.some((filterType = '') => {
      const filterFunction = filterFunctionToEventStatusMapping[filterType];
      return filterFunction && filterFunction(event);
    });

    return isPartOfTheSelectedFilters;
  });
};