import React from 'react';
import { Link } from 'react-router-dom';
import { getReviewStatus } from '../revision-list-config';
import { TFunctionProvider } from '../../../../common/TFunctionProvider';
import { i18nKeys } from '../../../../../utils/i18n.utils';
import {
  ChallengeReviewableSection,
  ChallengeReviewStatus,
  ChallengeSectionFeedback
} from '../../../../../types/Challenge';
import { CHALLENGE_DETAILS_ROUTES } from '../../../../../routes';

// eslint-disable-next-line no-shadow
enum RevisionsNeededColumn {
  SECTION = 'section',
  STATUS = 'status',
  COMMENT = 'comment',
}

const getTranslationKey: (section: ChallengeReviewableSection | null) => string = (
  section: ChallengeReviewableSection | null
) => {
  if (!section) {
    throw new Error('Section cannot be undefined!');
  }
  switch (section) {
    case ChallengeReviewableSection.SUMMARY:
      return i18nKeys.challenges.subSections.overview.title;
    case ChallengeReviewableSection.LEARNING_OUTCOME:
      return i18nKeys.challenges.subSections.learningPlan.title;
    case ChallengeReviewableSection.SETTINGS:
      return i18nKeys.challenges.subSections.settings.title;
    case ChallengeReviewableSection.TASKS:
      return i18nKeys.challenges.subSections.tasks.title;
    case ChallengeReviewableSection.ASSETS_RESOURCES:
      return i18nKeys.challenges.subSections.assetsResources.title;
    case ChallengeReviewableSection.IAM_POLICY:
      return i18nKeys.challenges.subSections.iamPolicy.title;
    case ChallengeReviewableSection.CFN_TEMPLATE:
      return i18nKeys.challenges.subSections.cfnTemplate.title;
    case ChallengeReviewableSection.TESTING:
      return i18nKeys.challenges.subSections.testing.title;
    case ChallengeReviewableSection.NEXT_STEPS:
      return i18nKeys.challenges.subSections.nextSteps.title;
    case ChallengeReviewableSection.WIKI:
      return i18nKeys.challenges.subSections.wiki.title;
    default:
      throw new Error('Not Supported!');
  }
};

export const getSectionLink = (section: ChallengeReviewableSection | null, challengeId: string) => {
  if (!section) {
    throw new Error('Section cannot be undefined!');
  }
  switch (section) {
    case ChallengeReviewableSection.SUMMARY:
      return CHALLENGE_DETAILS_ROUTES.Summary.resolve(challengeId);
    case ChallengeReviewableSection.LEARNING_OUTCOME:
      return CHALLENGE_DETAILS_ROUTES.LearningOutcome.resolve(challengeId);
    case ChallengeReviewableSection.SETTINGS:
      return CHALLENGE_DETAILS_ROUTES.Settings.resolve(challengeId);
    case ChallengeReviewableSection.TASKS:
      return CHALLENGE_DETAILS_ROUTES.Tasks.resolve(challengeId);
    case ChallengeReviewableSection.CFN_TEMPLATE:
      return CHALLENGE_DETAILS_ROUTES.CfnTemplate.resolve(challengeId);
    case ChallengeReviewableSection.IAM_POLICY:
      return CHALLENGE_DETAILS_ROUTES.IamPolicy.resolve(challengeId);
    case ChallengeReviewableSection.ASSETS_RESOURCES:
      return CHALLENGE_DETAILS_ROUTES.Assets.resolve(challengeId);
    case ChallengeReviewableSection.TESTING:
      return CHALLENGE_DETAILS_ROUTES.Testing.resolve(challengeId);
    case ChallengeReviewableSection.WIKI:
      return CHALLENGE_DETAILS_ROUTES.Wiki.resolve(challengeId);
    case ChallengeReviewableSection.NEXT_STEPS:
      return CHALLENGE_DETAILS_ROUTES.NextSteps.resolve(challengeId);
    default:
      throw new Error('Not supported!');
  }
};

const getTerminalStatus: (status: ChallengeReviewStatus | null) => ChallengeReviewStatus = (status) => {
  if (status === ChallengeReviewStatus.APPROVED || status === ChallengeReviewStatus.NEEDS_WORK) {
    return status;
  }
  return ChallengeReviewStatus.NOT_REVIEWED;
};

export const COLUMN_DEFINITIONS = (challengeId: string) => [
  {
    id: RevisionsNeededColumn.SECTION,
    sortingField: RevisionsNeededColumn.SECTION,
    width: 350,
    header: (
      <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.section)}</TFunctionProvider>
    ),
    cell: (item: ChallengeSectionFeedback) => (
      <Link to={getSectionLink(item.section, challengeId)}>
        <TFunctionProvider>{(t) => t(getTranslationKey(item.section))}</TFunctionProvider>
      </Link>
    ),
  },
  {
    id: RevisionsNeededColumn.STATUS,
    sortingField: RevisionsNeededColumn.STATUS,
    width: 200,
    header: <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.status)}</TFunctionProvider>,
    cell: (item: ChallengeSectionFeedback) => getReviewStatus(getTerminalStatus(item.status)) || '--',
    allowLineWrap: true,
  },
  {
    id: RevisionsNeededColumn.COMMENT,
    sortingField: RevisionsNeededColumn.COMMENT,
    header: (
      <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.comments)}</TFunctionProvider>
    ),
    cell: (item: ChallengeSectionFeedback) => item.comment || '--',
    allowLineWrap: true,
  },
];
