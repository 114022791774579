import { Alert, Button, Header, Icon, Popover, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditEventActions, useEditEvent } from '../../../store/edit-event.context';
import { useSplitPanel } from '../../../store/split-panel.context';
import { useToolPanel } from '../../../store/tool-panel.context';
import { ChallengeDescriptor } from '../../../types/Challenge';
import { Event } from '../../../types/Event';
import { i18nKeys } from '../../../utils/i18n.utils';
import { BackupChallengeRow } from '../BackupChallengeRow';
import BrowseChallengesInSplitPanel from './BrowseChallengesInSplitPanel';

interface GeneralBackupChallengesProps {
  target: Event;
}

const GeneralBackupChallenges: React.FC<GeneralBackupChallengesProps> = ({ target }) => {
  const { handleUpdateEditEvent, editMode, newEventMode } = useEditEvent();
  const { toggleChallengeInfo } = useToolPanel();
  const { toggleShowSplitPanel, toggleHideAfterClose, toggleSplitPanel, renderSplitPanelContent } = useSplitPanel();
  const { t } = useTranslation();

  const moveUp = (backup: ChallengeDescriptor) => {
    move(backup, -1);
  };

  const moveDown = (backup: ChallengeDescriptor) => {
    move(backup, 1);
  };

  const move = (backup: ChallengeDescriptor, offset: number) => {
    if (target?.backupChallengeConfig) {
      let backupChallenges = target?.backupChallengeConfig?.generalBackups ?? [];
      backupChallenges = sortMove(backup, backupChallenges, offset) ?? [];
      handleUpdateEditEvent(EditEventActions.GENERAL_BACKUP_CHALLENGES, backupChallenges);
    }
  };

  const sortMove = (backup: ChallengeDescriptor, backupChallenges: ChallengeDescriptor[], offset: number) => {
    const index1 = backupChallenges.findIndex((c) => c?.challengeId === backup.challengeId);
    const index2 = index1 + offset;

    if (index1 < 0 || index1 > backupChallenges.length - 1 || index2 < 0 || index2 > backupChallenges.length - 1) {
      return;
    }

    const temp = backupChallenges[index1];
    backupChallenges[index1] = backupChallenges[index2];
    backupChallenges[index2] = temp;
    return backupChallenges;
  };

  const remove = (backup: ChallengeDescriptor) => {
    if (target?.backupChallengeConfig) {
      const backupChallenges = (target.backupChallengeConfig.generalBackups ?? []).filter(
        (c) => c?.challengeId !== backup.challengeId
      );
      handleUpdateEditEvent(EditEventActions.GENERAL_BACKUP_CHALLENGES, backupChallenges);
    }
  };

  const toggleBrowseBackupChallenges = () => {
    toggleShowSplitPanel(true);
    toggleHideAfterClose(true);
    toggleSplitPanel(true);
    updateSplitPanelSelection(
      target.backupChallengeConfig?.generalBackups || [],
      handleSelectBackupChallenges,
      target.challengeDescriptors
    );
  };

  const updateSplitPanelSelection = (
    challenges: ChallengeDescriptor[],
    handleSelection: (selectedChallenges: ChallengeDescriptor[]) => void,
    excludedChallenges?: ChallengeDescriptor[]
  ) => {
    renderSplitPanelContent(
      t(i18nKeys.newEvent.splitPanel.header),
      <BrowseChallengesInSplitPanel
        handleChallengeAction={handleSelection}
        toggleChallengeInfo={toggleChallengeInfo}
        currentChallengeDescriptors={challenges}
        excludedChallenges={excludedChallenges}
      />
    );
  };

  const handleSelectBackupChallenges = (challenges: ChallengeDescriptor[]) => {
    handleUpdateEditEvent(EditEventActions.GENERAL_BACKUP_CHALLENGES, challenges);
  };

  return (
    <React.Fragment>
      <SpaceBetween direction="vertical" size="s">
        <Alert type="info">{t(i18nKeys.challenges.backupChallenges.messages.alert)}</Alert>
        <Header variant="h2">
          {t(i18nKeys.newEvent.headers.chooseGeneralBackupChallenges)}
          <Popover className="ml-5" content={t(i18nKeys.challenges.backupChallenges.messages.info)}>
            <Icon name="status-info" />
          </Popover>
        </Header>
        {target.backupChallengeConfig?.generalBackups && target.backupChallengeConfig.generalBackups.length > 0 && (
          <React.Fragment>
            {target.backupChallengeConfig.generalBackups.map((challengeDescriptor, i) => {
              return (
                <BackupChallengeRow
                  key={`backup-challenge-row-${i}`}
                  challengeDescriptor={challengeDescriptor}
                  moveDown={moveDown}
                  moveUp={moveUp}
                  remove={remove}
                  index={i}
                  toggleChallengeInfo={toggleChallengeInfo}
                  totalCount={target.backupChallengeConfig?.generalBackups.length || 0}
                />
              );
            })}
          </React.Fragment>
        )}
        {!editMode &&
          !newEventMode &&
          target.backupChallengeConfig?.generalBackups &&
          target.backupChallengeConfig?.generalBackups.length < 1 && (
            <div>{t(i18nKeys.challenges.backupChallenges.messages.noneSelected)}</div>
          )}
        {(editMode || newEventMode) && (
          <Button variant="primary" onClick={() => toggleBrowseBackupChallenges()}>
            {t(i18nKeys.newEvent.buttons.addGeneralBackupChallenges)}
          </Button>
        )}
      </SpaceBetween>
    </React.Fragment>
  );
};
export default GeneralBackupChallenges;
