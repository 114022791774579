import { ReactNode } from 'react';
import { FlashbarProps } from '@amzn/awsui-components-react';
import { AnyT, jsonArrayMember, jsonMember, jsonObject } from 'typedjson';

export interface FlashbarItem extends FlashbarProps.MessageDefinition {
  id: string;
  i18nKeys: string[];
}

export type Renderable<T> = T | ReactNode;
export type DateString = string;
export type Nullable<T> = T | null;
export type NullableString = Nullable<string>;
export type NullableNumber = Nullable<number>;
export type NullableBoolean = Nullable<boolean>;
export type NullableTimeStamp = NullableNumber;
export type NullableDateString = NullableString;
export type NullableArray = Nullable<any[]>;
export type NullableEmail = Email | null;

// These are used in TypedJSON values for mapping to class instances
export const AnyValue = String || Boolean || Number || Object || Function || BigInt || Symbol || undefined || null;
export const NullableStringValue = String || null;
export const NullableNumberValue = Number || null;
export const NullableBooleanValue = Boolean || null;
export const NullableTimeStampValue = NullableNumberValue;
export const NullableDateStringValue = NullableStringValue;
export const NullableObjectValue = Object || null;
// Necessary to disable the next two lines to allow `Nullable`Classes with TypedJSON annotation
// eslint-disable-next-line
export const NullableClassValue = (className: any): any | null => className || null;
// eslint-disable-next-line
export const OfClassOrUndefined = (className: any): any | undefined => className || undefined;

export interface DateRangeFilter {
  start: DateString;
  end: NullableDateString;
}

export interface Comment {
  id: NullableString;
  value: NullableString;
  author: NullableString;
  createdAt: NullableDateString;
  updatedAt?: NullableDateString;
  tags?: string[];
}

@jsonObject
export class Email {
  @jsonMember(NullableStringValue)
  emailAddress: NullableString = null;
}

@jsonObject
export class Comment {
  @jsonMember(NullableStringValue)
  id: NullableString = null;

  @jsonMember(NullableStringValue)
  value: NullableString = null;

  @jsonMember(NullableStringValue)
  author: NullableString = null;

  @jsonMember(NullableDateStringValue)
  createdAt: NullableDateString = null;

  @jsonMember(NullableDateStringValue)
  updatedAt?: NullableDateString = null;

  @jsonArrayMember(String)
  tags?: string[] = [];
}

export enum ApprovalStatus {
  REQUEST_SUBMITTED = 'REQUEST_SUBMITTED',
  REQUEST_PENDING = 'REQUEST_PENDING',
  REQUEST_CANCELLED = 'REQUEST_CANCELLED',
  REQUEST_APPROVED = 'REQUEST_APPROVED',
  REQUEST_DENIED = 'REQUEST_DENIED',
}

export enum ChangeRequestStatus {
  CHANGE_REQUESTED = 'CHANGE_REQUESTED',
  CHANGE_PENDING = 'CHANGE_PENDING',
  CHANGE_CANCELLED = 'CHANGE_CANCELLED',
  CHANGE_APPROVED = 'CHANGE_APPROVED',
  CHANGE_DENIED = 'CHANGE_DENIED',
}

export enum ChangeRequestPendingStatus {
  CHANGE_REQUESTED = 'CHANGE_REQUESTED',
  CHANGE_PENDING = 'CHANGE_PENDING',
}

export enum RequestUnapprovedStatus {
  REQUEST_SUBMITTED = 'REQUEST_SUBMITTED',
  REQUEST_PENDING = 'REQUEST_PENDING',
  REQUEST_CANCELLED = 'REQUEST_CANCELLED',
  REQUEST_DENIED = 'REQUEST_DENIED',
}

export interface ChallengeCounts {
  [challengeId: string]: number;
}

export interface ChallengeStartTimes {
  [challengeId: string]: number[];
}

export interface ChallengeCompletionTimes {
  [challengeId: string]: number[];
}

/**
 * Enables EventPermissions to accept both Event & Campagin email lists and requestedBy
 * Based on their custom implementations
 */
export interface WithEventPermissions {
  getOwners: () => string[];
  getFacilitators: () => string[];
  requestedBy: NullableString;
}

export interface KeyValue {
  key: string;
  value: string;
}

export enum RequestType {
  CAMPAIGN = 'CAMPAIGN',
  EVENT = 'EVENT',
}

export enum FrequencyUnit {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum Stability {
  PERFECT = 'Perfect',
  GREAT = 'Great',
  GOOD = 'Good',
  FAIR = 'Fair',
  POOR = 'Poor',
  LIMITED_DATA = 'Limited Data',
  N_A = 'N/A',
}

export const EnterKeyCode = 13;

@jsonObject
export class TranslatedAttribute {
  @jsonMember(String)
  attributeName = '';
  @jsonMember(String)
  originalText = '';
  @jsonMember(String)
  translatedText = '';
}

@jsonObject
export class LocalizationOverrides {
  @jsonMember(String)
  appName = '';
  @jsonMember(String)
  languageCode = '';
  @jsonMember(AnyT)
  defaults: { [key: string]: string } = {};
  @jsonMember(AnyT)
  overrides: { [key: string]: string } = {};
}

export enum EventFilterType {
  ENDED = 'showEndedEvents',
  LIVE = 'showLiveEvents',
  IN_REVIEW = 'showInReviewEvents',
  CAMPAIGN = 'showCampaignEvents',
  JAM = 'showJamEvents',
  FUTURE = 'showFutureEvents',
}
