/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty-function */
import _ from 'lodash';
import { pick } from 'lodash';
import React, { useContext, useState } from 'react';
import { ChallengeSet, ChallengeSetRequest } from '../types/ChallengeSet';
import { preProdLogger } from '../utils/log.utils';
import { useApi } from './api.context';

export const CHALLENGE_SET_TITLE_MIN_LENGTH = 4;
export const CHALLENGE_SET_TITLE_MAX_LENGTH = 50;
export const CHALLENGE_SET_DESCRIPTION_MIN_LENGTH = 20;
export const CHALLENGE_SET_DESCRIPTION_MAX_LENGTH = 1000;

// eslint-disable-next-line no-shadow
export enum ChallengeSetAction {
  TITLE = 'title',
  DESCRIPTION = 'description',
  CHALLENGE_IDS = 'challenge-ids',
  FEATURED = 'featured',
}

export type ToggleEditMode = () => void;
export type GetChallengeSet = (challengeSetId: string) => Promise<void>;
export type GetChallengeSets = () => Promise<void>;
export type CreateChallengeSet = (request: ChallengeSetRequest) => Promise<ChallengeSet>;
export type HandleUpdateChallengeSetAttribute = (action: string, payload: any) => void;
export type UpdateChallengeSet = () => Promise<ChallengeSet>;

export interface ChallengeSetContentValue {
  challengeSets: ChallengeSet[] | undefined;
  challengeSet: ChallengeSet | undefined;
  editedChallengeSet: ChallengeSet | undefined;
  editMode: boolean;
  toggleEditMode: ToggleEditMode;
  getChallengeSet: GetChallengeSet;
  getChallengeSets: GetChallengeSets;
  createChallengeSet: CreateChallengeSet;
  handleUpdateChallengeSetAttribute: HandleUpdateChallengeSetAttribute;
  updateChallengeSet: UpdateChallengeSet;
}

const ChallengeSetContent = React.createContext<ChallengeSetContentValue>({
  challengeSets: undefined,
  challengeSet: undefined,
  editedChallengeSet: undefined,
  editMode: false,
  toggleEditMode: (): void => {},
  getChallengeSet: (challengeSetId: string) => new Promise(() => {}),
  getChallengeSets: () => new Promise(() => {}),
  createChallengeSet: (request: ChallengeSetRequest) => new Promise(() => {}),
  handleUpdateChallengeSetAttribute: (action: string, payload: any) => {},
  updateChallengeSet: () => new Promise(() => {}),
});

const ChallengeSetProvider: React.FC = ({ children }) => {
  const [challengeSets, setChallengeSets] = useState<ChallengeSet[] | undefined>(undefined);
  const [challengeSet, setChallengeSet] = useState<ChallengeSet | undefined>();
  const [editedChallengeSet, setEditedChallengeSet] = useState<ChallengeSet | undefined>();
  const [editMode, setEditMode] = useState(false);

  const { challengeSetApi } = useApi();

  const toggleEditMode = () => {
    if (!editMode) {
      setEditedChallengeSet(challengeSet);
    }
    setEditMode(!editMode);
  };

  const getChallengeSet = async (challengeSetId: string) => {
    await challengeSetApi
      .findOneById(challengeSetId)
      .then((set: ChallengeSet) => {
        setChallengeSet(set);
      })
      .catch((err) => preProdLogger(err));
  };

  const getChallengeSets = async () => {
    await challengeSetApi
      .findAll()
      .then((challengeSetResponse: ChallengeSet[] | undefined) => {
        setChallengeSets(challengeSetResponse);
      })
      .catch((err) => preProdLogger(err));
  };

  const createChallengeSet = async (request: ChallengeSetRequest): Promise<ChallengeSet> => {
    return await challengeSetApi.create(request).then(async (cs) => {
      await getChallengeSet(cs.id || '');
      return cs;
    });
  };

  const handleUpdateChallengeSetAttribute = (action: string, payload: any) => {
    const currentChallengeSet = editedChallengeSet ? _.cloneDeep(editedChallengeSet) : new ChallengeSet();

    switch (action) {
      case ChallengeSetAction.TITLE:
        currentChallengeSet.title = payload;
        break;
      case ChallengeSetAction.DESCRIPTION:
        currentChallengeSet.description = payload;
        break;
      case ChallengeSetAction.CHALLENGE_IDS:
        currentChallengeSet.challengeIds = payload;
        break;
      case ChallengeSetAction.FEATURED:
        currentChallengeSet.featured = payload;
        break;
    }

    setEditedChallengeSet(currentChallengeSet);
  };

  const updateChallengeSet = async (): Promise<ChallengeSet> => {
    const request: ChallengeSetRequest = pick(
      editedChallengeSet,
      Object.keys(new ChallengeSetRequest())
    ) as ChallengeSetRequest;

    return await challengeSetApi.update(editedChallengeSet?.id || '', request).then(async (cs) => {
      await getChallengeSet(cs.id || '');
      return cs;
    });
  };

  const data: ChallengeSetContentValue = {
    challengeSets,
    challengeSet,
    editedChallengeSet,
    editMode,
    toggleEditMode,
    getChallengeSet,
    getChallengeSets,
    createChallengeSet,
    handleUpdateChallengeSetAttribute,
    updateChallengeSet,
  };

  return <ChallengeSetContent.Provider value={data}>{children}</ChallengeSetContent.Provider>;
};

const useChallengeSet = () => {
  const context = useContext(ChallengeSetContent);
  if (context === undefined) {
    throw new Error('useChallengeSet can only be used inside ChallengeSetProvider');
  }
  return context;
};

export { ChallengeSetProvider, useChallengeSet };
