import React from 'react';
import { Challenge, ChallengeDifficulty, ChallengeListItem, ChallengeStatus } from '../../../types/Challenge';
import TimeAgo from 'timeago-react';
import { i18nKeys } from '../../../utils/i18n.utils';
import { getTableFilterMatcher, TableFilterMatcher } from '../../../utils/table.utils';
import DifficultyIndicator from '../../challenges/challengesCommon/difficultyIndiciator/DifficultyIndicator';
import { TFunctionProvider } from '../TFunctionProvider';
import { getDuration } from '../../../utils/time.utils';
import { Badge, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { NullableString, Stability } from '../../../types/common';
import TPopover from '../TPopover';
import StarRating from '../StarRating';
import { CHALLENGE_DETAILS_ROUTES } from '../../../routes';
import { useChallenges } from '../../../store/challenge.context';
import { TFunction } from 'i18next';

export const filteringFunction = (t: TFunction) => (item: ChallengeListItem, filteringText: string): boolean => {
  const matcher: TableFilterMatcher = getTableFilterMatcher(filteringText);
  return (
    matcher.filterEmpty ||
    matcher.isMatch(item?.props.title || null) ||
    matcher.isMatch(item?.props.jamType || null) ||
    matcher.isMatch(item?.props.category || null) ||
    matcher.isMatch(t(ChallengeDifficulty.getByKey(item?.props.difficulty).i18nKeyShort))
  );
};

export const compareValues = (firstValue: any, secondValue: any) => {
  if (firstValue === null) {
    return 1;
  }

  if (secondValue === null) {
    return -1;
  }

  if (firstValue === secondValue) {
    return 0;
  }

  return firstValue < secondValue ? -1 : 1;
};

export const getStabilityLabel = (stability: { label: Stability; percent: NullableString } | undefined): any => {
  const stabilityDictionary: { [key in Stability]: JSX.Element } = {
    [Stability.PERFECT]: (
      <TFunctionProvider>
        {(translate) => translate(i18nKeys.challenges.stability.perfect, { percentage: stability?.percent })}
      </TFunctionProvider>
    ),
    [Stability.GREAT]: (
      <TFunctionProvider>
        {(translate) => translate(i18nKeys.challenges.stability.great, { percentage: stability?.percent })}
      </TFunctionProvider>
    ),
    [Stability.GOOD]: (
      <TFunctionProvider>
        {(translate) => translate(i18nKeys.challenges.stability.good, { percentage: stability?.percent })}
      </TFunctionProvider>
    ),
    [Stability.GREAT]: (
      <TFunctionProvider>
        {(translate) => translate(i18nKeys.challenges.stability.great, { percentage: stability?.percent })}
      </TFunctionProvider>
    ),
    [Stability.FAIR]: (
      <TFunctionProvider>
        {(translate) => translate(i18nKeys.challenges.stability.fair, { percentage: stability?.percent })}
      </TFunctionProvider>
    ),
    [Stability.POOR]: (
      <TFunctionProvider>
        {(translate) => translate(i18nKeys.challenges.stability.poor, { percentage: stability?.percent })}
      </TFunctionProvider>
    ),
    [Stability.LIMITED_DATA]: (
      <TFunctionProvider>{(translate) => translate(i18nKeys.challenges.stability.limitedData)}</TFunctionProvider>
    ),
    [Stability.N_A]: (
      <TFunctionProvider>{(translate) => translate(i18nKeys.challenges.stability.N_A)}</TFunctionProvider>
    ),
  };

  return stability ? stabilityDictionary[stability.label] : stabilityDictionary[Stability.N_A];
};

export const COLUMN_DEFINITIONS = (
  toggleChallengeInfo: (challengeListItem: ChallengeListItem) => void,
  editMode = true
) => {
  const { challengeWrapperMap } = useChallenges();
  return [
    {
      id: 'title',
      sortingField: 'title',
      sortingComparator: (a: ChallengeListItem, b: ChallengeListItem) => {
        return compareValues(a?.props.title || null, b?.props.title || null);
      },
      minWidth: 300,
      header: <TFunctionProvider>{(translate) => translate(i18nKeys.events.fields.title.title)}</TFunctionProvider>,
      cell: (item: ChallengeListItem) => (
        <div>
          {item && !editMode && (
            <ReactRouterLink to={CHALLENGE_DETAILS_ROUTES.Summary.resolve(item.challengeId ?? '')}>
              {item.props.title}
            </ReactRouterLink>
          )}
          {item && editMode && (
            <div onClick={() => toggleChallengeInfo(item)}>
              <Link>{item?.props.title || 'Untitled Challenge'}</Link>
            </div>
          )}
          <SpaceBetween direction="horizontal" size="s">
            {/* Hiding Approved Badge if in editMode (challenge select) */}
            {item.status === ChallengeStatus.APPROVED && !editMode && (
              <Badge color="green">
                <TFunctionProvider>
                  {(translate) => translate(i18nKeys.challenges.browseChallenges.labels.approved)}
                </TFunctionProvider>
              </Badge>
            )}
            {/** TODO: Feature flag conditional implementation */}
            {item?.props.challengeAlwaysOn && (
              <Badge color="red">
                <TFunctionProvider>
                  {(translate) => translate(i18nKeys.challenges.browseChallenges.labels.warmup)}
                </TFunctionProvider>
              </Badge>
            )}
            {item.challengeId && challengeWrapperMap[item.challengeId]?.isPrivate && (
              <Badge color="grey">
                <TFunctionProvider>
                  {(translate) => translate(i18nKeys.challenges.browseChallenges.labels.private)}
                </TFunctionProvider>
              </Badge>
            )}
            {item.challengeId && challengeWrapperMap[item.challengeId]?.isDemo && (
              <Badge color="grey">
                <TFunctionProvider>
                  {(translate) => translate(i18nKeys.challenges.browseChallenges.labels.demo)}
                </TFunctionProvider>
              </Badge>
            )}
            {item?.props.tags &&
              item?.props.tags.length > 0 &&
              (item.props.tags.length > 1 ? (
                <TPopover
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  headerKey={i18nKeys.challenges.browseChallenges.headers.tags}
                  content={
                    <SpaceBetween direction="vertical" size="s">
                      {item.props.tags.map((tag, index) => {
                        return (
                          <Badge color="blue" key={`badge-${index}`}>
                            {tag}
                          </Badge>
                        );
                      })}
                    </SpaceBetween>
                  }>
                  <div>
                    <TFunctionProvider>
                      {(translate) =>
                        translate(i18nKeys.challenges.browseChallenges.labels.tags, {
                          tagCount: item?.props.tags.length,
                        })
                      }
                    </TFunctionProvider>
                  </div>
                </TPopover>
              ) : (
                <Badge color="blue">{item?.props.tags[0]}</Badge>
              ))}
          </SpaceBetween>
        </div>
      ),
    },
    {
      id: 'type',
      sortingField: 'jamType',
      sortingComparator: (a: ChallengeListItem, b: ChallengeListItem) => {
        return compareValues(a?.props.jamType || null, b?.props.jamType || null);
      },
      header: (
        <TFunctionProvider>
          {(translate) => translate(i18nKeys.challenges.browseChallenges.headers.type)}
        </TFunctionProvider>
      ),
      cell: (item: ChallengeListItem) => (
        <div>
          {item?.props.jamType || (
            <TFunctionProvider>{(translate) => translate(i18nKeys.general.nA)}</TFunctionProvider>
          )}
        </div>
      ),
    },
    {
      id: 'category',
      sortingField: 'category',
      sortingComparator: (a: ChallengeListItem, b: ChallengeListItem) => {
        return compareValues(a?.props.category || null, b?.props.category || null);
      },
      header: (
        <TFunctionProvider>
          {(translate) => translate(i18nKeys.challenges.browseChallenges.headers.category)}
        </TFunctionProvider>
      ),
      cell: (item: ChallengeListItem) => (
        <div>
          {item?.props.category || (
            <TFunctionProvider>{(translate) => translate(i18nKeys.general.nA)}</TFunctionProvider>
          )}
        </div>
      ),
    },
    {
      id: 'rating',
      sortingField: 'rating',
      sortingComparator: (a: ChallengeListItem, b: ChallengeListItem) => {
        return compareValues(a.globalStatistics.rating, b.globalStatistics.rating);
      },
      header: (
        <TFunctionProvider>
          {(translate) => translate(i18nKeys.challenges.browseChallenges.headers.rating)}
        </TFunctionProvider>
      ),
      cell: (item: ChallengeListItem) => (
        <StarRating value={item?.globalStatistics.rating} valueCount={item?.globalStatistics.ratingCount} showAverage />
      ),
    },
    {
      id: 'stability',
      sortingField: 'stability',
      sortingComparator: (a: ChallengeListItem, b: ChallengeListItem) => {
        return compareValues(a?.stability || null, b?.stability || null);
      },
      header: (
        <TFunctionProvider>
          {(translate) => translate(i18nKeys.challenges.browseChallenges.headers.stability)}
        </TFunctionProvider>
      ),
      cell: (item: ChallengeListItem) => <div>{getStabilityLabel(item?.stabilityLabel)}</div>,
    },
    {
      id: 'difficulty',
      sortingField: 'difficulty',
      sortingComparator: (a: ChallengeListItem, b: ChallengeListItem) => {
        return compareValues(a?.props.difficulty || null, b?.props.difficulty || null);
      },
      header: (
        <TFunctionProvider>
          {(translate) => translate(i18nKeys.challenges.browseChallenges.headers.difficulty)}
        </TFunctionProvider>
      ),
      cell: (item: ChallengeListItem) => <DifficultyIndicator challenge={item as Challenge} />,
    },
    {
      id: 'open-issues',
      sortingField: 'openIssues',
      sortingComparator: (a: ChallengeListItem, b: ChallengeListItem) => {
        return compareValues(
          a.globalStatistics.unresolvedChallengeIssues,
          b.globalStatistics.unresolvedChallengeIssues
        );
      },
      header: (
        <TFunctionProvider>
          {(translate) => translate(i18nKeys.challenges.browseChallenges.headers.openIssues)}
        </TFunctionProvider>
      ),
      cell: (item: ChallengeListItem) => (
        <Badge color={item?.globalStatistics?.highestIssueSeverityBadgeType}>
          {item?.globalStatistics?.unresolvedChallengeIssues}
        </Badge>
      ),
    },
    {
      id: 'average-solve-time',
      sortingField: 'averageSolveTime',
      sortingComparator: (a: ChallengeListItem, b: ChallengeListItem) => {
        return compareValues(
          a.globalStatistics?.solveTimes.trimmedAvgSeconds || null,
          b.globalStatistics?.solveTimes.trimmedAvgSeconds || null
        );
      },
      header: (
        <TFunctionProvider>
          {(translate) => translate(i18nKeys.challenges.browseChallenges.headers.avgSolveTime)}
        </TFunctionProvider>
      ),
      cell: (item: ChallengeListItem) => (
        <div>
          {getDuration(item.globalStatistics?.solveTimes?.trimmedAvgSeconds) || (
            <TFunctionProvider>{(translate) => translate(i18nKeys.general.nA)}</TFunctionProvider>
          )}
        </div>
      ),
    },
    {
      id: 'jams-used',
      sortingField: 'jamsUsed',
      sortingComparator: (a: ChallengeListItem, b: ChallengeListItem) => {
        return compareValues(a.globalStatistics?.jamsUsed, b.globalStatistics?.jamsUsed);
      },
      header: (
        <TFunctionProvider>
          {(translate) => translate(i18nKeys.challenges.browseChallenges.headers.jamsUsed)}
        </TFunctionProvider>
      ),
      cell: (item: ChallengeListItem) => <Badge color="blue">{item?.globalStatistics.jamsUsed}</Badge>,
    },
    {
      id: 'first-used',
      sortingField: 'firstUsed',
      sortingComparator: (a: ChallengeListItem, b: ChallengeListItem) => {
        return compareValues(a.globalStatistics.firstUsed, b.globalStatistics.firstUsed);
      },
      header: (
        <TFunctionProvider>
          {(translate) => translate(i18nKeys.challenges.browseChallenges.headers.firstUsed)}
        </TFunctionProvider>
      ),
      cell: (item: ChallengeListItem) => (
        <div>
          {item?.globalStatistics.firstUsed ? (
            <TimeAgo datetime={item.globalStatistics?.firstUsed} />
          ) : (
            <TFunctionProvider>{(translate) => translate(i18nKeys.general.nA)}</TFunctionProvider>
          )}
        </div>
      ),
    },
    {
      id: 'last-used',
      sortingField: 'lastUsed',
      sortingComparator: (a: ChallengeListItem, b: ChallengeListItem) => {
        return compareValues(
          a.globalStatistics?.lastUsed ? Date.now() - a.globalStatistics?.lastUsed : null,
          b.globalStatistics?.lastUsed ? Date.now() - b.globalStatistics?.lastUsed : null
        );
      },
      header: (
        <TFunctionProvider>
          {(translate) => translate(i18nKeys.challenges.browseChallenges.headers.lastUsed)}
        </TFunctionProvider>
      ),
      cell: (item: ChallengeListItem) => (
        <div>
          {item?.globalStatistics.lastUsed ? (
            <TimeAgo datetime={item.globalStatistics?.lastUsed} />
          ) : (
            <TFunctionProvider>{(translate) => translate(i18nKeys.general.nA)}</TFunctionProvider>
          )}
        </div>
      ),
    },
    {
      id: 'aws-services',
      header: (
        <TFunctionProvider>
          {(translate) => translate(i18nKeys.challenges.browseChallenges.headers.awsServices)}
        </TFunctionProvider>
      ),
      cell: (item: ChallengeListItem) => (
        <div>
          {item?.props.awsServices && item.props.awsServices.length > 0 && (
            <TPopover
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              headerKey={i18nKeys.challenges.browseChallenges.headers.awsServices}
              content={
                <SpaceBetween direction="vertical" size="s">
                  {item?.props.awsServices.map((awsService, key) => (
                    <Badge color="blue" key={`awsService-${key}`}>
                      {awsService}
                    </Badge>
                  ))}
                </SpaceBetween>
              }>
              {item?.props.awsServices.length > 1 ? (
                <TFunctionProvider>
                  {(translate) =>
                    translate(i18nKeys.challenges.browseChallenges.labels.services, {
                      serviceCount: item?.props.awsServices.length,
                    })
                  }
                </TFunctionProvider>
              ) : (
                <TFunctionProvider>
                  {(translate) =>
                    translate(i18nKeys.challenges.browseChallenges.labels.service, {
                      serviceCount: item?.props.awsServices.length,
                    })
                  }
                </TFunctionProvider>
              )}
            </TPopover>
          )}
        </div>
      ),
    },
  ];
};
