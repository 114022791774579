/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { map } from 'bluebird';
import { QueryParams } from './types';
import {
  Event,
  EventBase,
  EventChallengeFeedback,
  EventConfiguration,
  EventFeedback,
  EventFilterOptions,
  JamEventRequest,
  TeamChallengeProperties,
  TinyEvent,
} from '../types/Event';
import { DateRangeFilter, KeyValue } from '../types/common';
import { i18nKeys } from '../utils/i18n.utils';
import { ApiClient } from './ApiClient';
import { asList } from '../utils/list.utils';
import { fromPlainObject } from '../utils/mapper.utils';
import { downloadAsPEM, downloadObjectAsJson } from '../utils/download.utils';
import { GetPoolsResponse } from '../types/Pools';
import { Team, TeamMember } from '../types/Team';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { TFunction } from 'i18next';
import { EventLabSummary } from '../types/EventLabSummary';
import { Lab, LabCloudFormationDetails, LabsByChallengeId, LabSignInDetails } from '../types/LabModels';
import { ResourceDeploymentItem } from '../types/ResourceDeployment';
import { MILLIS_PER_DAY } from "../constants/DateTimeConstants";
import { LocalizedError } from "../types/LocalizedError";
import { Dispatch, SetStateAction } from "react";
import { LoggingService } from '../utils/logging-service.utils';

@jsonObject
export class GetEventByNameResponse {
  @jsonMember(Event)
  event: Event = new Event();
  @jsonArrayMember(Team)
  teams: Team[] = [];
  @jsonArrayMember(TeamMember)
  unassignedParticipants: TeamMember[] = [];
}

export class EventsAPI {
  // The batch size in days. This is the number of days that are queried at a time in getEventsByDateRange.
  readonly getEventsBatchSize: number = 10;
  // The default date range offset in days. This is used if the start or end date is not provided.
  readonly defaultDateRangeOffset: number = 60;

  // eslint-disable-next-line no-empty-function
  constructor(private apiClient: ApiClient, private t: TFunction) {}

  private teamSignInDetailsCache: { [awsAccountNumber: string]: LabSignInDetails } = {};
  private adminSignInDetailsCache: { [awsAccountNumber: string]: LabSignInDetails } = {};
  private masterSignInDetailsCache: { [awsAccountNumber: string]: LabSignInDetails } = {};

  /**
   * Retrieves list of events in supplied date range
   *
   * @param range Date range to retrieve events for
   * @param silent Optional param to enable silent failure
   * @param setEvents Optional function to set the events state as the batches are fetched
   * @returns a list of events in date range
   */
  public async getEventsByDateRange(range?: DateRangeFilter, silent = false, setEvents: Dispatch<SetStateAction<Event[] | undefined>> | null = null): Promise<Event[]> {
    const params: QueryParams = {};
    const now = Date.now();
    const defaultRangeStart = EventsAPI.formatDate(new Date(now - this.defaultDateRangeOffset * MILLIS_PER_DAY));
    const defaultRangeEnd = EventsAPI.formatDate(new Date(now + this.defaultDateRangeOffset * MILLIS_PER_DAY));

    params.dateRangeStart = range && range.start ? range.start : defaultRangeStart;
    params.dateRangeEnd = range && range.end ? range.end : defaultRangeEnd;

    LoggingService.debug(`getEventsByDateRange: ${params.dateRangeStart} - ${params.dateRangeEnd}`);

    if (EventsAPI.getDateFromString(params.dateRangeStart) > EventsAPI.getDateFromString(params.dateRangeEnd)) {
      throw new LocalizedError(i18nKeys.errors.validation.startDateCannotBeAfterEndDate);
    } else {
      return this.getEventsInBatches(silent, params, setEvents);
    }
  }

  // This method breaks the time range into cadence defined as getEventsBatchSize and fetches events as new request
  // between these time period as a workaround to Lambda 6MB payload size limit
  async getEventsInBatches(silent: boolean, params: QueryParams, setEvents: Dispatch<SetStateAction<Event[] | undefined>> | null = null): Promise<Event[]> {
    const start = new Date(Date.parse(params.dateRangeStart as string));
    const end = new Date(Date.parse(params.dateRangeEnd as string));
    const eventMap: Map<string, Event> = new Map();
    
    LoggingService.debug(`getEventsInBatches: START ${start.toISOString()} - ${end.toISOString()}`);

    for (let dateIterator: Date = start; dateIterator < end; dateIterator.setDate(dateIterator.getDate() + 1)) {
      const queryParams: QueryParams = {};
      queryParams.dateRangeStart = EventsAPI.formatDate(dateIterator);
      const nextDate = dateIterator;
      nextDate.setDate(dateIterator.getDate() + this.getEventsBatchSize);
      dateIterator = nextDate > end ? end : nextDate;
      queryParams.dateRangeEnd = EventsAPI.formatDate(dateIterator);
      const eventBatch: Promise<Event[]> = this.apiClient.get({
        path: '/admin/events',
        failMessage: this.t(i18nKeys.errors.requestFailed.getEvents),
        responseMapper: asList((object: { event: unknown }) => fromPlainObject(object.event, Event)),
        params: queryParams,
        silent,
      });
      const eventList: Event[] = await eventBatch;
      // Collect into a map so that we remove any duplicates that are returned for any reason
      eventList.map((event) => eventMap.set(event.name, event));

      LoggingService.debug(`getEventsInBatches: ${queryParams.dateRangeStart} - ${queryParams.dateRangeEnd} found ${eventList.length}; Total ${eventMap.size}`);

      if (setEvents != null) {
        // Set events here so that the user does not have to wait as long to start seeing events in the list
        const events = Array.from(eventMap.values());
        setEvents(events);
      }
    }

    LoggingService.debug(`getEventsInBatches: END ${start.toISOString()} - ${end.toISOString()}`);

    return Array.from(eventMap.values());
  }

  private static formatDate(date: Date): string {
    return date.toISOString().substring(0, 10);
  }

  private static getDateFromString(date: string): Date {
    return new Date(Date.parse(date));
  }

  /**
   * We ignore this next line because we can't test responseMapper option when mocking calls in tests
   *
   * @param eventName Name to retrieve event details for
   * @param silent Optional param to enable silent failure
   * @returns an Event response
   */
  public async getEvent(eventName: string, silent = false): Promise<GetEventByNameResponse> {
    const response: GetEventByNameResponse = await this.apiClient.get({
      path: `/admin/events/${eventName}`,
      failMessage: this.t(i18nKeys.errors.requestFailed.getEvent),
      responseMapper: (object) => fromPlainObject(object, GetEventByNameResponse),
      silent,
    });
    return response;
  }

  public async createEvent(event: Event, silent = false): Promise<any> {
    if (event.eventCode === '') {
      event.eventCode = null;
    }

    return this.apiClient.post({
      path: '/admin/events/create',
      body: event,
      successMessage: this.t(i18nKeys.success.requestSucceeded.events.create),
      failMessage: this.t(i18nKeys.errors.requestFailed.events.create),
      silent,
    });
  }

  public async createJamEventRequest(request: JamEventRequest, silent = false): Promise<Event> {
    return this.apiClient.post({
      path: '/admin/events/request/new',
      body: request,
      successMessage: this.t(i18nKeys.success.requestSucceeded.events.createJamEventRequest),
      failMessage: this.t(i18nKeys.errors.requestFailed.events.createJamEventRequest),
      responseMapper: (res) => fromPlainObject(res.event, Event),
      silent,
    }) as Promise<Event>;
  }

  public async updateJamEventRequest(eventName: string, request: JamEventRequest, silent = false): Promise<Event> {
    return this.apiClient.post({
      path: `/admin/events/${eventName}/request/update`,
      body: request,
      successMessage: this.t(i18nKeys.success.requestSucceeded.events.updateJamEventRequest),
      failMessage: this.t(i18nKeys.errors.requestFailed.events.updateJamEventRequest),
      responseMapper: (res) => fromPlainObject(res.event, Event),
      silent,
    }) as Promise<Event>;
  }

  public async createJamEventChangeRequest(
    eventName: string,
    request: JamEventRequest,
    silent = false
  ): Promise<Event> {
    return this.apiClient.post({
      path: `/admin/events/${eventName}/change-request/new`,
      body: request,
      successMessage: this.t(i18nKeys.success.requestSucceeded.events.createJamEventChangeRequest),
      failMessage: this.t(i18nKeys.errors.requestFailed.events.createJamEventChangeRequest),
      responseMapper: (res) => fromPlainObject(res.event, Event),
      silent,
    }) as Promise<Event>;
  }

  public async updateJamEventChangeRequest(
    eventName: string,
    request: JamEventRequest,
    silent = false
  ): Promise<Event> {
    return this.apiClient.post({
      path: `/admin/events/${eventName}/change-request/update`,
      body: request,
      successMessage: this.t(i18nKeys.success.requestSucceeded.events.updateJamEventChangeRequest),
      failMessage: this.t(i18nKeys.errors.requestFailed.events.updateJamEventChangeRequest),
      responseMapper: (res) => fromPlainObject(res.event, Event),
      silent,
    }) as Promise<Event>;
  }

  public async unlockChallenges(eventName: string, silent = false): Promise<void> {
    await this.apiClient.post({
      path: `/admin/events/${eventName}/challenges/unlock`,
      failMessage: this.t(i18nKeys.errors.requestFailed.unlockChallenges),
      silent,
    });
  }

  public async lockChallenges(eventName: string, silent = false): Promise<void> {
    await this.apiClient.post({
      path: `/admin/events/${eventName}/challenges/lock`,
      failMessage: this.t(i18nKeys.errors.requestFailed.lockChallenges),
      silent,
    });
  }

  public async cancelEvent(eventName: string, comment: string, silent = false): Promise<void> {
    await this.apiClient.post({
      path: `/admin/events/${eventName}/cancel`,
      body: { comment },
      successMessage: this.t(i18nKeys.success.requestSucceeded.cancelEvent),
      failMessage: this.t(i18nKeys.errors.requestFailed.cancelEvent),
      silent,
    });
  }

  public async deleteEvent(eventName: string, silent = false): Promise<void> {
    await this.apiClient.delete({
      path: `/admin/events/${eventName}`,
      successMessage: this.t(i18nKeys.success.requestSucceeded.deleteEvent),
      failMessage: this.t(i18nKeys.errors.requestFailed.deleteEvent),
      silent,
    });
  }

  async updateTags(event: Event, silent = false) {
    return this.apiClient.post({
      path: `/admin/events/${event.name}/tags`,
      body: {
        tags: event.tags || [],
      },
      failMessage: this.t(i18nKeys.errors.requestFailed.events.updateTags),
      silent,
      responseMapper: (res) => fromPlainObject(res.event, Event),
    });
  }

  public async addComment(eventName: string, commentValue: string, silent = false): Promise<void> {
    await this.apiClient.post({
      path: `/admin/events/${eventName}/comments/create`,
      body: { value: commentValue },
      successMessage: this.t(i18nKeys.success.requestSucceeded.comments.addComment),
      failMessage: this.t(i18nKeys.errors.requestFailed.comments.addComment),
      silent,
    });
  }

  public async updateEvent(event: Event, silent = false): Promise<Event> {
    const payload: EventBase = fromPlainObject(event, EventBase) as EventBase;

    if (event.eventCode === '') {
      payload.eventCode = null;
    }

    return this.apiClient.post({
      path: `/admin/events/update/${event.name}`,
      body: payload,
      successMessage: this.t(i18nKeys.success.requestSucceeded.events.updateEvent),
      failMessage: this.t(i18nKeys.errors.requestFailed.events.update),
      responseMapper: (res) => fromPlainObject(res.event, Event),
      silent,
    }) as Promise<Event>;
  }

  public async approveJamEventRequest(
    eventName: string,
    eventId: string,
    comment: string,
    silent = false
  ): Promise<Event> {
    return this.apiClient.post({
      path: `/admin/events/${eventName}/request/approve`,
      body: { eventId, comment },
      successMessage: this.t(i18nKeys.success.requestSucceeded.events.approveEventRequest),
      failMessage: this.t(i18nKeys.errors.requestFailed.events.approveEventRequest),
      responseMapper: (res) => fromPlainObject(res.event, Event),
      silent,
    }) as Promise<Event>;
  }

  public async denyJamEventRequest(eventName: string, comment: string, silent = false): Promise<Event> {
    return this.apiClient.post({
      path: `/admin/events/${eventName}/request/deny`,
      body: { comment },
      successMessage: this.t(i18nKeys.success.requestSucceeded.events.denyEventRequest),
      failMessage: this.t(i18nKeys.errors.requestFailed.events.denyEventRequest),
      responseMapper: (res) => fromPlainObject(res.event, Event),
      silent,
    }) as Promise<Event>;
  }

  public async cancelJamEventRequest(eventName: string, comment: string, silent = false): Promise<Event> {
    return this.apiClient.post({
      path: `/admin/events/${eventName}/request/cancel`,
      body: { comment },
      successMessage: this.t(i18nKeys.success.requestSucceeded.events.cancelEventRequest),
      failMessage: this.t(i18nKeys.errors.requestFailed.events.cancelEventRequest),
      responseMapper: (res) => fromPlainObject(res.event, Event),
      silent,
    }) as Promise<Event>;
  }

  public async approveJamEventChangeRequest(eventName: string, comment: string, silent = false): Promise<Event> {
    return this.apiClient.post({
      path: `/admin/events/${eventName}/change-request/approve`,
      body: { comment },
      successMessage: this.t(i18nKeys.success.requestSucceeded.events.approveChangeRequest),
      failMessage: this.t(i18nKeys.errors.requestFailed.events.approveChangeRequest),
      responseMapper: (res) => fromPlainObject(res.event, Event),
      silent,
    }) as Promise<Event>;
  }

  public async denyJamEventChangeRequest(eventName: string, comment: string, silent = false): Promise<Event> {
    return this.apiClient.post({
      path: `/admin/events/${eventName}/change-request/deny`,
      body: { comment },
      successMessage: this.t(i18nKeys.success.requestSucceeded.events.denyChangeRequest),
      failMessage: this.t(i18nKeys.errors.requestFailed.events.denyChangeRequest),
      responseMapper: (res) => fromPlainObject(res.event, Event),
      silent,
    }) as Promise<Event>;
  }

  public async cancelJamEventChangeRequest(eventName: string, comment: string, silent = false): Promise<Event> {
    return this.apiClient.post({
      path: `/admin/events/${eventName}/change-request/cancel`,
      body: { comment },
      successMessage: this.t(i18nKeys.success.requestSucceeded.events.cancelChangeRequest),
      failMessage: this.t(i18nKeys.errors.requestFailed.events.cancelChangeRequest),
      responseMapper: (res) => fromPlainObject(res.event, Event),
      silent,
    }) as Promise<Event>;
  }

  public async resetEvent(eventName: string, silent = false): Promise<void> {
    await this.apiClient.get({
      path: `/admin/events/${eventName}/reset`,
      successMessage: this.t(i18nKeys.success.requestSucceeded.events.resetEvent),
      failMessage: this.t(i18nKeys.errors.requestFailed.events.resetEvent),
      silent,
    });
  }

  public async resetEventTeams(eventName: string, silent = false): Promise<void> {
    await this.apiClient.get({
      path: `/admin/events/${eventName}/reset-teams`,
      successMessage: this.t(i18nKeys.success.requestSucceeded.events.resetEventTeams),
      failMessage: this.t(i18nKeys.errors.requestFailed.events.resetEventTeams),
      silent,
    });
  }

  public async updateComment(
    eventName: string,
    commentId: string,
    commentValue: string,
    silent = false
  ): Promise<void> {
    await this.apiClient.post({
      path: `/admin/events/${eventName}/comments/update`,
      body: { id: commentId, value: commentValue },
      successMessage: this.t(i18nKeys.success.requestSucceeded.comments.updateComment),
      failMessage: this.t(i18nKeys.errors.requestFailed.comments.updateComment),
      silent,
    });
  }

  public async deleteComment(eventName: string, commentId: string, silent = false): Promise<void> {
    await this.apiClient.post({
      path: `/admin/events/${eventName}/comments/delete`,
      body: { id: commentId },
      successMessage: this.t(i18nKeys.success.requestSucceeded.comments.deleteComment),
      failMessage: this.t(i18nKeys.errors.requestFailed.comments.deleteComment),
      silent,
    });
  }

  public async uploadTeamProperties(
    event: Event,
    teamProperties: TeamChallengeProperties[],
    silent = false
  ): Promise<void> {
    return this.apiClient.post({
      path: `/admin/events/${event.name}/challenge-properties`,
      body: { teamProperties },
      successMessage: this.t(i18nKeys.success.requestSucceeded.events.uploadTeamProperties),
      failMessage: this.t(i18nKeys.errors.requestFailed.events.uploadTeamProperties),
      silent,
    }) as Promise<void>;
  }

  public async getEventFeedback(eventName: string, silent = false): Promise<EventFeedback[]> {
    const response = await this.apiClient.get({
      path: `/admin/events/${eventName}/feedback`,
      failMessage: this.t(i18nKeys.errors.requestFailed.eventFeedback, { eventName }),
      silent,
    });
    return response as Promise<EventFeedback[]>;
  }

  public async getEventChallengeFeedback(eventName: string, silent = false): Promise<EventChallengeFeedback> {
    const response = await this.apiClient.get({
      path: `/admin/events/${eventName}/feedback/challenges`,
      failMessage: this.t(i18nKeys.errors.requestFailed.eventChallengeFeedback, { eventName }),
      silent,
    });
    return response as Promise<EventChallengeFeedback>;
  }

  public async getEventConfig(silent = false): Promise<EventConfiguration | undefined> {
    const response: EventConfiguration | undefined = await this.apiClient.get({
      path: '/admin/events/config',
      failMessage: this.t(i18nKeys.errors.requestFailed.loadConfig),
      silent,
    });
    return response;
  }

  public async reevaluateWithPlan(eventName: string, silent = false): Promise<void> {
    await this.apiClient.post({
      path: `admin/events/${eventName}/reevaluate`,
      failMessage: this.t(i18nKeys.errors.requestFailed.usagePlan.reevaluate),
      silent,
    });
  }

  public async getPools(silent = false): Promise<GetPoolsResponse> {
    return this.apiClient.get({
      path: `/admin/events/labs/pools`,
      responseMapper: (res) => GetPoolsResponse.fromPlainObject(res),
      failMessage: this.t(i18nKeys.errors.requestFailed.getPools),
      silent,
    }) as Promise<GetPoolsResponse>;
  }

  public async downloadTeamChallengeProperties(eventName: string, challengeId: string, silent = false): Promise<void> {
    const obj: any = await this.apiClient.get({
      path: `/admin/events/${eventName}/challenge-properties/${challengeId}`,
      failMessage: this.t(i18nKeys.errors.requestFailed.downloadChallengeTeamProperties),
      silent,
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    downloadObjectAsJson(obj, `${challengeId}-${eventName}-team-properties`);
  }

  public async getTinyEvents(options?: EventFilterOptions, silent = false): Promise<TinyEvent[]> {
    const params: QueryParams = Object.assign({}, options as QueryParams);

    return this.apiClient.get({
      path: '/admin/events/min',
      failMessage: this.t(i18nKeys.errors.requestFailed.getEvents),
      params,
      silent,
    }) as Promise<TinyEvent[]>;
  }

  public async getParticipantSolvedExport(eventName: string, silent = false): Promise<string[]> {
    const emails: string[] = await this.apiClient.get({
      path: `/admin/events/${eventName}/participants/solved/export`,
      failMessage: this.t(i18nKeys.errors.requestFailed.participants.export),
      silent,
    });

    return emails;
  }

  public async getAllParticipantsExport(eventName: string, silent = false): Promise<string[]> {
    const emails: string[] = await this.apiClient.get({
      path: `/admin/events/${eventName}/participants/all/export`,
      failMessage: this.t(i18nKeys.errors.requestFailed.participants.export),
      silent,
    });

    return emails;
  }

  public async getEventLabSummary(eventName: string, silent = false): Promise<EventLabSummary | null> {
    // fix bug where the "failed to get lab data" error message pops up when
    // refreshing the "one click test events" page (race condition)
    if (!eventName) {
      return null;
    }

    return this.apiClient.get({
      path: `/admin/events/${eventName}/labs/summary`,
      responseMapper: (obj: any) => fromPlainObject(obj, EventLabSummary),
      failMessage: this.t(i18nKeys.errors.requestFailed.getEventLabSummary),
      silent,
    }) as Promise<EventLabSummary>;
  }

  /**
   * Get all labs for the provided event and challengeIds.
   * NOTE: INELIGIBLE/Master accounts are excluded.
   * NOTE: makes a request for each challenge, limited to 3 concurrent requests
   *
   * @param eventName
   * @param challengeIds
   * @param silent
   */
  public async getEventLabs(eventName: string, challengeIds: string[], silent = false): Promise<LabsByChallengeId> {
    const labsByChallengeId: LabsByChallengeId = {};

    // make all requests, with a concurrency of 3 at a time.
    await map(
      challengeIds,
      async (challengeId: string) => {
        const labs: Lab[] = await this.getChallengeLabs(eventName, challengeId, silent);
        labsByChallengeId[challengeId] = labs || [];
      },
      { concurrency: 3 }
    );

    return labsByChallengeId;
  }

  /**
   * Get all labs for a single challenge, filtering out INELIGIBLE/Master accounts, and adding the eventName
   * to each lab.
   *
   * @param eventName
   * @param challengeId
   * @param silent
   */
  public async getChallengeLabs(eventName: string, challengeId: string, silent = false): Promise<Lab[]> {
    return this.apiClient.get({
      path: `/admin/events/${eventName}/labs/${challengeId}`,
      responseMapper: (labs: Lab[]) => {
        // filter out master accounts from labs
        // filter out INELIGIBLE accounts from labs
        return (labs || [])
          .map((l) => {
            const lab: Lab = fromPlainObject(l, Lab) as Lab;
            lab.eventName = eventName;
            return lab;
          })
          .filter((lab) => !lab.master && lab.status !== 'INELIGIBLE');
      },
      failMessage: this.t(i18nKeys.errors.requestFailed.getChallengeLabs),
      silent,
    }) as Promise<Lab[]>;
  }

  public async getLabResourceDeploymentHistory(lab: Lab, silent = false): Promise<ResourceDeploymentItem[]> {
    return this.apiClient.get({
      path: `/admin/events/${lab.eventName}/labs/${lab.challengeId}/resource-history/${lab.awsAccountNumber}`,
      responseMapper: asList((obj) => fromPlainObject(obj, ResourceDeploymentItem)),
      failMessage: this.t(i18nKeys.errors.requestFailed.getLabResourceDeploymentHistory),
      silent,
    }) as Promise<ResourceDeploymentItem[]>;
  }

  public async getChallengeLabTeamSignIn(lab: Lab, fetch = true, silent = false): Promise<LabSignInDetails> {
    if (fetch) {
      this.teamSignInDetailsCache[lab.awsAccountNumber || ''] = await this.apiClient.get({
        path: `/admin/events/${lab.eventName}/labs/${lab.challengeId}/accounts/${lab.id}/sign-in/team`,
        failMessage: this.t(i18nKeys.errors.requestFailed.getChallengeLabTeamSignIn),
        silent,
      });
    }
    return this.teamSignInDetailsCache[lab.awsAccountNumber || ''];
  }

  public async getChallengeLabAdminSignIn(lab: Lab, fetch = true, silent = false): Promise<LabSignInDetails> {
    if (fetch) {
      this.adminSignInDetailsCache[lab.awsAccountNumber || ''] = await this.apiClient.get({
        path: `/admin/events/${lab.eventName}/labs/${lab.challengeId}/accounts/${lab.id}/sign-in/admin`,
        failMessage: this.t(i18nKeys.errors.requestFailed.getChallengeLabAdminSignIn),
        silent,
      });
    }
    return this.adminSignInDetailsCache[lab.awsAccountNumber || ''];
  }

  public async getChallengeLabMasterSignIn(lab: Lab, fetch = true, silent = false): Promise<LabSignInDetails> {
    if (fetch) {
      this.masterSignInDetailsCache[lab.awsAccountNumber || ''] = await this.apiClient.get({
        path: `/admin/events/${lab.eventName}/labs/${lab.challengeId}/accounts/${lab.sessionId}/master/sign-in`,
        failMessage: this.t(i18nKeys.errors.requestFailed.getChallengeLabMasterSignIn),
        silent,
      });
    }
    return this.masterSignInDetailsCache[lab.awsAccountNumber || ''];
  }

  public async getChallengeLabKeyPair(lab: Lab, silent = false): Promise<void> {
    await this.apiClient
      .get({
        path: `/admin/events/${lab.eventName}/labs/${lab.challengeId}/accounts/${lab.id}/keypair`,
        failMessage: this.t(i18nKeys.errors.requestFailed.getChallengeLabKeyPair),
        silent,
      })
      .then((obj: { name: string; key: string }) => {
        if (obj.key) {
          downloadAsPEM(obj.key, obj.name || 'keypair');
        }
      });
  }

  public async teamRestart(lab: Lab, reason: string, silent = false) {
    return this.apiClient.post({
      path: `/admin/events/${lab.eventName}/labs/${lab.challengeId}/accounts/${lab.awsAccountNumber}/unassign`,
      body: { reason },
      successMessage: this.t(i18nKeys.success.requestSucceeded.events.teamRestart),
      failMessage: this.t(i18nKeys.errors.requestFailed.teamRestart),
      silent,
    });
  }

  public async terminateLab(lab: Lab, reason: string, silent = false) {
    return this.apiClient.post({
      path: `/admin/events/${lab.eventName}/labs/${lab.challengeId}/terminate/${lab.id}`,
      body: { reason },
      successMessage: this.t(i18nKeys.success.requestSucceeded.events.terminateLab),
      failMessage: this.t(i18nKeys.errors.requestFailed.terminateLab),
      silent,
    });
  }

  public async getLabMetadata(lab: Lab, silent = false): Promise<{ [key: string]: string }> {
    return this.apiClient.get({
      path: `/admin/events/${lab.eventName}/labs/${lab.challengeId}/accounts/${lab.id}/metadata`,
      failMessage: this.t(i18nKeys.errors.requestFailed.getLabMetadata),
      silent,
    }) as Promise<{ [key: string]: string }>;
  }

  public async getChallengeLabCfnDetails(lab: Lab, silent = false): Promise<LabCloudFormationDetails> {
    return this.apiClient.get({
      path: `/admin/events/${lab.eventName}/labs/${lab.challengeId}/accounts/${lab.id}/stackInfo`,
      failMessage: this.t(i18nKeys.errors.requestFailed.events.getChallengeLabCfnDetails),
      silent,
    }) as Promise<LabCloudFormationDetails>;
  }
}
