import { ButtonDropdown, Header, Tabs } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEvents } from '../../../../store/events.context';
import { Nullable } from '../../../../types/common';
import { TeamMetricsRow } from '../../../../types/DetailedEventStatisticsReport';
import { Event } from '../../../../types/Event';
import { downloadObjectAsJson } from '../../../../utils/download.utils';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { HorizontalRule } from '../../../common/HorizontalRule';
import ChallengeMetrics from './ChallengeMetrics';
import TeamMetricDetails from './TeamMetrics/TeamMetricDetails';
import TeamMetricsTable from './TeamMetrics/TeamMetricsTable';

interface ReportProps {
  event: Event | undefined;
}

const Report: React.FC<ReportProps> = ({ event }) => {
  const { t } = useTranslation();
  const { loadEventReport, eventReport } = useEvents();
  const [selectedTeam, setSelectedTeam] = useState<Nullable<TeamMetricsRow>>(null);

  useEffect(() => {
    if (event) {
      loadEventReport(event.name);
    }
  }, []);

  const downloadAsJson = () => {
    if (eventReport) {
      const eventJson = JSON.stringify(eventReport, null, 2);
      downloadObjectAsJson(eventJson, (event?.id || 'jam') + '-event-report.json');
    }
  };

  return (
    <React.Fragment>
      {!selectedTeam && (
        <React.Fragment>
          <Header
            variant="h2"
            actions={
              <ButtonDropdown
                items={[
                  {
                    id: 'download-as-json',
                    text: t(i18nKeys.report.buttons.downloadAsJSON).toString(),
                    disabled: false,
                  },
                ]}
                variant="primary"
                onItemClick={() => downloadAsJson()}>
                {t(i18nKeys.report.buttons.downloadReport)}
              </ButtonDropdown>
            }>
            {t(i18nKeys.report.headers.report)}
          </Header>
          <HorizontalRule evenMargins className="zero-margin-bottom" />
          <Tabs
            tabs={[
              {
                id: 'challenge-metrics',
                label: t(i18nKeys.report.headers.challengeMetrics),
                content: (
                  <div>
                    {eventReport?.challengeMetrics.map((challengeMetrics, i) => {
                      if (challengeMetrics && challengeMetrics.challengeId) {
                        return (
                          <ChallengeMetrics
                            key={`challenge-metrics-row-${i}`}
                            challengeMetricsRow={challengeMetrics}
                            teamCount={eventReport.teamMetrics.length || 0}
                            title={eventReport.challengeTitles[challengeMetrics.challengeId]}
                          />
                        );
                      }
                    })}
                  </div>
                ),
              },
              {
                id: 'team-metrics',
                label: t(i18nKeys.report.headers.teamMetrics),
                content: (
                  <div>
                    {eventReport?.teamMetrics && (
                      <TeamMetricsTable teamMetrics={eventReport?.teamMetrics} selectTeam={setSelectedTeam} />
                    )}
                  </div>
                ),
              },
            ]}
          />
        </React.Fragment>
      )}
      {!!selectedTeam && <TeamMetricDetails teamMetrics={selectedTeam} selectTeam={setSelectedTeam} />}
    </React.Fragment>
  );
};

export default Report;
