import { ExpandableSection, Header, Icon, Input, Table } from '@amzn/awsui-components-react';
import React from 'react';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import { Event, EventScoringSettings } from '../../../types/Event';
import { toLower } from 'lodash';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../../utils/i18n.utils';
import { EditEventActions, useEditEvent } from '../../../store/edit-event.context';
import { useUser } from '../../../store/user.context';
import { Campaign } from '../../../types/Campaign';
import { EditCampaignActions, useEditCampaign } from '../../../store/edit-campaign.context';
import { ChallengeDifficulty } from '../../../types/Challenge';

interface ScoringProps {
  target: Event | Campaign;
}

const Scoring: React.FC<ScoringProps> = ({ target }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { editMode, handleUpdateEditEvent, editedEvent } = useEditEvent();
  const { campaignEditMode, editedCampaign, handleUpdateEditCampaign } = useEditCampaign();
  const isEvent = target instanceof Event;
  const canEdit = editMode || campaignEditMode;

  const DifficultyDictionary: { [key: string]: string } = {
    Easy: t(ChallengeDifficulty.FUNDAMENTAL.i18nKeyShort),
    Medium: t(ChallengeDifficulty.INTERMEDIATE.i18nKeyShort),
    Hard: t(ChallengeDifficulty.ADVANCED.i18nKeyShort),
    Expert: t(ChallengeDifficulty.EXPERT.i18nKeyShort),
  };

  const scoringSettings = isEvent
    ? target.scoringSettings
    : target.campaignSettings?.scoringSettings || new EventScoringSettings();
  const editedTarget = isEvent ? editedEvent : editedCampaign?.campaignSettings;

  const getDeduction = (clueNumber: number, difficulty: string): number => {
    difficulty = toLower(difficulty);
    if (editedTarget?.scoringSettings || scoringSettings) {
      const penaltyProperty = `clue${clueNumber}PenaltyPercent` as keyof EventScoringSettings;
      const scoreProperty = `${difficulty}Score` as keyof EventScoringSettings;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const multiplier = editedTarget?.scoringSettings
        ? editedTarget.scoringSettings[penaltyProperty]! / 100
        : scoringSettings[penaltyProperty]! / 100;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return Math.round(
        multiplier *
          (editedTarget?.scoringSettings
            ? editedTarget.scoringSettings[scoreProperty]!
            : scoringSettings[scoreProperty]!)
      );
    } else {
      return 0;
    }
  };

  const getTotalDeduction = (cluesUsed: number, difficulty: string): number => {
    let clueCount = 1;
    let totalPenalty = 0;
    while (clueCount <= cluesUsed) {
      totalPenalty += getDeduction(clueCount, difficulty);
      clueCount++;
    }
    return totalPenalty;
  };

  const getAdjustedScore = (cluesUsed: number, difficulty: string): number => {
    if (editedTarget?.scoringSettings || scoringSettings) {
      difficulty = toLower(difficulty);
      const scoreProperty = `${difficulty}Score` as keyof EventScoringSettings;
      const totalDeduction = getTotalDeduction(cluesUsed, difficulty);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return (
        (editedTarget?.scoringSettings
          ? editedTarget.scoringSettings[scoreProperty]!
          : scoringSettings[scoreProperty]!) - totalDeduction
      );
    } else {
      return 0;
    }
  };

  const handleScoringSettingUpdate = (scoringSettingProperty: keyof EventScoringSettings, percentage: string) => {
    if (isEvent) {
      handleUpdateEditEvent(EditEventActions.SCORING_SETTINGS, {
        ...editedTarget?.scoringSettings,
        [scoringSettingProperty]: Number(percentage),
      });
    } else {
      handleUpdateEditCampaign(EditCampaignActions.SCORING_SETTINGS, {
        ...editedTarget?.scoringSettings,
        [scoringSettingProperty]: Number(percentage),
      });
    }
  };

  return (
    <ExpandableSection
      variant="container"
      header={
        <Header variant="h2" description={t(i18nKeys.events.eventDetails.headers.scoring.description)}>
          {t(i18nKeys.events.eventDetails.headers.scoring.header)}
        </Header>
      }>
      <div>
        <span style={{ color: awsui.colorTextAccent }}>
          <Icon name="status-info" />
        </span>{' '}
        <strong>{t(i18nKeys.general.tip)}</strong> {t(i18nKeys.events.eventDetails.messages.penaltyTip)}
      </div>
      <div style={{ margin: '12px -20px -21px -20px' }}>
        <Table
          variant="full-page"
          columnDefinitions={[
            {
              id: 'difficulty',
              header: t(i18nKeys.events.eventDetails.headers.scoring.difficulty),
              cell: (e) => DifficultyDictionary[e.difficulty],
            },
            {
              id: 'score',
              header: t(i18nKeys.events.eventDetails.headers.scoring.score),
              cell: (e) =>
                canEdit && user ? (
                  <span>
                    <Input
                      className="numeric-input"
                      inputMode="numeric"
                      onChange={({ detail }) =>
                        handleScoringSettingUpdate(
                          (toLower(e.difficulty) + 'Score') as keyof EventScoringSettings,
                          detail.value
                        )
                      }
                      disabled={!target?.canEditAttribute('scoringSettings', user)}
                      value={
                        editedTarget?.scoringSettings[
                          (toLower(e.difficulty) + 'Score') as keyof EventScoringSettings
                        ]!.toString() || '0'
                      }
                    />{' '}
                    pts
                  </span>
                ) : (
                  <span>{e.score} pts</span>
                ),
            },
            {
              id: 'clue-1-penalty',
              header:
                canEdit && user ? (
                  <span>
                    {t(i18nKeys.events.eventDetails.headers.scoring.clue1PenaltyEdit)}
                    <Input
                      className="numeric-input"
                      inputMode="numeric"
                      disabled={!target?.canEditAttribute('scoringSettings', user)}
                      value={editedTarget?.scoringSettings?.clue1PenaltyPercent?.toString() || '0'}
                      onChange={({ detail }) => handleScoringSettingUpdate('clue1PenaltyPercent', detail.value)}
                    />
                    %
                  </span>
                ) : (
                  t(i18nKeys.events.eventDetails.headers.scoring.clue1Penalty, {
                    penaltyPercent: scoringSettings?.clue1PenaltyPercent,
                  })
                ),
              cell: (e) => (
                <span>
                  <strong style={{ color: awsui.colorTextStatusSuccess }}>- {getDeduction(1, e.difficulty)} pts</strong>
                  <span> ({getAdjustedScore(1, e.difficulty)} pts)</span>
                </span>
              ),
            },
            {
              id: 'clue-2-penalty',
              header:
                canEdit && user ? (
                  <span>
                    {t(i18nKeys.events.eventDetails.headers.scoring.clue2PenaltyEdit)}
                    <Input
                      className="numeric-input"
                      inputMode="numeric"
                      disabled={!target?.canEditAttribute('scoringSettings', user)}
                      value={editedTarget?.scoringSettings?.clue2PenaltyPercent?.toString() || '0'}
                      onChange={({ detail }) => handleScoringSettingUpdate('clue2PenaltyPercent', detail.value)}
                    />
                    %
                  </span>
                ) : (
                  t(i18nKeys.events.eventDetails.headers.scoring.clue2Penalty, {
                    penaltyPercent: scoringSettings?.clue2PenaltyPercent,
                  })
                ),
              cell: (e) => (
                <span>
                  <strong style={{ color: awsui.colorTextAccent }}>- {getDeduction(2, e.difficulty)} pts</strong>
                  <span> ({getAdjustedScore(2, e.difficulty)} pts)</span>
                </span>
              ),
            },
            {
              id: 'clue-3-penalty',
              header:
                canEdit && user ? (
                  <span>
                    {t(i18nKeys.events.eventDetails.headers.scoring.clue3PenaltyEdit)}
                    <Input
                      className="numeric-input"
                      inputMode="numeric"
                      disabled={!target?.canEditAttribute('scoringSettings', user)}
                      value={editedTarget?.scoringSettings?.clue3PenaltyPercent?.toString() || '0'}
                      onChange={({ detail }) => handleScoringSettingUpdate('clue3PenaltyPercent', detail.value)}
                    />
                    %
                  </span>
                ) : (
                  t(i18nKeys.events.eventDetails.headers.scoring.clue3Penalty, {
                    penaltyPercent: scoringSettings?.clue3PenaltyPercent,
                  })
                ),
              cell: (e) => (
                <span>
                  <strong style={{ color: awsui.colorTextStatusError }}>- {getDeduction(3, e.difficulty)} pts</strong>
                  <span> ({getAdjustedScore(3, e.difficulty)} pts)</span>
                </span>
              ),
            },
          ]}
          items={[
            {
              difficulty: 'Easy',
              score: scoringSettings?.easyScore,
            },
            {
              difficulty: 'Medium',
              score: scoringSettings?.mediumScore,
            },
            {
              difficulty: 'Hard',
              score: scoringSettings?.hardScore,
            },
            {
              difficulty: 'Expert',
              score: scoringSettings?.expertScore,
            },
          ]}
        />
      </div>
    </ExpandableSection>
  );
};

export default Scoring;
