import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Button,
  Checkbox,
  Pagination,
  SpaceBetween,
  Table,
  TableProps,
  TextFilter,
} from '@amzn/awsui-components-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../store/api.context';
import { useChallengeSet } from '../../../store/challenge-set.context';
import { useUser } from '../../../store/user.context';
import { ChallengeSet } from '../../../types/ChallengeSet';
import { i18nKeys } from '../../../utils/i18n.utils';
import { preProdLogger } from '../../../utils/log.utils';
import { ConfirmModal } from '../../common/ConfirmModal';
import { TableEmptyState } from '../../common/TableEmptyState';
import { TableHeader } from '../../common/TableHeader';
import { Link as ReactRouterLink } from 'react-router-dom';
import { COLUMN_DEFINITIONS, filteringFunction } from './challenge-set-list-config';
import { RoutePath } from '../../../RoutePath';

const ChallengeSetListItems: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { challengeSets, getChallengeSets } = useChallengeSet();
  const { challengeSetApi } = useApi();
  const [loading, setLoading] = useState(false);
  const [preferences] = useState({
    pageSize: 10,
    visibleColumns: ['title', 'challenges', 'createdDate', 'updatedDate', 'owner', 'actions'],
  });

  useEffect(() => {
    setLoading(true);
    getChallengeSets()
      .then(() => {
        loadFilters();
        setLoading(false);
      })
      .catch((err) => preProdLogger(err));
  }, []);

  const [filteredChallengeSets, setFilteredChallengeSets] = useState<ChallengeSet[]>();
  const [challengeSet, setChallengeSet] = useState<ChallengeSet>();
  const [featured, setFeatured] = useState(false);
  const [createdByMe, setCreatedByMe] = useState(false);

  const [visibleConfirmDelete, setVisibleConfirmDelete] = useState(false);

  useEffect(() => {
    loadFilters();
  }, [challengeSets, featured, createdByMe]);

  const loadFilters = () => {
    let currentChallengeSets = challengeSets;
    if (featured && currentChallengeSets) {
      currentChallengeSets = currentChallengeSets.filter((set: ChallengeSet) => set.featured);
    }
    if (createdByMe && user && currentChallengeSets) {
      currentChallengeSets = currentChallengeSets.filter((set: ChallengeSet) => set.owner === user.email);
    }
    setFilteredChallengeSets(currentChallengeSets);
  };

  const handleDelete = (set: ChallengeSet) => {
    setChallengeSet(set);
    setVisibleConfirmDelete(true);
  };

  const onConfirmDelete = () => {
    if (challengeSet) {
      challengeSetApi
        .deleteChallengeSet(challengeSet.id || '')
        .then(() => {
          getChallengeSets().catch((err) => preProdLogger(err));
          setVisibleConfirmDelete(false);
        })
        .catch((err) => preProdLogger(err));
    }
  };

  const lastUpdatedColumnIndex = COLUMN_DEFINITIONS(handleDelete).findIndex(
    (columnDef: TableProps.ColumnDefinition<ChallengeSet>) => columnDef.id === 'updatedDate'
  );

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    filteredChallengeSets || [],
    {
      filtering: {
        filteringFunction,
        empty: (
          <TableEmptyState
            title={t(i18nKeys.challenges.list.empty.title)}
            subtitle={t(i18nKeys.challenges.list.empty.subtitle)}
          />
        ),
        noMatch: (
          <TableEmptyState
            title={t(i18nKeys.tables.noMatch.title)}
            subtitle={t(i18nKeys.tables.noMatch.subtitle)}
            onClearFilter={() => actions.setFiltering('')}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {
        defaultState: {
          sortingColumn: COLUMN_DEFINITIONS(handleDelete)[lastUpdatedColumnIndex],
          isDescending: false,
        },
      },
    }
  );

  return (
    <React.Fragment>
      <Table
        {...collectionProps}
        variant="full-page"
        stickyHeader
        header={
          <TableHeader
            totalItems={challengeSets?.length || 0}
            title={t(i18nKeys.challenges.challengeSets)}
            showZero
            actionButtons={
              <ReactRouterLink to={RoutePath.NEW_CHALLENGE_SET}>
                <Button variant="primary">{t(i18nKeys.challenges.challengeSet.buttons.newChallengeSet)}</Button>
              </ReactRouterLink>
            }
          />
        }
        pagination={<Pagination {...paginationProps} />}
        loading={loading}
        loadingText={t(i18nKeys.challenges.loadingChallenges)}
        items={items}
        columnDefinitions={COLUMN_DEFINITIONS(handleDelete)}
        filter={
          <SpaceBetween direction="horizontal" size="s">
            <TextFilter
              className="text-filter"
              filteringPlaceholder={t(i18nKeys.challenges.challengeSet.table.placeholders.filtering)}
              {...filterProps}
              countText={t(i18nKeys.tables.matchesCount, { count: filteredItemsCount })}
              filteringAriaLabel={t(i18nKeys.challenges.filter.filteringLabel)}
            />
            <Checkbox className="mt-4" checked={featured} onChange={() => setFeatured(!featured)}>
              {t(i18nKeys.challenges.challengeSet.checkBox.featured)}
            </Checkbox>
            <Checkbox className="mt-4" checked={createdByMe} onChange={() => setCreatedByMe(!createdByMe)}>
              {t(i18nKeys.challenges.challengeSet.checkBox.createdByMe)}
            </Checkbox>
          </SpaceBetween>
        }
        resizableColumns
      />
      <ConfirmModal
        title={t(i18nKeys.challenges.challengeSet.titles.deleteChallengeSet)}
        message={t(i18nKeys.general.areYouSure)}
        confirmBtnLabel={t(i18nKeys.challenges.challengeSet.buttons.yesDelete)}
        visible={visibleConfirmDelete}
        onCancel={() => setVisibleConfirmDelete(false)}
        onConfirm={onConfirmDelete}
      />
    </React.Fragment>
  );
};

export default ChallengeSetListItems;
