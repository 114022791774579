import { Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import * as React from 'react';
import { Challenge, ChallengeReviewableSection } from '../../../../types/Challenge';
import ChallengeDetailsDetail from './Details';
import ChallengeSummaryDetail from './Summary';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { ChallengeReviewPopover } from '../../challengesCommon/ChallengeReviewPopover';

interface ChallengeSummaryDetailProps {
  challenge: Challenge;
}

const ChallengeSummaryDetails: React.FC<ChallengeSummaryDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  return (
    <Container
      header={
        <Header variant="h2" actions={<ChallengeReviewPopover section={ChallengeReviewableSection.SUMMARY} />}>
          {t(i18nKeys.challenges.challengeDetails.headings.summary)}
        </Header>
      }>
      <SpaceBetween direction="vertical" size="m">
        <ChallengeSummaryDetail challenge={challenge} />
        <ChallengeDetailsDetail challenge={challenge} />
      </SpaceBetween>
    </Container>
  );
};

export default ChallengeSummaryDetails;
