import { Icon } from '@amzn/awsui-components-react';
import React, { MouseEventHandler } from 'react';
import { useFlashbars } from '../../store/flashbar.context';
import { NullableString } from '../../types/common';
import { copyToClipboard } from '../../utils/copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../utils/i18n.utils';

interface SecretKeyProps {
  secretKey: NullableString | undefined;
}

const SecretKey: React.FC<SecretKeyProps> = ({ secretKey }) => {
  const { t } = useTranslation();
  const { addSuccessFlashbar } = useFlashbars();
  const copySecretKey = (): MouseEventHandler<HTMLSpanElement> | undefined => {
    copyToClipboard(secretKey);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    addSuccessFlashbar(t(i18nKeys.events.eventDetails.labels.secretKey.message));
    return;
  };

  return (
    <React.Fragment>
      <span
        className="badge-aws badge-aws-medium"
        style={{ cursor: 'pointer' }}
        onClick={copySecretKey}
        title={t(i18nKeys.events.eventDetails.labels.secretKey.title)}>
        <span className="hover-reveal">{secretKey}</span> <Icon className="hover-icon" name="copy" />
      </span>
    </React.Fragment>
  );
};
export default SecretKey;
