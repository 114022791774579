/* eslint-disable no-underscore-dangle */
import { Button, Container, FormField, Header, SpaceBetween, Textarea } from '@amzn/awsui-components-react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../store/api.context';
import { i18nKeys } from '../../../utils/i18n.utils';
import { preProdLogger } from '../../../utils/log.utils';
import { CopyToClipboard } from '../../common/CopyToClipboard';
import { KeyValue } from '../../common/KeyValue';
import { LocalizationItem } from './JamUILocalization';
interface LocalizationItemContainerProps {
  item: LocalizationItem;
  selectedLanguage: string;
}
const LocalizationItemContainer: React.FC<LocalizationItemContainerProps> = ({ item, selectedLanguage }) => {
  const { t } = useTranslation();
  const appName = 'jam-ui';
  const [overrideText, setOverrideText] = useState(item.override || '');
  const { settingsApi } = useApi();
  const [currentItem, setCurrentItem] = useState<LocalizationItem>(item);
  const [loading, setLoading] = useState(false);

  const hasChanges = () => {
    return currentItem._override !== overrideText;
  };

  const isValidPlaceholders = (newItem: LocalizationItem) => {
    const numOriginalParams = (newItem.english.match(/<param\s*\/>/g) || []).length;
    const numTranslatedParams = (newItem.override.match(/<param\s*\/>/g) || []).length;
    return numOriginalParams === numTranslatedParams;
  };

  const saveOverride = (newItem: LocalizationItem) => {
    setLoading(true);
    if (!isValidPlaceholders(newItem)) {
      setLoading(false);
      return;
    }
    settingsApi
      .overrideLocalizationMessage(appName, selectedLanguage, item.key, overrideText)
      .then(() => {
        const itemToSave = item;
        itemToSave._override = overrideText;
        setCurrentItem(itemToSave);
        setLoading(false);
      })
      .catch((err) => {
        preProdLogger('Error saving override', err.message);
        setLoading(false);
      });
  };

  const removeOverride = (newItem: LocalizationItem) => {
    setLoading(true);
    settingsApi
      .removeLocalizationOverride(appName, selectedLanguage, newItem.key)
      .then(() => {
        const itemToSave = item;
        itemToSave._override = '';
        itemToSave.override = '';
        setCurrentItem(itemToSave);
        setOverrideText(itemToSave.override);
        setLoading(false);
      })
      .catch((err) => {
        preProdLogger('Error removing override', err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (currentItem.override) {
      setOverrideText(currentItem.override);
    }
  }, []);

  return (
    <Container
      header={
        <Header
          variant="h3"
          actions={
            <SpaceBetween direction="horizontal" size="s">
              {currentItem._override && (
                <Button variant="normal" onClick={() => removeOverride(currentItem)} loading={loading}>
                  {t(i18nKeys.settings.jamUILocalization.buttons.removeOverride)}
                </Button>
              )}
              {hasChanges() && (
                <SpaceBetween direction="horizontal" size="s">
                  <Button variant="link" onClick={() => setOverrideText(currentItem._override || '')} loading={loading}>
                    {t(i18nKeys.general.cancel)}
                  </Button>
                  <Button variant="primary" onClick={() => saveOverride(item)} loading={loading}>
                    {t(i18nKeys.general.save)}
                  </Button>
                </SpaceBetween>
              )}
            </SpaceBetween>
          }>
          {currentItem.key}
        </Header>
      }>
      <SpaceBetween direction="vertical" size="s">
        <KeyValue
          label={
            <div>
              <strong>{t(i18nKeys.settings.jamUILocalization.labels.english)}</strong>
              <CopyToClipboard value={currentItem.english} icon />
            </div>
          }>
          {currentItem.english}
        </KeyValue>
        <KeyValue
          label={
            <div>
              <strong>{t(i18nKeys.settings.jamUILocalization.labels.automatedTranslation)}</strong>
              <CopyToClipboard value={currentItem.translated} icon />
            </div>
          }>
          {currentItem.translated}
        </KeyValue>
        <FormField label={<strong>{t(i18nKeys.settings.jamUILocalization.labels.override)}</strong>}>
          <Textarea
            disabled={loading}
            placeholder={t(i18nKeys.settings.jamUILocalization.placeholder.overrideTranslatedValue)}
            value={overrideText}
            onChange={({ detail }) => setOverrideText(detail.value)}
          />
        </FormField>
      </SpaceBetween>
    </Container>
  );
};

export default LocalizationItemContainer;
