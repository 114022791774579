import * as React from 'react';
import { Spinner } from '@amzn/awsui-components-react';
import { useComponentDidMountEffect } from '../../../hooks/useComponentDidMountEffect';
import { useAuth } from '../../../store/auth.context';
import { Location } from 'history';

export interface LoginProps {
  readonly redirectTo?: Location;
}

/**
 * Component to sign a user in, taking the route to redirect the user after sign-in through its props.
 */
export const Login: React.FC<LoginProps> = (props) => {
  const { authClient } = useAuth();

  useComponentDidMountEffect(async () => {
    if (!(await authClient.isSignedIn())) {
      await authClient.signIn(props.redirectTo);
    }
  });

  return <Spinner size="normal" />;
};
