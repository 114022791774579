/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import {
  Alert,
  Checkbox,
  CheckboxProps,
  FormField,
  Grid,
  Input,
  SpaceBetween,
  Textarea,
} from '@amzn/awsui-components-react';
import { i18nKeys } from '../../utils/i18n.utils';
import { ConfirmModal } from '../common/ConfirmModal';
import { SkillBuilderSubscription } from '../../types/SkillBuilderSubscription';
import { cloneDeep } from 'lodash';
import { generateTemplateUrl } from '../../utils/url.utils';

export interface TemplateUrlModalProps {
  visible: boolean;
  onDismiss: () => any;
}

export const TemplateUrlModal: React.FC<TemplateUrlModalProps> = ({ visible, onDismiss }) => {
  const { t } = useTranslation();
  const [inputVisible, setInputVisible] = useState(true);
  const [resultVisible, setResultVisible] = useState(false);
  const [generatedUrl, setGeneratedUrl] = useState('');
  const [title, setTitle] = useState('');
  const [notes, setNotes] = useState('');
  const [agreementId, setAgreementId] = useState('');
  const [tags, setTags] = useState('');
  const [owners, setOwners] = useState('');
  const [facilitators, setFacilitators] = useState('');
  const [skillbuilderSubs, setSkillbuilderSubs] = useState<SkillBuilderSubscription[]>([]);
  const [challenges, setChallenges] = useState('');
  const [challengeSets, setChallengeSets] = useState('');
  const [allFieldsEmptyOnSubmit, setAllFieldsEmptyOnSubmit] = useState(false);

  const getUrl = () => {
    const cloneEvent = {
      title,
      notes,
      agreementId,
      tags,
      owners,
      facilitators,
      skillbuilderSubs,
      challenges,
      challengeSets,
    };
    const templateUrl = generateTemplateUrl(cloneEvent);
    setInputVisible(false);
    setResultVisible(true);
    setGeneratedUrl(templateUrl || '');
  };

  const dismissCopyModal = () => {
    setResultVisible(false);
    setInputVisible(true);
    resetState();
    onDismiss();
  };

  const resetState = () => {
    setGeneratedUrl('');
    setTitle('');
    setNotes('');
    setAgreementId('');
    setTags('');
    setOwners('');
    setFacilitators('');
    setSkillbuilderSubs([]);
    setChallenges('');
    setChallengeSets('');
    setAllFieldsEmptyOnSubmit(false);
  };

  const onSkillbuilderChange = (detail: CheckboxProps.ChangeDetail, subscription: SkillBuilderSubscription) => {
    let existingSubs: SkillBuilderSubscription[];
    if (detail.checked) {
      existingSubs = cloneDeep(skillbuilderSubs);
      existingSubs.push(subscription);
    } else {
      existingSubs = skillbuilderSubs.filter((s) => s !== subscription);
    }
    setSkillbuilderSubs(existingSubs);
  };

  return (
    <div>
      <ConfirmModal
        onCancel={() => {
          resetState();
          onDismiss();
        }}
        confirmBtnLabel={t(i18nKeys.events.templateUrls.generateUrl.createButton)}
        onConfirm={getUrl}
        title={t(i18nKeys.events.templateUrls.generateUrl.title)}
        visible={visible && inputVisible}
        message={
          <div>
            <SpaceBetween direction="vertical" size="s">
              <Alert
                visible={allFieldsEmptyOnSubmit}
                type="error"
                header={t(i18nKeys.events.templateUrls.generateUrl.allFieldsEmpty)}
              />
              <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                {/* Title */}
                <FormField label={t(i18nKeys.events.fields.title.title)}>
                  <Input value={title} onChange={({ detail }) => setTitle(detail.value)} />
                </FormField>
                {/* SOW */}
                <FormField label={t(i18nKeys.events.eventDetails.headers.agreementId)}>
                  <Input value={agreementId} onChange={({ detail }) => setAgreementId(detail.value)} />
                </FormField>
              </Grid>
              {/* Skillbuilder */}
              <FormField label={t(i18nKeys.events.templateUrls.generateUrl.skillbuilder)}>
                <Checkbox
                  checked={skillbuilderSubs.includes(SkillBuilderSubscription.TEAM) || false}
                  onChange={({ detail }) => onSkillbuilderChange(detail, SkillBuilderSubscription.TEAM)}>
                  {t(i18nKeys.events.eventDetails.labels.teamSubscription)}
                </Checkbox>
                <Checkbox
                  checked={skillbuilderSubs.includes(SkillBuilderSubscription.INDIVIDUAL) || false}
                  onChange={({ detail }) => onSkillbuilderChange(detail, SkillBuilderSubscription.INDIVIDUAL)}>
                  {t(i18nKeys.events.eventDetails.labels.individualSubscription)}
                </Checkbox>
              </FormField>
              {/* Owners */}
              <FormField
                label={t(i18nKeys.events.eventDetails.headers.owner)}
                description={t(
                  i18nKeys.events.templateUrls.generateUrl.subText,
                  t(i18nKeys.events.eventDetails.headers.owner)
                )}>
                <Input value={owners} onChange={({ detail }) => setOwners(detail.value)} />
              </FormField>
              {/* Facilitators */}
              <FormField
                label={t(i18nKeys.events.eventDetails.headers.facilitator)}
                description={t(
                  i18nKeys.events.templateUrls.generateUrl.subText,
                  t(i18nKeys.events.eventDetails.headers.facilitator)
                )}>
                <Input value={facilitators} onChange={({ detail }) => setFacilitators(detail.value)} />
              </FormField>
              {/* Challenges */}
              <FormField
                label={t(i18nKeys.challenges.title)}
                description={t(i18nKeys.events.templateUrls.generateUrl.subText, t(i18nKeys.challenges.title))}>
                <Input value={challenges} onChange={({ detail }) => setChallenges(detail.value)} />
              </FormField>

              <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                {/* Tags */}
                <FormField
                  label={t(i18nKeys.events.eventDetails.headers.tags)}
                  description={t(
                    i18nKeys.events.templateUrls.generateUrl.subText,
                    t(i18nKeys.events.eventDetails.headers.tags)
                  )}>
                  <Input value={tags} onChange={({ detail }) => setTags(detail.value)} />
                </FormField>
                {/* Challenge Sets */}
                <FormField
                  label={t(i18nKeys.challenges.importChallenges.fields.challengeSets.title)}
                  description={t(
                    i18nKeys.events.templateUrls.generateUrl.subText,
                    t(i18nKeys.challenges.importChallenges.fields.challengeSets.title)
                  )}>
                  <Input value={challengeSets} onChange={({ detail }) => setChallengeSets(detail.value)} />
                </FormField>
              </Grid>
              {/* Notes */}
              <FormField label={t(i18nKeys.newEvent.labels.notes)}>
                <Textarea value={notes} onChange={({ detail }) => setNotes(detail.value)} />
              </FormField>
            </SpaceBetween>
          </div>
        }
      />
      <ConfirmModal
        onCancel={() => {
          dismissCopyModal();
        }}
        onConfirm={() => {
          void navigator.clipboard.writeText(generatedUrl);
          dismissCopyModal();
        }}
        title={t(i18nKeys.events.templateUrls.generateUrl.resultTitle)}
        visible={visible && resultVisible}
        confirmBtnLabel={t(i18nKeys.clipboard.buttonLabel)}
        message={
          <div>
            {t(i18nKeys.events.templateUrls.generateUrl.resultMessage)}
            <code>{generatedUrl}</code>
          </div>
        }
      />
    </div>
  );
};
