import { Container, FormField, Header, Input, SpaceBetween, Wizard } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { RoutePath } from '../../../RoutePath';
import {
  CHALLENGE_SET_DESCRIPTION_MAX_LENGTH,
  CHALLENGE_SET_DESCRIPTION_MIN_LENGTH,
  CHALLENGE_SET_TITLE_MAX_LENGTH,
  CHALLENGE_SET_TITLE_MIN_LENGTH,
  useChallengeSet,
} from '../../../store/challenge-set.context';
import { useChallenges } from '../../../store/challenge.context';
import { useToolPanel } from '../../../store/tool-panel.context';
import { ChallengeDescriptor } from '../../../types/Challenge';
import { ChallengeSetRequest } from '../../../types/ChallengeSet';
import { i18nKeys } from '../../../utils/i18n.utils';
import { preProdLogger } from '../../../utils/log.utils';
import BrowseChallenges from '../../common/Challenges/BrowseChallenges';

const CreateChallengeSet: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { toggleChallengeInfo } = useToolPanel();
  const { getChallengeDescriptors } = useChallenges();
  const { createChallengeSet } = useChallengeSet();

  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [challengeSetRequest, setChallengeSetRequest] = useState<ChallengeSetRequest>(new ChallengeSetRequest());
  const [currentChallengeDescriptors, setCurrentChallengeDescriptors] = useState<ChallengeDescriptor[]>([]);

  useEffect(() => {
    setCurrentChallengeDescriptors(getChallengeDescriptors(challengeSetRequest.challengeIds));
  }, [challengeSetRequest]);

  const handleChallengeAction = (payload: ChallengeDescriptor[]) => {
    if (challengeSetRequest) {
      const challengeIds: string[] = payload.map((cd) => cd.challengeId as string);
      setChallengeSetRequest((prevState) => ({ ...prevState, challengeIds }));
    }
  };

  const handleSubmit = () => {
    createChallengeSet(challengeSetRequest)
      .then((cs) => {
        if (cs) {
          history.push(`${cs.id}`);
        }
      })
      .catch((err) => preProdLogger(err));
  };

  return (
    <Wizard
      allowSkipTo
      activeStepIndex={activeStepIndex}
      onNavigate={({ detail }) => {
        setActiveStepIndex(detail.requestedStepIndex);
      }}
      onSubmit={handleSubmit}
      onCancel={() => {
        history.push(RoutePath.CHALLENGE_SETS);
      }}
      steps={[
        {
          title: t(i18nKeys.challenges.subSections.overview.title),
          content: (
            <Container header={<Header variant="h2">{t(i18nKeys.challenges.subSections.overview.title)}</Header>}>
              <SpaceBetween direction="vertical" size="l">
                <FormField
                  label={t(i18nKeys.challenges.fields.title.title) + '*'}
                  constraintText={t(i18nKeys.challenges.subSections.overview.fields.title.constraint, {
                    CHALLENGE_SET_TITLE_MIN_LENGTH,
                    CHALLENGE_SET_TITLE_MAX_LENGTH,
                  })}>
                  <Input
                    value={challengeSetRequest.title || ''}
                    onChange={({ detail }) =>
                      setChallengeSetRequest((prevState) => ({ ...prevState, title: detail.value }))
                    }
                    placeholder={t(i18nKeys.challenges.challengeSet.placeholder.title)}
                  />
                </FormField>
                <FormField
                  label={t(i18nKeys.challenges.fields.description.title) + '*'}
                  constraintText={t(i18nKeys.challenges.subSections.overview.fields.description.constraint, {
                    CHALLENGE_SET_DESCRIPTION_MIN_LENGTH,
                    CHALLENGE_SET_DESCRIPTION_MAX_LENGTH,
                  })}>
                  <Input
                    value={challengeSetRequest.description || ''}
                    onChange={({ detail }) =>
                      setChallengeSetRequest((prevState) => ({ ...prevState, description: detail.value }))
                    }
                    placeholder={t(i18nKeys.challenges.challengeSet.placeholder.description)}
                  />
                </FormField>
              </SpaceBetween>
            </Container>
          ),
        },
        {
          title: t(i18nKeys.challenges.challengeSet.header.challengeSelection),
          description: t(i18nKeys.challenges.challengeSet.message.challengeSelectionDescription),
          content: (
            <BrowseChallenges
              handleChallengeAction={handleChallengeAction}
              currentChallengeDescriptors={currentChallengeDescriptors}
              toggleChallengeInfo={toggleChallengeInfo}
            />
          ),
        },
      ]}
      i18nStrings={{
        stepNumberLabel: (stepNumber) => t(i18nKeys.general.stepCounter, { count: stepNumber }),
        collapsedStepsLabel: (stepNumber, stepsCount) =>
          t(i18nKeys.general.stepOf, { stepNumber, totalSteps: stepsCount }),
        skipToButtonLabel: (step) => t(i18nKeys.general.skipTo, { stepName: step.title }),
        cancelButton: t(i18nKeys.general.cancel),
        previousButton: t(i18nKeys.general.previous),
        nextButton: t(i18nKeys.general.next),
        submitButton: t(i18nKeys.challenges.challengeSet.buttons.newChallengeSet),
        optional: t(i18nKeys.general.optional),
      }}
    />
  );
};

export default CreateChallengeSet;
