/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Container,
  FormField,
  Header,
  Hotspot,
  Input,
  SpaceBetween,
  Textarea,
  Button
} from '@amzn/awsui-components-react';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChallengeLearningOutcomeFields,
  ChallengePropAction,
  INTRODUCTION_MAX_LENGTH,
  INTRODUCTION_MIN_LENGTH,
  PREREQUISITES_MAX_LENGTH,
  PREREQUISITES_MIN_LENGTH,
  SUMMARY_MAX_LENGTH,
  SUMMARY_MIN_LENGTH,
  TOPICS_COVERED_MAX_LENGTH,
  TOPICS_COVERED_MIN_LENGTH,
  useCreateChallenge
} from '../../../store/create-challenge.context';
import { ChallengeLearningOutcome } from '../../../types/Challenge';
import { i18nKeys } from '../../../utils/i18n.utils';
import { ChallengeHotspot } from '../challengesCommon/ChallengeHotspots';
import { parseBraceDelimitedString, stringArrayToBraceDelimitedString } from '../../../utils/string.utils';

export interface ChallengeLearningOutcomeSectionProps {
  validationHandler: (validateSection: () => Promise<boolean>) => void;
}

const ChallengeLearningOutcomeSection: React.FC<ChallengeLearningOutcomeSectionProps> = ({ validationHandler }) => {
  const field = i18nKeys.challenges.subSections.learningPlan.fields;
  const { t } = useTranslation();
  const { handleUpdateChallengeProp, editedChallenge, challengeLearningOutcomeValidator } = useCreateChallenge();
  const [learningOutcome, setLearningOutcome] = useState<ChallengeLearningOutcome>(
    editedChallenge?.props.learningOutcome || new ChallengeLearningOutcome()
  );

  const [learningObjectives, setLearningObjectives] = useState<string[]>(
    parseBraceDelimitedString(editedChallenge?.props.learningOutcome.topicsCovered) || ['']
  );

  const handleLearningOutcome = (modifiedLearningOutcome: ChallengeLearningOutcome) => {
    setLearningOutcome(modifiedLearningOutcome);
    handleUpdateChallengeProp(ChallengePropAction.LEARNING_OUTCOME, modifiedLearningOutcome);
  };

  const handleModifyLearningObjective = (modifiedLearningObjective: string, index: number) => {
    learningObjectives[index] = modifiedLearningObjective;
    updateLearningObjectives(learningObjectives);
  };

  const handleAddLearningObjective = () => {
    learningObjectives.push('');
    updateLearningObjectives(learningObjectives);
  };

  const handleRemoveLearningObjective = (index: number) => {
    learningObjectives.splice(index, 1);
    updateLearningObjectives(learningObjectives);
    validator.isValidField(ChallengeLearningOutcomeFields.TOPICS_COVERED)
  };

  const updateLearningObjectives = (objectives: string[]) => {
    setLearningObjectives(objectives);
    handleInputChange({
      attribute: ChallengeLearningOutcomeFields.TOPICS_COVERED,
      value: stringArrayToBraceDelimitedString(objectives)
    });
  };

  // error texts and validation logic
  const [summaryErrorText, setSummaryErrorText] = useState<string>('');
  const [introductionErrorText, setIntroductionErrorText] = useState<string>('');
  const [learningObjectivesErrorText, setLearningObjectivesErrorText] = useState<string>('');
  const [preRequisitesErrorText, setPreRequisitesErrorText] = useState<string>('');

  const validator = challengeLearningOutcomeValidator(
    new Map<ChallengeLearningOutcomeFields, (error: string) => void>([
      [ChallengeLearningOutcomeFields.SUMMARY, (error: string) => setSummaryErrorText(error)],
      [ChallengeLearningOutcomeFields.INTRODUCTION, (error: string) => setIntroductionErrorText(error)],
      [ChallengeLearningOutcomeFields.TOPICS_COVERED, (error: string) => setLearningObjectivesErrorText(error)],
      [ChallengeLearningOutcomeFields.PRE_REQUISITES, (error: string) => setPreRequisitesErrorText(error)],
    ])
  );

  interface LearningOutcomeHelper {
    attribute: ChallengeLearningOutcomeFields;
    value: string;
  }

  const handleInputChange = (event: LearningOutcomeHelper) => {
    const { attribute, value } = event;
    switch (attribute) {
      case ChallengeLearningOutcomeFields.SUMMARY:
        learningOutcome.summary = value;
        break;
      case ChallengeLearningOutcomeFields.INTRODUCTION:
        learningOutcome.introduction = value;
        break;
      case ChallengeLearningOutcomeFields.TOPICS_COVERED:
        learningOutcome.topicsCovered = value;
        break;
      case ChallengeLearningOutcomeFields.PRE_REQUISITES:
        learningOutcome.technicalKnowledgePrerequisites = value;
        break;
      default:
        throw new Error('Unrecognized field on ChallengeLearningOutcome');
    }
    handleLearningOutcome(learningOutcome);
  };

  validationHandler(() => {
    return Promise.resolve(validator.isValidSection(true));
  });

  return (
    <Container header={<Header variant={'h2'}> Content </Header>}>
      <SpaceBetween direction="vertical" size="l">
        <FormField
          label={t(field.summary.title) + '*'}
          errorText={summaryErrorText}
          constraintText={t(field.summary.constraint, { SUMMARY_MIN_LENGTH, SUMMARY_MAX_LENGTH })}>
          <Hotspot direction="right" hotspotId={ChallengeHotspot.learningOutcomeSummary}>
            <Textarea
              onChange={({ detail }) =>
                handleInputChange({
                  attribute: ChallengeLearningOutcomeFields.SUMMARY,
                  value: detail.value,
                })
              }
              onBlur={() => validator.isValidField(ChallengeLearningOutcomeFields.SUMMARY)}
              name="learningOutcomeSummary"
              placeholder={t(field.summary.placeholder)}
              value={learningOutcome.summary ?? ''}
            />
          </Hotspot>
        </FormField>
        <FormField
          label={t(field.introduction.title) + '*'}
          errorText={introductionErrorText}
          constraintText={t(field.introduction.constraint, {
            INTRODUCTION_MIN_LENGTH,
            INTRODUCTION_MAX_LENGTH,
          })}>
          <Hotspot direction="right" hotspotId={ChallengeHotspot.learningOutcomeIntroduction}>
            <Textarea
              onChange={({ detail }) =>
                handleInputChange({
                  attribute: ChallengeLearningOutcomeFields.INTRODUCTION,
                  value: detail.value,
                })
              }
              onBlur={() => validator.isValidField(ChallengeLearningOutcomeFields.INTRODUCTION)}
              name="learningOutcomeIntroduction"
              value={learningOutcome.introduction ?? ''}
              placeholder={t(field.introduction.placeholder)}
            />
          </Hotspot>
        </FormField>
        <FormField
          label={t(field.learningObjectives.title) + '*'}
          errorText={learningObjectivesErrorText}
          constraintText={t(field.learningObjectives.constraint, {
            TOPICS_COVERED_MIN_LENGTH,
            TOPICS_COVERED_MAX_LENGTH,
          })}>
          <Hotspot direction="right" hotspotId={ChallengeHotspot.learningOutcomeTopicsCovered}>
            <SpaceBetween direction="vertical" size="m">
              {learningObjectives.map((learningObjective, i) => {
                return <span key={i}>
                  <Input
                    type="text"
                    value={learningObjective || ''}
                    onChange={({ detail }) => handleModifyLearningObjective(detail.value, i)}
                    placeholder={t(field.learningObjectives.placeholder)}
                    onBlur={() => validator.isValidField(ChallengeLearningOutcomeFields.TOPICS_COVERED)}
                  />
                  {learningObjectives.length > 1 && <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '0.5em'}}>
                      <Button variant={'normal'} onClick={() => handleRemoveLearningObjective(i)}>
                        {t(i18nKeys.general.remove)}
                      </Button>
                  </div>}
                </span>;
              })}
              <Button variant={'normal'} onClick={handleAddLearningObjective}>
                {t(field.learningObjectives.add)}
              </Button>
            </SpaceBetween>
          </Hotspot>
        </FormField>
        <FormField
          label={t(field.technicalKnowledgePrerequisites.title) + '*'}
          errorText={preRequisitesErrorText}
          constraintText={t(field.technicalKnowledgePrerequisites.constraint, {
            PREREQUISITES_MIN_LENGTH,
            PREREQUISITES_MAX_LENGTH,
          })}>
          <Hotspot direction="right" hotspotId={ChallengeHotspot.learningOutcomeTechnicalKnowledgePrerequisites}>
            <Textarea
              onChange={({ detail }) =>
                handleInputChange({
                  attribute: ChallengeLearningOutcomeFields.PRE_REQUISITES,
                  value: detail.value,
                })
              }
              onBlur={() => validator.isValidField(ChallengeLearningOutcomeFields.PRE_REQUISITES)}
              value={learningOutcome.technicalKnowledgePrerequisites ?? ''}
              placeholder={t(field.technicalKnowledgePrerequisites.placeholder)}
            />
          </Hotspot>
        </FormField>
      </SpaceBetween>
    </Container>
  );
};
export default ChallengeLearningOutcomeSection;
