import { Box, Button, Form, FormField, Header, Input, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../store/api.context';
import { i18nKeys } from '../../../utils/i18n.utils';
import { preProdLogger } from '../../../utils/log.utils';
import { isEmailValid } from '../../../utils/string.utils';
import { ConfirmModal } from '../../common/ConfirmModal';

const GDPR: React.FC = () => {
  const [email, setEmail] = useState('');
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);
  const { accountApi } = useApi();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleDSARRequest = () => {
    setLoading(true);
    accountApi
      .makeDSARRequest(email)
      .then(() => {
        setLoading(false);
        setEmail('');
      })
      .catch((err) => {
        setLoading(false);
        preProdLogger('Error requesting DSAR', err.message);
      });
  };

  const handleRTFRequest = () => {
    setLoading(true);
    accountApi
      .makeRTFRequest(email)
      .then(() => {
        setLoading(false);
        setEmail('');
        setConfirmDeleteModalVisible(false);
      })
      .catch((err) => {
        setLoading(false);
        preProdLogger('Error requesting RTF', err.message);
        setConfirmDeleteModalVisible(false);
      });
  };

  return (
    <Box>
      <ConfirmModal
        visible={confirmDeleteModalVisible}
        onCancel={() => setConfirmDeleteModalVisible(false)}
        onConfirm={() => handleRTFRequest()}
        message={t(i18nKeys.settings.gdpr.messages.deleteConfirmation, { email })}
      />
      <Form className="max-form-width" header={<Header variant="h2">{t(i18nKeys.settings.gdpr.header)}</Header>}>
        <FormField label={t(i18nKeys.settings.gdpr.labels.email)}>
          <SpaceBetween direction="vertical" size="s">
            <Input placeholder="user1@example.com" value={email} onChange={({ detail }) => setEmail(detail.value)} />
            {email && !isEmailValid(email) && (
              <div className="warning">{t(i18nKeys.settings.errors.invalidEmailFormat)}</div>
            )}
            <SpaceBetween direction="horizontal" size="s">
              <Button
                variant="normal"
                disabled={!isEmailValid(email)}
                onClick={() => setConfirmDeleteModalVisible(true)}
                loading={loading}
                iconName="close">
                {t(i18nKeys.settings.gdpr.buttons.requestRTF)}
              </Button>
              <Button
                variant="primary"
                disabled={!isEmailValid(email)}
                onClick={() => handleDSARRequest()}
                loading={loading}
                iconName="download">
                {t(i18nKeys.settings.gdpr.buttons.requestDSAR)}
              </Button>
            </SpaceBetween>
          </SpaceBetween>
        </FormField>
      </Form>
    </Box>
  );
};

export default GDPR;
