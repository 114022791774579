import React, { useMemo, memo } from 'react'
import { Button, ButtonDropdown, TopNavigation, TopNavigationProps, ButtonDropdownProps, SpaceBetween } from '@amzn/awsui-components-react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ThemeToggle, IThemeToggleProps } from '@/src/components/ui/molecules/ThemeToggle'
import { LanguageSelect, ILanguageSelectProps } from '@/src/components/ui/molecules/LanguageSelect'

// types
import { INavMenuItem, IUserMenuItem } from './Header.types'

// styles
import './Header.scss'
import type { CancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events'

export interface IHeaderProps {
  /** i18n locale key for logo title and href */
  logo: {
    title: string,
    href: string,
    svg: string
  }
  navItems: INavMenuItem[],
  /** User loggedin details  */
  userDetails?: {
    name: string,
    email: string,
    menuItems: IUserMenuItem[]
  },
  theme: IThemeToggleProps
  lang: ILanguageSelectProps
  onMenuItemClick: CancelableEventHandler<ButtonDropdownProps.ItemClickDetails | object>
  onUserMenuItemClick: CancelableEventHandler<ButtonDropdownProps.ItemClickDetails>
  onSignInClick: () => void
}

const i18String = {
  searchIconAriaLabel:"Search",
  searchDismissIconAriaLabel:"Close search",
  overflowMenuTriggerText:"More",
  overflowMenuTitleText:"All",
  overflowMenuBackIconAriaLabel:"Back",
  overflowMenuDismissIconAriaLabel:"Close menu" 
}

export const Header = memo<IHeaderProps>(({logo, navItems, userDetails, theme, lang, onMenuItemClick, onUserMenuItemClick, onSignInClick}: IHeaderProps) => {
    const { t } = useTranslation()

    const identity = useMemo(() => ({
      href: logo.href,
      title: t(logo.title),
      logo: {
        src: logo.svg,
        alt: t(logo.title),
      }
    }), [logo, t])

    const navMenu = useMemo<TopNavigationProps.Utility[]>(() => {
      return navItems.map((item, index) => {
        if (item.subMenuItems) {
          return {
            id: item.id,
            type: 'menu-dropdown',
            ariaLabel: t(item.text),
            text: t(item.text),
            onItemClick: onMenuItemClick,
            items: item.subMenuItems.map((subItem) => {
              return {
                id: subItem.id,
                text: t(subItem.text),
                description: t(subItem.description),
                href: subItem.href,
                onClick: onMenuItemClick
              }
            }) as ButtonDropdownProps.Item[]
          }
        } else {
          return {
            type: 'button',
            id: item.id,
            ariaLabel: t(item.text),
            text: t(item.text),
            href: item.href,
            onClick: onMenuItemClick
          } 
        }
      })
    }, [navItems, t])

    const loggedInMenu = useMemo(():ButtonDropdownProps.Item[] => {
      if (!userDetails) return []
      return [
        { id:'user-email', text: userDetails.email },
        ...userDetails.menuItems
      ]
    }, [userDetails])
        
    return <div className='topnav'>
      <TopNavigation
        identity={identity}
        i18nStrings={i18String}
        utilities={navMenu}
      />
      <div className='topnav_aside awsui-polaris-dark-mode'>
        {
          !userDetails 
          ? <SpaceBetween direction='horizontal' size='s'>
              <ThemeToggle {...theme} />
              <Button variant="primary" onClick={onSignInClick}>{t('account.login.button')}</Button>
              <LanguageSelect {...lang} />
            </SpaceBetween>
          : <SpaceBetween direction='horizontal' size='s'>
              <ThemeToggle {...theme} />
              <ButtonDropdown
                items={loggedInMenu}
                onItemClick={onUserMenuItemClick}
              >
                <FontAwesomeIcon icon='user' /> {userDetails.name}
              </ButtonDropdown>
              <LanguageSelect {...lang} />
            </SpaceBetween>
        }
      </div>
    </div>
})

Header.displayName = 'Header'