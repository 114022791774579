import { Button, ColumnLayout, Container, Header, SpaceBetween, Table } from '@amzn/awsui-components-react';
import { i18nKeys } from '../../../utils/i18n.utils';
import { KeyValue } from '../../common/KeyValue';
import { FrequencyUnit, RequestType } from '../../../types/common';
import { MinMax, UsagePlan } from '../../../types/usage-plan/UsagePlan';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { formatLabProviderLabel } from '../../../types/LabProvider';
import { useTranslation } from 'react-i18next';
import { useChallenges } from '../../../store/challenge.context';
import { ChallengeListItem } from '../../../types/Challenge';
import { Link } from 'react-router-dom';
import { useUser } from '../../../store/user.context';

interface UsagePlanDetailsOverviewProps {
  usagePlan: UsagePlan | undefined;
  handleToggleEdit?: () => void; // if this is not passed in, we won't show the edit button
}

const UsagePlanDetailsOverview: React.FC<UsagePlanDetailsOverviewProps> = ({ usagePlan, handleToggleEdit }) => {
  const { t } = useTranslation();
  const { challengeListItems, getChallenges } = useChallenges();
  const [blockedChallenges, setBlockedChallenges] = useState<ChallengeListItem[]>([]);
  const { user } = useUser();

  useEffect(() => {
    if (usagePlan?.blockedChallenges && usagePlan.blockedChallenges.length !== blockedChallenges.length) {
      void getChallenges(false, false, true);
    }
  }, []);

  useEffect(() => {
    setBlockedChallenges(usagePlan?.getBlockedChallengesFromListItems(challengeListItems) ?? []);
  }, [challengeListItems, usagePlan]);

  const humanFriendlyLabProvider = () => {
    if (usagePlan?.allowedLabProviders) {
      return usagePlan.allowedLabProviders.map(formatLabProviderLabel).join(', ');
    }
  };

  const humanFriendlyRequestLimit = () => {
    if (!usagePlan?.requestType || !usagePlan.requestFrequency) {
      return;
    }
    let unit = '';
    switch (usagePlan.requestFrequency.unit) {
      case FrequencyUnit.DAY:
        unit = t(i18nKeys.general.day);
        break;
      case FrequencyUnit.WEEK:
        unit = t(i18nKeys.general.week);
        break;
      case FrequencyUnit.MONTH:
        unit = t(i18nKeys.general.month);
        break;
      case FrequencyUnit.YEAR:
        unit = t(i18nKeys.general.year);
        break;
    }
    return `${usagePlan.requestFrequency.frequency.toString()} ${t(i18nKeys.usagePlan.fields.requestLimit.requests)} 
        ${t(i18nKeys.usagePlan.fields.requestLimit.per)} ${unit}`;
  };

  const humanFriendlyMinMax = (minMax: MinMax) => {
    return `${minMax.min} ${t(i18nKeys.general.min)}, ${minMax.max} ${t(i18nKeys.general.max)}`;
  };

  return (
    <div>
      <SpaceBetween size={'l'}>
        <Container
          header={
            <Header
              variant={'h2'}
              actions={
                handleToggleEdit && <Button onClick={() => handleToggleEdit()}>{t(i18nKeys.general.edit)}</Button>
              }>
              {t(i18nKeys.general.details)}
            </Header>
          }>
          <ColumnLayout columns={3} variant={'text-grid'}>
            <SpaceBetween size={'l'}>
              <KeyValue
                label={t(i18nKeys.usagePlan.fields.expiration.title)}
                info={t(i18nKeys.usagePlan.fields.expiration.description)}>
                {usagePlan?.getExpirationFormatted() || t(i18nKeys.events.eventDetails.labels.none)}
              </KeyValue>
              <KeyValue
                label={t(i18nKeys.usagePlan.fields.labProvider.title)}
                info={t(i18nKeys.usagePlan.fields.labProvider.description)}>
                {humanFriendlyLabProvider() || t(i18nKeys.events.eventDetails.labels.none)}
              </KeyValue>
              <KeyValue
                label={t(i18nKeys.usagePlan.fields.requestType.title)}
                info={t(i18nKeys.usagePlan.fields.requestType.description)}>
                {usagePlan?.requestType === RequestType.EVENT
                  ? t(i18nKeys.events.event)
                  : t(i18nKeys.events.eventDetails.labels.campaign)}
              </KeyValue>
            </SpaceBetween>
            <SpaceBetween size={'l'}>
              {usagePlan?.requestType === RequestType.EVENT && (
                <KeyValue
                  label={t(i18nKeys.usagePlan.fields.minDaysBeforeEvent.title)}
                  info={t(i18nKeys.usagePlan.fields.minDaysBeforeEvent.description)}>
                  {!usagePlan?.minDaysBeforeEvent
                    ? t(i18nKeys.events.eventDetails.labels.none)
                    : usagePlan.minDaysBeforeEvent === 1
                    ? `${usagePlan.minDaysBeforeEvent} ${t(i18nKeys.general.day)}`
                    : `${usagePlan.minDaysBeforeEvent} ${t(i18nKeys.general.days)}`}
                </KeyValue>
              )}
              <KeyValue
                label={t(i18nKeys.usagePlan.fields.requestLimit.title)}
                info={t(i18nKeys.usagePlan.fields.requestLimit.description)}>
                {humanFriendlyRequestLimit() || t(i18nKeys.events.eventDetails.labels.none)}
              </KeyValue>
              <KeyValue
                label={t(i18nKeys.usagePlan.fields.challenge.title)}
                info={t(i18nKeys.usagePlan.fields.challenge.description)}>
                {usagePlan?.numOfChallenges}
              </KeyValue>
            </SpaceBetween>
            <SpaceBetween size={'l'}>
              <KeyValue
                label={t(i18nKeys.usagePlan.fields.participant.title)}
                info={t(i18nKeys.usagePlan.fields.participant.description)}>
                {humanFriendlyMinMax(usagePlan?.numOfParticipants as MinMax)}
              </KeyValue>

              {usagePlan?.requestType === RequestType.EVENT && (
                <KeyValue
                  label={t(i18nKeys.usagePlan.fields.duration.title)}
                  info={t(i18nKeys.usagePlan.fields.duration.description)}>
                  {!usagePlan?.minDaysBeforeEvent
                    ? t(i18nKeys.events.eventDetails.labels.none)
                    : usagePlan.minDaysBeforeEvent === 1
                    ? `${usagePlan.minDaysBeforeEvent} ${t(i18nKeys.general.day)}`
                    : `${usagePlan.minDaysBeforeEvent} ${t(i18nKeys.general.days)}`}
                </KeyValue>
              )}
              {usagePlan?.requestType === RequestType.EVENT && (
                <KeyValue
                  label={t(i18nKeys.usagePlan.fields.teamSize.title)}
                  info={t(i18nKeys.usagePlan.fields.teamSize.description)}>
                  {humanFriendlyMinMax(usagePlan?.maxTeamSize ?? { min: 0, max: 0 })}
                </KeyValue>
              )}
            </SpaceBetween>
          </ColumnLayout>
          {/* everyone who can see this usage plan can view the description */}
          {usagePlan?.description && (
            <KeyValue label={t(i18nKeys.usagePlan.fields.description.title)}>{usagePlan.description}</KeyValue>
          )}
          {/* only super admins can view notes */}
          {usagePlan?.notes && user && user.isSuperAdmin && (
            <KeyValue label={t(i18nKeys.usagePlan.fields.notes.title)}>{usagePlan.notes}</KeyValue>
          )}
        </Container>

        <Table
          columnDefinitions={[
            {
              id: 'title',
              header: t(i18nKeys.challenges.fields.title.title),
              cell: (item) => (
                <div>
                  <Link to={`/challenges/${item.id}`}>{item.props.title || '-'}</Link>
                </div>
              ),
            },
          ]}
          items={blockedChallenges}
          variant="container"
          empty={<i>{t(i18nKeys.usagePlan.fields.challengeDeny.none)}</i>}
          header={<Header>{t(i18nKeys.usagePlan.fields.challengeDeny.title)}</Header>}
        />

        {/* only mainainers and super admins can see the maintainer and allowlist */}
        {user && (usagePlan?.isMaintainer(user) || user.isSuperAdmin) && (
          <Table
            columnDefinitions={[
              {
                id: 'maintainer',
                header: t(i18nKeys.account.email),
                cell: (email) => email.emailAddress,
              },
            ]}
            items={usagePlan?.maintainers ?? []}
            variant="container"
            empty={<i>{t(i18nKeys.usagePlan.fields.maintainers.empty)}</i>}
            header={<Header>{t(i18nKeys.usagePlan.fields.maintainers.title)}</Header>}
          />
        )}

        {user && (usagePlan?.isMaintainer(user) || user.isSuperAdmin) && (
          <Table
            columnDefinitions={[
              {
                id: 'allowlist',
                header: t(i18nKeys.account.email),
                cell: (email) => email.emailAddress,
              },
            ]}
            items={usagePlan?.allowlist ?? []}
            variant="container"
            empty={<i>{t(i18nKeys.usagePlan.fields.allowedUsers.empty)}</i>}
            header={<Header>{t(i18nKeys.usagePlan.fields.allowedUsers.title)}</Header>}
          />
        )}
      </SpaceBetween>
    </div>
  );
};

export default UsagePlanDetailsOverview;
