import { FormField, Select } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChallenges } from '../../store/challenge.context';
import { ChallengeSet } from '../../types/ChallengeSet';
import { Nullable } from '../../types/common';
import { i18nKeys } from '../../utils/i18n.utils';
import { LoadingBar } from './LoadingBar';

interface ChallengeSetSelectionProps {
  handleSelectedChallengeSet: (option: OptionDefinition) => void;
  showTitle?: boolean;
  clearOnSelection?: boolean;
}

export const ChallengeSetSelection: React.FC<ChallengeSetSelectionProps> = ({
  handleSelectedChallengeSet,
  showTitle = true,
  clearOnSelection = false,
}) => {
  const { challengeSets, getChallengeSets } = useChallenges();
  const { t } = useTranslation();
  const [selectedChallengeSetOption, setSelectedChallengeSetOption] = useState<Nullable<OptionDefinition>>(null);

  useEffect(() => {
    getChallengeSets();
  }, []);

  return (
    <FormField label={showTitle ? t(i18nKeys.challenges.importChallenges.fields.challengeSets.title) : undefined}>
      {challengeSets ? (
        <Select
          className="mr-5"
          selectedOption={selectedChallengeSetOption}
          onChange={({ detail }) => {
            handleSelectedChallengeSet(detail.selectedOption);
            if (!clearOnSelection) {
              setSelectedChallengeSetOption(detail.selectedOption);
            }
          }}
          placeholder={t(i18nKeys.challenges.importChallenges.fields.challengeSets.chooseChallengeSet)}
          options={[
            ...(challengeSets
              ? challengeSets?.map((challengeSet: ChallengeSet) => {
                  return {
                    label: `${challengeSet.title} (${challengeSet.challengeIds.length} challenges)`,
                    value: challengeSet.id!,
                  };
                })
              : []),
          ]}
        />
      ) : (
        <LoadingBar />
      )}
    </FormField>
  );
};
