import { Container, Header, Select } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChallengePropAction,
  ChallengeSettingsFields,
  useCreateChallenge,
} from '../../../../store/create-challenge.context';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { Column } from '../../../common/Column';
import { Columns } from '../../../common/Columns';
import { KeyValue } from '../../../common/KeyValue';
import { DifficultyDefinitions } from '../../challengesCommon/ChallengeOptionDefinitions';
import DifficultyIndicator from '../../challengesCommon/difficultyIndiciator/DifficultyIndicator';
import { getReviewStatus } from '../challengeRevisions/revision-list-config';
import { useChallenges } from '../../../../store/challenge.context';

const ChallengeOverviewDetail: React.FC = () => {
  const { t } = useTranslation();
  const { editMode, editedChallenge, handleUpdateChallengeProp } = useCreateChallenge();
  const { challenge } = useChallenges();

  // Basic Settings
  const [basicSettings, setBasicSettings] = useState({
    jamType: challenge?.props?.jamType || '',
    category: challenge?.props?.category || '',
    difficulty: challenge?.props?.difficulty || '',
  });

  useEffect(() => {
    if (editedChallenge) {
      setBasicSettings({
        jamType: editedChallenge?.props?.jamType || '',
        category: editedChallenge?.props?.category || '',
        difficulty: editedChallenge?.props?.difficulty.toString() || '',
      });
    }
  }, [editedChallenge]);

  const difficulties: OptionDefinition[] = [];

  DifficultyDefinitions.forEach((category: OptionDefinition) => {
    difficulties.push({ label: t(category.label as string), value: category.value });
  });

  const handleBasicSettingsUpdate = (e: { target: { name: ChallengeSettingsFields; value: string | undefined } }) => {
    const { name, value } = e.target;

    const newBasicSettings = {
      ...basicSettings,
      [name]: value,
    };

    handleUpdateChallengeProp(ChallengePropAction.BASIC_SETTINGS, newBasicSettings);
    setBasicSettings(newBasicSettings);
  };

  const noReviewerAssigned = t(i18nKeys.challenges.challengeDetails.titles.noReviewer);

  return (
    <div>
      <Container header={<Header variant="h2">{t(i18nKeys.challenges.challengeDetails.headings.overview)}</Header>}>
        <Columns columns={4} variant="default">
          <Column size="s">
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.status)}>
              {challenge && getReviewStatus(challenge.status)}
            </KeyValue>
          </Column>
          <Column size="s">
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.difficultyLevel)}>
              {!editMode ? (
                <DifficultyIndicator challenge={challenge} />
              ) : (
                <Select
                  selectedOption={difficulties.find((item) => item.value === basicSettings?.difficulty) || null}
                  onChange={({ detail }) =>
                    handleBasicSettingsUpdate({
                      target: { name: ChallengeSettingsFields.DIFFICULTY, value: detail.selectedOption.value },
                    })
                  }
                  options={difficulties}
                  selectedAriaLabel={t(i18nKeys.general.selected)}
                />
              )}
            </KeyValue>
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.brReviewStatus)}>
              {getReviewStatus(challenge?.barRaiserReviewStatus)}
            </KeyValue>
          </Column>
          <Column size="s">
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.labDeployment)}>
              {'--'}
            </KeyValue>
          </Column>
          <Column size="s">
            <KeyValue className="secondary-text" label={t(i18nKeys.challenges.challengeDetails.titles.moreInfo)}>
              {!!challenge?.numLambdaValidatedTasks && (
                <span
                  className="badge-aws badge-aws-aws badge-aws-primary"
                  title={t(i18nKeys.challenges.challengeDetails.field.lambdaValidated.title)}>
                  <span className="fa fa-check-circle" />
                  {t(i18nKeys.challenges.challengeDetails.field.lambdaValidated.name)}
                </span>
              )}
              {!!challenge?.numDynamicAnswerTasks && (
                <span
                  className="badge-aws badge-aws-primary"
                  title={t(i18nKeys.challenges.challengeDetails.field.dynamicAnswer.title)}>
                  <span className="fa fa-magic" />
                  {t(i18nKeys.challenges.challengeDetails.field.dynamicAnswer.name)}
                </span>
              )}
            </KeyValue>
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.barRaiser)}>
              {challenge?.props?.barRaiser?.emailAddress ?? noReviewerAssigned}
            </KeyValue>
          </Column>
        </Columns>
      </Container>
      <div className="grey-section-divider-top" />
    </div>
  );
};

export default ChallengeOverviewDetail;
