import React, { useEffect, useState } from 'react';
import { Container, Header, Select, SpaceBetween } from '@amzn/awsui-components-react';
import { Event, EventChallengeFeedbackSummaries, EventFeedback, EventFeedbackSummary } from '../../../../types/Event';
import { ChallengeFeedback, ChallengeFeedbackSummary } from '../../../../types/Challenge';
import { useComponentDidMountEffect } from '../../../../hooks/useComponentDidMountEffect';
import { useEvents } from '../../../../store/events.context';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import EventFeedbackSection from './EventFeedbackSection';
import ChallengeFeedbackSection from './ChallengeFeedbackSection';
import { useTranslation } from 'react-i18next';
import { preProdLogger } from '../../../../utils/log.utils';
import { i18nKeys } from '../../../../utils/i18n.utils';

interface FeedbackProps {
  event: Event | undefined;
}

// eslint-disable-next-line no-shadow
export enum FeedbackType {
  EVENT = 'event',
  CHALLENGE = 'challenge',
}

const Feedback: React.FC<FeedbackProps> = ({ event }) => {
  const { t } = useTranslation();
  const EventOption = {
    label: t(i18nKeys.feedback.labels.eventFeedback),
    value: 'event',
  };
  const { eventFeedback, challengeFeedback, loadEventFeedback, loadEventChallengeFeedback } = useEvents();
  const [feedbackList, setFeedbackList] = useState<EventFeedback[]>([]);
  const [feedbackWithComments, setFeedbackWithComments] = useState<EventFeedback[]>([]);
  const [summary, setSummary] = useState<EventFeedbackSummary>();
  const [averageEventRating, setAverageEventRating] = useState(0);
  const [averageSpeakerRank, setAverageSpeakerRank] = useState(0);
  const [challengeFeedbackSummaries, setChallengeFeedbackSummaries] = useState<EventChallengeFeedbackSummaries>({});
  const [challengeAvgRanks, setChallengeAvgRanks] = useState<{ [challengeId: string]: number }>({});
  const [challengeAvgDifficulties, setChallengeAvgDifficulties] = useState<{ [challengeId: string]: number }>({});
  const [selectedFeedbackType, setSelectedFeedbackType] = useState<OptionDefinition>(EventOption);
  const [feedbackOptions, setFeedbackOptions] = useState<OptionDefinition[]>([]);
  const rowsPerPage = 40;

  useComponentDidMountEffect(async () => {
    if (event) {
      await generateEventFeedback();
    }
  });

  useEffect(() => {
    if (event) {
      resetState();
      generateEventFeedback()
        .then(() => {
          preProdLogger('Generated feedback');
        })
        .catch(() => {
          preProdLogger('Issue generating feedback');
        });
    }
  }, [event]);

  useEffect(() => {
    // update the feedback list
    addEventFeedbackRows(eventFeedback || []);
  }, [eventFeedback]);

  useEffect(() => {
    generateSummary();
    generateChallengeFeedbackSummaries();
  }, [feedbackList]);

  useEffect(() => {
    setupFeedbackTypeOptions();
  }, [challengeFeedback]);

  const getChallengeIds = () => {
    if (challengeFeedback) {
      return Object.keys(challengeFeedback);
    }
  };

  const challengeFeedbackWithComments = (): ChallengeFeedback[] => {
    if (challengeFeedback && selectedFeedbackType.value) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
      return (
        (challengeFeedback[selectedFeedbackType.value] || []).filter((feedback: ChallengeFeedback) => {
          return feedback.notes != null && feedback.notes.length > 1;
        }) || []
      );
    } else {
      return [];
    }
  };

  const setupFeedbackTypeOptions = () => {
    const newOptions: OptionDefinition[] = [];
    const challengeIds = getChallengeIds() || [];
    newOptions.push(EventOption);
    challengeIds.forEach((challengeId) => {
      newOptions.push({ label: t(i18nKeys.feedback.labels.challengeFeedback, { challengeId }), value: challengeId });
    });
    setFeedbackOptions(newOptions);
  };

  const resetState = (): void => {
    setFeedbackList([]);
    setFeedbackWithComments([]);
    setSummary(undefined);
    setAverageEventRating(0);
    setAverageSpeakerRank(0);
    setChallengeFeedbackSummaries({});
    setChallengeAvgRanks({});
    setChallengeAvgDifficulties({});
  };

  const generateEventFeedback = async () => {
    if (!event) {
      resetState();
      return;
    }
    await Promise.all([loadEventFeedback(event.name), loadEventChallengeFeedback(event.name)]);
  };

  const generateAverageEventRating = (newSummary: EventFeedbackSummary): void => {
    if (newSummary) {
      const totalScore =
        newSummary.rating5 * 5 +
        newSummary.rating4 * 4 +
        newSummary.rating3 * 3 +
        newSummary.rating2 * 2 +
        newSummary.rating1 * 1;

      if (totalScore < 1) {
        setAverageEventRating(0);
      } else {
        setAverageEventRating(Number((totalScore / newSummary.total).toFixed(2)));
      }
    }
  };

  const generateAverageSpeakerRank = (newSummary: EventFeedbackSummary) => {
    if (newSummary) {
      const totalScore =
        newSummary.speaker5 * 5 +
        newSummary.speaker4 * 4 +
        newSummary.speaker3 * 3 +
        newSummary.speaker2 * 2 +
        newSummary.speaker1 * 1;

      if (totalScore < 1) {
        setAverageSpeakerRank(0);
      } else {
        setAverageSpeakerRank(Number((totalScore / newSummary.total).toFixed(2)));
      }
    }
  };

  const setAverageChallengeRating = (challengeId: string, challengeSummary: ChallengeFeedbackSummary): void => {
    if (challengeSummary) {
      const totalScore =
        challengeSummary.rating5 * 5 +
        challengeSummary.rating4 * 4 +
        challengeSummary.rating3 * 3 +
        challengeSummary.rating2 * 2 +
        challengeSummary.rating1 * 1;

      if (totalScore < 1) {
        challengeAvgRanks[challengeId] = 0;
        setChallengeAvgRanks(challengeAvgRanks);
      } else {
        challengeAvgRanks[challengeId] = Number((totalScore / challengeSummary.total).toFixed(2));
        setChallengeAvgRanks(challengeAvgRanks);
      }
    }
  };

  const setAverageChallengeDifficulty = (challengeId: string, challengeSummary: ChallengeFeedbackSummary) => {
    const totalScore =
      challengeSummary.difficulty5 * 5 +
      challengeSummary.difficulty4 * 4 +
      challengeSummary.difficulty3 * 3 +
      challengeSummary.difficulty2 * 2 +
      challengeSummary.difficulty1 * 1;
    if (totalScore < 1) {
      challengeAvgDifficulties[challengeId] = 0;
      setChallengeAvgDifficulties(challengeAvgDifficulties);
    } else {
      challengeAvgDifficulties[challengeId] = Number((totalScore / challengeSummary.total).toFixed(2));
      setChallengeAvgDifficulties(challengeAvgDifficulties);
    }
  };

  const generateSummary = () => {
    const newSummary = new EventFeedbackSummary();

    (feedbackList || []).forEach((feedback) => {
      newSummary.total++;
      if (feedback.eventRank === 1) {
        newSummary.rating1++;
      } else if (feedback.eventRank === 2) {
        newSummary.rating2++;
      } else if (feedback.eventRank === 3) {
        newSummary.rating3++;
      } else if (feedback.eventRank === 4) {
        newSummary.rating4++;
      } else if (feedback.eventRank === 5) {
        newSummary.rating5++;
      }

      if (feedback.speakerRank === 1) {
        newSummary.speaker1++;
      } else if (feedback.speakerRank === 2) {
        newSummary.speaker2++;
      } else if (feedback.speakerRank === 3) {
        newSummary.speaker3++;
      } else if (feedback.speakerRank === 4) {
        newSummary.speaker4++;
      } else if (feedback.speakerRank === 5) {
        newSummary.speaker5++;
      }

      if (feedback.notes) {
        newSummary.totalWithComments++;
      }

      // only count true, false, ignore null/undefined
      if (feedback.didYouLearnSomethingNew === true) {
        newSummary.learnedSomethingNew++;
      } else if (feedback.didYouLearnSomethingNew === false) {
        newSummary.didNotLearnSomethingNew++;
      }
    });
    setSummary(newSummary);
    generateAverageEventRating(newSummary);
    generateAverageSpeakerRank(newSummary);
  };

  const generateChallengeFeedbackSummaries = () => {
    if (challengeFeedback) {
      Object.keys(challengeFeedback).forEach((challengeId) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const challengeFeedbackList: ChallengeFeedback[] = challengeFeedback[challengeId];

        const challengeSummary: ChallengeFeedbackSummary = new ChallengeFeedbackSummary();

        (challengeFeedbackList || []).forEach((feedback) => {
          challengeSummary.total++;

          if (feedback.challengeRank === 1) {
            challengeSummary.rating1++;
          } else if (feedback.challengeRank === 2) {
            challengeSummary.rating2++;
          } else if (feedback.challengeRank === 3) {
            challengeSummary.rating3++;
          } else if (feedback.challengeRank === 4) {
            challengeSummary.rating4++;
          } else if (feedback.challengeRank === 5) {
            challengeSummary.rating5++;
          }

          if (feedback.challengeDifficulty === 1) {
            challengeSummary.difficulty1++;
          } else if (feedback.challengeDifficulty === 2) {
            challengeSummary.difficulty2++;
          } else if (feedback.challengeDifficulty === 3) {
            challengeSummary.difficulty3++;
          } else if (feedback.challengeDifficulty === 4) {
            challengeSummary.difficulty4++;
          } else if (feedback.challengeDifficulty === 5) {
            challengeSummary.difficulty5++;
          }

          if (feedback.notes) {
            challengeSummary.totalWithComments++;
          }

          if (challengeId) {
            challengeSummary.challengeId = challengeId;
          }

          // only count true, false, ignore null/undefined
          if (feedback.didYouLearnSomethingNew === true) {
            challengeSummary.learnedSomethingNew++;
          } else if (feedback.didYouLearnSomethingNew === false) {
            challengeSummary.didNotLearnSomethingNew++;
          }
        });

        challengeFeedbackSummaries[challengeId] = challengeSummary;
        setChallengeFeedbackSummaries(challengeFeedbackSummaries);
        setAverageChallengeRating(challengeId, challengeSummary);
        setAverageChallengeDifficulty(challengeId, challengeSummary);
      });
    }
  };

  const withComments = (feedback: EventFeedback[]) => {
    return (feedback || []).filter((f) => f.notes != null && f.notes.length > 0);
  };

  const addEventFeedbackRows = (feedback: EventFeedback[]) => {
    const newList = feedback;
    setFeedbackList(newList);
    setFeedbackWithComments(withComments(newList));
  };

  return (
    <Container header={<Header variant="h2">{t(i18nKeys.feedback.headers.feedback)}</Header>}>
      <SpaceBetween direction="vertical" size="m">
        <div className="inline button-right">
          {t(i18nKeys.feedback.labels.feedbackType)}
          <Select
            className="inline long-select-input"
            options={feedbackOptions}
            selectedOption={selectedFeedbackType}
            onChange={({ detail }) => setSelectedFeedbackType(detail.selectedOption)}
          />
        </div>
        {selectedFeedbackType.value === EventOption.value && (
          <EventFeedbackSection
            summary={summary}
            averageEventRating={averageEventRating}
            averageSpeakerRank={averageSpeakerRank}
            feedbackWithComments={feedbackWithComments}
          />
        )}
        {selectedFeedbackType.value && selectedFeedbackType.value !== EventOption.value && (
          <ChallengeFeedbackSection
            summary={challengeFeedbackSummaries[selectedFeedbackType.value]}
            averageChallengeRating={challengeAvgRanks[selectedFeedbackType.value]}
            averageDifficultyRating={challengeAvgDifficulties[selectedFeedbackType.value]}
            feedbackWithComments={challengeFeedbackWithComments()}
          />
        )}
      </SpaceBetween>
    </Container>
  );
};
export default Feedback;
