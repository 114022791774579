/* eslint-disable @typescript-eslint/no-unsafe-return */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormField, Input, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { i18nKeys } from '../../utils/i18n.utils';

export interface ConfirmModalProps {
  title?: string;
  message?: string | React.ReactElement;
  confirmBtnLabel?: string;
  cancelBtnLabel?: string;
  visible: boolean;
  size?: 'small' | 'medium' | 'large';
  textInput?: string;
  onTextInput?: (input: string) => any;
  disabled?: boolean;
  onCancel: () => any;
  onConfirm: () => any;
  errorText?: string;
  constraintText?: string;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  title,
  message,
  confirmBtnLabel,
  cancelBtnLabel,
  visible,
  size,
  textInput,
  onTextInput,
  onCancel,
  onConfirm,
  disabled = false,
  errorText = '',
  constraintText = '',
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={visible}
      size={size || 'medium'}
      header={title || t(i18nKeys.general.confirm)}
      onDismiss={() => onCancel()}
      footer={
        <Box float="right" className="awsui-util-f-r">
          <Button variant="link" onClick={() => onCancel()}>
            {cancelBtnLabel || t(i18nKeys.general.cancel)}
          </Button>
          <Button disabled={disabled} variant="primary" onClick={() => onConfirm()}>
            {confirmBtnLabel || t(i18nKeys.general.confirm)}
          </Button>
        </Box>
      }>
      <SpaceBetween size={'s'}>
        {message || t(i18nKeys.general.areYouSure)}

        {textInput !== undefined && onTextInput !== undefined && (
          <FormField errorText={errorText} constraintText={constraintText}>
            <Input onChange={({ detail }) => onTextInput(detail.value)} value={textInput} />
          </FormField>
        )}
      </SpaceBetween>
    </Modal>
  );
};
