import { I18NDef } from '../types/autogen/I18NDef';
import i18nRawDefinitions from '../types/autogen/i18nRawDefinitions.json';
import { replaceValuesWithTreeLocation, StringTree } from './stringtree.utils';

/**
 * A typed definition of all i18n keys, where the value of each key is the path/location of the key in the overall tree
 * of keys.
 *
 * Example:
 * t(i18nKeys.home.title) is the equivalent of t('home.title') but with type safety and autocompletion
 */
export const i18nKeys: I18NDef = replaceValuesWithTreeLocation(i18nRawDefinitions as I18NDef & StringTree) as I18NDef &
  StringTree;

export const I18N_PREFIX = 'i18n.';

/**
 * Prefix an i18n key with 'i18n' so that it can clearly be identified as an i18n key vs just a random string.
 *
 * @param key
 */
export const withI18NPrefix = (key: string): string => {
  return `${I18N_PREFIX}${key}`;
};

/**
 * Remove the prefix of an i18n key with 'i18n' so that it can clearly be identified as an i18n key vs just a random string.
 *
 * @param key
 */
export const withoutI18nPrefix = (key: string): string => {
  return key.startsWith(I18N_PREFIX) ? key.replace(I18N_PREFIX, '') : key;
};
