/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useCollection } from '@amzn/awsui-collection-hooks';
import { Button, Checkbox, Pagination, SpaceBetween, Table, TextFilter } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useComponentDidMountEffect } from '../../../../hooks/useComponentDidMountEffect';
import { CHALLENGE_DETAILS_ROUTES } from '../../../../routes';
import { useApi } from '../../../../store/api.context';
import { Challenge, ChallengeIssue } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { safeString } from '../../../../utils/string.utils';
import { paginationLabels } from '../../../../utils/table.utils';
import { TableEmptyState } from '../../../common/TableEmptyState';
import { TableHeader } from '../../../common/TableHeader';
import { Link as ReactRouterLink } from 'react-router-dom';
import { COLUMN_DEFINITIONS, filteringFunction } from './issue-list-config';

interface ChallengeIssuesDetailProps {
  challenge: Challenge;
}

const ChallengeIssuesDetail: React.FC<ChallengeIssuesDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { challengesApi } = useApi();
  const [challengeIssues, setChallengeIssues] = useState<ChallengeIssue[] | undefined>();

  const [showResolvedIssues, setShowResolvedIssues] = useState(false);

  useComponentDidMountEffect(async () => {
    setChallengeIssues(await challengesApi.getChallengeIssuesByChallengeId(safeString(challenge.id)));
  });

  const [preferences] = useState({
    pageSize: 10,
    visibleColumns: ['title', 'createdDate', 'openedBy', 'status', 'severity', 'comments'],
  });

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    challengeIssues || [],
    {
      filtering: {
        filteringFunction,
        empty: (
          <TableEmptyState
            title={t(i18nKeys.challenges.challengeDetails.table.emptyIssuesState)}
            subtitle={t(i18nKeys.challenges.challengeDetails.table.emptyIssuesSubtitle)}
          />
        ),
        noMatch: (
          <TableEmptyState
            title={t(i18nKeys.tables.noMatch.title)}
            subtitle={t(i18nKeys.tables.noMatch.subtitle)}
            onClearFilter={() => actions.setFiltering('')}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
    }
  );

  return (
    <Table
      {...collectionProps}
      variant="container"
      resizableColumns
      header={
        <TableHeader
          totalItems={challengeIssues ? challengeIssues.length : 0}
          title={t(i18nKeys.challenges.challengeDetails.headings.issues)}
          toggleTools={() => alert('challenge list tools not implemented yet')}
          actionButtons={
            <SpaceBetween direction="horizontal" size="s">
              <Checkbox onChange={({ detail }) => setShowResolvedIssues(detail.checked)} checked={showResolvedIssues}>
                {t(i18nKeys.challenges.challengeDetails.table.showResolvedIssues)}
              </Checkbox>
              {challengeIssues && (
                <Link
                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                  to={`${CHALLENGE_DETAILS_ROUTES.Issues.resolve(challenge.challengeId ?? '')}/${
                    challengeIssues.length
                  }`}>
                  {/* TODO: Route to old UI new issues */}
                  <ReactRouterLink to={'../new-issues'}>
                    <Button variant="primary">
                      {t(i18nKeys.challenges.challengeDetails.table.button.openNewIssue)}
                    </Button>
                  </ReactRouterLink>
                </Link>
              )}
            </SpaceBetween>
          }
        />
      }
      columnDefinitions={COLUMN_DEFINITIONS}
      items={items}
      loadingText={t(i18nKeys.challenges.challengeDetails.table.loadingIssues)}
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels(t)} />}
      filter={
        <TextFilter
          {...filterProps}
          countText={t(i18nKeys.tables.matchesCount, { count: filteredItemsCount })}
          filteringAriaLabel={t(i18nKeys.challenges.filteringLabel)}
        />
      }
    />
  );
};

export default ChallengeIssuesDetail;
