import { Header, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Event } from '../../../types/Event';
import { i18nKeys } from '../../../utils/i18n.utils';
import Access from './Summary/Access';
import AgreementInfo from './Summary/AgreementInfo';
import Attendance from './Summary/Attendance';
import Audit from './Summary/Audit';
import OwnerPermissions from './Summary/OwnerPermissions';
import EventTimes from './Summary/EventTimes';
import PreperationGuide from './Summary/PreperationGuide';
import SupportAndCollaboration from '../../common/CampaignEventComponents/SupportAndCollaboration';
import TeamSettings from './Summary/TeamSettings';
import DetailsEventType from './Summary/DetailsEventType';
import { useEditEvent } from '../../../store/edit-event.context';
import EventDetailsNotes from './Summary/EventDetailsNotes';
import TargetSummaryDetails from '../../common/CampaignEventComponents/TargetSummaryDetails';
// import TargetDetailsUsagePlan from '../../common/CampaignEventComponents/TargetDetailsUsagePlan';

interface EventDetailsSummaryProps {
  event: Event | undefined;
}

const EventDetailsSummary: React.FC<EventDetailsSummaryProps> = ({ event }) => {
  const { t } = useTranslation();
  const { newEventMode, destroyEdits, editedEvent } = useEditEvent();

  useEffect(() => {
    if (newEventMode) {
      destroyEdits();
    }
  }, []);

  const eventToEdit = editedEvent || event;

  return (
    <div>
      <Header variant="h2">{t(i18nKeys.events.eventDetails.headers.summary)}</Header>
      <SpaceBetween direction="vertical" size="m">
        <Access event={eventToEdit} />
        <PreperationGuide />
        <TargetSummaryDetails target={eventToEdit} />
        <EventDetailsNotes target={eventToEdit} />
        <AgreementInfo target={eventToEdit} />
        {/* <TargetDetailsUsagePlan target={eventToEdit} /> */}
        <EventTimes target={eventToEdit} />
        <Attendance target={eventToEdit} />
        <DetailsEventType target={eventToEdit} />
        <SupportAndCollaboration target={eventToEdit} />
        <TeamSettings target={eventToEdit} />
        <OwnerPermissions target={eventToEdit} targetType="event" />
        <Audit target={event} />
      </SpaceBetween>
    </div>
  );
};

export default EventDetailsSummary;
