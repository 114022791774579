import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { decodeForMarkdown } from '../../utils/string.utils';

interface MarkdownProps {
  content?: string;
  removePageBreaks?: boolean;
  className?: string;
}

export const Markdown: React.FC<MarkdownProps> = ({ content, removePageBreaks = false, className }) => {
  const withoutPageBreakSymbols = (text: string): string => {
    return (text || '').replace(/\$PAGE_BREAK/g, '');
  };

  const decodedContent = () => {
    let result: string = content || '';
    if (result) {
      if (removePageBreaks) {
        result = withoutPageBreakSymbols(result);
      }
      result = decodeForMarkdown(result);
    }
    return result;
  };

  return (
    <ReactMarkdown className={className} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
      {decodedContent()}
    </ReactMarkdown>
  );
};
