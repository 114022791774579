import {
  Box,
  Button,
  ExpandableSection,
  Header,
  Input,
  SpaceBetween,
  Table,
  TextContent,
} from '@amzn/awsui-components-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChallengePropAction, useCreateChallenge } from '../../../../store/create-challenge.context';
import { Challenge } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { isEmailValid } from '../../../../utils/string.utils';
import { ConfirmModal } from '../../../common/ConfirmModal';

interface ChallengeCollaboratorsDetailProps {
  challenge: Challenge;
}

const ChallengeCollaboratorsDetail: React.FC<ChallengeCollaboratorsDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { editMode, editedChallenge, handleUpdateChallengeProp } = useCreateChallenge();
  const [collaborators, setCollaborators] = useState<string[]>([]);
  const [collaborator, setCollaborator] = useState('');

  const [confirmTransferVisible, setConfirmTransferVisible] = useState(false);
  const [newOwnerEmail, setNewOwnerEmail] = useState('');

  useEffect(() => {
    updateCollaborators();
  }, [editedChallenge, challenge]);

  const updateCollaborators = () => {
    const currentCollaborators: string[] = [];
    const currentChallenge = editMode && editedChallenge ? editedChallenge : challenge;
    currentCollaborators.push(currentChallenge.props.owner || '');
    currentChallenge.props.maintainers.forEach((maintainer) => {
      if (maintainer !== currentChallenge.props.owner) {
        currentCollaborators.push(maintainer);
      }
    });
    setCollaborators(currentCollaborators);
  };

  const addCollaborator = () => {
    const currentMaintainers: string[] = collaborators.filter((c) => c !== editedChallenge?.props.owner);
    currentMaintainers.push(collaborator);

    handleUpdateChallengeProp(ChallengePropAction.MAINTAINERS, currentMaintainers);
    setCollaborator('');
  };

  const removeCollaborator = (email: string) => {
    const currentMaintainers: string[] = collaborators.filter((c) => c !== email && c !== editedChallenge?.props.owner);
    handleUpdateChallengeProp(ChallengePropAction.MAINTAINERS, currentMaintainers);
  };

  const handleTransferOwnership = () => {
    const newOwner = newOwnerEmail.trim();
    if (isEmailValid(newOwner)) {
      handleUpdateChallengeProp(ChallengePropAction.OWNER, newOwner);
      setConfirmTransferVisible(false);
      setNewOwnerEmail('');
    }
    return;
  };

  const activeChallenge = editMode && editedChallenge ? editedChallenge : challenge;

  return (
    <ExpandableSection
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.challenges.challengeDetails.headings.collaborators)}</Header>}>
      <SpaceBetween size="m">
        {editMode && (
          <SpaceBetween size="s">
            <TextContent>{t(i18nKeys.challenges.challengeDetails.text.addCollaborator)}</TextContent>
            <SpaceBetween size="s" direction="horizontal">
              <Input
                value={collaborator}
                onChange={({ detail }) => setCollaborator(detail.value)}
                placeholder={'user@example.com'}
              />
              <Button iconName="add-plus" onClick={addCollaborator}>
                {t(i18nKeys.challenges.challengeDetails.buttons.add)}
              </Button>
            </SpaceBetween>
          </SpaceBetween>
        )}
        <Table
          variant="full-page"
          columnDefinitions={
            editMode
              ? [
                  {
                    id: 'email',
                    header: t(i18nKeys.challenges.challengeDetails.table.titles.email),
                    cell: (item) => item || '-',
                    sortingField: 'name',
                  },
                  {
                    id: 'role',
                    header: t(i18nKeys.challenges.challengeDetails.table.titles.role),
                    cell: (item) => {
                      if (activeChallenge.props.owner === item) {
                        return t(i18nKeys.challenges.challengeDetails.table.role.owner);
                      } else {
                        return t(i18nKeys.challenges.challengeDetails.table.role.maintainer);
                      }
                    },
                  },
                  {
                    id: 'actions',
                    header: t(i18nKeys.general.actions),
                    cell: (item) => {
                      if (item !== activeChallenge.props.owner) {
                        return (
                          <Button onClick={() => removeCollaborator(item)} iconName={'close'}>
                            {t(i18nKeys.general.remove)}
                          </Button>
                        );
                      } else {
                        return (
                          <Button onClick={() => setConfirmTransferVisible(true)}>
                            {t(i18nKeys.challenges.challengeDetails.buttons.transferOwnership)}
                          </Button>
                        );
                      }
                    },
                  },
                ]
              : [
                  {
                    id: 'email',
                    header: t(i18nKeys.challenges.challengeDetails.table.titles.email),
                    cell: (item) => item || '-',
                    sortingField: 'name',
                  },
                  {
                    id: 'role',
                    header: t(i18nKeys.challenges.challengeDetails.table.titles.role),
                    cell: (item) => {
                      if (activeChallenge.props.owner === item) {
                        return t(i18nKeys.challenges.challengeDetails.table.role.owner);
                      } else {
                        return t(i18nKeys.challenges.challengeDetails.table.role.maintainer);
                      }
                    },
                  },
                ]
          }
          items={collaborators}
          loadingText={t(i18nKeys.challenges.challengeDetails.table.loadingCollaborators)}
          sortingDisabled
          empty={
            <Box textAlign="center" color="inherit">
              <b>{t(i18nKeys.challenges.challengeDetails.table.emptyCollaboratorsState)}</b>
              <Box padding={{ bottom: 's' }} variant="p" color="inherit">
                {t(i18nKeys.challenges.challengeDetails.table.emptyCollaboratorsSubtitle)}
              </Box>
            </Box>
          }
        />
      </SpaceBetween>
      <ConfirmModal
        message={t(i18nKeys.challenges.challengeDetails.messages.confirmNewOwnersEmail)}
        visible={confirmTransferVisible}
        textInput={newOwnerEmail}
        onTextInput={setNewOwnerEmail}
        onConfirm={handleTransferOwnership}
        onCancel={() => setConfirmTransferVisible(false)}
      />
    </ExpandableSection>
  );
};

export default ChallengeCollaboratorsDetail;
