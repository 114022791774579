import { ExpandableSection, Header, Textarea, TextContent } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ChallengePropAction, useCreateChallenge } from '../../../../store/create-challenge.context';
import { Challenge } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';

interface ChallengeFacilitatorNotesDetailProps {
  challenge: Challenge;
}

const ChallengeFacilitatorNotesDetail: React.FC<ChallengeFacilitatorNotesDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { editMode, editedChallenge, handleUpdateChallengeProp } = useCreateChallenge();

  return (
    <ExpandableSection
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.challenges.challengeDetails.headings.facilitatorNotes)}</Header>}>
      <TextContent>
        {!editMode && challenge.props.facilitatorNotes && challenge.props.facilitatorNotes}
        {!editMode &&
          !challenge.props.facilitatorNotes &&
          t(i18nKeys.challenges.challengeDetails.field.facilitatorNotes.emptyState)}
        {editMode && editedChallenge && (
          <Textarea
            onChange={({ detail }) => handleUpdateChallengeProp(ChallengePropAction.FACILITATOR_NOTES, detail.value)}
            value={editedChallenge.props.facilitatorNotes || ''}
          />
        )}
      </TextContent>
    </ExpandableSection>
  );
};

export default ChallengeFacilitatorNotesDetail;
