import React from 'react';
import { ExpandableSection, Header, Link } from '@amzn/awsui-components-react';
import { Columns } from '../../../common/Columns';
import { Column } from '../../../common/Column';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../../../utils/i18n.utils';
import {
  eventOwnerChecklistUrl,
  facilitatorChecklistUrl,
  getGameUIUrl,
  jamFacilitatorGuideUrl,
} from '../../../../utils/jam-urls';

const PreperationGuide: React.FC = () => {
  const { t } = useTranslation();
  return (
    <ExpandableSection
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.preparationGuide)}</Header>}>
      <Columns columns={2} variant="default">
        <Column size="s">
          <Header variant="h3">
            <strong>{t(i18nKeys.events.eventDetails.headers.firstJam)}</strong>
          </Header>
          <ul>
            <li style={{ marginLeft: '-22px', marginTop: '-12px' }}>
              <Link external href={getGameUIUrl() + '/register'}>
                {t(i18nKeys.events.eventDetails.messages.registerForAccount)}
              </Link>
              {t(i18nKeys.events.eventDetails.messages.ifYouDontAlready)}
            </li>
          </ul>
        </Column>
        <Column size="s">
          <Header variant="h3">
            <strong>{t(i18nKeys.events.eventDetails.headers.prepareToRunAJam)}</strong>
          </Header>
          <ul>
            <li style={{ marginLeft: '-22px', marginTop: '-12px' }}>
              {t(i18nKeys.events.eventDetails.messages.reviewAndComplete)}
              <Link external href={eventOwnerChecklistUrl}>
                {t(i18nKeys.events.eventDetails.messages.eventOwnerChecklist)}
              </Link>
            </li>
          </ul>
        </Column>
        <Column size="s">
          <Header variant="h3">
            <strong>{t(i18nKeys.events.eventDetails.headers.ensureChallengesTest)}</strong>
          </Header>
          <ul>
            <li style={{ marginLeft: '-22px', marginTop: '-12px' }}>
              {t(i18nKeys.events.eventDetails.messages.challenge24HoursTest)}
            </li>
            <li style={{ marginLeft: '-22px' }}>{t(i18nKeys.events.eventDetails.messages.challengeVerifyAndReview)}</li>
          </ul>
        </Column>
        <Column size="s">
          <Header variant="h3">
            <strong>{t(i18nKeys.events.eventDetails.headers.ensureEventFacilitators)}</strong>
          </Header>
          <ul>
            <li style={{ marginLeft: '-22px', marginTop: '-12px' }}>
              {t(i18nKeys.events.eventDetails.messages.facilitatorReview)}
              <Link external href={jamFacilitatorGuideUrl}>
                {t(i18nKeys.events.eventDetails.messages.facilitatorGuide)}
              </Link>{' '}
              {t(i18nKeys.general.and)}
            </li>
            <li style={{ marginLeft: '-22px' }}>
              {t(i18nKeys.events.eventDetails.messages.facilitatorCompleteThe)}
              <Link external href={facilitatorChecklistUrl}>
                {t(i18nKeys.events.eventDetails.messages.faciliatorChecklist)}
              </Link>
            </li>
          </ul>
        </Column>
      </Columns>
    </ExpandableSection>
  );
};
export default PreperationGuide;
