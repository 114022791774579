import { HOURS_PER_DAY, MINUTES_PER_HOUR, SECONDS_PER_MINUTE } from '@amzn/aws-jam-constants';
import { Container, ExpandableSection, FormField, Grid, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditCampaignActions, useEditCampaign } from '../../../store/edit-campaign.context';
import { Campaign } from '../../../types/Campaign';
import { i18nKeys } from '../../../utils/i18n.utils';
import { getDuration } from '../../../utils/time.utils';
import { InputWithIcon } from '../../common/InputWithIcon';

interface CampaignTimeLimitProps {
  campaign: Campaign | undefined;
}

const CampaignTimeLimit: React.FC<CampaignTimeLimitProps> = ({ campaign }) => {
  const { t } = useTranslation();
  const { campaignEditMode, editedCampaign, handleUpdateEditCampaign, newCampaignMode, newEditedCampaign } =
    useEditCampaign();
  const [days, setDays] = useState('0');
  const [hours, setHours] = useState('0');

  useEffect(() => {
    if (campaignEditMode || newCampaignMode) {
      const totalHours =
        editedCampaign?.campaignSettings.sessionDurationLimitHours ||
        newEditedCampaign?.campaignSettings?.sessionDurationLimitHours ||
        0;
      const newDays = Math.floor(totalHours / HOURS_PER_DAY).toString();
      const newHours = (totalHours % HOURS_PER_DAY).toString();
      setDays(newDays);
      setHours(newHours);
    }
  }, [campaignEditMode, newCampaignMode]);

  useEffect(() => {
    let totalHours = 0;
    totalHours += Number(days) * HOURS_PER_DAY;
    totalHours += Number(hours);
    handleUpdateEditCampaign(EditCampaignActions.TIME_LIMIT, totalHours);
  }, [days, hours]);

  const renderContent = () => {
    return !campaignEditMode && !newCampaignMode ? (
      <div className="section-first-row">
        <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
          <div className="secondary-text">{t(i18nKeys.campaigns.labels.campaignDetails.timeLimit)}</div>
          <div>
            {campaign?.campaignSettings.sessionDurationLimitHours &&
              getDuration(campaign?.campaignSettings.sessionDurationLimitHours * MINUTES_PER_HOUR * SECONDS_PER_MINUTE)}
          </div>
        </Grid>
      </div>
    ) : (
      (campaignEditMode || newCampaignMode) && (
        <FormField label={<strong>{t(i18nKeys.campaigns.labels.campaignDetails.timeLimit)}</strong>}>
          <SpaceBetween direction="horizontal" size="s">
            <InputWithIcon
              value={days}
              type="number"
              disabled={false}
              icon={<span>{t(i18nKeys.campaigns.labels.campaignDetails.days)}</span>}
              onChange={(value) => setDays(value.replaceAll('-', ''))}
            />
            <InputWithIcon
              value={hours}
              type="number"
              disabled={false}
              icon={<span>{t(i18nKeys.campaigns.labels.campaignDetails.hours)}</span>}
              onChange={(value) => setHours(value.replaceAll('-', ''))}
            />
          </SpaceBetween>
        </FormField>
      )
    );
  };

  const headerElement = (
    <Header variant="h2" description={t(i18nKeys.campaigns.headers.campaignDetails.descriptions.timeLimit)}>
      {t(i18nKeys.campaigns.headers.campaignDetails.timeLimit)}
    </Header>
  );

  return (
    <React.Fragment>
      {!newCampaignMode && (
        <ExpandableSection variant="container" header={headerElement}>
          {renderContent()}
        </ExpandableSection>
      )}
      {newCampaignMode && <Container header={headerElement}>{renderContent()}</Container>}
    </React.Fragment>
  );
};

export default CampaignTimeLimit;
