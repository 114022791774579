import { ExpandableSection, FormField, Header, Input, Select } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChallengePropAction,
  ChallengeSettingsFields,
  useCreateChallenge,
} from '../../../../store/create-challenge.context';
import { Challenge, ChallengeDifficulty } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { Column } from '../../../common/Column';
import { Columns } from '../../../common/Columns';
import { KeyValue } from '../../../common/KeyValue';
import { DifficultyDefinitions, SettingTypeDefinitions } from '../../challengesCommon/ChallengeOptionDefinitions';

interface ChallengeBasicSettingDetailProps {
  challenge: Challenge;
}

const ChallengeBasicSettingDetail: React.FC<ChallengeBasicSettingDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { editMode, editedChallenge, handleUpdateChallengeProp, challengeSettingsValidator } = useCreateChallenge();

  const difficulties: OptionDefinition[] = [];
  const settingTypes: OptionDefinition[] = [];

  DifficultyDefinitions.forEach((difficulty: OptionDefinition) => {
    difficulties.push({ label: t(difficulty.label as string), value: difficulty.value });
  });

  SettingTypeDefinitions.forEach((settingType: OptionDefinition) => {
    settingTypes.push({ label: t(settingType.label as string), value: settingType.value });
  });

  const [basicSettings, setBasicSettings] = useState({
    jamType: challenge.props.jamType || '',
    category: challenge.props.category || '',
    difficulty: challenge.props.difficulty.toString() || '',
  });

  const field = i18nKeys.challenges.subSections.settings;
  const [typeErrorText, setTypeErrorText] = useState<string>('');
  const [categoryErrorText, setCategoryErrorText] = useState<string>('');
  const [difficultyErrorText, setDifficultyErrorText] = useState<string>('');

  useEffect(() => {
    // reset error fields when editMode is changed
    if (!editMode) {
      setTypeErrorText('');
      setCategoryErrorText('');
      setDifficultyErrorText('');
    }
  }, [editMode]);

  const validator = challengeSettingsValidator(
    new Map<ChallengeSettingsFields, (error: string) => void>([
      [ChallengeSettingsFields.JAM_TYPE, (error: string) => setTypeErrorText(error)],
      [ChallengeSettingsFields.CATEGORY, (error: string) => setCategoryErrorText(error)],
      [ChallengeSettingsFields.DIFFICULTY, (error: string) => setDifficultyErrorText(error)],
    ])
  );

  useEffect(() => {
    if (editedChallenge) {
      setBasicSettings({
        jamType: editedChallenge.props.jamType || '',
        category: editedChallenge.props.category || '',
        difficulty: editedChallenge.props.difficulty.toString() || '',
      });
    }
  }, [editedChallenge]);

  const handleBasicSettingsUpdate = (e: { target: { name: ChallengeSettingsFields; value: string | undefined } }) => {
    const { name, value } = e.target;

    const newBasicSettings = {
      ...basicSettings,
      [name]: value,
    };

    handleUpdateChallengeProp(ChallengePropAction.BASIC_SETTINGS, newBasicSettings);
    setBasicSettings(newBasicSettings);
  };

  const [expanded, setExpanded] = useState(true);

  return (
    <ExpandableSection
      expanded={expanded}
      onChange={() => setExpanded((prevState) => !prevState)}
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.challenges.challengeDetails.headings.basicSettings)}</Header>}>
      <Columns columns={3} variant="default">
        <Column size="s">
          <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.type)}>
            {!editMode ? (
              challenge.props.jamType
            ) : (
              <FormField constraintText={t(field.container_1.fields.type.constraint)} errorText={typeErrorText}>
                <Select
                  onBlur={() => validator.isValidField(ChallengeSettingsFields.JAM_TYPE)}
                  selectedOption={settingTypes.find((item) => item.value === basicSettings.jamType) || null}
                  onChange={({ detail }) =>
                    handleBasicSettingsUpdate({
                      target: {
                        name: ChallengeSettingsFields.JAM_TYPE,
                        value: detail.selectedOption.value,
                      },
                    })
                  }
                  options={settingTypes}
                  selectedAriaLabel={t(i18nKeys.general.selected)}
                  placeholder={t(i18nKeys.challenges.subSections.settings.container_1.fields.type.placeholder)}
                />
              </FormField>
            )}
          </KeyValue>
        </Column>
        <Column size="s">
          <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.category)}>
            {!editMode ? (
              challenge.props.category
            ) : (
              <FormField constraintText={t(field.container_1.fields.category.constraint)} errorText={categoryErrorText}>
                <Input
                  onBlur={() => validator.isValidField(ChallengeSettingsFields.CATEGORY)}
                  onChange={({ detail }) =>
                    handleBasicSettingsUpdate({
                      target: { name: ChallengeSettingsFields.CATEGORY, value: detail.value },
                    })
                  }
                  value={basicSettings.category || ''}
                  autoComplete
                  placeholder={t(i18nKeys.challenges.subSections.settings.container_1.fields.category.placeholder)}
                />
              </FormField>
            )}
          </KeyValue>
        </Column>
        <Column size="s">
          <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.difficulty)}>
            {!editMode ? (
              t(ChallengeDifficulty.getByKey(challenge.props.difficulty).i18nKeyShort)
            ) : (
              <FormField
                constraintText={t(field.container_1.fields.difficulty.constraint)}
                errorText={difficultyErrorText}>
                <Select
                  onBlur={() => validator.isValidField(ChallengeSettingsFields.DIFFICULTY)}
                  selectedOption={
                    difficulties.find(
                      (difficulty) => difficulty.value === basicSettings.difficulty
                    ) as OptionDefinition
                  }
                  onChange={({ detail }) =>
                    handleBasicSettingsUpdate({
                      target: { name: ChallengeSettingsFields.DIFFICULTY, value: detail.selectedOption.value },
                    })
                  }
                  options={difficulties}
                  selectedAriaLabel={t(i18nKeys.general.selected)}
                  placeholder={t(i18nKeys.challenges.subSections.settings.container_1.fields.difficulty.placeholder)}
                />
              </FormField>
            )}
          </KeyValue>
        </Column>
      </Columns>
    </ExpandableSection>
  );
};

export default ChallengeBasicSettingDetail;
