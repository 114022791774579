import { ExpandableSection, Grid, Header } from '@amzn/awsui-components-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../utils/i18n.utils';
import { WithApprovalAndChangeRequest } from '../../types/Approvable';
import { DiffChange } from '../../types/Diff';
import { useFlashbars } from '../../store/flashbar.context';

interface ChangeRequestSummaryProps {
  source: WithApprovalAndChangeRequest;
  getPendingChanges: () => DiffChange[];
}

const ChangeRequestSummary: React.FC<ChangeRequestSummaryProps> = ({ source, getPendingChanges }) => {
  const { t } = useTranslation();
  const changes = getPendingChanges();
  const { addInfoFlashbar } = useFlashbars();

  useEffect(() => {
    if (source?.pendingChangeRequest) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      addInfoFlashbar(t(i18nKeys.changeRequest.infoFlashbar));
    }
  }, []);

  return (
    <React.Fragment>
      {source?.pendingChangeRequest && (
        <ExpandableSection
          className="mb-8"
          variant="container"
          header={<Header variant="h2">{t(i18nKeys.changeRequest.title)}</Header>}
          defaultExpanded>
          <div className="container-table-header">
            <Grid gridDefinition={[{ colspan: 2 }, { colspan: 4 }, { colspan: 4 }]}>
              <div className="table-divider">
                <strong>{t(i18nKeys.changeRequest.fields.property.title)}</strong>
              </div>
              <div>
                <strong>{t(i18nKeys.changeRequest.fields.oldValue.title)}</strong>
              </div>
              <div className="table-divider">
                <strong>{t(i18nKeys.changeRequest.fields.newValue.title)}</strong>
              </div>
            </Grid>
          </div>
          <div className="grid-striped">
            {changes.map((change, i) => {
              return (
                <div key={i} className="grid-row" style={{ paddingTop: '10px' }}>
                  <Grid gridDefinition={[{ colspan: 2 }, { colspan: 4 }, { colspan: 4 }]}>
                    <div>{change.property}</div>
                    <div>{change.previousValue}</div>
                    <div>{change.updatedValue}</div>
                  </Grid>
                </div>
              );
            })}
          </div>
        </ExpandableSection>
      )}
    </React.Fragment>
  );
};
export default ChangeRequestSummary;
