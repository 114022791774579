import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { NoMatch } from '../common/NoMatch';
import routes from '../../routes';
import { TermsAndConditionsGuard } from '../terms-conditions/TermsAndConditionsGuard';
import RestrictedRoute from './RestrictedRoute';

/* Main content to show the user if they are authenticated. */
const AuthenticatedRoutes: React.FC = () => {
  return (
    <TermsAndConditionsGuard>
      <Switch>
        {routes.map(({ path, exact, Component, requiredGroups }, key) => {
          return (
            <RestrictedRoute
              exact={exact}
              path={path}
              key={key}
              requiredGroups={requiredGroups}
              component={Component}
            />
          );
        })}
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </TermsAndConditionsGuard>
  );
};

export default AuthenticatedRoutes;
