import { FormField, Header, Select, SpaceBetween } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../utils/i18n.utils';
import JamUILocalization from './Sections/JamUILocalization';
import GDPR from './Sections/GDPR';
import LSE from './Sections/LSE';
import UserManagement from './Sections/UserManagement';
import { useHistory, useLocation } from 'react-router-dom';

const Settings: React.FC = () => {
  const { t } = useTranslation();
  const Categories = {
    USER_MANAGEMENT: 'user-management',
    JAM_UI_LOCALIZATION: 'jam-ui-localization',
    GDPR: 'gdpr',
    LSE: 'lse',
  };

  const categoryOptions: OptionDefinition[] = [
    { label: t(i18nKeys.settings.categories.placeholder), value: '' },
    { label: t(i18nKeys.settings.categories.userManagement), value: Categories.USER_MANAGEMENT },
    { label: t(i18nKeys.settings.categories.jamUILocalization), value: Categories.JAM_UI_LOCALIZATION },
    { label: 'GDPR', value: Categories.GDPR },
    { label: 'LSE', value: Categories.LSE },
  ];
  const [selectedCategory, setSelectedCategory] = useState<OptionDefinition>(categoryOptions[0]);
  const history = useHistory();
  const location = useLocation();

  const renderSection = () => {
    switch (selectedCategory.value) {
      case Categories.JAM_UI_LOCALIZATION:
        return <JamUILocalization />;
      case Categories.LSE:
        return <LSE />;
      case Categories.USER_MANAGEMENT:
        return <UserManagement />;
      case Categories.GDPR:
        return <GDPR />;
      default:
        return;
    }
  };

  const setCategoryParam = (newCategory: string) => {
    history.push({ search: `category=${newCategory}` });
  };

  const handleLoadQueryParam = () => {
    if (location.search) {
      const category = location.search.split('=')[1];
      const newSelectedOption = categoryOptions.find((option) => option.value === category);
      if (newSelectedOption) {
        setSelectedCategory(newSelectedOption);
      }
    }
  };

  useEffect(() => {
    handleLoadQueryParam();
  }, []);

  return (
    <React.Fragment>
      <SpaceBetween direction="vertical" size="s">
        <Header variant="h1">{t(i18nKeys.settings.title)}</Header>
        <FormField label={t(i18nKeys.settings.categories.chooseCategory)}>
          <Select
            className="long-select-input"
            options={categoryOptions}
            selectedOption={selectedCategory}
            onChange={({ detail }) => {
              setCategoryParam(detail.selectedOption.value || '');
              setSelectedCategory(detail.selectedOption);
            }}
          />
        </FormField>
        {renderSection()}
      </SpaceBetween>
    </React.Fragment>
  );
};

export default Settings;
