import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Button,
  CollectionPreferencesProps,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react';
import { TableHeader } from '../TableHeader';
import { Event } from '../../../types/Event';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../../utils/i18n.utils';
import { Team } from '../../../types/Team';
import { COLUMN_DEFINITIONS, filteringFunction } from './teams-config';
import { TimezoneFormat } from '../DateTime';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableEmptyState } from '../TableEmptyState';
import { paginationLabels } from '../../../utils/table.utils';

interface TeamTableProps {
  target: Event;
  teams: Team[];
  toggleTeamDetails: (teamName: string) => void;
  selectTeamName: (teamName: string) => void;
  setCreateTeamsVisible: Dispatch<SetStateAction<boolean>>;
  setCreateTeamVisible: Dispatch<SetStateAction<boolean>>;
  setTeamDetailsVisible: Dispatch<SetStateAction<boolean>>;
  setRenameTeamAliasVisible: Dispatch<SetStateAction<boolean>>;
  setDisableTeamVisible: Dispatch<SetStateAction<boolean>>;
  setDeleteTeamVisible: Dispatch<SetStateAction<boolean>>;
}

export const TeamTable: React.FC<TeamTableProps> = ({
  target,
  teams,
  toggleTeamDetails,
  selectTeamName,
  setCreateTeamsVisible,
  setCreateTeamVisible,
  setTeamDetailsVisible,
  setRenameTeamAliasVisible,
  setDisableTeamVisible,
  setDeleteTeamVisible,
}) => {
  const { t } = useTranslation();

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    pageSize: 10,
    custom: TimezoneFormat.LOCAL,
  });

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    teams || [],
    {
      filtering: {
        filteringFunction,
        empty: (
          <TableEmptyState
            title={t(i18nKeys.participants.labels.empty.teams.title)}
            subtitle={t(i18nKeys.participants.labels.empty.teams.subtitle)}
          />
        ),
        noMatch: (
          <TableEmptyState
            title={t(i18nKeys.tables.noMatch.title)}
            subtitle={t(i18nKeys.tables.noMatch.subtitle)}
            onClearFilter={() => actions.setFiltering('')}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
    }
  );

  return (
    <Table
      className="mb-12"
      {...collectionProps}
      header={
        <TableHeader
          title={t(i18nKeys.participants.headers.teams)}
          actionButtons={[
            <SpaceBetween key="space-between" direction="horizontal" size="s">
              {!target?.isCampaignEvent && (
                <Button key="bulk-create-team" onClick={() => setCreateTeamsVisible(true)}>
                  {t(i18nKeys.participants.buttons.bulkCreateTeam)}
                </Button>
              )}
              {!target?.isCampaignEvent && (
                <Button key="create-team" variant="primary" onClick={() => setCreateTeamVisible(true)}>
                  {t(i18nKeys.participants.buttons.createTeam)}
                </Button>
              )}
            </SpaceBetween>,
          ]}
        />
      }
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels(t)} />}
      items={items}
      filter={
        <SpaceBetween direction="horizontal" size="s">
          <TextFilter
            className="text-filter"
            filteringPlaceholder={t(i18nKeys.participants.labels.searchTeams)}
            {...filterProps}
            countText={t(i18nKeys.tables.matchesCount, { count: filteredItemsCount })}
            filteringAriaLabel={t(i18nKeys.events.filteringLabel)}
          />
        </SpaceBetween>
      }
      columnDefinitions={COLUMN_DEFINITIONS(
        preferences,
        target,
        toggleTeamDetails,
        selectTeamName,
        setTeamDetailsVisible,
        setRenameTeamAliasVisible,
        setDisableTeamVisible,
        setDeleteTeamVisible
      )}
    />
  );
};
