import {
  Checkbox,
  Container,
  DatePicker,
  ExpandableSection,
  FormField,
  Grid,
  Header,
  Input,
  Select,
  SpaceBetween,
  Tiles,
  TimeInput,
} from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { Moment } from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HOURS_PER_MONTH, MeridiemOptions } from '../../../../constants/DateTimeConstants';
import { EditEventActions, useEditEvent } from '../../../../store/edit-event.context';
import { NullableString } from '../../../../types/common';
import { Event } from '../../../../types/Event';
import {
  getBrowserTimezoneName,
  getTimeForPolarisTimePicker,
  getTimeInBrowserLocalTime,
  getTimezonesWithUTCOffsetAsOptionList,
  polarisDateTimeToMoment,
} from '../../../../utils/event-time.utils';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { getLanguageCodeSafe } from '../../../../utils/locale.utils';
import { containsOnlyNumbers } from '../../../../utils/string.utils';
import { DateTime, DateTimeKeys, TimezoneFormat } from '../../../common/DateTime';
import { KeyValue } from '../../../common/KeyValue';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';

interface EventTimesProps {
  target: Event | undefined;
}

const EventTimes: React.FC<EventTimesProps> = ({ target }) => {
  const { i18n, t } = useTranslation();
  const { editMode, newEventMode, handleUpdateEditEvent, newDuration, setNewDuration } = useEditEvent();
  const [timezoneOptions, setTimezoneOptions] = useState<OptionDefinition[]>([]);
  const [selectedTimezoneOption, setSelectedTimezoneOption] = useState<OptionDefinition | null>(null);
  const [startDate, setStartDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [selectedMeridiem, setSelectedMeridiem] = useState<OptionDefinition>(MeridiemOptions.AM);
  const [selectedSpecificDuration, setSelectedSpecificDuration] = useState<NullableString>(null);
  const [autoUnlockChallenges, setAutoUnlockChallenges] = useState(false);
  const [autoUnlockChallengesMinutes, setAutoUnlockChallengesMinutes] = useState('0');

  const canEdit = newEventMode || editMode;
  const languageCode: string = getLanguageCodeSafe(i18n.language);

  const durationItems: { [key: number]: { label: string; value: string } } = {
    4: { label: t(i18nKeys.events.eventDetails.labels.durationItems.fourHours), value: '4' },
    8: { label: t(i18nKeys.events.eventDetails.labels.durationItems.eightHours), value: '8' },
  };
  useEffect(() => {
    setupSelections();
  }, []);

  useEffect(() => {
    setupSelections();
  }, [editMode, newEventMode]);

  /**
   * Url template generator isnt catching default update to target until second time it renders,
   * watch target change here and verify we add default if target changes and it doesnt exist
   */
  useEffect(() => {
    if (newEventMode && !target?.timezone) {
      const defaultTimezone = getBrowserTimezoneName();
      handleUpdateEditEvent(EditEventActions.TIMEZONE, defaultTimezone);
    }
  }, [target]);

  useEffect(() => {
    if (
      (editMode || newEventMode) &&
      (startDate !== target?.startDate ||
        durationChanged() ||
        getTimeForPolarisTimePicker(target?.startDate) !== startTime ||
        target?.timezone !== selectedTimezoneOption?.value)
    ) {
      handleSetStartAndEndDateTimes();
    }
  }, [startDate, startTime, selectedMeridiem, selectedTimezoneOption, newDuration, selectedSpecificDuration]);

  const setupSelections = () => {
    const newTimezoneOptions = getTimezonesWithUTCOffsetAsOptionList();
    setTimezoneOptions(newTimezoneOptions);
    const defaultTimezone = target?.timezone ? target.timezone : getBrowserTimezoneName();
    if (!target?.timezone) {
      handleUpdateEditEvent(EditEventActions.TIMEZONE, defaultTimezone);
    }
    if (defaultTimezone) {
      const selectedTimezone = newTimezoneOptions.find((e) => e.value === defaultTimezone);
      if (selectedTimezone) {
        setSelectedTimezoneOption(selectedTimezone);
      }
    }
    if (target?.autoUnlockChallengesOffsetMinutes) {
      setAutoUnlockChallenges(true);
      setAutoUnlockChallengesMinutes(target?.autoUnlockChallengesOffsetMinutes.toString());
    }
    setSelectedStartDateAndTime();
    getSelectedDurationPreset();
  };

  const durationChanged = () => {
    if (target?.duration.minutes !== 0 && target?.duration.hours && selectedSpecificDuration !== null) {
      return formatDuration(target?.duration?.hours, target?.duration.minutes) !== selectedSpecificDuration;
    } else if (newDuration && target?.duration.hours) {
      return target?.duration.hours !== Number(newDuration);
    }
    return false;
  };

  const getSelectedDurationPreset = () => {
    if (target && (target.duration.hours || target?.duration.minutes)) {
      if (durationItems[target.duration.hours] && target.duration.minutes === 0) {
        setNewDuration(target.duration.hours.toString());
      } else if (target.duration.minutes !== 0 || target.duration.hours > HOURS_PER_MONTH) {
        setNewDuration('specific');
        setSelectedSpecificDuration(formatDuration(target.duration.hours, target.duration.minutes));
      }
    }
  };

  const setSelectedStartDateAndTime = () => {
    if (target?.startDate) {
      // Polaris DatePicker component does not properly parse full startDate with time
      // Spliting here fixes default selection for DatePicker
      setStartDate(target?.startDate.split('T')[0]);
      const startTimeFromEvent = getTimeInBrowserLocalTime(target?.startDate, {
        includeDate: false,
        includeTime: true,
      });
      const dateSelectorTime = getTimeForPolarisTimePicker(target?.startDate);
      if (startTimeFromEvent && dateSelectorTime) {
        if (startTimeFromEvent.includes('PM')) {
          setSelectedMeridiem(MeridiemOptions.PM);
        } else {
          setSelectedMeridiem(MeridiemOptions.AM);
        }
        setStartTime(dateSelectorTime);
      }
    }
  };

  const formatDuration = (hours: number, minutes: number) => {
    return `${hours}:${minutes}`;
  };

  const handleTimezoneSelection = (optionSelection: OptionDefinition) => {
    setSelectedTimezoneOption(optionSelection);
    handleUpdateEditEvent(EditEventActions.TIMEZONE, optionSelection.value);
  };

  const handleDurationChange = (duration: string) => {
    setNewDuration(duration);
    setSelectedSpecificDuration(null);
  };

  const handleAutoUnlockChallengesCheck = (autoUnlockChallengesCheck: boolean) => {
    setAutoUnlockChallenges(autoUnlockChallengesCheck);
    if (!autoUnlockChallengesCheck) {
      setAutoUnlockChallengesMinutes('');
    } else {
      setAutoUnlockChallengesMinutes('0');
    }
  };

  const handleAutoUnlockChallengesMinutesChange = (selectedAutoUnlockChallengesMinutes: string) => {
    if (selectedAutoUnlockChallengesMinutes && containsOnlyNumbers(selectedAutoUnlockChallengesMinutes)) {
      setAutoUnlockChallengesMinutes(selectedAutoUnlockChallengesMinutes);
      handleUpdateEditEvent(
        EditEventActions.AUTO_UNLOCK_CHALLENGES_MINUTES,
        Number(selectedAutoUnlockChallengesMinutes)
      );
    }
  };

  const handleSetStartAndEndDateTimes = () => {
    if (startDate && startTime && selectedMeridiem && selectedTimezoneOption && selectedTimezoneOption.value) {
      const startDateTime = polarisDateTimeToMoment(
        startDate,
        `${startTime} ${selectedMeridiem.value}`,
        selectedTimezoneOption.value
      ).format();

      // Cloning/reassigning startDateTime to endDateTime was causing the math to affect both start and end dateTimes
      // so, we recreate the startDateTime moment to modify here
      let endDateTime: Moment | string = polarisDateTimeToMoment(
        startDate,
        `${startTime} ${selectedMeridiem.value}`,
        selectedTimezoneOption.value
      );

      if (selectedSpecificDuration) {
        const [hours, minutes] = selectedSpecificDuration.split(':');
        endDateTime = endDateTime.add(hours, 'hours').add(minutes, 'minutes').format();
      } else {
        endDateTime = endDateTime.add(newDuration, 'hours').format();
      }

      handleUpdateEditEvent(EditEventActions.START_AND_END_DATETIMES, {
        startDateTime,
        endDateTime,
      });
    }
  };

  const renderContent = () => {
    return !canEdit ? (
      <React.Fragment>
        <div className="container-table-header">
          <Grid gridDefinition={[{ colspan: 2 }, { colspan: 4 }, { colspan: 4 }]}>
            <div className="table-divider" />
            <div>
              <strong>{t(i18nKeys.events.eventDetails.labels.eventLocalTime)}</strong>
            </div>
            <div className="table-divider">
              <strong>{t(i18nKeys.events.eventDetails.labels.yourLocalTime)}</strong>
            </div>
          </Grid>
        </div>
        <div style={{ paddingTop: '10px', marginBottom: '-20px' }}>
          <Grid gridDefinition={[{ colspan: 2 }, { colspan: 4 }, { colspan: 4 }]}>
            <div style={{ lineHeight: '40px' }}>{t(i18nKeys.events.eventDetails.labels.startTime)}</div>
            <div>
              <strong>
                <DateTime
                  startOrEnd={DateTimeKeys.START}
                  event={target}
                  timezoneFormat={TimezoneFormat.UTC}
                  displayEventTimezone
                  displayInlineDateTime
                />
              </strong>
            </div>
            <div>
              <strong>
                <DateTime
                  startOrEnd={DateTimeKeys.START}
                  event={target}
                  timezoneFormat={TimezoneFormat.LOCAL}
                  displayLocalTimezone
                  displayInlineDateTime
                />
              </strong>
            </div>
          </Grid>
        </div>
        <div className="grey-section-divider-top">
          <Grid gridDefinition={[{ colspan: 2 }, { colspan: 4 }, { colspan: 4 }]}>
            <div style={{ lineHeight: '40px' }}>{t(i18nKeys.events.eventDetails.labels.endTime)}</div>
            <div>
              <strong>
                <DateTime
                  startOrEnd={DateTimeKeys.END}
                  event={target}
                  timezoneFormat={TimezoneFormat.UTC}
                  displayEventTimezone
                  displayInlineDateTime
                />
              </strong>
            </div>
            <div>
              <strong>
                <DateTime
                  startOrEnd={DateTimeKeys.END}
                  event={target}
                  timezoneFormat={TimezoneFormat.LOCAL}
                  displayLocalTimezone
                  displayInlineDateTime
                />
              </strong>
            </div>
          </Grid>
        </div>
      </React.Fragment>
    ) : (
      canEdit && (
        <React.Fragment>
          <Grid gridDefinition={[{ colspan: 8 }]}>
            <SpaceBetween direction="vertical" size="s">
              <KeyValue label={<b>{t(i18nKeys.events.eventDetails.labels.timezone)}</b>} required>
                <Select
                  selectedOption={selectedTimezoneOption}
                  options={timezoneOptions}
                  onChange={({ detail }) => handleTimezoneSelection(detail.selectedOption)}
                />
              </KeyValue>
              <FormField
                label={<b>{t(i18nKeys.events.eventDetails.labels.startTime)}</b>}
                description={t(i18nKeys.events.eventDetails.messages.startTimeDescription)}
                info={<span style={{ color: awsui.colorTextStatusError }}> *</span>}>
                <DatePicker
                  className="inline"
                  onChange={({ detail }) => setStartDate(detail.value)}
                  value={startDate}
                  openCalendarAriaLabel={(selectedDate) =>
                    t(i18nKeys.general.chooseDate) +
                    (selectedDate ? t(i18nKeys.general.selectedDate, { selectedDate }) : '')
                  }
                  placeholder="YYYY/MM/DD"
                  locale={languageCode}
                  previousMonthAriaLabel={t(i18nKeys.events.fields.filters.labels.previousMonth)}
                  nextMonthAriaLabel={t(i18nKeys.events.fields.filters.labels.nextMonth)}
                  todayAriaLabel={t(i18nKeys.events.fields.filters.labels.today)}
                />
                <TimeInput
                  className="time-input inline ml-5"
                  value={startTime}
                  placeholder="hh:mm"
                  format="hh:mm"
                  onChange={({ detail }) => setStartTime(detail.value)}
                />
                <Select
                  className="inline-select"
                  options={[MeridiemOptions.AM, MeridiemOptions.PM]}
                  onChange={({ detail }) => setSelectedMeridiem(detail.selectedOption)}
                  selectedOption={selectedMeridiem}
                />
              </FormField>
              <KeyValue label={<b>{t(i18nKeys.events.eventDetails.labels.duration)}</b>} required>
                <SpaceBetween direction="vertical" size="s">
                  <Tiles
                    value={newDuration}
                    onChange={({ detail }) => handleDurationChange(detail.value)}
                    items={[
                      durationItems[4],
                      durationItems[8],
                      {
                        label: (
                          <KeyValue label={t(i18nKeys.events.eventDetails.labels.specific)}>
                            {newDuration === 'specific' && (
                              <Input
                                type="text"
                                placeholder="hhh:mm"
                                value={selectedSpecificDuration || ''}
                                onChange={({ detail }) => setSelectedSpecificDuration(detail.value)}
                              />
                            )}
                          </KeyValue>
                        ),
                        value: 'specific',
                      },
                    ]}
                  />
                  <div>
                    <span style={{ display: "inline-block", verticalAlign: "middle", marginRight: "0.5em", marginTop: "-0.2em" }}>
                      <Checkbox
                        checked={autoUnlockChallenges}
                        onChange={() => handleAutoUnlockChallengesCheck(!autoUnlockChallenges)} />
                    </span>
                    <span style={{ verticalAlign: "middle" }}>
                      {t(i18nKeys.events.eventDetails.messages.automaticallyUnlockChallenges)}
                      <Input
                        disabled={!autoUnlockChallenges}
                        inputMode="numeric"
                        type="number"
                        value={autoUnlockChallengesMinutes}
                        onChange={({ detail }) => handleAutoUnlockChallengesMinutesChange(detail.value)}
                        className="numeric-input"
                      />
                      {t(i18nKeys.events.eventDetails.messages.minutesAfterStart)}
                    </span>
                  </div>
                  <div className="secondary-text">
                    {t(i18nKeys.events.eventDetails.messages.challengesWillRemainLocked)}
                  </div>
                </SpaceBetween>
              </KeyValue>
            </SpaceBetween>
          </Grid>
        </React.Fragment>
      )
    );
  };

  return !newEventMode ? (
    <ExpandableSection
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.eventTimes)}</Header>}>
      {renderContent()}
    </ExpandableSection>
  ) : (
    <Container header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.eventTimes)}</Header>}>
      {renderContent()}
    </Container>
  );
};
export default EventTimes;
