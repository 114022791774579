/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button, ButtonDropdown, Link, SpaceBetween } from '@amzn/awsui-components-react';
import { capitalize } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../store/api.context';
import { useChallenges } from '../../../store/challenge.context';
import { useUser } from '../../../store/user.context';
import { Challenge } from '../../../types/Challenge';
import { Lab, LabSignInDetails, LabStatus } from '../../../types/LabModels';
import { LabProvider } from '../../../types/LabProvider';
import { getTimeInBrowserLocalTime } from '../../../utils/event-time.utils';
import { i18nKeys } from '../../../utils/i18n.utils';
import { preProdLogger } from '../../../utils/log.utils';
import { getTableFilterMatcher, TableFilterMatcher } from '../../../utils/table.utils';
import { timeUntil } from '../../../utils/time.utils';

export const filteringFunction = (item: Lab, filteringText: string): boolean => {
  const matcher: TableFilterMatcher = getTableFilterMatcher(filteringText);
  return (
    matcher.filterEmpty ||
    matcher.isMatch(item?.id) ||
    matcher.isMatch(item?.challengeId) ||
    matcher.isMatch(item?.status) ||
    matcher.isMatch(item?.extStatus) ||
    matcher.isMatch(item?.team?.name || '') ||
    matcher.isMatch(item?.awsAccountNumber)
  );
};

export const COLUMN_DEFINITIONS = (
  toggleStatusHistory: (lab: Lab) => void,
  toggleResourceDeploymentHistory: (lab: Lab) => void,
  toggleError: (lab: Lab) => void,
  toggleUnassignAccount: (lab: Lab) => void,
  toggleTerminateLab: (lab: Lab) => void,
  toggleMetadata: (lab: Lab) => void,
  toggleAccountStackDetails: (lab: Lab) => void,
  handleTeamSignInUrl: (lab: Lab, fetch?: boolean) => void,
  handleAdminSignInUrl: (lab: Lab, fetch?: boolean) => void,
  handleMasterSignInUrl: (lab: Lab, fetch?: boolean) => void
) => {
  const { t } = useTranslation();
  const { eventsApi } = useApi();
  const { user } = useUser();
  const { challengeWrapperMap } = useChallenges();

  const [teamSignInDetails, setTeamSignInDetails] = useState<LabSignInDetails | undefined>();
  const [adminSignInDetails, setAdminSignInDetails] = useState<LabSignInDetails | undefined>();
  const [masterSignInDetails, setMasterSignInDetails] = useState<LabSignInDetails | undefined>();

  // eslint-disable-next-line no-shadow
  enum LabAccountAction {
    SSH_KEY = 'sshKey',
    UNASSIGN_ACCOUNT = 'unassignAccount',
    TERMINATE_LAB = 'terminateLab',
    STATUS_HISTORY = 'statusHistory',
    RESOURCE_DEPLOYMENT_HISTORY = 'resourceDeploymentHistory',
    CLOUDFORMATION_DETAILS = 'cloudformationStackDetails',
    VIEW_METADATA = 'viewMetadata',
  }

  const getChallenge = (challengeId: string): Challenge | null => {
    return challengeWrapperMap[challengeId].latest;
  };

  const isLabUsable = (lab: Lab) => {
    // non terminal states
    // for list of terminal states see CBE: com.amazonaws.awsjam.model.data.LabAccount.Status
    switch (lab.status) {
      case LabStatus.NOT_READY:
      case LabStatus.UNASSIGNED:
      case LabStatus.ASSIGNED:
      case LabStatus.PREPARING_RESOURCES:
        return true;
      default:
        return false;
    }
  };

  const getTeamSignInUrl = (lab: Lab, fetch = true) => {
    eventsApi
      .getChallengeLabAdminSignIn(lab, fetch)
      .then((signInDetails) => {
        if (signInDetails) {
          setTeamSignInDetails(signInDetails);
        }
      })
      .catch((err) => preProdLogger(err));
  };

  const getAdminSignInUrl = (lab: Lab, fetch = true) => {
    eventsApi
      .getChallengeLabAdminSignIn(lab, fetch)
      .then((signInDetails) => {
        if (signInDetails) {
          setAdminSignInDetails(signInDetails);
        }
      })
      .catch((err) => preProdLogger(err));
  };

  const getMasterSignInUrl = (lab: Lab, fetch = true) => {
    eventsApi
      .getChallengeLabAdminSignIn(lab, fetch)
      .then((signInDetails) => {
        if (signInDetails) {
          setMasterSignInDetails(signInDetails);
        }
      })
      .catch((err) => preProdLogger(err));
  };

  const fetchSignInDetails = (lab: Lab, challenge?: Challenge) => {
    getTeamSignInUrl(lab);
    getAdminSignInUrl(lab);
    if (challenge && challenge.isGameDay) {
      getMasterSignInUrl(lab);
    }
  };

  const downloadSshKeyPair = (lab: Lab) => {
    void eventsApi.getChallengeLabKeyPair(lab);
  };

  const formatStatus = (status: string): string => {
    return status.split('_').map(capitalize).join(' ');
  };

  const handleActionClick = (id: string, lab: Lab) => {
    switch (id) {
      case LabAccountAction.SSH_KEY:
        downloadSshKeyPair(lab);
        break;
      case LabAccountAction.UNASSIGN_ACCOUNT:
        void toggleUnassignAccount(lab);
        break;
      case LabAccountAction.TERMINATE_LAB:
        void toggleTerminateLab(lab);
        break;
      case LabAccountAction.STATUS_HISTORY:
        toggleStatusHistory(lab);
        break;
      case LabAccountAction.RESOURCE_DEPLOYMENT_HISTORY:
        void toggleResourceDeploymentHistory(lab);
        break;
      case LabAccountAction.CLOUDFORMATION_DETAILS:
        toggleAccountStackDetails(lab);
        break;
      case LabAccountAction.VIEW_METADATA:
        toggleMetadata(lab);
        break;
    }
    return;
  };

  return [
    {
      id: 'challenge',
      header: t(i18nKeys.challenges.challenge),
      cell: (item: Lab) => (
        <Link
          external
          externalIconAriaLabel={t(i18nKeys.general.externalIconLabel)}
          href={`/challenges/${item.challengeId}`}>
          {item.challengeId}
        </Link>
      ),
      minWidth: 150,
    },
    {
      id: 'master',
      header: t(i18nKeys.lab.table.titles.primaryAccount),
      cell: (item: Lab) => {
        return (
          <React.Fragment>
            <SpaceBetween direction="horizontal" size="s">
              {item.region && item.regionDescription !== 'Unknown' && <small>{item.regionDescription}</small>}
              {item.regionDescription === 'Unknown' && <small>{t(i18nKeys.general.unknown)}</small>}
              {item.awsAccountNumber && <small>{item.awsAccountNumber}</small>}
            </SpaceBetween>
            {!item.expired && !item.isInFinalStatus && (
              <div className="expiration-time mb-1" title={t(i18nKeys.lab.table.titles.labTimeExpiration)}>
                {t(i18nKeys.general.expires)}{' '}
                {getTimeInBrowserLocalTime(new Date(item.expiration || 0).toISOString(), {
                  includeDate: true,
                  includeTime: true,
                  includeComma: true,
                })}{' '}
                {`(${timeUntil(item.expiration || 0)})`}
              </div>
            )}
            {item.canSignIn && (isLabUsable(item) || item.error) && (
              <React.Fragment>
                <SpaceBetween direction="horizontal" size="s">
                  {teamSignInDetails || adminSignInDetails || masterSignInDetails ? (
                    <Button iconName="refresh" variant="icon" onClick={() => fetchSignInDetails(item)} />
                  ) : (
                    <Button variant="link" onClick={() => fetchSignInDetails(item)}>
                      {t(i18nKeys.challenges.challengeDetails.buttons.fetchSignInDetails)}
                    </Button>
                  )}
                  {teamSignInDetails && (
                    <small className="mr-1">
                      {t(i18nKeys.general.team)}:{' '}
                      <Link
                        external
                        externalIconAriaLabel={t(i18nKeys.general.externalIconLabel)}
                        href={teamSignInDetails.url || ''}>
                        {t(i18nKeys.general.login)}
                      </Link>
                      <Button variant={'link'} onClick={() => handleTeamSignInUrl(item)}>
                        {t(i18nKeys.general.cli)}
                      </Button>
                    </small>
                  )}
                  {adminSignInDetails && (
                    <small className="mr-1">
                      {t(i18nKeys.general.admin)}:{' '}
                      <Link
                        external
                        externalIconAriaLabel={t(i18nKeys.general.externalIconLabel)}
                        href={adminSignInDetails.url || ''}>
                        {t(i18nKeys.general.login)}
                      </Link>
                      <Button variant={'link'} onClick={() => void handleAdminSignInUrl(item)}>
                        {t(i18nKeys.general.cli)}
                      </Button>
                    </small>
                  )}
                  {masterSignInDetails && (
                    <small className="mr-1">
                      {t(i18nKeys.general.master)}:{' '}
                      <Link
                        external
                        externalIconAriaLabel={t(i18nKeys.general.externalIconLabel)}
                        href={masterSignInDetails.url || ''}>
                        {t(i18nKeys.general.login)}
                      </Link>
                      <Button variant={'link'} onClick={() => void handleMasterSignInUrl(item)}>
                        {t(i18nKeys.general.cli)}
                      </Button>
                    </small>
                  )}
                </SpaceBetween>
              </React.Fragment>
            )}
          </React.Fragment>
        );
      },
      sortingField: 'master',
      minWidth: 400,
      allowLineWrap: true,
    },
    {
      id: 'status',
      header: t(i18nKeys.lab.table.titles.status),
      cell: (item: Lab) => (item ? formatStatus(item.status as string) : '-'),
      sortingField: 'status',
      minWidth: 150,
      allowLineWrap: true,
    },
    {
      id: 'extStatus',
      header: t(i18nKeys.lab.table.titles.labProviderStatus),
      cell: (item: Lab) => (item ? formatStatus(item.extStatus as string) : '-'),
      sortingField: 'extStatus',
      minWidth: 200,
      allowLineWrap: true,
    },
    {
      id: 'assignedTeam',
      header: t(i18nKeys.lab.table.titles.assignedTeam),
      cell: (item: Lab) => (item ? item.team?.name : '-'),
      sortingField: 'assignedTeam',
      minWidth: 200,
      allowLineWrap: true,
    },
    {
      id: 'moreOptions',
      header: t(i18nKeys.lab.table.titles.moreOptions),
      cell: (item: Lab) => {
        const challenge: Challenge | null = getChallenge(item.challengeId || '');
        return (
          <ButtonDropdown
            items={[
              {
                id: LabAccountAction.SSH_KEY,
                text: t(i18nKeys.challenges.challengeDetails.buttons.sshKey)!,
                disabled: !item.canSignIn || challenge === null || !challenge.props.sshKeyPairRequired,
              },
              {
                id: LabAccountAction.UNASSIGN_ACCOUNT,
                text: t(i18nKeys.challenges.challengeDetails.buttons.unassignAccount)!,
                disabled: item.team === null || item.status !== LabStatus.ASSIGNED,
              },
              {
                id: LabAccountAction.TERMINATE_LAB,
                text: t(i18nKeys.challenges.challengeDetails.buttons.terminateLab)!,
                disabled: !user?.isEventAdmin || !isLabUsable(item),
              },
              {
                id: LabAccountAction.STATUS_HISTORY,
                text: t(i18nKeys.challenges.challengeDetails.buttons.statusHistory)!,
                disabled: item.statusHistory.length < 1,
              },
              {
                id: LabAccountAction.RESOURCE_DEPLOYMENT_HISTORY,
                text: t(i18nKeys.challenges.challengeDetails.buttons.resourceDeploymentHistory)!,
                disabled: challenge === null || !challenge.hasCustomResources,
              },
              {
                id: LabAccountAction.CLOUDFORMATION_DETAILS,
                text: t(i18nKeys.challenges.challengeDetails.buttons.cloudformationStackDetails)!,
                disabled:
                  item.error === null &&
                  (!item.canSignIn || (item.labProvider !== null && item.labProvider !== LabProvider.AWS_LABS)),
              },
              {
                id: LabAccountAction.VIEW_METADATA,
                text: t(i18nKeys.challenges.challengeDetails.buttons.viewMetadata)!,
                disabled: !user?.isEventAdmin,
              },
            ]}
            onItemClick={(details) => handleActionClick(details.detail.id, item)}
            ariaLabel="More lab options"
            variant="icon"
            expandToViewport
          />
        );
      },
      minWidth: 50,
      allowLineWrap: true,
    },
  ];
};
