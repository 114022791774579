import * as React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { Home } from './Home';
import { OAuthRedirect } from './OAuthRedirect';
import { Login } from './Login/Login';
import { RoutePath } from '../../RoutePath';

/* Routes available to an unauthenticated user. */
const UnauthenticatedRoutes: React.FC = () => {
  const location = useLocation();

  return (
    <Switch>
      <Route exact path={RoutePath.ROOT} component={Home} />
      <Route exact path={RoutePath.SIGNOUT} component={Home} />
      <Route exact path={RoutePath.OAUTH2} component={OAuthRedirect} />

      {/* Any other route will redirect the user to sign in. */}
      <Route path="*">
        <Login redirectTo={location} />
      </Route>
    </Switch>
  );
};

export default UnauthenticatedRoutes;
