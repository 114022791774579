import { TinyEvent } from './Event';
import { EventType } from '@amzn/aws-jam-constants';
import { Dictionary, keyBy } from 'lodash';
import * as common from './common';
import { fromPlainObject } from '../utils/mapper.utils';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';

@jsonObject
export class PoolEvent extends TinyEvent {
  @jsonMember(Boolean)
  test = false;
  @jsonMember(common.NullableStringValue)
  clonedFrom: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  type: common.NullableString = null;
  @jsonMember(Number)
  minExpectedTeams = 0;
  @jsonMember(Number)
  maxExpectedTeams = 0;

  get live(): boolean {
    return !this.test;
  }

  get testClone(): boolean {
    return !!this.clonedFrom;
  }

  get oneClickEvent(): boolean {
    return this.type === EventType.ONE_CLICK_TEST_EVENT;
  }
}

@jsonObject
export class PoolChallenge {
  @jsonMember(common.NullableStringValue)
  eventName: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  challengeId: common.NullableString = null;

  // ui only, not included in api response
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  @jsonMember(common.NullableClassValue(PoolEvent))
  event: common.Nullable<PoolEvent> = null;

  setEvent(eventsById: Dictionary<PoolEvent>) {
    if (this.eventName) {
      this.event = eventsById[this.eventName] || null;
    }
  }

  get minExpectedTeams(): number {
    return this.event?.minExpectedTeams || 0;
  }

  get maxExpectedTeams(): number {
    return this.event?.maxExpectedTeams || 0;
  }
}

@jsonObject
export class PoolChallengeCounts {
  @jsonMember(Number)
  live = 0;
  @jsonMember(Number)
  test = 0;
  @jsonMember(Number)
  testClone = 0;
  @jsonMember(Number)
  oneClick = 0;

  static of(poolChallenges: PoolChallenge[]): PoolChallengeCounts {
    const counts: PoolChallengeCounts = new PoolChallengeCounts();

    poolChallenges.forEach((poolChallenge) => {
      if (poolChallenge.event?.live) {
        counts.live += 1;
      } else if (poolChallenge.event?.testClone) {
        counts.testClone += 1;
      } else if (poolChallenge.event?.oneClickEvent) {
        counts.oneClick += 1;
      } else if (poolChallenge.event?.test) {
        counts.test += 1;
      }
    });

    return counts;
  }
}

@jsonObject
export class Pool {
  @jsonMember(common.NullableStringValue)
  id: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  name: common.NullableString = null;
  @jsonMember(Number)
  maxPoolSize = 0;
  @jsonMember(Number)
  numAvailable = 0;
  @jsonMember(Number)
  numCreating = 0;
  @jsonMember(Number)
  numFailed = 0;
  @jsonMember(Boolean)
  test = false;
  @jsonMember(Number)
  numReaped = 0;
  @jsonMember(Number)
  numPotential = 0;
  @jsonArrayMember(PoolChallenge)
  currentChallenges: PoolChallenge[] = [];
  @jsonArrayMember(PoolChallenge)
  reservedChallenges: PoolChallenge[] = [];

  // ui only, not included in api response
  @jsonMember(Number)
  numCurrentChallenges = 0;
  @jsonMember(PoolChallengeCounts)
  currentChallengeCounts: PoolChallengeCounts = new PoolChallengeCounts();
  @jsonMember(Number)
  numReservedChallenges = 0;
  @jsonMember(PoolChallengeCounts)
  reservedChallengeCounts: PoolChallengeCounts = new PoolChallengeCounts();
  @jsonMember(Number)
  total = 0;

  static fromPlainObject(obj: any): Pool {
    const pool: Pool = fromPlainObject(obj, Pool) as Pool;
    pool.currentChallenges = pool.currentChallenges.map(
      (poolChallenge) => fromPlainObject(poolChallenge, PoolChallenge) as PoolChallenge
    );
    pool.reservedChallenges = pool.reservedChallenges.map(
      (poolChallenge) => fromPlainObject(poolChallenge, PoolChallenge) as PoolChallenge
    );
    pool.numPotential = Math.max(0, pool.numPotential);

    // populated ui-only attributes
    pool.numCurrentChallenges = pool.currentChallenges.length;
    pool.numReservedChallenges = pool.reservedChallenges.length;
    pool.total = pool.numAvailable + pool.numPotential;

    return pool;
  }
}

@jsonObject
export class GetPoolsResponse {
  @jsonArrayMember(Pool)
  pools: Pool[] = [];
  @jsonArrayMember(PoolEvent)
  events: PoolEvent[] = [];

  static fromPlainObject(obj: any): GetPoolsResponse {
    const res: GetPoolsResponse = fromPlainObject(obj, GetPoolsResponse) as GetPoolsResponse;
    res.pools = res.pools.map((pool) => Pool.fromPlainObject(pool));
    res.events = res.events.map((event) => fromPlainObject(event, PoolEvent) as PoolEvent);

    const eventsById: Dictionary<PoolEvent> = keyBy(res.events, 'name');

    // populate the event attribute on each pool challenge
    res.pools.forEach((pool) => {
      [...pool.currentChallenges, ...pool.reservedChallenges].forEach((poolChallenge) =>
        poolChallenge.setEvent(eventsById)
      );

      pool.currentChallengeCounts = PoolChallengeCounts.of(pool.currentChallenges);
      pool.reservedChallengeCounts = PoolChallengeCounts.of(pool.reservedChallenges);
    });

    return res;
  }
}
