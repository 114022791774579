import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import * as common from './common';

@jsonObject
export class ChallengeSetRequest {
  @jsonMember(common.NullableStringValue)
  title: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  description: common.NullableString = null;
  @jsonArrayMember(String)
  challengeIds: string[] = [];
  @jsonMember(Boolean)
  featured = false;
}

@jsonObject
export class ChallengeSet extends ChallengeSetRequest {
  @jsonMember(common.NullableStringValue)
  id: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  owner: common.NullableString = null;
  @jsonMember(common.NullableNumberValue)
  createDate: common.NullableNumber = null;
  @jsonMember(common.NullableNumberValue)
  updatedDate: common.NullableNumber = null;

  hasChallenge(challengeId: string): boolean {
    return this.challengeIds.includes(challengeId);
  }

  get numChallenges() {
    return this.challengeIds.length;
  }
}

@jsonObject
export class SimpleChallengeSetChallenge {
  @jsonMember(common.NullableStringValue)
  challengeId: common.NullableString = null;
  @jsonMember(common.NullableStringValue)
  challengeTitle: common.NullableString = null;
  @jsonMember(Boolean)
  isDefective = false;
  @jsonMember(Boolean)
  isPrivate = false;
}
