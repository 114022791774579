/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Box,
  Button,
  ExpandableSection,
  FormField,
  Grid,
  Header,
  Icon,
  Input,
  Link,
  Multiselect,
  RadioGroup,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { Textarea } from '@amzn/awsui-components-react/uxdg';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useChallenges } from '../../../../store/challenge.context';
import {
  BACKGROUND_MAX_LENGTH,
  BACKGROUND_MIN_LENGTH,
  ChallengePropAction,
  ChallengeTaskFields,
  GETTING_STARTED_MAX_LENGTH,
  GETTING_STARTED_MIN_LENGTH,
  INVENTORY_MAX_LENGTH,
  INVENTORY_MIN_LENGTH,
  TASK_VALIDATION_DESCRIPTION_MAX_LENGTH,
  TASK_VALIDATION_DESCRIPTION_MIN_LENGTH,
  useCreateChallenge,
} from '../../../../store/create-challenge.context';
import { ChallengeTask, ChallengeTaskValidationType } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { preProdLogger } from '../../../../utils/log.utils';
import { safeString } from '../../../../utils/string.utils';
import { Column } from '../../../common/Column';
import { Columns } from '../../../common/Columns';
import { ConfirmModal } from '../../../common/ConfirmModal';
import { HorizontalRule } from '../../../common/HorizontalRule';
import { KeyValue } from '../../../common/KeyValue';
import { AWSServicesDefinitions } from '../../challengesCommon/ChallengeOptionDefinitions';
import JamCodeEditor from '../../challengesCommon/JamCodeEditor';
import ChallengeCluesDetail from './Clues';

interface ChallengeTaskDetailProps {
  task: ChallengeTask;
  getTaskLabel: (taskId: string) => string;
  removeTask: (challengeId: string, challengeVersion: number, taskId: string) => void;
}

const ChallengeTaskDetail: React.FC<ChallengeTaskDetailProps> = ({ task, getTaskLabel, removeTask }) => {
  const { t } = useTranslation();
  const { editMode, editedChallenge, handleUpdateChallengeTask, challengeTaskValidator } = useCreateChallenge();
  const { challengeConfig, getChallengeConfiguration } = useChallenges();
  const hiddenFileInput = React.createRef<HTMLInputElement>();

  const [prerequisite, setPrerequisite] = useState('');
  const [challengeTask, setChallengeTask] = useState<ChallengeTask | undefined>(task);
  const [supportedRuntimeOptions, setSupportedRuntimeOptions] = useState<OptionDefinition[]>([]);

  const [showSampleFunction, setShowSampleFunction] = useState(false);
  const [visibleConfirmSample, setVisibleConfirmSample] = useState(false);

  const field = i18nKeys.challenges.subSections.tasks;
  const [weightedScoreErrorText, setWeightedScoreErrorText] = useState<string>('');
  const [titleErrorText, setTitleErrorText] = useState<string>('');
  const [taskDescriptionErrorText, setTaskDescriptionErrorText] = useState<string>('');
  const [backgroundErrorText, setBackgroundErrorText] = useState<string>('');
  const [gettingStartedErrorText, setGettingStartedErrorText] = useState<string>('');
  const [inventoryErrorText, setInventoryErrorText] = useState<string>('');
  const [servicesUsedErrorText, setServicesUsedErrorText] = useState<string>('');
  const [taskValidationDescriptionErrorText, setTaskValidationDescriptionErrorText] = useState<string>('');
  const [globalStaticAnswerErrorText, setGlobalStaticAnswerErrorText] = useState<string>('');
  const [taskValidationFunctionErrorText, setTaskValidationFunctionErrorText] = useState<string>('');

  useEffect(() => {
    // reset error fields when editMode is changed
    if (!editMode) {
      setWeightedScoreErrorText('');
      setTitleErrorText('');
      setTaskDescriptionErrorText('');
      setBackgroundErrorText('');
      setGettingStartedErrorText('');
      setInventoryErrorText('');
      setServicesUsedErrorText('');
      setTaskValidationDescriptionErrorText('');
      setGlobalStaticAnswerErrorText('');
      setTaskValidationFunctionErrorText('');
    }
  }, [editMode]);

  const taskValidator = challengeTaskValidator(
    task,
    new Map<ChallengeTaskFields, (error: string) => void>([
      [ChallengeTaskFields.WEIGHTED_SCORE, (error: string) => setWeightedScoreErrorText(error)],
      [ChallengeTaskFields.TASK_TITLE, (error: string) => setTitleErrorText(error)],
      [ChallengeTaskFields.TASK_DESCRIPTION, (error: string) => setTaskDescriptionErrorText(error)],
      [ChallengeTaskFields.BACKGROUND, (error: string) => setBackgroundErrorText(error)],
      [ChallengeTaskFields.GETTING_STARTED, (error: string) => setGettingStartedErrorText(error)],
      [ChallengeTaskFields.INVENTORY, (error: string) => setInventoryErrorText(error)],
      [ChallengeTaskFields.AWS_SERVICES_USED, (error: string) => setServicesUsedErrorText(error)],
      [
        ChallengeTaskFields.TASK_VALIDATION_DESCRIPTION,
        (error: string) => setTaskValidationDescriptionErrorText(error),
      ],
      [ChallengeTaskFields.GLOBAL_STATIC_ANSWER, (error: string) => setGlobalStaticAnswerErrorText(error)],
      [ChallengeTaskFields.VALIDATION_FUNCTION, (error: string) => setTaskValidationFunctionErrorText(error)],
    ])
  );

  useEffect(() => {
    if (!editMode && challengeTask) {
      challengeTask.decodeForMarkdown();
    }
  }, [editMode]);

  useEffect(() => {
    if (editedChallenge) {
      setChallengeTask(editedChallenge.props.tasks.find((ct) => ct.id === task.id));
      getChallengeConfiguration();
    }
  }, [editedChallenge]);

  useEffect(() => {
    const runtimes: OptionDefinition[] = [];
    if (challengeConfig) {
      challengeConfig.supportedLambdaRuntimes.forEach((runtime) => runtimes.push({ label: runtime, value: runtime }));
    }
    setSupportedRuntimeOptions(runtimes);
  }, [challengeConfig]);

  const handleFileUpload = (uploadedFile: File) => {
    // Handle file upload
    uploadedFile
      .text()
      .then((file) => {
        handleUpdateChallengeTask(ChallengePropAction.TASK_VALIDATION_LAMDA, task.taskNumber, file);
      })
      .catch((err) => preProdLogger(err));
  };

  const getCodeLanguage = () => {
    const currentRunTime = challengeTask?.validationFunctionRuntime;
    if (currentRunTime) {
      if (currentRunTime.includes('nodejs')) {
        return 'javascript';
      }
      if (currentRunTime.includes('python')) {
        return 'python';
      }
    }
    // default to javascript
    return 'javascript';
  };

  const getRuntimeFileType = () => {
    const currentRunTime = challengeTask?.validationFunctionRuntime;
    if (currentRunTime) {
      if (currentRunTime.includes('nodejs')) {
        return 'js';
      }
      if (currentRunTime.includes('python')) {
        return 'py';
      }
    }
    // default to javascript
    return 'js';
  };

  const title = task.title ? task.title : t(i18nKeys.challenges.challengeDetails.text.challengeTaskEmptyTitle);

  const taskValidationFunctionFileName = challengeTask?.validationFunction
    ? `${editedChallenge?.challengeId}-task-${challengeTask.taskNumber}-validation-function.${getRuntimeFileType()}`
    : '';

  const getValidationType = () => {
    switch (task.validationType) {
      case ChallengeTaskValidationType.GLOBAL_STATIC_ANSWER:
        return t(
          i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types.globalStaticAnswer.label
        );
      case ChallengeTaskValidationType.CFN_OUTPUT_PARAMETER:
        return t(i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types.dynamicAnswer.label);
      case ChallengeTaskValidationType.LAMBDA_FUNCTION:
        return t(i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types.lambdaFunction.label);
      case ChallengeTaskValidationType.LAMBDA_FUNCTION_WITH_INPUT:
        return t(
          i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types.lambdaFunctionInput.label
        );
    }
  };

  const addPrerequisite = () => {
    handleUpdateChallengeTask(ChallengePropAction.TASK_PREREQUISITES, challengeTask?.taskNumber || 0, prerequisite);
  };

  const [expanded, setExpanded] = useState(true);

  return (
    <div style={{ marginBottom: '1.5rem' }}>
      <ExpandableSection
        expanded={expanded}
        onChange={() => setExpanded((prevState) => !prevState)}
        variant="container"
        header={
          <Header
            variant="h2"
            actions={
              editMode && task.taskNumber > 1 ? (
                <div
                  onClick={() =>
                    removeTask(editedChallenge?.challengeId || '', editedChallenge?.version || 0, task.id || '')
                  }>
                  <Icon name="close" />
                </div>
              ) : null
            }>
            {t(i18nKeys.challenges.challengeDetails.headings.task, { count: task.taskNumber })}: {title}
          </Header>
        }>
        <SpaceBetween size={'s'} direction="vertical">
          <Columns columns={3} variant="default">
            <Column size={'s'}>
              <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.weightedScore)}>
                {!editMode ? (
                  `${task.scorePercent}%`
                ) : (
                  <FormField
                    constraintText={t(field.subSections.fields.weightedScore.constraint)}
                    errorText={weightedScoreErrorText}>
                    <Input
                      value={challengeTask?.scorePercent.toString() || ''}
                      onBlur={() => {
                        taskValidator.isValidField(ChallengeTaskFields.WEIGHTED_SCORE);
                      }}
                      onChange={({ detail }) =>
                        handleUpdateChallengeTask(
                          ChallengePropAction.TASK_WEIGHTED_SCORE,
                          challengeTask?.taskNumber || 0,
                          detail.value
                        )
                      }
                      placeholder={t(
                        i18nKeys.challenges.subSections.tasks.subSections.fields.weightedScore.placeholder
                      )}
                    />
                  </FormField>
                )}
              </KeyValue>
            </Column>
            <Column size={'s'}>
              <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.validationType)}>
                {!editMode ? (
                  getValidationType()
                ) : (
                  <RadioGroup
                    items={[
                      {
                        value: ChallengeTaskValidationType.LAMBDA_FUNCTION,
                        label: t(
                          i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types.lambdaFunction
                            .label
                        ),
                        description: t(
                          i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types.lambdaFunction
                            .description
                        ),
                      },
                      {
                        value: ChallengeTaskValidationType.LAMBDA_FUNCTION_WITH_INPUT,
                        label: t(
                          i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types
                            .lambdaFunctionInput.label
                        ),
                        description: t(
                          i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types
                            .lambdaFunctionInput.description
                        ),
                      },
                      {
                        value: ChallengeTaskValidationType.CFN_OUTPUT_PARAMETER,
                        label: t(
                          i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types.dynamicAnswer
                            .label
                        ),
                        description: t(
                          i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types.dynamicAnswer
                            .description
                        ),
                      },
                      {
                        value: ChallengeTaskValidationType.GLOBAL_STATIC_ANSWER,
                        label: t(
                          i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types
                            .globalStaticAnswer.label
                        ),
                        description: t(
                          i18nKeys.challenges.subSections.tasks.subSections.fields.validationType.types
                            .globalStaticAnswer.description
                        ),
                      },
                    ]}
                    value={challengeTask?.validationType || ''}
                    onChange={({ detail }) =>
                      handleUpdateChallengeTask(
                        ChallengePropAction.TASK_VALIDATION_TYPE,
                        challengeTask?.taskNumber || 0,
                        detail.value
                      )
                    }
                  />
                )}
              </KeyValue>
            </Column>
            <Column size={'s'}>
              <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.prerequisites)}>
                {!editMode &&
                  task.dependsOnTaskIds.length > 0 &&
                  task.dependsOnTaskIds.map((id, i) => {
                    return <div key={i}>{getTaskLabel(id)}</div>;
                  })}
                {!editMode && task.dependsOnTaskIds.length === 0 && t(i18nKeys.general.none)}
                {editMode && (
                  <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.enterTaskId)}>
                    <React.Fragment>
                      <SpaceBetween size={'s'}>
                        <Input value={prerequisite} onChange={({ detail }) => setPrerequisite(detail.value)} />
                        <Button onClick={addPrerequisite}>
                          {t(i18nKeys.challenges.challengeDetails.buttons.addPrerequisite)}
                        </Button>
                      </SpaceBetween>
                    </React.Fragment>
                  </KeyValue>
                )}
              </KeyValue>
            </Column>
          </Columns>
          <ExpandableSection
            header={<Header variant="h3">{t(i18nKeys.challenges.challengeDetails.headings.content)}</Header>}>
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.title)}>
              {!editMode ? (
                title
              ) : (
                <FormField constraintText={t(field.subSections.fields.title.constraint)} errorText={titleErrorText}>
                  <Input
                    onBlur={() => {
                      taskValidator.isValidField(ChallengeTaskFields.TASK_TITLE);
                    }}
                    onChange={({ detail }) =>
                      handleUpdateChallengeTask(
                        ChallengePropAction.TASK_TITLE,
                        challengeTask?.taskNumber || 0,
                        detail.value
                      )
                    }
                    value={challengeTask?.title || ''}
                  />
                </FormField>
              )}
            </KeyValue>
            <HorizontalRule />
            <KeyValue
              className="primary-text"
              label={t(i18nKeys.challenges.subSections.tasks.subSections.fields.task.title)}>
              {!editMode ? (
                task.description
              ) : (
                <FormField
                  errorText={taskDescriptionErrorText}
                  constraintText={t(field.subSections.fields.task.constraint, {
                    TASK_VALIDATION_DESCRIPTION_MIN_LENGTH,
                    TASK_VALIDATION_DESCRIPTION_MAX_LENGTH,
                  })}>
                  <Input
                    onBlur={() => {
                      taskValidator.isValidField(ChallengeTaskFields.TASK_DESCRIPTION);
                    }}
                    onChange={({ detail }) =>
                      handleUpdateChallengeTask(
                        ChallengePropAction.TASK_DESCRIPTION,
                        challengeTask?.taskNumber || 0,
                        detail.value
                      )
                    }
                    value={challengeTask?.description || ''}
                  />
                </FormField>
              )}
            </KeyValue>
            <HorizontalRule />
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.background)}>
              {!editMode ? (
                <ReactMarkdown>{task.background || ''}</ReactMarkdown>
              ) : (
                <FormField
                  constraintText={t(field.subSections.fields.background.constraint, {
                    BACKGROUND_MIN_LENGTH,
                    BACKGROUND_MAX_LENGTH,
                  })}
                  errorText={backgroundErrorText}>
                  <Textarea
                    onBlur={() => {
                      taskValidator.isValidField(ChallengeTaskFields.BACKGROUND);
                    }}
                    onChange={({ detail }) =>
                      handleUpdateChallengeTask(
                        ChallengePropAction.TASK_BACKGROUND,
                        challengeTask?.taskNumber || 0,
                        detail.value
                      )
                    }
                    value={challengeTask?.background || ''}
                  />
                </FormField>
              )}
            </KeyValue>
            <HorizontalRule />
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.gettingStarted)}>
              {!editMode ? (
                <ReactMarkdown>{task.gettingStarted || ''}</ReactMarkdown>
              ) : (
                <FormField
                  constraintText={t(field.subSections.fields.gettingStarted.constraint, {
                    GETTING_STARTED_MIN_LENGTH,
                    GETTING_STARTED_MAX_LENGTH,
                  })}
                  errorText={gettingStartedErrorText}>
                  <Textarea
                    onBlur={() => {
                      taskValidator.isValidField(ChallengeTaskFields.GETTING_STARTED);
                    }}
                    onChange={({ detail }) =>
                      handleUpdateChallengeTask(
                        ChallengePropAction.TASK_GETTING_STARTED,
                        challengeTask?.taskNumber || 0,
                        detail.value
                      )
                    }
                    value={challengeTask?.gettingStarted || ''}
                  />
                </FormField>
              )}
            </KeyValue>
            <HorizontalRule />
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.inventory)}>
              {!editMode ? (
                <ReactMarkdown>{task.inventory || ''}</ReactMarkdown>
              ) : (
                <FormField
                  constraintText={t(field.subSections.fields.inventory.constraint, {
                    INVENTORY_MIN_LENGTH,
                    INVENTORY_MAX_LENGTH,
                  })}
                  errorText={inventoryErrorText}>
                  <Textarea
                    onBlur={() => {
                      taskValidator.isValidField(ChallengeTaskFields.INVENTORY);
                    }}
                    onChange={({ detail }) =>
                      handleUpdateChallengeTask(
                        ChallengePropAction.TASK_INVENTORY,
                        challengeTask?.taskNumber || 0,
                        detail.value
                      )
                    }
                    value={challengeTask?.inventory || ''}
                  />
                </FormField>
              )}
            </KeyValue>
            <HorizontalRule />
            <KeyValue
              className="primary-text"
              label={t(i18nKeys.challenges.challengeDetails.titles.servicesYouShouldUse)}>
              {!editMode &&
                challengeTask?.awsServicesUsed.map((serviceName, i) => (
                  <ul key={i} style={{ listStyleType: 'none' }}>
                    {serviceName}
                  </ul>
                ))}
              {!editMode && !challengeTask?.awsServicesUsed && t(i18nKeys.general.none)}
              {editMode && (
                <FormField
                  constraintText={t(field.subSections.fields.servicesUsed.constraint)}
                  errorText={servicesUsedErrorText}>
                  <Multiselect
                    onBlur={() => {
                      taskValidator.isValidField(ChallengeTaskFields.AWS_SERVICES_USED);
                    }}
                    selectedOptions={
                      challengeTask?.awsServicesUsed.map(
                        (serviceName) =>
                          AWSServicesDefinitions.find((service) => service.value === serviceName) as OptionDefinition
                      ) || []
                    }
                    onChange={({ detail }) => {
                      const services = detail.selectedOptions.map((option) => option.value);
                      handleUpdateChallengeTask(ChallengePropAction.TASK_SERVICES, task.taskNumber, services);
                    }}
                    options={AWSServicesDefinitions}
                    placeholder={t(i18nKeys.challenges.subSections.tasks.subSections.fields.servicesUsed.placeholder)}
                    selectedAriaLabel={t(i18nKeys.general.selected)}
                  />
                </FormField>
              )}
            </KeyValue>
            <HorizontalRule />
            <KeyValue className="primary-text" label={t(i18nKeys.challenges.challengeDetails.titles.taskValidation)}>
              {!editMode ? (
                <ReactMarkdown>{task.validationDescription || ''}</ReactMarkdown>
              ) : (
                <FormField
                  constraintText={t(field.subSections.fields.taskValidation.constraint, {
                    TASK_VALIDATION_DESCRIPTION_MIN_LENGTH,
                    TASK_VALIDATION_DESCRIPTION_MAX_LENGTH,
                  })}
                  errorText={taskValidationDescriptionErrorText}>
                  <Textarea
                    onBlur={() => {
                      taskValidator.isValidField(ChallengeTaskFields.TASK_VALIDATION_DESCRIPTION);
                    }}
                    onChange={({ detail }) =>
                      handleUpdateChallengeTask(
                        ChallengePropAction.TASK_VALIDATION_DESCRIPTION,
                        challengeTask?.taskNumber || 0,
                        detail.value
                      )
                    }
                    value={challengeTask?.validationDescription || ''}
                  />
                </FormField>
              )}
            </KeyValue>
          </ExpandableSection>
          <ChallengeCluesDetail taskId={task.id || ''} taskNumber={task.taskNumber} />
          {challengeTask && (
            <div className="dynamicValidationArea">
              {challengeTask.validationType !== ChallengeTaskValidationType.GLOBAL_STATIC_ANSWER && (
                <ExpandableSection
                  header={
                    <Header variant="h3">{t(i18nKeys.challenges.challengeDetails.headings.validationFunction)}</Header>
                  }>
                  {editMode && (
                    <SpaceBetween size={'s'}>
                      <Grid gridDefinition={[{ colspan: 4 }]}>
                        {challengeConfig && (
                          <Select
                            selectedOption={
                              supportedRuntimeOptions.find(
                                (runtime) => runtime.value === challengeTask.validationFunctionRuntime
                              ) || null
                            }
                            options={supportedRuntimeOptions}
                            onChange={({ detail }) =>
                              handleUpdateChallengeTask(
                                ChallengePropAction.TASK_VALIDATION_RUNTIME,
                                task.taskNumber,
                                detail.selectedOption.value
                              )
                            }
                          />
                        )}
                      </Grid>
                      <SpaceBetween size="s">
                        {challengeTask.validationFunction && (
                          <Box textAlign="center">
                            <Icon name="file" />
                            <Link href="#" variant="primary">
                              {taskValidationFunctionFileName}
                            </Link>
                          </Box>
                        )}
                        <Box className="gray-box" padding={'l'} textAlign="center">
                          <Button
                            variant="link"
                            iconName="upload"
                            formAction="none"
                            onClick={() => {
                              // @ts-ignore
                              hiddenFileInput.current.value = null; // allow for re-choosing the same file name
                              // @ts-ignore
                              hiddenFileInput.current.click();
                            }}>
                            {t(i18nKeys.challenges.challengeDetails.text.uploadFileOrDrop)}
                          </Button>
                          <input
                            ref={hiddenFileInput}
                            id="chooseFileInput"
                            type="file"
                            hidden
                            multiple={false}
                            accept="application/js"
                            // @ts-ignore
                            onChange={(event) => handleFileUpload(event.target.files[0])}
                          />
                        </Box>
                      </SpaceBetween>
                      <SpaceBetween size="m" direction="horizontal">
                        {showSampleFunction ? (
                          <Button onClick={() => setShowSampleFunction(false)}>
                            {t(i18nKeys.challenges.challengeDetails.buttons.hideSampleFunction)}
                          </Button>
                        ) : (
                          <Button onClick={() => setShowSampleFunction(true)}>
                            {t(i18nKeys.challenges.challengeDetails.buttons.showSampleFunction)}
                          </Button>
                        )}
                        <Button onClick={() => setVisibleConfirmSample(true)}>
                          {t(i18nKeys.challenges.challengeDetails.buttons.useSampleFunction)}
                        </Button>
                      </SpaceBetween>
                      {showSampleFunction && challengeConfig && (
                        <JamCodeEditor
                          language={getCodeLanguage()}
                          value={
                            getCodeLanguage() === 'python'
                              ? safeString(challengeConfig.pythonSampleLambdaCode)
                              : safeString(challengeConfig.nodeSampleLambdaCode)
                          }
                          setValue={() => preProdLogger('none')}
                        />
                      )}
                      <FormField errorText={taskValidationFunctionErrorText}>
                        <JamCodeEditor
                          language={getCodeLanguage()}
                          value={challengeTask.validationFunction || ''}
                          setValue={(e) => {
                            handleUpdateChallengeTask(ChallengePropAction.TASK_VALIDATION_LAMDA, task.taskNumber, e);
                          }}
                        />
                      </FormField>
                    </SpaceBetween>
                  )}
                  {!editMode && (
                    <div style={{ pointerEvents: 'none', opacity: '0.4' }}>
                      <JamCodeEditor
                        language="yaml"
                        value={challengeTask.validationFunction || ''}
                        setValue={(e) => {
                          handleUpdateChallengeTask(ChallengePropAction.TASK_VALIDATION_LAMDA, task.taskNumber, e);
                        }}
                      />
                    </div>
                  )}
                </ExpandableSection>
              )}
              {challengeTask.validationType === ChallengeTaskValidationType.GLOBAL_STATIC_ANSWER && (
                <ExpandableSection
                  header={
                    <Header variant="h3">{t(i18nKeys.challenges.challengeDetails.headings.globalStaticAnswer)}</Header>
                  }>
                  {!editMode ? (
                    challengeTask.globalStaticAnswer
                  ) : (
                    <FormField errorText={globalStaticAnswerErrorText}>
                      <Textarea
                        onBlur={() => {
                          taskValidator.isValidField(ChallengeTaskFields.GLOBAL_STATIC_ANSWER);
                        }}
                        value={challengeTask.globalStaticAnswer || ''}
                        onChange={({ detail }) => {
                          handleUpdateChallengeTask(
                            ChallengePropAction.TASK_GLOBAL_STATIC_ANSWER,
                            task.taskNumber,
                            detail.value
                          );
                        }}
                      />
                    </FormField>
                  )}
                </ExpandableSection>
              )}
            </div>
          )}
        </SpaceBetween>
      </ExpandableSection>
      {challengeTask && challengeConfig && (
        <ConfirmModal
          visible={visibleConfirmSample}
          message={t(i18nKeys.challenges.challengeDetails.messages.useSampleFunction)}
          confirmBtnLabel={t(i18nKeys.challenges.challengeDetails.yesLabels.continue)}
          onConfirm={() => {
            if (getCodeLanguage() === 'javascript') {
              challengeTask.validationFunction = challengeConfig.nodeSampleLambdaCode;
            } else {
              challengeTask.validationFunction = challengeConfig.pythonSampleLambdaCode;
            }
          }}
          onCancel={() => setVisibleConfirmSample(false)}
        />
      )}
    </div>
  );
};

export default ChallengeTaskDetail;
