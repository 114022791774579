import { Grid, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Event } from '../../../types/Event';
import { i18nKeys } from '../../../utils/i18n.utils';
import ChimeNotifications from '../../common/CampaignEventComponents/ChimeNotifications';
import EventCapacityRedirectSettings from './AdvancedSettings/EventCapacityRedirectSettings';
import Gamified from '../../common/CampaignEventComponents/Gamified';
import ParticipantRegistration from './AdvancedSettings/ParticipantRegistration';
import JamUISettings from './AdvancedSettings/JamUISettings';
import Scoring from '../../common/CampaignEventComponents/Scoring';
import Sponsorship from './AdvancedSettings/Sponsorship';
import Prizes from './AdvancedSettings/Prizes';
import EventDetailsSubType from './AdvancedSettings/EventDetailsSubType';
import SkillBuilder from '../../common/CampaignEventComponents/SkillBuilder';
import SecretKeyDetails from './AdvancedSettings/SecretKeyDetails';
import PublicEventSettings from './AdvancedSettings/PublicEventSettings';
import PreEventLobbyVideo from './AdvancedSettings/PreEventLobbyVideo';
import AccessControl from '../../common/CampaignEventComponents/AccessControl';
import LabAvailabilty from '../../common/CampaignEventComponents/LabAvailability';

interface EventDetailsAdvancedSettingsProps {
  event: Event;
}

const EventDetailsAdvancedSettings: React.FC<EventDetailsAdvancedSettingsProps> = ({ event }) => {
  const { t } = useTranslation();

  return (
    <div style={{ marginTop: '12px' }}>
      <Grid gridDefinition={[{ colspan: 8 }]}>
        <Header variant="h2" description={t(i18nKeys.events.eventDetails.headers.advancedSettings.description)}>
          {t(i18nKeys.events.eventDetails.headers.advancedSettings.header)}
        </Header>
      </Grid>
      <div style={{ borderTop: '2px solid #eaeded', marginTop: '12px', marginBottom: '24px' }} />
      <SpaceBetween direction="vertical" size="s">
        <Scoring target={event} />
        <ChimeNotifications target={event} />
        <EventDetailsSubType event={event} />
        <Gamified target={event} />
        <PublicEventSettings event={event} />
        <SecretKeyDetails event={event} />
        <ParticipantRegistration event={event} />
        <AccessControl target={event} />
        <LabAvailabilty target={event} />
        <SkillBuilder target={event} />
        <PreEventLobbyVideo event={event} />
        <JamUISettings event={event} />
        <Prizes event={event} />
        <Sponsorship event={event} />
        <EventCapacityRedirectSettings event={event} />
      </SpaceBetween>
    </div>
  );
};

export default EventDetailsAdvancedSettings;
