/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Box,
  Button,
  Container,
  ExpandableSection,
  Flashbar,
  Header,
  Icon,
  Link,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChallenges } from '../../../../store/challenge.context';
import { ChallengePropAction, useCreateChallenge } from '../../../../store/create-challenge.context';
import { useFlashbars } from '../../../../store/flashbar.context';
import { Challenge, ChallengeReviewableSection, IamPolicyValidationResponse } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { preProdLogger } from '../../../../utils/log.utils';
import JamCodeEditor from '../../challengesCommon/JamCodeEditor';
import { ChallengeReviewPopover } from '../../challengesCommon/ChallengeReviewPopover';

interface ChallengeIAMPolicyDetailProps {
  challenge: Challenge;
}

const ChallengeIAMPolicyDetail: React.FC<ChallengeIAMPolicyDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { validateIamPolicy } = useChallenges();
  const { addErrorFlashbar } = useFlashbars();
  const { editMode, editedChallenge, handleUpdateChallengeProp } = useCreateChallenge();
  const [validationResponse, setValidationResponse] = useState<IamPolicyValidationResponse | undefined>();
  const hiddenFileInput = React.createRef<HTMLInputElement>();

  const [items, setItems] = useState([
    {
      dismissible: true,
      dismissLabel: t(i18nKeys.challenges.subSections.iamPolicy.dismissLabel),
      onDismiss: () => setItems([]),
      content: <>{t(i18nKeys.challenges.subSections.iamPolicy.infoFlashbar)}</>,
    },
  ]);

  const iamPolicyFileName = `aws-jam-${challenge.challengeId}-team-iam-policy`;

  const handleFileUpload = (uploadedFile: File) => {
    // Handle file upload
    uploadedFile
      .text()
      .then((file) => {
        handleUpdateChallengeProp(ChallengePropAction.STUDENT_POLICY, file);
      })
      .catch((err) => preProdLogger(err));
  };

  const handleUpdateIamPolicy = (policy: string) => {
    if (editMode) {
      handleUpdateChallengeProp(ChallengePropAction.STUDENT_POLICY, policy);
    }
  };

  const onValidateIamPolicy = () => {
    if (editedChallenge) {
      if (!editedChallenge.props.studentPolicy || editedChallenge.props.studentPolicy.trim().length < 1) {
        addErrorFlashbar(t(i18nKeys.challenges.challengeDetails.messages.emptyPolicy));
      } else {
        validateIamPolicy(editedChallenge.props.studentPolicy)
          .then((response) => setValidationResponse(response))
          .catch((err) => preProdLogger(err));
      }
    }
  };

  const getValidationList = () => {
    if (validationResponse) {
      if (validationResponse?.findings?.length > 0) {
        return (
          <ul>
            {validationResponse.findings.map((result, i) => (
              <li key={i}>
                <b>{result.issueCode}:</b> {result.finding}{' '}
                <a href={result.learnMoreLink}> {t(i18nKeys.general.learnMore)} </a>{' '}
              </li>
            ))}
          </ul>
        );
      } else {
        return <div>{t(i18nKeys.challenges.challengeDetails.messages.emptyPolicy)}</div>;
      }
    }
  };

  return (
    <Container
      header={
        <Header variant="h2" actions={<ChallengeReviewPopover section={ChallengeReviewableSection.IAM_POLICY} />}>
          {t(i18nKeys.challenges.challengeDetails.headings.teamIAMPolicy)}
        </Header>
      }>
      <SpaceBetween size="s">
        {editMode && <Flashbar items={items} />}
        {challenge.hasIamPolicy && !editMode && (
          <TextContent>
            <h4>{t(i18nKeys.challenges.challengeDetails.titles.file)}</h4>
          </TextContent>
        )}
        {editMode && <Button>{t(i18nKeys.challenges.challengeDetails.buttons.showIamSnippets)}</Button>}
        {editMode && (
          <Box className="gray-box" padding={'l'} textAlign="center">
            <SpaceBetween size="s">
              {challenge.hasIamPolicy && (
                <div>
                  <Icon name="file" />
                  <Link href="#" variant="primary">
                    {iamPolicyFileName}
                  </Link>
                </div>
              )}
              <p>{t(i18nKeys.challenges.subSections.iamPolicy.dropJsonFiles)}</p>
              <input
                ref={hiddenFileInput}
                id="chooseFileInput"
                type="file"
                hidden
                multiple={false}
                accept="application/json"
                // @ts-ignore
                onChange={(event) => handleFileUpload(event.target.files[0])}
              />
              <Button
                variant="link"
                iconName="upload"
                formAction="none"
                onClick={() => {
                  // @ts-ignore
                  hiddenFileInput.current.value = null; // allow for re-choosing the same file name
                  // @ts-ignore
                  hiddenFileInput.current.click();
                }}>
                {t(i18nKeys.challenges.subSections.iamPolicy.browseFiles)}
              </Button>
              {editedChallenge?.hasIamPolicy && (
                <Button
                  iconName="status-negative"
                  formAction="none"
                  onClick={() => {
                    handleUpdateChallengeProp(ChallengePropAction.STUDENT_POLICY, null);
                  }}>
                  {t(i18nKeys.general.remove)}
                </Button>
              )}
            </SpaceBetween>
          </Box>
        )}
        {challenge.hasIamPolicy && !editMode && (
          <div>
            <Icon name="file" />
            <Link href="#" variant="primary">
              {iamPolicyFileName}
            </Link>
          </div>
        )}
        {editMode && (
          <Button iconName="status-positive" onClick={onValidateIamPolicy}>
            {t(i18nKeys.challenges.subSections.iamPolicy.validatePolicy)}
          </Button>
        )}
        {editMode && validationResponse && (
          <ExpandableSection
            header={<Header variant="h3">{t(i18nKeys.challenges.challengeDetails.titles.iamSecurityFindings)}</Header>}>
            {getValidationList()}
          </ExpandableSection>
        )}
        {editMode && (
          <JamCodeEditor
            language="json"
            value={editedChallenge?.props.studentPolicy || ''}
            setValue={(e) => {
              handleUpdateIamPolicy(e);
            }}
          />
        )}
        {!editMode && (
          <div style={{ pointerEvents: 'none', opacity: '0.4' }}>
            <JamCodeEditor
              language="json"
              value={challenge.props.studentPolicy || ''}
              setValue={() => {
                preProdLogger('no input allowed');
              }}
            />
          </div>
        )}
      </SpaceBetween>
    </Container>
  );
};

export default ChallengeIAMPolicyDetail;
