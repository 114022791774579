import { Stack, StackEvent } from './cloud-formation';
import { Credentials } from 'aws-sdk';
import * as common from './common';
import { ResourceDeploymentItem } from './ResourceDeployment';
import { Dictionary } from 'lodash';
import * as _ from 'lodash';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { AwsRegions } from '@amzn/aws-jam-constants';

export const LAB_STATUS_SORT_ORDER: { [key in LabStatus]: number } = {
  NOT_READY: 0,
  PREPARING_RESOURCES: 1,
  ON_HOLD: 2, // pseudo-status
  UNASSIGNED: 3,
  ASSIGNED: 4,
  RESTARTED: 5,
  COMPLETED: 6,
  TIMED_OUT: 7,
  TERMINATED: 8,
  INVALID: 9,
  INELIGIBLE: 10,
};

export enum LabStatus {
  TERMINATED = 'TERMINATED',
  RESTARTED = 'RESTARTED',
  INVALID = 'INVALID',
  COMPLETED = 'COMPLETED',
  TIMED_OUT = 'TIMED_OUT',
  INELIGIBLE = 'INELIGIBLE',
  UNASSIGNED = 'UNASSIGNED',
  ASSIGNED = 'ASSIGNED',
  NOT_READY = 'NOT_READY',
  PREPARING_RESOURCES = 'PREPARING_RESOURCES',
  ON_HOLD = 'ON_HOLD',
}

export const LAB_FINAL_STATUSES: LabStatus[] = [
  LabStatus.TERMINATED,
  LabStatus.RESTARTED,
  LabStatus.INVALID,
  LabStatus.COMPLETED,
  LabStatus.TIMED_OUT,
  LabStatus.INELIGIBLE,
];

export const LAB_READY_STATUSES: LabStatus[] = [LabStatus.ASSIGNED, LabStatus.UNASSIGNED];

@jsonObject
export class LabTeam {
  @jsonMember(common.NullableStringValue)
  name: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  alias: common.NullableString = null;
}
@jsonObject
export class LabStatusHistoryItem {
  @jsonMember(common.NullableTimeStampValue)
  time: common.NullableTimeStamp = null;

  @jsonMember(common.NullableStringValue)
  status: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  details: common.NullableString = null;
}

@jsonObject
export class Lab {
  @jsonMember(common.NullableStringValue)
  id: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  sessionId: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  extStatus: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  status: common.Nullable<LabStatus> = null;

  @jsonMember(common.NullableStringValue)
  error: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  awsAccountNumber: common.NullableString = null;

  @jsonMember(LabTeam)
  team: common.Nullable<LabTeam> = null;

  @jsonArrayMember(LabStatusHistoryItem)
  statusHistory: LabStatusHistoryItem[] = [];

  @jsonMember(common.NullableStringValue)
  labProvider: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  challengeId: common.NullableString = null;

  @jsonMember(common.NullableTimeStampValue)
  holdUntil: common.NullableTimeStamp = null;

  @jsonMember(Boolean)
  master = false;

  @jsonMember(Boolean)
  canSignIn = false;

  @jsonMember(common.NullableStringValue)
  region: common.NullableString = null;

  @jsonMember(common.NullableTimeStampValue)
  expiration: common.NullableTimeStamp = null;

  // ui only, not from api
  @jsonMember(common.NullableStringValue)
  eventName: common.NullableString = null;

  @jsonMember(Object)
  mostRecentTaskByType: { [type: string]: ResourceDeploymentItem } = {};

  @jsonArrayMember(common.NullableStringValue)
  failedTaskErrors: common.NullableString[] = [];

  @jsonArrayMember(ResourceDeploymentItem)
  unresolvedTasks: ResourceDeploymentItem[] = [];

  @jsonArrayMember(ResourceDeploymentItem)
  resolvedTasks: ResourceDeploymentItem[] = [];

  @jsonArrayMember(ResourceDeploymentItem)
  completeTasks: ResourceDeploymentItem[] = [];

  /**
   * Checks Lab properties to see if lab is on hold
   *
   * @param lab Lab to check if on hold
   * @returns boolean depicting whether or not the lab is on hold
   */
  static isOnHold(lab: Lab): boolean {
    if (!lab.holdUntil) {
      return false;
    }
    return lab.holdUntil > Date.now();
  }

  /**
   * Checks Lab properties to see if lab is in final status
   *
   * @param status Status to check against list of final statuses
   * @returns boolean depicting if lab status is in final status
   */
  static isInFinalStatus(status: common.Nullable<LabStatus>) {
    if (status) {
      return LAB_FINAL_STATUSES.indexOf(status) > -1;
    } else {
      return false;
    }
  }

  /**
   * Checks Lab properties to see if lab is in ready status
   *
   * @param status Status to check against list of ready statuses
   * @returns boolean depicting if lab status is in ready status
   */
  static isReadyStatus(status: common.Nullable<LabStatus>) {
    if (status) {
      return LAB_READY_STATUSES.indexOf(status) > -1;
    } else {
      return false;
    }
  }

  /**
   * Checks Lab statusHistory to see if provided status is first instance in statusHistory
   *
   * @param status Status to check against statusHistory
   * @returns boolean depicting if provided status is first of its kind in statusHistory
   */
  getFirstOfStatus(status: string): LabStatusHistoryItem | undefined {
    return this.statusHistory.find((item) => item.status === status);
  }

  /**
   * Checks lab properties to determine if lab was ever in an assignable status
   */
  get wasEverAssignable(): boolean {
    return !this.onHold && this.wasEverInStatus(LabStatus.UNASSIGNED);
  }

  get regionDescription(): string {
    if (this.region) {
      const region = AwsRegions.ALL_AWS_REGIONS_BY_ID[this.region];
      return region ? `${region.name} - ${this.region}` : this.region;
    } else {
      return 'Unknown';
    }
  }

  /**
   * Checks lab properties to determine if lab was ever assigned
   */
  get wasEverAssigned(): boolean {
    return this.wasEverInStatus(LabStatus.ASSIGNED);
  }

  /**
   * Checks lab properties to determine if lab was ever restarted
   */
  get wasRestarted(): boolean {
    return this.wasEverInStatus(LabStatus.RESTARTED);
  }

  /**
   * Checks lab properties to determine if lab is on hold
   */
  get onHold(): boolean {
    return Lab.isOnHold(this);
  }

  /**
   * Checks lab properties to determine if lab is ready
   */
  get ready(): boolean {
    return Lab.isReadyStatus(this.status) && !this.onHold;
  }

  /**
   * Checks labs statusHistory to determine if it was ever in provided status
   *
   * @param status Status to check statusHistory for
   * @returns boolean depicting if lab was ever in provided status
   */
  wasEverInStatus(status: string): boolean {
    return this.getFirstOfStatus(status) != null;
  }

  /**
   * Checks lab properties to determine if lab is in final status
   */
  get isInFinalStatus(): boolean {
    return Lab.isInFinalStatus(this.status);
  }

  /**
   * Checks lab properties to determine deployedTime
   */
  get deployedTime(): number | null {
    if (this.holdUntil) {
      return this.holdUntil;
    }
    const deployedStatus = this.getFirstOfStatus(LabStatus.UNASSIGNED);
    return deployedStatus ? deployedStatus.time : null;
  }

  /**
   * Checks lab statusHistory to determine lab terminaton time
   */
  get terminatedTime(): number | null {
    const sortedStatusHistory = this.statusHistory
      .filter((item) => !!item.time)
      .sort((a, b) => {
        // Object is possibly null per typescript regardless of filter, adding ! to ensure the times are sorted as they are indeed there
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return a.time! - b.time!;
      });

    if (_.isEmpty(sortedStatusHistory)) {
      return null;
    }

    const finalStatus = this.isInFinalStatus
      ? sortedStatusHistory[sortedStatusHistory.length - 1].status === this.status
        ? sortedStatusHistory[sortedStatusHistory.length - 1]
        : null
      : null;
    return finalStatus ? finalStatus.time : null;
  }

  /**
   * Checks lab mostRecentTaskByType and returns boolean depicting presence of resourceHistory
   */
  get hasResourceHistory(): boolean {
    return Object.keys(this.mostRecentTaskByType).length > 0;
  }

  /**
   * Checks failedTaskErrors length and returns boolean depicting presence of failedTasks
   */
  get hasFailedResource(): boolean {
    return this.failedTaskErrors.length > 0;
  }

  /**
   * Returns the number of unresolvedTasks
   */
  get unresolvedTaskCount(): number {
    return this.unresolvedTasks.length;
  }

  /**
   * Returns the number of resolvedTasks
   */
  get resolvedTaskCount(): number {
    return this.resolvedTasks.length;
  }

  /**
   * Checks unresolved and resolved tasks length and returns number depicting sum of both
   */
  get totalTaskCount(): number {
    return this.unresolvedTaskCount + this.resolvedTaskCount;
  }

  /**
   * Returns whether this lab is expired or not
   */
  get expired(): boolean {
    if (this.expiration) {
      return this.expiration < Date.now();
    } else {
      return false;
    }
  }

  /**
   * Sorts and applies resource deployment task history
   *
   * @param items Resource deployment items to sort and apply
   */
  applyResourceTaskHistory(items: ResourceDeploymentItem[]): void {
    this.mostRecentTaskByType = {};
    this.unresolvedTasks = [];
    this.resolvedTasks = [];
    this.completeTasks = [];
    this.failedTaskErrors = [];

    if (items && items.length > 0) {
      const itemByTaskType: Dictionary<ResourceDeploymentItem[]> = _.groupBy<ResourceDeploymentItem>(
        items,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        (item: any) => item.type
      );

      Object.keys(itemByTaskType).forEach((taskType) => {
        const itemsOfType: ResourceDeploymentItem[] = itemByTaskType[taskType];
        itemsOfType.sort((a, b) => (b.timeCreated || 0) - (a.timeCreated || 0));
        this.mostRecentTaskByType[taskType] = itemsOfType[0];
      });

      this.unresolvedTasks = Object.values(this.mostRecentTaskByType).filter(
        (task: ResourceDeploymentItem) => task != null && !task.resolved
      );

      this.resolvedTasks = Object.values(this.mostRecentTaskByType).filter(
        (task: ResourceDeploymentItem) => task != null && task.resolved
      );

      this.completeTasks = Object.values(this.mostRecentTaskByType).filter(
        (task: ResourceDeploymentItem) => task != null && task.completed
      );

      this.failedTaskErrors = Object.values(this.mostRecentTaskByType)
        .filter((task: ResourceDeploymentItem) => task != null && task.failed)
        .map((task) => task.errorMessage);
    }
  }
}

export enum LabAccountResourceType {
  TASK_VALIDATION_FUNCTION = 'TASK_VALIDATION_FUNCTION',
  TASK_VALIDATION_FUNCTION_ROLE = 'TASK_VALIDATION_FUNCTION_ROLE',
  TASK_VALIDATION_FUNCTION_IAM_POLICY = 'TASK_VALIDATION_FUNCTION_IAM_POLICY',
}

@jsonObject
export class AccountCredentials {
  @jsonMember(common.NullableStringValue)
  accessKey: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  secretKey: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  sessionToken: common.NullableString = null;

  /**
   * Takes the AccountCredentials accessKey, secretKey, and sessionToken and creates an instance of Credentials
   *
   * @returns New instance of Credentials utilizing all keys and tokens
   */
  toAWSCredentials(): common.Nullable<Credentials> {
    if (this.accessKey && this.secretKey && this.sessionToken) {
      return new Credentials(this.accessKey, this.secretKey, this.sessionToken);
    } else {
      return null;
    }
  }
}

@jsonObject
export class LabSignInDetails {
  @jsonMember(common.NullableStringValue)
  url: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  region: common.NullableString = null;

  @jsonMember(common.NullableTimeStampValue)
  expiration: common.NullableTimeStamp = null;

  @jsonMember(AccountCredentials)
  credentials: AccountCredentials = new AccountCredentials();
}

@jsonObject
export class LabCloudFormationDetails {
  @jsonMember(common.NullableStringValue)
  awsAccountNumber: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  labId: common.NullableString = null;

  @jsonArrayMember(Object)
  stacks: Stack[] = [];

  @jsonArrayMember(Object)
  stackEvents: StackEvent[] = [];
}

@jsonObject
export class LabProviderAuditRecord {
  @jsonMember(common.NullableStringValue)
  id: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  eventName: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  challengeId: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  eventNameChallengeId: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  action: common.NullableString = null;

  @jsonMember(common.NullableTimeStampValue)
  time: common.NullableTimeStamp = null;

  @jsonMember(common.NullableTimeStampValue)
  lastUpdated: common.NullableTimeStamp = null;

  @jsonMember(common.NullableStringValue)
  debugMessage: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  errorMessage: common.NullableString = null;

  @jsonArrayMember(Object)
  metadata: { key: string; value: string }[] = [];

  @jsonMember(common.NullableStringValue)
  requestBody: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  requestHost: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  requestMethod: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  requestPath: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  responseBody: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  responseCode: common.NullableString = null;

  @jsonMember(Boolean)
  successful = false;

  @jsonMember(common.NullableTimeStampValue)
  duration: common.NullableTimeStamp = null;
}

export interface LabMetadata {
  [key: string]: any;
}

export interface LabStatusCounts {
  [status: string]: number;
}

@jsonObject
export class LabDashboardChartData {
  @jsonArrayMember(Number)
  teamCreationTimes: number[] = [];

  @jsonMember(Object)
  challengeStartTimes: common.ChallengeStartTimes = {};

  @jsonMember(Object)
  challengeCompletionTimes: common.ChallengeCompletionTimes = {};

  @jsonMember(Object)
  labAutoScalingDecisions: { [challengeId: string]: LabAutoScalingDecisionMin[] } = {};
}

@jsonObject
export class LabAutoScalingDecisionMin {
  @jsonMember(common.NullableTimeStampValue)
  time: common.NullableTimeStamp = null;

  @jsonMember(Number)
  count = 0;
}

@jsonObject
export class LabAutoScalingDecision extends LabAutoScalingDecisionMin {
  @jsonMember(Object)
  parameters: { [key: string]: string } = {};

  @jsonArrayMember(String)
  decisions: string[] = [];
}

@jsonObject
export class LabAssignmentQueueItem {
  @jsonMember(common.NullableStringValue)
  teamName: common.NullableString = null;

  @jsonMember(common.NullableTimeStampValue)
  timeEnteredQueue: common.NullableTimeStamp = null;

  @jsonMember(common.NullableTimeStampValue)
  estimatedReadyTime: common.NullableTimeStamp = null;
}

@jsonObject
export class LabStatusSnapshot {
  @jsonMember(common.NullableStringValue)
  eventName: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  challengeId: common.NullableString = null;

  @jsonArrayMember(LabAssignmentQueueItem)
  assignmentQueue: LabAssignmentQueueItem[] = [];

  /**
   * Errors for a lab account that didn't cause an automatic shutoff.
   */
  @jsonArrayMember(String)
  errors: string[] = [];

  /**
   * Old lab deployment errors that once applied to a lab account but are no longer applicable.
   */
  @jsonArrayMember(String)
  staleErrors: string[] = [];

  @jsonMember(Number)
  desiredLabCount = 0;

  @jsonMember(Number)
  adjustedDesiredLabCount = 0;

  @jsonMember(Number)
  totalAccounts = 0;

  @jsonMember(Number)
  notReady = 0;

  @jsonMember(Number)
  preparingResources = 0;

  @jsonMember(Number)
  unassigned = 0;

  @jsonMember(Number)
  onHold = 0;

  @jsonMember(Number)
  assigned = 0;

  @jsonMember(Number)
  completed = 0;

  @jsonMember(Number)
  timedOut = 0;

  @jsonMember(Number)
  restarted = 0;

  @jsonMember(Number)
  invalid = 0;

  @jsonMember(Number)
  ineligible = 0;

  @jsonMember(Number)
  terminated = 0;

  @jsonMember(Number)
  finalized = 0;

  @jsonMember(Number)
  usable = 0;

  /**
   * Returns sum of unassigned and assigned labs
   */
  get numReady(): number {
    return this.unassigned + this.assigned;
  }

  /**
   * Returns percentage of labs that are in a ready status
   */
  get readyPercent(): number {
    return this.adjustedDesiredLabCount > 0 ? Math.ceil((this.numReady / this.adjustedDesiredLabCount) * 100) : 100;
  }

  /**
   * Returns the number of labs in a finished status
   */
  get numFinishedTeams(): number {
    return this.desiredLabCount - this.adjustedDesiredLabCount;
  }

  /**
   * Returns the number of labs still needed
   */
  get deficit(): number {
    return Math.max(0, this.adjustedDesiredLabCount - this.usable);
  }

  /**
   * Returns the number of excess labs
   */
  get surplus(): number {
    return Math.max(0, this.usable - this.adjustedDesiredLabCount);
  }
}

export interface LabsByChallengeId {
  [challengeId: string]: Lab[];
}
