import { LocalizedError } from '../types/LocalizedError';
import { NullableNumber, NullableString } from '../types/common';

export const trapError = (func: () => any): LocalizedError | null => {
  try {
    func();
    return null;
  } catch (e: any) {
    if (LocalizedError.isLocalizedError(e)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return e;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    return new LocalizedError(e.message, undefined, true);
  }
};

export const phoneNumberIsValid = (phoneNumber: string): boolean => {
  return /^\+\d{5,20}$/.test(phoneNumber);
};

export const isValidLength = (attribute: NullableString, minLength: number, maxLength: number, isRequired = true) => {
  if (!attribute && !isRequired) {
    return true;
  }
  if (minLength > maxLength || minLength < 0 || maxLength < 0) {
    throw new Error('Invalid args');
  }

  const str = attribute || '';
  return attribute !== null && str.length >= minLength && str.length <= maxLength;
};

export const isValidInteger = (attribute: NullableNumber, min: number, max: number, isRequired?: boolean) => {
  if (!attribute && !isRequired) {
    return true;
  }

  if (min > max || min < 0 || max < 0) {
    throw new Error('Invalid args');
  }

  return attribute !== null && attribute >= min && attribute <= max;
};

export const isValidMinMax = (minVal: NullableNumber, maxVal: NullableNumber, minLimit: number, maxLimit: number) => {
  return (
    minVal &&
    maxVal &&
    minVal <= maxVal &&
    isValidInteger(minVal, minLimit, maxLimit, true) &&
    isValidInteger(maxVal, minLimit, maxLimit, true)
  );
};

export const isValidUrl: (attr: NullableString) => boolean = (attribute: NullableString) => {
  if (!attribute) {
    return false;
  }
  let url: URL;
  try {
    url = new URL(attribute);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const containsYear = (attribute: NullableString) => {
  // checks if attribute contains year, if attribute is null then it implicitly doesn't contain year
  return /((19)|(20))\d\d/.test(attribute || '');
};

export const containsOnlyNumbers = (attribute: NullableString) => {
  // checks if attribute contains only numbers, if attribute is null then it implicitly doesn't contain only numbers
  return /^\d+$/.test(attribute || '');
};

export const containsLargeEventName = (attribute: NullableString) => {
  if (attribute) {
    return (
      attribute.includes('reinvent') ||
      attribute.includes('re-invent') ||
      attribute.includes('reinforce') ||
      attribute.includes('re-inforce') ||
      attribute.includes('re:invent') ||
      attribute.includes('re:inforce') ||
      attribute.includes('reignite') ||
      attribute.includes('re-ignite') ||
      attribute.includes('re:ignite')
    );
  }

  return false;
};

export const containsWhitespace = (attribute: NullableString) => {
  // checks if attribute contains any whitespace, if attribute is null then it implicitly doesn't contain whitespace
  return /\s/.test(attribute || '');
}

export const isValidChallengeId = (attribute: NullableString) => {
  // Checks if attribute is a valid challenge ID, such that it only consists of letters a-z, A-Z, hyphens, and
  // underscores. Length is tested elsewhere. We use '+' instead of '*' here to exclude null attributes.
  return /^[a-zA-Z0-9\-_]+$/.test(attribute || '');
}
