import React from 'react';
import { getTableFilterMatcher, TableFilterMatcher } from '../../../../utils/table.utils';
import { CollectionPreferencesProps } from '@amzn/awsui-components-react';
import { CampaignGroup } from '../../../../types/Campaign';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import TimeAgo from 'timeago-react';
import { getFormattedDate } from '../../../../utils/time.utils';
import { groupRoute } from '../../../../routes';

export const filteringFunction = (item: CampaignGroup, filteringText: string): boolean => {
  const matcher: TableFilterMatcher = getTableFilterMatcher(filteringText);
  return matcher.filterEmpty || matcher.isMatch(item.title);
};

export const COLUMN_DEFINITIONS = (preferences: CollectionPreferencesProps.Preferences) => {
  const { t } = useTranslation();
  return [
    {
      id: 'title',
      sortingField: 'title',
      header: t(i18nKeys.campaigns.headers.groups.title),
      cell: (item: CampaignGroup) => {
        return (
          <React.Fragment>
            {item.campaignId && item.slug && (
              <Link to={groupRoute.resolvePath(item?.campaignId, item.slug)}>{item?.title}</Link>
            )}
            {item?.title !== item?.slug && <div className="secondary-text">{item?.slug}</div>}
          </React.Fragment>
        );
      },
      minWidth: 260,
      allowLineWrap: true,
    },
    {
      id: 'status',
      sortingField: 'currentStatus',
      header: t(i18nKeys.campaigns.headers.groups.status),
      cell: (item: CampaignGroup) => {
        return <React.Fragment>{t(item.currentStatus)}</React.Fragment>;
      },
      minWidth: 260,
      allowLineWrap: true,
    },
    {
      id: 'time-created',
      sortingField: 'createdDate',
      header: t(i18nKeys.campaigns.headers.groups.timeCreated),
      cell: (item: CampaignGroup) => {
        return (
          item.createdDate && (
            <React.Fragment>
              <div>{getFormattedDate(new Date(item.createdDate))}</div>
              <div>{<TimeAgo datetime={item.createdDate} />}</div>
            </React.Fragment>
          )
        );
      },
      minWidth: 260,
      allowLineWrap: true,
    },
    {
      id: 'start-time',
      sortingField: 'startDate',
      header: t(i18nKeys.campaigns.headers.groups.startTime),
      cell: (item: CampaignGroup) => {
        return (
          item.startDate && (
            <React.Fragment>
              <div>{`${getFormattedDate(new Date(item.startDate))}, ${item.startTimeInEventLocalTime}`}</div>
              <div>{<TimeAgo datetime={item.startDate} />}</div>
            </React.Fragment>
          )
        );
      },
      minWidth: 260,
      allowLineWrap: true,
    },
    {
      id: 'end-time',
      sortingField: 'endDate',
      header: t(i18nKeys.campaigns.headers.groups.endTime),
      cell: (item: CampaignGroup) => {
        return (
          item.endDate && (
            <React.Fragment>
              <div>{`${getFormattedDate(new Date(item.endDate))}, ${item.endTimeInEventLocalTime}`}</div>
              <div>{<TimeAgo datetime={item.endDate} />}</div>
            </React.Fragment>
          )
        );
      },
      minWidth: 260,
      allowLineWrap: true,
    },
  ];
};
