import {act} from "../jsx-act";
import UXDefinitions from '../definitions/ux-definitions'
import OpenIcon from '../ux-components/utilities/icon-open'
const COOKIE_LINK = "https://aws.amazon.com/legal/cookies/"

export const localizationRtl:UXDefinitions.LanguageCodesNormalized[] = ['ar-sa']
export const localizationDictionary: UXDefinitions.LocalizationDictionary = {
  "ar-sa": {
    "consentBanner": {
        "title": "تحديد تفضيلات ملفات تعريف الارتباط",
        "paragraph": "نستخدم ملفات تعريف الارتباط والأدوات المشابهة لتحسين تجربتك، ولتوفير خدماتنا وتقديم الإعلانات ذات الصلة وإجراء التحسينات. وتستخدم الأطراف الثالثة المعتمدة هذه الأدوات أيضًا لمساعدتنا على تقديم الإعلانات وتوفير ميزات معينة للموقع.",
        "button-customize": "تخصيص",
        "button-accept": "قبول الكل",
        "button-customize-aria-label": "تخصيص تفضيلات ملفات تعريف الارتباط",
        "button-accept-aria-label": "قبول جميع ملفات تعريف الارتباط"
    },
    "consentSelector": {
        "header": "تخصيص تفضيلات ملفات تعريف الارتباط",
        "intro": "إننا نستخدم ملفات تعريف الارتباط والأدوات المشابهة (يطلق عليها مجتمعة «ملفات تعريف الارتباط») للأغراض التالية.",
        "checkbox-label": "مسموح بها",
        "button-cancel": "إلغاء",
        "button-save": "حفظ التفضيلات",
        "button-cancel-aria-label": "إلغاء تخصيص تفضيلات ملفات تعريف الارتباط",
        "button-save-aria-label": "حفظ تفضيلات ملفات تعريف الارتباط المخصصة",
        "footer": <span>قد يؤثر حظر بعض أنواع ملفات تعريف الارتباط على تجربتك في مواقعنا. يمكنك تغيير تفضيلات ملفات تعريف الارتباط الخاصة بك في أي وقت من خلال النقر فوق تفضيلات ملفات تعريف الارتباط في تذييل هذا الموقع. لمعرفة المزيد حول طريقتنا والأطراف الثالثة في استخدام ملفات تعريف الارتباط في مواقعنا، يرجى قراءة <a data-id='awsccc-cs-f-notice' href={COOKIE_LINK} target='_blank' rel='noopener noreferrer' title="الفتح في نافذة جديدة"><span>إشعار ملفات تعريف الارتباط لخدمة AWS‏<OpenIcon size='10px' /></span></a> الخاصة بنا</span>,
        "section-essential": {
            "title": "أساسي",
            "paragraph": "ملفات تعريف الارتباط الأساسية هذه ضرورية من أجل إتاحة موقعنا وخدماتنا ولا يمكن إلغاء تنشيطها. ويتم تعيينها عادةً فقط استجابة لإجراءاتك على الموقع، مثل تعيين تفضيلات خصوصيتك، أو تسجيل الدخول، أو ملء النماذج. ",
            "checkbox-description": "السماح بالفئة الأساسية"
        },
        "section-performance": {
            "title": "الأداء",
            "paragraph": "توفر ملفات تعريف الارتباط الخاصة بالأداء إحصائيات مجهولة حول طريقة تنقل العملاء في موقعنا، وذلك ليتسنى لنا تحسين تجربة الموقع وأدائه. وقد تقوم أطراف ثالثة معتمدة بإجراء تحليل نيابة عنا، لكن لا يمكنها استخدام البيانات لأغراضها الخاصة.",
            "checkbox-description": "السماح بفئة الأداء"
        },
        "section-functional": {
            "title": "الوظيفية",
            "paragraph": "تساعدنا ملفات تعريف الارتباط الوظيفية على تقديم ميزات موقع مفيدة، وتذكر تفضيلاتك، وعرض محتوى ذي صلة. قد تقوم أطراف ثالثة معتمدة بتعيين ملفات تعريف الارتباط هذه لتقديم ميزات معينة للموقع. إذا لم تسمح بملفات تعريف الارتباط هذه، فقد لا تعمل بعض أو كل من هذه الخدمات على نحو صحيح.",
            "checkbox-description": "السماح بالفئة الوظيفية"
        },
        "section-advertising": {
            "title": "الإعلان",
            "paragraph": "قد نقوم نحن أو شركاؤنا في مجال الإعلانات بتعيين ملفات تعريف الارتباط الإعلانية خلال موقعنا وهي تساعدنا على تقديم محتوى تسويقي ذي صلة. فإذا لم تسمح بوجود ملفات تعريف الارتباط هذه، فستشاهد إعلانات أقل صلة.",
            "checkbox-description": "السماح بالفئة الإعلانية"
        }
    }
  },
  "en-us": {
    "consentBanner": {
      "title": "Select your cookie preferences",
      "paragraph": "We use cookies and similar tools to enhance your experience, provide our services, deliver relevant advertising, and make improvements. Approved third parties also use these tools to help us deliver advertising and provide certain site features.",
      "button-customize": "Customize",
      "button-accept": "Accept all",
      "button-customize-aria-label": "Customize cookie preferences",
      "button-accept-aria-label": "Accept all cookies"
    },
    "consentSelector": {
      "header": "Customize cookie preferences",
      "intro": "We use cookies and similar tools (collectively, \"cookies\") for the following purposes.",
      "checkbox-label": "Allowed",
      "button-cancel": "Cancel",
      "button-save": "Save preferences",
      "button-cancel-aria-label": "Cancel customizing cookie preferences",
      "button-save-aria-label": "Save customized cookie preferences",
      "footer": <span>Blocking some types of cookies may impact your experience of our sites. You may review and change your choices at any time by clicking Cookie preferences in the footer of this site. We and selected third-parties use cookies or similar technologies as specified in the&nbsp;<a data-id='awsccc-cs-f-notice' href={COOKIE_LINK} target='_blank' rel='noopener noreferrer' title='Opens in a new Window'><span>AWS Cookie Notice<OpenIcon size='10px' /></span></a>.</span>,
      "section-essential": {
        "title": "Essential",
        "paragraph": "Essential cookies are necessary to provide our site and services and cannot be deactivated. They are usually set in response to your actions on the site, such as setting your privacy preferences, signing in, or filling in forms. ",
        "checkbox-description": "Allow essential category"
      },
      "section-performance": {
        "title": "Performance",
        "paragraph": "Performance cookies provide anonymous statistics about how customers navigate our site so we can improve site experience and performance. Approved third parties may perform analytics on our behalf, but they cannot use the data for their own purposes.",
        "checkbox-description": "Allow performance category"
      },
      "section-functional": {
        "title": "Functional",
        "paragraph": "Functional cookies help us provide useful site features, remember your preferences, and display relevant content. Approved third parties may set these cookies to provide certain site features. If you do not allow these cookies, then some or all of these services may not function properly.",
        "checkbox-description": "Allow functional category"
      },
      "section-advertising": {
        "title": "Advertising",
        "paragraph": "Advertising cookies may be set through our site by us or our advertising partners and help us deliver relevant marketing content. If you do not allow these cookies, you will experience less relevant advertising.",
        "checkbox-description": "Allow advertising category"
      }
    }
  },
  "de-de": {
    "consentBanner": {
        "title": "Wählen Sie Ihre Cookie-Einstellungen aus",
        "paragraph": "Wir verwenden Cookies und ähnliche Tools, um Ihre Erfahrung zu verbessern, unsere Services bereitzustellen, relevante Werbung bereitzustellen und Verbesserungen vorzunehmen. Von uns zugelassene Dritte verwenden diese Tools auch, um uns bei der Bereitstellung von Werbung und bestimmten Website-Funktionen zu helfen.",
        "button-customize": "Anpassen",
        "button-accept": "Alle akzeptieren",
        "button-customize-aria-label": "Cookie-Einstellungen anpassen",
        "button-accept-aria-label": "Alle Cookies akzeptieren"
    },
    "consentSelector": {
        "header": "Cookie-Einstellungen anpassen",
        "intro": "Wir verwenden Cookies und ähnliche Tools (zusammen \"Cookies\") für folgende Zwecke.",
        "checkbox-label": "Erlaubt",
        "button-cancel": "Abbrechen",
        "button-save": "Einstellungen speichern",
        "button-cancel-aria-label": "Anpassen der Cookie-Einstellungen abbrechen",
        "button-save-aria-label": "Benutzerdefinierte Cookie-Einstellungen speichern",
        "footer": <span>Das Blockieren einiger Arten von Cookies kann sich auf Ihre Erfahrung auf unseren Websites auswirken. Sie können Ihre Cookie-Einstellungen jederzeit ändern, indem Sie in der Fußzeile dieser Website auf Cookie-Einstellungen klicken. Um mehr darüber zu erfahren, wie wir und zugelassene Dritte Cookies auf unseren Websites verwenden, lesen Sie bitte unseren <a data-id='awsccc-cs-f-notice' href={COOKIE_LINK} target='_blank' rel='noopener noreferrer' title='Wird in einem neuen Fenster geöffnet.'><span>AWS-Cookie-Hinweis.<OpenIcon size='10px' /></span></a></span>,
        "section-essential": {
            "title": "Essenziell",
            "paragraph": "Diese Cookies sind erforderlich, um unsere Website und Services bereitzustellen und können nicht deaktiviert werden. Sie werden in der Regel als Reaktion auf Ihre Aktionen auf der Website festgelegt, z. B. die Festlegung Ihrer Datenschutzeinstellungen, die Anmeldung oder das Ausfüllen von Formularen. ",
            "checkbox-description": "Essenziell Cookies zulassen"
        },
        "section-performance": {
            "title": "Leistung",
            "paragraph": "Leistungs-Cookies stellen anonyme Statistiken darüber bereit, wie Kunden auf unserer Website navigieren, damit wir die Website-Erfahrung und -Leistung verbessern können. Zugelassene Dritte können Analysen in unserem Namen durchführen, die Daten aber nicht für ihre eigenen Zwecke verwenden.",
            "checkbox-description": "Lesitungs-Cookies zulassen"
        },
        "section-functional": {
            "title": "Funktional",
            "paragraph": "Funktionale Cookies helfen uns dabei, nützliche Website-Funktionen bereitzustellen, Ihre Präferenzen zu speichern und relevante Inhalte anzuzeigen. Zugelassene Dritte können diese Cookies so einrichten, dass bestimmte Website-Funktionen bereitgestellt werden. Wenn Sie diese Cookies nicht zulassen, funktionieren einige oder alle dieser Services möglicherweise nicht ordnungsgemäß.",
            "checkbox-description": "Funktionale Cookies zulassen"
        },
        "section-advertising": {
            "title": "Werbung",
            "paragraph": "Diese Cookies können von uns oder unseren Werbepartnern über unsere Website gesetzt werden und uns helfen, relevante Marketinginhalte bereitzustellen. Wenn Sie diese Cookies nicht zulassen, werden Sie weniger relevante Werbung erleben.",
            "checkbox-description": "Werbe-Cookies zulassen"
        }
    }
  },
  "es-es": {
    "consentBanner": {
        "title": "Seleccione sus preferencias de cookies",
        "paragraph": "Utilizamos cookies y herramientas similares para mejorar su experiencia, ofrecer nuestros servicios, hacerle llegar publicidad relevante y realizar mejoras. Algunos terceros aprobados también utilizan estas herramientas para ayudarnos a hacerle llegar publicidad y a proporcionar determinadas características del sitio.",
        "button-customize": "Personalizar",
        "button-accept": "Aceptar todas",
        "button-customize-aria-label": "Personalizar preferencias de cookies",
        "button-accept-aria-label": "Aceptar todas las cookies"
    },
    "consentSelector": {
        "header": "Personalizar preferencias de cookies",
        "intro": "Utilizamos cookies y herramientas similares (de forma conjunta, “cookies”) para los siguientes fines.",
        "checkbox-label": "Permitidas",
        "button-cancel": "Cancelar",
        "button-save": "Guardar preferencias",
        "button-cancel-aria-label": "Cancelar la personalización de las preferencias de cookies",
        "button-save-aria-label": "Guardar preferencias personalizadas de cookies",
        "footer": <span>El bloqueo de algunos tipos de cookies puede afectar a su experiencia al navegar por nuestros sitios. Puede cambiar las preferencias de cookies en cualquier momento haciendo clic en Preferencias de cookies en el pie de página de este sitio. Para obtener más información sobre la forma en que nosotros y algunos terceros aprobados usamos las cookies en nuestros sitios, lea el <a data-id='awsccc-cs-f-notice' href={COOKIE_LINK} target='_blank' rel='noopener noreferrer' title='Se abre en una ventana nueva'><span>Aviso de AWS sobre cookies.<OpenIcon size='10px' /></span></a></span>,
        "section-essential": {
            "title": "Esenciales",
            "paragraph": "Estas cookies son necesarias para poder ofrecer nuestro sitio y nuestros servicios, y no se pueden desactivar. Por lo general, solo se emplean en respuesta a las acciones que lleve a cabo en el sitio, por ejemplo, al configurar sus preferencias de privacidad, al iniciar sesión o al completar formularios. ",
            "checkbox-description": "Permitir categoría esencial"
        },
        "section-performance": {
            "title": "De rendimiento",
            "paragraph": "Las cookies de rendimiento proporcionan estadísticas anónimas sobre la forma en que los clientes navegan por nuestro sitio para que podamos mejorar la experiencia y el rendimiento del sitio. Los terceros aprobados pueden realizar análisis en nuestro nombre, pero no pueden utilizar los datos para sus propios fines.",
            "checkbox-description": "Permitir categoría de rendimiento"
        },
        "section-functional": {
            "title": "Funcionales",
            "paragraph": "Las cookies funcionales nos ayudan a proporcionar características útiles del sitio, recordar sus preferencias y mostrar contenido relevante. Es posible que algunos terceros aprobados empleen estas cookies para proporcionar determinadas características del sitio. Si no permite estas cookies, es posible que algunos de estos servicios (o todos ellos) no funcionen correctamente.",
            "checkbox-description": "Permitir categoría funcional"
        },
        "section-advertising": {
            "title": "De publicidad",
            "paragraph": "AWS o nuestros socios publicitarios podemos emplear cookies de publicidad en el sitio para ayudarnos a ofrecer contenido de marketing personalizado. Si no habilita estas cookies, verá publicidad menos relevante.",
            "checkbox-description": "Permitir categoría de publicidad"
        }
    }
  },
  "fr-fr": {
    "consentBanner": {
        "title": "Sélectionner vos préférences de cookies",
        "paragraph": "Nous utilisons des cookies et des outils similaires pour améliorer votre expérience, fournir nos services, diffuser des publicités pertinentes et apporter des améliorations. Des tiers approuvés utilisent également ces outils pour nous aider à diffuser des publicités et à fournir certaines fonctionnalités de site.",
        "button-customize": "Personnaliser",
        "button-accept": "Accepter tout",
        "button-customize-aria-label": "Personnaliser les préférences de cookies",
        "button-accept-aria-label": "Accepter tous les cookies"
    },
    "consentSelector": {
        "header": "Personnaliser les préférences de cookies",
        "intro": "Nous utilisons des cookies et des outils similaires (collectivement, « cookies ») pour les raisons suivantes.",
        "checkbox-label": "Autorisé",
        "button-cancel": "Annuler",
        "button-save": "Enregistrer les préférences",
        "button-cancel-aria-label": "Annuler la personnalisation des préférences de cookies",
        "button-save-aria-label": "Enregistrer les préférences de cookies personnalisées",
        "footer": <span>Le blocage de certains types de cookies peut affecter votre expérience sur nos sites. Vous pouvez modifier vos préférences de cookies à tout moment en cliquant sur Préférences de cookies en bas de la page de ce site. Pour en savoir plus sur la façon dont nous-mêmes et des tiers approuvés utilisons les cookies sur nos sites, veuillez lire la<a data-id='awsccc-cs-f-notice' href={COOKIE_LINK} target='_blank' rel='noopener noreferrer' title='Politique AWS relative aux cookies'><span>S’ouvre dans une nouvelle fenêtre.<OpenIcon size='10px' /></span></a></span>,
        "section-essential": {
            "title": "Essentiels",
            "paragraph": "Les cookies essentiels sont nécessaires pour vous proposer notre site et nos services et ne peuvent pas être désactivés. Ils sont généralement définis en réponse à vos actions sur le site, telles que la définition de vos préférences de confidentialité, la connexion ou le remplissage de formulaires. ",
            "checkbox-description": "Autoriser la catégorie Essentiels"
        },
        "section-performance": {
            "title": "Performances",
            "paragraph": "Les cookies performances fournissent des statistiques anonymes sur la façon dont les clients naviguent sur notre site afin que nous puissions améliorer l'expérience et les performances du site. Les tiers autorisés peuvent effectuer des analyses en notre nom, mais ils ne peuvent pas utiliser les données à leurs propres fins.",
            "checkbox-description": "Autoriser la catégorie Performances"
        },
        "section-functional": {
            "title": "Fonctionnels",
            "paragraph": "Les cookies fonctionnels nous aident à fournir des fonctionnalités utiles du site, à mémoriser vos préférences et à afficher du contenu pertinent. Des tiers approuvés peuvent configurer ces cookies pour fournir certaines fonctionnalités du site. Si vous n'autorisez pas ces cookies, certains ou tous ces services peuvent ne pas fonctionner correctement.",
            "checkbox-description": "Autoriser la catégorie Fonctionnels"
        },
        "section-advertising": {
            "title": "Publicitaires",
            "paragraph": "Les cookies publicitaires peuvent être installés sur notre site par nous ou nos partenaires publicitaires et nous aide à diffuser du contenu marketing pertinent. Si vous n’autorisez pas ces cookies, la publicité que vous verrez s’afficher sera moins pertinente.",
            "checkbox-description": "Autoriser la catégorie Publicitaires"
        }
    }
  },
  "id-id": {
    "consentBanner": {
        "title": "Pilih preferensi cookie Anda",
        "paragraph": "Kami menggunakan cookie dan alat serupa untuk meningkatkan pengalaman Anda, menyediakan layanan kami, menyampaikan iklan yang relevan, dan melakukan penyempurnaan. Pihak ketiga yang disetujui juga menggunakan alat ini guna membantu kami menyampaikan iklan dan menyediakan berbagai fitur tertentu pada situs.",
        "button-customize": "Sesuaikan",
        "button-accept": "Terima semua",
        "button-customize-aria-label": "Sesuaikan preferensi cookie",
        "button-accept-aria-label": "Terima semua cookie"
    },
    "consentSelector": {
        "header": "Sesuaikan preferensi cookie",
        "intro": "Kami menggunakan cookie dan alat yang serupa (secara kolektif, “cookie”) untuk tujuan berikut.",
        "checkbox-label": "Diizinkan",
        "button-cancel": "Batalkan",
        "button-save": "Simpan preferensi",
        "button-cancel-aria-label": "Batalkan penyesuaian preferensi cookie",
        "button-save-aria-label": "Simpan preferensi cookie yang disesuaikan",
        "footer": <span>Memblokir beberapa jenis cookie dapat memengaruhi pengalaman Anda di situs kami. Anda dapat mengubah preferensi cookie kapan saja dengan mengklik Preferensi cookie di footer situs ini. Untuk mempelajari lebih lanjut tentang bagaimana kami dan pihak ketiga yang disetujui menggunakan cookie di situs kami, silakan baca <a data-id='awsccc-cs-f-notice' href={COOKIE_LINK} target='_blank' rel='noopener noreferrer' title='Membuka AWS Cookie Notice'><span> di jendela baru.<OpenIcon size='10px' /></span></a></span>,
        "section-essential": {
            "title": "Penting",
            "paragraph": "Cookie ini diperlukan untuk menjalankan situs dan layanan kami dan tidak dapat dinonaktifkan. Cookie biasanya tersusun hanya sebagai tanggapan atas tindakan Anda di situs, seperti mengatur preferensi privasi, masuk, atau mengisi formulir. ",
            "checkbox-description": "Izinkan kategori penting"
        },
        "section-performance": {
            "title": "Kinerja",
            "paragraph": "Cookie kinerja menyediakan statistik anonim tentang cara pelanggan menavigasi situs kami sehingga kami dapat menyempurnakan pengalaman dan kinerja situs. Pihak ketiga yang disetujui dapat melakukan analisis atas nama kami, tetapi tidak dapat menggunakan data untuk tujuannya sendiri.",
            "checkbox-description": "Izinkan kategori kinerja"
        },
        "section-functional": {
            "title": "Fungsional",
            "paragraph": "Cookie fungsional membantu kami menyediakan berbagai fitur bermanfaat, mengingat preferensi Anda, dan menampilkan konten yang relevan pada situs. Pihak ketiga yang disetujui dapat mengatur cookie ini untuk menyediakan fitur tertentu pada situs. Jika Anda tidak mengizinkan cookie ini, maka beberapa atau semua layanan ini mungkin tidak berjalan dengan baik.",
            "checkbox-description": "Izinkan kategori fungsional"
        },
        "section-advertising": {
            "title": "Iklan",
            "paragraph": "Cookie ini dapat diatur melalui situs kami oleh mitra iklan dan membantu kami mempersonalisasi konten pemasaran. Jika Anda tidak mengizinkan cookie, Anda akan mendapatkan iklan yang kurang relevan.",
            "checkbox-description": "Izinkan kategori iklan"
        }
    }
  },
  "it-it": {
    "consentBanner": {
        "title": "Seleziona le tue preferenze relative ai cookie",
        "paragraph": "Utilizziamo cookie e strumenti simili per migliorare l'esperienza, fornire servizi, offrire pubblicità pertinenti e apportare miglioramenti. Anche le terze parti approvate utilizzano questi strumenti per aiutarci a distribuire pubblicità e fornire determinate funzionalità del sito.",
        "button-customize": "Personalizza",
        "button-accept": "Accetta tutto",
        "button-customize-aria-label": "Personalizza le tue preferenze relative ai cookie",
        "button-accept-aria-label": "Accetta tutti i cookie"
    },
    "consentSelector": {
        "header": "Personalizza le tue preferenze relative ai cookie",
        "intro": "Utilizziamo cookie e strumenti simili (collettivamente, \"cookie\") per le seguenti finalità.",
        "checkbox-label": "Consentiti",
        "button-cancel": "Annulla",
        "button-save": "Salva preferenze",
        "button-cancel-aria-label": "Annulla la personalizzazione delle preferenze relative ai cookie",
        "button-save-aria-label": "Salva le preferenze personalizzate relative ai cookie",
        "footer": <span>Il blocco di alcuni tipi di cookie può influire sulla tua esperienza dei nostri siti. Puoi modificare le tue preferenze relative ai cookie in qualsiasi momento facendo clic su Preferenze cookie, nel piè di pagina di questo sito. Per ulteriori informazioni su come noi e le terze parti approvate utilizziamo i cookie sui nostri siti, leggi la nostra <a data-id='awsccc-cs-f-notice' href={COOKIE_LINK} target='_blank' rel='noopener noreferrer' title='Apertura in una nuova finestra'><span>Informativa sui cookie di AWS.<OpenIcon size='10px' /></span></a></span>,
        "section-essential": {
            "title": "Essenziali",
            "paragraph": "I cookie essenziali sono necessari per fornire il nostro sito e i nostri servizi e non possono essere disattivati. In genere vengono impostati in risposta alle tue azioni sul sito, come l'impostazione delle tue preferenze sulla privacy, l'accesso o la compilazione di moduli. ",
            "checkbox-description": "Consenti i cookie essenziali"
        },
        "section-performance": {
            "title": "Prestazione",
            "paragraph": "I cookie di prestazione forniscono statistiche anonime sul modo in cui i clienti navigano nel nostro sito in modo da migliorare l'esperienza e le prestazioni del sito. Le terze parti approvate possono eseguire analisi per conto nostro, ma non possono utilizzare i dati per le proprie finalità.",
            "checkbox-description": "Consenti i cookie di prestazione"
        },
        "section-functional": {
            "title": "Funzionali",
            "paragraph": "I cookie funzionali ci aiutano a fornire funzionalità utili del sito, a ricordare le tue preferenze e a mostrare contenuti pertinenti. Le terze parti approvate possono impostare questi cookie per fornire determinate funzionalità del sito. Se non permetti l'installazione di questi cookie, alcuni o tutti questi servizi potrebbero non funzionare correttamente.",
            "checkbox-description": "Consenti i cookie funzionali"
        },
        "section-advertising": {
            "title": "Pubblicitari",
            "paragraph": "I cookie pubblicitari possono essere impostati tramite il nostro sito da noi o dai nostri partner pubblicitari e ci aiutano a distribuire contenuti di marketing personalizzati. Se non permetti l'installazione di questi cookie, visualizzerai pubblicità meno pertinenti.",
            "checkbox-description": "Consenti i cookie pubblicitari"
        }
    }
  },
  "ja-jp": {
    "consentBanner": {
        "title": "Cookie の設定を選択する",
        "paragraph": "当社は、お客様のエクスペリエンスの強化、サービスの提供、関連性の高い広告の配信を行い、改善を行うために、クッキーや同様のツールを使用しています。また、承認された第三者も、こうしたツールを使用して、広告を配信し、特定のサイト機能を提供いたします。",
        "button-customize": "カスタマイズ",
        "button-accept": "すべてを受け入れる",
        "button-customize-aria-label": "Cookie の設定をカスタマイズする",
        "button-accept-aria-label": "すべての Cookie を受け入れる"
    },
    "consentSelector": {
        "header": "Cookie の設定をカスタマイズする",
        "intro": "当社は、以下の目的で Cookie および同様のツール (以下総称して「Cookie」) を使用いたします。",
        "checkbox-label": "許可",
        "button-cancel": "キャンセル",
        "button-save": "設定を保存",
        "button-cancel-aria-label": "Cookie 設定のカスタマイズをキャンセルする",
        "button-save-aria-label": "カスタマイズした Cookie 設定を保存する",
        "footer": <span>一部の種類の Cookie をブロックすると、サイトの操作に影響する可能性があります。Cookie の設定は、このサイトのフッターにある [Cookie preferences] をクリックすることで、いつでも変更できます。当社および承認された第三者が Cookie をどのように使用しているかについては、「<a data-id='awsccc-cs-f-notice' href={COOKIE_LINK} target='_blank' rel='noopener noreferrer' title='Open in a new Window'><span>AWS Cookie Notice」をお読みください。<OpenIcon size='10px' /></span></a></span>,
        "section-essential": {
            "title": "Essential",
            "paragraph": "Essential Cookie は、当社のサイトおよびサービスを提供するために必要であり、無効にすることはできません。通常、プライバシー設定の選択、サインイン、フォームへの入力など、サイトでのアクションに応じてのみ設定されます。",
            "checkbox-description": "Essential カテゴリを許可する"
        },
        "section-performance": {
            "title": "Performance",
            "paragraph": "Performance Cookie は、お客様によるサイトの操作方法に関する匿名の統計を提供するため、サイトのエクスペリエンスとパフォーマンスを向上させることができます。承認された第三者は、当社に代わって分析を行う場合がありますが、データを独自の目的で使用することはできません。",
            "checkbox-description": "Performance カテゴリを許可する"
        },
        "section-functional": {
            "title": "Functional",
            "paragraph": "Functional Cookie は、有用なサイト機能の提供、ユーザーの嗜好の記憶、関連コンテンツの表示に役立ちます。承認された第三者は、特定のサイト機能を提供するためにこれらのクッキーを設定する場合があります。これらのクッキーを許可しない場合、サービスの一部またはすべてが適切に機能しない可能性があります。",
            "checkbox-description": "Functional カテゴリを許可する"
        },
        "section-advertising": {
            "title": "Advertising",
            "paragraph": "Advertising Cookie は、当社の広告パートナーによって当社のサイトを通じて設定され、関連するマーケティングコンテンツの配信に役立ちます。これらの Cookie を許可しないと、広告の関連性が低くなります。",
            "checkbox-description": "Advertising カテゴリを許可する"
        }
    }
  },
  "ko-kr": {
    "consentBanner": {
        "title": "쿠키 기본 설정 선택",
        "paragraph": "AWS는 사용자 경험을 개선하고, 서비스를 제공하며, 관련 광고를 전달하고, 서비스를 개선하기 위해 쿠키 및 유사한 도구를 사용합니다. 또한 승인된 제3자도 이러한 도구를 사용하여 AWS가 광고를 전달하고 특정 사이트 기능을 제공하도록 지원합니다.",
        "button-customize": "사용자 지정",
        "button-accept": "모두 수락",
        "button-customize-aria-label": "쿠키 기본 설정 사용자 지정",
        "button-accept-aria-label": "모든 쿠키 수락"
    },
    "consentSelector": {
        "header": "쿠키 기본 설정 사용자 지정",
        "intro": "AWS는 다음과 같은 목적으로 쿠키 및 유사한 도구(총칭하여 \"쿠키\")를 사용합니다.",
        "checkbox-label": "허용됨",
        "button-cancel": "취소",
        "button-save": "기본 설정 저장",
        "button-cancel-aria-label": "쿠키 기본 설정 사용자 지정 취소",
        "button-save-aria-label": "사용자 지정된 쿠키 기본 설정 저장",
        "footer": <span>일부 유형의 쿠키를 차단하면 AWS 사이트 경험이 영향을 받을 수 있습니다. 언제든지 이 사이트의 바닥글에서 [쿠키 기본 설정]을 클릭하여 해당하는 쿠키 기본 설정을 변경할 수 있습니다. AWS 사이트에서 AWS 및 승인된 제 3자가 쿠키를 사용하는 방법에 대한 자세한 내용은 <a data-id='awsccc-cs-f-notice' href={COOKIE_LINK} target='_blank' rel='noopener noreferrer' title='새 창에서 열기'><span>AWS 쿠키 공지 사항<OpenIcon size='10px' /></span></a>을 참조하십시오.</span>,
        "section-essential": {
            "title": "필수",
            "paragraph": "필수 쿠키는 AWS 사이트 및 서비스를 제공하는 데 필요하며, 비활성화할 수 없습니다. 일반적으로 개인 정보 보호 기본 설정, 로그인 또는 양식 작성 등 사이트 내에서 사용자가 수행한 작업에 상응하는 쿠키가 설정됩니다. ",
            "checkbox-description": "필수 범주 허용"
        },
        "section-performance": {
            "title": "성능",
            "paragraph": "성능 쿠키는 AWS에서 사이트 경험 및 성능을 개선할 수 있도록 고객이 AWS 사이트를 탐색하는 방법에 대한 익명의 통계를 제공합니다. 승인된 제3자가 AWS를 대신하여 분석을 수행할 수 있지만, 해당 데이터를 다른 특정 목적으로 사용할 수는 없습니다.",
            "checkbox-description": "성능 범주 허용"
        },
        "section-functional": {
            "title": "기능",
            "paragraph": "기능 쿠키는 유용한 사이트 기능을 제공하고, 사용자의 기본 설정을 기억하며, 관련 콘텐츠를 표시하는 데 도움을 줍니다. 승인된 제3자가 이러한 쿠키를 설정하여 특정 사이트 기능을 제공할 수 있습니다. 이러한 쿠키를 허용하지 않으면 이러한 서비스 중 일부 또는 전체가 제대로 작동하지 않을 수 있습니다.",
            "checkbox-description": "기능 범주 허용"
        },
        "section-advertising": {
            "title": "광고",
            "paragraph": "광고 쿠키는 AWS의 광고 파트너가 AWS 사이트를 통해 설정할 수 있으며, 관련 마케팅 콘텐츠를 제공하는 데 도움을 줍니다. 이러한 쿠키를 허용하지 않으면 관련성이 낮은 광고가 표시됩니다.",
            "checkbox-description": "광고 범주 허용"
        }
    }
  },
  "pt-br": {
    "consentBanner": {
        "title": "Selecione suas preferências de cookies",
        "paragraph": "Usamos cookies e ferramentas semelhantes para aprimorar sua experiência, fornecer nossos serviços, entregar publicidade relevante e fazer melhorias. Os terceiros aprovados também usam essas ferramentas para nos ajudar a entregar publicidade e fornecer determinados recursos do site.",
        "button-customize": "Personalizar",
        "button-accept": "Aceitar tudo",
        "button-customize-aria-label": "Personalizar preferências de cookies",
        "button-accept-aria-label": "Aceitar todos os cookies"
    },
    "consentSelector": {
        "header": "Personalizar preferências de cookies",
        "intro": "Usamos cookies e ferramentas semelhantes (coletivamente, \"cookies\") para as seguintes finalidades.",
        "checkbox-label": "Permitido",
        "button-cancel": "Cancelar",
        "button-save": "Salvar preferências",
        "button-cancel-aria-label": "Cancelar personalização de preferências de cookies",
        "button-save-aria-label": "Salvar preferências de cookies personalizadas",
        "footer": <span>Bloquear alguns tipos de cookies pode afetar sua experiência em nossos sites. Você pode alterar suas preferências de cookies a qualquer momento, clicando em Preferências de cookies no rodapé deste site. Para saber mais sobre como nós e terceiros aprovados usamos cookies em nossos sites, leia nosso <a data-id='awsccc-cs-f-notice' href={COOKIE_LINK} target='_blank' rel='noopener noreferrer' title='Abre em uma nova janela'><span>Aviso sobre cookies da AWS.<OpenIcon size='10px' /></span></a></span>,
        "section-essential": {
            "title": "Essenciais",
            "paragraph": "Cookies essenciais são necessários para fornecer nosso site e serviços e não podem ser desativados. Geralmente, eles são definidos em resposta às suas ações no site, como definir suas preferências de privacidade, fazer login ou preencher formulários. ",
            "checkbox-description": "Permitir a categoria Essenciais"
        },
        "section-performance": {
            "title": "Desempenho",
            "paragraph": "Os cookies de desempenho fornecem estatísticas anônimas sobre como os clientes navegam em nosso site, para que possamos melhorar a experiência e o desempenho do site. Terceiros aprovados podem realizar análises em nosso nome, mas não podem usar os dados para seus próprios propósitos.",
            "checkbox-description": "Permitir a categoria Desempenho"
        },
        "section-functional": {
            "title": "Funcionais",
            "paragraph": "Cookies funcionais nos ajudam a fornecer recursos úteis do site, lembrar suas preferências e exibir conteúdo relevante. Terceiros aprovados podem definir esses cookies para fornecer determinados recursos do site. Se você não permitir esses cookies, alguns ou todos esses serviços talvez não funcionem corretamente.",
            "checkbox-description": "Permitir a categoria Funcionais"
        },
        "section-advertising": {
            "title": "Publicidade",
            "paragraph": "Cookies de publicidade podem ser configurados em nosso site por nós ou nossos parceiros de publicidade e nos ajudar a distribuir conteúdo de marketing relevante. Se você não permitir esses cookies, receberá publicidade menos relevante.",
            "checkbox-description": "Permitir a categoria Publicidade"
        }
    }
  },
  "ru-ru": {
    "consentBanner": {
        "title": "Выберите настройки файлов cookie",
        "paragraph": "Мы используем файлы cookie и аналогичные инструменты, чтобы улучшать ваше взаимодействие с сайтом, предоставлять свои услуги, показывать релевантную рекламу и вносить усовершенствования. Одобренные сторонние компании также используют эти инструменты, чтобы помогать нам предоставлять рекламу и определенные возможности сайта.",
        "button-customize": "Настроить",
        "button-accept": "Принять все",
        "button-customize-aria-label": "Настроить параметры файлов cookie",
        "button-accept-aria-label": "Принять все файлы cookie"
    },
    "consentSelector": {
        "header": "Настроить параметры файлов cookie",
        "intro": "Мы используем файлы cookie и аналогичные инструменты (совместно именуемые «файлы cookie») для следующих целей.",
        "checkbox-label": "Разрешенные",
        "button-cancel": "Отмена",
        "button-save": "Сохранить настройки",
        "button-cancel-aria-label": "Отменить настройку параметров файлов cookie",
        "button-save-aria-label": "Сохранить измененные параметры файлов cookie",
        "footer": <span>Блокировка некоторых типов файлов cookie может повлиять на вашу работу с нашими сайтами. Вы можете в любое время изменить настройки файлов cookie, нажав «Параметры файлов cookie» в нижнем колонтитуле этого сайта. Чтобы узнать больше о том, как файлы cookie на наших сайтах используются нами и одобренными третьими сторонами, прочитайте <a data-id='awsccc-cs-f-notice' href={COOKIE_LINK} target='_blank' rel='noopener noreferrer' title='Откроется в новом окне'><span>Уведомление AWS о файлах cookie.<OpenIcon size='10px' /></span></a></span>,
        "section-essential": {
            "title": "Необходимые",
            "paragraph": "Необходимые файлы cookie требуется для работы нашего сайта и служб, и их нельзя отключить. Обычно они устанавливаются при выборе параметров конфиденциальности, входе в аккаунт, заполнении форм и других действиях на сайте. ",
            "checkbox-description": "Разрешить категорию «Необходимые»"
        },
        "section-performance": {
            "title": "Связанные с производительностью",
            "paragraph": "Файлы cookie, связанные с производительностью, предоставляют анонимную статистику о том, как клиенты просматривают наш сайт, чтобы мы могли улучшить его работу и повысить производительность. Одобренные третьи стороны могут проводить анализ от нашего имени, но не имеют право использовать данные в своих целях.",
            "checkbox-description": "Разрешить категорию «Связанные с производительностью»"
        },
        "section-functional": {
            "title": "Функциональные",
            "paragraph": "Функциональные файлы cookie помогают нам предоставлять полезные функции сайта, запоминать ваши предпочтения и отображать соответствующий контент. Одобренные третьи стороны могут устанавливать эти файлы cookie для предоставления определенных функций сайта. Если вы не разрешаете применять эти файлы cookie, некоторые (или все) эти сервисы могут работать неправильно.",
            "checkbox-description": "Разрешить категорию «Функциональные»"
        },
        "section-advertising": {
            "title": "Рекламные",
            "paragraph": "Эти файлы cookie устанавливаются на наш сайт нами или нашими рекламными партнерами. Они помогают нам персонализировать рекламу. Если вы отключите эти файлы cookie, реклама станет менее релевантной.",
            "checkbox-description": "Разрешить категорию «Рекламные»"
        }
    }
  },
  "th-th": {
    "consentBanner": {
        "title": "เลือกค่ากำหนดคุกกี้ของคุณ",
        "paragraph": "เราใช้คุกกี้และเครื่องมือที่คล้ายคลึงกันเพื่อยกระดับประสบการณ์ของคุณ ให้บริการของเรา นำเสนอโฆษณาที่เกี่ยวข้อง และปรับปรุงพัฒนาต่อไป นอกจากนี้ บุคคลภายนอกที่ได้รับอนุญาตยังจะใช้เครื่องมือเหล่านี้เพื่อช่วยเรานำเสนอโฆษณาและมอบคุณสมบัติบางอย่างของเว็บไซต์อีกด้วย",
        "button-customize": "ปรับแต่ง",
        "button-accept": "ยอมรับทั้งหมด",
        "button-customize-aria-label": "ปรับแต่งค่ากำหนดของคุกกี้",
        "button-accept-aria-label": "ยอมรับคุกกี้ทั้งหมด"
    },
    "consentSelector": {
        "header": "ปรับแต่งค่ากำหนดของคุกกี้",
        "intro": "เราใช้คุกกี้และเครื่องมือที่คล้ายคลึงกัน (เรียกโดยรวมว่า \"คุกกี้\") เพื่อวัตถุประสงค์ต่อไปนี้",
        "checkbox-label": "อนุญาตแล้ว",
        "button-cancel": "ยกเลิก",
        "button-save": "บันทึกค่ากำหนด",
        "button-cancel-aria-label": "ยกเลิกการปรับแต่งค่ากำหนดของคุกกี้",
        "button-save-aria-label": "บันทึกค่ากำหนดของคุกกี้ที่ปรับแต่ง",
        "footer": <span>การบล็อกคุกกี้บางประเภทอาจส่งผลต่อประสบการณ์ในการใช้งานเว็บไซต์ของเรา คุณสามารถเปลี่ยนแปลงค่ากำหนดของคุกกี้ได้ทุกเมื่อ โดยคลิกที่ค่ากำหนดของคุกกี้ในส่วนล่างของเว็บไซต์นี้ หากต้องการเรียนรู้เพิ่มเติมเกี่ยวกับวิธีการที่เราและบุคคลภายนอกที่ได้รับอนุญาตใช้คุกกี้บนเว็บไซต์ของเรา โปรดอ่าน<a data-id='awsccc-cs-f-notice' href={COOKIE_LINK} target='_blank' rel='noopener noreferrer' title='เปิดในหน้าต่างบานใหม่'><span>ประกาศเกี่ยวกับคุกกี้ของ AWS<OpenIcon size='10px' /></span></a></span>,
        "section-essential": {
            "title": "คุกกี้ที่จำเป็น",
            "paragraph": "คุกกี้เหล่านี้จำเป็นต่อการให้บริการของเว็บไซต์และบริการของเรา และไม่สามารถปิดการใช้งานได้ โดยปกติแล้วจะมีการตั้งค่าให้ตอบสนองต่อการใช้งานของคุณบนเว็บไซต์ เช่น การตั้งค่ากำหนดความเป็นส่วนตัวของคุณ การลงชื่อเข้าใช้ หรือการกรอกแบบฟอร์มต่างๆ ",
            "checkbox-description": "อนุญาตให้ใช้คุกกี้ที่จำเป็น"
        },
        "section-performance": {
            "title": "คุกกี้ด้านประสิทธิภาพ",
            "paragraph": "คุกกี้ด้านประสิทธิภาพจะให้ข้อมูลสถิติแบบไม่ระบุชื่อเกี่ยวกับลักษณะการเยี่ยมชมส่วนต่างๆ ของเว็บไซต์ของลูกค้า เพื่อที่เราจะได้นำไปปรับปรุงประสบการณ์และประสิทธิภาพของเว็บไซต์ บุคคลภายนอกที่ได้รับอนุญาตอาจทำการวิเคราะห์ข้อมูลในนามของเรา แต่จะไม่สามารถนำข้อมูลไปใช้เพื่อวัตถุประสงค์ของตัวเองได้",
            "checkbox-description": "อนุญาตให้ใช้คุกกี้ด้านประสิทธิภาพ"
        },
        "section-functional": {
            "title": "คุกกี้เพื่อช่วยในการใช้งาน",
            "paragraph": "คุกกี้เพื่อช่วยในการใช้งานจะช่วยให้เรามอบคุณสมบัติที่มีประโยชน์ของเว็บไซต์ จดจำค่ากำหนดของคุณ และแสดงเนื้อหาที่เกี่ยวข้อง บุคคลภายนอกที่ได้รับอนุญาตอาจตั้งค่าคุกกี้เหล่านี้เพื่อมอบคุณสมบัติบางอย่างของเว็บไซต์ หากคุณไม่อนุญาตให้ใช้คุกกี้เหล่านี้ บริการบางอย่างหรือทั้งหมดเหล่านี้อาจทำงานไม่เหมาะสม",
            "checkbox-description": "อนุญาตให้ใช้คุกกี้เพื่อช่วยในการใช้งาน"
        },
        "section-advertising": {
            "title": "คุกกี้เพื่อการโฆษณา",
            "paragraph": "คุกกี้เพื่อการโฆษณาอาจได้รับการตั้งค่าผ่านเว็บไซต์โดยเราหรือคู่ค้าด้านโฆษณาของเรา และช่วยเราในการส่งมอบเนื้อหาทางการตลาดที่เกี่ยวข้อง หากคุณไม่อนุญาตคุกกี้เหล่านี้ คุณจะพบโฆษณาที่เกี่ยวข้องน้อยลง",
            "checkbox-description": "อนุญาตให้ใช้คุกกี้เพื่อการโฆษณา"
        }
    }
  },
  "tr-tr": {
    "consentBanner": {
        "title": "Çerez tercihlerinizi seçme",
        "paragraph": "Size daha iyi bir deneyim sunmak, hizmetlerimizi sağlamak, alakalı reklamlar yayınlamak ve iyileştirmeler yapmak amacıyla çerezler ve benzer araçlar kullanırız. Bu araçları, reklam yayınlamamıza ve belirli site özelliklerini sağlamamıza yardımcı olmak amacıyla onaylı üçüncü taraflar da kullanır.",
        "button-customize": "Özelleştir",
        "button-accept": "Tümünü kabul et",
        "button-customize-aria-label": "Çerez tercihlerini özelleştir",
        "button-accept-aria-label": "Tüm çerezleri kabul et"
    },
    "consentSelector": {
        "header": "Çerez tercihlerini özelleştir",
        "intro": "Çerezleri ve benzer araçları (topluca \"çerezler\") aşağıdaki amaçlar için kullanırız.",
        "checkbox-label": "İzin verildi",
        "button-cancel": "İptal",
        "button-save": "Tercihleri kaydet",
        "button-cancel-aria-label": "Çerez tercihlerini özelleştirmeyi iptal et",
        "button-save-aria-label": "Özelleştirilmiş çerez tercihlerini kaydet",
        "footer": <span>Bazı çerez türlerini engellemek, sitelerimizle ilgili deneyiminizi etkileyebilir. Bu sitenin alt bilgisindeki Çerez tercihleri bağlantısına tıklayarak dilediğiniz zaman seçimlerinizi değiştirebilirsiniz. Bizim ve onaylı üçüncü tarafların, çerezleri sitelerimizde nasıl kullandığımız hakkında daha fazla bilgi edinmek için lütfen <a data-id='awsccc-cs-f-notice' href={COOKIE_LINK} target='_blank' rel='noopener noreferrer' title='Yeni Bir Pencerede Açılır'><span>AWS Çerez Bildirimimizi<OpenIcon size='10px' /></span></a> okuyun.</span>,
        "section-essential": {
            "title": "Temel",
            "paragraph": "Temel çerezler, sitemizi ve hizmetlerimizi sunmak için gerekli olup devre dışı bırakılamaz. Bunlar genellikle sitede gizlilik tercihlerinizi ayarlama, oturum açma veya formları doldurma gibi eylemlerde bulunduğunuzda yerleştirilir. ",
            "checkbox-description": "Temel kategoriye izin ver"
        },
        "section-performance": {
            "title": "Performans",
            "paragraph": "Performans çerezleri, site deneyimini ve performansını iyileştirebilmemiz için müşterilerin sitemizde nasıl gezindiği hakkında anonim istatistikler sağlar. Onaylı üçüncü taraflar bizim adımıza analiz yapabilir ancak verileri kendi amaçları için kullanamazlar.",
            "checkbox-description": "Performans kategorisine izin ver"
        },
        "section-functional": {
            "title": "İşlevsellik",
            "paragraph": "İşlevsellik çerezleri yararlı site özellikleri sunmamıza, tercihlerinizi hatırlamamıza ve alakalı içerikler göstermemize yardımcı olur. Onaylı üçüncü taraflar bu çerezleri belirli site özelliklerini sağlamak için yerleştirebilir. Bu çerezlere izin vermezseniz, bu hizmetlerin bir kısmı veya tamamı düzgün çalışmayabilir.",
            "checkbox-description": "İşlevsellik kategorisine izin ver"
        },
        "section-advertising": {
            "title": "Reklam",
            "paragraph": "Reklam çerezleri, sitemiz aracılığıyla bizim tarafımızdan ya da reklam çözüm ortaklarımız tarafından yerleştirilebilir ve alakalı pazarlama içerikleri yayınlamamıza yardımcı olur. Bu çerezlere izin vermezseniz, daha az alakalı reklamlarla karşılaşırsınız.",
            "checkbox-description": "Reklam kategorisine izin ver"
        }
    }
  },
  "vi-vn": {
    "consentBanner": {
        "title": "Chọn tùy chọn cookie của bạn",
        "paragraph": "Chúng tôi sử dụng cookie và các công cụ tương tự để nâng cao trải nghiệm của bạn, cung cấp dịch vụ, phân phối nội dung quảng cáo phù hợp và tạo ra cải thiện. Các bên thứ ba được chấp thuận cũng có thể sử dụng các công cụ này để giúp chúng tôi phân phối quảng cáo và cung cấp một số tính năng trên trang.",
        "button-customize": "Tùy chỉnh",
        "button-accept": "Chấp nhận tất cả",
        "button-customize-aria-label": "Tùy chỉnh tùy chọn cookie",
        "button-accept-aria-label": "Chấp nhận tất cả cookie"
    },
    "consentSelector": {
        "header": "Tùy chỉnh tùy chọn cookie",
        "intro": "Chúng tôi sử dụng cookie và các công cụ tương tự (gọi chung là “cookie”) để phục vụ các mục đích sau.",
        "checkbox-label": "Đã cho phép",
        "button-cancel": "Hủy",
        "button-save": "Lưu tùy chọn",
        "button-cancel-aria-label": "Hủy tùy chỉnh tùy chọn cookie",
        "button-save-aria-label": "Lưu tùy chọn cookie đã tùy chỉnh",
        "footer": <span>Việc chặn một số loại cookie có thể ảnh hưởng đến trải nghiệm của bạn trên trang của chúng tôi. Bạn có thể thay đổi tùy chọn cookie của mình bất cứ lúc nào bằng cách nhấp vào Tùy chọn cookie ở chân của trang này. Để tìm hiểu thêm về cách chúng tôi và các bên thứ ba được chấp thuận sử dụng cookie trên trang của chúng tôi, vui lòng đọc <a data-id='awsccc-cs-f-notice' href={COOKIE_LINK} target='_blank' rel='noopener noreferrer' title='Mở trong cửa sổ mới'><span>Thông báo về cookie của AWS.<OpenIcon size='10px' /></span></a></span>,
        "section-essential": {
            "title": "Thiết yếu",
            "paragraph": "Cookie thiết yếu đóng vai trò cần thiết trong việc cung cấp trang cũng như dịch vụ của chúng tôi và không thể bị vô hiệu hóa. Thông thường, cookie được thiết lập để phản hồi các hành động của bạn trên trang, chẳng hạn như thiết lập tùy chọn quyền riêng tư, đăng nhập hoặc điền vào biểu mẫu. ",
            "checkbox-description": "Cho phép hạng mục thiết yếu"
        },
        "section-performance": {
            "title": "Hiệu suất",
            "paragraph": "Cookie hiệu suất cung cấp số liệu thống kê ẩn danh về cách khách hàng điều hướng trang của chúng tôi nhằm giúp chúng tôi cải thiện trải nghiệm và hiệu suất của trang. Các bên thứ ba được chấp thuận có thể thực hiện phân tích thay mặt chúng tôi nhưng không thể sử dụng dữ liệu cho mục đích riêng của mình.",
            "checkbox-description": "Cho phép hạng mục hiệu suất"
        },
        "section-functional": {
            "title": "Chức năng",
            "paragraph": "Cookie chức năng giúp chúng tôi cung cấp các tính năng có ích trên trang, ghi nhớ tùy chọn của bạn và hiển thị nội dung phù hợp. Các bên thứ ba được chấp thuận có thể thiết lập các cookie này để cung cấp một số tính năng trên site. Nếu bạn không cho phép các cookie này thì một số hoặc toàn bộ các dịch vụ này có thể không hoạt động đúng cách.",
            "checkbox-description": "Cho phép hạng mục chức năng"
        },
        "section-advertising": {
            "title": "Quảng cáo",
            "paragraph": "Chúng tôi hoặc các đối tác quảng cáo của chúng tôi có thể thiết lập cookie quảng cáo thông qua trang của chúng tôi. Các cookie này giúp chúng tôi phân phối nội dung tiếp thị phù hợp. Nếu bạn không cho phép các cookie này, bạn sẽ nhận được quảng cáo ít phù hợp hơn.",
            "checkbox-description": "Cho phép hạng mục quảng cáo"
        }
    }
  },
  "zh-cn": {
    "consentBanner": {
        "title": "选择您的 Cookie 首选项",
        "paragraph": "我们使用 Cookie 和类似工具来改善您的用户体验，提供我们的服务，推送针对性广告，以及做出改进。经批准的第三方也可使用这些工具来帮助我们投放广告并提供某些站点功能。",
        "button-customize": "自定义",
        "button-accept": "接受全部",
        "button-customize-aria-label": "自定义 Cookie 首选项",
        "button-accept-aria-label": "接受所有 Cookie"
    },
    "consentSelector": {
        "header": "自定义 Cookie 首选项",
        "intro": "Cookie 及类似工具(统称为“Cookie”)的用途包括以下几种。",
        "checkbox-label": "允许",
        "button-cancel": "取消",
        "button-save": "保存首选项",
        "button-cancel-aria-label": "取消自定义 Cookie 首选项",
        "button-save-aria-label": "保存自定义的 Cookie 首选项",
        "footer": <span>阻止某些类型的 Cookie 的话，可能会影响到您的网站体验。您可以随时单击此网站页脚中的 Cookie 首选项来对您的 Cookie 首选项进行更改。要了解有关我们及经批准的第三方如何在网站上使用 Cookie 的更多信息，请阅读 <a data-id='awsccc-cs-f-notice' href={COOKIE_LINK} target='_blank' rel='noopener noreferrer' title='在新窗口中打开'><span>AWS Cookie 声明。<OpenIcon size='10px' /></span></a></span>,
        "section-essential": {
            "title": "关键",
            "paragraph": "关键 Cookie 对我们提供网站和服务来说绝对必要，不可将其禁用。关键 Cookie 通常是根据您在网站上的操作(例如，设置您的隐私首选项，登录或填写表格)来设置的。",
            "checkbox-description": "允许关键类别"
        },
        "section-performance": {
            "title": "性能",
            "paragraph": "性能 Cookie 可为我们提供有关客户使用网站情况的匿名统计信息，以便我们改善用户的网站体验及网站性能。经批准的第三方可为我们执行分析，但不可将数据用于其自身目的。",
            "checkbox-description": "允许性能类别"
        },
        "section-functional": {
            "title": "功能",
            "paragraph": "功能 Cookie 有助于我们提供有用的网站功能，记住您的首选项及显示有针对性的内容。经批准的第三方可对功能 Cookie 进行设置以提供某些网站功能。如果您不允许功能 Cookie，则某些或所有这些服务可能无法正常提供。",
            "checkbox-description": "允许功能类别"
        },
        "section-advertising": {
            "title": "广告",
            "paragraph": "广告 Cookie 可由我们或我们的广告合作伙伴通过我们的网站进行设置，有助于我们推送有针对性的营销内容。如果您不允许广告 Cookie，则您所接收到的广告的针对性将会有所降低。",
            "checkbox-description": "允许广告类别"
        }
    }
  },
  "zh-tw": {
    "consentBanner": {
        "title": "選取您的 Cookie 偏好設定",
        "paragraph": "我們使用 Cookie 和類似工具來增強您的體驗、提供我們的服務、提供相關廣告，以及進行改善。獲核准的第三方也會使用這些工具協助我們提供廣告和特定網站功能。",
        "button-customize": "自訂",
        "button-accept": "全部接受",
        "button-customize-aria-label": "自訂 Cookie 偏好設定",
        "button-accept-aria-label": "接受所有 Cookie"
    },
    "consentSelector": {
        "header": "自訂 Cookie 偏好設定",
        "intro": "我們會將 Cookie 和類似工具 (統稱為 \"Cookie\") 用於以下目的。",
        "checkbox-label": "已允許",
        "button-cancel": "取消",
        "button-save": "儲存偏好設定",
        "button-cancel-aria-label": "取消自訂 Cookie 偏好設定",
        "button-save-aria-label": "儲存自訂的 Cookie 偏好設定",
        "footer": <span>封鎖部分類型的 Cookie 可能會影響您在使用我們的網站時的體驗。您可以隨時在本網站頁尾按一下「Cookie 偏好設定」來變更您的 Cookie 偏好設定。若要進一步了解我們和獲核准的第三方如何在我們的網站上使用 Cookie，請閱讀我們的 <a data-id='awsccc-cs-f-notice' href={COOKIE_LINK} target='_blank' rel='noopener noreferrer' title='(在新視窗中開啟) '><span>AWS Cookie 通知。<OpenIcon size='10px' /></span></a></span>,
        "section-essential": {
            "title": "必要",
            "paragraph": "必要 Cookie 對於我們所提供的網站和服務而是必要的，而且無法停用。它們的設定通常是對您在網站上的動作的回應，例如，設定您的隱私偏好、登入或填寫表單。",
            "checkbox-description": "允許必要類別"
        },
        "section-performance": {
            "title": "效能",
            "paragraph": "效能 Cookie 提供有關客戶如何瀏覽我們網站的匿名統計資料，以便我們改善網站體驗和效能。獲核准的第三方可代表我們執行分析，但他們無法將資料用於自己的用途。",
            "checkbox-description": "允許效能類別"
        },
        "section-functional": {
            "title": "功能",
            "paragraph": "功能 Cookie 可協助我們提供實用的網站功能、記住您的偏好設定，以及顯示相關內容，獲核准的第三方可能會設定這些 Cookie 以提供特定網站功能。若您不允許這些 Cookie，則部分或全部服務可能無法正常運作。",
            "checkbox-description": "允許功能類別"
        },
        "section-advertising": {
            "title": "廣告",
            "paragraph": "我們或我們的廣告合作夥伴可以透過網站對廣告 Cookie 進行設定，協助我們提供相關的行銷內容。若您不允許這些 Cookie，您將看到相關程度較低的廣告。",
            "checkbox-description": "允許廣告類別"
      }
    }
  }
}
