import { Container, Header, Textarea, TextContent } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { ChallengePropAction, useCreateChallenge } from '../../../../store/create-challenge.context';
import { Challenge, ChallengeReviewableSection } from '../../../../types/Challenge';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { safeString } from '../../../../utils/string.utils';
import { ChallengeReviewPopover } from '../../challengesCommon/ChallengeReviewPopover';

interface ChallengeWikiDetailProps {
  challenge: Challenge;
}

const ChallengeWikiDetail: React.FC<ChallengeWikiDetailProps> = ({ challenge }) => {
  const { t } = useTranslation();
  const { editMode, editedChallenge, handleUpdateChallengeProp } = useCreateChallenge();

  return (
    <Container
      header={
        <Header variant="h2" actions={<ChallengeReviewPopover section={ChallengeReviewableSection.WIKI} />}>
          {t(i18nKeys.challenges.challengeDetails.headings.wiki)}
        </Header>
      }>
      <TextContent>
        {!editMode && challenge.props.wiki && (
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{challenge.props.wiki}</ReactMarkdown>
        )}
        {!editMode && !challenge.props.wiki && t(i18nKeys.challenges.challengeDetails.field.wiki.emptyState)}
        {editMode && (
          <Textarea
            onChange={({ detail }) => handleUpdateChallengeProp(ChallengePropAction.WIKI, detail.value)}
            value={editedChallenge?.props.wiki || ''}
          />
        )}
      </TextContent>
    </Container>
  );
};

export default ChallengeWikiDetail;
