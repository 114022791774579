import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Spinner } from '@amzn/awsui-components-react';
import { ReactNode } from 'react';
import { i18nKeys } from '../../utils/i18n.utils';

export type StatusIndicatorStatus =
  | 'error'
  | 'warning'
  | 'success'
  | 'info'
  | 'stopped'
  | 'pending'
  | 'in-progress'
  | 'loading';

interface StatusIndicatorProps {
  status: StatusIndicatorStatus;
  label?: ReactNode;
}

export const StatusIndicator: React.FC<StatusIndicatorProps> = ({ status, label }) => {
  const { t } = useTranslation();

  switch (status) {
    case 'error':
      return (
        <span className="awsui-util-status-negative">
          <Icon name="status-negative" /> {label || t(i18nKeys.general.error)}
        </span>
      );
    case 'warning':
      return (
        <span className="awsui-util-status-negative">
          <Icon name="status-warning" /> {label || t(i18nKeys.general.warning)}
        </span>
      );
    case 'success':
      return (
        <span className="awsui-util-status-positive">
          <Icon name="status-positive" /> {label || t(i18nKeys.general.success)}
        </span>
      );
    case 'info':
      return (
        <span className="awsui-util-status-info">
          <Icon name="status-info" /> {label || t(i18nKeys.general.info)}
        </span>
      );
    case 'stopped':
      return (
        <span className="awsui-util-status-inactive">
          <Icon name="status-stopped" /> {label || t(i18nKeys.general.stopped)}
        </span>
      );
    case 'pending':
      return (
        <span className="awsui-util-status-inactive">
          <Icon name="status-pending" /> {label || t(i18nKeys.general.pending)}
        </span>
      );
    case 'in-progress':
      return (
        <span className="awsui-util-status-inactive">
          <Icon name="status-in-progress" /> {label || t(i18nKeys.general.inProgress)}
        </span>
      );
    case 'loading':
      return (
        <span className="awsui-util-status-inactive inline-header">
          <Spinner />
          {label || t(i18nKeys.general.loading)}
        </span>
      );
  }
};
