import * as React from 'react';
import { useApi } from '../../store/api.context';
import { Nullable } from '../../types/common';
import { TermsAndConditionsStatus } from '../../types/TermsAndConditionsStatus';
import { useComponentDidMountEffect } from '../../hooks/useComponentDidMountEffect';
import { TermsAndConditionsModal } from './TermsAndConditionsModal';
import { Spinner } from '@amzn/awsui-components-react';
import { useState } from 'react';
import { preProdLogger } from '../../utils/log.utils';

/**
 * Component to show the enclosed content if the user has accepted the terms and conditions,
 * or hide it and show a terms and conditions modal otherwise.
 */
export const TermsAndConditionsGuard: React.FC = (props) => {
  const { accountApi } = useApi();

  /* The user's terms and conditions status. */
  const [termsStatus, setTermsStatus] = useState<Nullable<TermsAndConditionsStatus>>(null);

  useComponentDidMountEffect(async () => {
    setTermsStatus(await accountApi.getConsoleUserTermStatus());
  });

  const afterAcceptTerms = () => {
    accountApi
      .getConsoleUserTermStatus()
      .then((status) => setTermsStatus(status))
      .catch((err) => preProdLogger(err));
  };

  /* Show a spinner if the terms and conditions have not yet been loaded so that the content behind this guard is not displayed
     before the user accepts the terms and conditions. */
  if (termsStatus == null) {
    return <Spinner size="normal" />;
  }

  return termsStatus.forceAgreeTermsAndConditions ? (
    <TermsAndConditionsModal termsStatus={termsStatus} afterAcceptTerms={afterAcceptTerms} />
  ) : (
    <React.Fragment>{props.children}</React.Fragment>
  );
};
