import { useCollection } from '@amzn/awsui-collection-hooks';
import { Table } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useComponentDidMountEffect } from '../../../../../hooks/useComponentDidMountEffect';
import { useApi } from '../../../../../store/api.context';
import { ChallengeReview } from '../../../../../types/Challenge';
import { i18nKeys } from '../../../../../utils/i18n.utils';
import { safeString } from '../../../../../utils/string.utils';
import { TableHeader } from '../../../../common/TableHeader';
import { useChallenges } from '../../../../../store/challenge.context';
import { COLUMN_DEFINITIONS } from './revisions-needed-list-config';
import { TableEmptyState } from '../../../../common/TableEmptyState';

const RevisionsNeeded: React.FC = () => {
  const { t } = useTranslation();
  const { challengesApi } = useApi();
  const { challenge, setCurrentReview } = useChallenges();

  const [reviews, setReviews] = useState<ChallengeReview[]>([]);
  const challengeId = safeString(challenge?.challengeId);

  useComponentDidMountEffect(async () => {
    const response = await challengesApi.getLatestReviews(safeString(challenge?.challengeId));
    setReviews(response.reviews || []);
  });

  const { items, collectionProps } = useCollection(reviews || [], {
    filtering: {
      empty: (
        <TableEmptyState
          title={t(i18nKeys.challenges.challengeDetails.table.emptyReviewsState)}
          subtitle={t(i18nKeys.challenges.challengeDetails.table.emptyReviewsSubtitle)}
        />
      ),
    },
  });

  return (
    <React.Fragment>
      <Table
        {...collectionProps}
        resizableColumns
        header={
          <TableHeader
            totalItems={reviews ? reviews.length : 0}
            title={t(i18nKeys.challenges.challengeDetails.headings.revisionsNeeded)}
          />
        }
        columnDefinitions={COLUMN_DEFINITIONS(challengeId, setCurrentReview)}
        items={items}
      />
    </React.Fragment>
  );
};

export default RevisionsNeeded;
