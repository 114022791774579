import { CreateLSERequest, LSEMessage, LSEResponse, UpdateLSERequest } from '../types/Lse';
import { fromPlainObject } from '../utils/mapper.utils';
import { ApiClient } from './ApiClient';

export class LseAPI {
  private static readonly LSE_RESOURCE = '/admin/lse';
  // eslint-disable-next-line no-empty-function
  constructor(private apiClient: ApiClient) {}

  public async getLSEMessage(): Promise<LSEMessage | undefined> {
    return (await this.apiClient.get({
      path: LseAPI.LSE_RESOURCE,
      silent: true,
      responseMapper: (res) => fromPlainObject(res.message, LSEMessage),
    })) as Promise<LSEMessage | undefined>;
  }

  public async createLSE(message: LSEMessage, silent = false): Promise<LSEMessage> {
    const request: CreateLSERequest = {
      message,
    };
    return (await this.apiClient.post({
      path: LseAPI.LSE_RESOURCE,
      body: request,
      silent,
      responseMapper: (res) => fromPlainObject(res, LSEResponse),
    })) as Promise<LSEMessage>;
  }

  public async updateLSE(message: LSEMessage, silent = false): Promise<LSEMessage> {
    const request: UpdateLSERequest = {
      message,
    };
    return (await this.apiClient.patch({
      path: LseAPI.LSE_RESOURCE,
      body: request,
      silent,
      responseMapper: (res) => fromPlainObject(res, LSEResponse),
    })) as Promise<LSEMessage>;
  }

  public async deactivateLSE(silent = false): Promise<void> {
    return (await this.apiClient.delete({
      path: LseAPI.LSE_RESOURCE,
      silent,
    })) as void;
  }
}
