import { AwsRegions, Region } from '@amzn/aws-jam-constants';
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import * as common from './common';
import { LabProvider } from './LabProvider';

/**
 * Map of candidateString -> shutoff status of the candidate
 */
export interface CandidateStatusMap {
  [candidateString: string]: LabShutoffStatus;
}

export enum LabShutoffActionType {
  TURNON = 'TURNON',
  SHUTOFF = 'SHUTOFF',
}

@jsonObject
export class LabShutoffCandidate {
  /**
   * Separator for LabShutoffCandidate components, i.e. event:challenge:region:labProvider
   */
  private static readonly SEPARATOR = ':';

  /**
   * String to signify a component is a wildcard, i.e. event:*:region:labProvider.
   * Null, undefined, or empty components are mapped to a wildcard.
   */
  private static readonly WILDCARD = '*';

  // every parameter defaults to null so every new candidate defaults to *:*:*:*
  @jsonMember(common.NullableStringValue)
  eventName: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  challengeId: common.NullableString = null;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  @jsonMember(common.NullableClassValue(Region))
  region: common.Nullable<Region> = null;

  @jsonMember(common.NullableStringValue)
  labProvider: common.Nullable<LabProvider> = null;

  static ofPlatform(): LabShutoffCandidate {
    const candidate: LabShutoffCandidate = new LabShutoffCandidate();

    return candidate;
  }

  static ofEvent(eventName: string): LabShutoffCandidate {
    const candidate: LabShutoffCandidate = new LabShutoffCandidate();
    candidate.eventName = eventName;

    return candidate;
  }

  static ofChallenge(challengeId: string): LabShutoffCandidate {
    const candidate: LabShutoffCandidate = new LabShutoffCandidate();
    candidate.challengeId = challengeId;

    return candidate;
  }

  static ofEventChallengePair(eventName: common.NullableString, challengeId: string): LabShutoffCandidate {
    const candidate: LabShutoffCandidate = new LabShutoffCandidate();

    candidate.eventName = eventName;
    candidate.challengeId = challengeId;

    return candidate;
  }

  /**
   * Create a LabShutoffCandidate targetting a specific region.
   *
   * @param region
   */
  static ofRegion(region: Region): LabShutoffCandidate {
    const candidate: LabShutoffCandidate = new LabShutoffCandidate();
    candidate.region = region;

    return candidate;
  }

  /**
   * Create a LabShutoffCandidate targetting a specific lab provider.
   *
   * @param region
   */
  static ofLabProvider(labProvider: LabProvider): LabShutoffCandidate {
    const candidate: LabShutoffCandidate = new LabShutoffCandidate();
    candidate.labProvider = labProvider;

    return candidate;
  }

  /**
   * Create a LabShutoffCandidate from all components.
   *
   * @param eventName
   * @param challengeId
   * @param region
   * @param labProvider
   */
  static of(
    eventName: common.NullableString,
    challengeId: common.NullableString,
    region: common.Nullable<Region>,
    labProvider: common.Nullable<LabProvider>
  ): LabShutoffCandidate {
    const candidate: LabShutoffCandidate = new LabShutoffCandidate();
    candidate.eventName = eventName ?? null;
    candidate.challengeId = challengeId ?? null;
    candidate.region = region ?? null;
    candidate.labProvider = labProvider ?? null;

    return candidate;
  }

  /**
   * Map string -> LabShutoffCandidate
   * If the provided candidateString is malformed, undefined is returned.
   */
  static fromCandidateString(candidateString?: string): LabShutoffCandidate | undefined {
    if (!candidateString) {
      return undefined;
    }

    let [eventName, challengeId, regionId, labProviderString]: common.NullableString[] = candidateString.split(
      LabShutoffCandidate.SEPARATOR
    );

    // for backwards compatibility, allow any component to be null/undefined;
    // i.e. there may exist candidates like event:challenge that should still
    // be interpreted correctly

    if (eventName === LabShutoffCandidate.WILDCARD || !eventName) {
      eventName = null;
    }

    if (challengeId === LabShutoffCandidate.WILDCARD || !challengeId) {
      challengeId = null;
    }

    if (regionId === LabShutoffCandidate.WILDCARD || !regionId) {
      regionId = null;
    }

    if (labProviderString === LabShutoffCandidate.WILDCARD || !labProviderString) {
      labProviderString = null;
    }

    const region: common.Nullable<Region> = regionId ? AwsRegions.ALL_AWS_REGIONS_BY_ID[regionId] : null;
    const labProvider: common.Nullable<LabProvider> = labProviderString ? LabProvider[labProviderString as LabProvider] : null;

    return LabShutoffCandidate.of(eventName, challengeId, region, labProvider);
  }

  public toCandidateString(): common.NullableString {
    // join event:challenge:region:labProvider, falling back to wildcard for each component that's null, undefined, or empty
    return [this.eventName, this.challengeId, this.region?.id, this.labProvider]
      .map((component?: string | null) => component || LabShutoffCandidate.WILDCARD)
      .join(LabShutoffCandidate.SEPARATOR);
  }

  /**
   * Determine whether this LabShutoffCandidate refers to a challenge platform-wide.
   */
  public get isChallengeOnly(): boolean {
    return !!(!this.eventName && this.challengeId && !this.region && !this.labProvider);
  }

  /**
   * Determine whether this LabShutoffCandidate refers to a single event only.
   */
  public get isEventOnly(): boolean {
    return !!(this.eventName && !this.challengeId && !this.region && !this.labProvider);
  }

  /**
   * Determine whether this LabShutoffCandidate refers to an event/challenge pair.
   */
  public get isEventChallenge(): boolean {
    return !!(this.eventName && this.challengeId && !this.region && !this.labProvider);
  }

  /**
   * Determine whether this LabShutoffCandidate refers to a single region only.
   */
  public get isRegionOnly(): boolean {
    return !!(!this.eventName && !this.challengeId && this.region && !this.labProvider);
  }

  /**
   * Determine whether this LabShutoffCandidate refers to a single labProvider only.
   */
  public get isLabProviderOnly(): boolean {
    return !!(this.labProvider && !this.eventName && !this.challengeId && !this.region);
  }

  /**
   * Determine whether this LabShutoffCandidate refers to the platform (for platform-wide shutoffs).
   */
  public get isPlatform(): boolean {
    return !this.eventName && !this.challengeId && !this.region && !this.labProvider;
  }
}

@jsonObject
export class LabShutoffAction {
  // properties returned by controller
  @jsonMember(common.NullableStringValue)
  candidate: common.NullableString = null;

  @jsonMember(common.NullableTimeStampValue)
  timeCreated: common.NullableTimeStamp = null; // serialized from java.util.Date

  @jsonMember(common.NullableStringValue)
  createdByAlias: common.NullableString = null;

  @jsonMember(common.NullableBooleanValue)
  activeShutoff: common.NullableBoolean = null;

  @jsonMember(common.NullableStringValue)
  actionType: common.Nullable<LabShutoffActionType> = null;

  // computed properties
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  @jsonMember(common.NullableClassValue(LabShutoffCandidate))
  shutoffCandidate?: common.Nullable<LabShutoffCandidate> = null;
}

@jsonObject
export class LabShutoffStatus {
  // all of these values are provided from the backend and should not be computed

  /**
   * Whether this status's candidate has a shutoff at its granularity.
   */
  @jsonMember(Boolean)
  selfShutoff = false;
  /**
   * Whether this status's candidate has a shutoff from a parent.
   */
  @jsonMember(Boolean)
  inheritedShutoff = false;
  /**
   * Whether this status's candidate has a self or inherited shutoff. Same as selfShutoff || inheritedShutoff.
   */
  @jsonMember(Boolean)
  hasCompleteShutoff = false;
  /**
   * Whether this status's candidate has at least one region shutoff at any granularity.
   */
  @jsonMember(Boolean)
  hasRegionShutoff = false;
  /**
   * Whether this status's candidate has anything shutoff: self shutoff, inherited shutoff, or at least one region shutoff.
   */
  @jsonMember(Boolean)
  hasAnyShutoff = false;
  /**
   * List of regions that are shutoff at this status's candidate's granularity.
   */
  @jsonArrayMember(common.NullableStringValue)
  selfShutoffRegions?: common.NullableString[] = undefined;
  /**
   * List of regions that are shutoff at a parent candidate's granularity.
   */
  @jsonArrayMember(common.NullableStringValue)
  inheritedShutoffRegions?: common.NullableString[] = undefined;
}

// Lab Shutoff Request Objects

export interface CreateLabShutoffActionRequest {
  readonly candidateString: string;
  readonly reason: string;
}

export interface GetLabShutoffStatusRequest {
  readonly candidateStrings: string[];
}

// Lab Shutoff Response Objects
@jsonObject
export class CreateLabShutoffActionResponse {
  @jsonMember(LabShutoffAction)
  newShutoffAction: LabShutoffAction = new LabShutoffAction();
}

@jsonObject
export class GetLabShutoffStatusResponse {
  // properties returned by the controller
  @jsonMember(Object)
  statuses: CandidateStatusMap = {};

  // computed properties

  /**
   * Map of candidateString -> a LabShutoffCandidate object represented by the candidateString
   */
  @jsonMember(Object)
  candidates: { [candidateString: string]: LabShutoffCandidate | undefined } = {};
}

@jsonObject
export class GetActiveLabShutoffsResponse {
  // returned by the controller
  @jsonArrayMember(String)
  activeShutoffCandidates: string[] = []; // list of active shutoffs as candidate strings

  // computed
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  @jsonArrayMember(common.OfClassOrUndefined(LabShutoffCandidate))
  candidates: (LabShutoffCandidate | undefined)[] = []; // activeShutoffCandidates as candidates
}

/**
 * Response to getting a list of LabShutoffActions by date range, with no guarantees
 * about the ordering of ShutoffActions.
 */
@jsonObject
export class GetLabShutoffAuditTrailResponse {
  // returned by the controller, list of LabShutoffActions in the provided date range
  @jsonArrayMember(LabShutoffAction)
  shutoffActions: LabShutoffAction[] = [];
}
