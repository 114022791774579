import React from 'react';
import { getTableFilterMatcher, TableFilterMatcher } from '../../utils/table.utils';
import { Badge, CollectionPreferencesProps, SpaceBetween } from '@amzn/awsui-components-react';
import { Campaign } from '../../types/Campaign';
import { StatusBadge } from '../common/StatusBadge';
import { toTitleCase } from '../../utils/string.utils';
import { getFormattedDate } from '../../utils/time.utils';
import TimeAgo from 'timeago-react';
import { CopyToClipboard } from '../common/CopyToClipboard';
import TPopover from '../common/TPopover';
import { CAMPAIGN_DETAILS_ROUTES } from '../../routes';
import { i18nKeys } from '../../utils/i18n.utils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const filteringFunction = (item: Campaign, filteringText: string): boolean => {
  const matcher: TableFilterMatcher = getTableFilterMatcher(filteringText);
  return (
    matcher.filterEmpty ||
    matcher.isMatch(item.title) ||
    matcher.isMatch(item.status) ||
    matcher.isMatch(new Date(item.createdDate!).toString()) ||
    matcher.isMatch(item.requestedBy) ||
    matcher.isMatch(item.lastUpdatedBy) ||
    matcher.isMatch(new Date(item.lastUpdatedDate!).toString())
  );
};

export const COLUMN_DEFINITIONS = (preferences: CollectionPreferencesProps.Preferences) => {
  const { t, i18n } = useTranslation();
  return [
    {
      id: 'title',
      sortingField: 'title',
      header: t(i18nKeys.campaigns.headers.title),
      cell: (item: Campaign) => {
        return (
          <React.Fragment>
            {item.id && <Link to={CAMPAIGN_DETAILS_ROUTES.Summary.resolve(item.id)}>{item?.title}</Link>}
            {item?.title !== item?.slug && <div className="secondary-text">{item?.slug}</div>}
          </React.Fragment>
        );
      },
      minWidth: 260,
      allowLineWrap: true,
    },
    {
      id: 'tags',
      sortingField: 'tags',
      header: t(i18nKeys.campaigns.headers.tags),
      cell: (item: Campaign) => {
        return (
          <React.Fragment>
            {item.tags?.length < 2 && <Badge>{item.tags[0]}</Badge>}
            {item.tags?.length > 1 && (
              <TPopover
                headerKey="Tags"
                content={
                  <SpaceBetween direction="vertical" size="s">
                    {item.tags.map((tag, i) => (
                      <div key={`tag-${i}`}>
                        <Badge>{tag}</Badge>
                      </div>
                    ))}
                  </SpaceBetween>
                }>
                {t(i18nKeys.campaigns.labels.tags, { tagCount: item.tags.length })}
              </TPopover>
            )}
          </React.Fragment>
        );
      },
      minWidth: 140,
    },
    {
      id: 'type',
      sortingField: 'type',
      header: t(i18nKeys.campaigns.headers.type),
      minWidth: 64,
      cell: (item: Campaign) => toTitleCase(item.type),
    },
    {
      id: 'status',
      sortingField: 'status',
      header: t(i18nKeys.campaigns.headers.status),
      cell: (item: Campaign) => {
        return <StatusBadge status={item.status} />;
      },
      minWidth: 164,
      allowLineWrap: false,
    },
    {
      id: 'time-created',
      sortingField: 'createdDate',
      header: t(i18nKeys.campaigns.headers.timeCreated),
      cell: (item: Campaign) =>
        item.createdDate && (
          <React.Fragment>
            <div>{getFormattedDate(new Date(item.createdDate), i18n.language)}</div>
            <div>{<TimeAgo datetime={item.createdDate} />}</div>
          </React.Fragment>
        ),
      minWidth: 150,
      allowLineWrap: false,
    },
    {
      id: 'created-by',
      sortingField: 'requestedBy',
      header: t(i18nKeys.campaigns.headers.createdBy),
      cell: (item: Campaign) =>
        item.requestedBy && (
          <React.Fragment>
            <span>{item.requestedBy}</span>
            <CopyToClipboard value={item.requestedBy} icon />
          </React.Fragment>
        ),
      minWidth: 262,
      allowLineWrap: false,
    },
    {
      id: 'last-updated',
      sortingField: 'lastUpdatedDate',
      header: t(i18nKeys.campaigns.headers.lastUpdated),
      cell: (item: Campaign) =>
        item.lastUpdatedDate && (
          <React.Fragment>
            <div>{getFormattedDate(new Date(item.lastUpdatedDate), i18n.language)}</div>
            <div>{<TimeAgo datetime={item.lastUpdatedDate} />}</div>
          </React.Fragment>
        ),
      minWidth: 150,
      allowLineWrap: false,
    },
    {
      id: 'last-updated-by',
      sortingField: 'lastUpdatedBy',
      header: t(i18nKeys.campaigns.headers.lastUpdatedBy),
      cell: (item: Campaign) =>
        item.lastUpdatedBy && (
          <React.Fragment>
            <span>{item.lastUpdatedBy}</span>
            <CopyToClipboard value={item.lastUpdatedBy} icon />
          </React.Fragment>
        ),
      minWidth: 262,
      allowLineWrap: false,
    },
  ];
};
