import * as React from 'react';
import { Challenge, ChallengeStatus } from '../../../types/Challenge';
import { StatusIndicator } from '../../common/StatusIndicator';

interface ChallengeStatusIndicatorProps {
  challenge: Challenge | undefined;
  subtitle: string;
  showVersion: boolean;
}

const ChallengeStatusIndicator: React.FC<ChallengeStatusIndicatorProps> = ({ challenge, subtitle, showVersion }) => {
  const getStatus = (): ChallengeStatus => {
    if (challenge && challenge.status) {
      if (challenge.isArchived) {
        return 'ARCHIVED' as ChallengeStatus;
      }
      return challenge.status;
    }
    return ChallengeStatus.DRAFT;
  };

  const getVersion = () => {
    if (showVersion && challenge && !challenge.isArchived) {
      return `${challenge.majorVersion || 0}.${challenge.minorVersion || 0}`;
    }
    return '';
  };

  return (
    <div>
      {getStatus() !== ChallengeStatus.APPROVED && !showVersion && (
        <StatusIndicator status={'in-progress'} label={challenge?.status} />
      )}
      {getStatus() === ChallengeStatus.APPROVED && !showVersion && (
        <StatusIndicator status={'success'} label={challenge?.status} />
      )}
      {getStatus() !== ChallengeStatus.APPROVED && showVersion && (
        <StatusIndicator status={'in-progress'} label={`${getVersion()}-${challenge?.status}`} />
      )}
      {getStatus() === ChallengeStatus.APPROVED && showVersion && (
        <StatusIndicator status={'success'} label={`${getVersion()}-${challenge?.status}`} />
      )}
    </div>
  );
};

export default ChallengeStatusIndicator;
