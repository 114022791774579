import { getEnvVar } from '../utils/env-var.utils';

export interface ApiConfig {
  readonly origin: string;
}

export interface CognitoConfig {
  readonly clientId: string;
  readonly userPoolId: string;
  readonly gandalfIdpName: string;
  readonly gandalfOtpIdpName: string;
  readonly amazonFederateIdpName: string;
  readonly appWebDomain: string;
}

export interface AuthGroupNames {
  readonly generalAccess: string;
  readonly eventAdmin: string;
  readonly challengeAdmin: string;
  readonly campaignAdmin: string;
}

export interface DomainConfig {
  readonly gameUI: string;
}

export interface AppConfig {
  readonly api: ApiConfig;
  readonly cognito: CognitoConfig;
  readonly domains: DomainConfig;
  readonly warningBannerMessage: string;
  readonly challengeResourcesBucketName: string;
  readonly stageName: string;
  readonly useHybridExperience: boolean;
  readonly isUsagePlanEnabled: boolean;
  readonly isChallengeReviewEnabled: boolean;
  readonly isNewTopNavigationEnabled: boolean;
}

const localBackendOrigin = 'http://localhost:3001';

/**
 * Create a new app config by inspecting the environment.
 */
export const newAppConfig = (): AppConfig => {
  const useLocalBackend = process.env.REACT_APP_LOCAL_BACKEND === 'true';
  return {
    api: {
      origin: useLocalBackend ? /* istanbul ignore next */ localBackendOrigin : getEnvVar('REACT_APP_API_ORIGIN'),
    },
    cognito: {
      clientId: getEnvVar('REACT_APP_COGNITO_CLIENT_ID'),
      userPoolId: getEnvVar('REACT_APP_COGNITO_USER_POOL_ID'),
      gandalfIdpName: getEnvVar('REACT_APP_COGNITO_GANDALF_IDP_NAME'),
      gandalfOtpIdpName: getEnvVar('REACT_APP_COGNITO_GANDALF_OTP_IDP_NAME'),
      amazonFederateIdpName: getEnvVar('REACT_APP_COGNITO_AMAZON_FEDERATE_IDP_NAME'),
      appWebDomain: getEnvVar('REACT_APP_COGNITO_APP_WEB_DOMAIN'),
    },
    domains: {
      gameUI: getEnvVar('REACT_APP_GAME_UI_DOMAIN'),
    },
    warningBannerMessage: getEnvVar('REACT_APP_WARNING_BANNER_MESSAGE', ''),
    challengeResourcesBucketName: getEnvVar('REACT_APP_CHALLENGE_RESOURCES_BUCKET_NAME'),
    stageName: getEnvVar('REACT_APP_STAGE_NAME'),
    useHybridExperience: getEnvVar('REACT_APP_USE_HYBRID_EXPERIENCE') === 'true',
    isUsagePlanEnabled: getEnvVar('REACT_APP_IS_USAGE_PLAN_ENABLED') === 'true',
    isChallengeReviewEnabled: getEnvVar('REACT_APP_IS_CHALLENGE_REVIEW_ENABLED') === 'true',
    isNewTopNavigationEnabled: getEnvVar('REACT_APP_IS_NEW_TOP_NAVIGATION_ENABLED') === 'true',
  };
};
