import React, { useCallback, useMemo } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonDropdownProps} from '@amzn/awsui-components-react';

// context
import { useUser } from '@/src/store/user.context';
import { useAuth } from '@/src/store/auth.context';

// utils
import { getLanguageCodeSafe, getLocaleLabel, LANGUAGE_SELECT_OPTIONS } from '@/src/utils/locale.utils';
import { i18nKeys } from '@/src/utils/i18n.utils';
import { preProdLogger } from '@/src/utils/log.utils';
import { getNavMenuForUser } from '@/src/utils/navMenu.utils';
import { LoggingService } from '@/src/utils/logging-service.utils';

// ui components
import { Header as HeaderUI } from '@/src/components/ui/organisms/Header'

// constants
import { DARK_MODE_CLASS, DARK_MODE_KEY, LOGO_SVG } from '@/src/constants/common';

// types
import type { CancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';
import type { ThemeMode } from '@/src/components/ui/molecules/ThemeToggle';

const getCurrentTheme = ():ThemeMode => {
  if (localStorage.getItem(DARK_MODE_KEY) === 'yes') {
    return 'dark'
  }
  return 'light'
}
export const NewHeader: React.FC = () => {
  const { i18n, t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(getLanguageCodeSafe(i18n.language));
  const [ currTheme, setCurrTheme ] = useState<ThemeMode>(getCurrentTheme())

  const { user } = useUser();
  const { authClient } = useAuth();

  const handleThemeToggle = (theme: ThemeMode) => {
    setCurrTheme(theme);
  };

  useEffect(() => {
    if (currTheme === 'dark') {
      document.body.classList.add(DARK_MODE_CLASS);
      localStorage.setItem(DARK_MODE_KEY, 'yes');
    } else {
      document.body.classList.remove(DARK_MODE_CLASS);
      localStorage.removeItem(DARK_MODE_KEY);
    }
  }, [currTheme])


  const handleLangaugeSelect = useCallback((code: string) => {
    void i18n.changeLanguage(code);
    setSelectedLanguage(code)
  }, [i18n])

  const langOptions = useMemo(() => {
    return {
      lang: {
        value: selectedLanguage,
        label: getLocaleLabel(selectedLanguage)
      },
      options: LANGUAGE_SELECT_OPTIONS,
      onLanguageSelect: handleLangaugeSelect
    }
  }, [selectedLanguage, handleLangaugeSelect])

  const userOptions = useMemo(() => {
    if (!user) return undefined

    return {
      name: user.name,
      email: user.email,
      menuItems: [
        { id:'user-profile', text: 'Profile', href: `/account` },
        // { id:'user-preferences', text: 'Preferences', href: '/preferences' },
        { id:'user-signout', text: 'Sign Out' }
      ]
    }
  }, [user])

  const handleMenuItemClick = useCallback(() => {
    // handleMenuItemClick
  }, [])

   const handleUserMenuItemClick:CancelableEventHandler<ButtonDropdownProps.ItemClickDetails> = useCallback((e) => {
    const id = e.detail.id
    switch (id) {
      case 'user-signout':
        authClient
          .signOut()
          .then(() => preProdLogger('sign out'))
          .catch((err) => preProdLogger(err));
        break;
    }
  }, [authClient]);

  const handleSignInClick = useCallback(() => {
    void authClient.signIn()
  }, [authClient])

  const navMenu = useMemo(() => {
    if (!user) return []
    return getNavMenuForUser(user)
  }, [user])

  return (
    <HeaderUI 
      logo={{
        title: i18nKeys.header.logo.title,
        href: '/',
        svg: LOGO_SVG
      }} 
      navItems={navMenu} 
      theme={{
        mode: currTheme,
        onToggleTheme: handleThemeToggle
      }}
      userDetails={userOptions}
      lang={langOptions} 
      onMenuItemClick={handleMenuItemClick}
      onUserMenuItemClick={handleUserMenuItemClick}
      onSignInClick={handleSignInClick}
    />
  );
};
