import { Alert, Grid, Header, SpaceBetween, Toggle } from '@amzn/awsui-components-react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChallenges } from '../../../store/challenge.context';
import { useEvents } from '../../../store/events.context';
import { useToolPanel } from '../../../store/tool-panel.context';
import { useUser } from '../../../store/user.context';
import { Campaign } from '../../../types/Campaign';
import {
  ChallengeDescriptor,
  ChallengeListItem,
  ChallengeWarningDictionary,
  ChallengeWarnings,
  getChallengePointsPossible,
} from '../../../types/Challenge';
import { ChallengeBoardPosition } from '../../../types/ChallengeBoard';
import { Nullable } from '../../../types/common';
import { Event } from '../../../types/Event';
import { i18nKeys } from '../../../utils/i18n.utils';
import { getDuration } from '../../../utils/time.utils';
import { HorizontalRule } from '../HorizontalRule';
import { LoadingBar } from '../LoadingBar';
import ChallengeCard from './ChallengeCard';

interface ChallengeSelectionProps {
  handleRemoveChallenge?: (challenges: ChallengeDescriptor[]) => void;
  toggleChallengeInfo: (challengeListItem: ChallengeListItem) => void;
  targetChallengeDescriptors: ChallengeDescriptor[];
  target: Event | Campaign;
}

const ChallengeSelection: React.FC<ChallengeSelectionProps> = ({
  handleRemoveChallenge,
  target,
  targetChallengeDescriptors,
  toggleChallengeInfo,
}) => {
  const {
    getChallengeListItemFromChallengeId,
    wrapperMapInitalized,
    copyChallengeDescriptorsFromTarget,
    challengeWrappers,
    getChallenges,
    getChallengeDescriptor,
    gameBoardPositions,
    updateGameBoardPositions,
  } = useChallenges();
  const { t } = useTranslation();
  const { loadPools } = useEvents();
  const { user } = useUser();
  const [selectedChallenges, setSelectedChallenges] = useState<Nullable<ChallengeListItem>[] | undefined>(undefined);
  const [maxTotalScore, setMaxTotalScore] = useState(0);
  const [estimatedTotalSolveTime, setEstimatedTotalSolveTime] = useState('0m');
  const [advancedMode, setAdvancedMode] = useState(false);

  const { toggleShowToolPanel } = useToolPanel();

  const isEvent = target instanceof Event;

  useEffect(() => {
    if (!challengeWrappers || challengeWrappers.length > 1) {
      void getChallenges(false, false, true);
    }
    if (user && target.isAdmin(user)) {
      void loadPools();
    }
    if (isEvent && target.challengeBoards) {
      const targetChallengeBoard = target.challengeBoards[0];
      const newGameBoardPositions: { [id: string]: Nullable<ChallengeBoardPosition> } = {};
      // initialize board positions
      (targetChallengeBoard?.challengeIds || []).forEach((challengeId) => {
        newGameBoardPositions[challengeId] = targetChallengeBoard.getBoardPosition(challengeId);
      });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      updateGameBoardPositions(newGameBoardPositions);
    }
  }, []);

  useEffect(() => {
    mapChallengeIdsToListItems();
  }, [wrapperMapInitalized]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    copyChallengeDescriptorsFromTarget(
      targetChallengeDescriptors,
      isEvent && target.challengeBoards ? target.challengeBoards[0] : undefined
    );
  }, [wrapperMapInitalized, challengeWrappers, gameBoardPositions]);

  useEffect(() => {
    getMaxTotalScore();
    getAvgTimeToSolveAllChallenges();

    return () => toggleShowToolPanel(false);
  }, [selectedChallenges]);

  useEffect(() => {
    mapChallengeIdsToListItems();
  }, [wrapperMapInitalized, targetChallengeDescriptors]);

  const mapChallengeIdsToListItems = () => {
    const challengeItemList: Nullable<ChallengeListItem>[] = [];
    targetChallengeDescriptors.forEach((challengeDescriptor) => {
      if (challengeDescriptor.challengeId !== null) {
        const newChallengeListItem = getChallengeListItemFromChallengeId(challengeDescriptor.challengeId);
        if (newChallengeListItem) {
          challengeItemList.push(newChallengeListItem);
        }
      }
    });
    setSelectedChallenges(challengeItemList);
  };

  const doesEventHaveChallenge = (challengeId: string): boolean => {
    return (selectedChallenges || []).some((c) => c?.challengeId === challengeId);
  };

  const getCurrentChallengePointsPossible = (challenge: Nullable<ChallengeListItem>): number => {
    if (!challenge || !challenge.challengeId) {
      return 0;
    }

    const cd: Nullable<ChallengeDescriptor> = doesEventHaveChallenge(challenge.challengeId)
      ? getChallengeDescriptor(challenge, isEvent && target.challengeBoards ? target.challengeBoards[0] : undefined)
      : ChallengeDescriptor.fromChallenge(challenge);
    if (cd) {
      return getChallengePointsPossible(target.getScoringSettings(), challenge, cd);
    } else {
      return 0;
    }
  };

  const getChallengeDefaultPointsPossible = (challenge: ChallengeListItem): number => {
    if (!challenge || !challenge.challengeId) {
      return 0;
    }
    const cd: Nullable<ChallengeDescriptor> = doesEventHaveChallenge(challenge.challengeId)
      ? getChallengeDescriptor(challenge, isEvent && target.challengeBoards ? target.challengeBoards[0] : undefined)
      : ChallengeDescriptor.fromChallenge(challenge);

    return cd ? getChallengePointsPossible(target.getScoringSettings(), challenge, cd, false) : 0;
  };

  const getMaxTotalScore = (): void => {
    setMaxTotalScore(
      (selectedChallenges || [])
        .map((challengeListItem: Nullable<ChallengeListItem>) => getCurrentChallengePointsPossible(challengeListItem))
        .reduce((total: number, score: number) => total + score, 0)
    );
  };

  const getAvgTimeToSolveAllChallenges = (): void => {
    setEstimatedTotalSolveTime(
      getDuration(
        (selectedChallenges || [])
          .map((challengeListItem) => {
            let avgSolveTimeInSeconds: number = challengeListItem?.globalStatistics.solveTimes.trimmedAvgSeconds || 0;
            if (!avgSolveTimeInSeconds) {
              avgSolveTimeInSeconds = 60 * 45; // 45 minutes as default
            }
            return avgSolveTimeInSeconds;
          })
          .reduce((total, score) => total + score, 0),
        false
      ) || '0m'
    );
  };

  const handleRemoveChallengeAction = (challengeId: string) => {
    if (handleRemoveChallenge) {
      const challengeToRemoveIndex = targetChallengeDescriptors.findIndex(
        (challengeDescriptor: ChallengeDescriptor) => challengeDescriptor.challengeId === challengeId
      );
      targetChallengeDescriptors.splice(challengeToRemoveIndex, 1);
      if (
        isEvent &&
        target.backupChallengeConfig?.perChallengeBackups &&
        target.backupChallengeConfig?.perChallengeBackups[challengeId].length > 0
      ) {
        target.backupChallengeConfig.removePerChallengeBackups(challengeId);
      }
      handleRemoveChallenge(_.clone(targetChallengeDescriptors));
    }
  };

  return (
    <div style={{ marginBottom: '24px' }}>
      <Header
        variant="h2"
        actions={
          <Toggle checked={advancedMode} onChange={() => setAdvancedMode(!advancedMode)}>
            {t(i18nKeys.challenges.challengeSelection.labels.advancedMode)}
          </Toggle>
        }>
        {t(i18nKeys.challenges.title)}
      </Header>
      {isEvent && (
        <Grid
          gridDefinition={[
            { colspan: Object.keys(target?.warnings.challengeWarnings).length < 1 ? 12 : 6 },
            { colspan: 6 },
            { colspan: 6 },
            { colspan: 6 },
          ]}>
          <Alert type="info" header={t(i18nKeys.challenges.challengeSelection.challengeOrder.header)}>
            {t(i18nKeys.challenges.challengeSelection.challengeOrder.description)}
          </Alert>
          {target?.warnings &&
            Object.keys(target.warnings.challengeWarnings).length > 0 &&
            Object.keys(target?.warnings.challengeWarnings).map((challengeWarning: string, index) => {
              return (
                <Alert type="warning" key={`challenge-warning-${index}`}>
                  {t(ChallengeWarningDictionary[challengeWarning as ChallengeWarnings], {
                    challengeIds: target.warnings.challengeWarnings[challengeWarning],
                  })}
                </Alert>
              );
            })}
        </Grid>
      )}
      <HorizontalRule />
      <div style={{ margin: '0 auto', width: '750px' }}>
        <h2
          style={{ display: 'inline-block', borderRight: '2px solid #16191F', paddingRight: '10px' }}
          className="inline-header">
          {t(i18nKeys.challenges.challengeSelection.maxScore.label)}
          {t(i18nKeys.challenges.challengeSelection.maxScore.value, { points: maxTotalScore })}
        </h2>
        <h2 style={{ display: 'inline', paddingLeft: '10px' }} className="inline-header">
          {t(i18nKeys.challenges.challengeSelection.estimatedTime)}
          {estimatedTotalSolveTime}
        </h2>
      </div>
      <div>
        <SpaceBetween direction="vertical" size="s">
          {!wrapperMapInitalized && <LoadingBar />}
          {wrapperMapInitalized && selectedChallenges && selectedChallenges.length < 1 && (
            <div>
              <h2>{t(i18nKeys.challenges.challengeSelection.noChallengesSelected)}</h2>
            </div>
          )}
          {selectedChallenges?.map((challengeItem, i) => {
            return (
              challengeItem && (
                <ChallengeCard
                  key={`selected-challenge-${i}`}
                  challenge={challengeItem}
                  index={i}
                  getChallengePointsPossible={getCurrentChallengePointsPossible}
                  getDefaultChallengePointsPossible={getChallengeDefaultPointsPossible}
                  handleRemoveChallengeAction={handleRemoveChallengeAction}
                  toggleChallengeInfo={toggleChallengeInfo}
                  advancedMode={advancedMode}
                  target={target}
                />
              )
            );
          })}
        </SpaceBetween>
      </div>
    </div>
  );
};
export default ChallengeSelection;
