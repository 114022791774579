import { ColumnLayout, DatePicker, Input, Select, TimeInput, Toggle } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MeridiemOptions } from '../../../constants/DateTimeConstants';
import { useChallenges } from '../../../store/challenge.context';
import { EditEventActions, useEditEvent } from '../../../store/edit-event.context';
import { useEvents } from '../../../store/events.context';
import { useUser } from '../../../store/user.context';
import { Campaign } from '../../../types/Campaign';
import { ChallengeDescriptor, ChallengeDifficulty, ChallengeListItem } from '../../../types/Challenge';
import { Nullable } from '../../../types/common';
import { Event } from '../../../types/Event';
import { LabProvider } from '../../../types/LabProvider';
import { Pool } from '../../../types/Pools';
import {
  getBrowserTimezoneName,
  getTimeForPolarisTimePicker,
  polarisDateTimeToMoment,
} from '../../../utils/event-time.utils';
import { i18nKeys } from '../../../utils/i18n.utils';
import { getLanguageCodeSafe } from '../../../utils/locale.utils';
import { toTitleCase } from '../../../utils/string.utils';
import { HorizontalRule } from '../HorizontalRule';
import { KeyValue } from '../KeyValue';

interface ChallengeCardAdvancedProps {
  challenge: ChallengeListItem;
  getDefaultChallengePointsPossible: (challenge: ChallengeListItem) => number;
  target: Event | Campaign;
}

const ChallengeCardAdvanced: React.FC<ChallengeCardAdvancedProps> = ({
  challenge,
  getDefaultChallengePointsPossible,
  target,
}) => {
  const { i18n, t } = useTranslation();
  const [selectedMeridiem, setSelectedMeridiem] = useState<OptionDefinition>(MeridiemOptions.AM);
  const { handleUpdateEditEvent, editMode } = useEditEvent();
  const { pools } = useEvents();
  const { user } = useUser();
  const { updateChallengeDescriptor, getChallengeDescriptor, wrapperMapInitalized } = useChallenges();
  const [challengeDescriptor, setChallengeDescriptor] = useState<Nullable<ChallengeDescriptor>>(null);
  const [selectedDifficulty, setSelectedDifficulty] = useState<Nullable<OptionDefinition>>(null);
  const [selectedLabProvider, setSelectedLabProvider] = useState<Nullable<OptionDefinition>>(null);
  const [selectedPoolOverrideId, setSelectedPoolOverrideId] = useState<Nullable<OptionDefinition>>(null);
  const [poolOverrideOptions, setPoolOverrideOptions] = useState<OptionDefinition[]>([]);
  const [selectedPointsPossible, setSelectedPointsPossible] = useState(0);
  const [autoScalingOverride, setAutoScalingOverride] = useState(0);
  const [restartsAllowed, setRestartsAllowed] = useState(0);
  const [prizeCount, setPrizeCount] = useState(0);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [challengeDifficultyOptions, setChallengeDifficultyOptions] = useState<OptionDefinition[]>([]);
  const isEvent = target instanceof Event;

  const LabProviderOptions: OptionDefinition[] = [
    // These values shouldnt be translated anyway, but falling back in case toTitleCase fails, it shouldnt
    { label: toTitleCase(LabProvider.AWS_LABS) || 'AWS Labs', value: LabProvider.AWS_LABS },
    { label: toTitleCase(LabProvider.EVENT_ENGINE) || 'Event Engine', value: LabProvider.EVENT_ENGINE },
  ];

  const languageCode: string = getLanguageCodeSafe(i18n.language);

  const isAdmin = user && target?.isAdmin(user);

  useEffect(() => {
    const difficultyLevels = ChallengeDifficulty.values;
    const newOptions: OptionDefinition[] = [];
    difficultyLevels.forEach((challengeDifficulty) => {
      newOptions.push({
        label: t(challengeDifficulty.i18nKeyShort),
        value: challengeDifficulty.key.toString(),
      });
    });
    setChallengeDifficultyOptions(newOptions);
  }, []);

  useEffect(() => {
    if (challenge && challenge.challengeId) {
      setChallengeDescriptor(getChallengeDescriptor(challenge));
    }
  }, [wrapperMapInitalized]);

  useEffect(() => {
    if (editMode) {
      handleAddToEvent();
    }
    handleSelectedDefaults();
  }, [challengeDescriptor]);

  useEffect(() => {
    if (editMode) {
      handleDateTimeChange();
    }
  }, [selectedDate, selectedTime, selectedMeridiem]);

  useEffect(() => {
    generatePoolOptions();
  }, [pools]);

  const handleSelectedDefaults = () => {
    const difficultyOptionIndex = challengeDifficultyOptions.findIndex(
      (difficultyOption: OptionDefinition) =>
        Number(difficultyOption.value) === (challengeDescriptor?.overrides.difficulty || challenge.props.difficulty)
    );
    const labProviderIndex = LabProviderOptions.findIndex(
      (labProviderOption: OptionDefinition) =>
        labProviderOption.value === (challengeDescriptor?.labProvider || challengeDescriptor?.defaultLabProvider)
    );
    setSelectedDifficulty(challengeDifficultyOptions[difficultyOptionIndex]);
    setSelectedLabProvider(LabProviderOptions[labProviderIndex]);
    setSelectedPointsPossible(challengeDescriptor?.overrides.score || getDefaultChallengePointsPossible(challenge));
    setAutoScalingOverride(challengeDescriptor?.labAutoScalingOverride || 0);
    setRestartsAllowed(challengeDescriptor?.restartsAllowed || 0);
    setPrizeCount(challengeDescriptor?.prizeInformation.prizeCount || 0);
    setSelectedDateAndTime();
  };

  const setSelectedDateAndTime = () => {
    if (challengeDescriptor?.displayTime) {
      // Polaris DatePicker component does not properly parse full startDate with time
      // Spliting here fixes default selection for DatePicker
      setSelectedDate(challengeDescriptor?.displayTime.split('T')[0]);
      const displayTime = getTimeForPolarisTimePicker(challengeDescriptor.displayTime);
      if (displayTime) {
        if (displayTime.includes('PM')) {
          setSelectedMeridiem(MeridiemOptions.PM);
        } else {
          setSelectedMeridiem(MeridiemOptions.AM);
        }
        setSelectedTime(displayTime);
      }
    }
  };

  const generatePoolOptions = () => {
    const poolOptions =
      pools?.map((pool: Pool) => {
        return {
          label: t(i18nKeys.challenges.challengeSelection.advancedSettings.labels.poolLabel, {
            prefix: pool.test
              ? t(i18nKeys.challenges.challengeSelection.advancedSettings.labels.prefixes.preProd)
              : t(i18nKeys.challenges.challengeSelection.advancedSettings.labels.prefixes.prod),
            name: pool.name,
            numCurrentChallenges: pool.numCurrentChallenges,
            numReservedChallenges: pool.numReservedChallenges,
            numAvailable: pool.numAvailable,
          }),
          value: pool.id as string,
        };
      }) || [];
    setPoolOverrideOptions(poolOptions);
  };

  const toggleWarmup = () => {
    const modifiedDescriptor = challengeDescriptor;
    if (modifiedDescriptor) {
      modifiedDescriptor.overrides.challengeAlwaysOn = !challengeDescriptor?.overrides.challengeAlwaysOn;
      modifiedDescriptor.setDateTimeModels();
      handleEditEvent(modifiedDescriptor);
    }
  };

  const toggleVirtualQueue = () => {
    const modifiedDescriptor = challengeDescriptor;
    if (modifiedDescriptor) {
      modifiedDescriptor.virtualQueueSettings.enabled = !challengeDescriptor.virtualQueueSettings.enabled;
      handleEditEvent(modifiedDescriptor);
    }
  };

  const toggleHidden = () => {
    const modifiedDescriptor = challengeDescriptor;
    if (modifiedDescriptor) {
      modifiedDescriptor.hidden = !challengeDescriptor?.hidden;
      if (modifiedDescriptor.hidden) {
        modifiedDescriptor.displayTime = null;
      }
      modifiedDescriptor.setDateTimeModels();
      handleEditEvent(modifiedDescriptor);
    }
  };

  const toggleCodeWhispererDisabled = () => {
    const modifiedDescriptor = challengeDescriptor;
    if (modifiedDescriptor) {
      modifiedDescriptor.codeWhispererDisabled = !challengeDescriptor?.codeWhispererDisabled;
      handleEditEvent(modifiedDescriptor);
    }
  }

  const handleScoreChange = (score: string) => {
    const newScore = Number(score);
    setSelectedPointsPossible(newScore);
    const modifiedDescriptor = challengeDescriptor;
    if (modifiedDescriptor) {
      modifiedDescriptor.overrides.score = newScore;
      handleEditEvent(modifiedDescriptor);
    }
  };

  const handleAutoScalingOverrideChange = (autoScaleOveride: string) => {
    const autoScaleOverideNumber = Number(autoScaleOveride);
    setAutoScalingOverride(autoScaleOverideNumber);
    const modifiedDescriptor = challengeDescriptor;
    if (modifiedDescriptor) {
      modifiedDescriptor.labAutoScalingOverride = autoScaleOverideNumber;
      handleEditEvent(modifiedDescriptor);
    }
  };

  const handleRestartsAllowedChange = (selectedRestartsAllowed: string) => {
    const restartsAllowedNumber = Number(selectedRestartsAllowed);
    if (restartsAllowedNumber > 0 && restartsAllowedNumber < 6) {
      setRestartsAllowed(restartsAllowedNumber);
      const modifiedDescriptor = challengeDescriptor;
      if (modifiedDescriptor) {
        modifiedDescriptor.restartsAllowed = restartsAllowedNumber;
        handleEditEvent(modifiedDescriptor);
      }
    }
  };

  const handlePrizeCountChange = (selectedPrizeCount: string) => {
    const prizeCountNumber = Number(selectedPrizeCount);
    setPrizeCount(prizeCountNumber);
    const modifiedDescriptor = challengeDescriptor;
    if (modifiedDescriptor) {
      modifiedDescriptor.prizeInformation.prizeCount = prizeCountNumber;
      handleEditEvent(modifiedDescriptor);
    }
  };

  const handleDifficultyChange = (selectedDifficultyOption: OptionDefinition) => {
    setSelectedDifficulty(selectedDifficultyOption);
    const modifiedDescriptor = challengeDescriptor;
    if (modifiedDescriptor) {
      modifiedDescriptor.overrides.difficulty = Number(selectedDifficultyOption.value);
      handleEditEvent(modifiedDescriptor);
    }
  };

  const handleLabProviderChange = (selectedLabProviderOption: OptionDefinition) => {
    setSelectedLabProvider(selectedLabProviderOption);
    if (selectedLabProviderOption.value !== LabProvider.EVENT_ENGINE) {
      setSelectedPoolOverrideId(null);
    }
    const modifiedDescriptor = challengeDescriptor;
    if (modifiedDescriptor && selectedLabProviderOption.value) {
      modifiedDescriptor.labProvider = selectedLabProviderOption.value as LabProvider;
      handleEditEvent(modifiedDescriptor);
    }
  };

  const handleAddToEvent = () => {
    const newChallengeDescriptors = target?.challengeDescriptors || [];
    const challengeDescriptorIndex = newChallengeDescriptors?.findIndex(
      (cd: ChallengeDescriptor) => cd.challengeId === challengeDescriptor?.challengeId
    );
    if (challengeDescriptorIndex && challengeDescriptor) {
      newChallengeDescriptors[challengeDescriptorIndex] = challengeDescriptor;
      handleUpdateEditEvent(EditEventActions.CHALLENGE_DESCRIPTORS, newChallengeDescriptors);
    }
  };

  const handleDateTimeChange = () => {
    if (isEvent) {
      const newDisplayTime = polarisDateTimeToMoment(
        selectedDate,
        `${selectedTime} ${selectedMeridiem.value}`,
        target?.timezone || getBrowserTimezoneName() || ''
      ).format();
      const modifiedDescriptor = challengeDescriptor;
      if (modifiedDescriptor && newDisplayTime) {
        modifiedDescriptor.displayTime = newDisplayTime;
        handleEditEvent(modifiedDescriptor);
      }
    }
  };

  const handleSelectPoolOverrideId = (selectedPool: OptionDefinition) => {
    setSelectedPoolOverrideId(selectedPool);
    const modifiedDescriptor = challengeDescriptor;
    if (modifiedDescriptor && selectedPool.value) {
      modifiedDescriptor.poolIdOverride = selectedPool.value;
      handleEditEvent(modifiedDescriptor);
    }
  };

  const handleEditEvent = (newChallengeDescriptor: ChallengeDescriptor) => {
    updateChallengeDescriptor(newChallengeDescriptor);
    const indexOfChallengeDescriptor = target.challengeDescriptors.findIndex(
      (cd: ChallengeDescriptor) => cd.challengeId === newChallengeDescriptor.challengeId
    );
    const newChallengeDescriptors = _.cloneDeep(target.challengeDescriptors);
    newChallengeDescriptors[indexOfChallengeDescriptor] = newChallengeDescriptor;
    handleUpdateEditEvent(EditEventActions.CHALLENGE_DESCRIPTORS, newChallengeDescriptors);
  };

  const canEditWithAttribute = (attribute: string) => {
    if (user) {
      return editMode && isAdmin && target.canEditAttribute(attribute, user);
    } else {
      return false;
    }
  };

  /**
   * TODO:
   * Align with/implement Feature Flags
   */
  return user ? (
    <div style={{ marginTop: '4px' }}>
      <HorizontalRule />
      <h3>{t(i18nKeys.challenges.challengeSelection.advancedSettings.header)}</h3>
      <ColumnLayout columns={3}>
        <Toggle
          disabled={!canEditWithAttribute('challengeAlwaysOn')}
          checked={challengeDescriptor?.overrides.challengeAlwaysOn || false}
          onChange={() => toggleWarmup()}>
          {t(i18nKeys.challenges.challengeSelection.advancedSettings.labels.warmup, {
            warmup: challenge?.props.challengeAlwaysOn ? t(i18nKeys.general.yes) : t(i18nKeys.general.no),
          })}
        </Toggle>
        {isEvent && (
          <Toggle
            disabled={!canEditWithAttribute('hidden')}
            checked={challengeDescriptor?.hidden || false}
            onChange={() => toggleHidden()}>
            {t(i18nKeys.challenges.challengeSelection.advancedSettings.labels.locked)}
          </Toggle>
        )}
        <Toggle
          disabled={!canEditWithAttribute('virtualQueueSettings')}
          checked={challengeDescriptor?.virtualQueueSettings.enabled || false}
          onChange={() => toggleVirtualQueue()}>
          {t(i18nKeys.challenges.challengeSelection.advancedSettings.labels.virtualQueueEnabled)}
        </Toggle>
        {isEvent && (
          <KeyValue
            label={t(i18nKeys.challenges.challengeSelection.advancedSettings.labels.unlockTime)}
            description={t(i18nKeys.challenges.challengeSelection.advancedSettings.descriptions.unlockTime)}>
            <div style={{ minWidth: '426px' }}>
              <DatePicker
                disabled={!canEditWithAttribute('displayTime')}
                className="float-left inline"
                placeholder="YYYY/MM/DD"
                onChange={({ detail }) => setSelectedDate(detail.value)}
                value={selectedDate}
                openCalendarAriaLabel={(newSelectedDate) =>
                  t(i18nKeys.general.chooseDate) +
                  (newSelectedDate ? t(i18nKeys.general.selectedDate, { newSelectedDate }) : '')
                }
                locale={languageCode}
                previousMonthAriaLabel={t(i18nKeys.events.fields.filters.labels.previousMonth)}
                nextMonthAriaLabel={t(i18nKeys.events.fields.filters.labels.nextMonth)}
                todayAriaLabel={t(i18nKeys.events.fields.filters.labels.today)}
              />
              <div className="float-left" style={{ display: 'inline-block' }}>
                {/** Todo: Align this above time input <div style={{ float: 'left' }}>{getBrowserTimezoneName()}</div> */}
                <TimeInput
                  disabled={!canEditWithAttribute('displayTime')}
                  className="time-input-small inline ml-5 float-left"
                  placeholder="hh:mm"
                  format="hh:mm"
                  onChange={({ detail }) => setSelectedTime(detail.value)}
                  value={selectedTime}
                />
                <Select
                  disabled={!canEditWithAttribute('displayTime')}
                  className="float-left force-overlap"
                  options={[MeridiemOptions.AM, MeridiemOptions.PM]}
                  onChange={({ detail }) => setSelectedMeridiem(detail.selectedOption)}
                  selectedOption={selectedMeridiem}
                />
              </div>
            </div>
          </KeyValue>
        )}
        <KeyValue
          label={t(i18nKeys.challenges.challengeSelection.advancedSettings.labels.difficulty)}
          description={t(i18nKeys.challenges.challengeSelection.advancedSettings.descriptions.difficulty, {
            difficulty: t(ChallengeDifficulty.getByKey(challenge.props.difficulty).i18nKeyShort),
          })}>
          <Select
            disabled={!canEditWithAttribute('challengeDifficultyOverride')}
            placeholder={t(i18nKeys.general.chooseAnOption)}
            options={challengeDifficultyOptions}
            onChange={({ detail }) => handleDifficultyChange(detail.selectedOption)}
            selectedOption={selectedDifficulty}
          />
        </KeyValue>
        <KeyValue
          label={t(i18nKeys.challenges.challengeSelection.advancedSettings.labels.pointsPossible)}
          description={t(i18nKeys.challenges.challengeSelection.advancedSettings.labels.locked, {
            pointsPossible: getDefaultChallengePointsPossible(challenge),
          })}>
          <Input
            disabled={!canEditWithAttribute('pointsPossibleOverride')}
            value={selectedPointsPossible.toString()}
            onChange={({ detail }) => handleScoreChange(detail.value)}
            type="number"
          />
        </KeyValue>
        {isEvent && (
          <KeyValue
            label={t(i18nKeys.challenges.challengeSelection.advancedSettings.labels.autoscalingOverride)}
            alignWithDescriptiveKeyValues>
            <Input
              disabled={!canEditWithAttribute('autoScalingOverride')}
              type="number"
              value={autoScalingOverride.toString()}
              onChange={({ detail }) => handleAutoScalingOverrideChange(detail.value)}
            />
          </KeyValue>
        )}
        <KeyValue
          label={t(i18nKeys.challenges.challengeSelection.advancedSettings.labels.restartsAllowed)}
          description={t(i18nKeys.challenges.challengeSelection.advancedSettings.descriptions.restartsAllowed, {
            restartsAllowed: 0,
          })}>
          <Input
            disabled={!canEditWithAttribute('restartsOverride')}
            type="number"
            value={restartsAllowed.toString()}
            onChange={({ detail }) => handleRestartsAllowedChange(detail.value)}
          />
        </KeyValue>
        <KeyValue
          label={t(i18nKeys.challenges.challengeSelection.advancedSettings.labels.labProvider)}
          description={t(i18nKeys.challenges.challengeSelection.advancedSettings.labels.locked, {
            labProvider: challenge?.props?.defaultLabProviderLabel,
          })}>
          <Select
            disabled={!canEditWithAttribute('labProviderOverride')}
            placeholder={t(i18nKeys.general.chooseAnOption)}
            options={LabProviderOptions}
            selectedOption={selectedLabProvider}
            onChange={({ detail }) => handleLabProviderChange(detail.selectedOption)}
          />
        </KeyValue>
        {selectedLabProvider?.value === LabProvider.EVENT_ENGINE && (
          <KeyValue
            label={t(i18nKeys.challenges.challengeSelection.advancedSettings.labels.eventEnginePool)}
            description={t(i18nKeys.general.optional)}>
            <Select
              disabled={!canEditWithAttribute('eventEnginePoolOverride')}
              placeholder={t(i18nKeys.general.chooseAnOption)}
              options={poolOverrideOptions}
              selectedOption={selectedPoolOverrideId}
              onChange={({ detail }) => handleSelectPoolOverrideId(detail.selectedOption)}
            />
          </KeyValue>
        )}
        {isEvent && (
          <KeyValue
            label={t(i18nKeys.challenges.challengeSelection.advancedSettings.labels.numberOfPrizes)}
            description={t(i18nKeys.challenges.challengeSelection.advancedSettings.descriptions.numberOfPrizes, {
              numberOfPrizes: 0,
            })}>
            <Input
              disabled={!canEditWithAttribute('prizeInformationOverride')}
              type="number"
              value={prizeCount.toString()}
              onChange={({ detail }) => handlePrizeCountChange(detail.value)}
            />
          </KeyValue>
        )}
        {isEvent && (
          <Toggle
            disabled={!canEditWithAttribute('codeWhispererDisabled')}
            checked={challengeDescriptor?.codeWhispererDisabled ?? false}
            onChange={() => toggleCodeWhispererDisabled()}>
            {t(i18nKeys.challenges.challengeSelection.advancedSettings.labels.disableCodeWhisperer)}
          </Toggle>
        )}
      </ColumnLayout>
    </div>
  ) : null;
};

export default ChallengeCardAdvanced;
