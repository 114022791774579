import React from 'react';
import RevisionHistory from './challengeRevisionHistory/RevisionHistory';
import RevisionsNeeded from './challengeRevisionsNeeded/RevisionsNeeded';
import { Box, SpaceBetween } from '@amzn/awsui-components-react';

export const ChallengeRevisionsDetail: React.FC = () => {
  return (
    <Box padding={'m'}>
      <SpaceBetween direction={'vertical'} size={'m'}>
        <RevisionsNeeded />
        <RevisionHistory />
      </SpaceBetween>
    </Box>
  );
};

export default ChallengeRevisionsDetail;
