import React, { useEffect, useState } from 'react';
import { Modal, Flashbar } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@/src/utils/i18n.utils';

const TempLogBanner: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    const errMsg = localStorage.getItem('loginWarnMsg');
    if (errMsg && errMsg === 'true') {
      setShowWarning(true);
    }
  }, []);

  const closeWarnMsg = (): void => {
    setShowWarning(false);
    localStorage.removeItem('loginWarnMsg');
  };

  return (
    <>
      {showWarning && (
        <Flashbar
          items={[
            {
              dismissible: true,
              onDismiss: () => closeWarnMsg(),
              header: t(i18nKeys.tempLogBanner.flasher.title),
              type: 'warning',
              content: t(i18nKeys.tempLogBanner.flasher.message),
              buttonText: t(i18nKeys.tempLogBanner.flasher.btnTxt),
              onButtonClick: () => setShowModal(true),
            },
          ]}
        />
      )}
      <Modal
        header={t(i18nKeys.tempLogBanner.faq.header)}
        onDismiss={() => setShowModal(false)}
        size="large"
        visible={showModal}>
        <div>
          <h2> {t(i18nKeys.tempLogBanner.faq.oneH)} </h2>
          <p> {t(i18nKeys.tempLogBanner.faq.oneP)} </p>
        </div>
        <hr />
        <div>
          <h2> {t(i18nKeys.tempLogBanner.faq.twoH)} </h2>
          <p> {t(i18nKeys.tempLogBanner.faq.twoP)} </p>
          <p> {t(i18nKeys.tempLogBanner.faq.twoP2)}</p>
        </div>
        <hr />
        <div>
          <h2>{t(i18nKeys.tempLogBanner.faq.threeH)}</h2>
          <p>{t(i18nKeys.tempLogBanner.faq.threeP)}</p>
        </div>
        <hr />
        <div>
          <h2>{t(i18nKeys.tempLogBanner.faq.fourH)}</h2>
          <p>{t(i18nKeys.tempLogBanner.faq.fourP)}</p>
        </div>
        <div>
          <h2>{t(i18nKeys.tempLogBanner.faq.fiveH)}</h2>
          <p>
            {t(i18nKeys.tempLogBanner.faq.fiveP)}
            <a href="https://skillbuilder.aws/">https://skillbuilder.aws/</a>
          </p>
        </div>
      </Modal>
    </>
  );
};

export default TempLogBanner;
