import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppLayout, Flashbar, Link } from '@amzn/awsui-components-react';
import { Header, NewHeader } from './Header';
import { Footer } from './Footer/Footer';
import AuthenticatedRoutes from './AuthenticedRoutes';
import { SideNav } from './SideNav';
import BreadCrumbs from './BreadCrumbs';
import { useActiveRouteDetails } from '../../hooks/useActiveRouteDetails';
import { LoadingBar } from '../common/LoadingBar';
import { FlashbarItem } from '../../types/common';
import { useFlashbars } from '../../store/flashbar.context';
import { i18nKeys, withI18NPrefix } from '../../utils/i18n.utils';
import { useUser } from '../../store/user.context';
import UnauthenticatedRoutes from './UnauthenticedRoutes';
import { useAuth } from '../../store/auth.context';
import { useCookieConsent } from '../../store/cookie-consent.context';
import { ToolSets } from '../tools/ToolSets';
import { useSplitPanel } from '../../store/split-panel.context';
import SplitPanelInterface from '../common/SplitPanelInterface';
import { useToolPanel } from '../../store/tool-panel.context';
import { useComponentDidMountEffect } from '../../hooks/useComponentDidMountEffect';
import { useHistory } from 'react-router-dom';
import {
  getConsoleBannerAcknowledged,
  performRedirect,
  setConsoleBannerAcknowledged,
} from '@amzn/aws-jam-hybrid-console';
import { config } from '../../config/app-config';
import { bannerFlashbar, infoFlashbar } from '../../utils/notification.utils';
import { useLSE } from '../../store/lse.context';
import { MILLIS_PER_FIVE_MINUTES } from '../../constants/DateTimeConstants';

const TopHeader = () => {
  return  <Suspense fallback={<LoadingBar />}>
    {
      config.isNewTopNavigationEnabled ? <NewHeader /> : <Header />
    }
  </Suspense>
}
const App: React.FC = () => {
  const { t } = useTranslation();
  const { isHome } = useActiveRouteDetails();
  const { user } = useUser();
  const { authClient } = useAuth();
  const { shortbread, initializeCookies } = useCookieConsent();
  const { showSplitPanel, toggleSplitPanel, splitPanelOpen } = useSplitPanel();
  const { showToolPanel, toggleToolPanel, toolPanelOpen, toolPanelWidth, showSidenav, toggleSideNav } = useToolPanel();
  const { currentLSE, loadCurrentLSE } = useLSE();
  const { addFlashbar, removeFlashbar, flashbars } = useFlashbars();
  const [currentLSEText, setCurrentLSEText] = useState('');

  const history = useHistory();

  /** Setup interval to check for new LSE every five minutes */
  useEffect(() => {
    const interval = setInterval(() => {
      loadCurrentLSE();
    }, MILLIS_PER_FIVE_MINUTES);

    return () => clearInterval(interval);
  }, []);

  useComponentDidMountEffect(async () => {
    if (await authClient.isSignedIn()) {
      await new Promise(() => loadCurrentLSE());
    }
  });

  /** Add banner flashbar if lse message doesn't equal current cached lse message */
  useEffect(() => {
    if (!!currentLSE?.text && currentLSE.text !== currentLSEText) {
      addFlashbar(
        bannerFlashbar(
          currentLSE?.text,
          currentLSE?.url ? (
            <Link href={currentLSE?.url} external color="inverted">
              {t(i18nKeys.general.learnMore)}
            </Link>
          ) : undefined
        )
      );
      setCurrentLSEText(currentLSE.text);
    } else {
      const flashbarId = flashbars.find((fb) => fb.header === currentLSEText)?.id;
      if (flashbarId) {
        removeFlashbar(flashbarId);
      }
      setCurrentLSEText('');
    }
  }, [currentLSE]);

  useEffect(() => {
    // check for the IDP query parameter on the app's every route change / state update
    void authClient.handleIdpQueryParam(user);
    if (shortbread === undefined) {
      initializeCookies();
    }
    // in EU geo locations, will show the cookie consent banner.
    // (will show it once per 365 days at most per domain)
    shortbread?.checkForCookieConsent();
    shortbread?.getConsentCookie();
  }, [shortbread, authClient, initializeCookies, user, showSplitPanel]);

  if (config.useHybridExperience) {
    // eslint-disable-next-line @typescript-eslint/require-await
    useComponentDidMountEffect(async () => {
      if (!getConsoleBannerAcknowledged()) {
        const flashbar = infoFlashbar('', t(i18nKeys.hybrid.welcome));
        flashbar.onDismiss = setConsoleBannerAcknowledged;
        addFlashbar(flashbar);
      }

      history.listen(() => performRedirect());

      performRedirect();
    });
  }

  /**
   * Iterate through all flashbar items and handle translation of any i18n keys that might be present.
   */
  const getLocalizedFlashbarItems = (): FlashbarItem[] => {
    return (flashbars || []).map((item) => {
      if (item.i18nKeys && item.i18nKeys.length > 0) {
        item = Object.assign({}, item);
        item.i18nKeys.forEach((key) => {
          const value = t(key);
          if (value) {
            if (typeof item.header === 'string') {
              item.header = item.header.replace(withI18NPrefix(key), value);
            }
            if (typeof item.content === 'string') {
              item.content = item.content.replace(withI18NPrefix(key), value);
            }
          }
        });
      }
      return item;
    });
  };

  return (
    <Suspense fallback={<LoadingBar />}>
      {user == null ? (
        <React.Fragment>
          <TopHeader />
          <UnauthenticatedRoutes />
          <Footer />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <TopHeader />
          <AppLayout
            disableContentPaddings={isHome}
            navigationOpen={showSidenav}
            onNavigationChange={() => toggleSideNav(!showSidenav)}
            navigation={<SideNav />}
            notifications={<Flashbar items={getLocalizedFlashbarItems()} />}
            breadcrumbs={isHome ? null : <BreadCrumbs />}
            headerSelector={'#header'}
            footerSelector={'#footer'}
            toolsOpen={toolPanelOpen}
            toolsWidth={toolPanelWidth}
            content={<AuthenticatedRoutes />}
            tools={<ToolSets />}
            toolsHide={!showToolPanel}
            onToolsChange={() => toggleToolPanel(!toolPanelOpen)}
            splitPanelOpen={splitPanelOpen}
            onSplitPanelToggle={() => toggleSplitPanel()}
            splitPanel={<SplitPanelInterface />}
          />
          <Footer />
        </React.Fragment>
      )}
    </Suspense>
  );
};

export default App;
