import { Box, Button, Flashbar, ProgressBar, SpaceBetween } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useChallenges } from '../../../../store/challenge.context';
import {
  ChallengeReviewableSection,
  ChallengeReviewFeedback,
  ChallengeReviewStatus,
  ChallengeSectionFeedback,
} from '../../../../types/Challenge';
import { Nullable } from '../../../../types/common';
import { CHALLENGE_DETAILS_ROUTES } from '../../../../routes';
import { safeString } from '../../../../utils/string.utils';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { TFunctionProvider } from '../../../common/TFunctionProvider';

type FeedbackBySection = Map<ChallengeReviewableSection, ChallengeSectionFeedback>;

/**
 * Helper function to retrieve list of sections that are
 * required to be reviewed in given feedback list
 *
 * @param challengeReviewFeedback
 * @param hasReviewed filter to only retain
 * sections that were reviewed by user
 */
const getRequiredSections: (
  challengeReviewFeedback: Nullable<ChallengeReviewFeedback> | undefined,
  hasReviewed?: boolean
) => ChallengeReviewableSection[] = (challengeReviewFeedback, hasReviewed = false) => {
  if (!challengeReviewFeedback) {
    return [];
  }
  const checkIfReviewed = (item: ChallengeSectionFeedback) =>
    !hasReviewed ||
    (item.comment !== null &&
      (item.status === ChallengeReviewStatus.APPROVED || item.status === ChallengeReviewStatus.NEEDS_WORK));

  const feedbackBySection: FeedbackBySection = challengeReviewFeedback.feedback.reduce(
    (map: FeedbackBySection, item: ChallengeSectionFeedback) => {
      if (item.section != null && item.required === true && checkIfReviewed(item)) {
        map.set(item.section, item);
      }
      return map;
    },
    new Map()
  );

  // this maintains the ordering as per ChallengeReviewableSection.keys() order
  return Object.values(ChallengeReviewableSection)
    .map((section) => feedbackBySection.get(section))
    .map((item) => item?.section || ChallengeReviewableSection.DEFAULT)
    .filter((item) => item != null && item !== ChallengeReviewableSection.DEFAULT);
};

const pathBySection = (challengeId: string) => {
  return {
    [ChallengeReviewableSection.SUMMARY]: CHALLENGE_DETAILS_ROUTES.Summary.resolve(challengeId),
    [ChallengeReviewableSection.DEFAULT]: CHALLENGE_DETAILS_ROUTES.Summary.resolve(challengeId),
    [ChallengeReviewableSection.LEARNING_OUTCOME]: CHALLENGE_DETAILS_ROUTES.LearningOutcome.resolve(challengeId),
    [ChallengeReviewableSection.SETTINGS]: CHALLENGE_DETAILS_ROUTES.Settings.resolve(challengeId),
    [ChallengeReviewableSection.TASKS]: CHALLENGE_DETAILS_ROUTES.Tasks.resolve(challengeId),
    [ChallengeReviewableSection.ASSETS_RESOURCES]: CHALLENGE_DETAILS_ROUTES.Assets.resolve(challengeId),
    [ChallengeReviewableSection.IAM_POLICY]: CHALLENGE_DETAILS_ROUTES.IamPolicy.resolve(challengeId),
    [ChallengeReviewableSection.CFN_TEMPLATE]: CHALLENGE_DETAILS_ROUTES.CfnTemplate.resolve(challengeId),
    [ChallengeReviewableSection.NEXT_STEPS]: CHALLENGE_DETAILS_ROUTES.NextSteps.resolve(challengeId),
    [ChallengeReviewableSection.WIKI]: CHALLENGE_DETAILS_ROUTES.Wiki.resolve(challengeId),
    [ChallengeReviewableSection.TESTING]: CHALLENGE_DETAILS_ROUTES.Testing.resolve(challengeId),
  };
};

const ReviewProgressTracker: React.FC = () => {
  const { t } = useTranslation();
  const { currentReview, reviewMode, setReviewMode, challenge } = useChallenges();
  const reviewedRequiredSections = new Set<ChallengeReviewableSection>(
    getRequiredSections(currentReview?.challengeReviewFeedback, true)
  );
  const allRequiredSections = getRequiredSections(currentReview?.challengeReviewFeedback);
  const nextSection = allRequiredSections.find((section) => !reviewedRequiredSections.has(section));
  const nextLink = !nextSection ? null : pathBySection(safeString(challenge?.challengeId))[nextSection];
  const history = useHistory();

  const completedItems = reviewedRequiredSections.size;
  const totalItems = allRequiredSections.length;
  const progressText = t(i18nKeys.challenges.challengeDetails.reviewTracker.progress, {
    completedItems: completedItems.toString(),
    totalItems: totalItems.toString(),
  });
  const resultText = t(i18nKeys.challenges.challengeDetails.reviewTracker.successText);
  const label = t(i18nKeys.challenges.challengeDetails.reviewTracker.label);
  const percentageProgress = totalItems === 0 ? 100 : (completedItems / totalItems) * 100;
  const barRaiserReviewInfo =
    percentageProgress < 100
      ? t(i18nKeys.challenges.challengeDetails.reviewTracker.barRaiserReviewInProgress)
      : t(i18nKeys.challenges.challengeDetails.reviewTracker.barRaiserReviewCompleted);
  const exitReview = t(i18nKeys.challenges.challengeDetails.reviewTracker.exitReviewMode);

  return reviewMode ? (
    <Flashbar
      items={[
        {
          dismissible: true,
          dismissLabel: exitReview,
          onDismiss: () => {
            setReviewMode(false);
          },
          content: (
            <ProgressBar
              variant={'flash'}
              value={percentageProgress}
              additionalInfo={
                currentReview
                  ? barRaiserReviewInfo
                  : ''
              }
              label={label}
              description={percentageProgress === 100 ? '' : progressText}
              status={percentageProgress === 100 ? 'success' : 'in-progress'}
              resultText={resultText}
            />
          ),
          action: (
            <Box margin={{ top: 'm' }} padding={{ top: 'm' }}>
              <SpaceBetween size={'m'} direction={'horizontal'}>
                {nextLink != null && (
                  <Button
                    onClick={() => {
                      history.push(nextLink);
                    }}>
                    <TFunctionProvider>
                      {(tr) => tr(i18nKeys.challenges.challengeDetails.reviewTracker.nextSection)}
                    </TFunctionProvider>
                  </Button>
                )}
                <Button
                  onClick={() => {
                    setReviewMode(false);
                  }}>
                  {exitReview}
                </Button>
              </SpaceBetween>
            </Box>
          ),
        },
      ]}
    />
  ) : (
    <></>
  );
};
export default ReviewProgressTracker;
