import { AuthRole } from '@amzn/aws-jam-constants';
import { IdentityProvider, IdpToProviderName, ProviderNameToIdp } from './IdentityProvider';
import { Nullable } from './common';
import { getSpoofedGroups, SpoofedGroup } from '../utils/spoofed-groups';
import { AnyT, jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import type { UserStatusType } from 'aws-sdk/clients/cognitoidentityserviceprovider';

/**
 * Expected type of a user's parsed ID token.
 */
export interface IdTokenParsed {
  readonly email?: string;
  readonly given_name?: string;
  readonly family_name?: string;
  readonly name?: string;
  readonly 'cognito:username': string;
  readonly 'cognito:groups': string[];
  readonly identities?: [
    {
      readonly providerName?: string;
    }
  ];
  readonly public_provider_name?: string;
}

/**
 * Properties used to create a User.
 */
export interface UserProps {
  readonly name: string;
  readonly email: string;
  readonly identityProvider: Nullable<IdentityProvider>;
  readonly groups: string[];
}

export class User {
  public readonly name: string;
  public readonly email: string;
  public readonly provider: Nullable<IdentityProvider>;
  private groups: string[];

  /**
   * Create a User from the given properties.
   *
   * @param userProps - Properties of the user.
   */
  public constructor(userProps: UserProps) {
    this.name = userProps.name;
    this.email = userProps.email;
    this.provider = userProps.identityProvider;
    this.groups = userProps.groups;
  }

  /**
   * Create an exact copy of this User.
   */
  public clone(): User {
    return new User({
      email: this.email,
      name: this.name,
      identityProvider: this.provider,
      groups: this.groups
    });
  }

  /**
   * Create a user from the given id token.
   */
  public static fromIdToken(idToken: string): User {
    const idTokenParsed: IdTokenParsed = User.parseToken(idToken);

    const firstName = idTokenParsed.given_name;
    const lastName = idTokenParsed.family_name;
    const name = idTokenParsed.name;
    const username = idTokenParsed['cognito:username'];

    const identities = idTokenParsed.identities;

    return new User({
      email: (idTokenParsed.email ?? '').toLowerCase(),
      name: firstName && lastName ? firstName + ' ' + lastName : name || username,
      identityProvider: identities?.[0]?.providerName ? ProviderNameToIdp[identities[0].providerName] ?? null : null,
      groups: idTokenParsed['cognito:groups'],
    });
  }

  /**
   * Parse cognito JWT token into JSON object
   *
   * @param token
   * @public
   */
  private static parseToken(token: string): IdTokenParsed {
    const payload = token.split('.')[1];
    const tokenParsed = atob(payload);
    return JSON.parse(tokenParsed) as IdTokenParsed;
  }

  get isAmazonian() {
    return this.isMatchingIdp(IdentityProvider.AmazonFederate);
  }

  get isTCUser() {
    return this.isMatchingIdp(IdentityProvider.TC);
  }

  get isEventAdmin() {
    return this.memberOfGroup(AuthRole.EVENT_SUPPORT);
  }

  get isCampaignAdmin() {
    return this.memberOfGroup(AuthRole.EVENT_SUPPORT);
  }

  get isChallengeAdmin() {
    return this.memberOfGroup(AuthRole.CHALLENGE_SUPPORT);
  }

  get isChallengeBuilder() {
    return this.memberOfGroup(AuthRole.CHALLENGE_BUILDER);
  }

  get isSuperAdmin() {
    return this.memberOfGroup(AuthRole.JAM_ADMIN);
  }

  get isBarRaiser() {
    return this.memberOfGroup(AuthRole.CHALLENGE_REVIEWER);
  }

  /**
   * Get the identity provider for the current user.
   * Returning null means a username/password user.
   * If the role-spoofing feature is being used, then the spoofed idp is used here.
   */
  get idp(): IdentityProvider | null {
    return Object.values(IdentityProvider).find((idp: IdentityProvider) => this.isMatchingIdp(idp)) || null;
  }

  public memberOfGroup(groupName: string): boolean {
    const spoofedGroups: string[] = getSpoofedGroups();
    const groups: SpoofedGroup[] | string[] = spoofedGroups.length > 0 ? [...spoofedGroups] : [...this.groups];
    return groups.includes(groupName);
  }

  public isMatchingIdp(idp: IdentityProvider): boolean {
    const providerName = IdpToProviderName[idp];
    if (getSpoofedGroups().length > 0) {
      return this.memberOfGroup(providerName);
    }
    return this.provider === idp;
  }
}

export const DEFAULT_STATUS = 'EXTERNAL_PROVIDER';

@jsonObject
export class CognitoUser {
  @jsonMember(AnyT)
  userAttributes: { [key: string]: any } = {};
  @jsonMember(String)
  userStatus: UserStatusType = DEFAULT_STATUS;
  @jsonMember(Boolean)
  isMFAEnabled = false;
  @jsonMember(String)
  username = '';
  @jsonMember(Boolean)
  isEnabled = false;
  @jsonArrayMember(String)
  groups?: string[] = [];
}
