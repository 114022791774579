/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useContext } from 'react';
import { LSEMessage } from '../types/Lse';
import { preProdLogger } from '../utils/log.utils';
import { useApi } from './api.context';

export interface LSEContextValue {
  currentLSE: LSEMessage | undefined;
  loadCurrentLSE: () => void;
  createLSE: (lseMessage: LSEMessage) => void;
  updateLSE: (lseMessage: LSEMessage) => void;
  deactivateLSE: () => void;
}

const LSEContext = React.createContext<LSEContextValue>({
  currentLSE: undefined,
  loadCurrentLSE: () => {},
  createLSE: (lseMessage: LSEMessage) => {},
  updateLSE: (lseMessage: LSEMessage) => {},
  deactivateLSE: () => {},
});

const LSEProvider: React.FC = ({ children }) => {
  const [currentLSE, setCurrentLSE] = useState<LSEMessage | undefined>(undefined);
  const { lseApi } = useApi();

  const loadCurrentLSE = () => {
    lseApi
      .getLSEMessage()
      .then((res) => {
        if (res) {
          setCurrentLSE(res);
        }
      })
      .catch((err) => {
        preProdLogger('Error retrieving LSE Message', err.message);
      });
  };

  const createLSE = (lseMessage: LSEMessage) => {
    if (lseMessage.text) {
      lseApi
        .createLSE(lseMessage)
        .then(() => {
          loadCurrentLSE();
        })
        .catch((err) => {
          preProdLogger('Error creating LSE', err.message);
        });
    }
  };

  const updateLSE = (lseMessage: LSEMessage) => {
    if (lseMessage.text) {
      lseApi
        .updateLSE(lseMessage)
        .then(() => {
          setCurrentLSE(undefined);
          loadCurrentLSE();
        })
        .catch((err) => {
          preProdLogger('Error creating LSE', err.message);
        });
    }
  };

  const deactivateLSE = () => {
    if (currentLSE) {
      lseApi
        .deactivateLSE()
        .then(() => {
          setCurrentLSE(undefined);
        })
        .catch((err) => {
          preProdLogger('Error deactivating LSE', err.message);
        });
    }
  };

  const data: LSEContextValue = {
    currentLSE,
    loadCurrentLSE,
    createLSE,
    updateLSE,
    deactivateLSE,
  };

  return <LSEContext.Provider value={data}>{children}</LSEContext.Provider>;
};

const useLSE = () => {
  const context = useContext(LSEContext);
  if (context === undefined) {
    throw new Error('useLSEs can only be used inside LSEProvider');
  }
  return context;
};

export { LSEProvider, useLSE };
