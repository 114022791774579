import {
  Container,
  ExpandableSection,
  FormField,
  Grid,
  Header,
  Input,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditEventActions, useEditEvent } from '../../../../store/edit-event.context';
import {
  Event,
  MAX_EXPECTED_PARTICIPANTS,
  DEFAULT_MIN_EXPECTED_PARTICIPANTS,
  MIN_EXPECTED_PARTICIPANTS,
  DEFAULT_MAX_EXPECTED_PARTICIPANTS,
} from '../../../../types/Event';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { KeyValue } from '../../../common/KeyValue';
import * as awsui from '@amzn/awsui-design-tokens/polaris.js';
import { useUser } from '../../../../store/user.context';
import _ from 'lodash';

interface AttendanceProps {
  target: Event | undefined;
}

const Attendance: React.FC<AttendanceProps> = ({ target }) => {
  const { t } = useTranslation();
  const { editMode, newEventMode, bulkHandleUpdateEditEvent, editedEvent, handleUpdateEditEvent } = useEditEvent();
  const { user } = useUser();
  const [minExpectedParticipants, setMinExpectedParticipants] = useState('');
  const [maxExpectedParticipants, setMaxExpectedParticipants] = useState('');
  const [maxTeamSize, setMaxTeamSize] = useState('');

  const canEdit = newEventMode || editMode;

  useEffect(() => {
    if (target && (editMode || newEventMode)) {
      // Polaris Input expects a string, even for numeric types
      const minExpectedDefault =
        target?.minExpectedParticipants !== null
          ? target.minExpectedParticipants.toString()
          : DEFAULT_MIN_EXPECTED_PARTICIPANTS.toString();
      const maxExpectedDefault =
        target?.maxExpectedParticipants !== null ? target.maxExpectedParticipants.toString() : '4';
      setMinExpectedParticipants(minExpectedDefault);
      setMaxExpectedParticipants(maxExpectedDefault);
      setMaxTeamSize(target?.maxTeamSize.toString() || '');
      if (newEventMode) {
        setNewEventDefaults();
      }
    }
  }, [editMode, editedEvent, newEventMode]);

  useEffect(() => {
    if (newEventMode) {
      setNewEventDefaults();
    }
  }, []);

  const setNewEventDefaults = () => {
    let minExpectedDefault;
    if (target && target.minExpectedParticipants !== null) {
      minExpectedDefault = target.minExpectedParticipants.toString();
    } else {
      minExpectedDefault = DEFAULT_MIN_EXPECTED_PARTICIPANTS.toString();
    }
    const maxExpectedDefault = target?.maxExpectedParticipants
      ? target?.maxExpectedParticipants.toString()
      : DEFAULT_MAX_EXPECTED_PARTICIPANTS.toString();
    bulkHandleUpdateEditEvent([
      { action: EditEventActions.MIN_EXPECTED_PARTICIPANTS, payload: minExpectedDefault },
      { action: EditEventActions.MAX_EXPECTED_PARTICIPANTS, payload: maxExpectedDefault },
    ]);

    setMinExpectedParticipants(minExpectedDefault);
    setMaxExpectedParticipants(maxExpectedDefault);
  };

  const handleSetMinExpectedParticipants = (selectedMinExpectedParticipants: string) => {
    if (Number(selectedMinExpectedParticipants) >= MIN_EXPECTED_PARTICIPANTS) {
      setMinExpectedParticipants(selectedMinExpectedParticipants);
      handleUpdateEditEvent(EditEventActions.MIN_EXPECTED_PARTICIPANTS, Number(selectedMinExpectedParticipants));
    }
  };

  const handleSetMaxExpectedParticipants = (selectedMaxExpectedParticipants: string) => {
    if (
      Number(selectedMaxExpectedParticipants) >= Number(minExpectedParticipants) &&
      Number(selectedMaxExpectedParticipants) <= MAX_EXPECTED_PARTICIPANTS
    ) {
      setMaxExpectedParticipants(selectedMaxExpectedParticipants);
      handleUpdateEditEvent(EditEventActions.MAX_EXPECTED_PARTICIPANTS, Number(selectedMaxExpectedParticipants));
    }
  };

  const handleMaxTeamSize = (selectedMaxTeamSize: string) => {
    setMaxTeamSize(selectedMaxTeamSize);
    handleUpdateEditEvent(EditEventActions.MAX_TEAM_SIZE, Number(selectedMaxTeamSize));
  };

  const renderContent = () => {
    return !canEdit ? (
      <React.Fragment>
        <div className="section-first-row">
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
            <div className="secondary-text">{t(i18nKeys.events.fields.participants.label)}</div>
            <div>
              {t(i18nKeys.events.fields.participants.value, {
                min: target?.minExpectedParticipants,
                max: target?.maxExpectedParticipants,
              })}
            </div>
          </Grid>
        </div>
        <div className="grey-section-divider-top" style={{ paddingBottom: '18px' }}>
          <Grid gridDefinition={[{ colspan: 3 }, { colspan: 6 }]}>
            <div className="secondary-text">{t(i18nKeys.events.fields.teamSize.label)}</div>
            <div>{t(i18nKeys.events.fields.teamSize.value, { teamSize: target?.maxTeamSize })}</div>
          </Grid>
        </div>
      </React.Fragment>
    ) : (
      canEdit && user && (
        <React.Fragment>
          <SpaceBetween direction="vertical" size="s">
            <FormField
              label={<b>{t(i18nKeys.events.eventDetails.labels.participants.header)}</b>}
              description={t(i18nKeys.events.eventDetails.labels.participants.description)}
              info={<span style={{ color: awsui.colorTextStatusError }}> *</span>}>
              <div>
                {t(i18nKeys.events.eventDetails.labels.participants.start)}{' '}
                <Input
                  inputMode="numeric"
                  type="number"
                  value={minExpectedParticipants}
                  className="numeric-input"
                  onChange={({ detail }) => handleSetMinExpectedParticipants(detail.value)}
                  disabled={!target?.canEditAttribute('minExpectedParticipants', user)}
                />{' '}
                {t(i18nKeys.events.eventDetails.labels.participants.middle)}{' '}
                <Input
                  inputMode="numeric"
                  type="number"
                  value={maxExpectedParticipants}
                  onChange={({ detail }) => handleSetMaxExpectedParticipants(detail.value)}
                  className="numeric-input"
                  disabled={!target?.canEditAttribute('maxExpectedParticipants', user)}
                />{' '}
                {t(i18nKeys.events.eventDetails.labels.participants.end)}
              </div>
            </FormField>
            <KeyValue
              label={<b>{t(i18nKeys.events.eventDetails.labels.participants.maxTeamSizeHeader)}</b>}
              required
              info={t(i18nKeys.events.eventDetails.labels.participants.maxTeamSizeInfo)}>
              <Input
                inputMode="numeric"
                value={maxTeamSize}
                onChange={({ detail }) => handleMaxTeamSize(detail.value)}
                type="number"
                className="numeric-input"
                step={1}
                disabled={!target?.canEditAttribute('maxTeamSize', user)}
              />{' '}
              {t(i18nKeys.events.eventDetails.labels.participants.maxTeamSize)}
            </KeyValue>
          </SpaceBetween>
        </React.Fragment>
      )
    );
  };

  return !newEventMode ? (
    <ExpandableSection
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.attendance)}</Header>}>
      {renderContent()}
    </ExpandableSection>
  ) : (
    <Container header={<Header variant="h2">{t(i18nKeys.events.eventDetails.headers.attendance)}</Header>}>
      {renderContent()}
    </Container>
  );
};

export default Attendance;
