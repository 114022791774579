import { Button, Link } from '@amzn/awsui-components-react';
import React from 'react';
import { useChallenges } from '../../store/challenge.context';
import { useEditEvent } from '../../store/edit-event.context';
import { ChallengeDescriptor, ChallengeListItem } from '../../types/Challenge';

interface BackupChallengeRowProps {
  challengeDescriptor: ChallengeDescriptor;
  moveUp: (cd: ChallengeDescriptor) => void;
  moveDown: (cd: ChallengeDescriptor) => void;
  remove: (cd: ChallengeDescriptor) => void;
  toggleChallengeInfo: (challenge: ChallengeListItem) => void;
  index: number;
  totalCount: number;
}

export const BackupChallengeRow: React.FC<BackupChallengeRowProps> = ({
  challengeDescriptor,
  moveUp,
  moveDown,
  remove,
  toggleChallengeInfo,
  index,
  totalCount,
}) => {
  const { getChallengeListItemFromChallengeId } = useChallenges();
  const { editMode, newEventMode } = useEditEvent();
  const challengeListItem = getChallengeListItemFromChallengeId(challengeDescriptor.challengeId!);
  return challengeListItem ? (
    <div key={`backup-challenge-${index}`}>
      {(editMode || newEventMode) && (
        <React.Fragment>
          <Button
            disabled={index >= totalCount - 1}
            iconName="angle-down"
            variant="inline-icon"
            onClick={() => moveDown(challengeDescriptor)}
          />
          <Button
            disabled={index <= 0}
            iconName="angle-up"
            variant="inline-icon"
            onClick={() => moveUp(challengeDescriptor)}
          />
        </React.Fragment>
      )}
      <strong>{index + 1}. </strong>
      <Link className="mr-5" onFollow={() => toggleChallengeInfo(challengeListItem)}>
        {challengeListItem?.props.title}
      </Link>
      <span>({challengeListItem?.challengeId})</span>
      {(editMode || newEventMode) && (
        <Button onClick={() => remove(challengeDescriptor)} iconName="close" variant="inline-icon" />
      )}
    </div>
  ) : null;
};
