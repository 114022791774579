/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { BASE_PATH_PREFIX } from '../utils/env.utils';
import { config } from './app-config';
import { getSignInUrl, getSignOutUrl } from "@amzn/aws-jam-hybrid-console";

const defaultConfig = {
  Auth: {
    region: 'us-west-2',
    userPoolId: config.cognito.userPoolId,
    userPoolWebClientId: config.cognito.clientId,
    oauth: {
      domain: config.cognito.appWebDomain,
      scope: ['phone', 'email', 'profile', 'openid'],
      redirectSignIn: getSignInUrl(BASE_PATH_PREFIX),
      redirectSignOut: getSignOutUrl(BASE_PATH_PREFIX),
      responseType: 'code',
    },
  },
};

export default defaultConfig;
