import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Table,
  Pagination,
  TextFilter,
  CollectionPreferences,
  SpaceBetween,
  FormField,
  RadioGroup,
  CollectionPreferencesProps,
  ButtonDropdown,
  Checkbox,
} from '@amzn/awsui-components-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableEmptyState } from '../common/TableEmptyState';
import { paginationLabels } from '../../utils/table.utils';
import { TableHeader } from '../common/TableHeader';
import { LoadingBar } from '../common/LoadingBar';
import { COLUMN_DEFINITIONS, filteringFunction } from './event-list-config';
import { i18nKeys } from '../../utils/i18n.utils';
import { useComponentDidMountEffect } from '../../hooks/useComponentDidMountEffect';
import '../../styles/base.scss';
import { useEvents } from '../../store/events.context';
import { EventFilterType, DateRangeFilter } from '../../types/common';
import './EventList.scss';
import moment from 'moment';
import { MultiSelectInput } from '../common/MultiSelectInput/MultiSelectInput';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { DateTimeKeys, TimezoneFormat } from '../common/DateTime';
import { Event, EventStatus } from '../../types/Event';
import { UserPreferenceKeys } from '../../store/user.context';
import { useMediaQuery } from 'react-responsive';
import { DateRangeFilterInput } from '../common/DateRangeFilterInput/DateRangeFilterInput';
import { YYYY_MM_DD } from '../../utils/event-time.utils';
import { TemplateUrlModal } from './TemplateUrlModal';
import { filterEvents } from '../../utils/events-filter.utils';
import { LoggingService } from '../../utils/logging-service.utils';
import { CheckboxProps } from '@amzn/awsui-components-react/uxdg';
import { NonCancelableEventHandler } from '@amzn/awsui-components-react/polaris/internal/events';

interface MultiSelectEvent {
  detail: {
    selectedOptions: OptionDefinition[];
  };
}

interface CheckboxOnChangeEvent {
  details: CheckboxProps.ChangeDetail;
}

interface EventFilter {
  label: string;
  value: string;
}

const EventList: React.FC = () => {
  const templateButtonId = 'template';

  const { events, isLoadEventsByDateRangeInProgress, loadEventsByDateRange } = useEvents();
  const [eventList, setEventList] = useState<Event[]>([]);
  const history = useHistory();
  const location = useLocation();
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRangeFilter>({
    start: moment().subtract(30, 'days').format(YYYY_MM_DD),
    end: null,
  });
  const [templateModalVisible, setTemplateModalVisible] = useState(false);

  const [selectedJamFilters, setSelectedJamFilters] = useState<OptionDefinition[]>([]);

  const [showEndedEvents, setShowEndedEvents] = useState(false);

  const { t } = useTranslation();

  const isMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const liveEventsFilter = useMemo(() => {
    const liveEventsFilterObject: EventFilter = {
      label: t(i18nKeys.events.fields.filters.eventTypes.liveEvents),
      value: EventFilterType.LIVE,
    };
    return liveEventsFilterObject;
  }, [t]);

  const endedEventsFilter = useMemo(() => {
    const endedEventsFilterObject: EventFilter = {
      label: t(i18nKeys.events.fields.filters.eventTypes.endedEvents),
      value: EventFilterType.ENDED,
    };
    return endedEventsFilterObject;
  }, [t]);

  const futureEventsFilter = useMemo(() => {
    const futureEventsFilterObject: EventFilter = {
      label: t(i18nKeys.events.fields.filters.eventTypes.futureEvents),
      value: EventFilterType.FUTURE,
    };
    return futureEventsFilterObject;
  }, [t]);

  const inReviewFilter = useMemo(() => {
    const inReviewFilterObject: EventFilter = {
      label: t(i18nKeys.events.fields.filters.eventTypes.inReviewEvents),
      value: EventFilterType.IN_REVIEW,
    };
    return inReviewFilterObject;
  }, [t]);

  const jamEventsFilter = useMemo(() => {
    const jamEventsFilterObject = {
      label: t(i18nKeys.events.fields.filters.eventTypes.jamEvents),
      value: EventFilterType.JAM,
    };
    return jamEventsFilterObject;
  }, [t]);

  const campaignEventsFilter = useMemo(() => {
    const campaignEventsFilterObject: EventFilter = {
      label: t(i18nKeys.events.fields.filters.eventTypes.campaignEvents),
      value: EventFilterType.CAMPAIGN,
    };
    return campaignEventsFilterObject;
  }, [t]);

  // eslint-disable-next-line @typescript-eslint/require-await
  useComponentDidMountEffect(async () => {
    // TODO: Load filtering from memory JAM-2234
    handleQueryParameters();
    handleOnLoadPreferences();
  });

  /**
   * Sets the dateRangeFilter with desired selection and then calls api for events
   *
   * @param startOrEnd Desired date range filter to set
   * @param date date selected
   */
  const handleDateSelection = (startOrEnd: DateTimeKeys, date: any): void => {
    const dateRangeFilterObject: DateRangeFilter = {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      start: startOrEnd === DateTimeKeys.START ? moment(date).format(YYYY_MM_DD) : dateRangeFilter.start,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      end: startOrEnd === DateTimeKeys.END ? moment(date).format(YYYY_MM_DD) : dateRangeFilter.end,
    };

    setDateRangeFilter(dateRangeFilterObject);

    loadEventsByDateRange({
      start: dateRangeFilterObject.start,
      end: dateRangeFilterObject.end,
    });

    filterEventList();
    handleFilterSelection(selectedJamFilters);
  };

  const handleQueryParameters = () => {
    if (!location.search) {
      history.push({ search: `dateRangeStart=${dateRangeFilter.start}` });
    }
    const params = location.search.split('&');
    const parameterFilters: EventFilter[] = [];
    params.forEach((param) => {
      // Handles the case of a dateRangeEnd being passed to params
      if (param.search('dateRange') > -1) {
        const dateSplit = param.split('dateRange')[1].split('=');
        const startOrEnd: DateTimeKeys = dateSplit[0].toLowerCase() as DateTimeKeys;
        handleDateSelection(startOrEnd, dateSplit[1]);
      } else {
        switch (param) {
          case liveEventsFilter.value:
            parameterFilters.push(liveEventsFilter);
            break;
          case endedEventsFilter.value:
            parameterFilters.push(endedEventsFilter);
            break;
          case futureEventsFilter.value:
            parameterFilters.push(futureEventsFilter);
            break;
          case inReviewFilter.value:
            parameterFilters.push(inReviewFilter);
            break;
          case jamEventsFilter.value:
            parameterFilters.push(jamEventsFilter);
            break;
          case campaignEventsFilter.value:
            parameterFilters.push(campaignEventsFilter);
            break;
          default:
            break;
        }
      }
    });
    handleFilterSelection(parameterFilters);
  };

  const getTypeFilterPlaceholder = (): string => {
    const placeholderText = t(i18nKeys.events.fields.filters.eventTypes.placeholder);
    if (isMobile) {
      return placeholderText;
    }

    let filters = '';
    if (selectedJamFilters) {
      const selectedLabels = selectedJamFilters.map((filter) => filter.label);
      filters = selectedLabels.join(', ');
    }
    return filters || placeholderText;
  };

  const handleFilterSelection = (selectedFilters: OptionDefinition[]) => {
    setSelectedJamFilters(selectedFilters);
    selectedFilters.map((filter) => filter.value);
    let newParamPath = `dateRangeStart=${dateRangeFilter.start}`;
    if (dateRangeFilter.end) {
      newParamPath = newParamPath.concat(`&dateRangeEnd=${dateRangeFilter.end}`);
    }
    selectedFilters.forEach((filter: OptionDefinition) => {
      if (filter.value && newParamPath.search(filter.value) < 0) {
        newParamPath = newParamPath.concat(`&${filter.value}`);
      }
    });
    history.push({ search: newParamPath });
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleSetPreferences = (preferences: CollectionPreferencesProps.Preferences) => {
    if (preferences.pageSize) {
      sessionStorage.setItem(UserPreferenceKeys.PAGE_SIZE, preferences.pageSize.toString());
    }
    if (preferences.custom) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      sessionStorage.setItem(UserPreferenceKeys.DISPLAY_TIME, preferences.custom);
    }
    setPreferences(preferences);
  };

  const handleOnLoadPreferences = () => {
    const pageSize = Number(sessionStorage.getItem(UserPreferenceKeys.PAGE_SIZE));
    const displayTime: string | null = sessionStorage.getItem(UserPreferenceKeys.DISPLAY_TIME);
    const userPreferences = {
      pageSize: pageSize ? pageSize : preferences.pageSize,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      custom: displayTime ? displayTime : preferences.custom,
    };
    setPreferences(userPreferences);
  };

  const filterEventList = useCallback(() => {
    const selectedFilters = selectedJamFilters.map((filter) => filter.value);

    const filteredList = filterEvents(events, selectedFilters, showEndedEvents);

    if (filteredList) {
      LoggingService.debug(
        `Selected filters: ${selectedFilters.join(', ')}; showEndedEvents ${
          showEndedEvents ? 'true' : 'false'
        }; Total events: ${events?.length}; Filtered events: ${filteredList.length}`
      );
      setEventList(filteredList);
    }
  }, [
    jamEventsFilter,
    liveEventsFilter,
    selectedJamFilters,
    campaignEventsFilter,
    endedEventsFilter,
    futureEventsFilter,
    inReviewFilter,
    events,
    showEndedEvents,
  ]);

  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
    pageSize: 10,
    custom: TimezoneFormat.LOCAL,
  });

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    eventList || [],
    {
      filtering: {
        filteringFunction,
        empty: (
          <TableEmptyState
            title={t(i18nKeys.events.list.empty.title)}
            subtitle={t(i18nKeys.events.list.empty.subtitle)}
          />
        ),
        noMatch: (
          <TableEmptyState
            title={t(i18nKeys.tables.noMatch.title)}
            subtitle={t(i18nKeys.tables.noMatch.subtitle)}
            onClearFilter={() => actions.setFiltering('')}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
    }
  );

  useEffect(() => {
    if (events) {
      setEventList(events);
    }
    filterEventList();
  }, [events, selectedJamFilters, filterEventList]);

  if (!events) {
    return <LoadingBar />;
  }

  const handleCreateClick = (id: string) => {
    if (id === templateButtonId) {
      setTemplateModalVisible(true);
    }
  };

  return (
    <div>
      <TemplateUrlModal visible={templateModalVisible} onDismiss={() => setTemplateModalVisible(false)} />
      <Table
        {...collectionProps}
        resizableColumns
        variant="full-page"
        stickyHeader
        header={
          <TableHeader
            totalItems={eventList?.length}
            title={t(i18nKeys.events.title)}
            isLoadingInProgress={isLoadEventsByDateRangeInProgress}
            actionButtons={
              <SpaceBetween direction={isMobile ? 'vertical' : 'horizontal'} size="xs">
                {!isMobile && (
                  <DateRangeFilterInput
                    direction="horizontal"
                    dateRangeFilter={dateRangeFilter}
                    handleDateSelection={handleDateSelection}
                    className="date-selector-action-button"
                  />
                )}
                {
                  <ButtonDropdown
                    items={[
                      {
                        id: 'new',
                        text: t(i18nKeys.events.newEvent).toString(),
                        href: '/events/new',
                        disabled: false,
                      },
                      {
                        text: t(i18nKeys.events.templateUrls.generateUrl.button).toString(),
                        id: templateButtonId,
                        disabled: false,
                      },
                    ]}
                    variant="primary"
                    onItemClick={(details) => handleCreateClick(details.detail.id)}>
                    {t(i18nKeys.events.create)}
                  </ButtonDropdown>
                }
              </SpaceBetween>
            }
          />
        }
        columnDefinitions={COLUMN_DEFINITIONS(preferences)}
        items={items}
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels(t)} />}
        preferences={
          <CollectionPreferences
            title={t(i18nKeys.events.fields.preferences.title)}
            onConfirm={({ detail }) => handleSetPreferences(detail)}
            confirmLabel={t(i18nKeys.general.confirm)}
            cancelLabel={t(i18nKeys.general.cancel)}
            preferences={preferences}
            customPreference={(customValue, setCustomValue) => (
              <FormField label={t(i18nKeys.events.fields.filters.timezoneOptions.label)}>
                <RadioGroup
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  value={customValue}
                  onChange={({ detail }) => setCustomValue(detail.value)}
                  items={[
                    { value: TimezoneFormat.LOCAL, label: t(i18nKeys.events.fields.filters.timezoneOptions.local) },
                    { value: TimezoneFormat.UTC, label: t(i18nKeys.events.fields.filters.timezoneOptions.utc) },
                  ]}
                />
              </FormField>
            )}
            pageSizePreference={{
              title: t(i18nKeys.events.fields.preferences.pageSize.title),
              options: [
                { value: 10, label: t(i18nKeys.events.fields.preferences.pageSize.label, { count: 10 }) },
                { value: 20, label: t(i18nKeys.events.fields.preferences.pageSize.label, { count: 20 }) },
                { value: 50, label: t(i18nKeys.events.fields.preferences.pageSize.label, { count: 50 }) },
                { value: 100, label: t(i18nKeys.events.fields.preferences.pageSize.label, { count: 100 }) },
              ],
            }}
          />
        }
        filter={
          <SpaceBetween direction={isMobile ? 'vertical' : 'horizontal'} size="m">
            {isMobile && (
              <DateRangeFilterInput
                direction="vertical"
                dateRangeFilter={dateRangeFilter}
                handleDateSelection={handleDateSelection}
                className="date-selector-filter"
              />
            )}
            <TextFilter
              className="text-filter"
              filteringPlaceholder={t(i18nKeys.events.searchEvents)}
              {...filterProps}
              countText={t(i18nKeys.tables.matchesCount, { count: filteredItemsCount })}
              filteringAriaLabel={t(i18nKeys.events.filteringLabel)}
            />
            <MultiSelectInput
              options={[liveEventsFilter, futureEventsFilter, inReviewFilter, jamEventsFilter, campaignEventsFilter]}
              label={isMobile ? '' : t(i18nKeys.events.fields.filters.eventTypes.label)}
              selectedOptions={selectedJamFilters}
              placeholder={getTypeFilterPlaceholder()}
              onChange={(event: MultiSelectEvent) => {
                handleFilterSelection(event.detail.selectedOptions);
              }}
            />
            <span className="event-list-checkbox-filter">
              <Checkbox
                checked={showEndedEvents}
                onChange={({ detail }) => {
                  setShowEndedEvents(detail.checked);
                }}>
                {t(i18nKeys.events.fields.filters.labels.showEndedEvents)}
              </Checkbox>
            </span>
          </SpaceBetween>
        }
      />
    </div>
  );
};

export default EventList;
