import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import * as common from './common';

export enum TeamGoal {
  PLAY_TO_WIN = 'PLAY_TO_WIN',
  PLAY_TO_LEARN = 'PLAY_TO_LEARN',
}

@jsonObject
export class TeamMember {
  @jsonMember(common.NullableStringValue)
  userId: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  nickname: common.NullableString = null;

  @jsonMember(Boolean)
  facilitator = false;

  @jsonMember(Boolean)
  disabled = false;

  @jsonArrayMember(String)
  supportChallengeIds: string[] = [];

  @jsonMember(common.NullableStringValue)
  goal: common.Nullable<TeamGoal> = null;
}

@jsonObject
export class Team {
  @jsonMember(common.NullableStringValue)
  name: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  alias: common.NullableString = null;

  @jsonArrayMember(TeamMember)
  members: TeamMember[] = [];

  @jsonMember(Boolean)
  passwordRequired = false;

  @jsonMember(common.NullableStringValue)
  goal: common.Nullable<TeamGoal> = null;

  get teamLabel() {
    return this.alias ? `${this.name}(${this.alias})` : this.name;
  }
}
