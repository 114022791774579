import { SpaceBetween } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useState } from 'react';
import { useComponentDidMountEffect } from '../../../../hooks/useComponentDidMountEffect';
import { useApi } from '../../../../store/api.context';
import { Challenge, ChallengeDeploymentStatistics } from '../../../../types/Challenge';
import { safeString } from '../../../../utils/string.utils';
import ChallengeDeploymentStatisticsDetail from './deploymentStatistics/DeploymentStatistics';
import ChallengeRecentEventsDetail from './recentEvents/RecentEvents';
import ChallengeStatisticSummarysDetail from './StatisticsSummary';

interface ChallengeStatsDetailsProps {
  challenge: Challenge;
}

const ChallengeStatsDetails: React.FC<ChallengeStatsDetailsProps> = ({ challenge }) => {
  const { challengesApi } = useApi();

  const [deploymentStatistics, setDeploymentStatistics] = useState<ChallengeDeploymentStatistics>();

  useComponentDidMountEffect(async () => {
    await challengesApi.getChallengeDeploymentStatistics(safeString(challenge.challengeId), false).then((r) => {
      if (r.statistics) {
        setDeploymentStatistics(r.statistics);
      }
    });
  });

  return (
    <SpaceBetween direction="vertical" size="m">
      <ChallengeStatisticSummarysDetail challenge={challenge} challengeDeploymentStatistics={deploymentStatistics} />
      <ChallengeDeploymentStatisticsDetail challengeDeploymentStatistics={deploymentStatistics} />
      <ChallengeRecentEventsDetail challenge={challenge} />
    </SpaceBetween>
  );
};

export default ChallengeStatsDetails;
