import React, { Dispatch, SetStateAction } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getReviewStatus } from '../revision-list-config';
import { TFunctionProvider } from '../../../../common/TFunctionProvider';
import { i18nKeys } from '../../../../../utils/i18n.utils';
import { ChallengeReview } from '../../../../../types/Challenge';
import { CHALLENGE_DETAILS_ROUTES } from '../../../../../routes';

// eslint-disable-next-line no-shadow
enum RevisionsNeededColumn {
  REVIEW_TYPE = 'type',
  STATUS = 'status',
  REVIEWER = 'reviewer',
  DATE_REVIEWED = 'lastUpdatedDate',
}

export const COLUMN_DEFINITIONS = (
  challengeId: string,
  setCurrentReview: Dispatch<SetStateAction<ChallengeReview | undefined>>
) => [
  {
    id: RevisionsNeededColumn.REVIEW_TYPE,
    sortingField: 'type',
    header: (
      <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.reviewType)}</TFunctionProvider>
    ),
    width: 200,
    cell: (item: ChallengeReview) => (
      <Link
        to={CHALLENGE_DETAILS_ROUTES.Reviews.resolve(challengeId)}
        onClick={() => {
          setCurrentReview(item);
        }}>
        <TFunctionProvider>
          {(t) => t(i18nKeys.challenges.challengeDetails.table.titles.brReview)}
        </TFunctionProvider>
      </Link>
    ),
  },
  {
    id: RevisionsNeededColumn.STATUS,
    sortingField: RevisionsNeededColumn.STATUS,
    header: <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.status)}</TFunctionProvider>,
    minWidth: 100,
    cell: (item: ChallengeReview) => getReviewStatus(item.status) || '--',
  },
  {
    id: RevisionsNeededColumn.REVIEWER,
    sortingField: RevisionsNeededColumn.REVIEWER,
    minWidth: 300,
    header: (
      <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.reviewer)}</TFunctionProvider>
    ),
    cell: (item: ChallengeReview) => item.reviewer?.emailAddress || '--',
  },
  {
    id: RevisionsNeededColumn.DATE_REVIEWED,
    sortingField: RevisionsNeededColumn.DATE_REVIEWED,
    minWidth: 250,
    header: (
      <TFunctionProvider>{(t) => t(i18nKeys.challenges.challengeDetails.table.titles.dateReviewed)}</TFunctionProvider>
    ),
    cell: (item: ChallengeReview) => moment(item?.reviewedOn).format('lll'),
    allowLineWrap: true,
  },
];
