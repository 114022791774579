/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { PageHeader } from '../common/PageHeader';
import { Button, ButtonDropdown, SpaceBetween } from '@amzn/awsui-components-react';
import { LoadingBar } from '../common/LoadingBar';
import { CHALLENGE_DETAILS_ROUTES, challengeRoute, resolve404Path, challengesRoute } from '../../routes';
import { i18nKeys } from '../../utils/i18n.utils';
import ChallengeSummaryDetails from './challengeDetailSections/challengeSummary/ChallengeSummaryDetails';
import ChallengeSettingsDetails from './challengeDetailSections/challengeSettings/ChallengeSettingsDetails';
import { useChallenges } from '../../store/challenge.context';
import ChallengeFeedbackDetails from './challengeDetailSections/challengeFeedback/ChallengeFeedbackDetails';
import { useApi } from '../../store/api.context';
import ChallengeStatsDetails from './challengeDetailSections/challengeStats/ChallengeStatsDetails';
import { ConfirmModal } from '../common/ConfirmModal';
import { ChallengeUtils, ChallengeWarningResponse, MarkChallengeDefectiveRequest } from '../../types/Challenge';
import { useCreateChallenge } from '../../store/create-challenge.context';
import { useUser } from '../../store/user.context';
import { Comment } from '../../../src/types/common';
import ChallengeOverviewDetail from './challengeDetailSections/challengeSummary/Overview';
import LearningOutcomeDetail from './challengeDetailSections/challengeLearningOutcome/LearningOutcome';
import ChallengeTasksDetail from './challengeDetailSections/challengeTask/Tasks';
import ChallengeResourcesDetail from './challengeDetailSections/challengeResources/Resources';
import ChallengeIAMPolicyDetail from './challengeDetailSections/challengeIAMPolicy/IAMPolicy';
import ChallengeCFNTemplateDetail from './challengeDetailSections/challengeCFNTemplate/CFNTemplate';
import ChallengeNextStepsDetail from './challengeDetailSections/challengeNextSteps/NextSteps';
import ChallengeWikiDetail from './challengeDetailSections/challengeWiki/Wiki';
import ChallengeCollaboratorsDetail from './challengeDetailSections/challengeCollaborators/Collaborators';
import ChallengeIssuesDetail from './challengeDetailSections/challengeIssues/Issues';
import ChallengeTestingDetail from './challengeDetailSections/challengeTesting/Testing';
import ChallengeRevisionsDetail from './challengeDetailSections/challengeRevisions/Revisions';
import ChallengeFacilitatorNotesDetail from './challengeDetailSections/challengeFacilitatorNotes/FacilitatorNotes';
import ChallengeHelpDetail from './challengeDetailSections/challengeHelp/Help';
import Comments from '../common/Comments/Comments';
import ChallengeReviewDetail from './challengeDetailSections/challengeRevisions/challengeReviewDetail/ChallengeReviewDetail';
import ReviewProgressTracker from './challengeDetailSections/challengeReviewCommon/ReviewProgressTracker';
import ReviewButtonPanel from './challengeDetailSections/challengeReviewCommon/ReviewButtonPanel';
import { preProdLogger } from '../../utils/log.utils';
import MonkeySpinner from '../common/MonkeySpinner';

const ChallengeDetails: React.FC = () => {
  const { t } = useTranslation();
  const { challengeId }: { challengeId: string } = useParams();
  const history = useHistory();
  const { user } = useUser();
  const { challengesApi } = useApi();
  const {
    challenge,
    getChallenge,
    addChallengeComment,
    updateChallengeComment,
    deleteChallengeComment,
    updatePublicStatus,
    updateDemoStatus,
    archiveChallenge,
    unarchiveChallenge,
    deleteChallenge,
  } = useChallenges();
  const { editMode, toggleChallengeEditMode, initializeNewChallenge, saveEditedChallenge } = useCreateChallenge();

  const [loading, setLoading] = useState(true);

  // Modal Visibility Settings
  const [confirmDefectiveVisible, setConfirmDefectiveVisible] = useState(false);
  const [confirmShutoffVisible, setConfirmShutoffVisible] = useState(false);
  const [confirmArchiveVisible, setConfirmArchiveVisible] = useState(false);
  const [confirmPrivateVisble, setConfirmPrivateVisible] = useState(false);
  const [confirmDemoVisible, setConfirmDemoVisible] = useState(false);
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);

  // Modal Messages
  const [defectiveMessage, setDefectiveMessage] = useState('');
  const [defectiveYesLabel, setDefectiveYesLabel] = useState('');
  const [defectiveWarningToken, setDefectiveWarningToken] = useState('');

  const [privateMessage, setPrivateMessage] = useState('');
  const [privateYesLabel, setPrivateYesLabel] = useState('');

  const [demoMessage, setDemoMessage] = useState('');
  const [demoYesLabel, setDemoYesLAbel] = useState('');

  const [archiveMessage, setArchiveMessage] = useState('');
  const [archiveYesLabel, setArchiveYesLabel] = useState('');

  const [optionalDefectiveReason, setOptionalDefectiveReason] = useState('');

  useEffect(() => {
    loadChallenge();
  }, [challengeId]);

  const loadChallenge = () => {
    setLoading(true);
    getChallenge(challengeId)
      .then(() => setLoading(false))
      .catch((err) => preProdLogger('Error loading challenge', err));
  };

  if (!loading && history && challenge == null) {
    history.push(resolve404Path(challengeRoute.resolvePath(challengeId)));
    return null;
  }

  const comments: Comment[] = challenge?.props.comments ? challenge.props.comments : [];

  const renderChildView = () => {
    return challenge ? (
      <Switch>
        <Route path={CHALLENGE_DETAILS_ROUTES.LearningOutcome.wildcard()}>
          <LearningOutcomeDetail challenge={challenge} />
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Settings.wildcard()}>
          <ChallengeSettingsDetails challenge={challenge} />
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Tasks.wildcard()}>
          <ChallengeTasksDetail challenge={challenge} />
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Assets.wildcard()}>
          <ChallengeResourcesDetail challenge={challenge} />
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.IamPolicy.wildcard()}>
          <ChallengeIAMPolicyDetail challenge={challenge} />
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.CfnTemplate.wildcard()}>
          <ChallengeCFNTemplateDetail challenge={challenge} />
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.NextSteps.wildcard()}>
          <ChallengeNextStepsDetail challenge={challenge} />
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Wiki.wildcard()}>
          <ChallengeWikiDetail challenge={challenge} />
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Collaborators.wildcard()}>
          <ChallengeCollaboratorsDetail challenge={challenge} />
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Issues.wildcard()}>
          <ChallengeIssuesDetail challenge={challenge} />
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Comments.wildcard()}>
          <Comments
            comments={comments || []}
            addNewComment={addChallengeComment}
            deleteComment={deleteChallengeComment}
            updateComment={updateChallengeComment}
          />
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Testing.wildcard()}>
          <ChallengeTestingDetail challenge={challenge} />
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Feedback.wildcard()}>
          <ChallengeFeedbackDetails challenge={challenge} />
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Stats.wildcard()}>
          <ChallengeStatsDetails challenge={challenge} />
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Revisions.wildcard()}>
          <ChallengeRevisionsDetail />
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Reviews.wildcard()}>
          <ChallengeReviewDetail />
        </Route>
        {/* TODO: Complete the Challenge Detail Translation Page */}
        {/* <Route path={CHALLENGE_DETAILS_ROUTES.Translation.wildcard()}>
          <ChallengeTranslationDetails challenge={challenge} />
        </Route> */}
        <Route path={CHALLENGE_DETAILS_ROUTES.FacilitatorNotes.wildcard()}>
          <ChallengeFacilitatorNotesDetail challenge={challenge} />
        </Route>
        <Route path={CHALLENGE_DETAILS_ROUTES.Help.wildcard()}>
          <ChallengeHelpDetail />
        </Route>
        {/* Summary must be last */}
        <Route path={CHALLENGE_DETAILS_ROUTES.Summary.wildcard()}>
          <ChallengeSummaryDetails challenge={challenge} />
        </Route>
      </Switch>
    ) : null;
  };

  const shouldShowButton = (buttonName: string): boolean => {
    if (editMode) {
      return false;
    }

    if (user && challenge) {
      switch (buttonName) {
        case 'critical-issue':
          // return this.challengeGlobalStats.highestIssueSeverity === ChallengeIssueSeverity.CRITICAL;
          break;
        case 'clone':
          return false;
        case 'delete':
          return ChallengeUtils.isChallengeAdmin(user);
        case 'archive':
          return ChallengeUtils.isChallengeAdmin(user);
        case 'defective':
          return ChallengeUtils.isChallengeAdmin(user);
        case 'public':
          return ChallengeUtils.isChallengeAdmin(user);
        case 'demo':
          return ChallengeUtils.isChallengeAdmin(user);
        default:
          return false;
      }
    }

    return false;
  };

  const handleActionClick = (clickEvent: any) => {
    switch (clickEvent.detail.id) {
      case 'defective':
        void setDefectiveMessages();
        setConfirmDefectiveVisible(true);
        break;
      case 'shutOffLabDeployment':
        setConfirmShutoffVisible(true);
        break;
      case 'public':
        setPrivateMessages();
        setConfirmPrivateVisible(true);
        break;
      case 'demo':
        setDemoMessages();
        setConfirmDemoVisible(true);
        break;
      case 'archive':
        setArchiveMessages();
        setConfirmArchiveVisible(true);
        break;
    }
    return;
  };

  const setDefectiveMessages = async () => {
    const newDefectiveState = !challenge?.isDefective;
    let message = '';

    if (!newDefectiveState) {
      message = t(i18nKeys.challenges.challengeDetails.messages.removeDefectiveState);
      setDefectiveYesLabel(t(i18nKeys.challenges.challengeDetails.yesLabels.removeDefectiveState));
    } else {
      message = t(i18nKeys.challenges.challengeDetails.messages.markDefectiveState);
      setDefectiveYesLabel(t(i18nKeys.challenges.challengeDetails.yesLabels.markDefectiveState));
    }

    let warningToken = '';

    // i.e. if this challenge is transitioning from not defective to defective
    if (newDefectiveState === true && !challenge?.isDefective) {
      /* Get a list of warnings and a token associated with marking this challenge as defective. */
      const warning: ChallengeWarningResponse = await challengesApi.getMarkDefectiveWarnings(challengeId, false);

      warningToken = warning.warningToken;

      if (warning.warnings !== undefined && warning.warnings.length > 0) {
        message += '\n\nWarning: ';

        for (const warningMessage of warning.warnings) {
          message += `${warningMessage}\n`;
        }

        setDefectiveWarningToken(warningToken);
      }
    }
    setDefectiveMessage(message);
  };

  const handleDefectiveConfirmation = async () => {
    const request: MarkChallengeDefectiveRequest = {
      challengeId,
      defective: !challenge?.isDefective,
      warningToken: defectiveWarningToken,
      optionalComment: optionalDefectiveReason,
    };

    await challengesApi.updateDefectiveStatus(challengeId, request);
    setConfirmDefectiveVisible(false);
  };

  const setShutOffMessages = () => {
    // TODO: After integrating Lab Shut Off APIs
  };

  const handleShutOffConfirmation = () => {
    // TODO: After integrating Lab Shut Off APIs
  };

  const setPrivateMessages = () => {
    if (!challenge?.isPublic) {
      setPrivateMessage(t(i18nKeys.challenges.challengeDetails.messages.markChallengePublic));
      setPrivateYesLabel(t(i18nKeys.challenges.challengeDetails.yesLabels.markChallengePublic));
    } else {
      setPrivateMessage(t(i18nKeys.challenges.challengeDetails.messages.markChallengePrivate));
      setPrivateYesLabel(t(i18nKeys.challenges.challengeDetails.yesLabels.markChallengePrivate));
    }
  };

  const handlePrivateConfirmation = () => {
    updatePublicStatus(challengeId, !challenge?.isPublic);
    setConfirmPrivateVisible(false);
  };

  const setDemoMessages = () => {
    const newDemoState = !challenge?.isDemo;

    let newDemoMessage = '';

    if (!newDemoState) {
      newDemoMessage = t(i18nKeys.challenges.challengeDetails.messages.removeAsExampleChallenge);
      setDemoYesLAbel(t(i18nKeys.challenges.challengeDetails.yesLabels.removeAsExampleChallenge));
    } else {
      newDemoMessage = t(i18nKeys.challenges.challengeDetails.messages.markAsExampleChallenge);
      if (!challenge?.isPublic) {
        newDemoMessage += t(i18nKeys.challenges.challengeDetails.messages.markAsNewExampleChallenge);
      }
      setDefectiveYesLabel(t(i18nKeys.challenges.challengeDetails.yesLabels.markAsExampleChallenge));
    }

    setDemoMessage(newDemoMessage);
  };

  const handleDemoConfirmation = () => {
    updateDemoStatus(challengeId, !challenge?.isDemo);
    setConfirmDemoVisible(false);
  };

  const setArchiveMessages = () => {
    if (challenge?.isArchived) {
      setArchiveMessage(t(i18nKeys.challenges.challengeDetails.messages.unarchiveChallenge));
      setArchiveYesLabel(t(i18nKeys.challenges.challengeDetails.yesLabels.unarchiveChallenge));
    } else {
      setArchiveMessage(t(i18nKeys.challenges.challengeDetails.messages.archiveChallenge));
      setArchiveYesLabel(t(i18nKeys.challenges.challengeDetails.yesLabels.archiveChallenge));
    }
  };

  const handleArchiveConfirmation = () => {
    if (challenge?.isArchived) {
      unarchiveChallenge(challengeId);
    } else {
      archiveChallenge(challengeId);
    }
    setConfirmArchiveVisible(false);
  };

  const handleToggleEdit = () => {
    if (challenge) {
      initializeNewChallenge();
    }

    toggleChallengeEditMode(challenge || null);
  };

  const handleDeleteChallenge = () => {
    deleteChallenge(challengeId);
    setConfirmDeleteVisible(false);
    if (history) {
      history.push(challengesRoute.path);
    }
  };

  const handleSaveChallenge = () => {
    setLoading(true);
    saveEditedChallenge()
      .then((c) => {
        if (c) {
          getChallenge(c.challengeId || '')
            .then(() => toggleChallengeEditMode(null))
            .catch((err) => preProdLogger(err));
        }
      })
      .catch((err) => preProdLogger(err))
      .finally(() => setLoading(false));
  };

  return (
      <React.Fragment>
        {loading && (
          <MonkeySpinner />
        )}
        <ReviewProgressTracker />
        <PageHeader
          title={challenge?.props.title || 'Untitled Challenge'}
          buttons={[
            <span key="challenge-details-button">
              <ReviewButtonPanel />
              {shouldShowButton('public') && (
                <ButtonDropdown
                  className="mr-5"
                  onItemClick={handleActionClick}
                  items={[
                    // Non-null assertions necessary due to i18nKeys being string | undefined and ItemOrGroup requiring text to be just string
                    // i18nKey should never be undefined
                    {
                      text: challenge?.isDefective
                        ? t(i18nKeys.challenges.challengeDetails.buttons.removeChallengeDefective)!
                        : t(i18nKeys.challenges.challengeDetails.buttons.markChallengeDefective)!,
                      id: 'defective',
                      disabled: !shouldShowButton('defective'),
                    },
                    {
                      text: t(i18nKeys.challenges.challengeDetails.buttons.shutOffLabDeployment)!,
                      id: 'shutOffLabDeployment',
                      disabled: false,
                    },
                    {
                      text: challenge?.isPrivate
                        ? t(i18nKeys.challenges.challengeDetails.buttons.markChallengePublic)!
                        : t(i18nKeys.challenges.challengeDetails.buttons.markChallengePrivate)!,
                      id: 'public',
                      disabled: !shouldShowButton('public'),
                    },
                    {
                      text: challenge?.isDemo
                        ? t(i18nKeys.challenges.challengeDetails.buttons.removeAsExampleChallenge)!
                        : t(i18nKeys.challenges.challengeDetails.buttons.markAsExampleChallenge)!,
                      id: 'demo',
                      disabled: !shouldShowButton('demo'),
                    },
                    {
                      text: challenge?.isArchived
                        ? t(i18nKeys.challenges.challengeDetails.buttons.unarchiveChallenge)!
                        : t(i18nKeys.challenges.challengeDetails.buttons.archiveChallenge)!,
                      id: 'archive',
                      disabled: !shouldShowButton('archive'),
                    },
                  ]}>
                  {t(i18nKeys.events.eventDetails.buttons.actionsPlaceholder)}
                </ButtonDropdown>
              )}
              {!editMode && ChallengeUtils.isCollaborator(challenge, user) && (
                <Button className="mr-5" onClick={() => handleToggleEdit()} disabled={loading}>
                  {t(i18nKeys.general.edit)}
                </Button>
              )}
              {editMode && ChallengeUtils.isCollaborator(challenge, user) && (
                <React.Fragment>
                  <Button className="mr-5" variant="link" onClick={() => toggleChallengeEditMode(challenge || null)} disabled={loading}>
                    {t(i18nKeys.general.cancel)}
                  </Button>
                  <Button className="mr-5" variant="primary" onClick={handleSaveChallenge} disabled={loading}>
                    {t(i18nKeys.general.save)}
                  </Button>
                </React.Fragment>
              )}
              {shouldShowButton('delete') && (
                <Button className="mr-5" onClick={() => setConfirmDeleteVisible(true)}>
                  {t(i18nKeys.general.delete)}
                </Button>
              )}
            </span>,
          ]}
        />
        <ConfirmModal
          message={defectiveMessage}
          visible={confirmDefectiveVisible}
          confirmBtnLabel={defectiveYesLabel}
          textInput={optionalDefectiveReason}
          onTextInput={setOptionalDefectiveReason}
          onConfirm={handleDefectiveConfirmation}
          onCancel={() => setConfirmDefectiveVisible(false)}
        />
        <ConfirmModal
          visible={confirmShutoffVisible}
          confirmBtnLabel={t(i18nKeys.events.eventDetails.buttons.yesDelete)}
          onConfirm={handleShutOffConfirmation}
          onCancel={() => setConfirmShutoffVisible(false)}
        />
        <ConfirmModal
          message={privateMessage}
          visible={confirmPrivateVisble}
          confirmBtnLabel={privateYesLabel}
          onConfirm={handlePrivateConfirmation}
          onCancel={() => setConfirmPrivateVisible(false)}
        />
        <ConfirmModal
          message={demoMessage}
          visible={confirmDemoVisible}
          confirmBtnLabel={demoYesLabel}
          onConfirm={handleDemoConfirmation}
          onCancel={() => setConfirmDemoVisible(false)}
        />
        <ConfirmModal
          message={archiveMessage}
          visible={confirmArchiveVisible}
          confirmBtnLabel={archiveYesLabel}
          onConfirm={handleArchiveConfirmation}
          onCancel={() => setConfirmArchiveVisible(false)}
        />
        <ConfirmModal
          visible={confirmDeleteVisible}
          confirmBtnLabel={t(i18nKeys.events.eventDetails.buttons.yesDelete)}
          onConfirm={handleDeleteChallenge}
          onCancel={() => setConfirmDeleteVisible(false)}
        />
        <SpaceBetween size="s">
          <ChallengeOverviewDetail />
          {renderChildView()}
        </SpaceBetween>
      </React.Fragment>
  );
};

export default ChallengeDetails;
