import {
  Badge,
  Button,
  Container,
  Flashbar,
  FormField,
  Grid,
  Header,
  Input,
  SpaceBetween,
  Textarea,
  Toggle,
} from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { challengeSetRoute, resolve404Path } from '../../../routes';
import {
  ChallengeSetAction,
  CHALLENGE_SET_DESCRIPTION_MAX_LENGTH,
  CHALLENGE_SET_DESCRIPTION_MIN_LENGTH,
  CHALLENGE_SET_TITLE_MAX_LENGTH,
  CHALLENGE_SET_TITLE_MIN_LENGTH,
  useChallengeSet,
} from '../../../store/challenge-set.context';
import { useChallenges } from '../../../store/challenge.context';
import { useToolPanel } from '../../../store/tool-panel.context';
import { ChallengeDescriptor } from '../../../types/Challenge';
import { ChallengeSet } from '../../../types/ChallengeSet';
import { i18nKeys } from '../../../utils/i18n.utils';
import { preProdLogger } from '../../../utils/log.utils';
import BrowseChallenges from '../../common/Challenges/BrowseChallenges';
import { KeyValue } from '../../common/KeyValue';
import { LoadingBar } from '../../common/LoadingBar';
import { PageHeader } from '../../common/PageHeader';

const ChallengeSetDetails: React.FC = () => {
  const { t } = useTranslation();
  const { challengeSetId }: { challengeSetId: string } = useParams();
  const { toggleChallengeInfo } = useToolPanel();
  const history = useHistory();
  const { getChallengeDescriptors } = useChallenges();
  const {
    challengeSet,
    editedChallengeSet,
    editMode,
    toggleEditMode,
    getChallengeSet,
    handleUpdateChallengeSetAttribute,
    updateChallengeSet,
  } = useChallengeSet();

  const [loading, setLoading] = useState(true);

  const [items, setItems] = useState([
    {
      dismissible: true,
      dismissLabel: t(i18nKeys.challenges.subSections.cfnTemplate.dismissLabel),
      onDismiss: () => setItems([]),
      content: <>{t(i18nKeys.challenges.challengeSet.message.challengeSetDescription)}</>,
    },
  ]);

  const [currentChallengeDescriptors, setCurrentChallengeDescriptors] = useState<ChallengeDescriptor[]>([]);

  useEffect(() => {
    setLoading(true);
    getChallengeSet(challengeSetId)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => preProdLogger('Error loading challenge set', err));
  }, [challengeSetId]);

  useEffect(() => {
    const currentChallengeSet: ChallengeSet | undefined = editMode ? editedChallengeSet : challengeSet;
    const challengeDescriptors = getChallengeDescriptors(currentChallengeSet?.challengeIds || []);
    setCurrentChallengeDescriptors(challengeDescriptors);
  }, [challengeSet, editedChallengeSet]);

  if (loading) {
    return <LoadingBar />;
  }

  if (history && challengeSet == null) {
    history.push(resolve404Path(challengeSetRoute.resolvePath(challengeSetId)));
    return null;
  }

  const handleChallengeAction = (payload: ChallengeDescriptor[]) => {
    if (editMode && editedChallengeSet) {
      const challengeIds: string[] = payload.map((cd) => cd.challengeId as string);
      handleUpdateChallengeSetAttribute(ChallengeSetAction.CHALLENGE_IDS, challengeIds);
    }
  };

  const handleOnSave = () => {
    updateChallengeSet()
      .then((cs) => {
        if (cs) {
          toggleEditMode();
        }
      })
      .catch((err) => preProdLogger(err));
  };

  return (
    <SpaceBetween size="m">
      <PageHeader
        title={challengeSet?.title || 'Untitled Challenge Set'}
        buttons={[
          <SpaceBetween direction="horizontal" size="xs" key={'challenge-set-save'}>
            {!editMode && (
              <Button variant="primary" onClick={toggleEditMode}>
                {t(i18nKeys.general.edit)}
              </Button>
            )}
            {editMode && <Button onClick={toggleEditMode}>{t(i18nKeys.general.cancel)}</Button>}
            {editMode && (
              <Button variant="primary" onClick={handleOnSave}>
                {t(i18nKeys.general.save)}
              </Button>
            )}
          </SpaceBetween>,
        ]}
      />
      <Flashbar items={items} />
      <Container header={<Header>{t(i18nKeys.challenges.challengeSet.header.details)}</Header>}>
        <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
          <KeyValue label={t(i18nKeys.challenges.challengeSet.titles.title)}>
            {!editMode && challengeSet?.title}
            {editMode && editedChallengeSet && (
              <FormField
                constraintText={t(i18nKeys.challenges.subSections.overview.fields.title.constraint, {
                  CHALLENGE_SET_TITLE_MIN_LENGTH,
                  CHALLENGE_SET_TITLE_MAX_LENGTH,
                })}>
                <Input
                  type="text"
                  value={editedChallengeSet.title || ''}
                  onChange={({ detail }) => handleUpdateChallengeSetAttribute(ChallengeSetAction.TITLE, detail.value)}
                  placeholder={t(i18nKeys.challenges.challengeSet.placeholder.title)}
                />
              </FormField>
            )}
          </KeyValue>
          <KeyValue label={t(i18nKeys.challenges.challengeSet.titles.description)}>
            {!editMode && challengeSet?.description}
            {editMode && editedChallengeSet && (
              <FormField
                constraintText={t(i18nKeys.challenges.subSections.overview.fields.description.constraint, {
                  CHALLENGE_SET_DESCRIPTION_MIN_LENGTH,
                  CHALLENGE_SET_DESCRIPTION_MAX_LENGTH,
                })}>
                <Textarea
                  onChange={({ detail }) =>
                    handleUpdateChallengeSetAttribute(ChallengeSetAction.DESCRIPTION, detail.value)
                  }
                  value={editedChallengeSet.description || ''}
                  placeholder={t(i18nKeys.challenges.challengeSet.placeholder.description)}
                />
              </FormField>
            )}
          </KeyValue>
        </Grid>
        <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
          {challengeSet?.featured && !editMode && (
            <Badge color="green">{t(i18nKeys.challenges.challengeSet.titles.featured)}</Badge>
          )}
          {editMode && editedChallengeSet && (
            <Toggle
              onChange={({ detail }) => handleUpdateChallengeSetAttribute(ChallengeSetAction.FEATURED, detail.checked)}
              checked={editedChallengeSet.featured || false}>
              {t(i18nKeys.challenges.challengeSet.titles.featured)}
            </Toggle>
          )}
        </Grid>
      </Container>
      <BrowseChallenges
        handleChallengeAction={handleChallengeAction}
        currentChallengeDescriptors={currentChallengeDescriptors}
        toggleChallengeInfo={toggleChallengeInfo}
        editMode={editMode}
        displayChallenges={challengeSet?.challengeIds}
      />
    </SpaceBetween>
  );
};

export default ChallengeSetDetails;
