import { SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { Event } from '../../../types/Event';
import Attendance from '../eventDetailsSections/Summary/Attendance';
import DetailsEventType from '../eventDetailsSections/Summary/DetailsEventType';

interface AttendanceAndTestingProps {
  target: Event;
}

const AttendanceAndTesting: React.FC<AttendanceAndTestingProps> = ({ target }) => {
  return (
    <SpaceBetween direction="vertical" size="s">
      <Attendance target={target} />
      <DetailsEventType target={target} />
    </SpaceBetween>
  );
};

export default AttendanceAndTesting;
