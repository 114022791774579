import moment from 'moment';
import { AnyT, jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { getEventUrl } from '../utils/jam-urls';
import { getAllowedRegionsForEvent } from '../utils/supported-regions';
import { WithChallenges, Challenge, ChallengeDescriptor, ChallengeConfiguration } from './Challenge';
import * as common from './common';
import { EventStatus } from './Event';
import * as LabModels from './LabModels';
import { LabShutoffStatus } from './LabShutoff';

export enum EventLabSummaryStatus {
  NOT_READY = 'notReady',
  PREPARING_RESOURCES = 'preparingResources',
  ON_HOLD = 'onHold',
  UNASSIGNED = 'unassigned',
  ASSIGNED = 'assigned',
  COMPLETED = 'completed',
  TIMED_OUT = 'timedOut',
  RESTARTED = 'restarted',
  TERMINATED = 'terminated',
  INVALID = 'invalid',
}

@jsonObject
export class EventLabSummary implements WithChallenges {
  // required for the challenge-selection component (WithChallengesAndScoring interface)
  @jsonMember(String)
  idAttribute = 'eventName';

  @jsonMember(common.NullableTimeStampValue)
  timestamp: common.NullableTimeStamp = null; // generated from ui, time response was received

  @jsonMember(common.NullableStringValue)
  eventName: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  eventId: common.NullableString = null;

  @jsonMember(common.NullableStringValue)
  eventTitle: common.NullableString = null;

  @jsonMember(String)
  eventStatus: EventStatus = EventStatus.NOT_STARTED;

  @jsonMember(common.NullableStringValue)
  eventCode: common.NullableString = null;

  @jsonMember(String)
  approvalStatus: common.ApprovalStatus = common.ApprovalStatus.REQUEST_SUBMITTED;

  @jsonMember(common.NullableStringValue)
  changeRequestStatus: common.Nullable<common.ChangeRequestStatus> = null;

  @jsonMember(Boolean)
  cancelled = false;

  @jsonMember(Boolean)
  testEvent = false;

  @jsonMember(Boolean)
  testClone = false;

  @jsonMember(common.NullableDateStringValue)
  eventStartTime: common.NullableDateString = null;

  @jsonMember(common.NullableDateStringValue)
  eventEndTime: common.NullableDateString = null;

  @jsonMember(common.NullableDateStringValue)
  labStartTime: common.NullableDateString = null;

  @jsonMember(common.NullableDateStringValue)
  labEndTime: common.NullableDateString = null;
  // map of {challengeId: labProvider}

  @jsonMember(Object)
  challengeLabProviders: { [challengeId: string]: string } = {};

  @jsonMember(AnyT)
  labStatusSnapshots: { [challengeId: string]: LabModels.LabStatusSnapshot } = {};

  @jsonMember(Object)
  labEventMetadata: { [challengeId: string]: LabModels.LabMetadata } = {};

  // TODO: Investigate improper loading of Lab Auto Scaling decisions, decisions field not parsable as array
  // https://sim.amazon.com/issues/JAM-5193
  desiredLabCounts: { [challengeId: string]: LabModels.LabAutoScalingDecision } = {};

  @jsonMember(Object)
  unusedImportedPropsCounts: common.ChallengeCounts = {};

  @jsonMember(Object)
  usedImportedPropsCounts: common.ChallengeCounts = {};

  @jsonMember(Number)
  minExpectedParticipants = 0;

  @jsonMember(Number)
  maxExpectedParticipants = 0;

  @jsonMember(Number)
  numTeams = 0;

  @jsonMember(Number)
  numRegisteredParticipants = 0;

  @jsonMember(Number)
  numFacilitators = 0;

  @jsonArrayMember(String)
  regionAllowlist: string[] = [];

  @jsonArrayMember(String)
  regionDenylist: string[] = [];

  @jsonArrayMember(Challenge)
  challenges: Challenge[] = [];

  @jsonArrayMember(ChallengeDescriptor)
  challengeDescriptors: ChallengeDescriptor[] = [];

  // may be set on the frontend by API calls to the labShutoffService
  @jsonMember(LabShutoffStatus)
  shutoffStatus?: LabShutoffStatus;

  @jsonMember(LabShutoffStatus)
  testEventShutoffStatus?: LabShutoffStatus;

  // the labs are loaded from separate api calls and then added to this class
  @jsonMember(Object)
  labs: LabModels.LabsByChallengeId = {};

  /**
   * Gets lab counts of all labs in an event for a particular challenge
   *
   * @param eventLabSummary EventLabSummary depicting the labs and their statuses
   * @param challengeId ChallengeId of the challenge to get counts of labs for
   * @returns An object depicting the count of all labs in their respective statuses for provided challengeId and Event
   */
  static getLabInternalStatusCounts(eventLabSummary: EventLabSummary, challengeId: string): LabModels.LabStatusCounts {
    return EventLabSummary.getLabStatusCounts((attribute: keyof LabModels.LabStatusSnapshot): number => {
      return eventLabSummary.getLabSnapshot(challengeId)[attribute] as number;
    });
  }

  /**
   * Gets aggregated lab counts of all challenges in a provided event
   *
   * @param eventLabSummary EventLabSummary depicting the labs and their statuses
   * @returns An object depicting the count of all labs in their respective statuses for all challenges in an Event
   */
  static getAggregatedLabStatusCounts(eventLabSummary: EventLabSummary): LabModels.LabStatusCounts {
    return EventLabSummary.getLabStatusCounts((attribute: keyof LabModels.LabStatusSnapshot): number => {
      return Object.values(eventLabSummary.labStatusSnapshots).reduce((sum, snapshot) => {
        return sum + (snapshot[attribute] as number);
      }, 0);
    });
  }

  /**
   * @param getCount provided method for getting counts of provided lab statuses
   * @returns An instance of LabStatusCounts depicting the counts of provided event challenge labs utilizing provided getCount method
   */
  static getLabStatusCounts(
    getCount: (attribute: keyof LabModels.LabStatusSnapshot) => number
  ): LabModels.LabStatusCounts {
    return {
      NOT_READY: getCount(EventLabSummaryStatus.NOT_READY),
      PREPARING_RESOURCES: getCount(EventLabSummaryStatus.PREPARING_RESOURCES),
      ON_HOLD: getCount(EventLabSummaryStatus.ON_HOLD),
      UNASSIGNED: getCount(EventLabSummaryStatus.UNASSIGNED),
      ASSIGNED: getCount(EventLabSummaryStatus.ASSIGNED),
      COMPLETED: getCount(EventLabSummaryStatus.COMPLETED),
      TIMED_OUT: getCount(EventLabSummaryStatus.TIMED_OUT),
      RESTARTED: getCount(EventLabSummaryStatus.RESTARTED),
      TERMINATED: getCount(EventLabSummaryStatus.TERMINATED),
      INVALID: getCount(EventLabSummaryStatus.INVALID),
    };
  }

  /**
   * Retrieves total number of nonTerminated lab accounts
   */
  get totalNonTerminatedAccounts(): number {
    return Object.values(this.labStatusSnapshots).reduce((sum, snapshot) => {
      return Math.max(0, sum + snapshot.usable);
    }, 0);
  }

  /**
   * Retrieves all labs on EventLabSummary
   *
   * @returns A list of Labs
   */
  getLabsAsList(): LabModels.Lab[] {
    return Object.values(this.labs).reduce((allLabs, labs) => [...allLabs, ...labs], []);
  }

  /**
   * Retrieves list of all nonMaster labs on EventLabSummary
   */
  get nonMasterLabs(): LabModels.Lab[] {
    return this.getLabsAsList().filter((lab) => !lab.master);
  }

  /**
   * Retrieves labSnapshot of provided challengeId
   *
   * @param challengeId ChallengeId to get lab snapshot of
   * @returns Labsnapshot of provided challengeId
   */
  getLabSnapshot(challengeId: string): LabModels.LabStatusSnapshot {
    const newLabStatusSnapshot = new LabModels.LabStatusSnapshot();
    newLabStatusSnapshot.eventName = this.eventName;
    newLabStatusSnapshot.challengeId = challengeId;
    return this.labStatusSnapshots[challengeId] || newLabStatusSnapshot;
  }

  /**
   * Calculates and returns time remaining based on eventEndTime
   */
  get timeRemainingMillis(): number {
    return moment.parseZone(this.eventEndTime).valueOf() - Date.now();
  }

  /**
   * Calculates sum of total desired labs
   */
  get totalDesiredLabs() {
    return Object.values(this.desiredLabCounts).reduce((total, value) => {
      return total + (value.count || 0);
    }, 0);
  }

  /**
   * Get the list of allowlisted regionIds that are not also in the denylist,
   * falling back to the list of supported lab regions for the allowlist.
   *
   * @param challengeConfiguration Challenge configuration to derive allowedRegions from
   * @returns array of string depicting supported regions
   */
  getAllowedRegions(challengeConfiguration: ChallengeConfiguration): string[] {
    return getAllowedRegionsForEvent(this, challengeConfiguration);
  }

  /**
   * Returns the first challenge descriptor in this list with the given challengeId,
   * if there is any such descriptor, undefined otherwise.
   *
   * @param challengeId
   * @returns challenge descriptor of provided challengeId
   */
  getChallengeDescriptorById(challengeId: string): ChallengeDescriptor | undefined {
    return this.challengeDescriptors?.find((cd: ChallengeDescriptor) => cd.challengeId === challengeId);
  }

  /**
   * Returns length of all awsAccountBased challenges
   */
  get numChallengesWithLabs(): number {
    return this.challenges.filter((challenge) => challenge.awsAccountBased).length;
  }

  /**
   * Returns length of challenges
   */
  get numChallenges(): number {
    return this.challenges.length;
  }

  get url() {
    if (this.eventId) {
      return getEventUrl(this.eventId);
    }
  }

  get urlWithSecretKey(): common.NullableString {
    if (this.url) {
      return this.eventCode ? `${this.url}?code=${this.eventCode}` : this.url;
    } else {
      return null;
    }
  }

  get urlWithMaskedSecretKey(): common.NullableString {
    if (this.url) {
      return this.eventCode ? `${this.url}?code=******` : this.url;
    } else {
      return null;
    }
  }
}
