import { ExpandableSection, Header, TextContent } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '../../../../utils/i18n.utils';
import { Column } from '../../../common/Column';
import { Columns } from '../../../common/Columns';
import { HorizontalRule } from '../../../common/HorizontalRule';

const ChallengeHelpDetail: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ExpandableSection
      variant="container"
      header={<Header variant="h2">{t(i18nKeys.challenges.challengeDetails.headings.help)}</Header>}>
      <strong>{t(i18nKeys.challenges.challengeDetails.titles.challengeStatusLifecycle)}</strong>
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <img src="/assets/challenge-status-workflow-polaris.png" />
      </div>
      <HorizontalRule />
      <strong>{t(i18nKeys.challenges.challengeDetails.titles.changedFieldIndicators)}</strong>
      <Columns columns={2}>
        <Column size={'s'}>
          <div style={{ display: 'inline-block' }}>
            <div className="block" style={{ backgroundColor: 'red' }}>
              {' '}
            </div>
            {t(i18nKeys.challenges.challengeDetails.titles.title)}
          </div>
          <TextContent>{t(i18nKeys.challenges.challengeDetails.text.changedMostRecentIndicator)}</TextContent>
        </Column>
        <Column size={'s'}>
          <div style={{ display: 'inline-block' }}>
            <div className="block" style={{ backgroundColor: 'blue' }}>
              {' '}
            </div>
            {t(i18nKeys.challenges.challengeDetails.titles.title)}
          </div>
          <TextContent>{t(i18nKeys.challenges.challengeDetails.text.unsavedChange)}</TextContent>
        </Column>
      </Columns>
    </ExpandableSection>
  );
};

export default ChallengeHelpDetail;
